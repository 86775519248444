import React, { FC } from "react";

import { CircularProgress, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import Occasion from "./Occasion";
import Grid from "@material-ui/core/Grid/Grid";
import { getOccasionsVariables, getOccasions } from "../../graphql/queries/occasions/__generated__/getOccasions";
import { QueryResult } from "@apollo/client/react/types/types";
import ViewOccasionDialog from "./ViewOccasionDialog";
import List from "@material-ui/core/List/List";
import ErrorState from "../general-ui/state-messages/ErrorState";

type Props = {
  queryContext: QueryResult<getOccasions, getOccasionsVariables>;
  showGiftee?: boolean;
};

const useStyles = makeStyles((theme) => ({
  occasionFeedContainer: {
    maxHeight: "calc(100vh - 100px)",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    overflowY: "scroll",
    padding: theme.spacing(1),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "calc(100vh - 150px)",
      width: "100%",
      display: "block",
      alignItems: "center",
      padding: theme.spacing(0.25),
      paddingBottom: theme.spacing(5),
    },
  },
}));

const OccasionFeed: FC<Props> = ({ queryContext, showGiftee }) => {
  const { loading, error, data } = queryContext;

  const occasions = data?.occasions ?? [];
  const classes = useStyles();
  if (loading) {
    return <CircularProgress />;
  }
  if (error) {
    return <ErrorState />;
  }

  return (
    <List className={classes.occasionFeedContainer}>
      <ViewOccasionDialog />
      {occasions.map((occasion: any) => {
        return <Occasion showGiftee={showGiftee} occasion={occasion} key={occasion._id} />;
      })}
    </List>
  );
};

export default OccasionFeed;
