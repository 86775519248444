// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  query GetGiftProfileQuery($query: GiftProfileQueryInput)  {
    giftProfile(query: $query)  {
      __typename
      recommendationsByCategory  {
        __typename
        recommendations  {
          __typename
          _id
          image
          price
          similarity
          title
          giftProvider  {
            __typename
            logo
          }
        }
        category
      }
      purchases  {
        __typename
        giftIdeaId
        transactionId
        date
        targetYear
      }
      userId
      gifteeId
      interests
    }
  }
`);

function parse(value) {
  var value$1 = value.giftProfile;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.recommendationsByCategory;
    var value$3 = value$1.purchases;
    var value$4 = value$1.userId;
    var value$5 = value$1.gifteeId;
    var value$6 = value$1.interests;
    tmp = {
      __typename: value$1.__typename,
      recommendationsByCategory: !(value$2 == null) ? value$2.map(function (value) {
              if (value == null) {
                return ;
              }
              var value$1 = value.recommendations;
              var value$2 = value.category;
              return {
                      __typename: value.__typename,
                      recommendations: !(value$1 == null) ? value$1.map(function (value) {
                              if (value == null) {
                                return ;
                              }
                              var value$1 = value._id;
                              var value$2 = value.image;
                              var value$3 = value.price;
                              var value$4 = value.similarity;
                              var value$5 = value.title;
                              var value$6 = value.giftProvider;
                              var tmp;
                              if (value$6 == null) {
                                tmp = undefined;
                              } else {
                                var value$7 = value$6.logo;
                                tmp = {
                                  __typename: value$6.__typename,
                                  logo: !(value$7 == null) ? value$7 : undefined
                                };
                              }
                              return {
                                      __typename: value.__typename,
                                      _id: !(value$1 == null) ? Caml_option.some(value$1) : undefined,
                                      image: !(value$2 == null) ? value$2 : undefined,
                                      price: !(value$3 == null) ? value$3 : undefined,
                                      similarity: !(value$4 == null) ? value$4 : undefined,
                                      title: !(value$5 == null) ? value$5 : undefined,
                                      giftProvider: tmp
                                    };
                            }) : undefined,
                      category: !(value$2 == null) ? value$2 : undefined
                    };
            }) : undefined,
      purchases: !(value$3 == null) ? value$3.map(function (value) {
              if (value == null) {
                return ;
              }
              var value$1 = value.giftIdeaId;
              var value$2 = value.transactionId;
              var value$3 = value.date;
              var value$4 = value.targetYear;
              return {
                      __typename: value.__typename,
                      giftIdeaId: !(value$1 == null) ? Caml_option.some(value$1) : undefined,
                      transactionId: !(value$2 == null) ? Caml_option.some(value$2) : undefined,
                      date: !(value$3 == null) ? Caml_option.some(value$3) : undefined,
                      targetYear: !(value$4 == null) ? value$4 : undefined
                    };
            }) : undefined,
      userId: !(value$4 == null) ? Caml_option.some(value$4) : undefined,
      gifteeId: !(value$5 == null) ? Caml_option.some(value$5) : undefined,
      interests: !(value$6 == null) ? value$6.map(function (value) {
              if (!(value == null)) {
                return value;
              }
              
            }) : undefined
    };
  }
  return {
          giftProfile: tmp
        };
}

function serialize(value) {
  var value$1 = value.giftProfile;
  var giftProfile;
  if (value$1 !== undefined) {
    var value$2 = value$1.interests;
    var interests = value$2 !== undefined ? value$2.map(function (value) {
            if (value !== undefined) {
              return value;
            } else {
              return null;
            }
          }) : null;
    var value$3 = value$1.gifteeId;
    var gifteeId = value$3 !== undefined ? Caml_option.valFromOption(value$3) : null;
    var value$4 = value$1.userId;
    var userId = value$4 !== undefined ? Caml_option.valFromOption(value$4) : null;
    var value$5 = value$1.purchases;
    var purchases = value$5 !== undefined ? value$5.map(function (value) {
            if (value === undefined) {
              return null;
            }
            var value$1 = value.targetYear;
            var targetYear = value$1 !== undefined ? value$1 : null;
            var value$2 = value.date;
            var date = value$2 !== undefined ? Caml_option.valFromOption(value$2) : null;
            var value$3 = value.transactionId;
            var transactionId = value$3 !== undefined ? Caml_option.valFromOption(value$3) : null;
            var value$4 = value.giftIdeaId;
            var giftIdeaId = value$4 !== undefined ? Caml_option.valFromOption(value$4) : null;
            var value$5 = value.__typename;
            return {
                    __typename: value$5,
                    giftIdeaId: giftIdeaId,
                    transactionId: transactionId,
                    date: date,
                    targetYear: targetYear
                  };
          }) : null;
    var value$6 = value$1.recommendationsByCategory;
    var recommendationsByCategory = value$6 !== undefined ? value$6.map(function (value) {
            if (value === undefined) {
              return null;
            }
            var value$1 = value.category;
            var category = value$1 !== undefined ? value$1 : null;
            var value$2 = value.recommendations;
            var recommendations = value$2 !== undefined ? value$2.map(function (value) {
                    if (value === undefined) {
                      return null;
                    }
                    var value$1 = value.giftProvider;
                    var giftProvider;
                    if (value$1 !== undefined) {
                      var value$2 = value$1.logo;
                      var logo = value$2 !== undefined ? value$2 : null;
                      var value$3 = value$1.__typename;
                      giftProvider = {
                        __typename: value$3,
                        logo: logo
                      };
                    } else {
                      giftProvider = null;
                    }
                    var value$4 = value.title;
                    var title = value$4 !== undefined ? value$4 : null;
                    var value$5 = value.similarity;
                    var similarity = value$5 !== undefined ? value$5 : null;
                    var value$6 = value.price;
                    var price = value$6 !== undefined ? value$6 : null;
                    var value$7 = value.image;
                    var image = value$7 !== undefined ? value$7 : null;
                    var value$8 = value._id;
                    var _id = value$8 !== undefined ? Caml_option.valFromOption(value$8) : null;
                    var value$9 = value.__typename;
                    return {
                            __typename: value$9,
                            _id: _id,
                            image: image,
                            price: price,
                            similarity: similarity,
                            title: title,
                            giftProvider: giftProvider
                          };
                  }) : null;
            var value$3 = value.__typename;
            return {
                    __typename: value$3,
                    recommendations: recommendations,
                    category: category
                  };
          }) : null;
    var value$7 = value$1.__typename;
    giftProfile = {
      __typename: value$7,
      recommendationsByCategory: recommendationsByCategory,
      purchases: purchases,
      userId: userId,
      gifteeId: gifteeId,
      interests: interests
    };
  } else {
    giftProfile = null;
  }
  return {
          giftProfile: giftProfile
        };
}

function serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput(inp) {
  var a = inp.title_nin;
  var a$1 = inp.url;
  var a$2 = inp.title_ne;
  var a$3 = inp.title;
  var a$4 = inp.OR;
  var a$5 = inp.image_ne;
  var a$6 = inp.url_lte;
  var a$7 = inp.url_gt;
  var a$8 = inp.title_gt;
  var a$9 = inp.image_gte;
  var a$10 = inp.image_gt;
  var a$11 = inp.image_exists;
  var a$12 = inp.title_gte;
  var a$13 = inp.url_exists;
  var a$14 = inp.url_in;
  var a$15 = inp.title_lte;
  var a$16 = inp.title_in;
  var a$17 = inp.url_nin;
  var a$18 = inp.image_in;
  var a$19 = inp.url_gte;
  var a$20 = inp.image_nin;
  var a$21 = inp.image_lt;
  var a$22 = inp.image;
  var a$23 = inp.title_exists;
  var a$24 = inp.image_lte;
  var a$25 = inp.url_ne;
  var a$26 = inp.url_lt;
  var a$27 = inp.AND;
  var a$28 = inp.title_lt;
  return {
          title_nin: a !== undefined ? a.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          url: a$1 !== undefined ? a$1 : undefined,
          title_ne: a$2 !== undefined ? a$2 : undefined,
          title: a$3 !== undefined ? a$3 : undefined,
          OR: a$4 !== undefined ? a$4.map(serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput) : undefined,
          image_ne: a$5 !== undefined ? a$5 : undefined,
          url_lte: a$6 !== undefined ? a$6 : undefined,
          url_gt: a$7 !== undefined ? a$7 : undefined,
          title_gt: a$8 !== undefined ? a$8 : undefined,
          image_gte: a$9 !== undefined ? a$9 : undefined,
          image_gt: a$10 !== undefined ? a$10 : undefined,
          image_exists: a$11 !== undefined ? a$11 : undefined,
          title_gte: a$12 !== undefined ? a$12 : undefined,
          url_exists: a$13 !== undefined ? a$13 : undefined,
          url_in: a$14 !== undefined ? a$14.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          title_lte: a$15 !== undefined ? a$15 : undefined,
          title_in: a$16 !== undefined ? a$16.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          url_nin: a$17 !== undefined ? a$17.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image_in: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          url_gte: a$19 !== undefined ? a$19 : undefined,
          image_nin: a$20 !== undefined ? a$20.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image_lt: a$21 !== undefined ? a$21 : undefined,
          image: a$22 !== undefined ? a$22 : undefined,
          title_exists: a$23 !== undefined ? a$23 : undefined,
          image_lte: a$24 !== undefined ? a$24 : undefined,
          url_ne: a$25 !== undefined ? a$25 : undefined,
          url_lt: a$26 !== undefined ? a$26 : undefined,
          AND: a$27 !== undefined ? a$27.map(serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput) : undefined,
          title_lt: a$28 !== undefined ? a$28 : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput(inp) {
  var a = inp.logo_exists;
  var a$1 = inp.logo_in;
  var a$2 = inp.logo_ne;
  var a$3 = inp.name_exists;
  var a$4 = inp.logo_gte;
  var a$5 = inp.name_nin;
  var a$6 = inp.logo;
  var a$7 = inp.name_ne;
  var a$8 = inp.name_lte;
  var a$9 = inp.logo_gt;
  var a$10 = inp.name;
  var a$11 = inp.name_lt;
  var a$12 = inp.logo_lt;
  var a$13 = inp.name_gt;
  var a$14 = inp.logo_nin;
  var a$15 = inp.name_gte;
  var a$16 = inp.AND;
  var a$17 = inp.OR;
  var a$18 = inp.logo_lte;
  var a$19 = inp.name_in;
  return {
          logo_exists: a !== undefined ? a : undefined,
          logo_in: a$1 !== undefined ? a$1.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          logo_ne: a$2 !== undefined ? a$2 : undefined,
          name_exists: a$3 !== undefined ? a$3 : undefined,
          logo_gte: a$4 !== undefined ? a$4 : undefined,
          name_nin: a$5 !== undefined ? a$5.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          logo: a$6 !== undefined ? a$6 : undefined,
          name_ne: a$7 !== undefined ? a$7 : undefined,
          name_lte: a$8 !== undefined ? a$8 : undefined,
          logo_gt: a$9 !== undefined ? a$9 : undefined,
          name: a$10 !== undefined ? a$10 : undefined,
          name_lt: a$11 !== undefined ? a$11 : undefined,
          logo_lt: a$12 !== undefined ? a$12 : undefined,
          name_gt: a$13 !== undefined ? a$13 : undefined,
          logo_nin: a$14 !== undefined ? a$14.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          name_gte: a$15 !== undefined ? a$15 : undefined,
          AND: a$16 !== undefined ? a$16.map(serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput) : undefined,
          OR: a$17 !== undefined ? a$17.map(serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput) : undefined,
          logo_lte: a$18 !== undefined ? a$18 : undefined,
          name_in: a$19 !== undefined ? a$19.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined
        };
}

function serializeInputObjectGiftProfilePurchasesQueryInput(inp) {
  var a = inp.giftIdeaId_ne;
  var a$1 = inp.date_exists;
  var a$2 = inp.targetYear_gt;
  var a$3 = inp.targetYear_in;
  var a$4 = inp.OR;
  var a$5 = inp.date_gt;
  var a$6 = inp.transactionId_gt;
  var a$7 = inp.transactionId_lt;
  var a$8 = inp.AND;
  var a$9 = inp.date_in;
  var a$10 = inp.targetYear_gte;
  var a$11 = inp.transactionId;
  var a$12 = inp.giftIdeaId_lt;
  var a$13 = inp.transactionId_nin;
  var a$14 = inp.transactionId_in;
  var a$15 = inp.giftIdeaId_nin;
  var a$16 = inp.giftIdeaId_in;
  var a$17 = inp.giftIdeaId_gte;
  var a$18 = inp.targetYear_nin;
  var a$19 = inp.targetYear_lt;
  var a$20 = inp.transactionId_gte;
  var a$21 = inp.giftIdeaId_gt;
  var a$22 = inp.targetYear_ne;
  var a$23 = inp.targetYear;
  var a$24 = inp.date_nin;
  var a$25 = inp.date_gte;
  var a$26 = inp.giftIdeaId_exists;
  var a$27 = inp.targetYear_exists;
  var a$28 = inp.transactionId_lte;
  var a$29 = inp.transactionId_ne;
  var a$30 = inp.transactionId_exists;
  var a$31 = inp.targetYear_lte;
  var a$32 = inp.date_lt;
  var a$33 = inp.date_ne;
  var a$34 = inp.giftIdeaId;
  var a$35 = inp.giftIdeaId_lte;
  var a$36 = inp.date_lte;
  var a$37 = inp.date;
  return {
          giftIdeaId_ne: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          date_exists: a$1 !== undefined ? a$1 : undefined,
          targetYear_gt: a$2 !== undefined ? a$2 : undefined,
          targetYear_in: a$3 !== undefined ? a$3.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          OR: a$4 !== undefined ? a$4.map(serializeInputObjectGiftProfilePurchasesQueryInput) : undefined,
          date_gt: a$5 !== undefined ? Caml_option.valFromOption(a$5) : undefined,
          transactionId_gt: a$6 !== undefined ? Caml_option.valFromOption(a$6) : undefined,
          transactionId_lt: a$7 !== undefined ? Caml_option.valFromOption(a$7) : undefined,
          AND: a$8 !== undefined ? a$8.map(serializeInputObjectGiftProfilePurchasesQueryInput) : undefined,
          date_in: a$9 !== undefined ? a$9.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          targetYear_gte: a$10 !== undefined ? a$10 : undefined,
          transactionId: a$11 !== undefined ? Caml_option.valFromOption(a$11) : undefined,
          giftIdeaId_lt: a$12 !== undefined ? Caml_option.valFromOption(a$12) : undefined,
          transactionId_nin: a$13 !== undefined ? a$13.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          transactionId_in: a$14 !== undefined ? a$14.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          giftIdeaId_nin: a$15 !== undefined ? a$15.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          giftIdeaId_in: a$16 !== undefined ? a$16.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          giftIdeaId_gte: a$17 !== undefined ? Caml_option.valFromOption(a$17) : undefined,
          targetYear_nin: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          targetYear_lt: a$19 !== undefined ? a$19 : undefined,
          transactionId_gte: a$20 !== undefined ? Caml_option.valFromOption(a$20) : undefined,
          giftIdeaId_gt: a$21 !== undefined ? Caml_option.valFromOption(a$21) : undefined,
          targetYear_ne: a$22 !== undefined ? a$22 : undefined,
          targetYear: a$23 !== undefined ? a$23 : undefined,
          date_nin: a$24 !== undefined ? a$24.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          date_gte: a$25 !== undefined ? Caml_option.valFromOption(a$25) : undefined,
          giftIdeaId_exists: a$26 !== undefined ? a$26 : undefined,
          targetYear_exists: a$27 !== undefined ? a$27 : undefined,
          transactionId_lte: a$28 !== undefined ? Caml_option.valFromOption(a$28) : undefined,
          transactionId_ne: a$29 !== undefined ? Caml_option.valFromOption(a$29) : undefined,
          transactionId_exists: a$30 !== undefined ? a$30 : undefined,
          targetYear_lte: a$31 !== undefined ? a$31 : undefined,
          date_lt: a$32 !== undefined ? Caml_option.valFromOption(a$32) : undefined,
          date_ne: a$33 !== undefined ? Caml_option.valFromOption(a$33) : undefined,
          giftIdeaId: a$34 !== undefined ? Caml_option.valFromOption(a$34) : undefined,
          giftIdeaId_lte: a$35 !== undefined ? Caml_option.valFromOption(a$35) : undefined,
          date_lte: a$36 !== undefined ? Caml_option.valFromOption(a$36) : undefined,
          date: a$37 !== undefined ? Caml_option.valFromOption(a$37) : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput(inp) {
  var a = inp.likedProducts_nin;
  var a$1 = inp.likedProducts_in;
  var a$2 = inp.recommendations_nin;
  var a$3 = inp.category_in;
  var a$4 = inp.likedProducts;
  var a$5 = inp.likedProducts_exists;
  var a$6 = inp.AND;
  var a$7 = inp.category_ne;
  var a$8 = inp.category_gt;
  var a$9 = inp.category_exists;
  var a$10 = inp.category_gte;
  var a$11 = inp.OR;
  var a$12 = inp.category_lte;
  var a$13 = inp.recommendations_exists;
  var a$14 = inp.category_lt;
  var a$15 = inp.recommendations_in;
  var a$16 = inp.recommendations;
  var a$17 = inp.category;
  var a$18 = inp.category_nin;
  return {
          likedProducts_nin: a !== undefined ? a.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput(b);
                  }
                  
                }) : undefined,
          likedProducts_in: a$1 !== undefined ? a$1.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput(b);
                  }
                  
                }) : undefined,
          recommendations_nin: a$2 !== undefined ? a$2.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput(b);
                  }
                  
                }) : undefined,
          category_in: a$3 !== undefined ? a$3.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          likedProducts: a$4 !== undefined ? a$4.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput(b);
                  }
                  
                }) : undefined,
          likedProducts_exists: a$5 !== undefined ? a$5 : undefined,
          AND: a$6 !== undefined ? a$6.map(serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput) : undefined,
          category_ne: a$7 !== undefined ? a$7 : undefined,
          category_gt: a$8 !== undefined ? a$8 : undefined,
          category_exists: a$9 !== undefined ? a$9 : undefined,
          category_gte: a$10 !== undefined ? a$10 : undefined,
          OR: a$11 !== undefined ? a$11.map(serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput) : undefined,
          category_lte: a$12 !== undefined ? a$12 : undefined,
          recommendations_exists: a$13 !== undefined ? a$13 : undefined,
          category_lt: a$14 !== undefined ? a$14 : undefined,
          recommendations_in: a$15 !== undefined ? a$15.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput(b);
                  }
                  
                }) : undefined,
          recommendations: a$16 !== undefined ? a$16.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput(b);
                  }
                  
                }) : undefined,
          category: a$17 !== undefined ? a$17 : undefined,
          category_nin: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsQueryInput(inp) {
  var a = inp.similarity_gt;
  var a$1 = inp.similarity_lt;
  var a$2 = inp.OR;
  var a$3 = inp.interest_gt;
  var a$4 = inp.title_lte;
  var a$5 = inp.title_gt;
  var a$6 = inp.title_nin;
  var a$7 = inp.giftSource_nin;
  var a$8 = inp.title_exists;
  var a$9 = inp.giftSource_ne;
  var a$10 = inp.url_in;
  var a$11 = inp._id_lte;
  var a$12 = inp.price_exists;
  var a$13 = inp.similarity_exists;
  var a$14 = inp._id_gt;
  var a$15 = inp.similarity_ne;
  var a$16 = inp.price_ne;
  var a$17 = inp.interest_nin;
  var a$18 = inp.similarity_nin;
  var a$19 = inp.avgReview_gt;
  var a$20 = inp.interest_lte;
  var a$21 = inp.price_gt;
  var a$22 = inp.url_lt;
  var a$23 = inp.giftSource_exists;
  var a$24 = inp._id_gte;
  var a$25 = inp._id_in;
  var a$26 = inp.interest_gte;
  var a$27 = inp.price;
  var a$28 = inp.title_gte;
  var a$29 = inp.price_lt;
  var a$30 = inp.image_in;
  var a$31 = inp.image_lt;
  var a$32 = inp.interest_ne;
  var a$33 = inp.giftSource_lte;
  var a$34 = inp.avgReview_nin;
  var a$35 = inp.similarity_in;
  var a$36 = inp.url_lte;
  var a$37 = inp._id_lt;
  var a$38 = inp.price_lte;
  var a$39 = inp.title;
  var a$40 = inp.url_gte;
  var a$41 = inp.url_ne;
  var a$42 = inp.avgReview_lt;
  var a$43 = inp.price_gte;
  var a$44 = inp.similarity_lte;
  var a$45 = inp.image_ne;
  var a$46 = inp.description_lt;
  var a$47 = inp.giftProvider;
  var a$48 = inp._id_exists;
  var a$49 = inp.description_gt;
  var a$50 = inp._id_ne;
  var a$51 = inp.interest_exists;
  var a$52 = inp.avgReview;
  var a$53 = inp.giftSource_gte;
  var a$54 = inp.interest;
  var a$55 = inp.url_gt;
  var a$56 = inp.image_gt;
  var a$57 = inp.AND;
  var a$58 = inp.avgReview_in;
  var a$59 = inp.image;
  var a$60 = inp.avgReview_exists;
  var a$61 = inp.description_lte;
  var a$62 = inp._id_nin;
  var a$63 = inp.image_nin;
  var a$64 = inp.similarity;
  var a$65 = inp.avgReview_gte;
  var a$66 = inp.associatedProducts_exists;
  var a$67 = inp.associatedProducts_in;
  var a$68 = inp.giftSource;
  var a$69 = inp.description_gte;
  var a$70 = inp.description_nin;
  var a$71 = inp.description_exists;
  var a$72 = inp.title_in;
  var a$73 = inp.similarity_gte;
  var a$74 = inp.giftSource_gt;
  var a$75 = inp.url_nin;
  var a$76 = inp.interest_lt;
  var a$77 = inp.giftProvider_exists;
  var a$78 = inp.avgReview_lte;
  var a$79 = inp._id;
  var a$80 = inp.title_lt;
  var a$81 = inp.avgReview_ne;
  var a$82 = inp.image_exists;
  var a$83 = inp.price_in;
  var a$84 = inp.title_ne;
  var a$85 = inp.interest_in;
  var a$86 = inp.giftSource_lt;
  var a$87 = inp.description_in;
  var a$88 = inp.description_ne;
  var a$89 = inp.url;
  var a$90 = inp.url_exists;
  var a$91 = inp.image_gte;
  var a$92 = inp.giftSource_in;
  var a$93 = inp.associatedProducts;
  var a$94 = inp.associatedProducts_nin;
  var a$95 = inp.price_nin;
  var a$96 = inp.description;
  var a$97 = inp.image_lte;
  return {
          similarity_gt: a !== undefined ? a : undefined,
          similarity_lt: a$1 !== undefined ? a$1 : undefined,
          OR: a$2 !== undefined ? a$2.map(serializeInputObjectGiftProfileRecommendationsQueryInput) : undefined,
          interest_gt: a$3 !== undefined ? a$3 : undefined,
          title_lte: a$4 !== undefined ? a$4 : undefined,
          title_gt: a$5 !== undefined ? a$5 : undefined,
          title_nin: a$6 !== undefined ? a$6.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          giftSource_nin: a$7 !== undefined ? a$7.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          title_exists: a$8 !== undefined ? a$8 : undefined,
          giftSource_ne: a$9 !== undefined ? a$9 : undefined,
          url_in: a$10 !== undefined ? a$10.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          _id_lte: a$11 !== undefined ? Caml_option.valFromOption(a$11) : undefined,
          price_exists: a$12 !== undefined ? a$12 : undefined,
          similarity_exists: a$13 !== undefined ? a$13 : undefined,
          _id_gt: a$14 !== undefined ? Caml_option.valFromOption(a$14) : undefined,
          similarity_ne: a$15 !== undefined ? a$15 : undefined,
          price_ne: a$16 !== undefined ? a$16 : undefined,
          interest_nin: a$17 !== undefined ? a$17.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          similarity_nin: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          avgReview_gt: a$19 !== undefined ? a$19 : undefined,
          interest_lte: a$20 !== undefined ? a$20 : undefined,
          price_gt: a$21 !== undefined ? a$21 : undefined,
          url_lt: a$22 !== undefined ? a$22 : undefined,
          giftSource_exists: a$23 !== undefined ? a$23 : undefined,
          _id_gte: a$24 !== undefined ? Caml_option.valFromOption(a$24) : undefined,
          _id_in: a$25 !== undefined ? a$25.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          interest_gte: a$26 !== undefined ? a$26 : undefined,
          price: a$27 !== undefined ? a$27 : undefined,
          title_gte: a$28 !== undefined ? a$28 : undefined,
          price_lt: a$29 !== undefined ? a$29 : undefined,
          image_in: a$30 !== undefined ? a$30.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image_lt: a$31 !== undefined ? a$31 : undefined,
          interest_ne: a$32 !== undefined ? a$32 : undefined,
          giftSource_lte: a$33 !== undefined ? a$33 : undefined,
          avgReview_nin: a$34 !== undefined ? a$34.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          similarity_in: a$35 !== undefined ? a$35.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          url_lte: a$36 !== undefined ? a$36 : undefined,
          _id_lt: a$37 !== undefined ? Caml_option.valFromOption(a$37) : undefined,
          price_lte: a$38 !== undefined ? a$38 : undefined,
          title: a$39 !== undefined ? a$39 : undefined,
          url_gte: a$40 !== undefined ? a$40 : undefined,
          url_ne: a$41 !== undefined ? a$41 : undefined,
          avgReview_lt: a$42 !== undefined ? a$42 : undefined,
          price_gte: a$43 !== undefined ? a$43 : undefined,
          similarity_lte: a$44 !== undefined ? a$44 : undefined,
          image_ne: a$45 !== undefined ? a$45 : undefined,
          description_lt: a$46 !== undefined ? a$46 : undefined,
          giftProvider: a$47 !== undefined ? serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput(a$47) : undefined,
          _id_exists: a$48 !== undefined ? a$48 : undefined,
          description_gt: a$49 !== undefined ? a$49 : undefined,
          _id_ne: a$50 !== undefined ? Caml_option.valFromOption(a$50) : undefined,
          interest_exists: a$51 !== undefined ? a$51 : undefined,
          avgReview: a$52 !== undefined ? a$52 : undefined,
          giftSource_gte: a$53 !== undefined ? a$53 : undefined,
          interest: a$54 !== undefined ? a$54 : undefined,
          url_gt: a$55 !== undefined ? a$55 : undefined,
          image_gt: a$56 !== undefined ? a$56 : undefined,
          AND: a$57 !== undefined ? a$57.map(serializeInputObjectGiftProfileRecommendationsQueryInput) : undefined,
          avgReview_in: a$58 !== undefined ? a$58.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image: a$59 !== undefined ? a$59 : undefined,
          avgReview_exists: a$60 !== undefined ? a$60 : undefined,
          description_lte: a$61 !== undefined ? a$61 : undefined,
          _id_nin: a$62 !== undefined ? a$62.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          image_nin: a$63 !== undefined ? a$63.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          similarity: a$64 !== undefined ? a$64 : undefined,
          avgReview_gte: a$65 !== undefined ? a$65 : undefined,
          associatedProducts_exists: a$66 !== undefined ? a$66 : undefined,
          associatedProducts_in: a$67 !== undefined ? a$67.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput(b);
                  }
                  
                }) : undefined,
          giftSource: a$68 !== undefined ? a$68 : undefined,
          description_gte: a$69 !== undefined ? a$69 : undefined,
          description_nin: a$70 !== undefined ? a$70.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          description_exists: a$71 !== undefined ? a$71 : undefined,
          title_in: a$72 !== undefined ? a$72.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          similarity_gte: a$73 !== undefined ? a$73 : undefined,
          giftSource_gt: a$74 !== undefined ? a$74 : undefined,
          url_nin: a$75 !== undefined ? a$75.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          interest_lt: a$76 !== undefined ? a$76 : undefined,
          giftProvider_exists: a$77 !== undefined ? a$77 : undefined,
          avgReview_lte: a$78 !== undefined ? a$78 : undefined,
          _id: a$79 !== undefined ? Caml_option.valFromOption(a$79) : undefined,
          title_lt: a$80 !== undefined ? a$80 : undefined,
          avgReview_ne: a$81 !== undefined ? a$81 : undefined,
          image_exists: a$82 !== undefined ? a$82 : undefined,
          price_in: a$83 !== undefined ? a$83.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          title_ne: a$84 !== undefined ? a$84 : undefined,
          interest_in: a$85 !== undefined ? a$85.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          giftSource_lt: a$86 !== undefined ? a$86 : undefined,
          description_in: a$87 !== undefined ? a$87.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          description_ne: a$88 !== undefined ? a$88 : undefined,
          url: a$89 !== undefined ? a$89 : undefined,
          url_exists: a$90 !== undefined ? a$90 : undefined,
          image_gte: a$91 !== undefined ? a$91 : undefined,
          giftSource_in: a$92 !== undefined ? a$92.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          associatedProducts: a$93 !== undefined ? a$93.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput(b);
                  }
                  
                }) : undefined,
          associatedProducts_nin: a$94 !== undefined ? a$94.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput(b);
                  }
                  
                }) : undefined,
          price_nin: a$95 !== undefined ? a$95.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          description: a$96 !== undefined ? a$96 : undefined,
          image_lte: a$97 !== undefined ? a$97 : undefined
        };
}

function serializeInputObjectGiftProfileProfileQueryInput(inp) {
  var a = inp.interest;
  var a$1 = inp.productId_lt;
  var a$2 = inp.productId;
  var a$3 = inp.title_lte;
  var a$4 = inp.title_gte;
  var a$5 = inp.weight_gte;
  var a$6 = inp.interest_in;
  var a$7 = inp.productId_exists;
  var a$8 = inp.weight_exists;
  var a$9 = inp.weight_ne;
  var a$10 = inp.title_lt;
  var a$11 = inp.interest_gt;
  var a$12 = inp.weight_gt;
  var a$13 = inp.productId_in;
  var a$14 = inp.productId_ne;
  var a$15 = inp.image_lte;
  var a$16 = inp.weight_in;
  var a$17 = inp.OR;
  var a$18 = inp.image_gt;
  var a$19 = inp.AND;
  var a$20 = inp.title_gt;
  var a$21 = inp.weight;
  var a$22 = inp.interest_lte;
  var a$23 = inp.productId_gt;
  var a$24 = inp.interest_gte;
  var a$25 = inp.image_nin;
  var a$26 = inp.interest_nin;
  var a$27 = inp.title;
  var a$28 = inp.interest_lt;
  var a$29 = inp.weight_lte;
  var a$30 = inp.image_gte;
  var a$31 = inp.interest_exists;
  var a$32 = inp.productId_nin;
  var a$33 = inp.interest_ne;
  var a$34 = inp.title_exists;
  var a$35 = inp.title_ne;
  var a$36 = inp.title_in;
  var a$37 = inp.weight_lt;
  var a$38 = inp.image_ne;
  var a$39 = inp.weight_nin;
  var a$40 = inp.image_lt;
  var a$41 = inp.productId_gte;
  var a$42 = inp.image_in;
  var a$43 = inp.productId_lte;
  var a$44 = inp.image_exists;
  var a$45 = inp.title_nin;
  var a$46 = inp.image;
  return {
          interest: a !== undefined ? a : undefined,
          productId_lt: a$1 !== undefined ? a$1 : undefined,
          productId: a$2 !== undefined ? a$2 : undefined,
          title_lte: a$3 !== undefined ? a$3 : undefined,
          title_gte: a$4 !== undefined ? a$4 : undefined,
          weight_gte: a$5 !== undefined ? Caml_option.valFromOption(a$5) : undefined,
          interest_in: a$6 !== undefined ? a$6.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          productId_exists: a$7 !== undefined ? a$7 : undefined,
          weight_exists: a$8 !== undefined ? a$8 : undefined,
          weight_ne: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          title_lt: a$10 !== undefined ? a$10 : undefined,
          interest_gt: a$11 !== undefined ? a$11 : undefined,
          weight_gt: a$12 !== undefined ? Caml_option.valFromOption(a$12) : undefined,
          productId_in: a$13 !== undefined ? a$13.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          productId_ne: a$14 !== undefined ? a$14 : undefined,
          image_lte: a$15 !== undefined ? a$15 : undefined,
          weight_in: a$16 !== undefined ? a$16.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          OR: a$17 !== undefined ? a$17.map(serializeInputObjectGiftProfileProfileQueryInput) : undefined,
          image_gt: a$18 !== undefined ? a$18 : undefined,
          AND: a$19 !== undefined ? a$19.map(serializeInputObjectGiftProfileProfileQueryInput) : undefined,
          title_gt: a$20 !== undefined ? a$20 : undefined,
          weight: a$21 !== undefined ? Caml_option.valFromOption(a$21) : undefined,
          interest_lte: a$22 !== undefined ? a$22 : undefined,
          productId_gt: a$23 !== undefined ? a$23 : undefined,
          interest_gte: a$24 !== undefined ? a$24 : undefined,
          image_nin: a$25 !== undefined ? a$25.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          interest_nin: a$26 !== undefined ? a$26.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          title: a$27 !== undefined ? a$27 : undefined,
          interest_lt: a$28 !== undefined ? a$28 : undefined,
          weight_lte: a$29 !== undefined ? Caml_option.valFromOption(a$29) : undefined,
          image_gte: a$30 !== undefined ? a$30 : undefined,
          interest_exists: a$31 !== undefined ? a$31 : undefined,
          productId_nin: a$32 !== undefined ? a$32.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          interest_ne: a$33 !== undefined ? a$33 : undefined,
          title_exists: a$34 !== undefined ? a$34 : undefined,
          title_ne: a$35 !== undefined ? a$35 : undefined,
          title_in: a$36 !== undefined ? a$36.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          weight_lt: a$37 !== undefined ? Caml_option.valFromOption(a$37) : undefined,
          image_ne: a$38 !== undefined ? a$38 : undefined,
          weight_nin: a$39 !== undefined ? a$39.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          image_lt: a$40 !== undefined ? a$40 : undefined,
          productId_gte: a$41 !== undefined ? a$41 : undefined,
          image_in: a$42 !== undefined ? a$42.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          productId_lte: a$43 !== undefined ? a$43 : undefined,
          image_exists: a$44 !== undefined ? a$44 : undefined,
          title_nin: a$45 !== undefined ? a$45.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image: a$46 !== undefined ? a$46 : undefined
        };
}

function serializeInputObjectGiftProfileQueryInput(inp) {
  var a = inp.recommendations_exists;
  var a$1 = inp.recommendationsByCategory_nin;
  var a$2 = inp.createdAt_gt;
  var a$3 = inp.recommendationsByCategory_exists;
  var a$4 = inp.updatedAt_lte;
  var a$5 = inp.createdAt_gte;
  var a$6 = inp.priceMin_lt;
  var a$7 = inp.gender_in;
  var a$8 = inp.createdAt_exists;
  var a$9 = inp.userId;
  var a$10 = inp.AND;
  var a$11 = inp._id_ne;
  var a$12 = inp._id;
  var a$13 = inp.createdAt_lte;
  var a$14 = inp.priceMax_lte;
  var a$15 = inp.purchases_in;
  var a$16 = inp._id_gte;
  var a$17 = inp.priceMin_gte;
  var a$18 = inp.gifteeId_lt;
  var a$19 = inp.interests;
  var a$20 = inp.priceMin_gt;
  var a$21 = inp.userId_exists;
  var a$22 = inp.profile_nin;
  var a$23 = inp.updatedAt_nin;
  var a$24 = inp.OR;
  var a$25 = inp.gifteeId_lte;
  var a$26 = inp.updatedAt_exists;
  var a$27 = inp.priceMin_lte;
  var a$28 = inp._id_nin;
  var a$29 = inp.updatedAt_ne;
  var a$30 = inp._id_in;
  var a$31 = inp.priceMax_nin;
  var a$32 = inp.gifteeId_exists;
  var a$33 = inp.priceMin_nin;
  var a$34 = inp.createdAt_lt;
  var a$35 = inp.priceMax_exists;
  var a$36 = inp.recommendationsByCategory_in;
  var a$37 = inp._id_lt;
  var a$38 = inp.priceMax;
  var a$39 = inp.userId_in;
  var a$40 = inp.createdAt_nin;
  var a$41 = inp.gender_nin;
  var a$42 = inp.recommendations_in;
  var a$43 = inp.userId_ne;
  var a$44 = inp.priceMin;
  var a$45 = inp._id_lte;
  var a$46 = inp._id_gt;
  var a$47 = inp.priceMax_ne;
  var a$48 = inp.updatedAt;
  var a$49 = inp.gifteeId_gt;
  var a$50 = inp.updatedAt_gt;
  var a$51 = inp.updatedAt_gte;
  var a$52 = inp.userId_nin;
  var a$53 = inp.purchases;
  var a$54 = inp.gifteeId_gte;
  var a$55 = inp.recommendationsByCategory;
  var a$56 = inp.gender_ne;
  var a$57 = inp.userId_lte;
  var a$58 = inp.createdAt_in;
  var a$59 = inp.priceMax_lt;
  var a$60 = inp.gifteeId_ne;
  var a$61 = inp.purchases_exists;
  var a$62 = inp.priceMax_in;
  var a$63 = inp.profile_exists;
  var a$64 = inp.userId_gt;
  var a$65 = inp.recommendations_nin;
  var a$66 = inp.gender_exists;
  var a$67 = inp.gender_lte;
  var a$68 = inp.profile;
  var a$69 = inp.priceMin_ne;
  var a$70 = inp.gender;
  var a$71 = inp.priceMin_exists;
  var a$72 = inp.gender_lt;
  var a$73 = inp.createdAt;
  var a$74 = inp.gifteeId_nin;
  var a$75 = inp._id_exists;
  var a$76 = inp.gifteeId;
  var a$77 = inp.gender_gt;
  var a$78 = inp.updatedAt_in;
  var a$79 = inp.userId_lt;
  var a$80 = inp.priceMax_gt;
  var a$81 = inp.gender_gte;
  var a$82 = inp.purchases_nin;
  var a$83 = inp.gifteeId_in;
  var a$84 = inp.priceMax_gte;
  var a$85 = inp.interests_nin;
  var a$86 = inp.profile_in;
  var a$87 = inp.userId_gte;
  var a$88 = inp.interests_exists;
  var a$89 = inp.priceMin_in;
  var a$90 = inp.updatedAt_lt;
  var a$91 = inp.createdAt_ne;
  var a$92 = inp.recommendations;
  var a$93 = inp.interests_in;
  return {
          recommendations_exists: a !== undefined ? a : undefined,
          recommendationsByCategory_nin: a$1 !== undefined ? a$1.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput(b);
                  }
                  
                }) : undefined,
          createdAt_gt: a$2 !== undefined ? Caml_option.valFromOption(a$2) : undefined,
          recommendationsByCategory_exists: a$3 !== undefined ? a$3 : undefined,
          updatedAt_lte: a$4 !== undefined ? Caml_option.valFromOption(a$4) : undefined,
          createdAt_gte: a$5 !== undefined ? Caml_option.valFromOption(a$5) : undefined,
          priceMin_lt: a$6 !== undefined ? a$6 : undefined,
          gender_in: a$7 !== undefined ? a$7.map(function (b) {
                  if (b !== undefined) {
                    if (b === "UNISEX") {
                      return "UNISEX";
                    } else if (b === "MALE") {
                      return "MALE";
                    } else {
                      return "FEMALE";
                    }
                  }
                  
                }) : undefined,
          createdAt_exists: a$8 !== undefined ? a$8 : undefined,
          userId: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          AND: a$10 !== undefined ? a$10.map(serializeInputObjectGiftProfileQueryInput) : undefined,
          _id_ne: a$11 !== undefined ? Caml_option.valFromOption(a$11) : undefined,
          _id: a$12 !== undefined ? Caml_option.valFromOption(a$12) : undefined,
          createdAt_lte: a$13 !== undefined ? Caml_option.valFromOption(a$13) : undefined,
          priceMax_lte: a$14 !== undefined ? a$14 : undefined,
          purchases_in: a$15 !== undefined ? a$15.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfilePurchasesQueryInput(b);
                  }
                  
                }) : undefined,
          _id_gte: a$16 !== undefined ? Caml_option.valFromOption(a$16) : undefined,
          priceMin_gte: a$17 !== undefined ? a$17 : undefined,
          gifteeId_lt: a$18 !== undefined ? Caml_option.valFromOption(a$18) : undefined,
          interests: a$19 !== undefined ? a$19.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          priceMin_gt: a$20 !== undefined ? a$20 : undefined,
          userId_exists: a$21 !== undefined ? a$21 : undefined,
          profile_nin: a$22 !== undefined ? a$22.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileProfileQueryInput(b);
                  }
                  
                }) : undefined,
          updatedAt_nin: a$23 !== undefined ? a$23.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          OR: a$24 !== undefined ? a$24.map(serializeInputObjectGiftProfileQueryInput) : undefined,
          gifteeId_lte: a$25 !== undefined ? Caml_option.valFromOption(a$25) : undefined,
          updatedAt_exists: a$26 !== undefined ? a$26 : undefined,
          priceMin_lte: a$27 !== undefined ? a$27 : undefined,
          _id_nin: a$28 !== undefined ? a$28.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          updatedAt_ne: a$29 !== undefined ? Caml_option.valFromOption(a$29) : undefined,
          _id_in: a$30 !== undefined ? a$30.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          priceMax_nin: a$31 !== undefined ? a$31.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          gifteeId_exists: a$32 !== undefined ? a$32 : undefined,
          priceMin_nin: a$33 !== undefined ? a$33.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          createdAt_lt: a$34 !== undefined ? Caml_option.valFromOption(a$34) : undefined,
          priceMax_exists: a$35 !== undefined ? a$35 : undefined,
          recommendationsByCategory_in: a$36 !== undefined ? a$36.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput(b);
                  }
                  
                }) : undefined,
          _id_lt: a$37 !== undefined ? Caml_option.valFromOption(a$37) : undefined,
          priceMax: a$38 !== undefined ? a$38 : undefined,
          userId_in: a$39 !== undefined ? a$39.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          createdAt_nin: a$40 !== undefined ? a$40.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          gender_nin: a$41 !== undefined ? a$41.map(function (b) {
                  if (b !== undefined) {
                    if (b === "UNISEX") {
                      return "UNISEX";
                    } else if (b === "MALE") {
                      return "MALE";
                    } else {
                      return "FEMALE";
                    }
                  }
                  
                }) : undefined,
          recommendations_in: a$42 !== undefined ? a$42.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsQueryInput(b);
                  }
                  
                }) : undefined,
          userId_ne: a$43 !== undefined ? Caml_option.valFromOption(a$43) : undefined,
          priceMin: a$44 !== undefined ? a$44 : undefined,
          _id_lte: a$45 !== undefined ? Caml_option.valFromOption(a$45) : undefined,
          _id_gt: a$46 !== undefined ? Caml_option.valFromOption(a$46) : undefined,
          priceMax_ne: a$47 !== undefined ? a$47 : undefined,
          updatedAt: a$48 !== undefined ? Caml_option.valFromOption(a$48) : undefined,
          gifteeId_gt: a$49 !== undefined ? Caml_option.valFromOption(a$49) : undefined,
          updatedAt_gt: a$50 !== undefined ? Caml_option.valFromOption(a$50) : undefined,
          updatedAt_gte: a$51 !== undefined ? Caml_option.valFromOption(a$51) : undefined,
          userId_nin: a$52 !== undefined ? a$52.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          purchases: a$53 !== undefined ? a$53.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfilePurchasesQueryInput(b);
                  }
                  
                }) : undefined,
          gifteeId_gte: a$54 !== undefined ? Caml_option.valFromOption(a$54) : undefined,
          recommendationsByCategory: a$55 !== undefined ? a$55.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput(b);
                  }
                  
                }) : undefined,
          gender_ne: a$56 !== undefined ? (
              a$56 === "UNISEX" ? "UNISEX" : (
                  a$56 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          userId_lte: a$57 !== undefined ? Caml_option.valFromOption(a$57) : undefined,
          createdAt_in: a$58 !== undefined ? a$58.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          priceMax_lt: a$59 !== undefined ? a$59 : undefined,
          gifteeId_ne: a$60 !== undefined ? Caml_option.valFromOption(a$60) : undefined,
          purchases_exists: a$61 !== undefined ? a$61 : undefined,
          priceMax_in: a$62 !== undefined ? a$62.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          profile_exists: a$63 !== undefined ? a$63 : undefined,
          userId_gt: a$64 !== undefined ? Caml_option.valFromOption(a$64) : undefined,
          recommendations_nin: a$65 !== undefined ? a$65.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsQueryInput(b);
                  }
                  
                }) : undefined,
          gender_exists: a$66 !== undefined ? a$66 : undefined,
          gender_lte: a$67 !== undefined ? (
              a$67 === "UNISEX" ? "UNISEX" : (
                  a$67 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          profile: a$68 !== undefined ? a$68.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileProfileQueryInput(b);
                  }
                  
                }) : undefined,
          priceMin_ne: a$69 !== undefined ? a$69 : undefined,
          gender: a$70 !== undefined ? (
              a$70 === "UNISEX" ? "UNISEX" : (
                  a$70 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          priceMin_exists: a$71 !== undefined ? a$71 : undefined,
          gender_lt: a$72 !== undefined ? (
              a$72 === "UNISEX" ? "UNISEX" : (
                  a$72 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          createdAt: a$73 !== undefined ? Caml_option.valFromOption(a$73) : undefined,
          gifteeId_nin: a$74 !== undefined ? a$74.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          _id_exists: a$75 !== undefined ? a$75 : undefined,
          gifteeId: a$76 !== undefined ? Caml_option.valFromOption(a$76) : undefined,
          gender_gt: a$77 !== undefined ? (
              a$77 === "UNISEX" ? "UNISEX" : (
                  a$77 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          updatedAt_in: a$78 !== undefined ? a$78.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          userId_lt: a$79 !== undefined ? Caml_option.valFromOption(a$79) : undefined,
          priceMax_gt: a$80 !== undefined ? a$80 : undefined,
          gender_gte: a$81 !== undefined ? (
              a$81 === "UNISEX" ? "UNISEX" : (
                  a$81 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          purchases_nin: a$82 !== undefined ? a$82.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfilePurchasesQueryInput(b);
                  }
                  
                }) : undefined,
          gifteeId_in: a$83 !== undefined ? a$83.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          priceMax_gte: a$84 !== undefined ? a$84 : undefined,
          interests_nin: a$85 !== undefined ? a$85.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          profile_in: a$86 !== undefined ? a$86.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileProfileQueryInput(b);
                  }
                  
                }) : undefined,
          userId_gte: a$87 !== undefined ? Caml_option.valFromOption(a$87) : undefined,
          interests_exists: a$88 !== undefined ? a$88 : undefined,
          priceMin_in: a$89 !== undefined ? a$89.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          updatedAt_lt: a$90 !== undefined ? Caml_option.valFromOption(a$90) : undefined,
          createdAt_ne: a$91 !== undefined ? Caml_option.valFromOption(a$91) : undefined,
          recommendations: a$92 !== undefined ? a$92.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsQueryInput(b);
                  }
                  
                }) : undefined,
          interests_in: a$93 !== undefined ? a$93.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput(inp) {
  var a = inp.image_lt;
  var a$1 = inp.title_nin;
  var a$2 = inp.price;
  var a$3 = inp._id_in;
  var a$4 = inp.price_exists;
  var a$5 = inp.price_gt;
  var a$6 = inp.similarity_nin;
  var a$7 = inp._id_nin;
  var a$8 = inp.title_lt;
  var a$9 = inp._id_gte;
  var a$10 = inp.title_in;
  var a$11 = inp.image_nin;
  var a$12 = inp.image_ne;
  var a$13 = inp.image_lte;
  var a$14 = inp._id_lte;
  var a$15 = inp.AND;
  var a$16 = inp.price_lt;
  var a$17 = inp.price_ne;
  var a$18 = inp._id_lt;
  var a$19 = inp.similarity_lt;
  var a$20 = inp.price_nin;
  var a$21 = inp.similarity;
  var a$22 = inp.image_in;
  var a$23 = inp._id_ne;
  var a$24 = inp.title_gte;
  var a$25 = inp.title_gt;
  var a$26 = inp.price_in;
  var a$27 = inp.title_ne;
  var a$28 = inp.title_lte;
  var a$29 = inp.similarity_ne;
  var a$30 = inp.price_gte;
  var a$31 = inp._id_exists;
  var a$32 = inp.image_exists;
  var a$33 = inp.similarity_lte;
  var a$34 = inp.similarity_exists;
  var a$35 = inp.title;
  var a$36 = inp.title_exists;
  var a$37 = inp._id_gt;
  var a$38 = inp.similarity_in;
  var a$39 = inp.giftProvider_exists;
  var a$40 = inp.giftProvider;
  var a$41 = inp.OR;
  var a$42 = inp._id;
  var a$43 = inp.image_gte;
  var a$44 = inp.image;
  var a$45 = inp.price_lte;
  var a$46 = inp.similarity_gt;
  var a$47 = inp.similarity_gte;
  var a$48 = inp.image_gt;
  return {
          image_lt: a !== undefined ? a : undefined,
          title_nin: a$1 !== undefined ? a$1.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          price: a$2 !== undefined ? a$2 : undefined,
          _id_in: a$3 !== undefined ? a$3.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          price_exists: a$4 !== undefined ? a$4 : undefined,
          price_gt: a$5 !== undefined ? a$5 : undefined,
          similarity_nin: a$6 !== undefined ? a$6.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          _id_nin: a$7 !== undefined ? a$7.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          title_lt: a$8 !== undefined ? a$8 : undefined,
          _id_gte: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          title_in: a$10 !== undefined ? a$10.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image_nin: a$11 !== undefined ? a$11.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image_ne: a$12 !== undefined ? a$12 : undefined,
          image_lte: a$13 !== undefined ? a$13 : undefined,
          _id_lte: a$14 !== undefined ? Caml_option.valFromOption(a$14) : undefined,
          AND: a$15 !== undefined ? a$15.map(serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput) : undefined,
          price_lt: a$16 !== undefined ? a$16 : undefined,
          price_ne: a$17 !== undefined ? a$17 : undefined,
          _id_lt: a$18 !== undefined ? Caml_option.valFromOption(a$18) : undefined,
          similarity_lt: a$19 !== undefined ? a$19 : undefined,
          price_nin: a$20 !== undefined ? a$20.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          similarity: a$21 !== undefined ? a$21 : undefined,
          image_in: a$22 !== undefined ? a$22.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          _id_ne: a$23 !== undefined ? Caml_option.valFromOption(a$23) : undefined,
          title_gte: a$24 !== undefined ? a$24 : undefined,
          title_gt: a$25 !== undefined ? a$25 : undefined,
          price_in: a$26 !== undefined ? a$26.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          title_ne: a$27 !== undefined ? a$27 : undefined,
          title_lte: a$28 !== undefined ? a$28 : undefined,
          similarity_ne: a$29 !== undefined ? a$29 : undefined,
          price_gte: a$30 !== undefined ? a$30 : undefined,
          _id_exists: a$31 !== undefined ? a$31 : undefined,
          image_exists: a$32 !== undefined ? a$32 : undefined,
          similarity_lte: a$33 !== undefined ? a$33 : undefined,
          similarity_exists: a$34 !== undefined ? a$34 : undefined,
          title: a$35 !== undefined ? a$35 : undefined,
          title_exists: a$36 !== undefined ? a$36 : undefined,
          _id_gt: a$37 !== undefined ? Caml_option.valFromOption(a$37) : undefined,
          similarity_in: a$38 !== undefined ? a$38.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          giftProvider_exists: a$39 !== undefined ? a$39 : undefined,
          giftProvider: a$40 !== undefined ? serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput(a$40) : undefined,
          OR: a$41 !== undefined ? a$41.map(serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput) : undefined,
          _id: a$42 !== undefined ? Caml_option.valFromOption(a$42) : undefined,
          image_gte: a$43 !== undefined ? a$43 : undefined,
          image: a$44 !== undefined ? a$44 : undefined,
          price_lte: a$45 !== undefined ? a$45 : undefined,
          similarity_gt: a$46 !== undefined ? a$46 : undefined,
          similarity_gte: a$47 !== undefined ? a$47 : undefined,
          image_gt: a$48 !== undefined ? a$48 : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput(inp) {
  var a = inp.logo_in;
  var a$1 = inp.name;
  var a$2 = inp.name_ne;
  var a$3 = inp.logo_nin;
  var a$4 = inp.logo_gt;
  var a$5 = inp.name_exists;
  var a$6 = inp.logo_exists;
  var a$7 = inp.name_gte;
  var a$8 = inp.logo_lte;
  var a$9 = inp.logo_lt;
  var a$10 = inp.AND;
  var a$11 = inp.name_lt;
  var a$12 = inp.name_in;
  var a$13 = inp.logo;
  var a$14 = inp.logo_ne;
  var a$15 = inp.name_gt;
  var a$16 = inp.name_lte;
  var a$17 = inp.name_nin;
  var a$18 = inp.logo_gte;
  var a$19 = inp.OR;
  return {
          logo_in: a !== undefined ? a.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          name: a$1 !== undefined ? a$1 : undefined,
          name_ne: a$2 !== undefined ? a$2 : undefined,
          logo_nin: a$3 !== undefined ? a$3.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          logo_gt: a$4 !== undefined ? a$4 : undefined,
          name_exists: a$5 !== undefined ? a$5 : undefined,
          logo_exists: a$6 !== undefined ? a$6 : undefined,
          name_gte: a$7 !== undefined ? a$7 : undefined,
          logo_lte: a$8 !== undefined ? a$8 : undefined,
          logo_lt: a$9 !== undefined ? a$9 : undefined,
          AND: a$10 !== undefined ? a$10.map(serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput) : undefined,
          name_lt: a$11 !== undefined ? a$11 : undefined,
          name_in: a$12 !== undefined ? a$12.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          logo: a$13 !== undefined ? a$13 : undefined,
          logo_ne: a$14 !== undefined ? a$14 : undefined,
          name_gt: a$15 !== undefined ? a$15 : undefined,
          name_lte: a$16 !== undefined ? a$16 : undefined,
          name_nin: a$17 !== undefined ? a$17.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          logo_gte: a$18 !== undefined ? a$18 : undefined,
          OR: a$19 !== undefined ? a$19.map(serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput) : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput(inp) {
  var a = inp.productId_lt;
  var a$1 = inp.weight;
  var a$2 = inp.productId_in;
  var a$3 = inp.productId_lte;
  var a$4 = inp.OR;
  var a$5 = inp.weight_lte;
  var a$6 = inp.image_ne;
  var a$7 = inp.weight_nin;
  var a$8 = inp.weight_gt;
  var a$9 = inp.title_lt;
  var a$10 = inp.image_lt;
  var a$11 = inp.image_gt;
  var a$12 = inp.productId;
  var a$13 = inp.title_ne;
  var a$14 = inp.image_in;
  var a$15 = inp.productId_ne;
  var a$16 = inp.productId_gte;
  var a$17 = inp.image_exists;
  var a$18 = inp.weight_in;
  var a$19 = inp.AND;
  var a$20 = inp.image_gte;
  var a$21 = inp.weight_gte;
  var a$22 = inp.weight_lt;
  var a$23 = inp.weight_ne;
  var a$24 = inp.title_nin;
  var a$25 = inp.title_exists;
  var a$26 = inp.productId_exists;
  var a$27 = inp.title_lte;
  var a$28 = inp.productId_gt;
  var a$29 = inp.title_gte;
  var a$30 = inp.title_gt;
  var a$31 = inp.image;
  var a$32 = inp.title;
  var a$33 = inp.productId_nin;
  var a$34 = inp.title_in;
  var a$35 = inp.image_nin;
  var a$36 = inp.image_lte;
  var a$37 = inp.weight_exists;
  return {
          productId_lt: a !== undefined ? a : undefined,
          weight: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          productId_in: a$2 !== undefined ? a$2.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          productId_lte: a$3 !== undefined ? a$3 : undefined,
          OR: a$4 !== undefined ? a$4.map(serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput) : undefined,
          weight_lte: a$5 !== undefined ? Caml_option.valFromOption(a$5) : undefined,
          image_ne: a$6 !== undefined ? a$6 : undefined,
          weight_nin: a$7 !== undefined ? a$7.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          weight_gt: a$8 !== undefined ? Caml_option.valFromOption(a$8) : undefined,
          title_lt: a$9 !== undefined ? a$9 : undefined,
          image_lt: a$10 !== undefined ? a$10 : undefined,
          image_gt: a$11 !== undefined ? a$11 : undefined,
          productId: a$12 !== undefined ? a$12 : undefined,
          title_ne: a$13 !== undefined ? a$13 : undefined,
          image_in: a$14 !== undefined ? a$14.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          productId_ne: a$15 !== undefined ? a$15 : undefined,
          productId_gte: a$16 !== undefined ? a$16 : undefined,
          image_exists: a$17 !== undefined ? a$17 : undefined,
          weight_in: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          AND: a$19 !== undefined ? a$19.map(serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput) : undefined,
          image_gte: a$20 !== undefined ? a$20 : undefined,
          weight_gte: a$21 !== undefined ? Caml_option.valFromOption(a$21) : undefined,
          weight_lt: a$22 !== undefined ? Caml_option.valFromOption(a$22) : undefined,
          weight_ne: a$23 !== undefined ? Caml_option.valFromOption(a$23) : undefined,
          title_nin: a$24 !== undefined ? a$24.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          title_exists: a$25 !== undefined ? a$25 : undefined,
          productId_exists: a$26 !== undefined ? a$26 : undefined,
          title_lte: a$27 !== undefined ? a$27 : undefined,
          productId_gt: a$28 !== undefined ? a$28 : undefined,
          title_gte: a$29 !== undefined ? a$29 : undefined,
          title_gt: a$30 !== undefined ? a$30 : undefined,
          image: a$31 !== undefined ? a$31 : undefined,
          title: a$32 !== undefined ? a$32 : undefined,
          productId_nin: a$33 !== undefined ? a$33.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          title_in: a$34 !== undefined ? a$34.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image_nin: a$35 !== undefined ? a$35.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image_lte: a$36 !== undefined ? a$36 : undefined,
          weight_exists: a$37 !== undefined ? a$37 : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.query;
  return {
          query: a !== undefined ? serializeInputObjectGiftProfileQueryInput(a) : undefined
        };
}

function makeVariables(query, param) {
  return {
          query: query
        };
}

function makeInputObjectGiftProfileQueryInput(recommendations_exists, recommendationsByCategory_nin, createdAt_gt, recommendationsByCategory_exists, updatedAt_lte, createdAt_gte, priceMin_lt, gender_in, createdAt_exists, userId, AND, _id_ne, _id, createdAt_lte, priceMax_lte, purchases_in, _id_gte, priceMin_gte, gifteeId_lt, interests, priceMin_gt, userId_exists, profile_nin, updatedAt_nin, OR, gifteeId_lte, updatedAt_exists, priceMin_lte, _id_nin, updatedAt_ne, _id_in, priceMax_nin, gifteeId_exists, priceMin_nin, createdAt_lt, priceMax_exists, recommendationsByCategory_in, _id_lt, priceMax, userId_in, createdAt_nin, gender_nin, recommendations_in, userId_ne, priceMin, _id_lte, _id_gt, priceMax_ne, updatedAt, gifteeId_gt, updatedAt_gt, updatedAt_gte, userId_nin, purchases, gifteeId_gte, recommendationsByCategory, gender_ne, userId_lte, createdAt_in, priceMax_lt, gifteeId_ne, purchases_exists, priceMax_in, profile_exists, userId_gt, recommendations_nin, gender_exists, gender_lte, profile, priceMin_ne, gender, priceMin_exists, gender_lt, createdAt, gifteeId_nin, _id_exists, gifteeId, gender_gt, updatedAt_in, userId_lt, priceMax_gt, gender_gte, purchases_nin, gifteeId_in, priceMax_gte, interests_nin, profile_in, userId_gte, interests_exists, priceMin_in, updatedAt_lt, createdAt_ne, recommendations, interests_in, param) {
  return {
          recommendations_exists: recommendations_exists,
          recommendationsByCategory_nin: recommendationsByCategory_nin,
          createdAt_gt: createdAt_gt,
          recommendationsByCategory_exists: recommendationsByCategory_exists,
          updatedAt_lte: updatedAt_lte,
          createdAt_gte: createdAt_gte,
          priceMin_lt: priceMin_lt,
          gender_in: gender_in,
          createdAt_exists: createdAt_exists,
          userId: userId,
          AND: AND,
          _id_ne: _id_ne,
          _id: _id,
          createdAt_lte: createdAt_lte,
          priceMax_lte: priceMax_lte,
          purchases_in: purchases_in,
          _id_gte: _id_gte,
          priceMin_gte: priceMin_gte,
          gifteeId_lt: gifteeId_lt,
          interests: interests,
          priceMin_gt: priceMin_gt,
          userId_exists: userId_exists,
          profile_nin: profile_nin,
          updatedAt_nin: updatedAt_nin,
          OR: OR,
          gifteeId_lte: gifteeId_lte,
          updatedAt_exists: updatedAt_exists,
          priceMin_lte: priceMin_lte,
          _id_nin: _id_nin,
          updatedAt_ne: updatedAt_ne,
          _id_in: _id_in,
          priceMax_nin: priceMax_nin,
          gifteeId_exists: gifteeId_exists,
          priceMin_nin: priceMin_nin,
          createdAt_lt: createdAt_lt,
          priceMax_exists: priceMax_exists,
          recommendationsByCategory_in: recommendationsByCategory_in,
          _id_lt: _id_lt,
          priceMax: priceMax,
          userId_in: userId_in,
          createdAt_nin: createdAt_nin,
          gender_nin: gender_nin,
          recommendations_in: recommendations_in,
          userId_ne: userId_ne,
          priceMin: priceMin,
          _id_lte: _id_lte,
          _id_gt: _id_gt,
          priceMax_ne: priceMax_ne,
          updatedAt: updatedAt,
          gifteeId_gt: gifteeId_gt,
          updatedAt_gt: updatedAt_gt,
          updatedAt_gte: updatedAt_gte,
          userId_nin: userId_nin,
          purchases: purchases,
          gifteeId_gte: gifteeId_gte,
          recommendationsByCategory: recommendationsByCategory,
          gender_ne: gender_ne,
          userId_lte: userId_lte,
          createdAt_in: createdAt_in,
          priceMax_lt: priceMax_lt,
          gifteeId_ne: gifteeId_ne,
          purchases_exists: purchases_exists,
          priceMax_in: priceMax_in,
          profile_exists: profile_exists,
          userId_gt: userId_gt,
          recommendations_nin: recommendations_nin,
          gender_exists: gender_exists,
          gender_lte: gender_lte,
          profile: profile,
          priceMin_ne: priceMin_ne,
          gender: gender,
          priceMin_exists: priceMin_exists,
          gender_lt: gender_lt,
          createdAt: createdAt,
          gifteeId_nin: gifteeId_nin,
          _id_exists: _id_exists,
          gifteeId: gifteeId,
          gender_gt: gender_gt,
          updatedAt_in: updatedAt_in,
          userId_lt: userId_lt,
          priceMax_gt: priceMax_gt,
          gender_gte: gender_gte,
          purchases_nin: purchases_nin,
          gifteeId_in: gifteeId_in,
          priceMax_gte: priceMax_gte,
          interests_nin: interests_nin,
          profile_in: profile_in,
          userId_gte: userId_gte,
          interests_exists: interests_exists,
          priceMin_in: priceMin_in,
          updatedAt_lt: updatedAt_lt,
          createdAt_ne: createdAt_ne,
          recommendations: recommendations,
          interests_in: interests_in
        };
}

function makeInputObjectGiftProfileRecommendationsByCategoryQueryInput(likedProducts_nin, likedProducts_in, recommendations_nin, category_in, likedProducts, likedProducts_exists, AND, category_ne, category_gt, category_exists, category_gte, OR, category_lte, recommendations_exists, category_lt, recommendations_in, recommendations, category, category_nin, param) {
  return {
          likedProducts_nin: likedProducts_nin,
          likedProducts_in: likedProducts_in,
          recommendations_nin: recommendations_nin,
          category_in: category_in,
          likedProducts: likedProducts,
          likedProducts_exists: likedProducts_exists,
          AND: AND,
          category_ne: category_ne,
          category_gt: category_gt,
          category_exists: category_exists,
          category_gte: category_gte,
          OR: OR,
          category_lte: category_lte,
          recommendations_exists: recommendations_exists,
          category_lt: category_lt,
          recommendations_in: recommendations_in,
          recommendations: recommendations,
          category: category,
          category_nin: category_nin
        };
}

function makeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput(productId_lt, weight, productId_in, productId_lte, OR, weight_lte, image_ne, weight_nin, weight_gt, title_lt, image_lt, image_gt, productId, title_ne, image_in, productId_ne, productId_gte, image_exists, weight_in, AND, image_gte, weight_gte, weight_lt, weight_ne, title_nin, title_exists, productId_exists, title_lte, productId_gt, title_gte, title_gt, image, title, productId_nin, title_in, image_nin, image_lte, weight_exists, param) {
  return {
          productId_lt: productId_lt,
          weight: weight,
          productId_in: productId_in,
          productId_lte: productId_lte,
          OR: OR,
          weight_lte: weight_lte,
          image_ne: image_ne,
          weight_nin: weight_nin,
          weight_gt: weight_gt,
          title_lt: title_lt,
          image_lt: image_lt,
          image_gt: image_gt,
          productId: productId,
          title_ne: title_ne,
          image_in: image_in,
          productId_ne: productId_ne,
          productId_gte: productId_gte,
          image_exists: image_exists,
          weight_in: weight_in,
          AND: AND,
          image_gte: image_gte,
          weight_gte: weight_gte,
          weight_lt: weight_lt,
          weight_ne: weight_ne,
          title_nin: title_nin,
          title_exists: title_exists,
          productId_exists: productId_exists,
          title_lte: title_lte,
          productId_gt: productId_gt,
          title_gte: title_gte,
          title_gt: title_gt,
          image: image,
          title: title,
          productId_nin: productId_nin,
          title_in: title_in,
          image_nin: image_nin,
          image_lte: image_lte,
          weight_exists: weight_exists
        };
}

function makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput(image_lt, title_nin, price, _id_in, price_exists, price_gt, similarity_nin, _id_nin, title_lt, _id_gte, title_in, image_nin, image_ne, image_lte, _id_lte, AND, price_lt, price_ne, _id_lt, similarity_lt, price_nin, similarity, image_in, _id_ne, title_gte, title_gt, price_in, title_ne, title_lte, similarity_ne, price_gte, _id_exists, image_exists, similarity_lte, similarity_exists, title, title_exists, _id_gt, similarity_in, giftProvider_exists, giftProvider, OR, _id, image_gte, image, price_lte, similarity_gt, similarity_gte, image_gt, param) {
  return {
          image_lt: image_lt,
          title_nin: title_nin,
          price: price,
          _id_in: _id_in,
          price_exists: price_exists,
          price_gt: price_gt,
          similarity_nin: similarity_nin,
          _id_nin: _id_nin,
          title_lt: title_lt,
          _id_gte: _id_gte,
          title_in: title_in,
          image_nin: image_nin,
          image_ne: image_ne,
          image_lte: image_lte,
          _id_lte: _id_lte,
          AND: AND,
          price_lt: price_lt,
          price_ne: price_ne,
          _id_lt: _id_lt,
          similarity_lt: similarity_lt,
          price_nin: price_nin,
          similarity: similarity,
          image_in: image_in,
          _id_ne: _id_ne,
          title_gte: title_gte,
          title_gt: title_gt,
          price_in: price_in,
          title_ne: title_ne,
          title_lte: title_lte,
          similarity_ne: similarity_ne,
          price_gte: price_gte,
          _id_exists: _id_exists,
          image_exists: image_exists,
          similarity_lte: similarity_lte,
          similarity_exists: similarity_exists,
          title: title,
          title_exists: title_exists,
          _id_gt: _id_gt,
          similarity_in: similarity_in,
          giftProvider_exists: giftProvider_exists,
          giftProvider: giftProvider,
          OR: OR,
          _id: _id,
          image_gte: image_gte,
          image: image,
          price_lte: price_lte,
          similarity_gt: similarity_gt,
          similarity_gte: similarity_gte,
          image_gt: image_gt
        };
}

function makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput(logo_exists, logo_in, logo_ne, name_exists, logo_gte, name_nin, logo, name_ne, name_lte, logo_gt, name, name_lt, logo_lt, name_gt, logo_nin, name_gte, AND, OR, logo_lte, name_in, param) {
  return {
          logo_exists: logo_exists,
          logo_in: logo_in,
          logo_ne: logo_ne,
          name_exists: name_exists,
          logo_gte: logo_gte,
          name_nin: name_nin,
          logo: logo,
          name_ne: name_ne,
          name_lte: name_lte,
          logo_gt: logo_gt,
          name: name,
          name_lt: name_lt,
          logo_lt: logo_lt,
          name_gt: name_gt,
          logo_nin: logo_nin,
          name_gte: name_gte,
          AND: AND,
          OR: OR,
          logo_lte: logo_lte,
          name_in: name_in
        };
}

function makeInputObjectGiftProfilePurchasesQueryInput(giftIdeaId_ne, date_exists, targetYear_gt, targetYear_in, OR, date_gt, transactionId_gt, transactionId_lt, AND, date_in, targetYear_gte, transactionId, giftIdeaId_lt, transactionId_nin, transactionId_in, giftIdeaId_nin, giftIdeaId_in, giftIdeaId_gte, targetYear_nin, targetYear_lt, transactionId_gte, giftIdeaId_gt, targetYear_ne, targetYear, date_nin, date_gte, giftIdeaId_exists, targetYear_exists, transactionId_lte, transactionId_ne, transactionId_exists, targetYear_lte, date_lt, date_ne, giftIdeaId, giftIdeaId_lte, date_lte, date, param) {
  return {
          giftIdeaId_ne: giftIdeaId_ne,
          date_exists: date_exists,
          targetYear_gt: targetYear_gt,
          targetYear_in: targetYear_in,
          OR: OR,
          date_gt: date_gt,
          transactionId_gt: transactionId_gt,
          transactionId_lt: transactionId_lt,
          AND: AND,
          date_in: date_in,
          targetYear_gte: targetYear_gte,
          transactionId: transactionId,
          giftIdeaId_lt: giftIdeaId_lt,
          transactionId_nin: transactionId_nin,
          transactionId_in: transactionId_in,
          giftIdeaId_nin: giftIdeaId_nin,
          giftIdeaId_in: giftIdeaId_in,
          giftIdeaId_gte: giftIdeaId_gte,
          targetYear_nin: targetYear_nin,
          targetYear_lt: targetYear_lt,
          transactionId_gte: transactionId_gte,
          giftIdeaId_gt: giftIdeaId_gt,
          targetYear_ne: targetYear_ne,
          targetYear: targetYear,
          date_nin: date_nin,
          date_gte: date_gte,
          giftIdeaId_exists: giftIdeaId_exists,
          targetYear_exists: targetYear_exists,
          transactionId_lte: transactionId_lte,
          transactionId_ne: transactionId_ne,
          transactionId_exists: transactionId_exists,
          targetYear_lte: targetYear_lte,
          date_lt: date_lt,
          date_ne: date_ne,
          giftIdeaId: giftIdeaId,
          giftIdeaId_lte: giftIdeaId_lte,
          date_lte: date_lte,
          date: date
        };
}

function makeInputObjectGiftProfileProfileQueryInput(interest, productId_lt, productId, title_lte, title_gte, weight_gte, interest_in, productId_exists, weight_exists, weight_ne, title_lt, interest_gt, weight_gt, productId_in, productId_ne, image_lte, weight_in, OR, image_gt, AND, title_gt, weight, interest_lte, productId_gt, interest_gte, image_nin, interest_nin, title, interest_lt, weight_lte, image_gte, interest_exists, productId_nin, interest_ne, title_exists, title_ne, title_in, weight_lt, image_ne, weight_nin, image_lt, productId_gte, image_in, productId_lte, image_exists, title_nin, image, param) {
  return {
          interest: interest,
          productId_lt: productId_lt,
          productId: productId,
          title_lte: title_lte,
          title_gte: title_gte,
          weight_gte: weight_gte,
          interest_in: interest_in,
          productId_exists: productId_exists,
          weight_exists: weight_exists,
          weight_ne: weight_ne,
          title_lt: title_lt,
          interest_gt: interest_gt,
          weight_gt: weight_gt,
          productId_in: productId_in,
          productId_ne: productId_ne,
          image_lte: image_lte,
          weight_in: weight_in,
          OR: OR,
          image_gt: image_gt,
          AND: AND,
          title_gt: title_gt,
          weight: weight,
          interest_lte: interest_lte,
          productId_gt: productId_gt,
          interest_gte: interest_gte,
          image_nin: image_nin,
          interest_nin: interest_nin,
          title: title,
          interest_lt: interest_lt,
          weight_lte: weight_lte,
          image_gte: image_gte,
          interest_exists: interest_exists,
          productId_nin: productId_nin,
          interest_ne: interest_ne,
          title_exists: title_exists,
          title_ne: title_ne,
          title_in: title_in,
          weight_lt: weight_lt,
          image_ne: image_ne,
          weight_nin: weight_nin,
          image_lt: image_lt,
          productId_gte: productId_gte,
          image_in: image_in,
          productId_lte: productId_lte,
          image_exists: image_exists,
          title_nin: title_nin,
          image: image
        };
}

function makeInputObjectGiftProfileRecommendationsQueryInput(similarity_gt, similarity_lt, OR, interest_gt, title_lte, title_gt, title_nin, giftSource_nin, title_exists, giftSource_ne, url_in, _id_lte, price_exists, similarity_exists, _id_gt, similarity_ne, price_ne, interest_nin, similarity_nin, avgReview_gt, interest_lte, price_gt, url_lt, giftSource_exists, _id_gte, _id_in, interest_gte, price, title_gte, price_lt, image_in, image_lt, interest_ne, giftSource_lte, avgReview_nin, similarity_in, url_lte, _id_lt, price_lte, title, url_gte, url_ne, avgReview_lt, price_gte, similarity_lte, image_ne, description_lt, giftProvider, _id_exists, description_gt, _id_ne, interest_exists, avgReview, giftSource_gte, interest, url_gt, image_gt, AND, avgReview_in, image, avgReview_exists, description_lte, _id_nin, image_nin, similarity, avgReview_gte, associatedProducts_exists, associatedProducts_in, giftSource, description_gte, description_nin, description_exists, title_in, similarity_gte, giftSource_gt, url_nin, interest_lt, giftProvider_exists, avgReview_lte, _id, title_lt, avgReview_ne, image_exists, price_in, title_ne, interest_in, giftSource_lt, description_in, description_ne, url, url_exists, image_gte, giftSource_in, associatedProducts, associatedProducts_nin, price_nin, description, image_lte, param) {
  return {
          similarity_gt: similarity_gt,
          similarity_lt: similarity_lt,
          OR: OR,
          interest_gt: interest_gt,
          title_lte: title_lte,
          title_gt: title_gt,
          title_nin: title_nin,
          giftSource_nin: giftSource_nin,
          title_exists: title_exists,
          giftSource_ne: giftSource_ne,
          url_in: url_in,
          _id_lte: _id_lte,
          price_exists: price_exists,
          similarity_exists: similarity_exists,
          _id_gt: _id_gt,
          similarity_ne: similarity_ne,
          price_ne: price_ne,
          interest_nin: interest_nin,
          similarity_nin: similarity_nin,
          avgReview_gt: avgReview_gt,
          interest_lte: interest_lte,
          price_gt: price_gt,
          url_lt: url_lt,
          giftSource_exists: giftSource_exists,
          _id_gte: _id_gte,
          _id_in: _id_in,
          interest_gte: interest_gte,
          price: price,
          title_gte: title_gte,
          price_lt: price_lt,
          image_in: image_in,
          image_lt: image_lt,
          interest_ne: interest_ne,
          giftSource_lte: giftSource_lte,
          avgReview_nin: avgReview_nin,
          similarity_in: similarity_in,
          url_lte: url_lte,
          _id_lt: _id_lt,
          price_lte: price_lte,
          title: title,
          url_gte: url_gte,
          url_ne: url_ne,
          avgReview_lt: avgReview_lt,
          price_gte: price_gte,
          similarity_lte: similarity_lte,
          image_ne: image_ne,
          description_lt: description_lt,
          giftProvider: giftProvider,
          _id_exists: _id_exists,
          description_gt: description_gt,
          _id_ne: _id_ne,
          interest_exists: interest_exists,
          avgReview: avgReview,
          giftSource_gte: giftSource_gte,
          interest: interest,
          url_gt: url_gt,
          image_gt: image_gt,
          AND: AND,
          avgReview_in: avgReview_in,
          image: image,
          avgReview_exists: avgReview_exists,
          description_lte: description_lte,
          _id_nin: _id_nin,
          image_nin: image_nin,
          similarity: similarity,
          avgReview_gte: avgReview_gte,
          associatedProducts_exists: associatedProducts_exists,
          associatedProducts_in: associatedProducts_in,
          giftSource: giftSource,
          description_gte: description_gte,
          description_nin: description_nin,
          description_exists: description_exists,
          title_in: title_in,
          similarity_gte: similarity_gte,
          giftSource_gt: giftSource_gt,
          url_nin: url_nin,
          interest_lt: interest_lt,
          giftProvider_exists: giftProvider_exists,
          avgReview_lte: avgReview_lte,
          _id: _id,
          title_lt: title_lt,
          avgReview_ne: avgReview_ne,
          image_exists: image_exists,
          price_in: price_in,
          title_ne: title_ne,
          interest_in: interest_in,
          giftSource_lt: giftSource_lt,
          description_in: description_in,
          description_ne: description_ne,
          url: url,
          url_exists: url_exists,
          image_gte: image_gte,
          giftSource_in: giftSource_in,
          associatedProducts: associatedProducts,
          associatedProducts_nin: associatedProducts_nin,
          price_nin: price_nin,
          description: description,
          image_lte: image_lte
        };
}

function makeInputObjectGiftProfileRecommendationsGiftProviderQueryInput(logo_in, name, name_ne, logo_nin, logo_gt, name_exists, logo_exists, name_gte, logo_lte, logo_lt, AND, name_lt, name_in, logo, logo_ne, name_gt, name_lte, name_nin, logo_gte, OR, param) {
  return {
          logo_in: logo_in,
          name: name,
          name_ne: name_ne,
          logo_nin: logo_nin,
          logo_gt: logo_gt,
          name_exists: name_exists,
          logo_exists: logo_exists,
          name_gte: name_gte,
          logo_lte: logo_lte,
          logo_lt: logo_lt,
          AND: AND,
          name_lt: name_lt,
          name_in: name_in,
          logo: logo,
          logo_ne: logo_ne,
          name_gt: name_gt,
          name_lte: name_lte,
          name_nin: name_nin,
          logo_gte: logo_gte,
          OR: OR
        };
}

function makeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput(title_nin, url, title_ne, title, OR, image_ne, url_lte, url_gt, title_gt, image_gte, image_gt, image_exists, title_gte, url_exists, url_in, title_lte, title_in, url_nin, image_in, url_gte, image_nin, image_lt, image, title_exists, image_lte, url_ne, url_lt, AND, title_lt, param) {
  return {
          title_nin: title_nin,
          url: url,
          title_ne: title_ne,
          title: title,
          OR: OR,
          image_ne: image_ne,
          url_lte: url_lte,
          url_gt: url_gt,
          title_gt: title_gt,
          image_gte: image_gte,
          image_gt: image_gt,
          image_exists: image_exists,
          title_gte: title_gte,
          url_exists: url_exists,
          url_in: url_in,
          title_lte: title_lte,
          title_in: title_in,
          url_nin: url_nin,
          image_in: image_in,
          url_gte: url_gte,
          image_nin: image_nin,
          image_lt: image_lt,
          image: image,
          title_exists: title_exists,
          image_lte: image_lte,
          url_ne: url_ne,
          url_lt: url_lt,
          AND: AND,
          title_lt: title_lt
        };
}

function makeDefaultVariables(param) {
  return {
          query: undefined
        };
}

var GetGiftProfileQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectGiftProfileQueryInput: serializeInputObjectGiftProfileQueryInput,
  serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput,
  serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput,
  serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput,
  serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput,
  serializeInputObjectGiftProfilePurchasesQueryInput: serializeInputObjectGiftProfilePurchasesQueryInput,
  serializeInputObjectGiftProfileProfileQueryInput: serializeInputObjectGiftProfileProfileQueryInput,
  serializeInputObjectGiftProfileRecommendationsQueryInput: serializeInputObjectGiftProfileRecommendationsQueryInput,
  serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput: serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput,
  serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput: serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput,
  makeVariables: makeVariables,
  makeInputObjectGiftProfileQueryInput: makeInputObjectGiftProfileQueryInput,
  makeInputObjectGiftProfileRecommendationsByCategoryQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryQueryInput,
  makeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput,
  makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput,
  makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput,
  makeInputObjectGiftProfilePurchasesQueryInput: makeInputObjectGiftProfilePurchasesQueryInput,
  makeInputObjectGiftProfileProfileQueryInput: makeInputObjectGiftProfileProfileQueryInput,
  makeInputObjectGiftProfileRecommendationsQueryInput: makeInputObjectGiftProfileRecommendationsQueryInput,
  makeInputObjectGiftProfileRecommendationsGiftProviderQueryInput: makeInputObjectGiftProfileRecommendationsGiftProviderQueryInput,
  makeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput: makeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var GetGiftProfileQuery_refetchQueryDescription = include.refetchQueryDescription;

var GetGiftProfileQuery_use = include.use;

var GetGiftProfileQuery_useLazy = include.useLazy;

var GetGiftProfileQuery_useLazyWithVariables = include.useLazyWithVariables;

var GetGiftProfileQuery = {
  GetGiftProfileQuery_inner: GetGiftProfileQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectGiftProfileQueryInput: serializeInputObjectGiftProfileQueryInput,
  serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput,
  serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput,
  serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput,
  serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput,
  serializeInputObjectGiftProfilePurchasesQueryInput: serializeInputObjectGiftProfilePurchasesQueryInput,
  serializeInputObjectGiftProfileProfileQueryInput: serializeInputObjectGiftProfileProfileQueryInput,
  serializeInputObjectGiftProfileRecommendationsQueryInput: serializeInputObjectGiftProfileRecommendationsQueryInput,
  serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput: serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput,
  serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput: serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput,
  makeVariables: makeVariables,
  makeInputObjectGiftProfileQueryInput: makeInputObjectGiftProfileQueryInput,
  makeInputObjectGiftProfileRecommendationsByCategoryQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryQueryInput,
  makeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput,
  makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput,
  makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput,
  makeInputObjectGiftProfilePurchasesQueryInput: makeInputObjectGiftProfilePurchasesQueryInput,
  makeInputObjectGiftProfileProfileQueryInput: makeInputObjectGiftProfileProfileQueryInput,
  makeInputObjectGiftProfileRecommendationsQueryInput: makeInputObjectGiftProfileRecommendationsQueryInput,
  makeInputObjectGiftProfileRecommendationsGiftProviderQueryInput: makeInputObjectGiftProfileRecommendationsGiftProviderQueryInput,
  makeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput: makeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: GetGiftProfileQuery_refetchQueryDescription,
  use: GetGiftProfileQuery_use,
  useLazy: GetGiftProfileQuery_useLazy,
  useLazyWithVariables: GetGiftProfileQuery_useLazyWithVariables
};

var Raw$1 = {};

var query$1 = (require("@apollo/client").gql`
  mutation UpdateOneGiftProfileMut($query: GiftProfileQueryInput!, $set: GiftProfileUpdateInput!)  {
    updateOneGiftProfile(query: $query, set: $set)  {
      __typename
      _id
      purchases  {
        __typename
        giftIdeaId
        transactionId
        date
        targetYear
      }
    }
  }
`);

function parse$1(value) {
  var value$1 = value.updateOneGiftProfile;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1._id;
    var value$3 = value$1.purchases;
    tmp = {
      __typename: value$1.__typename,
      _id: !(value$2 == null) ? Caml_option.some(value$2) : undefined,
      purchases: !(value$3 == null) ? value$3.map(function (value) {
              if (value == null) {
                return ;
              }
              var value$1 = value.giftIdeaId;
              var value$2 = value.transactionId;
              var value$3 = value.date;
              var value$4 = value.targetYear;
              return {
                      __typename: value.__typename,
                      giftIdeaId: !(value$1 == null) ? Caml_option.some(value$1) : undefined,
                      transactionId: !(value$2 == null) ? Caml_option.some(value$2) : undefined,
                      date: !(value$3 == null) ? Caml_option.some(value$3) : undefined,
                      targetYear: !(value$4 == null) ? value$4 : undefined
                    };
            }) : undefined
    };
  }
  return {
          updateOneGiftProfile: tmp
        };
}

function serialize$1(value) {
  var value$1 = value.updateOneGiftProfile;
  var updateOneGiftProfile;
  if (value$1 !== undefined) {
    var value$2 = value$1.purchases;
    var purchases = value$2 !== undefined ? value$2.map(function (value) {
            if (value === undefined) {
              return null;
            }
            var value$1 = value.targetYear;
            var targetYear = value$1 !== undefined ? value$1 : null;
            var value$2 = value.date;
            var date = value$2 !== undefined ? Caml_option.valFromOption(value$2) : null;
            var value$3 = value.transactionId;
            var transactionId = value$3 !== undefined ? Caml_option.valFromOption(value$3) : null;
            var value$4 = value.giftIdeaId;
            var giftIdeaId = value$4 !== undefined ? Caml_option.valFromOption(value$4) : null;
            var value$5 = value.__typename;
            return {
                    __typename: value$5,
                    giftIdeaId: giftIdeaId,
                    transactionId: transactionId,
                    date: date,
                    targetYear: targetYear
                  };
          }) : null;
    var value$3 = value$1._id;
    var _id = value$3 !== undefined ? Caml_option.valFromOption(value$3) : null;
    var value$4 = value$1.__typename;
    updateOneGiftProfile = {
      __typename: value$4,
      _id: _id,
      purchases: purchases
    };
  } else {
    updateOneGiftProfile = null;
  }
  return {
          updateOneGiftProfile: updateOneGiftProfile
        };
}

function serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput$1(inp) {
  var a = inp.title_nin;
  var a$1 = inp.url;
  var a$2 = inp.title_ne;
  var a$3 = inp.title;
  var a$4 = inp.OR;
  var a$5 = inp.image_ne;
  var a$6 = inp.url_lte;
  var a$7 = inp.url_gt;
  var a$8 = inp.title_gt;
  var a$9 = inp.image_gte;
  var a$10 = inp.image_gt;
  var a$11 = inp.image_exists;
  var a$12 = inp.title_gte;
  var a$13 = inp.url_exists;
  var a$14 = inp.url_in;
  var a$15 = inp.title_lte;
  var a$16 = inp.title_in;
  var a$17 = inp.url_nin;
  var a$18 = inp.image_in;
  var a$19 = inp.url_gte;
  var a$20 = inp.image_nin;
  var a$21 = inp.image_lt;
  var a$22 = inp.image;
  var a$23 = inp.title_exists;
  var a$24 = inp.image_lte;
  var a$25 = inp.url_ne;
  var a$26 = inp.url_lt;
  var a$27 = inp.AND;
  var a$28 = inp.title_lt;
  return {
          title_nin: a !== undefined ? a.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          url: a$1 !== undefined ? a$1 : undefined,
          title_ne: a$2 !== undefined ? a$2 : undefined,
          title: a$3 !== undefined ? a$3 : undefined,
          OR: a$4 !== undefined ? a$4.map(serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput$1) : undefined,
          image_ne: a$5 !== undefined ? a$5 : undefined,
          url_lte: a$6 !== undefined ? a$6 : undefined,
          url_gt: a$7 !== undefined ? a$7 : undefined,
          title_gt: a$8 !== undefined ? a$8 : undefined,
          image_gte: a$9 !== undefined ? a$9 : undefined,
          image_gt: a$10 !== undefined ? a$10 : undefined,
          image_exists: a$11 !== undefined ? a$11 : undefined,
          title_gte: a$12 !== undefined ? a$12 : undefined,
          url_exists: a$13 !== undefined ? a$13 : undefined,
          url_in: a$14 !== undefined ? a$14.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          title_lte: a$15 !== undefined ? a$15 : undefined,
          title_in: a$16 !== undefined ? a$16.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          url_nin: a$17 !== undefined ? a$17.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image_in: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          url_gte: a$19 !== undefined ? a$19 : undefined,
          image_nin: a$20 !== undefined ? a$20.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image_lt: a$21 !== undefined ? a$21 : undefined,
          image: a$22 !== undefined ? a$22 : undefined,
          title_exists: a$23 !== undefined ? a$23 : undefined,
          image_lte: a$24 !== undefined ? a$24 : undefined,
          url_ne: a$25 !== undefined ? a$25 : undefined,
          url_lt: a$26 !== undefined ? a$26 : undefined,
          AND: a$27 !== undefined ? a$27.map(serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput$1) : undefined,
          title_lt: a$28 !== undefined ? a$28 : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderUpdateInput(inp) {
  var a = inp.logo;
  var a$1 = inp.logo_unset;
  var a$2 = inp.name;
  var a$3 = inp.name_unset;
  return {
          logo: a !== undefined ? a : undefined,
          logo_unset: a$1 !== undefined ? a$1 : undefined,
          name: a$2 !== undefined ? a$2 : undefined,
          name_unset: a$3 !== undefined ? a$3 : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsUpdateInput(inp) {
  var a = inp.title_unset;
  var a$1 = inp.weight;
  var a$2 = inp.weight_unset;
  var a$3 = inp.image;
  var a$4 = inp.image_unset;
  var a$5 = inp.productId;
  var a$6 = inp.productId_unset;
  var a$7 = inp.title;
  return {
          title_unset: a !== undefined ? a : undefined,
          weight: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          weight_unset: a$2 !== undefined ? a$2 : undefined,
          image: a$3 !== undefined ? a$3 : undefined,
          image_unset: a$4 !== undefined ? a$4 : undefined,
          productId: a$5 !== undefined ? a$5 : undefined,
          productId_unset: a$6 !== undefined ? a$6 : undefined,
          title: a$7 !== undefined ? a$7 : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsUpdateInput(inp) {
  var a = inp.similarity_unset;
  var a$1 = inp.price_inc;
  var a$2 = inp.giftProvider_unset;
  var a$3 = inp.image_unset;
  var a$4 = inp.similarity_inc;
  var a$5 = inp.giftProvider;
  var a$6 = inp._id;
  var a$7 = inp.price;
  var a$8 = inp.title;
  var a$9 = inp.image;
  var a$10 = inp.title_unset;
  var a$11 = inp._id_unset;
  var a$12 = inp.price_unset;
  var a$13 = inp.similarity;
  return {
          similarity_unset: a !== undefined ? a : undefined,
          price_inc: a$1 !== undefined ? a$1 : undefined,
          giftProvider_unset: a$2 !== undefined ? a$2 : undefined,
          image_unset: a$3 !== undefined ? a$3 : undefined,
          similarity_inc: a$4 !== undefined ? a$4 : undefined,
          giftProvider: a$5 !== undefined ? serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderUpdateInput(a$5) : undefined,
          _id: a$6 !== undefined ? Caml_option.valFromOption(a$6) : undefined,
          price: a$7 !== undefined ? a$7 : undefined,
          title: a$8 !== undefined ? a$8 : undefined,
          image: a$9 !== undefined ? a$9 : undefined,
          title_unset: a$10 !== undefined ? a$10 : undefined,
          _id_unset: a$11 !== undefined ? a$11 : undefined,
          price_unset: a$12 !== undefined ? a$12 : undefined,
          similarity: a$13 !== undefined ? a$13 : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput$1(inp) {
  var a = inp.likedProducts_nin;
  var a$1 = inp.likedProducts_in;
  var a$2 = inp.recommendations_nin;
  var a$3 = inp.category_in;
  var a$4 = inp.likedProducts;
  var a$5 = inp.likedProducts_exists;
  var a$6 = inp.AND;
  var a$7 = inp.category_ne;
  var a$8 = inp.category_gt;
  var a$9 = inp.category_exists;
  var a$10 = inp.category_gte;
  var a$11 = inp.OR;
  var a$12 = inp.category_lte;
  var a$13 = inp.recommendations_exists;
  var a$14 = inp.category_lt;
  var a$15 = inp.recommendations_in;
  var a$16 = inp.recommendations;
  var a$17 = inp.category;
  var a$18 = inp.category_nin;
  return {
          likedProducts_nin: a !== undefined ? a.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput$1(b);
                  }
                  
                }) : undefined,
          likedProducts_in: a$1 !== undefined ? a$1.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput$1(b);
                  }
                  
                }) : undefined,
          recommendations_nin: a$2 !== undefined ? a$2.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput$1(b);
                  }
                  
                }) : undefined,
          category_in: a$3 !== undefined ? a$3.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          likedProducts: a$4 !== undefined ? a$4.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput$1(b);
                  }
                  
                }) : undefined,
          likedProducts_exists: a$5 !== undefined ? a$5 : undefined,
          AND: a$6 !== undefined ? a$6.map(serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput$1) : undefined,
          category_ne: a$7 !== undefined ? a$7 : undefined,
          category_gt: a$8 !== undefined ? a$8 : undefined,
          category_exists: a$9 !== undefined ? a$9 : undefined,
          category_gte: a$10 !== undefined ? a$10 : undefined,
          OR: a$11 !== undefined ? a$11.map(serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput$1) : undefined,
          category_lte: a$12 !== undefined ? a$12 : undefined,
          recommendations_exists: a$13 !== undefined ? a$13 : undefined,
          category_lt: a$14 !== undefined ? a$14 : undefined,
          recommendations_in: a$15 !== undefined ? a$15.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput$1(b);
                  }
                  
                }) : undefined,
          recommendations: a$16 !== undefined ? a$16.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput$1(b);
                  }
                  
                }) : undefined,
          category: a$17 !== undefined ? a$17 : undefined,
          category_nin: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined
        };
}

function serializeInputObjectGiftProfileQueryInput$1(inp) {
  var a = inp.recommendations_exists;
  var a$1 = inp.recommendationsByCategory_nin;
  var a$2 = inp.createdAt_gt;
  var a$3 = inp.recommendationsByCategory_exists;
  var a$4 = inp.updatedAt_lte;
  var a$5 = inp.createdAt_gte;
  var a$6 = inp.priceMin_lt;
  var a$7 = inp.gender_in;
  var a$8 = inp.createdAt_exists;
  var a$9 = inp.userId;
  var a$10 = inp.AND;
  var a$11 = inp._id_ne;
  var a$12 = inp._id;
  var a$13 = inp.createdAt_lte;
  var a$14 = inp.priceMax_lte;
  var a$15 = inp.purchases_in;
  var a$16 = inp._id_gte;
  var a$17 = inp.priceMin_gte;
  var a$18 = inp.gifteeId_lt;
  var a$19 = inp.interests;
  var a$20 = inp.priceMin_gt;
  var a$21 = inp.userId_exists;
  var a$22 = inp.profile_nin;
  var a$23 = inp.updatedAt_nin;
  var a$24 = inp.OR;
  var a$25 = inp.gifteeId_lte;
  var a$26 = inp.updatedAt_exists;
  var a$27 = inp.priceMin_lte;
  var a$28 = inp._id_nin;
  var a$29 = inp.updatedAt_ne;
  var a$30 = inp._id_in;
  var a$31 = inp.priceMax_nin;
  var a$32 = inp.gifteeId_exists;
  var a$33 = inp.priceMin_nin;
  var a$34 = inp.createdAt_lt;
  var a$35 = inp.priceMax_exists;
  var a$36 = inp.recommendationsByCategory_in;
  var a$37 = inp._id_lt;
  var a$38 = inp.priceMax;
  var a$39 = inp.userId_in;
  var a$40 = inp.createdAt_nin;
  var a$41 = inp.gender_nin;
  var a$42 = inp.recommendations_in;
  var a$43 = inp.userId_ne;
  var a$44 = inp.priceMin;
  var a$45 = inp._id_lte;
  var a$46 = inp._id_gt;
  var a$47 = inp.priceMax_ne;
  var a$48 = inp.updatedAt;
  var a$49 = inp.gifteeId_gt;
  var a$50 = inp.updatedAt_gt;
  var a$51 = inp.updatedAt_gte;
  var a$52 = inp.userId_nin;
  var a$53 = inp.purchases;
  var a$54 = inp.gifteeId_gte;
  var a$55 = inp.recommendationsByCategory;
  var a$56 = inp.gender_ne;
  var a$57 = inp.userId_lte;
  var a$58 = inp.createdAt_in;
  var a$59 = inp.priceMax_lt;
  var a$60 = inp.gifteeId_ne;
  var a$61 = inp.purchases_exists;
  var a$62 = inp.priceMax_in;
  var a$63 = inp.profile_exists;
  var a$64 = inp.userId_gt;
  var a$65 = inp.recommendations_nin;
  var a$66 = inp.gender_exists;
  var a$67 = inp.gender_lte;
  var a$68 = inp.profile;
  var a$69 = inp.priceMin_ne;
  var a$70 = inp.gender;
  var a$71 = inp.priceMin_exists;
  var a$72 = inp.gender_lt;
  var a$73 = inp.createdAt;
  var a$74 = inp.gifteeId_nin;
  var a$75 = inp._id_exists;
  var a$76 = inp.gifteeId;
  var a$77 = inp.gender_gt;
  var a$78 = inp.updatedAt_in;
  var a$79 = inp.userId_lt;
  var a$80 = inp.priceMax_gt;
  var a$81 = inp.gender_gte;
  var a$82 = inp.purchases_nin;
  var a$83 = inp.gifteeId_in;
  var a$84 = inp.priceMax_gte;
  var a$85 = inp.interests_nin;
  var a$86 = inp.profile_in;
  var a$87 = inp.userId_gte;
  var a$88 = inp.interests_exists;
  var a$89 = inp.priceMin_in;
  var a$90 = inp.updatedAt_lt;
  var a$91 = inp.createdAt_ne;
  var a$92 = inp.recommendations;
  var a$93 = inp.interests_in;
  return {
          recommendations_exists: a !== undefined ? a : undefined,
          recommendationsByCategory_nin: a$1 !== undefined ? a$1.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput$1(b);
                  }
                  
                }) : undefined,
          createdAt_gt: a$2 !== undefined ? Caml_option.valFromOption(a$2) : undefined,
          recommendationsByCategory_exists: a$3 !== undefined ? a$3 : undefined,
          updatedAt_lte: a$4 !== undefined ? Caml_option.valFromOption(a$4) : undefined,
          createdAt_gte: a$5 !== undefined ? Caml_option.valFromOption(a$5) : undefined,
          priceMin_lt: a$6 !== undefined ? a$6 : undefined,
          gender_in: a$7 !== undefined ? a$7.map(function (b) {
                  if (b !== undefined) {
                    if (b === "UNISEX") {
                      return "UNISEX";
                    } else if (b === "MALE") {
                      return "MALE";
                    } else {
                      return "FEMALE";
                    }
                  }
                  
                }) : undefined,
          createdAt_exists: a$8 !== undefined ? a$8 : undefined,
          userId: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          AND: a$10 !== undefined ? a$10.map(serializeInputObjectGiftProfileQueryInput$1) : undefined,
          _id_ne: a$11 !== undefined ? Caml_option.valFromOption(a$11) : undefined,
          _id: a$12 !== undefined ? Caml_option.valFromOption(a$12) : undefined,
          createdAt_lte: a$13 !== undefined ? Caml_option.valFromOption(a$13) : undefined,
          priceMax_lte: a$14 !== undefined ? a$14 : undefined,
          purchases_in: a$15 !== undefined ? a$15.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfilePurchasesQueryInput$1(b);
                  }
                  
                }) : undefined,
          _id_gte: a$16 !== undefined ? Caml_option.valFromOption(a$16) : undefined,
          priceMin_gte: a$17 !== undefined ? a$17 : undefined,
          gifteeId_lt: a$18 !== undefined ? Caml_option.valFromOption(a$18) : undefined,
          interests: a$19 !== undefined ? a$19.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          priceMin_gt: a$20 !== undefined ? a$20 : undefined,
          userId_exists: a$21 !== undefined ? a$21 : undefined,
          profile_nin: a$22 !== undefined ? a$22.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileProfileQueryInput$1(b);
                  }
                  
                }) : undefined,
          updatedAt_nin: a$23 !== undefined ? a$23.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          OR: a$24 !== undefined ? a$24.map(serializeInputObjectGiftProfileQueryInput$1) : undefined,
          gifteeId_lte: a$25 !== undefined ? Caml_option.valFromOption(a$25) : undefined,
          updatedAt_exists: a$26 !== undefined ? a$26 : undefined,
          priceMin_lte: a$27 !== undefined ? a$27 : undefined,
          _id_nin: a$28 !== undefined ? a$28.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          updatedAt_ne: a$29 !== undefined ? Caml_option.valFromOption(a$29) : undefined,
          _id_in: a$30 !== undefined ? a$30.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          priceMax_nin: a$31 !== undefined ? a$31.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          gifteeId_exists: a$32 !== undefined ? a$32 : undefined,
          priceMin_nin: a$33 !== undefined ? a$33.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          createdAt_lt: a$34 !== undefined ? Caml_option.valFromOption(a$34) : undefined,
          priceMax_exists: a$35 !== undefined ? a$35 : undefined,
          recommendationsByCategory_in: a$36 !== undefined ? a$36.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput$1(b);
                  }
                  
                }) : undefined,
          _id_lt: a$37 !== undefined ? Caml_option.valFromOption(a$37) : undefined,
          priceMax: a$38 !== undefined ? a$38 : undefined,
          userId_in: a$39 !== undefined ? a$39.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          createdAt_nin: a$40 !== undefined ? a$40.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          gender_nin: a$41 !== undefined ? a$41.map(function (b) {
                  if (b !== undefined) {
                    if (b === "UNISEX") {
                      return "UNISEX";
                    } else if (b === "MALE") {
                      return "MALE";
                    } else {
                      return "FEMALE";
                    }
                  }
                  
                }) : undefined,
          recommendations_in: a$42 !== undefined ? a$42.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsQueryInput$1(b);
                  }
                  
                }) : undefined,
          userId_ne: a$43 !== undefined ? Caml_option.valFromOption(a$43) : undefined,
          priceMin: a$44 !== undefined ? a$44 : undefined,
          _id_lte: a$45 !== undefined ? Caml_option.valFromOption(a$45) : undefined,
          _id_gt: a$46 !== undefined ? Caml_option.valFromOption(a$46) : undefined,
          priceMax_ne: a$47 !== undefined ? a$47 : undefined,
          updatedAt: a$48 !== undefined ? Caml_option.valFromOption(a$48) : undefined,
          gifteeId_gt: a$49 !== undefined ? Caml_option.valFromOption(a$49) : undefined,
          updatedAt_gt: a$50 !== undefined ? Caml_option.valFromOption(a$50) : undefined,
          updatedAt_gte: a$51 !== undefined ? Caml_option.valFromOption(a$51) : undefined,
          userId_nin: a$52 !== undefined ? a$52.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          purchases: a$53 !== undefined ? a$53.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfilePurchasesQueryInput$1(b);
                  }
                  
                }) : undefined,
          gifteeId_gte: a$54 !== undefined ? Caml_option.valFromOption(a$54) : undefined,
          recommendationsByCategory: a$55 !== undefined ? a$55.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput$1(b);
                  }
                  
                }) : undefined,
          gender_ne: a$56 !== undefined ? (
              a$56 === "UNISEX" ? "UNISEX" : (
                  a$56 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          userId_lte: a$57 !== undefined ? Caml_option.valFromOption(a$57) : undefined,
          createdAt_in: a$58 !== undefined ? a$58.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          priceMax_lt: a$59 !== undefined ? a$59 : undefined,
          gifteeId_ne: a$60 !== undefined ? Caml_option.valFromOption(a$60) : undefined,
          purchases_exists: a$61 !== undefined ? a$61 : undefined,
          priceMax_in: a$62 !== undefined ? a$62.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          profile_exists: a$63 !== undefined ? a$63 : undefined,
          userId_gt: a$64 !== undefined ? Caml_option.valFromOption(a$64) : undefined,
          recommendations_nin: a$65 !== undefined ? a$65.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsQueryInput$1(b);
                  }
                  
                }) : undefined,
          gender_exists: a$66 !== undefined ? a$66 : undefined,
          gender_lte: a$67 !== undefined ? (
              a$67 === "UNISEX" ? "UNISEX" : (
                  a$67 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          profile: a$68 !== undefined ? a$68.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileProfileQueryInput$1(b);
                  }
                  
                }) : undefined,
          priceMin_ne: a$69 !== undefined ? a$69 : undefined,
          gender: a$70 !== undefined ? (
              a$70 === "UNISEX" ? "UNISEX" : (
                  a$70 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          priceMin_exists: a$71 !== undefined ? a$71 : undefined,
          gender_lt: a$72 !== undefined ? (
              a$72 === "UNISEX" ? "UNISEX" : (
                  a$72 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          createdAt: a$73 !== undefined ? Caml_option.valFromOption(a$73) : undefined,
          gifteeId_nin: a$74 !== undefined ? a$74.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          _id_exists: a$75 !== undefined ? a$75 : undefined,
          gifteeId: a$76 !== undefined ? Caml_option.valFromOption(a$76) : undefined,
          gender_gt: a$77 !== undefined ? (
              a$77 === "UNISEX" ? "UNISEX" : (
                  a$77 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          updatedAt_in: a$78 !== undefined ? a$78.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          userId_lt: a$79 !== undefined ? Caml_option.valFromOption(a$79) : undefined,
          priceMax_gt: a$80 !== undefined ? a$80 : undefined,
          gender_gte: a$81 !== undefined ? (
              a$81 === "UNISEX" ? "UNISEX" : (
                  a$81 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          purchases_nin: a$82 !== undefined ? a$82.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfilePurchasesQueryInput$1(b);
                  }
                  
                }) : undefined,
          gifteeId_in: a$83 !== undefined ? a$83.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          priceMax_gte: a$84 !== undefined ? a$84 : undefined,
          interests_nin: a$85 !== undefined ? a$85.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          profile_in: a$86 !== undefined ? a$86.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileProfileQueryInput$1(b);
                  }
                  
                }) : undefined,
          userId_gte: a$87 !== undefined ? Caml_option.valFromOption(a$87) : undefined,
          interests_exists: a$88 !== undefined ? a$88 : undefined,
          priceMin_in: a$89 !== undefined ? a$89.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          updatedAt_lt: a$90 !== undefined ? Caml_option.valFromOption(a$90) : undefined,
          createdAt_ne: a$91 !== undefined ? Caml_option.valFromOption(a$91) : undefined,
          recommendations: a$92 !== undefined ? a$92.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsQueryInput$1(b);
                  }
                  
                }) : undefined,
          interests_in: a$93 !== undefined ? a$93.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined
        };
}

function serializeInputObjectGiftProfileProfileQueryInput$1(inp) {
  var a = inp.interest;
  var a$1 = inp.productId_lt;
  var a$2 = inp.productId;
  var a$3 = inp.title_lte;
  var a$4 = inp.title_gte;
  var a$5 = inp.weight_gte;
  var a$6 = inp.interest_in;
  var a$7 = inp.productId_exists;
  var a$8 = inp.weight_exists;
  var a$9 = inp.weight_ne;
  var a$10 = inp.title_lt;
  var a$11 = inp.interest_gt;
  var a$12 = inp.weight_gt;
  var a$13 = inp.productId_in;
  var a$14 = inp.productId_ne;
  var a$15 = inp.image_lte;
  var a$16 = inp.weight_in;
  var a$17 = inp.OR;
  var a$18 = inp.image_gt;
  var a$19 = inp.AND;
  var a$20 = inp.title_gt;
  var a$21 = inp.weight;
  var a$22 = inp.interest_lte;
  var a$23 = inp.productId_gt;
  var a$24 = inp.interest_gte;
  var a$25 = inp.image_nin;
  var a$26 = inp.interest_nin;
  var a$27 = inp.title;
  var a$28 = inp.interest_lt;
  var a$29 = inp.weight_lte;
  var a$30 = inp.image_gte;
  var a$31 = inp.interest_exists;
  var a$32 = inp.productId_nin;
  var a$33 = inp.interest_ne;
  var a$34 = inp.title_exists;
  var a$35 = inp.title_ne;
  var a$36 = inp.title_in;
  var a$37 = inp.weight_lt;
  var a$38 = inp.image_ne;
  var a$39 = inp.weight_nin;
  var a$40 = inp.image_lt;
  var a$41 = inp.productId_gte;
  var a$42 = inp.image_in;
  var a$43 = inp.productId_lte;
  var a$44 = inp.image_exists;
  var a$45 = inp.title_nin;
  var a$46 = inp.image;
  return {
          interest: a !== undefined ? a : undefined,
          productId_lt: a$1 !== undefined ? a$1 : undefined,
          productId: a$2 !== undefined ? a$2 : undefined,
          title_lte: a$3 !== undefined ? a$3 : undefined,
          title_gte: a$4 !== undefined ? a$4 : undefined,
          weight_gte: a$5 !== undefined ? Caml_option.valFromOption(a$5) : undefined,
          interest_in: a$6 !== undefined ? a$6.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          productId_exists: a$7 !== undefined ? a$7 : undefined,
          weight_exists: a$8 !== undefined ? a$8 : undefined,
          weight_ne: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          title_lt: a$10 !== undefined ? a$10 : undefined,
          interest_gt: a$11 !== undefined ? a$11 : undefined,
          weight_gt: a$12 !== undefined ? Caml_option.valFromOption(a$12) : undefined,
          productId_in: a$13 !== undefined ? a$13.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          productId_ne: a$14 !== undefined ? a$14 : undefined,
          image_lte: a$15 !== undefined ? a$15 : undefined,
          weight_in: a$16 !== undefined ? a$16.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          OR: a$17 !== undefined ? a$17.map(serializeInputObjectGiftProfileProfileQueryInput$1) : undefined,
          image_gt: a$18 !== undefined ? a$18 : undefined,
          AND: a$19 !== undefined ? a$19.map(serializeInputObjectGiftProfileProfileQueryInput$1) : undefined,
          title_gt: a$20 !== undefined ? a$20 : undefined,
          weight: a$21 !== undefined ? Caml_option.valFromOption(a$21) : undefined,
          interest_lte: a$22 !== undefined ? a$22 : undefined,
          productId_gt: a$23 !== undefined ? a$23 : undefined,
          interest_gte: a$24 !== undefined ? a$24 : undefined,
          image_nin: a$25 !== undefined ? a$25.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          interest_nin: a$26 !== undefined ? a$26.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          title: a$27 !== undefined ? a$27 : undefined,
          interest_lt: a$28 !== undefined ? a$28 : undefined,
          weight_lte: a$29 !== undefined ? Caml_option.valFromOption(a$29) : undefined,
          image_gte: a$30 !== undefined ? a$30 : undefined,
          interest_exists: a$31 !== undefined ? a$31 : undefined,
          productId_nin: a$32 !== undefined ? a$32.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          interest_ne: a$33 !== undefined ? a$33 : undefined,
          title_exists: a$34 !== undefined ? a$34 : undefined,
          title_ne: a$35 !== undefined ? a$35 : undefined,
          title_in: a$36 !== undefined ? a$36.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          weight_lt: a$37 !== undefined ? Caml_option.valFromOption(a$37) : undefined,
          image_ne: a$38 !== undefined ? a$38 : undefined,
          weight_nin: a$39 !== undefined ? a$39.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          image_lt: a$40 !== undefined ? a$40 : undefined,
          productId_gte: a$41 !== undefined ? a$41 : undefined,
          image_in: a$42 !== undefined ? a$42.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          productId_lte: a$43 !== undefined ? a$43 : undefined,
          image_exists: a$44 !== undefined ? a$44 : undefined,
          title_nin: a$45 !== undefined ? a$45.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image: a$46 !== undefined ? a$46 : undefined
        };
}

function serializeInputObjectGiftProfilePurchasesQueryInput$1(inp) {
  var a = inp.giftIdeaId_ne;
  var a$1 = inp.date_exists;
  var a$2 = inp.targetYear_gt;
  var a$3 = inp.targetYear_in;
  var a$4 = inp.OR;
  var a$5 = inp.date_gt;
  var a$6 = inp.transactionId_gt;
  var a$7 = inp.transactionId_lt;
  var a$8 = inp.AND;
  var a$9 = inp.date_in;
  var a$10 = inp.targetYear_gte;
  var a$11 = inp.transactionId;
  var a$12 = inp.giftIdeaId_lt;
  var a$13 = inp.transactionId_nin;
  var a$14 = inp.transactionId_in;
  var a$15 = inp.giftIdeaId_nin;
  var a$16 = inp.giftIdeaId_in;
  var a$17 = inp.giftIdeaId_gte;
  var a$18 = inp.targetYear_nin;
  var a$19 = inp.targetYear_lt;
  var a$20 = inp.transactionId_gte;
  var a$21 = inp.giftIdeaId_gt;
  var a$22 = inp.targetYear_ne;
  var a$23 = inp.targetYear;
  var a$24 = inp.date_nin;
  var a$25 = inp.date_gte;
  var a$26 = inp.giftIdeaId_exists;
  var a$27 = inp.targetYear_exists;
  var a$28 = inp.transactionId_lte;
  var a$29 = inp.transactionId_ne;
  var a$30 = inp.transactionId_exists;
  var a$31 = inp.targetYear_lte;
  var a$32 = inp.date_lt;
  var a$33 = inp.date_ne;
  var a$34 = inp.giftIdeaId;
  var a$35 = inp.giftIdeaId_lte;
  var a$36 = inp.date_lte;
  var a$37 = inp.date;
  return {
          giftIdeaId_ne: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          date_exists: a$1 !== undefined ? a$1 : undefined,
          targetYear_gt: a$2 !== undefined ? a$2 : undefined,
          targetYear_in: a$3 !== undefined ? a$3.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          OR: a$4 !== undefined ? a$4.map(serializeInputObjectGiftProfilePurchasesQueryInput$1) : undefined,
          date_gt: a$5 !== undefined ? Caml_option.valFromOption(a$5) : undefined,
          transactionId_gt: a$6 !== undefined ? Caml_option.valFromOption(a$6) : undefined,
          transactionId_lt: a$7 !== undefined ? Caml_option.valFromOption(a$7) : undefined,
          AND: a$8 !== undefined ? a$8.map(serializeInputObjectGiftProfilePurchasesQueryInput$1) : undefined,
          date_in: a$9 !== undefined ? a$9.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          targetYear_gte: a$10 !== undefined ? a$10 : undefined,
          transactionId: a$11 !== undefined ? Caml_option.valFromOption(a$11) : undefined,
          giftIdeaId_lt: a$12 !== undefined ? Caml_option.valFromOption(a$12) : undefined,
          transactionId_nin: a$13 !== undefined ? a$13.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          transactionId_in: a$14 !== undefined ? a$14.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          giftIdeaId_nin: a$15 !== undefined ? a$15.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          giftIdeaId_in: a$16 !== undefined ? a$16.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          giftIdeaId_gte: a$17 !== undefined ? Caml_option.valFromOption(a$17) : undefined,
          targetYear_nin: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          targetYear_lt: a$19 !== undefined ? a$19 : undefined,
          transactionId_gte: a$20 !== undefined ? Caml_option.valFromOption(a$20) : undefined,
          giftIdeaId_gt: a$21 !== undefined ? Caml_option.valFromOption(a$21) : undefined,
          targetYear_ne: a$22 !== undefined ? a$22 : undefined,
          targetYear: a$23 !== undefined ? a$23 : undefined,
          date_nin: a$24 !== undefined ? a$24.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          date_gte: a$25 !== undefined ? Caml_option.valFromOption(a$25) : undefined,
          giftIdeaId_exists: a$26 !== undefined ? a$26 : undefined,
          targetYear_exists: a$27 !== undefined ? a$27 : undefined,
          transactionId_lte: a$28 !== undefined ? Caml_option.valFromOption(a$28) : undefined,
          transactionId_ne: a$29 !== undefined ? Caml_option.valFromOption(a$29) : undefined,
          transactionId_exists: a$30 !== undefined ? a$30 : undefined,
          targetYear_lte: a$31 !== undefined ? a$31 : undefined,
          date_lt: a$32 !== undefined ? Caml_option.valFromOption(a$32) : undefined,
          date_ne: a$33 !== undefined ? Caml_option.valFromOption(a$33) : undefined,
          giftIdeaId: a$34 !== undefined ? Caml_option.valFromOption(a$34) : undefined,
          giftIdeaId_lte: a$35 !== undefined ? Caml_option.valFromOption(a$35) : undefined,
          date_lte: a$36 !== undefined ? Caml_option.valFromOption(a$36) : undefined,
          date: a$37 !== undefined ? Caml_option.valFromOption(a$37) : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsQueryInput$1(inp) {
  var a = inp.similarity_gt;
  var a$1 = inp.similarity_lt;
  var a$2 = inp.OR;
  var a$3 = inp.interest_gt;
  var a$4 = inp.title_lte;
  var a$5 = inp.title_gt;
  var a$6 = inp.title_nin;
  var a$7 = inp.giftSource_nin;
  var a$8 = inp.title_exists;
  var a$9 = inp.giftSource_ne;
  var a$10 = inp.url_in;
  var a$11 = inp._id_lte;
  var a$12 = inp.price_exists;
  var a$13 = inp.similarity_exists;
  var a$14 = inp._id_gt;
  var a$15 = inp.similarity_ne;
  var a$16 = inp.price_ne;
  var a$17 = inp.interest_nin;
  var a$18 = inp.similarity_nin;
  var a$19 = inp.avgReview_gt;
  var a$20 = inp.interest_lte;
  var a$21 = inp.price_gt;
  var a$22 = inp.url_lt;
  var a$23 = inp.giftSource_exists;
  var a$24 = inp._id_gte;
  var a$25 = inp._id_in;
  var a$26 = inp.interest_gte;
  var a$27 = inp.price;
  var a$28 = inp.title_gte;
  var a$29 = inp.price_lt;
  var a$30 = inp.image_in;
  var a$31 = inp.image_lt;
  var a$32 = inp.interest_ne;
  var a$33 = inp.giftSource_lte;
  var a$34 = inp.avgReview_nin;
  var a$35 = inp.similarity_in;
  var a$36 = inp.url_lte;
  var a$37 = inp._id_lt;
  var a$38 = inp.price_lte;
  var a$39 = inp.title;
  var a$40 = inp.url_gte;
  var a$41 = inp.url_ne;
  var a$42 = inp.avgReview_lt;
  var a$43 = inp.price_gte;
  var a$44 = inp.similarity_lte;
  var a$45 = inp.image_ne;
  var a$46 = inp.description_lt;
  var a$47 = inp.giftProvider;
  var a$48 = inp._id_exists;
  var a$49 = inp.description_gt;
  var a$50 = inp._id_ne;
  var a$51 = inp.interest_exists;
  var a$52 = inp.avgReview;
  var a$53 = inp.giftSource_gte;
  var a$54 = inp.interest;
  var a$55 = inp.url_gt;
  var a$56 = inp.image_gt;
  var a$57 = inp.AND;
  var a$58 = inp.avgReview_in;
  var a$59 = inp.image;
  var a$60 = inp.avgReview_exists;
  var a$61 = inp.description_lte;
  var a$62 = inp._id_nin;
  var a$63 = inp.image_nin;
  var a$64 = inp.similarity;
  var a$65 = inp.avgReview_gte;
  var a$66 = inp.associatedProducts_exists;
  var a$67 = inp.associatedProducts_in;
  var a$68 = inp.giftSource;
  var a$69 = inp.description_gte;
  var a$70 = inp.description_nin;
  var a$71 = inp.description_exists;
  var a$72 = inp.title_in;
  var a$73 = inp.similarity_gte;
  var a$74 = inp.giftSource_gt;
  var a$75 = inp.url_nin;
  var a$76 = inp.interest_lt;
  var a$77 = inp.giftProvider_exists;
  var a$78 = inp.avgReview_lte;
  var a$79 = inp._id;
  var a$80 = inp.title_lt;
  var a$81 = inp.avgReview_ne;
  var a$82 = inp.image_exists;
  var a$83 = inp.price_in;
  var a$84 = inp.title_ne;
  var a$85 = inp.interest_in;
  var a$86 = inp.giftSource_lt;
  var a$87 = inp.description_in;
  var a$88 = inp.description_ne;
  var a$89 = inp.url;
  var a$90 = inp.url_exists;
  var a$91 = inp.image_gte;
  var a$92 = inp.giftSource_in;
  var a$93 = inp.associatedProducts;
  var a$94 = inp.associatedProducts_nin;
  var a$95 = inp.price_nin;
  var a$96 = inp.description;
  var a$97 = inp.image_lte;
  return {
          similarity_gt: a !== undefined ? a : undefined,
          similarity_lt: a$1 !== undefined ? a$1 : undefined,
          OR: a$2 !== undefined ? a$2.map(serializeInputObjectGiftProfileRecommendationsQueryInput$1) : undefined,
          interest_gt: a$3 !== undefined ? a$3 : undefined,
          title_lte: a$4 !== undefined ? a$4 : undefined,
          title_gt: a$5 !== undefined ? a$5 : undefined,
          title_nin: a$6 !== undefined ? a$6.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          giftSource_nin: a$7 !== undefined ? a$7.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          title_exists: a$8 !== undefined ? a$8 : undefined,
          giftSource_ne: a$9 !== undefined ? a$9 : undefined,
          url_in: a$10 !== undefined ? a$10.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          _id_lte: a$11 !== undefined ? Caml_option.valFromOption(a$11) : undefined,
          price_exists: a$12 !== undefined ? a$12 : undefined,
          similarity_exists: a$13 !== undefined ? a$13 : undefined,
          _id_gt: a$14 !== undefined ? Caml_option.valFromOption(a$14) : undefined,
          similarity_ne: a$15 !== undefined ? a$15 : undefined,
          price_ne: a$16 !== undefined ? a$16 : undefined,
          interest_nin: a$17 !== undefined ? a$17.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          similarity_nin: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          avgReview_gt: a$19 !== undefined ? a$19 : undefined,
          interest_lte: a$20 !== undefined ? a$20 : undefined,
          price_gt: a$21 !== undefined ? a$21 : undefined,
          url_lt: a$22 !== undefined ? a$22 : undefined,
          giftSource_exists: a$23 !== undefined ? a$23 : undefined,
          _id_gte: a$24 !== undefined ? Caml_option.valFromOption(a$24) : undefined,
          _id_in: a$25 !== undefined ? a$25.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          interest_gte: a$26 !== undefined ? a$26 : undefined,
          price: a$27 !== undefined ? a$27 : undefined,
          title_gte: a$28 !== undefined ? a$28 : undefined,
          price_lt: a$29 !== undefined ? a$29 : undefined,
          image_in: a$30 !== undefined ? a$30.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image_lt: a$31 !== undefined ? a$31 : undefined,
          interest_ne: a$32 !== undefined ? a$32 : undefined,
          giftSource_lte: a$33 !== undefined ? a$33 : undefined,
          avgReview_nin: a$34 !== undefined ? a$34.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          similarity_in: a$35 !== undefined ? a$35.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          url_lte: a$36 !== undefined ? a$36 : undefined,
          _id_lt: a$37 !== undefined ? Caml_option.valFromOption(a$37) : undefined,
          price_lte: a$38 !== undefined ? a$38 : undefined,
          title: a$39 !== undefined ? a$39 : undefined,
          url_gte: a$40 !== undefined ? a$40 : undefined,
          url_ne: a$41 !== undefined ? a$41 : undefined,
          avgReview_lt: a$42 !== undefined ? a$42 : undefined,
          price_gte: a$43 !== undefined ? a$43 : undefined,
          similarity_lte: a$44 !== undefined ? a$44 : undefined,
          image_ne: a$45 !== undefined ? a$45 : undefined,
          description_lt: a$46 !== undefined ? a$46 : undefined,
          giftProvider: a$47 !== undefined ? serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput$1(a$47) : undefined,
          _id_exists: a$48 !== undefined ? a$48 : undefined,
          description_gt: a$49 !== undefined ? a$49 : undefined,
          _id_ne: a$50 !== undefined ? Caml_option.valFromOption(a$50) : undefined,
          interest_exists: a$51 !== undefined ? a$51 : undefined,
          avgReview: a$52 !== undefined ? a$52 : undefined,
          giftSource_gte: a$53 !== undefined ? a$53 : undefined,
          interest: a$54 !== undefined ? a$54 : undefined,
          url_gt: a$55 !== undefined ? a$55 : undefined,
          image_gt: a$56 !== undefined ? a$56 : undefined,
          AND: a$57 !== undefined ? a$57.map(serializeInputObjectGiftProfileRecommendationsQueryInput$1) : undefined,
          avgReview_in: a$58 !== undefined ? a$58.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image: a$59 !== undefined ? a$59 : undefined,
          avgReview_exists: a$60 !== undefined ? a$60 : undefined,
          description_lte: a$61 !== undefined ? a$61 : undefined,
          _id_nin: a$62 !== undefined ? a$62.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          image_nin: a$63 !== undefined ? a$63.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          similarity: a$64 !== undefined ? a$64 : undefined,
          avgReview_gte: a$65 !== undefined ? a$65 : undefined,
          associatedProducts_exists: a$66 !== undefined ? a$66 : undefined,
          associatedProducts_in: a$67 !== undefined ? a$67.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput$1(b);
                  }
                  
                }) : undefined,
          giftSource: a$68 !== undefined ? a$68 : undefined,
          description_gte: a$69 !== undefined ? a$69 : undefined,
          description_nin: a$70 !== undefined ? a$70.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          description_exists: a$71 !== undefined ? a$71 : undefined,
          title_in: a$72 !== undefined ? a$72.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          similarity_gte: a$73 !== undefined ? a$73 : undefined,
          giftSource_gt: a$74 !== undefined ? a$74 : undefined,
          url_nin: a$75 !== undefined ? a$75.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          interest_lt: a$76 !== undefined ? a$76 : undefined,
          giftProvider_exists: a$77 !== undefined ? a$77 : undefined,
          avgReview_lte: a$78 !== undefined ? a$78 : undefined,
          _id: a$79 !== undefined ? Caml_option.valFromOption(a$79) : undefined,
          title_lt: a$80 !== undefined ? a$80 : undefined,
          avgReview_ne: a$81 !== undefined ? a$81 : undefined,
          image_exists: a$82 !== undefined ? a$82 : undefined,
          price_in: a$83 !== undefined ? a$83.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          title_ne: a$84 !== undefined ? a$84 : undefined,
          interest_in: a$85 !== undefined ? a$85.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          giftSource_lt: a$86 !== undefined ? a$86 : undefined,
          description_in: a$87 !== undefined ? a$87.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          description_ne: a$88 !== undefined ? a$88 : undefined,
          url: a$89 !== undefined ? a$89 : undefined,
          url_exists: a$90 !== undefined ? a$90 : undefined,
          image_gte: a$91 !== undefined ? a$91 : undefined,
          giftSource_in: a$92 !== undefined ? a$92.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          associatedProducts: a$93 !== undefined ? a$93.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput$1(b);
                  }
                  
                }) : undefined,
          associatedProducts_nin: a$94 !== undefined ? a$94.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput$1(b);
                  }
                  
                }) : undefined,
          price_nin: a$95 !== undefined ? a$95.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          description: a$96 !== undefined ? a$96 : undefined,
          image_lte: a$97 !== undefined ? a$97 : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput$1(inp) {
  var a = inp.logo_exists;
  var a$1 = inp.logo_in;
  var a$2 = inp.logo_ne;
  var a$3 = inp.name_exists;
  var a$4 = inp.logo_gte;
  var a$5 = inp.name_nin;
  var a$6 = inp.logo;
  var a$7 = inp.name_ne;
  var a$8 = inp.name_lte;
  var a$9 = inp.logo_gt;
  var a$10 = inp.name;
  var a$11 = inp.name_lt;
  var a$12 = inp.logo_lt;
  var a$13 = inp.name_gt;
  var a$14 = inp.logo_nin;
  var a$15 = inp.name_gte;
  var a$16 = inp.AND;
  var a$17 = inp.OR;
  var a$18 = inp.logo_lte;
  var a$19 = inp.name_in;
  return {
          logo_exists: a !== undefined ? a : undefined,
          logo_in: a$1 !== undefined ? a$1.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          logo_ne: a$2 !== undefined ? a$2 : undefined,
          name_exists: a$3 !== undefined ? a$3 : undefined,
          logo_gte: a$4 !== undefined ? a$4 : undefined,
          name_nin: a$5 !== undefined ? a$5.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          logo: a$6 !== undefined ? a$6 : undefined,
          name_ne: a$7 !== undefined ? a$7 : undefined,
          name_lte: a$8 !== undefined ? a$8 : undefined,
          logo_gt: a$9 !== undefined ? a$9 : undefined,
          name: a$10 !== undefined ? a$10 : undefined,
          name_lt: a$11 !== undefined ? a$11 : undefined,
          logo_lt: a$12 !== undefined ? a$12 : undefined,
          name_gt: a$13 !== undefined ? a$13 : undefined,
          logo_nin: a$14 !== undefined ? a$14.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          name_gte: a$15 !== undefined ? a$15 : undefined,
          AND: a$16 !== undefined ? a$16.map(serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput$1) : undefined,
          OR: a$17 !== undefined ? a$17.map(serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput$1) : undefined,
          logo_lte: a$18 !== undefined ? a$18 : undefined,
          name_in: a$19 !== undefined ? a$19.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput$1(inp) {
  var a = inp.productId_lt;
  var a$1 = inp.weight;
  var a$2 = inp.productId_in;
  var a$3 = inp.productId_lte;
  var a$4 = inp.OR;
  var a$5 = inp.weight_lte;
  var a$6 = inp.image_ne;
  var a$7 = inp.weight_nin;
  var a$8 = inp.weight_gt;
  var a$9 = inp.title_lt;
  var a$10 = inp.image_lt;
  var a$11 = inp.image_gt;
  var a$12 = inp.productId;
  var a$13 = inp.title_ne;
  var a$14 = inp.image_in;
  var a$15 = inp.productId_ne;
  var a$16 = inp.productId_gte;
  var a$17 = inp.image_exists;
  var a$18 = inp.weight_in;
  var a$19 = inp.AND;
  var a$20 = inp.image_gte;
  var a$21 = inp.weight_gte;
  var a$22 = inp.weight_lt;
  var a$23 = inp.weight_ne;
  var a$24 = inp.title_nin;
  var a$25 = inp.title_exists;
  var a$26 = inp.productId_exists;
  var a$27 = inp.title_lte;
  var a$28 = inp.productId_gt;
  var a$29 = inp.title_gte;
  var a$30 = inp.title_gt;
  var a$31 = inp.image;
  var a$32 = inp.title;
  var a$33 = inp.productId_nin;
  var a$34 = inp.title_in;
  var a$35 = inp.image_nin;
  var a$36 = inp.image_lte;
  var a$37 = inp.weight_exists;
  return {
          productId_lt: a !== undefined ? a : undefined,
          weight: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          productId_in: a$2 !== undefined ? a$2.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          productId_lte: a$3 !== undefined ? a$3 : undefined,
          OR: a$4 !== undefined ? a$4.map(serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput$1) : undefined,
          weight_lte: a$5 !== undefined ? Caml_option.valFromOption(a$5) : undefined,
          image_ne: a$6 !== undefined ? a$6 : undefined,
          weight_nin: a$7 !== undefined ? a$7.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          weight_gt: a$8 !== undefined ? Caml_option.valFromOption(a$8) : undefined,
          title_lt: a$9 !== undefined ? a$9 : undefined,
          image_lt: a$10 !== undefined ? a$10 : undefined,
          image_gt: a$11 !== undefined ? a$11 : undefined,
          productId: a$12 !== undefined ? a$12 : undefined,
          title_ne: a$13 !== undefined ? a$13 : undefined,
          image_in: a$14 !== undefined ? a$14.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          productId_ne: a$15 !== undefined ? a$15 : undefined,
          productId_gte: a$16 !== undefined ? a$16 : undefined,
          image_exists: a$17 !== undefined ? a$17 : undefined,
          weight_in: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          AND: a$19 !== undefined ? a$19.map(serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput$1) : undefined,
          image_gte: a$20 !== undefined ? a$20 : undefined,
          weight_gte: a$21 !== undefined ? Caml_option.valFromOption(a$21) : undefined,
          weight_lt: a$22 !== undefined ? Caml_option.valFromOption(a$22) : undefined,
          weight_ne: a$23 !== undefined ? Caml_option.valFromOption(a$23) : undefined,
          title_nin: a$24 !== undefined ? a$24.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          title_exists: a$25 !== undefined ? a$25 : undefined,
          productId_exists: a$26 !== undefined ? a$26 : undefined,
          title_lte: a$27 !== undefined ? a$27 : undefined,
          productId_gt: a$28 !== undefined ? a$28 : undefined,
          title_gte: a$29 !== undefined ? a$29 : undefined,
          title_gt: a$30 !== undefined ? a$30 : undefined,
          image: a$31 !== undefined ? a$31 : undefined,
          title: a$32 !== undefined ? a$32 : undefined,
          productId_nin: a$33 !== undefined ? a$33.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          title_in: a$34 !== undefined ? a$34.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image_nin: a$35 !== undefined ? a$35.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image_lte: a$36 !== undefined ? a$36 : undefined,
          weight_exists: a$37 !== undefined ? a$37 : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput$1(inp) {
  var a = inp.logo_in;
  var a$1 = inp.name;
  var a$2 = inp.name_ne;
  var a$3 = inp.logo_nin;
  var a$4 = inp.logo_gt;
  var a$5 = inp.name_exists;
  var a$6 = inp.logo_exists;
  var a$7 = inp.name_gte;
  var a$8 = inp.logo_lte;
  var a$9 = inp.logo_lt;
  var a$10 = inp.AND;
  var a$11 = inp.name_lt;
  var a$12 = inp.name_in;
  var a$13 = inp.logo;
  var a$14 = inp.logo_ne;
  var a$15 = inp.name_gt;
  var a$16 = inp.name_lte;
  var a$17 = inp.name_nin;
  var a$18 = inp.logo_gte;
  var a$19 = inp.OR;
  return {
          logo_in: a !== undefined ? a.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          name: a$1 !== undefined ? a$1 : undefined,
          name_ne: a$2 !== undefined ? a$2 : undefined,
          logo_nin: a$3 !== undefined ? a$3.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          logo_gt: a$4 !== undefined ? a$4 : undefined,
          name_exists: a$5 !== undefined ? a$5 : undefined,
          logo_exists: a$6 !== undefined ? a$6 : undefined,
          name_gte: a$7 !== undefined ? a$7 : undefined,
          logo_lte: a$8 !== undefined ? a$8 : undefined,
          logo_lt: a$9 !== undefined ? a$9 : undefined,
          AND: a$10 !== undefined ? a$10.map(serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput$1) : undefined,
          name_lt: a$11 !== undefined ? a$11 : undefined,
          name_in: a$12 !== undefined ? a$12.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          logo: a$13 !== undefined ? a$13 : undefined,
          logo_ne: a$14 !== undefined ? a$14 : undefined,
          name_gt: a$15 !== undefined ? a$15 : undefined,
          name_lte: a$16 !== undefined ? a$16 : undefined,
          name_nin: a$17 !== undefined ? a$17.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          logo_gte: a$18 !== undefined ? a$18 : undefined,
          OR: a$19 !== undefined ? a$19.map(serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput$1) : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput$1(inp) {
  var a = inp.image_lt;
  var a$1 = inp.title_nin;
  var a$2 = inp.price;
  var a$3 = inp._id_in;
  var a$4 = inp.price_exists;
  var a$5 = inp.price_gt;
  var a$6 = inp.similarity_nin;
  var a$7 = inp._id_nin;
  var a$8 = inp.title_lt;
  var a$9 = inp._id_gte;
  var a$10 = inp.title_in;
  var a$11 = inp.image_nin;
  var a$12 = inp.image_ne;
  var a$13 = inp.image_lte;
  var a$14 = inp._id_lte;
  var a$15 = inp.AND;
  var a$16 = inp.price_lt;
  var a$17 = inp.price_ne;
  var a$18 = inp._id_lt;
  var a$19 = inp.similarity_lt;
  var a$20 = inp.price_nin;
  var a$21 = inp.similarity;
  var a$22 = inp.image_in;
  var a$23 = inp._id_ne;
  var a$24 = inp.title_gte;
  var a$25 = inp.title_gt;
  var a$26 = inp.price_in;
  var a$27 = inp.title_ne;
  var a$28 = inp.title_lte;
  var a$29 = inp.similarity_ne;
  var a$30 = inp.price_gte;
  var a$31 = inp._id_exists;
  var a$32 = inp.image_exists;
  var a$33 = inp.similarity_lte;
  var a$34 = inp.similarity_exists;
  var a$35 = inp.title;
  var a$36 = inp.title_exists;
  var a$37 = inp._id_gt;
  var a$38 = inp.similarity_in;
  var a$39 = inp.giftProvider_exists;
  var a$40 = inp.giftProvider;
  var a$41 = inp.OR;
  var a$42 = inp._id;
  var a$43 = inp.image_gte;
  var a$44 = inp.image;
  var a$45 = inp.price_lte;
  var a$46 = inp.similarity_gt;
  var a$47 = inp.similarity_gte;
  var a$48 = inp.image_gt;
  return {
          image_lt: a !== undefined ? a : undefined,
          title_nin: a$1 !== undefined ? a$1.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          price: a$2 !== undefined ? a$2 : undefined,
          _id_in: a$3 !== undefined ? a$3.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          price_exists: a$4 !== undefined ? a$4 : undefined,
          price_gt: a$5 !== undefined ? a$5 : undefined,
          similarity_nin: a$6 !== undefined ? a$6.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          _id_nin: a$7 !== undefined ? a$7.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          title_lt: a$8 !== undefined ? a$8 : undefined,
          _id_gte: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          title_in: a$10 !== undefined ? a$10.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image_nin: a$11 !== undefined ? a$11.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image_ne: a$12 !== undefined ? a$12 : undefined,
          image_lte: a$13 !== undefined ? a$13 : undefined,
          _id_lte: a$14 !== undefined ? Caml_option.valFromOption(a$14) : undefined,
          AND: a$15 !== undefined ? a$15.map(serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput$1) : undefined,
          price_lt: a$16 !== undefined ? a$16 : undefined,
          price_ne: a$17 !== undefined ? a$17 : undefined,
          _id_lt: a$18 !== undefined ? Caml_option.valFromOption(a$18) : undefined,
          similarity_lt: a$19 !== undefined ? a$19 : undefined,
          price_nin: a$20 !== undefined ? a$20.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          similarity: a$21 !== undefined ? a$21 : undefined,
          image_in: a$22 !== undefined ? a$22.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          _id_ne: a$23 !== undefined ? Caml_option.valFromOption(a$23) : undefined,
          title_gte: a$24 !== undefined ? a$24 : undefined,
          title_gt: a$25 !== undefined ? a$25 : undefined,
          price_in: a$26 !== undefined ? a$26.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          title_ne: a$27 !== undefined ? a$27 : undefined,
          title_lte: a$28 !== undefined ? a$28 : undefined,
          similarity_ne: a$29 !== undefined ? a$29 : undefined,
          price_gte: a$30 !== undefined ? a$30 : undefined,
          _id_exists: a$31 !== undefined ? a$31 : undefined,
          image_exists: a$32 !== undefined ? a$32 : undefined,
          similarity_lte: a$33 !== undefined ? a$33 : undefined,
          similarity_exists: a$34 !== undefined ? a$34 : undefined,
          title: a$35 !== undefined ? a$35 : undefined,
          title_exists: a$36 !== undefined ? a$36 : undefined,
          _id_gt: a$37 !== undefined ? Caml_option.valFromOption(a$37) : undefined,
          similarity_in: a$38 !== undefined ? a$38.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          giftProvider_exists: a$39 !== undefined ? a$39 : undefined,
          giftProvider: a$40 !== undefined ? serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput$1(a$40) : undefined,
          OR: a$41 !== undefined ? a$41.map(serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput$1) : undefined,
          _id: a$42 !== undefined ? Caml_option.valFromOption(a$42) : undefined,
          image_gte: a$43 !== undefined ? a$43 : undefined,
          image: a$44 !== undefined ? a$44 : undefined,
          price_lte: a$45 !== undefined ? a$45 : undefined,
          similarity_gt: a$46 !== undefined ? a$46 : undefined,
          similarity_gte: a$47 !== undefined ? a$47 : undefined,
          image_gt: a$48 !== undefined ? a$48 : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsGiftProviderUpdateInput(inp) {
  var a = inp.logo;
  var a$1 = inp.logo_unset;
  var a$2 = inp.name;
  var a$3 = inp.name_unset;
  return {
          logo: a !== undefined ? a : undefined,
          logo_unset: a$1 !== undefined ? a$1 : undefined,
          name: a$2 !== undefined ? a$2 : undefined,
          name_unset: a$3 !== undefined ? a$3 : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsAssociatedProductsUpdateInput(inp) {
  var a = inp.title;
  var a$1 = inp.title_unset;
  var a$2 = inp.url;
  var a$3 = inp.url_unset;
  var a$4 = inp.image;
  var a$5 = inp.image_unset;
  return {
          title: a !== undefined ? a : undefined,
          title_unset: a$1 !== undefined ? a$1 : undefined,
          url: a$2 !== undefined ? a$2 : undefined,
          url_unset: a$3 !== undefined ? a$3 : undefined,
          image: a$4 !== undefined ? a$4 : undefined,
          image_unset: a$5 !== undefined ? a$5 : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsByCategoryUpdateInput(inp) {
  var a = inp.recommendations_unset;
  var a$1 = inp.category;
  var a$2 = inp.category_unset;
  var a$3 = inp.likedProducts;
  var a$4 = inp.likedProducts_unset;
  var a$5 = inp.recommendations;
  return {
          recommendations_unset: a !== undefined ? a : undefined,
          category: a$1 !== undefined ? a$1 : undefined,
          category_unset: a$2 !== undefined ? a$2 : undefined,
          likedProducts: a$3 !== undefined ? a$3.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsUpdateInput(b);
                  }
                  
                }) : undefined,
          likedProducts_unset: a$4 !== undefined ? a$4 : undefined,
          recommendations: a$5 !== undefined ? a$5.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsUpdateInput(b);
                  }
                  
                }) : undefined
        };
}

function serializeInputObjectGiftProfilePurchasesUpdateInput(inp) {
  var a = inp.targetYear_inc;
  var a$1 = inp.date_unset;
  var a$2 = inp.transactionId_unset;
  var a$3 = inp.giftIdeaId_unset;
  var a$4 = inp.targetYear;
  var a$5 = inp.transactionId;
  var a$6 = inp.giftIdeaId;
  var a$7 = inp.targetYear_unset;
  var a$8 = inp.date;
  return {
          targetYear_inc: a !== undefined ? a : undefined,
          date_unset: a$1 !== undefined ? a$1 : undefined,
          transactionId_unset: a$2 !== undefined ? a$2 : undefined,
          giftIdeaId_unset: a$3 !== undefined ? a$3 : undefined,
          targetYear: a$4 !== undefined ? a$4 : undefined,
          transactionId: a$5 !== undefined ? Caml_option.valFromOption(a$5) : undefined,
          giftIdeaId: a$6 !== undefined ? Caml_option.valFromOption(a$6) : undefined,
          targetYear_unset: a$7 !== undefined ? a$7 : undefined,
          date: a$8 !== undefined ? Caml_option.valFromOption(a$8) : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsUpdateInput(inp) {
  var a = inp.similarity_unset;
  var a$1 = inp.title_unset;
  var a$2 = inp.similarity;
  var a$3 = inp.associatedProducts_unset;
  var a$4 = inp.price;
  var a$5 = inp.title;
  var a$6 = inp.url;
  var a$7 = inp.description_unset;
  var a$8 = inp.interest;
  var a$9 = inp.price_unset;
  var a$10 = inp.image_unset;
  var a$11 = inp.avgReview_unset;
  var a$12 = inp.image;
  var a$13 = inp.associatedProducts;
  var a$14 = inp.giftProvider;
  var a$15 = inp.interest_unset;
  var a$16 = inp.url_unset;
  var a$17 = inp.avgReview;
  var a$18 = inp.giftSource;
  var a$19 = inp.description;
  var a$20 = inp._id;
  var a$21 = inp.avgReview_inc;
  var a$22 = inp._id_unset;
  var a$23 = inp.giftSource_unset;
  var a$24 = inp.similarity_inc;
  var a$25 = inp.giftProvider_unset;
  var a$26 = inp.price_inc;
  return {
          similarity_unset: a !== undefined ? a : undefined,
          title_unset: a$1 !== undefined ? a$1 : undefined,
          similarity: a$2 !== undefined ? a$2 : undefined,
          associatedProducts_unset: a$3 !== undefined ? a$3 : undefined,
          price: a$4 !== undefined ? a$4 : undefined,
          title: a$5 !== undefined ? a$5 : undefined,
          url: a$6 !== undefined ? a$6 : undefined,
          description_unset: a$7 !== undefined ? a$7 : undefined,
          interest: a$8 !== undefined ? a$8 : undefined,
          price_unset: a$9 !== undefined ? a$9 : undefined,
          image_unset: a$10 !== undefined ? a$10 : undefined,
          avgReview_unset: a$11 !== undefined ? a$11 : undefined,
          image: a$12 !== undefined ? a$12 : undefined,
          associatedProducts: a$13 !== undefined ? a$13.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsAssociatedProductsUpdateInput(b);
                  }
                  
                }) : undefined,
          giftProvider: a$14 !== undefined ? serializeInputObjectGiftProfileRecommendationsGiftProviderUpdateInput(a$14) : undefined,
          interest_unset: a$15 !== undefined ? a$15 : undefined,
          url_unset: a$16 !== undefined ? a$16 : undefined,
          avgReview: a$17 !== undefined ? a$17 : undefined,
          giftSource: a$18 !== undefined ? a$18 : undefined,
          description: a$19 !== undefined ? a$19 : undefined,
          _id: a$20 !== undefined ? Caml_option.valFromOption(a$20) : undefined,
          avgReview_inc: a$21 !== undefined ? a$21 : undefined,
          _id_unset: a$22 !== undefined ? a$22 : undefined,
          giftSource_unset: a$23 !== undefined ? a$23 : undefined,
          similarity_inc: a$24 !== undefined ? a$24 : undefined,
          giftProvider_unset: a$25 !== undefined ? a$25 : undefined,
          price_inc: a$26 !== undefined ? a$26 : undefined
        };
}

function serializeInputObjectGiftProfileProfileUpdateInput(inp) {
  var a = inp.title_unset;
  var a$1 = inp.interest;
  var a$2 = inp.interest_unset;
  var a$3 = inp.productId;
  var a$4 = inp.image;
  var a$5 = inp.weight;
  var a$6 = inp.weight_unset;
  var a$7 = inp.image_unset;
  var a$8 = inp.productId_unset;
  var a$9 = inp.title;
  return {
          title_unset: a !== undefined ? a : undefined,
          interest: a$1 !== undefined ? a$1 : undefined,
          interest_unset: a$2 !== undefined ? a$2 : undefined,
          productId: a$3 !== undefined ? a$3 : undefined,
          image: a$4 !== undefined ? a$4 : undefined,
          weight: a$5 !== undefined ? Caml_option.valFromOption(a$5) : undefined,
          weight_unset: a$6 !== undefined ? a$6 : undefined,
          image_unset: a$7 !== undefined ? a$7 : undefined,
          productId_unset: a$8 !== undefined ? a$8 : undefined,
          title: a$9 !== undefined ? a$9 : undefined
        };
}

function serializeInputObjectGiftProfileUpdateInput(inp) {
  var a = inp.priceMax_unset;
  var a$1 = inp.updatedAt_unset;
  var a$2 = inp.createdAt;
  var a$3 = inp.recommendationsByCategory;
  var a$4 = inp.updatedAt;
  var a$5 = inp.userId;
  var a$6 = inp.priceMin_inc;
  var a$7 = inp.interests;
  var a$8 = inp.priceMax;
  var a$9 = inp.recommendations_unset;
  var a$10 = inp.profile;
  var a$11 = inp.priceMax_inc;
  var a$12 = inp.createdAt_unset;
  var a$13 = inp._id;
  var a$14 = inp.priceMin;
  var a$15 = inp.gender;
  var a$16 = inp.gifteeId_unset;
  var a$17 = inp._id_unset;
  var a$18 = inp.userId_unset;
  var a$19 = inp.gender_unset;
  var a$20 = inp.purchases;
  var a$21 = inp.recommendationsByCategory_unset;
  var a$22 = inp.profile_unset;
  var a$23 = inp.purchases_unset;
  var a$24 = inp.recommendations;
  var a$25 = inp.gifteeId;
  var a$26 = inp.interests_unset;
  var a$27 = inp.priceMin_unset;
  return {
          priceMax_unset: a !== undefined ? a : undefined,
          updatedAt_unset: a$1 !== undefined ? a$1 : undefined,
          createdAt: a$2 !== undefined ? Caml_option.valFromOption(a$2) : undefined,
          recommendationsByCategory: a$3 !== undefined ? a$3.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryUpdateInput(b);
                  }
                  
                }) : undefined,
          updatedAt: a$4 !== undefined ? Caml_option.valFromOption(a$4) : undefined,
          userId: a$5 !== undefined ? Caml_option.valFromOption(a$5) : undefined,
          priceMin_inc: a$6 !== undefined ? a$6 : undefined,
          interests: a$7 !== undefined ? a$7.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          priceMax: a$8 !== undefined ? a$8 : undefined,
          recommendations_unset: a$9 !== undefined ? a$9 : undefined,
          profile: a$10 !== undefined ? a$10.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileProfileUpdateInput(b);
                  }
                  
                }) : undefined,
          priceMax_inc: a$11 !== undefined ? a$11 : undefined,
          createdAt_unset: a$12 !== undefined ? a$12 : undefined,
          _id: a$13 !== undefined ? Caml_option.valFromOption(a$13) : undefined,
          priceMin: a$14 !== undefined ? a$14 : undefined,
          gender: a$15 !== undefined ? (
              a$15 === "UNISEX" ? "UNISEX" : (
                  a$15 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          gifteeId_unset: a$16 !== undefined ? a$16 : undefined,
          _id_unset: a$17 !== undefined ? a$17 : undefined,
          userId_unset: a$18 !== undefined ? a$18 : undefined,
          gender_unset: a$19 !== undefined ? a$19 : undefined,
          purchases: a$20 !== undefined ? a$20.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfilePurchasesUpdateInput(b);
                  }
                  
                }) : undefined,
          recommendationsByCategory_unset: a$21 !== undefined ? a$21 : undefined,
          profile_unset: a$22 !== undefined ? a$22 : undefined,
          purchases_unset: a$23 !== undefined ? a$23 : undefined,
          recommendations: a$24 !== undefined ? a$24.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsUpdateInput(b);
                  }
                  
                }) : undefined,
          gifteeId: a$25 !== undefined ? Caml_option.valFromOption(a$25) : undefined,
          interests_unset: a$26 !== undefined ? a$26 : undefined,
          priceMin_unset: a$27 !== undefined ? a$27 : undefined
        };
}

function serializeVariables$1(inp) {
  return {
          query: serializeInputObjectGiftProfileQueryInput$1(inp.query),
          set: serializeInputObjectGiftProfileUpdateInput(inp.set)
        };
}

function makeVariables$1(query, set, param) {
  return {
          query: query,
          set: set
        };
}

function makeInputObjectGiftProfileQueryInput$1(recommendations_exists, recommendationsByCategory_nin, createdAt_gt, recommendationsByCategory_exists, updatedAt_lte, createdAt_gte, priceMin_lt, gender_in, createdAt_exists, userId, AND, _id_ne, _id, createdAt_lte, priceMax_lte, purchases_in, _id_gte, priceMin_gte, gifteeId_lt, interests, priceMin_gt, userId_exists, profile_nin, updatedAt_nin, OR, gifteeId_lte, updatedAt_exists, priceMin_lte, _id_nin, updatedAt_ne, _id_in, priceMax_nin, gifteeId_exists, priceMin_nin, createdAt_lt, priceMax_exists, recommendationsByCategory_in, _id_lt, priceMax, userId_in, createdAt_nin, gender_nin, recommendations_in, userId_ne, priceMin, _id_lte, _id_gt, priceMax_ne, updatedAt, gifteeId_gt, updatedAt_gt, updatedAt_gte, userId_nin, purchases, gifteeId_gte, recommendationsByCategory, gender_ne, userId_lte, createdAt_in, priceMax_lt, gifteeId_ne, purchases_exists, priceMax_in, profile_exists, userId_gt, recommendations_nin, gender_exists, gender_lte, profile, priceMin_ne, gender, priceMin_exists, gender_lt, createdAt, gifteeId_nin, _id_exists, gifteeId, gender_gt, updatedAt_in, userId_lt, priceMax_gt, gender_gte, purchases_nin, gifteeId_in, priceMax_gte, interests_nin, profile_in, userId_gte, interests_exists, priceMin_in, updatedAt_lt, createdAt_ne, recommendations, interests_in, param) {
  return {
          recommendations_exists: recommendations_exists,
          recommendationsByCategory_nin: recommendationsByCategory_nin,
          createdAt_gt: createdAt_gt,
          recommendationsByCategory_exists: recommendationsByCategory_exists,
          updatedAt_lte: updatedAt_lte,
          createdAt_gte: createdAt_gte,
          priceMin_lt: priceMin_lt,
          gender_in: gender_in,
          createdAt_exists: createdAt_exists,
          userId: userId,
          AND: AND,
          _id_ne: _id_ne,
          _id: _id,
          createdAt_lte: createdAt_lte,
          priceMax_lte: priceMax_lte,
          purchases_in: purchases_in,
          _id_gte: _id_gte,
          priceMin_gte: priceMin_gte,
          gifteeId_lt: gifteeId_lt,
          interests: interests,
          priceMin_gt: priceMin_gt,
          userId_exists: userId_exists,
          profile_nin: profile_nin,
          updatedAt_nin: updatedAt_nin,
          OR: OR,
          gifteeId_lte: gifteeId_lte,
          updatedAt_exists: updatedAt_exists,
          priceMin_lte: priceMin_lte,
          _id_nin: _id_nin,
          updatedAt_ne: updatedAt_ne,
          _id_in: _id_in,
          priceMax_nin: priceMax_nin,
          gifteeId_exists: gifteeId_exists,
          priceMin_nin: priceMin_nin,
          createdAt_lt: createdAt_lt,
          priceMax_exists: priceMax_exists,
          recommendationsByCategory_in: recommendationsByCategory_in,
          _id_lt: _id_lt,
          priceMax: priceMax,
          userId_in: userId_in,
          createdAt_nin: createdAt_nin,
          gender_nin: gender_nin,
          recommendations_in: recommendations_in,
          userId_ne: userId_ne,
          priceMin: priceMin,
          _id_lte: _id_lte,
          _id_gt: _id_gt,
          priceMax_ne: priceMax_ne,
          updatedAt: updatedAt,
          gifteeId_gt: gifteeId_gt,
          updatedAt_gt: updatedAt_gt,
          updatedAt_gte: updatedAt_gte,
          userId_nin: userId_nin,
          purchases: purchases,
          gifteeId_gte: gifteeId_gte,
          recommendationsByCategory: recommendationsByCategory,
          gender_ne: gender_ne,
          userId_lte: userId_lte,
          createdAt_in: createdAt_in,
          priceMax_lt: priceMax_lt,
          gifteeId_ne: gifteeId_ne,
          purchases_exists: purchases_exists,
          priceMax_in: priceMax_in,
          profile_exists: profile_exists,
          userId_gt: userId_gt,
          recommendations_nin: recommendations_nin,
          gender_exists: gender_exists,
          gender_lte: gender_lte,
          profile: profile,
          priceMin_ne: priceMin_ne,
          gender: gender,
          priceMin_exists: priceMin_exists,
          gender_lt: gender_lt,
          createdAt: createdAt,
          gifteeId_nin: gifteeId_nin,
          _id_exists: _id_exists,
          gifteeId: gifteeId,
          gender_gt: gender_gt,
          updatedAt_in: updatedAt_in,
          userId_lt: userId_lt,
          priceMax_gt: priceMax_gt,
          gender_gte: gender_gte,
          purchases_nin: purchases_nin,
          gifteeId_in: gifteeId_in,
          priceMax_gte: priceMax_gte,
          interests_nin: interests_nin,
          profile_in: profile_in,
          userId_gte: userId_gte,
          interests_exists: interests_exists,
          priceMin_in: priceMin_in,
          updatedAt_lt: updatedAt_lt,
          createdAt_ne: createdAt_ne,
          recommendations: recommendations,
          interests_in: interests_in
        };
}

function makeInputObjectGiftProfileRecommendationsByCategoryQueryInput$1(likedProducts_nin, likedProducts_in, recommendations_nin, category_in, likedProducts, likedProducts_exists, AND, category_ne, category_gt, category_exists, category_gte, OR, category_lte, recommendations_exists, category_lt, recommendations_in, recommendations, category, category_nin, param) {
  return {
          likedProducts_nin: likedProducts_nin,
          likedProducts_in: likedProducts_in,
          recommendations_nin: recommendations_nin,
          category_in: category_in,
          likedProducts: likedProducts,
          likedProducts_exists: likedProducts_exists,
          AND: AND,
          category_ne: category_ne,
          category_gt: category_gt,
          category_exists: category_exists,
          category_gte: category_gte,
          OR: OR,
          category_lte: category_lte,
          recommendations_exists: recommendations_exists,
          category_lt: category_lt,
          recommendations_in: recommendations_in,
          recommendations: recommendations,
          category: category,
          category_nin: category_nin
        };
}

function makeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput$1(productId_lt, weight, productId_in, productId_lte, OR, weight_lte, image_ne, weight_nin, weight_gt, title_lt, image_lt, image_gt, productId, title_ne, image_in, productId_ne, productId_gte, image_exists, weight_in, AND, image_gte, weight_gte, weight_lt, weight_ne, title_nin, title_exists, productId_exists, title_lte, productId_gt, title_gte, title_gt, image, title, productId_nin, title_in, image_nin, image_lte, weight_exists, param) {
  return {
          productId_lt: productId_lt,
          weight: weight,
          productId_in: productId_in,
          productId_lte: productId_lte,
          OR: OR,
          weight_lte: weight_lte,
          image_ne: image_ne,
          weight_nin: weight_nin,
          weight_gt: weight_gt,
          title_lt: title_lt,
          image_lt: image_lt,
          image_gt: image_gt,
          productId: productId,
          title_ne: title_ne,
          image_in: image_in,
          productId_ne: productId_ne,
          productId_gte: productId_gte,
          image_exists: image_exists,
          weight_in: weight_in,
          AND: AND,
          image_gte: image_gte,
          weight_gte: weight_gte,
          weight_lt: weight_lt,
          weight_ne: weight_ne,
          title_nin: title_nin,
          title_exists: title_exists,
          productId_exists: productId_exists,
          title_lte: title_lte,
          productId_gt: productId_gt,
          title_gte: title_gte,
          title_gt: title_gt,
          image: image,
          title: title,
          productId_nin: productId_nin,
          title_in: title_in,
          image_nin: image_nin,
          image_lte: image_lte,
          weight_exists: weight_exists
        };
}

function makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput$1(image_lt, title_nin, price, _id_in, price_exists, price_gt, similarity_nin, _id_nin, title_lt, _id_gte, title_in, image_nin, image_ne, image_lte, _id_lte, AND, price_lt, price_ne, _id_lt, similarity_lt, price_nin, similarity, image_in, _id_ne, title_gte, title_gt, price_in, title_ne, title_lte, similarity_ne, price_gte, _id_exists, image_exists, similarity_lte, similarity_exists, title, title_exists, _id_gt, similarity_in, giftProvider_exists, giftProvider, OR, _id, image_gte, image, price_lte, similarity_gt, similarity_gte, image_gt, param) {
  return {
          image_lt: image_lt,
          title_nin: title_nin,
          price: price,
          _id_in: _id_in,
          price_exists: price_exists,
          price_gt: price_gt,
          similarity_nin: similarity_nin,
          _id_nin: _id_nin,
          title_lt: title_lt,
          _id_gte: _id_gte,
          title_in: title_in,
          image_nin: image_nin,
          image_ne: image_ne,
          image_lte: image_lte,
          _id_lte: _id_lte,
          AND: AND,
          price_lt: price_lt,
          price_ne: price_ne,
          _id_lt: _id_lt,
          similarity_lt: similarity_lt,
          price_nin: price_nin,
          similarity: similarity,
          image_in: image_in,
          _id_ne: _id_ne,
          title_gte: title_gte,
          title_gt: title_gt,
          price_in: price_in,
          title_ne: title_ne,
          title_lte: title_lte,
          similarity_ne: similarity_ne,
          price_gte: price_gte,
          _id_exists: _id_exists,
          image_exists: image_exists,
          similarity_lte: similarity_lte,
          similarity_exists: similarity_exists,
          title: title,
          title_exists: title_exists,
          _id_gt: _id_gt,
          similarity_in: similarity_in,
          giftProvider_exists: giftProvider_exists,
          giftProvider: giftProvider,
          OR: OR,
          _id: _id,
          image_gte: image_gte,
          image: image,
          price_lte: price_lte,
          similarity_gt: similarity_gt,
          similarity_gte: similarity_gte,
          image_gt: image_gt
        };
}

function makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput$1(logo_exists, logo_in, logo_ne, name_exists, logo_gte, name_nin, logo, name_ne, name_lte, logo_gt, name, name_lt, logo_lt, name_gt, logo_nin, name_gte, AND, OR, logo_lte, name_in, param) {
  return {
          logo_exists: logo_exists,
          logo_in: logo_in,
          logo_ne: logo_ne,
          name_exists: name_exists,
          logo_gte: logo_gte,
          name_nin: name_nin,
          logo: logo,
          name_ne: name_ne,
          name_lte: name_lte,
          logo_gt: logo_gt,
          name: name,
          name_lt: name_lt,
          logo_lt: logo_lt,
          name_gt: name_gt,
          logo_nin: logo_nin,
          name_gte: name_gte,
          AND: AND,
          OR: OR,
          logo_lte: logo_lte,
          name_in: name_in
        };
}

function makeInputObjectGiftProfilePurchasesQueryInput$1(giftIdeaId_ne, date_exists, targetYear_gt, targetYear_in, OR, date_gt, transactionId_gt, transactionId_lt, AND, date_in, targetYear_gte, transactionId, giftIdeaId_lt, transactionId_nin, transactionId_in, giftIdeaId_nin, giftIdeaId_in, giftIdeaId_gte, targetYear_nin, targetYear_lt, transactionId_gte, giftIdeaId_gt, targetYear_ne, targetYear, date_nin, date_gte, giftIdeaId_exists, targetYear_exists, transactionId_lte, transactionId_ne, transactionId_exists, targetYear_lte, date_lt, date_ne, giftIdeaId, giftIdeaId_lte, date_lte, date, param) {
  return {
          giftIdeaId_ne: giftIdeaId_ne,
          date_exists: date_exists,
          targetYear_gt: targetYear_gt,
          targetYear_in: targetYear_in,
          OR: OR,
          date_gt: date_gt,
          transactionId_gt: transactionId_gt,
          transactionId_lt: transactionId_lt,
          AND: AND,
          date_in: date_in,
          targetYear_gte: targetYear_gte,
          transactionId: transactionId,
          giftIdeaId_lt: giftIdeaId_lt,
          transactionId_nin: transactionId_nin,
          transactionId_in: transactionId_in,
          giftIdeaId_nin: giftIdeaId_nin,
          giftIdeaId_in: giftIdeaId_in,
          giftIdeaId_gte: giftIdeaId_gte,
          targetYear_nin: targetYear_nin,
          targetYear_lt: targetYear_lt,
          transactionId_gte: transactionId_gte,
          giftIdeaId_gt: giftIdeaId_gt,
          targetYear_ne: targetYear_ne,
          targetYear: targetYear,
          date_nin: date_nin,
          date_gte: date_gte,
          giftIdeaId_exists: giftIdeaId_exists,
          targetYear_exists: targetYear_exists,
          transactionId_lte: transactionId_lte,
          transactionId_ne: transactionId_ne,
          transactionId_exists: transactionId_exists,
          targetYear_lte: targetYear_lte,
          date_lt: date_lt,
          date_ne: date_ne,
          giftIdeaId: giftIdeaId,
          giftIdeaId_lte: giftIdeaId_lte,
          date_lte: date_lte,
          date: date
        };
}

function makeInputObjectGiftProfileProfileQueryInput$1(interest, productId_lt, productId, title_lte, title_gte, weight_gte, interest_in, productId_exists, weight_exists, weight_ne, title_lt, interest_gt, weight_gt, productId_in, productId_ne, image_lte, weight_in, OR, image_gt, AND, title_gt, weight, interest_lte, productId_gt, interest_gte, image_nin, interest_nin, title, interest_lt, weight_lte, image_gte, interest_exists, productId_nin, interest_ne, title_exists, title_ne, title_in, weight_lt, image_ne, weight_nin, image_lt, productId_gte, image_in, productId_lte, image_exists, title_nin, image, param) {
  return {
          interest: interest,
          productId_lt: productId_lt,
          productId: productId,
          title_lte: title_lte,
          title_gte: title_gte,
          weight_gte: weight_gte,
          interest_in: interest_in,
          productId_exists: productId_exists,
          weight_exists: weight_exists,
          weight_ne: weight_ne,
          title_lt: title_lt,
          interest_gt: interest_gt,
          weight_gt: weight_gt,
          productId_in: productId_in,
          productId_ne: productId_ne,
          image_lte: image_lte,
          weight_in: weight_in,
          OR: OR,
          image_gt: image_gt,
          AND: AND,
          title_gt: title_gt,
          weight: weight,
          interest_lte: interest_lte,
          productId_gt: productId_gt,
          interest_gte: interest_gte,
          image_nin: image_nin,
          interest_nin: interest_nin,
          title: title,
          interest_lt: interest_lt,
          weight_lte: weight_lte,
          image_gte: image_gte,
          interest_exists: interest_exists,
          productId_nin: productId_nin,
          interest_ne: interest_ne,
          title_exists: title_exists,
          title_ne: title_ne,
          title_in: title_in,
          weight_lt: weight_lt,
          image_ne: image_ne,
          weight_nin: weight_nin,
          image_lt: image_lt,
          productId_gte: productId_gte,
          image_in: image_in,
          productId_lte: productId_lte,
          image_exists: image_exists,
          title_nin: title_nin,
          image: image
        };
}

function makeInputObjectGiftProfileRecommendationsQueryInput$1(similarity_gt, similarity_lt, OR, interest_gt, title_lte, title_gt, title_nin, giftSource_nin, title_exists, giftSource_ne, url_in, _id_lte, price_exists, similarity_exists, _id_gt, similarity_ne, price_ne, interest_nin, similarity_nin, avgReview_gt, interest_lte, price_gt, url_lt, giftSource_exists, _id_gte, _id_in, interest_gte, price, title_gte, price_lt, image_in, image_lt, interest_ne, giftSource_lte, avgReview_nin, similarity_in, url_lte, _id_lt, price_lte, title, url_gte, url_ne, avgReview_lt, price_gte, similarity_lte, image_ne, description_lt, giftProvider, _id_exists, description_gt, _id_ne, interest_exists, avgReview, giftSource_gte, interest, url_gt, image_gt, AND, avgReview_in, image, avgReview_exists, description_lte, _id_nin, image_nin, similarity, avgReview_gte, associatedProducts_exists, associatedProducts_in, giftSource, description_gte, description_nin, description_exists, title_in, similarity_gte, giftSource_gt, url_nin, interest_lt, giftProvider_exists, avgReview_lte, _id, title_lt, avgReview_ne, image_exists, price_in, title_ne, interest_in, giftSource_lt, description_in, description_ne, url, url_exists, image_gte, giftSource_in, associatedProducts, associatedProducts_nin, price_nin, description, image_lte, param) {
  return {
          similarity_gt: similarity_gt,
          similarity_lt: similarity_lt,
          OR: OR,
          interest_gt: interest_gt,
          title_lte: title_lte,
          title_gt: title_gt,
          title_nin: title_nin,
          giftSource_nin: giftSource_nin,
          title_exists: title_exists,
          giftSource_ne: giftSource_ne,
          url_in: url_in,
          _id_lte: _id_lte,
          price_exists: price_exists,
          similarity_exists: similarity_exists,
          _id_gt: _id_gt,
          similarity_ne: similarity_ne,
          price_ne: price_ne,
          interest_nin: interest_nin,
          similarity_nin: similarity_nin,
          avgReview_gt: avgReview_gt,
          interest_lte: interest_lte,
          price_gt: price_gt,
          url_lt: url_lt,
          giftSource_exists: giftSource_exists,
          _id_gte: _id_gte,
          _id_in: _id_in,
          interest_gte: interest_gte,
          price: price,
          title_gte: title_gte,
          price_lt: price_lt,
          image_in: image_in,
          image_lt: image_lt,
          interest_ne: interest_ne,
          giftSource_lte: giftSource_lte,
          avgReview_nin: avgReview_nin,
          similarity_in: similarity_in,
          url_lte: url_lte,
          _id_lt: _id_lt,
          price_lte: price_lte,
          title: title,
          url_gte: url_gte,
          url_ne: url_ne,
          avgReview_lt: avgReview_lt,
          price_gte: price_gte,
          similarity_lte: similarity_lte,
          image_ne: image_ne,
          description_lt: description_lt,
          giftProvider: giftProvider,
          _id_exists: _id_exists,
          description_gt: description_gt,
          _id_ne: _id_ne,
          interest_exists: interest_exists,
          avgReview: avgReview,
          giftSource_gte: giftSource_gte,
          interest: interest,
          url_gt: url_gt,
          image_gt: image_gt,
          AND: AND,
          avgReview_in: avgReview_in,
          image: image,
          avgReview_exists: avgReview_exists,
          description_lte: description_lte,
          _id_nin: _id_nin,
          image_nin: image_nin,
          similarity: similarity,
          avgReview_gte: avgReview_gte,
          associatedProducts_exists: associatedProducts_exists,
          associatedProducts_in: associatedProducts_in,
          giftSource: giftSource,
          description_gte: description_gte,
          description_nin: description_nin,
          description_exists: description_exists,
          title_in: title_in,
          similarity_gte: similarity_gte,
          giftSource_gt: giftSource_gt,
          url_nin: url_nin,
          interest_lt: interest_lt,
          giftProvider_exists: giftProvider_exists,
          avgReview_lte: avgReview_lte,
          _id: _id,
          title_lt: title_lt,
          avgReview_ne: avgReview_ne,
          image_exists: image_exists,
          price_in: price_in,
          title_ne: title_ne,
          interest_in: interest_in,
          giftSource_lt: giftSource_lt,
          description_in: description_in,
          description_ne: description_ne,
          url: url,
          url_exists: url_exists,
          image_gte: image_gte,
          giftSource_in: giftSource_in,
          associatedProducts: associatedProducts,
          associatedProducts_nin: associatedProducts_nin,
          price_nin: price_nin,
          description: description,
          image_lte: image_lte
        };
}

function makeInputObjectGiftProfileRecommendationsGiftProviderQueryInput$1(logo_in, name, name_ne, logo_nin, logo_gt, name_exists, logo_exists, name_gte, logo_lte, logo_lt, AND, name_lt, name_in, logo, logo_ne, name_gt, name_lte, name_nin, logo_gte, OR, param) {
  return {
          logo_in: logo_in,
          name: name,
          name_ne: name_ne,
          logo_nin: logo_nin,
          logo_gt: logo_gt,
          name_exists: name_exists,
          logo_exists: logo_exists,
          name_gte: name_gte,
          logo_lte: logo_lte,
          logo_lt: logo_lt,
          AND: AND,
          name_lt: name_lt,
          name_in: name_in,
          logo: logo,
          logo_ne: logo_ne,
          name_gt: name_gt,
          name_lte: name_lte,
          name_nin: name_nin,
          logo_gte: logo_gte,
          OR: OR
        };
}

function makeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput$1(title_nin, url, title_ne, title, OR, image_ne, url_lte, url_gt, title_gt, image_gte, image_gt, image_exists, title_gte, url_exists, url_in, title_lte, title_in, url_nin, image_in, url_gte, image_nin, image_lt, image, title_exists, image_lte, url_ne, url_lt, AND, title_lt, param) {
  return {
          title_nin: title_nin,
          url: url,
          title_ne: title_ne,
          title: title,
          OR: OR,
          image_ne: image_ne,
          url_lte: url_lte,
          url_gt: url_gt,
          title_gt: title_gt,
          image_gte: image_gte,
          image_gt: image_gt,
          image_exists: image_exists,
          title_gte: title_gte,
          url_exists: url_exists,
          url_in: url_in,
          title_lte: title_lte,
          title_in: title_in,
          url_nin: url_nin,
          image_in: image_in,
          url_gte: url_gte,
          image_nin: image_nin,
          image_lt: image_lt,
          image: image,
          title_exists: title_exists,
          image_lte: image_lte,
          url_ne: url_ne,
          url_lt: url_lt,
          AND: AND,
          title_lt: title_lt
        };
}

function makeInputObjectGiftProfileUpdateInput(priceMax_unset, updatedAt_unset, createdAt, recommendationsByCategory, updatedAt, userId, priceMin_inc, interests, priceMax, recommendations_unset, profile, priceMax_inc, createdAt_unset, _id, priceMin, gender, gifteeId_unset, _id_unset, userId_unset, gender_unset, purchases, recommendationsByCategory_unset, profile_unset, purchases_unset, recommendations, gifteeId, interests_unset, priceMin_unset, param) {
  return {
          priceMax_unset: priceMax_unset,
          updatedAt_unset: updatedAt_unset,
          createdAt: createdAt,
          recommendationsByCategory: recommendationsByCategory,
          updatedAt: updatedAt,
          userId: userId,
          priceMin_inc: priceMin_inc,
          interests: interests,
          priceMax: priceMax,
          recommendations_unset: recommendations_unset,
          profile: profile,
          priceMax_inc: priceMax_inc,
          createdAt_unset: createdAt_unset,
          _id: _id,
          priceMin: priceMin,
          gender: gender,
          gifteeId_unset: gifteeId_unset,
          _id_unset: _id_unset,
          userId_unset: userId_unset,
          gender_unset: gender_unset,
          purchases: purchases,
          recommendationsByCategory_unset: recommendationsByCategory_unset,
          profile_unset: profile_unset,
          purchases_unset: purchases_unset,
          recommendations: recommendations,
          gifteeId: gifteeId,
          interests_unset: interests_unset,
          priceMin_unset: priceMin_unset
        };
}

function makeInputObjectGiftProfileRecommendationsByCategoryUpdateInput(recommendations_unset, category, category_unset, likedProducts, likedProducts_unset, recommendations, param) {
  return {
          recommendations_unset: recommendations_unset,
          category: category,
          category_unset: category_unset,
          likedProducts: likedProducts,
          likedProducts_unset: likedProducts_unset,
          recommendations: recommendations
        };
}

function makeInputObjectGiftProfileRecommendationsByCategoryLikedProductsUpdateInput(title_unset, weight, weight_unset, image, image_unset, productId, productId_unset, title, param) {
  return {
          title_unset: title_unset,
          weight: weight,
          weight_unset: weight_unset,
          image: image,
          image_unset: image_unset,
          productId: productId,
          productId_unset: productId_unset,
          title: title
        };
}

function makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsUpdateInput(similarity_unset, price_inc, giftProvider_unset, image_unset, similarity_inc, giftProvider, _id, price, title, image, title_unset, _id_unset, price_unset, similarity, param) {
  return {
          similarity_unset: similarity_unset,
          price_inc: price_inc,
          giftProvider_unset: giftProvider_unset,
          image_unset: image_unset,
          similarity_inc: similarity_inc,
          giftProvider: giftProvider,
          _id: _id,
          price: price,
          title: title,
          image: image,
          title_unset: title_unset,
          _id_unset: _id_unset,
          price_unset: price_unset,
          similarity: similarity
        };
}

function makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderUpdateInput(logo, logo_unset, name, name_unset, param) {
  return {
          logo: logo,
          logo_unset: logo_unset,
          name: name,
          name_unset: name_unset
        };
}

function makeInputObjectGiftProfileProfileUpdateInput(title_unset, interest, interest_unset, productId, image, weight, weight_unset, image_unset, productId_unset, title, param) {
  return {
          title_unset: title_unset,
          interest: interest,
          interest_unset: interest_unset,
          productId: productId,
          image: image,
          weight: weight,
          weight_unset: weight_unset,
          image_unset: image_unset,
          productId_unset: productId_unset,
          title: title
        };
}

function makeInputObjectGiftProfilePurchasesUpdateInput(targetYear_inc, date_unset, transactionId_unset, giftIdeaId_unset, targetYear, transactionId, giftIdeaId, targetYear_unset, date, param) {
  return {
          targetYear_inc: targetYear_inc,
          date_unset: date_unset,
          transactionId_unset: transactionId_unset,
          giftIdeaId_unset: giftIdeaId_unset,
          targetYear: targetYear,
          transactionId: transactionId,
          giftIdeaId: giftIdeaId,
          targetYear_unset: targetYear_unset,
          date: date
        };
}

function makeInputObjectGiftProfileRecommendationsUpdateInput(similarity_unset, title_unset, similarity, associatedProducts_unset, price, title, url, description_unset, interest, price_unset, image_unset, avgReview_unset, image, associatedProducts, giftProvider, interest_unset, url_unset, avgReview, giftSource, description, _id, avgReview_inc, _id_unset, giftSource_unset, similarity_inc, giftProvider_unset, price_inc, param) {
  return {
          similarity_unset: similarity_unset,
          title_unset: title_unset,
          similarity: similarity,
          associatedProducts_unset: associatedProducts_unset,
          price: price,
          title: title,
          url: url,
          description_unset: description_unset,
          interest: interest,
          price_unset: price_unset,
          image_unset: image_unset,
          avgReview_unset: avgReview_unset,
          image: image,
          associatedProducts: associatedProducts,
          giftProvider: giftProvider,
          interest_unset: interest_unset,
          url_unset: url_unset,
          avgReview: avgReview,
          giftSource: giftSource,
          description: description,
          _id: _id,
          avgReview_inc: avgReview_inc,
          _id_unset: _id_unset,
          giftSource_unset: giftSource_unset,
          similarity_inc: similarity_inc,
          giftProvider_unset: giftProvider_unset,
          price_inc: price_inc
        };
}

function makeInputObjectGiftProfileRecommendationsAssociatedProductsUpdateInput(title, title_unset, url, url_unset, image, image_unset, param) {
  return {
          title: title,
          title_unset: title_unset,
          url: url,
          url_unset: url_unset,
          image: image,
          image_unset: image_unset
        };
}

function makeInputObjectGiftProfileRecommendationsGiftProviderUpdateInput(logo, logo_unset, name, name_unset, param) {
  return {
          logo: logo,
          logo_unset: logo_unset,
          name: name,
          name_unset: name_unset
        };
}

var UpdateOneGiftProfileMut_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectGiftProfileQueryInput: serializeInputObjectGiftProfileQueryInput$1,
  serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput$1,
  serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput$1,
  serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput$1,
  serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput$1,
  serializeInputObjectGiftProfilePurchasesQueryInput: serializeInputObjectGiftProfilePurchasesQueryInput$1,
  serializeInputObjectGiftProfileProfileQueryInput: serializeInputObjectGiftProfileProfileQueryInput$1,
  serializeInputObjectGiftProfileRecommendationsQueryInput: serializeInputObjectGiftProfileRecommendationsQueryInput$1,
  serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput: serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput$1,
  serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput: serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput$1,
  serializeInputObjectGiftProfileUpdateInput: serializeInputObjectGiftProfileUpdateInput,
  serializeInputObjectGiftProfileRecommendationsByCategoryUpdateInput: serializeInputObjectGiftProfileRecommendationsByCategoryUpdateInput,
  serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsUpdateInput: serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsUpdateInput,
  serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsUpdateInput: serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsUpdateInput,
  serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderUpdateInput: serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderUpdateInput,
  serializeInputObjectGiftProfileProfileUpdateInput: serializeInputObjectGiftProfileProfileUpdateInput,
  serializeInputObjectGiftProfilePurchasesUpdateInput: serializeInputObjectGiftProfilePurchasesUpdateInput,
  serializeInputObjectGiftProfileRecommendationsUpdateInput: serializeInputObjectGiftProfileRecommendationsUpdateInput,
  serializeInputObjectGiftProfileRecommendationsAssociatedProductsUpdateInput: serializeInputObjectGiftProfileRecommendationsAssociatedProductsUpdateInput,
  serializeInputObjectGiftProfileRecommendationsGiftProviderUpdateInput: serializeInputObjectGiftProfileRecommendationsGiftProviderUpdateInput,
  makeVariables: makeVariables$1,
  makeInputObjectGiftProfileQueryInput: makeInputObjectGiftProfileQueryInput$1,
  makeInputObjectGiftProfileRecommendationsByCategoryQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryQueryInput$1,
  makeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput$1,
  makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput$1,
  makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput$1,
  makeInputObjectGiftProfilePurchasesQueryInput: makeInputObjectGiftProfilePurchasesQueryInput$1,
  makeInputObjectGiftProfileProfileQueryInput: makeInputObjectGiftProfileProfileQueryInput$1,
  makeInputObjectGiftProfileRecommendationsQueryInput: makeInputObjectGiftProfileRecommendationsQueryInput$1,
  makeInputObjectGiftProfileRecommendationsGiftProviderQueryInput: makeInputObjectGiftProfileRecommendationsGiftProviderQueryInput$1,
  makeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput: makeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput$1,
  makeInputObjectGiftProfileUpdateInput: makeInputObjectGiftProfileUpdateInput,
  makeInputObjectGiftProfileRecommendationsByCategoryUpdateInput: makeInputObjectGiftProfileRecommendationsByCategoryUpdateInput,
  makeInputObjectGiftProfileRecommendationsByCategoryLikedProductsUpdateInput: makeInputObjectGiftProfileRecommendationsByCategoryLikedProductsUpdateInput,
  makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsUpdateInput: makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsUpdateInput,
  makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderUpdateInput: makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderUpdateInput,
  makeInputObjectGiftProfileProfileUpdateInput: makeInputObjectGiftProfileProfileUpdateInput,
  makeInputObjectGiftProfilePurchasesUpdateInput: makeInputObjectGiftProfilePurchasesUpdateInput,
  makeInputObjectGiftProfileRecommendationsUpdateInput: makeInputObjectGiftProfileRecommendationsUpdateInput,
  makeInputObjectGiftProfileRecommendationsAssociatedProductsUpdateInput: makeInputObjectGiftProfileRecommendationsAssociatedProductsUpdateInput,
  makeInputObjectGiftProfileRecommendationsGiftProviderUpdateInput: makeInputObjectGiftProfileRecommendationsGiftProviderUpdateInput
};

var include$1 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var UpdateOneGiftProfileMut_use = include$1.use;

var UpdateOneGiftProfileMut_useWithVariables = include$1.useWithVariables;

var UpdateOneGiftProfileMut = {
  UpdateOneGiftProfileMut_inner: UpdateOneGiftProfileMut_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectGiftProfileQueryInput: serializeInputObjectGiftProfileQueryInput$1,
  serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput$1,
  serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput$1,
  serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput$1,
  serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput$1,
  serializeInputObjectGiftProfilePurchasesQueryInput: serializeInputObjectGiftProfilePurchasesQueryInput$1,
  serializeInputObjectGiftProfileProfileQueryInput: serializeInputObjectGiftProfileProfileQueryInput$1,
  serializeInputObjectGiftProfileRecommendationsQueryInput: serializeInputObjectGiftProfileRecommendationsQueryInput$1,
  serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput: serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput$1,
  serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput: serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput$1,
  serializeInputObjectGiftProfileUpdateInput: serializeInputObjectGiftProfileUpdateInput,
  serializeInputObjectGiftProfileRecommendationsByCategoryUpdateInput: serializeInputObjectGiftProfileRecommendationsByCategoryUpdateInput,
  serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsUpdateInput: serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsUpdateInput,
  serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsUpdateInput: serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsUpdateInput,
  serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderUpdateInput: serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderUpdateInput,
  serializeInputObjectGiftProfileProfileUpdateInput: serializeInputObjectGiftProfileProfileUpdateInput,
  serializeInputObjectGiftProfilePurchasesUpdateInput: serializeInputObjectGiftProfilePurchasesUpdateInput,
  serializeInputObjectGiftProfileRecommendationsUpdateInput: serializeInputObjectGiftProfileRecommendationsUpdateInput,
  serializeInputObjectGiftProfileRecommendationsAssociatedProductsUpdateInput: serializeInputObjectGiftProfileRecommendationsAssociatedProductsUpdateInput,
  serializeInputObjectGiftProfileRecommendationsGiftProviderUpdateInput: serializeInputObjectGiftProfileRecommendationsGiftProviderUpdateInput,
  makeVariables: makeVariables$1,
  makeInputObjectGiftProfileQueryInput: makeInputObjectGiftProfileQueryInput$1,
  makeInputObjectGiftProfileRecommendationsByCategoryQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryQueryInput$1,
  makeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput$1,
  makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput$1,
  makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput$1,
  makeInputObjectGiftProfilePurchasesQueryInput: makeInputObjectGiftProfilePurchasesQueryInput$1,
  makeInputObjectGiftProfileProfileQueryInput: makeInputObjectGiftProfileProfileQueryInput$1,
  makeInputObjectGiftProfileRecommendationsQueryInput: makeInputObjectGiftProfileRecommendationsQueryInput$1,
  makeInputObjectGiftProfileRecommendationsGiftProviderQueryInput: makeInputObjectGiftProfileRecommendationsGiftProviderQueryInput$1,
  makeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput: makeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput$1,
  makeInputObjectGiftProfileUpdateInput: makeInputObjectGiftProfileUpdateInput,
  makeInputObjectGiftProfileRecommendationsByCategoryUpdateInput: makeInputObjectGiftProfileRecommendationsByCategoryUpdateInput,
  makeInputObjectGiftProfileRecommendationsByCategoryLikedProductsUpdateInput: makeInputObjectGiftProfileRecommendationsByCategoryLikedProductsUpdateInput,
  makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsUpdateInput: makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsUpdateInput,
  makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderUpdateInput: makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderUpdateInput,
  makeInputObjectGiftProfileProfileUpdateInput: makeInputObjectGiftProfileProfileUpdateInput,
  makeInputObjectGiftProfilePurchasesUpdateInput: makeInputObjectGiftProfilePurchasesUpdateInput,
  makeInputObjectGiftProfileRecommendationsUpdateInput: makeInputObjectGiftProfileRecommendationsUpdateInput,
  makeInputObjectGiftProfileRecommendationsAssociatedProductsUpdateInput: makeInputObjectGiftProfileRecommendationsAssociatedProductsUpdateInput,
  makeInputObjectGiftProfileRecommendationsGiftProviderUpdateInput: makeInputObjectGiftProfileRecommendationsGiftProviderUpdateInput,
  use: UpdateOneGiftProfileMut_use,
  useWithVariables: UpdateOneGiftProfileMut_useWithVariables
};

var Raw$2 = {};

var query$2 = (require("@apollo/client").gql`
  query GetGiftProfilesQuery($query: GiftProfileQueryInput)  {
    giftProfiles(query: $query)  {
      __typename
      recommendations  {
        __typename
        _id
        image
        price
        similarity
        title
        interest
        giftProvider  {
          __typename
          logo
        }
      }
      purchases  {
        __typename
        giftIdeaId
        transactionId
        date
        targetYear
      }
      userId
      gifteeId
      interests
    }
  }
`);

function parse$2(value) {
  var value$1 = value.giftProfiles;
  return {
          giftProfiles: value$1.map(function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value.recommendations;
                var value$2 = value.purchases;
                var value$3 = value.userId;
                var value$4 = value.gifteeId;
                var value$5 = value.interests;
                return {
                        __typename: value.__typename,
                        recommendations: !(value$1 == null) ? value$1.map(function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var value$1 = value._id;
                                var value$2 = value.image;
                                var value$3 = value.price;
                                var value$4 = value.similarity;
                                var value$5 = value.title;
                                var value$6 = value.interest;
                                var value$7 = value.giftProvider;
                                var tmp;
                                if (value$7 == null) {
                                  tmp = undefined;
                                } else {
                                  var value$8 = value$7.logo;
                                  tmp = {
                                    __typename: value$7.__typename,
                                    logo: !(value$8 == null) ? value$8 : undefined
                                  };
                                }
                                return {
                                        __typename: value.__typename,
                                        _id: !(value$1 == null) ? Caml_option.some(value$1) : undefined,
                                        image: !(value$2 == null) ? value$2 : undefined,
                                        price: !(value$3 == null) ? value$3 : undefined,
                                        similarity: !(value$4 == null) ? value$4 : undefined,
                                        title: !(value$5 == null) ? value$5 : undefined,
                                        interest: !(value$6 == null) ? value$6 : undefined,
                                        giftProvider: tmp
                                      };
                              }) : undefined,
                        purchases: !(value$2 == null) ? value$2.map(function (value) {
                                if (value == null) {
                                  return ;
                                }
                                var value$1 = value.giftIdeaId;
                                var value$2 = value.transactionId;
                                var value$3 = value.date;
                                var value$4 = value.targetYear;
                                return {
                                        __typename: value.__typename,
                                        giftIdeaId: !(value$1 == null) ? Caml_option.some(value$1) : undefined,
                                        transactionId: !(value$2 == null) ? Caml_option.some(value$2) : undefined,
                                        date: !(value$3 == null) ? Caml_option.some(value$3) : undefined,
                                        targetYear: !(value$4 == null) ? value$4 : undefined
                                      };
                              }) : undefined,
                        userId: !(value$3 == null) ? Caml_option.some(value$3) : undefined,
                        gifteeId: !(value$4 == null) ? Caml_option.some(value$4) : undefined,
                        interests: !(value$5 == null) ? value$5.map(function (value) {
                                if (!(value == null)) {
                                  return value;
                                }
                                
                              }) : undefined
                      };
              })
        };
}

function serialize$2(value) {
  var value$1 = value.giftProfiles;
  var giftProfiles = value$1.map(function (value) {
        if (value === undefined) {
          return null;
        }
        var value$1 = value.interests;
        var interests = value$1 !== undefined ? value$1.map(function (value) {
                if (value !== undefined) {
                  return value;
                } else {
                  return null;
                }
              }) : null;
        var value$2 = value.gifteeId;
        var gifteeId = value$2 !== undefined ? Caml_option.valFromOption(value$2) : null;
        var value$3 = value.userId;
        var userId = value$3 !== undefined ? Caml_option.valFromOption(value$3) : null;
        var value$4 = value.purchases;
        var purchases = value$4 !== undefined ? value$4.map(function (value) {
                if (value === undefined) {
                  return null;
                }
                var value$1 = value.targetYear;
                var targetYear = value$1 !== undefined ? value$1 : null;
                var value$2 = value.date;
                var date = value$2 !== undefined ? Caml_option.valFromOption(value$2) : null;
                var value$3 = value.transactionId;
                var transactionId = value$3 !== undefined ? Caml_option.valFromOption(value$3) : null;
                var value$4 = value.giftIdeaId;
                var giftIdeaId = value$4 !== undefined ? Caml_option.valFromOption(value$4) : null;
                var value$5 = value.__typename;
                return {
                        __typename: value$5,
                        giftIdeaId: giftIdeaId,
                        transactionId: transactionId,
                        date: date,
                        targetYear: targetYear
                      };
              }) : null;
        var value$5 = value.recommendations;
        var recommendations = value$5 !== undefined ? value$5.map(function (value) {
                if (value === undefined) {
                  return null;
                }
                var value$1 = value.giftProvider;
                var giftProvider;
                if (value$1 !== undefined) {
                  var value$2 = value$1.logo;
                  var logo = value$2 !== undefined ? value$2 : null;
                  var value$3 = value$1.__typename;
                  giftProvider = {
                    __typename: value$3,
                    logo: logo
                  };
                } else {
                  giftProvider = null;
                }
                var value$4 = value.interest;
                var interest = value$4 !== undefined ? value$4 : null;
                var value$5 = value.title;
                var title = value$5 !== undefined ? value$5 : null;
                var value$6 = value.similarity;
                var similarity = value$6 !== undefined ? value$6 : null;
                var value$7 = value.price;
                var price = value$7 !== undefined ? value$7 : null;
                var value$8 = value.image;
                var image = value$8 !== undefined ? value$8 : null;
                var value$9 = value._id;
                var _id = value$9 !== undefined ? Caml_option.valFromOption(value$9) : null;
                var value$10 = value.__typename;
                return {
                        __typename: value$10,
                        _id: _id,
                        image: image,
                        price: price,
                        similarity: similarity,
                        title: title,
                        interest: interest,
                        giftProvider: giftProvider
                      };
              }) : null;
        var value$6 = value.__typename;
        return {
                __typename: value$6,
                recommendations: recommendations,
                purchases: purchases,
                userId: userId,
                gifteeId: gifteeId,
                interests: interests
              };
      });
  return {
          giftProfiles: giftProfiles
        };
}

function serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput$2(inp) {
  var a = inp.productId_lt;
  var a$1 = inp.weight;
  var a$2 = inp.productId_in;
  var a$3 = inp.productId_lte;
  var a$4 = inp.OR;
  var a$5 = inp.weight_lte;
  var a$6 = inp.image_ne;
  var a$7 = inp.weight_nin;
  var a$8 = inp.weight_gt;
  var a$9 = inp.title_lt;
  var a$10 = inp.image_lt;
  var a$11 = inp.image_gt;
  var a$12 = inp.productId;
  var a$13 = inp.title_ne;
  var a$14 = inp.image_in;
  var a$15 = inp.productId_ne;
  var a$16 = inp.productId_gte;
  var a$17 = inp.image_exists;
  var a$18 = inp.weight_in;
  var a$19 = inp.AND;
  var a$20 = inp.image_gte;
  var a$21 = inp.weight_gte;
  var a$22 = inp.weight_lt;
  var a$23 = inp.weight_ne;
  var a$24 = inp.title_nin;
  var a$25 = inp.title_exists;
  var a$26 = inp.productId_exists;
  var a$27 = inp.title_lte;
  var a$28 = inp.productId_gt;
  var a$29 = inp.title_gte;
  var a$30 = inp.title_gt;
  var a$31 = inp.image;
  var a$32 = inp.title;
  var a$33 = inp.productId_nin;
  var a$34 = inp.title_in;
  var a$35 = inp.image_nin;
  var a$36 = inp.image_lte;
  var a$37 = inp.weight_exists;
  return {
          productId_lt: a !== undefined ? a : undefined,
          weight: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          productId_in: a$2 !== undefined ? a$2.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          productId_lte: a$3 !== undefined ? a$3 : undefined,
          OR: a$4 !== undefined ? a$4.map(serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput$2) : undefined,
          weight_lte: a$5 !== undefined ? Caml_option.valFromOption(a$5) : undefined,
          image_ne: a$6 !== undefined ? a$6 : undefined,
          weight_nin: a$7 !== undefined ? a$7.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          weight_gt: a$8 !== undefined ? Caml_option.valFromOption(a$8) : undefined,
          title_lt: a$9 !== undefined ? a$9 : undefined,
          image_lt: a$10 !== undefined ? a$10 : undefined,
          image_gt: a$11 !== undefined ? a$11 : undefined,
          productId: a$12 !== undefined ? a$12 : undefined,
          title_ne: a$13 !== undefined ? a$13 : undefined,
          image_in: a$14 !== undefined ? a$14.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          productId_ne: a$15 !== undefined ? a$15 : undefined,
          productId_gte: a$16 !== undefined ? a$16 : undefined,
          image_exists: a$17 !== undefined ? a$17 : undefined,
          weight_in: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          AND: a$19 !== undefined ? a$19.map(serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput$2) : undefined,
          image_gte: a$20 !== undefined ? a$20 : undefined,
          weight_gte: a$21 !== undefined ? Caml_option.valFromOption(a$21) : undefined,
          weight_lt: a$22 !== undefined ? Caml_option.valFromOption(a$22) : undefined,
          weight_ne: a$23 !== undefined ? Caml_option.valFromOption(a$23) : undefined,
          title_nin: a$24 !== undefined ? a$24.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          title_exists: a$25 !== undefined ? a$25 : undefined,
          productId_exists: a$26 !== undefined ? a$26 : undefined,
          title_lte: a$27 !== undefined ? a$27 : undefined,
          productId_gt: a$28 !== undefined ? a$28 : undefined,
          title_gte: a$29 !== undefined ? a$29 : undefined,
          title_gt: a$30 !== undefined ? a$30 : undefined,
          image: a$31 !== undefined ? a$31 : undefined,
          title: a$32 !== undefined ? a$32 : undefined,
          productId_nin: a$33 !== undefined ? a$33.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          title_in: a$34 !== undefined ? a$34.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image_nin: a$35 !== undefined ? a$35.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image_lte: a$36 !== undefined ? a$36 : undefined,
          weight_exists: a$37 !== undefined ? a$37 : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput$2(inp) {
  var a = inp.image_lt;
  var a$1 = inp.title_nin;
  var a$2 = inp.price;
  var a$3 = inp._id_in;
  var a$4 = inp.price_exists;
  var a$5 = inp.price_gt;
  var a$6 = inp.similarity_nin;
  var a$7 = inp._id_nin;
  var a$8 = inp.title_lt;
  var a$9 = inp._id_gte;
  var a$10 = inp.title_in;
  var a$11 = inp.image_nin;
  var a$12 = inp.image_ne;
  var a$13 = inp.image_lte;
  var a$14 = inp._id_lte;
  var a$15 = inp.AND;
  var a$16 = inp.price_lt;
  var a$17 = inp.price_ne;
  var a$18 = inp._id_lt;
  var a$19 = inp.similarity_lt;
  var a$20 = inp.price_nin;
  var a$21 = inp.similarity;
  var a$22 = inp.image_in;
  var a$23 = inp._id_ne;
  var a$24 = inp.title_gte;
  var a$25 = inp.title_gt;
  var a$26 = inp.price_in;
  var a$27 = inp.title_ne;
  var a$28 = inp.title_lte;
  var a$29 = inp.similarity_ne;
  var a$30 = inp.price_gte;
  var a$31 = inp._id_exists;
  var a$32 = inp.image_exists;
  var a$33 = inp.similarity_lte;
  var a$34 = inp.similarity_exists;
  var a$35 = inp.title;
  var a$36 = inp.title_exists;
  var a$37 = inp._id_gt;
  var a$38 = inp.similarity_in;
  var a$39 = inp.giftProvider_exists;
  var a$40 = inp.giftProvider;
  var a$41 = inp.OR;
  var a$42 = inp._id;
  var a$43 = inp.image_gte;
  var a$44 = inp.image;
  var a$45 = inp.price_lte;
  var a$46 = inp.similarity_gt;
  var a$47 = inp.similarity_gte;
  var a$48 = inp.image_gt;
  return {
          image_lt: a !== undefined ? a : undefined,
          title_nin: a$1 !== undefined ? a$1.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          price: a$2 !== undefined ? a$2 : undefined,
          _id_in: a$3 !== undefined ? a$3.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          price_exists: a$4 !== undefined ? a$4 : undefined,
          price_gt: a$5 !== undefined ? a$5 : undefined,
          similarity_nin: a$6 !== undefined ? a$6.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          _id_nin: a$7 !== undefined ? a$7.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          title_lt: a$8 !== undefined ? a$8 : undefined,
          _id_gte: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          title_in: a$10 !== undefined ? a$10.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image_nin: a$11 !== undefined ? a$11.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image_ne: a$12 !== undefined ? a$12 : undefined,
          image_lte: a$13 !== undefined ? a$13 : undefined,
          _id_lte: a$14 !== undefined ? Caml_option.valFromOption(a$14) : undefined,
          AND: a$15 !== undefined ? a$15.map(serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput$2) : undefined,
          price_lt: a$16 !== undefined ? a$16 : undefined,
          price_ne: a$17 !== undefined ? a$17 : undefined,
          _id_lt: a$18 !== undefined ? Caml_option.valFromOption(a$18) : undefined,
          similarity_lt: a$19 !== undefined ? a$19 : undefined,
          price_nin: a$20 !== undefined ? a$20.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          similarity: a$21 !== undefined ? a$21 : undefined,
          image_in: a$22 !== undefined ? a$22.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          _id_ne: a$23 !== undefined ? Caml_option.valFromOption(a$23) : undefined,
          title_gte: a$24 !== undefined ? a$24 : undefined,
          title_gt: a$25 !== undefined ? a$25 : undefined,
          price_in: a$26 !== undefined ? a$26.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          title_ne: a$27 !== undefined ? a$27 : undefined,
          title_lte: a$28 !== undefined ? a$28 : undefined,
          similarity_ne: a$29 !== undefined ? a$29 : undefined,
          price_gte: a$30 !== undefined ? a$30 : undefined,
          _id_exists: a$31 !== undefined ? a$31 : undefined,
          image_exists: a$32 !== undefined ? a$32 : undefined,
          similarity_lte: a$33 !== undefined ? a$33 : undefined,
          similarity_exists: a$34 !== undefined ? a$34 : undefined,
          title: a$35 !== undefined ? a$35 : undefined,
          title_exists: a$36 !== undefined ? a$36 : undefined,
          _id_gt: a$37 !== undefined ? Caml_option.valFromOption(a$37) : undefined,
          similarity_in: a$38 !== undefined ? a$38.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          giftProvider_exists: a$39 !== undefined ? a$39 : undefined,
          giftProvider: a$40 !== undefined ? serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput$2(a$40) : undefined,
          OR: a$41 !== undefined ? a$41.map(serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput$2) : undefined,
          _id: a$42 !== undefined ? Caml_option.valFromOption(a$42) : undefined,
          image_gte: a$43 !== undefined ? a$43 : undefined,
          image: a$44 !== undefined ? a$44 : undefined,
          price_lte: a$45 !== undefined ? a$45 : undefined,
          similarity_gt: a$46 !== undefined ? a$46 : undefined,
          similarity_gte: a$47 !== undefined ? a$47 : undefined,
          image_gt: a$48 !== undefined ? a$48 : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput$2(inp) {
  var a = inp.likedProducts_nin;
  var a$1 = inp.likedProducts_in;
  var a$2 = inp.recommendations_nin;
  var a$3 = inp.category_in;
  var a$4 = inp.likedProducts;
  var a$5 = inp.likedProducts_exists;
  var a$6 = inp.AND;
  var a$7 = inp.category_ne;
  var a$8 = inp.category_gt;
  var a$9 = inp.category_exists;
  var a$10 = inp.category_gte;
  var a$11 = inp.OR;
  var a$12 = inp.category_lte;
  var a$13 = inp.recommendations_exists;
  var a$14 = inp.category_lt;
  var a$15 = inp.recommendations_in;
  var a$16 = inp.recommendations;
  var a$17 = inp.category;
  var a$18 = inp.category_nin;
  return {
          likedProducts_nin: a !== undefined ? a.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput$2(b);
                  }
                  
                }) : undefined,
          likedProducts_in: a$1 !== undefined ? a$1.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput$2(b);
                  }
                  
                }) : undefined,
          recommendations_nin: a$2 !== undefined ? a$2.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput$2(b);
                  }
                  
                }) : undefined,
          category_in: a$3 !== undefined ? a$3.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          likedProducts: a$4 !== undefined ? a$4.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput$2(b);
                  }
                  
                }) : undefined,
          likedProducts_exists: a$5 !== undefined ? a$5 : undefined,
          AND: a$6 !== undefined ? a$6.map(serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput$2) : undefined,
          category_ne: a$7 !== undefined ? a$7 : undefined,
          category_gt: a$8 !== undefined ? a$8 : undefined,
          category_exists: a$9 !== undefined ? a$9 : undefined,
          category_gte: a$10 !== undefined ? a$10 : undefined,
          OR: a$11 !== undefined ? a$11.map(serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput$2) : undefined,
          category_lte: a$12 !== undefined ? a$12 : undefined,
          recommendations_exists: a$13 !== undefined ? a$13 : undefined,
          category_lt: a$14 !== undefined ? a$14 : undefined,
          recommendations_in: a$15 !== undefined ? a$15.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput$2(b);
                  }
                  
                }) : undefined,
          recommendations: a$16 !== undefined ? a$16.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput$2(b);
                  }
                  
                }) : undefined,
          category: a$17 !== undefined ? a$17 : undefined,
          category_nin: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput$2(inp) {
  var a = inp.logo_exists;
  var a$1 = inp.logo_in;
  var a$2 = inp.logo_ne;
  var a$3 = inp.name_exists;
  var a$4 = inp.logo_gte;
  var a$5 = inp.name_nin;
  var a$6 = inp.logo;
  var a$7 = inp.name_ne;
  var a$8 = inp.name_lte;
  var a$9 = inp.logo_gt;
  var a$10 = inp.name;
  var a$11 = inp.name_lt;
  var a$12 = inp.logo_lt;
  var a$13 = inp.name_gt;
  var a$14 = inp.logo_nin;
  var a$15 = inp.name_gte;
  var a$16 = inp.AND;
  var a$17 = inp.OR;
  var a$18 = inp.logo_lte;
  var a$19 = inp.name_in;
  return {
          logo_exists: a !== undefined ? a : undefined,
          logo_in: a$1 !== undefined ? a$1.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          logo_ne: a$2 !== undefined ? a$2 : undefined,
          name_exists: a$3 !== undefined ? a$3 : undefined,
          logo_gte: a$4 !== undefined ? a$4 : undefined,
          name_nin: a$5 !== undefined ? a$5.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          logo: a$6 !== undefined ? a$6 : undefined,
          name_ne: a$7 !== undefined ? a$7 : undefined,
          name_lte: a$8 !== undefined ? a$8 : undefined,
          logo_gt: a$9 !== undefined ? a$9 : undefined,
          name: a$10 !== undefined ? a$10 : undefined,
          name_lt: a$11 !== undefined ? a$11 : undefined,
          logo_lt: a$12 !== undefined ? a$12 : undefined,
          name_gt: a$13 !== undefined ? a$13 : undefined,
          logo_nin: a$14 !== undefined ? a$14.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          name_gte: a$15 !== undefined ? a$15 : undefined,
          AND: a$16 !== undefined ? a$16.map(serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput$2) : undefined,
          OR: a$17 !== undefined ? a$17.map(serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput$2) : undefined,
          logo_lte: a$18 !== undefined ? a$18 : undefined,
          name_in: a$19 !== undefined ? a$19.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined
        };
}

function serializeInputObjectGiftProfileQueryInput$2(inp) {
  var a = inp.recommendations_exists;
  var a$1 = inp.recommendationsByCategory_nin;
  var a$2 = inp.createdAt_gt;
  var a$3 = inp.recommendationsByCategory_exists;
  var a$4 = inp.updatedAt_lte;
  var a$5 = inp.createdAt_gte;
  var a$6 = inp.priceMin_lt;
  var a$7 = inp.gender_in;
  var a$8 = inp.createdAt_exists;
  var a$9 = inp.userId;
  var a$10 = inp.AND;
  var a$11 = inp._id_ne;
  var a$12 = inp._id;
  var a$13 = inp.createdAt_lte;
  var a$14 = inp.priceMax_lte;
  var a$15 = inp.purchases_in;
  var a$16 = inp._id_gte;
  var a$17 = inp.priceMin_gte;
  var a$18 = inp.gifteeId_lt;
  var a$19 = inp.interests;
  var a$20 = inp.priceMin_gt;
  var a$21 = inp.userId_exists;
  var a$22 = inp.profile_nin;
  var a$23 = inp.updatedAt_nin;
  var a$24 = inp.OR;
  var a$25 = inp.gifteeId_lte;
  var a$26 = inp.updatedAt_exists;
  var a$27 = inp.priceMin_lte;
  var a$28 = inp._id_nin;
  var a$29 = inp.updatedAt_ne;
  var a$30 = inp._id_in;
  var a$31 = inp.priceMax_nin;
  var a$32 = inp.gifteeId_exists;
  var a$33 = inp.priceMin_nin;
  var a$34 = inp.createdAt_lt;
  var a$35 = inp.priceMax_exists;
  var a$36 = inp.recommendationsByCategory_in;
  var a$37 = inp._id_lt;
  var a$38 = inp.priceMax;
  var a$39 = inp.userId_in;
  var a$40 = inp.createdAt_nin;
  var a$41 = inp.gender_nin;
  var a$42 = inp.recommendations_in;
  var a$43 = inp.userId_ne;
  var a$44 = inp.priceMin;
  var a$45 = inp._id_lte;
  var a$46 = inp._id_gt;
  var a$47 = inp.priceMax_ne;
  var a$48 = inp.updatedAt;
  var a$49 = inp.gifteeId_gt;
  var a$50 = inp.updatedAt_gt;
  var a$51 = inp.updatedAt_gte;
  var a$52 = inp.userId_nin;
  var a$53 = inp.purchases;
  var a$54 = inp.gifteeId_gte;
  var a$55 = inp.recommendationsByCategory;
  var a$56 = inp.gender_ne;
  var a$57 = inp.userId_lte;
  var a$58 = inp.createdAt_in;
  var a$59 = inp.priceMax_lt;
  var a$60 = inp.gifteeId_ne;
  var a$61 = inp.purchases_exists;
  var a$62 = inp.priceMax_in;
  var a$63 = inp.profile_exists;
  var a$64 = inp.userId_gt;
  var a$65 = inp.recommendations_nin;
  var a$66 = inp.gender_exists;
  var a$67 = inp.gender_lte;
  var a$68 = inp.profile;
  var a$69 = inp.priceMin_ne;
  var a$70 = inp.gender;
  var a$71 = inp.priceMin_exists;
  var a$72 = inp.gender_lt;
  var a$73 = inp.createdAt;
  var a$74 = inp.gifteeId_nin;
  var a$75 = inp._id_exists;
  var a$76 = inp.gifteeId;
  var a$77 = inp.gender_gt;
  var a$78 = inp.updatedAt_in;
  var a$79 = inp.userId_lt;
  var a$80 = inp.priceMax_gt;
  var a$81 = inp.gender_gte;
  var a$82 = inp.purchases_nin;
  var a$83 = inp.gifteeId_in;
  var a$84 = inp.priceMax_gte;
  var a$85 = inp.interests_nin;
  var a$86 = inp.profile_in;
  var a$87 = inp.userId_gte;
  var a$88 = inp.interests_exists;
  var a$89 = inp.priceMin_in;
  var a$90 = inp.updatedAt_lt;
  var a$91 = inp.createdAt_ne;
  var a$92 = inp.recommendations;
  var a$93 = inp.interests_in;
  return {
          recommendations_exists: a !== undefined ? a : undefined,
          recommendationsByCategory_nin: a$1 !== undefined ? a$1.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput$2(b);
                  }
                  
                }) : undefined,
          createdAt_gt: a$2 !== undefined ? Caml_option.valFromOption(a$2) : undefined,
          recommendationsByCategory_exists: a$3 !== undefined ? a$3 : undefined,
          updatedAt_lte: a$4 !== undefined ? Caml_option.valFromOption(a$4) : undefined,
          createdAt_gte: a$5 !== undefined ? Caml_option.valFromOption(a$5) : undefined,
          priceMin_lt: a$6 !== undefined ? a$6 : undefined,
          gender_in: a$7 !== undefined ? a$7.map(function (b) {
                  if (b !== undefined) {
                    if (b === "UNISEX") {
                      return "UNISEX";
                    } else if (b === "MALE") {
                      return "MALE";
                    } else {
                      return "FEMALE";
                    }
                  }
                  
                }) : undefined,
          createdAt_exists: a$8 !== undefined ? a$8 : undefined,
          userId: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          AND: a$10 !== undefined ? a$10.map(serializeInputObjectGiftProfileQueryInput$2) : undefined,
          _id_ne: a$11 !== undefined ? Caml_option.valFromOption(a$11) : undefined,
          _id: a$12 !== undefined ? Caml_option.valFromOption(a$12) : undefined,
          createdAt_lte: a$13 !== undefined ? Caml_option.valFromOption(a$13) : undefined,
          priceMax_lte: a$14 !== undefined ? a$14 : undefined,
          purchases_in: a$15 !== undefined ? a$15.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfilePurchasesQueryInput$2(b);
                  }
                  
                }) : undefined,
          _id_gte: a$16 !== undefined ? Caml_option.valFromOption(a$16) : undefined,
          priceMin_gte: a$17 !== undefined ? a$17 : undefined,
          gifteeId_lt: a$18 !== undefined ? Caml_option.valFromOption(a$18) : undefined,
          interests: a$19 !== undefined ? a$19.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          priceMin_gt: a$20 !== undefined ? a$20 : undefined,
          userId_exists: a$21 !== undefined ? a$21 : undefined,
          profile_nin: a$22 !== undefined ? a$22.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileProfileQueryInput$2(b);
                  }
                  
                }) : undefined,
          updatedAt_nin: a$23 !== undefined ? a$23.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          OR: a$24 !== undefined ? a$24.map(serializeInputObjectGiftProfileQueryInput$2) : undefined,
          gifteeId_lte: a$25 !== undefined ? Caml_option.valFromOption(a$25) : undefined,
          updatedAt_exists: a$26 !== undefined ? a$26 : undefined,
          priceMin_lte: a$27 !== undefined ? a$27 : undefined,
          _id_nin: a$28 !== undefined ? a$28.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          updatedAt_ne: a$29 !== undefined ? Caml_option.valFromOption(a$29) : undefined,
          _id_in: a$30 !== undefined ? a$30.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          priceMax_nin: a$31 !== undefined ? a$31.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          gifteeId_exists: a$32 !== undefined ? a$32 : undefined,
          priceMin_nin: a$33 !== undefined ? a$33.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          createdAt_lt: a$34 !== undefined ? Caml_option.valFromOption(a$34) : undefined,
          priceMax_exists: a$35 !== undefined ? a$35 : undefined,
          recommendationsByCategory_in: a$36 !== undefined ? a$36.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput$2(b);
                  }
                  
                }) : undefined,
          _id_lt: a$37 !== undefined ? Caml_option.valFromOption(a$37) : undefined,
          priceMax: a$38 !== undefined ? a$38 : undefined,
          userId_in: a$39 !== undefined ? a$39.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          createdAt_nin: a$40 !== undefined ? a$40.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          gender_nin: a$41 !== undefined ? a$41.map(function (b) {
                  if (b !== undefined) {
                    if (b === "UNISEX") {
                      return "UNISEX";
                    } else if (b === "MALE") {
                      return "MALE";
                    } else {
                      return "FEMALE";
                    }
                  }
                  
                }) : undefined,
          recommendations_in: a$42 !== undefined ? a$42.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsQueryInput$2(b);
                  }
                  
                }) : undefined,
          userId_ne: a$43 !== undefined ? Caml_option.valFromOption(a$43) : undefined,
          priceMin: a$44 !== undefined ? a$44 : undefined,
          _id_lte: a$45 !== undefined ? Caml_option.valFromOption(a$45) : undefined,
          _id_gt: a$46 !== undefined ? Caml_option.valFromOption(a$46) : undefined,
          priceMax_ne: a$47 !== undefined ? a$47 : undefined,
          updatedAt: a$48 !== undefined ? Caml_option.valFromOption(a$48) : undefined,
          gifteeId_gt: a$49 !== undefined ? Caml_option.valFromOption(a$49) : undefined,
          updatedAt_gt: a$50 !== undefined ? Caml_option.valFromOption(a$50) : undefined,
          updatedAt_gte: a$51 !== undefined ? Caml_option.valFromOption(a$51) : undefined,
          userId_nin: a$52 !== undefined ? a$52.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          purchases: a$53 !== undefined ? a$53.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfilePurchasesQueryInput$2(b);
                  }
                  
                }) : undefined,
          gifteeId_gte: a$54 !== undefined ? Caml_option.valFromOption(a$54) : undefined,
          recommendationsByCategory: a$55 !== undefined ? a$55.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput$2(b);
                  }
                  
                }) : undefined,
          gender_ne: a$56 !== undefined ? (
              a$56 === "UNISEX" ? "UNISEX" : (
                  a$56 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          userId_lte: a$57 !== undefined ? Caml_option.valFromOption(a$57) : undefined,
          createdAt_in: a$58 !== undefined ? a$58.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          priceMax_lt: a$59 !== undefined ? a$59 : undefined,
          gifteeId_ne: a$60 !== undefined ? Caml_option.valFromOption(a$60) : undefined,
          purchases_exists: a$61 !== undefined ? a$61 : undefined,
          priceMax_in: a$62 !== undefined ? a$62.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          profile_exists: a$63 !== undefined ? a$63 : undefined,
          userId_gt: a$64 !== undefined ? Caml_option.valFromOption(a$64) : undefined,
          recommendations_nin: a$65 !== undefined ? a$65.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsQueryInput$2(b);
                  }
                  
                }) : undefined,
          gender_exists: a$66 !== undefined ? a$66 : undefined,
          gender_lte: a$67 !== undefined ? (
              a$67 === "UNISEX" ? "UNISEX" : (
                  a$67 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          profile: a$68 !== undefined ? a$68.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileProfileQueryInput$2(b);
                  }
                  
                }) : undefined,
          priceMin_ne: a$69 !== undefined ? a$69 : undefined,
          gender: a$70 !== undefined ? (
              a$70 === "UNISEX" ? "UNISEX" : (
                  a$70 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          priceMin_exists: a$71 !== undefined ? a$71 : undefined,
          gender_lt: a$72 !== undefined ? (
              a$72 === "UNISEX" ? "UNISEX" : (
                  a$72 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          createdAt: a$73 !== undefined ? Caml_option.valFromOption(a$73) : undefined,
          gifteeId_nin: a$74 !== undefined ? a$74.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          _id_exists: a$75 !== undefined ? a$75 : undefined,
          gifteeId: a$76 !== undefined ? Caml_option.valFromOption(a$76) : undefined,
          gender_gt: a$77 !== undefined ? (
              a$77 === "UNISEX" ? "UNISEX" : (
                  a$77 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          updatedAt_in: a$78 !== undefined ? a$78.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          userId_lt: a$79 !== undefined ? Caml_option.valFromOption(a$79) : undefined,
          priceMax_gt: a$80 !== undefined ? a$80 : undefined,
          gender_gte: a$81 !== undefined ? (
              a$81 === "UNISEX" ? "UNISEX" : (
                  a$81 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          purchases_nin: a$82 !== undefined ? a$82.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfilePurchasesQueryInput$2(b);
                  }
                  
                }) : undefined,
          gifteeId_in: a$83 !== undefined ? a$83.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          priceMax_gte: a$84 !== undefined ? a$84 : undefined,
          interests_nin: a$85 !== undefined ? a$85.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          profile_in: a$86 !== undefined ? a$86.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileProfileQueryInput$2(b);
                  }
                  
                }) : undefined,
          userId_gte: a$87 !== undefined ? Caml_option.valFromOption(a$87) : undefined,
          interests_exists: a$88 !== undefined ? a$88 : undefined,
          priceMin_in: a$89 !== undefined ? a$89.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          updatedAt_lt: a$90 !== undefined ? Caml_option.valFromOption(a$90) : undefined,
          createdAt_ne: a$91 !== undefined ? Caml_option.valFromOption(a$91) : undefined,
          recommendations: a$92 !== undefined ? a$92.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsQueryInput$2(b);
                  }
                  
                }) : undefined,
          interests_in: a$93 !== undefined ? a$93.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsQueryInput$2(inp) {
  var a = inp.similarity_gt;
  var a$1 = inp.similarity_lt;
  var a$2 = inp.OR;
  var a$3 = inp.interest_gt;
  var a$4 = inp.title_lte;
  var a$5 = inp.title_gt;
  var a$6 = inp.title_nin;
  var a$7 = inp.giftSource_nin;
  var a$8 = inp.title_exists;
  var a$9 = inp.giftSource_ne;
  var a$10 = inp.url_in;
  var a$11 = inp._id_lte;
  var a$12 = inp.price_exists;
  var a$13 = inp.similarity_exists;
  var a$14 = inp._id_gt;
  var a$15 = inp.similarity_ne;
  var a$16 = inp.price_ne;
  var a$17 = inp.interest_nin;
  var a$18 = inp.similarity_nin;
  var a$19 = inp.avgReview_gt;
  var a$20 = inp.interest_lte;
  var a$21 = inp.price_gt;
  var a$22 = inp.url_lt;
  var a$23 = inp.giftSource_exists;
  var a$24 = inp._id_gte;
  var a$25 = inp._id_in;
  var a$26 = inp.interest_gte;
  var a$27 = inp.price;
  var a$28 = inp.title_gte;
  var a$29 = inp.price_lt;
  var a$30 = inp.image_in;
  var a$31 = inp.image_lt;
  var a$32 = inp.interest_ne;
  var a$33 = inp.giftSource_lte;
  var a$34 = inp.avgReview_nin;
  var a$35 = inp.similarity_in;
  var a$36 = inp.url_lte;
  var a$37 = inp._id_lt;
  var a$38 = inp.price_lte;
  var a$39 = inp.title;
  var a$40 = inp.url_gte;
  var a$41 = inp.url_ne;
  var a$42 = inp.avgReview_lt;
  var a$43 = inp.price_gte;
  var a$44 = inp.similarity_lte;
  var a$45 = inp.image_ne;
  var a$46 = inp.description_lt;
  var a$47 = inp.giftProvider;
  var a$48 = inp._id_exists;
  var a$49 = inp.description_gt;
  var a$50 = inp._id_ne;
  var a$51 = inp.interest_exists;
  var a$52 = inp.avgReview;
  var a$53 = inp.giftSource_gte;
  var a$54 = inp.interest;
  var a$55 = inp.url_gt;
  var a$56 = inp.image_gt;
  var a$57 = inp.AND;
  var a$58 = inp.avgReview_in;
  var a$59 = inp.image;
  var a$60 = inp.avgReview_exists;
  var a$61 = inp.description_lte;
  var a$62 = inp._id_nin;
  var a$63 = inp.image_nin;
  var a$64 = inp.similarity;
  var a$65 = inp.avgReview_gte;
  var a$66 = inp.associatedProducts_exists;
  var a$67 = inp.associatedProducts_in;
  var a$68 = inp.giftSource;
  var a$69 = inp.description_gte;
  var a$70 = inp.description_nin;
  var a$71 = inp.description_exists;
  var a$72 = inp.title_in;
  var a$73 = inp.similarity_gte;
  var a$74 = inp.giftSource_gt;
  var a$75 = inp.url_nin;
  var a$76 = inp.interest_lt;
  var a$77 = inp.giftProvider_exists;
  var a$78 = inp.avgReview_lte;
  var a$79 = inp._id;
  var a$80 = inp.title_lt;
  var a$81 = inp.avgReview_ne;
  var a$82 = inp.image_exists;
  var a$83 = inp.price_in;
  var a$84 = inp.title_ne;
  var a$85 = inp.interest_in;
  var a$86 = inp.giftSource_lt;
  var a$87 = inp.description_in;
  var a$88 = inp.description_ne;
  var a$89 = inp.url;
  var a$90 = inp.url_exists;
  var a$91 = inp.image_gte;
  var a$92 = inp.giftSource_in;
  var a$93 = inp.associatedProducts;
  var a$94 = inp.associatedProducts_nin;
  var a$95 = inp.price_nin;
  var a$96 = inp.description;
  var a$97 = inp.image_lte;
  return {
          similarity_gt: a !== undefined ? a : undefined,
          similarity_lt: a$1 !== undefined ? a$1 : undefined,
          OR: a$2 !== undefined ? a$2.map(serializeInputObjectGiftProfileRecommendationsQueryInput$2) : undefined,
          interest_gt: a$3 !== undefined ? a$3 : undefined,
          title_lte: a$4 !== undefined ? a$4 : undefined,
          title_gt: a$5 !== undefined ? a$5 : undefined,
          title_nin: a$6 !== undefined ? a$6.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          giftSource_nin: a$7 !== undefined ? a$7.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          title_exists: a$8 !== undefined ? a$8 : undefined,
          giftSource_ne: a$9 !== undefined ? a$9 : undefined,
          url_in: a$10 !== undefined ? a$10.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          _id_lte: a$11 !== undefined ? Caml_option.valFromOption(a$11) : undefined,
          price_exists: a$12 !== undefined ? a$12 : undefined,
          similarity_exists: a$13 !== undefined ? a$13 : undefined,
          _id_gt: a$14 !== undefined ? Caml_option.valFromOption(a$14) : undefined,
          similarity_ne: a$15 !== undefined ? a$15 : undefined,
          price_ne: a$16 !== undefined ? a$16 : undefined,
          interest_nin: a$17 !== undefined ? a$17.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          similarity_nin: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          avgReview_gt: a$19 !== undefined ? a$19 : undefined,
          interest_lte: a$20 !== undefined ? a$20 : undefined,
          price_gt: a$21 !== undefined ? a$21 : undefined,
          url_lt: a$22 !== undefined ? a$22 : undefined,
          giftSource_exists: a$23 !== undefined ? a$23 : undefined,
          _id_gte: a$24 !== undefined ? Caml_option.valFromOption(a$24) : undefined,
          _id_in: a$25 !== undefined ? a$25.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          interest_gte: a$26 !== undefined ? a$26 : undefined,
          price: a$27 !== undefined ? a$27 : undefined,
          title_gte: a$28 !== undefined ? a$28 : undefined,
          price_lt: a$29 !== undefined ? a$29 : undefined,
          image_in: a$30 !== undefined ? a$30.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image_lt: a$31 !== undefined ? a$31 : undefined,
          interest_ne: a$32 !== undefined ? a$32 : undefined,
          giftSource_lte: a$33 !== undefined ? a$33 : undefined,
          avgReview_nin: a$34 !== undefined ? a$34.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          similarity_in: a$35 !== undefined ? a$35.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          url_lte: a$36 !== undefined ? a$36 : undefined,
          _id_lt: a$37 !== undefined ? Caml_option.valFromOption(a$37) : undefined,
          price_lte: a$38 !== undefined ? a$38 : undefined,
          title: a$39 !== undefined ? a$39 : undefined,
          url_gte: a$40 !== undefined ? a$40 : undefined,
          url_ne: a$41 !== undefined ? a$41 : undefined,
          avgReview_lt: a$42 !== undefined ? a$42 : undefined,
          price_gte: a$43 !== undefined ? a$43 : undefined,
          similarity_lte: a$44 !== undefined ? a$44 : undefined,
          image_ne: a$45 !== undefined ? a$45 : undefined,
          description_lt: a$46 !== undefined ? a$46 : undefined,
          giftProvider: a$47 !== undefined ? serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput$2(a$47) : undefined,
          _id_exists: a$48 !== undefined ? a$48 : undefined,
          description_gt: a$49 !== undefined ? a$49 : undefined,
          _id_ne: a$50 !== undefined ? Caml_option.valFromOption(a$50) : undefined,
          interest_exists: a$51 !== undefined ? a$51 : undefined,
          avgReview: a$52 !== undefined ? a$52 : undefined,
          giftSource_gte: a$53 !== undefined ? a$53 : undefined,
          interest: a$54 !== undefined ? a$54 : undefined,
          url_gt: a$55 !== undefined ? a$55 : undefined,
          image_gt: a$56 !== undefined ? a$56 : undefined,
          AND: a$57 !== undefined ? a$57.map(serializeInputObjectGiftProfileRecommendationsQueryInput$2) : undefined,
          avgReview_in: a$58 !== undefined ? a$58.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image: a$59 !== undefined ? a$59 : undefined,
          avgReview_exists: a$60 !== undefined ? a$60 : undefined,
          description_lte: a$61 !== undefined ? a$61 : undefined,
          _id_nin: a$62 !== undefined ? a$62.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          image_nin: a$63 !== undefined ? a$63.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          similarity: a$64 !== undefined ? a$64 : undefined,
          avgReview_gte: a$65 !== undefined ? a$65 : undefined,
          associatedProducts_exists: a$66 !== undefined ? a$66 : undefined,
          associatedProducts_in: a$67 !== undefined ? a$67.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput$2(b);
                  }
                  
                }) : undefined,
          giftSource: a$68 !== undefined ? a$68 : undefined,
          description_gte: a$69 !== undefined ? a$69 : undefined,
          description_nin: a$70 !== undefined ? a$70.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          description_exists: a$71 !== undefined ? a$71 : undefined,
          title_in: a$72 !== undefined ? a$72.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          similarity_gte: a$73 !== undefined ? a$73 : undefined,
          giftSource_gt: a$74 !== undefined ? a$74 : undefined,
          url_nin: a$75 !== undefined ? a$75.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          interest_lt: a$76 !== undefined ? a$76 : undefined,
          giftProvider_exists: a$77 !== undefined ? a$77 : undefined,
          avgReview_lte: a$78 !== undefined ? a$78 : undefined,
          _id: a$79 !== undefined ? Caml_option.valFromOption(a$79) : undefined,
          title_lt: a$80 !== undefined ? a$80 : undefined,
          avgReview_ne: a$81 !== undefined ? a$81 : undefined,
          image_exists: a$82 !== undefined ? a$82 : undefined,
          price_in: a$83 !== undefined ? a$83.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          title_ne: a$84 !== undefined ? a$84 : undefined,
          interest_in: a$85 !== undefined ? a$85.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          giftSource_lt: a$86 !== undefined ? a$86 : undefined,
          description_in: a$87 !== undefined ? a$87.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          description_ne: a$88 !== undefined ? a$88 : undefined,
          url: a$89 !== undefined ? a$89 : undefined,
          url_exists: a$90 !== undefined ? a$90 : undefined,
          image_gte: a$91 !== undefined ? a$91 : undefined,
          giftSource_in: a$92 !== undefined ? a$92.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          associatedProducts: a$93 !== undefined ? a$93.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput$2(b);
                  }
                  
                }) : undefined,
          associatedProducts_nin: a$94 !== undefined ? a$94.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput$2(b);
                  }
                  
                }) : undefined,
          price_nin: a$95 !== undefined ? a$95.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          description: a$96 !== undefined ? a$96 : undefined,
          image_lte: a$97 !== undefined ? a$97 : undefined
        };
}

function serializeInputObjectGiftProfileProfileQueryInput$2(inp) {
  var a = inp.interest;
  var a$1 = inp.productId_lt;
  var a$2 = inp.productId;
  var a$3 = inp.title_lte;
  var a$4 = inp.title_gte;
  var a$5 = inp.weight_gte;
  var a$6 = inp.interest_in;
  var a$7 = inp.productId_exists;
  var a$8 = inp.weight_exists;
  var a$9 = inp.weight_ne;
  var a$10 = inp.title_lt;
  var a$11 = inp.interest_gt;
  var a$12 = inp.weight_gt;
  var a$13 = inp.productId_in;
  var a$14 = inp.productId_ne;
  var a$15 = inp.image_lte;
  var a$16 = inp.weight_in;
  var a$17 = inp.OR;
  var a$18 = inp.image_gt;
  var a$19 = inp.AND;
  var a$20 = inp.title_gt;
  var a$21 = inp.weight;
  var a$22 = inp.interest_lte;
  var a$23 = inp.productId_gt;
  var a$24 = inp.interest_gte;
  var a$25 = inp.image_nin;
  var a$26 = inp.interest_nin;
  var a$27 = inp.title;
  var a$28 = inp.interest_lt;
  var a$29 = inp.weight_lte;
  var a$30 = inp.image_gte;
  var a$31 = inp.interest_exists;
  var a$32 = inp.productId_nin;
  var a$33 = inp.interest_ne;
  var a$34 = inp.title_exists;
  var a$35 = inp.title_ne;
  var a$36 = inp.title_in;
  var a$37 = inp.weight_lt;
  var a$38 = inp.image_ne;
  var a$39 = inp.weight_nin;
  var a$40 = inp.image_lt;
  var a$41 = inp.productId_gte;
  var a$42 = inp.image_in;
  var a$43 = inp.productId_lte;
  var a$44 = inp.image_exists;
  var a$45 = inp.title_nin;
  var a$46 = inp.image;
  return {
          interest: a !== undefined ? a : undefined,
          productId_lt: a$1 !== undefined ? a$1 : undefined,
          productId: a$2 !== undefined ? a$2 : undefined,
          title_lte: a$3 !== undefined ? a$3 : undefined,
          title_gte: a$4 !== undefined ? a$4 : undefined,
          weight_gte: a$5 !== undefined ? Caml_option.valFromOption(a$5) : undefined,
          interest_in: a$6 !== undefined ? a$6.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          productId_exists: a$7 !== undefined ? a$7 : undefined,
          weight_exists: a$8 !== undefined ? a$8 : undefined,
          weight_ne: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          title_lt: a$10 !== undefined ? a$10 : undefined,
          interest_gt: a$11 !== undefined ? a$11 : undefined,
          weight_gt: a$12 !== undefined ? Caml_option.valFromOption(a$12) : undefined,
          productId_in: a$13 !== undefined ? a$13.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          productId_ne: a$14 !== undefined ? a$14 : undefined,
          image_lte: a$15 !== undefined ? a$15 : undefined,
          weight_in: a$16 !== undefined ? a$16.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          OR: a$17 !== undefined ? a$17.map(serializeInputObjectGiftProfileProfileQueryInput$2) : undefined,
          image_gt: a$18 !== undefined ? a$18 : undefined,
          AND: a$19 !== undefined ? a$19.map(serializeInputObjectGiftProfileProfileQueryInput$2) : undefined,
          title_gt: a$20 !== undefined ? a$20 : undefined,
          weight: a$21 !== undefined ? Caml_option.valFromOption(a$21) : undefined,
          interest_lte: a$22 !== undefined ? a$22 : undefined,
          productId_gt: a$23 !== undefined ? a$23 : undefined,
          interest_gte: a$24 !== undefined ? a$24 : undefined,
          image_nin: a$25 !== undefined ? a$25.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          interest_nin: a$26 !== undefined ? a$26.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          title: a$27 !== undefined ? a$27 : undefined,
          interest_lt: a$28 !== undefined ? a$28 : undefined,
          weight_lte: a$29 !== undefined ? Caml_option.valFromOption(a$29) : undefined,
          image_gte: a$30 !== undefined ? a$30 : undefined,
          interest_exists: a$31 !== undefined ? a$31 : undefined,
          productId_nin: a$32 !== undefined ? a$32.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          interest_ne: a$33 !== undefined ? a$33 : undefined,
          title_exists: a$34 !== undefined ? a$34 : undefined,
          title_ne: a$35 !== undefined ? a$35 : undefined,
          title_in: a$36 !== undefined ? a$36.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          weight_lt: a$37 !== undefined ? Caml_option.valFromOption(a$37) : undefined,
          image_ne: a$38 !== undefined ? a$38 : undefined,
          weight_nin: a$39 !== undefined ? a$39.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          image_lt: a$40 !== undefined ? a$40 : undefined,
          productId_gte: a$41 !== undefined ? a$41 : undefined,
          image_in: a$42 !== undefined ? a$42.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          productId_lte: a$43 !== undefined ? a$43 : undefined,
          image_exists: a$44 !== undefined ? a$44 : undefined,
          title_nin: a$45 !== undefined ? a$45.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image: a$46 !== undefined ? a$46 : undefined
        };
}

function serializeInputObjectGiftProfilePurchasesQueryInput$2(inp) {
  var a = inp.giftIdeaId_ne;
  var a$1 = inp.date_exists;
  var a$2 = inp.targetYear_gt;
  var a$3 = inp.targetYear_in;
  var a$4 = inp.OR;
  var a$5 = inp.date_gt;
  var a$6 = inp.transactionId_gt;
  var a$7 = inp.transactionId_lt;
  var a$8 = inp.AND;
  var a$9 = inp.date_in;
  var a$10 = inp.targetYear_gte;
  var a$11 = inp.transactionId;
  var a$12 = inp.giftIdeaId_lt;
  var a$13 = inp.transactionId_nin;
  var a$14 = inp.transactionId_in;
  var a$15 = inp.giftIdeaId_nin;
  var a$16 = inp.giftIdeaId_in;
  var a$17 = inp.giftIdeaId_gte;
  var a$18 = inp.targetYear_nin;
  var a$19 = inp.targetYear_lt;
  var a$20 = inp.transactionId_gte;
  var a$21 = inp.giftIdeaId_gt;
  var a$22 = inp.targetYear_ne;
  var a$23 = inp.targetYear;
  var a$24 = inp.date_nin;
  var a$25 = inp.date_gte;
  var a$26 = inp.giftIdeaId_exists;
  var a$27 = inp.targetYear_exists;
  var a$28 = inp.transactionId_lte;
  var a$29 = inp.transactionId_ne;
  var a$30 = inp.transactionId_exists;
  var a$31 = inp.targetYear_lte;
  var a$32 = inp.date_lt;
  var a$33 = inp.date_ne;
  var a$34 = inp.giftIdeaId;
  var a$35 = inp.giftIdeaId_lte;
  var a$36 = inp.date_lte;
  var a$37 = inp.date;
  return {
          giftIdeaId_ne: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          date_exists: a$1 !== undefined ? a$1 : undefined,
          targetYear_gt: a$2 !== undefined ? a$2 : undefined,
          targetYear_in: a$3 !== undefined ? a$3.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          OR: a$4 !== undefined ? a$4.map(serializeInputObjectGiftProfilePurchasesQueryInput$2) : undefined,
          date_gt: a$5 !== undefined ? Caml_option.valFromOption(a$5) : undefined,
          transactionId_gt: a$6 !== undefined ? Caml_option.valFromOption(a$6) : undefined,
          transactionId_lt: a$7 !== undefined ? Caml_option.valFromOption(a$7) : undefined,
          AND: a$8 !== undefined ? a$8.map(serializeInputObjectGiftProfilePurchasesQueryInput$2) : undefined,
          date_in: a$9 !== undefined ? a$9.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          targetYear_gte: a$10 !== undefined ? a$10 : undefined,
          transactionId: a$11 !== undefined ? Caml_option.valFromOption(a$11) : undefined,
          giftIdeaId_lt: a$12 !== undefined ? Caml_option.valFromOption(a$12) : undefined,
          transactionId_nin: a$13 !== undefined ? a$13.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          transactionId_in: a$14 !== undefined ? a$14.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          giftIdeaId_nin: a$15 !== undefined ? a$15.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          giftIdeaId_in: a$16 !== undefined ? a$16.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          giftIdeaId_gte: a$17 !== undefined ? Caml_option.valFromOption(a$17) : undefined,
          targetYear_nin: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          targetYear_lt: a$19 !== undefined ? a$19 : undefined,
          transactionId_gte: a$20 !== undefined ? Caml_option.valFromOption(a$20) : undefined,
          giftIdeaId_gt: a$21 !== undefined ? Caml_option.valFromOption(a$21) : undefined,
          targetYear_ne: a$22 !== undefined ? a$22 : undefined,
          targetYear: a$23 !== undefined ? a$23 : undefined,
          date_nin: a$24 !== undefined ? a$24.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          date_gte: a$25 !== undefined ? Caml_option.valFromOption(a$25) : undefined,
          giftIdeaId_exists: a$26 !== undefined ? a$26 : undefined,
          targetYear_exists: a$27 !== undefined ? a$27 : undefined,
          transactionId_lte: a$28 !== undefined ? Caml_option.valFromOption(a$28) : undefined,
          transactionId_ne: a$29 !== undefined ? Caml_option.valFromOption(a$29) : undefined,
          transactionId_exists: a$30 !== undefined ? a$30 : undefined,
          targetYear_lte: a$31 !== undefined ? a$31 : undefined,
          date_lt: a$32 !== undefined ? Caml_option.valFromOption(a$32) : undefined,
          date_ne: a$33 !== undefined ? Caml_option.valFromOption(a$33) : undefined,
          giftIdeaId: a$34 !== undefined ? Caml_option.valFromOption(a$34) : undefined,
          giftIdeaId_lte: a$35 !== undefined ? Caml_option.valFromOption(a$35) : undefined,
          date_lte: a$36 !== undefined ? Caml_option.valFromOption(a$36) : undefined,
          date: a$37 !== undefined ? Caml_option.valFromOption(a$37) : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput$2(inp) {
  var a = inp.title_nin;
  var a$1 = inp.url;
  var a$2 = inp.title_ne;
  var a$3 = inp.title;
  var a$4 = inp.OR;
  var a$5 = inp.image_ne;
  var a$6 = inp.url_lte;
  var a$7 = inp.url_gt;
  var a$8 = inp.title_gt;
  var a$9 = inp.image_gte;
  var a$10 = inp.image_gt;
  var a$11 = inp.image_exists;
  var a$12 = inp.title_gte;
  var a$13 = inp.url_exists;
  var a$14 = inp.url_in;
  var a$15 = inp.title_lte;
  var a$16 = inp.title_in;
  var a$17 = inp.url_nin;
  var a$18 = inp.image_in;
  var a$19 = inp.url_gte;
  var a$20 = inp.image_nin;
  var a$21 = inp.image_lt;
  var a$22 = inp.image;
  var a$23 = inp.title_exists;
  var a$24 = inp.image_lte;
  var a$25 = inp.url_ne;
  var a$26 = inp.url_lt;
  var a$27 = inp.AND;
  var a$28 = inp.title_lt;
  return {
          title_nin: a !== undefined ? a.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          url: a$1 !== undefined ? a$1 : undefined,
          title_ne: a$2 !== undefined ? a$2 : undefined,
          title: a$3 !== undefined ? a$3 : undefined,
          OR: a$4 !== undefined ? a$4.map(serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput$2) : undefined,
          image_ne: a$5 !== undefined ? a$5 : undefined,
          url_lte: a$6 !== undefined ? a$6 : undefined,
          url_gt: a$7 !== undefined ? a$7 : undefined,
          title_gt: a$8 !== undefined ? a$8 : undefined,
          image_gte: a$9 !== undefined ? a$9 : undefined,
          image_gt: a$10 !== undefined ? a$10 : undefined,
          image_exists: a$11 !== undefined ? a$11 : undefined,
          title_gte: a$12 !== undefined ? a$12 : undefined,
          url_exists: a$13 !== undefined ? a$13 : undefined,
          url_in: a$14 !== undefined ? a$14.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          title_lte: a$15 !== undefined ? a$15 : undefined,
          title_in: a$16 !== undefined ? a$16.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          url_nin: a$17 !== undefined ? a$17.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image_in: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          url_gte: a$19 !== undefined ? a$19 : undefined,
          image_nin: a$20 !== undefined ? a$20.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image_lt: a$21 !== undefined ? a$21 : undefined,
          image: a$22 !== undefined ? a$22 : undefined,
          title_exists: a$23 !== undefined ? a$23 : undefined,
          image_lte: a$24 !== undefined ? a$24 : undefined,
          url_ne: a$25 !== undefined ? a$25 : undefined,
          url_lt: a$26 !== undefined ? a$26 : undefined,
          AND: a$27 !== undefined ? a$27.map(serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput$2) : undefined,
          title_lt: a$28 !== undefined ? a$28 : undefined
        };
}

function serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput$2(inp) {
  var a = inp.logo_in;
  var a$1 = inp.name;
  var a$2 = inp.name_ne;
  var a$3 = inp.logo_nin;
  var a$4 = inp.logo_gt;
  var a$5 = inp.name_exists;
  var a$6 = inp.logo_exists;
  var a$7 = inp.name_gte;
  var a$8 = inp.logo_lte;
  var a$9 = inp.logo_lt;
  var a$10 = inp.AND;
  var a$11 = inp.name_lt;
  var a$12 = inp.name_in;
  var a$13 = inp.logo;
  var a$14 = inp.logo_ne;
  var a$15 = inp.name_gt;
  var a$16 = inp.name_lte;
  var a$17 = inp.name_nin;
  var a$18 = inp.logo_gte;
  var a$19 = inp.OR;
  return {
          logo_in: a !== undefined ? a.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          name: a$1 !== undefined ? a$1 : undefined,
          name_ne: a$2 !== undefined ? a$2 : undefined,
          logo_nin: a$3 !== undefined ? a$3.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          logo_gt: a$4 !== undefined ? a$4 : undefined,
          name_exists: a$5 !== undefined ? a$5 : undefined,
          logo_exists: a$6 !== undefined ? a$6 : undefined,
          name_gte: a$7 !== undefined ? a$7 : undefined,
          logo_lte: a$8 !== undefined ? a$8 : undefined,
          logo_lt: a$9 !== undefined ? a$9 : undefined,
          AND: a$10 !== undefined ? a$10.map(serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput$2) : undefined,
          name_lt: a$11 !== undefined ? a$11 : undefined,
          name_in: a$12 !== undefined ? a$12.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          logo: a$13 !== undefined ? a$13 : undefined,
          logo_ne: a$14 !== undefined ? a$14 : undefined,
          name_gt: a$15 !== undefined ? a$15 : undefined,
          name_lte: a$16 !== undefined ? a$16 : undefined,
          name_nin: a$17 !== undefined ? a$17.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          logo_gte: a$18 !== undefined ? a$18 : undefined,
          OR: a$19 !== undefined ? a$19.map(serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput$2) : undefined
        };
}

function serializeVariables$2(inp) {
  var a = inp.query;
  return {
          query: a !== undefined ? serializeInputObjectGiftProfileQueryInput$2(a) : undefined
        };
}

function makeVariables$2(query, param) {
  return {
          query: query
        };
}

function makeInputObjectGiftProfileQueryInput$2(recommendations_exists, recommendationsByCategory_nin, createdAt_gt, recommendationsByCategory_exists, updatedAt_lte, createdAt_gte, priceMin_lt, gender_in, createdAt_exists, userId, AND, _id_ne, _id, createdAt_lte, priceMax_lte, purchases_in, _id_gte, priceMin_gte, gifteeId_lt, interests, priceMin_gt, userId_exists, profile_nin, updatedAt_nin, OR, gifteeId_lte, updatedAt_exists, priceMin_lte, _id_nin, updatedAt_ne, _id_in, priceMax_nin, gifteeId_exists, priceMin_nin, createdAt_lt, priceMax_exists, recommendationsByCategory_in, _id_lt, priceMax, userId_in, createdAt_nin, gender_nin, recommendations_in, userId_ne, priceMin, _id_lte, _id_gt, priceMax_ne, updatedAt, gifteeId_gt, updatedAt_gt, updatedAt_gte, userId_nin, purchases, gifteeId_gte, recommendationsByCategory, gender_ne, userId_lte, createdAt_in, priceMax_lt, gifteeId_ne, purchases_exists, priceMax_in, profile_exists, userId_gt, recommendations_nin, gender_exists, gender_lte, profile, priceMin_ne, gender, priceMin_exists, gender_lt, createdAt, gifteeId_nin, _id_exists, gifteeId, gender_gt, updatedAt_in, userId_lt, priceMax_gt, gender_gte, purchases_nin, gifteeId_in, priceMax_gte, interests_nin, profile_in, userId_gte, interests_exists, priceMin_in, updatedAt_lt, createdAt_ne, recommendations, interests_in, param) {
  return {
          recommendations_exists: recommendations_exists,
          recommendationsByCategory_nin: recommendationsByCategory_nin,
          createdAt_gt: createdAt_gt,
          recommendationsByCategory_exists: recommendationsByCategory_exists,
          updatedAt_lte: updatedAt_lte,
          createdAt_gte: createdAt_gte,
          priceMin_lt: priceMin_lt,
          gender_in: gender_in,
          createdAt_exists: createdAt_exists,
          userId: userId,
          AND: AND,
          _id_ne: _id_ne,
          _id: _id,
          createdAt_lte: createdAt_lte,
          priceMax_lte: priceMax_lte,
          purchases_in: purchases_in,
          _id_gte: _id_gte,
          priceMin_gte: priceMin_gte,
          gifteeId_lt: gifteeId_lt,
          interests: interests,
          priceMin_gt: priceMin_gt,
          userId_exists: userId_exists,
          profile_nin: profile_nin,
          updatedAt_nin: updatedAt_nin,
          OR: OR,
          gifteeId_lte: gifteeId_lte,
          updatedAt_exists: updatedAt_exists,
          priceMin_lte: priceMin_lte,
          _id_nin: _id_nin,
          updatedAt_ne: updatedAt_ne,
          _id_in: _id_in,
          priceMax_nin: priceMax_nin,
          gifteeId_exists: gifteeId_exists,
          priceMin_nin: priceMin_nin,
          createdAt_lt: createdAt_lt,
          priceMax_exists: priceMax_exists,
          recommendationsByCategory_in: recommendationsByCategory_in,
          _id_lt: _id_lt,
          priceMax: priceMax,
          userId_in: userId_in,
          createdAt_nin: createdAt_nin,
          gender_nin: gender_nin,
          recommendations_in: recommendations_in,
          userId_ne: userId_ne,
          priceMin: priceMin,
          _id_lte: _id_lte,
          _id_gt: _id_gt,
          priceMax_ne: priceMax_ne,
          updatedAt: updatedAt,
          gifteeId_gt: gifteeId_gt,
          updatedAt_gt: updatedAt_gt,
          updatedAt_gte: updatedAt_gte,
          userId_nin: userId_nin,
          purchases: purchases,
          gifteeId_gte: gifteeId_gte,
          recommendationsByCategory: recommendationsByCategory,
          gender_ne: gender_ne,
          userId_lte: userId_lte,
          createdAt_in: createdAt_in,
          priceMax_lt: priceMax_lt,
          gifteeId_ne: gifteeId_ne,
          purchases_exists: purchases_exists,
          priceMax_in: priceMax_in,
          profile_exists: profile_exists,
          userId_gt: userId_gt,
          recommendations_nin: recommendations_nin,
          gender_exists: gender_exists,
          gender_lte: gender_lte,
          profile: profile,
          priceMin_ne: priceMin_ne,
          gender: gender,
          priceMin_exists: priceMin_exists,
          gender_lt: gender_lt,
          createdAt: createdAt,
          gifteeId_nin: gifteeId_nin,
          _id_exists: _id_exists,
          gifteeId: gifteeId,
          gender_gt: gender_gt,
          updatedAt_in: updatedAt_in,
          userId_lt: userId_lt,
          priceMax_gt: priceMax_gt,
          gender_gte: gender_gte,
          purchases_nin: purchases_nin,
          gifteeId_in: gifteeId_in,
          priceMax_gte: priceMax_gte,
          interests_nin: interests_nin,
          profile_in: profile_in,
          userId_gte: userId_gte,
          interests_exists: interests_exists,
          priceMin_in: priceMin_in,
          updatedAt_lt: updatedAt_lt,
          createdAt_ne: createdAt_ne,
          recommendations: recommendations,
          interests_in: interests_in
        };
}

function makeInputObjectGiftProfileRecommendationsByCategoryQueryInput$2(likedProducts_nin, likedProducts_in, recommendations_nin, category_in, likedProducts, likedProducts_exists, AND, category_ne, category_gt, category_exists, category_gte, OR, category_lte, recommendations_exists, category_lt, recommendations_in, recommendations, category, category_nin, param) {
  return {
          likedProducts_nin: likedProducts_nin,
          likedProducts_in: likedProducts_in,
          recommendations_nin: recommendations_nin,
          category_in: category_in,
          likedProducts: likedProducts,
          likedProducts_exists: likedProducts_exists,
          AND: AND,
          category_ne: category_ne,
          category_gt: category_gt,
          category_exists: category_exists,
          category_gte: category_gte,
          OR: OR,
          category_lte: category_lte,
          recommendations_exists: recommendations_exists,
          category_lt: category_lt,
          recommendations_in: recommendations_in,
          recommendations: recommendations,
          category: category,
          category_nin: category_nin
        };
}

function makeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput$2(productId_lt, weight, productId_in, productId_lte, OR, weight_lte, image_ne, weight_nin, weight_gt, title_lt, image_lt, image_gt, productId, title_ne, image_in, productId_ne, productId_gte, image_exists, weight_in, AND, image_gte, weight_gte, weight_lt, weight_ne, title_nin, title_exists, productId_exists, title_lte, productId_gt, title_gte, title_gt, image, title, productId_nin, title_in, image_nin, image_lte, weight_exists, param) {
  return {
          productId_lt: productId_lt,
          weight: weight,
          productId_in: productId_in,
          productId_lte: productId_lte,
          OR: OR,
          weight_lte: weight_lte,
          image_ne: image_ne,
          weight_nin: weight_nin,
          weight_gt: weight_gt,
          title_lt: title_lt,
          image_lt: image_lt,
          image_gt: image_gt,
          productId: productId,
          title_ne: title_ne,
          image_in: image_in,
          productId_ne: productId_ne,
          productId_gte: productId_gte,
          image_exists: image_exists,
          weight_in: weight_in,
          AND: AND,
          image_gte: image_gte,
          weight_gte: weight_gte,
          weight_lt: weight_lt,
          weight_ne: weight_ne,
          title_nin: title_nin,
          title_exists: title_exists,
          productId_exists: productId_exists,
          title_lte: title_lte,
          productId_gt: productId_gt,
          title_gte: title_gte,
          title_gt: title_gt,
          image: image,
          title: title,
          productId_nin: productId_nin,
          title_in: title_in,
          image_nin: image_nin,
          image_lte: image_lte,
          weight_exists: weight_exists
        };
}

function makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput$2(image_lt, title_nin, price, _id_in, price_exists, price_gt, similarity_nin, _id_nin, title_lt, _id_gte, title_in, image_nin, image_ne, image_lte, _id_lte, AND, price_lt, price_ne, _id_lt, similarity_lt, price_nin, similarity, image_in, _id_ne, title_gte, title_gt, price_in, title_ne, title_lte, similarity_ne, price_gte, _id_exists, image_exists, similarity_lte, similarity_exists, title, title_exists, _id_gt, similarity_in, giftProvider_exists, giftProvider, OR, _id, image_gte, image, price_lte, similarity_gt, similarity_gte, image_gt, param) {
  return {
          image_lt: image_lt,
          title_nin: title_nin,
          price: price,
          _id_in: _id_in,
          price_exists: price_exists,
          price_gt: price_gt,
          similarity_nin: similarity_nin,
          _id_nin: _id_nin,
          title_lt: title_lt,
          _id_gte: _id_gte,
          title_in: title_in,
          image_nin: image_nin,
          image_ne: image_ne,
          image_lte: image_lte,
          _id_lte: _id_lte,
          AND: AND,
          price_lt: price_lt,
          price_ne: price_ne,
          _id_lt: _id_lt,
          similarity_lt: similarity_lt,
          price_nin: price_nin,
          similarity: similarity,
          image_in: image_in,
          _id_ne: _id_ne,
          title_gte: title_gte,
          title_gt: title_gt,
          price_in: price_in,
          title_ne: title_ne,
          title_lte: title_lte,
          similarity_ne: similarity_ne,
          price_gte: price_gte,
          _id_exists: _id_exists,
          image_exists: image_exists,
          similarity_lte: similarity_lte,
          similarity_exists: similarity_exists,
          title: title,
          title_exists: title_exists,
          _id_gt: _id_gt,
          similarity_in: similarity_in,
          giftProvider_exists: giftProvider_exists,
          giftProvider: giftProvider,
          OR: OR,
          _id: _id,
          image_gte: image_gte,
          image: image,
          price_lte: price_lte,
          similarity_gt: similarity_gt,
          similarity_gte: similarity_gte,
          image_gt: image_gt
        };
}

function makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput$2(logo_exists, logo_in, logo_ne, name_exists, logo_gte, name_nin, logo, name_ne, name_lte, logo_gt, name, name_lt, logo_lt, name_gt, logo_nin, name_gte, AND, OR, logo_lte, name_in, param) {
  return {
          logo_exists: logo_exists,
          logo_in: logo_in,
          logo_ne: logo_ne,
          name_exists: name_exists,
          logo_gte: logo_gte,
          name_nin: name_nin,
          logo: logo,
          name_ne: name_ne,
          name_lte: name_lte,
          logo_gt: logo_gt,
          name: name,
          name_lt: name_lt,
          logo_lt: logo_lt,
          name_gt: name_gt,
          logo_nin: logo_nin,
          name_gte: name_gte,
          AND: AND,
          OR: OR,
          logo_lte: logo_lte,
          name_in: name_in
        };
}

function makeInputObjectGiftProfilePurchasesQueryInput$2(giftIdeaId_ne, date_exists, targetYear_gt, targetYear_in, OR, date_gt, transactionId_gt, transactionId_lt, AND, date_in, targetYear_gte, transactionId, giftIdeaId_lt, transactionId_nin, transactionId_in, giftIdeaId_nin, giftIdeaId_in, giftIdeaId_gte, targetYear_nin, targetYear_lt, transactionId_gte, giftIdeaId_gt, targetYear_ne, targetYear, date_nin, date_gte, giftIdeaId_exists, targetYear_exists, transactionId_lte, transactionId_ne, transactionId_exists, targetYear_lte, date_lt, date_ne, giftIdeaId, giftIdeaId_lte, date_lte, date, param) {
  return {
          giftIdeaId_ne: giftIdeaId_ne,
          date_exists: date_exists,
          targetYear_gt: targetYear_gt,
          targetYear_in: targetYear_in,
          OR: OR,
          date_gt: date_gt,
          transactionId_gt: transactionId_gt,
          transactionId_lt: transactionId_lt,
          AND: AND,
          date_in: date_in,
          targetYear_gte: targetYear_gte,
          transactionId: transactionId,
          giftIdeaId_lt: giftIdeaId_lt,
          transactionId_nin: transactionId_nin,
          transactionId_in: transactionId_in,
          giftIdeaId_nin: giftIdeaId_nin,
          giftIdeaId_in: giftIdeaId_in,
          giftIdeaId_gte: giftIdeaId_gte,
          targetYear_nin: targetYear_nin,
          targetYear_lt: targetYear_lt,
          transactionId_gte: transactionId_gte,
          giftIdeaId_gt: giftIdeaId_gt,
          targetYear_ne: targetYear_ne,
          targetYear: targetYear,
          date_nin: date_nin,
          date_gte: date_gte,
          giftIdeaId_exists: giftIdeaId_exists,
          targetYear_exists: targetYear_exists,
          transactionId_lte: transactionId_lte,
          transactionId_ne: transactionId_ne,
          transactionId_exists: transactionId_exists,
          targetYear_lte: targetYear_lte,
          date_lt: date_lt,
          date_ne: date_ne,
          giftIdeaId: giftIdeaId,
          giftIdeaId_lte: giftIdeaId_lte,
          date_lte: date_lte,
          date: date
        };
}

function makeInputObjectGiftProfileProfileQueryInput$2(interest, productId_lt, productId, title_lte, title_gte, weight_gte, interest_in, productId_exists, weight_exists, weight_ne, title_lt, interest_gt, weight_gt, productId_in, productId_ne, image_lte, weight_in, OR, image_gt, AND, title_gt, weight, interest_lte, productId_gt, interest_gte, image_nin, interest_nin, title, interest_lt, weight_lte, image_gte, interest_exists, productId_nin, interest_ne, title_exists, title_ne, title_in, weight_lt, image_ne, weight_nin, image_lt, productId_gte, image_in, productId_lte, image_exists, title_nin, image, param) {
  return {
          interest: interest,
          productId_lt: productId_lt,
          productId: productId,
          title_lte: title_lte,
          title_gte: title_gte,
          weight_gte: weight_gte,
          interest_in: interest_in,
          productId_exists: productId_exists,
          weight_exists: weight_exists,
          weight_ne: weight_ne,
          title_lt: title_lt,
          interest_gt: interest_gt,
          weight_gt: weight_gt,
          productId_in: productId_in,
          productId_ne: productId_ne,
          image_lte: image_lte,
          weight_in: weight_in,
          OR: OR,
          image_gt: image_gt,
          AND: AND,
          title_gt: title_gt,
          weight: weight,
          interest_lte: interest_lte,
          productId_gt: productId_gt,
          interest_gte: interest_gte,
          image_nin: image_nin,
          interest_nin: interest_nin,
          title: title,
          interest_lt: interest_lt,
          weight_lte: weight_lte,
          image_gte: image_gte,
          interest_exists: interest_exists,
          productId_nin: productId_nin,
          interest_ne: interest_ne,
          title_exists: title_exists,
          title_ne: title_ne,
          title_in: title_in,
          weight_lt: weight_lt,
          image_ne: image_ne,
          weight_nin: weight_nin,
          image_lt: image_lt,
          productId_gte: productId_gte,
          image_in: image_in,
          productId_lte: productId_lte,
          image_exists: image_exists,
          title_nin: title_nin,
          image: image
        };
}

function makeInputObjectGiftProfileRecommendationsQueryInput$2(similarity_gt, similarity_lt, OR, interest_gt, title_lte, title_gt, title_nin, giftSource_nin, title_exists, giftSource_ne, url_in, _id_lte, price_exists, similarity_exists, _id_gt, similarity_ne, price_ne, interest_nin, similarity_nin, avgReview_gt, interest_lte, price_gt, url_lt, giftSource_exists, _id_gte, _id_in, interest_gte, price, title_gte, price_lt, image_in, image_lt, interest_ne, giftSource_lte, avgReview_nin, similarity_in, url_lte, _id_lt, price_lte, title, url_gte, url_ne, avgReview_lt, price_gte, similarity_lte, image_ne, description_lt, giftProvider, _id_exists, description_gt, _id_ne, interest_exists, avgReview, giftSource_gte, interest, url_gt, image_gt, AND, avgReview_in, image, avgReview_exists, description_lte, _id_nin, image_nin, similarity, avgReview_gte, associatedProducts_exists, associatedProducts_in, giftSource, description_gte, description_nin, description_exists, title_in, similarity_gte, giftSource_gt, url_nin, interest_lt, giftProvider_exists, avgReview_lte, _id, title_lt, avgReview_ne, image_exists, price_in, title_ne, interest_in, giftSource_lt, description_in, description_ne, url, url_exists, image_gte, giftSource_in, associatedProducts, associatedProducts_nin, price_nin, description, image_lte, param) {
  return {
          similarity_gt: similarity_gt,
          similarity_lt: similarity_lt,
          OR: OR,
          interest_gt: interest_gt,
          title_lte: title_lte,
          title_gt: title_gt,
          title_nin: title_nin,
          giftSource_nin: giftSource_nin,
          title_exists: title_exists,
          giftSource_ne: giftSource_ne,
          url_in: url_in,
          _id_lte: _id_lte,
          price_exists: price_exists,
          similarity_exists: similarity_exists,
          _id_gt: _id_gt,
          similarity_ne: similarity_ne,
          price_ne: price_ne,
          interest_nin: interest_nin,
          similarity_nin: similarity_nin,
          avgReview_gt: avgReview_gt,
          interest_lte: interest_lte,
          price_gt: price_gt,
          url_lt: url_lt,
          giftSource_exists: giftSource_exists,
          _id_gte: _id_gte,
          _id_in: _id_in,
          interest_gte: interest_gte,
          price: price,
          title_gte: title_gte,
          price_lt: price_lt,
          image_in: image_in,
          image_lt: image_lt,
          interest_ne: interest_ne,
          giftSource_lte: giftSource_lte,
          avgReview_nin: avgReview_nin,
          similarity_in: similarity_in,
          url_lte: url_lte,
          _id_lt: _id_lt,
          price_lte: price_lte,
          title: title,
          url_gte: url_gte,
          url_ne: url_ne,
          avgReview_lt: avgReview_lt,
          price_gte: price_gte,
          similarity_lte: similarity_lte,
          image_ne: image_ne,
          description_lt: description_lt,
          giftProvider: giftProvider,
          _id_exists: _id_exists,
          description_gt: description_gt,
          _id_ne: _id_ne,
          interest_exists: interest_exists,
          avgReview: avgReview,
          giftSource_gte: giftSource_gte,
          interest: interest,
          url_gt: url_gt,
          image_gt: image_gt,
          AND: AND,
          avgReview_in: avgReview_in,
          image: image,
          avgReview_exists: avgReview_exists,
          description_lte: description_lte,
          _id_nin: _id_nin,
          image_nin: image_nin,
          similarity: similarity,
          avgReview_gte: avgReview_gte,
          associatedProducts_exists: associatedProducts_exists,
          associatedProducts_in: associatedProducts_in,
          giftSource: giftSource,
          description_gte: description_gte,
          description_nin: description_nin,
          description_exists: description_exists,
          title_in: title_in,
          similarity_gte: similarity_gte,
          giftSource_gt: giftSource_gt,
          url_nin: url_nin,
          interest_lt: interest_lt,
          giftProvider_exists: giftProvider_exists,
          avgReview_lte: avgReview_lte,
          _id: _id,
          title_lt: title_lt,
          avgReview_ne: avgReview_ne,
          image_exists: image_exists,
          price_in: price_in,
          title_ne: title_ne,
          interest_in: interest_in,
          giftSource_lt: giftSource_lt,
          description_in: description_in,
          description_ne: description_ne,
          url: url,
          url_exists: url_exists,
          image_gte: image_gte,
          giftSource_in: giftSource_in,
          associatedProducts: associatedProducts,
          associatedProducts_nin: associatedProducts_nin,
          price_nin: price_nin,
          description: description,
          image_lte: image_lte
        };
}

function makeInputObjectGiftProfileRecommendationsGiftProviderQueryInput$2(logo_in, name, name_ne, logo_nin, logo_gt, name_exists, logo_exists, name_gte, logo_lte, logo_lt, AND, name_lt, name_in, logo, logo_ne, name_gt, name_lte, name_nin, logo_gte, OR, param) {
  return {
          logo_in: logo_in,
          name: name,
          name_ne: name_ne,
          logo_nin: logo_nin,
          logo_gt: logo_gt,
          name_exists: name_exists,
          logo_exists: logo_exists,
          name_gte: name_gte,
          logo_lte: logo_lte,
          logo_lt: logo_lt,
          AND: AND,
          name_lt: name_lt,
          name_in: name_in,
          logo: logo,
          logo_ne: logo_ne,
          name_gt: name_gt,
          name_lte: name_lte,
          name_nin: name_nin,
          logo_gte: logo_gte,
          OR: OR
        };
}

function makeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput$2(title_nin, url, title_ne, title, OR, image_ne, url_lte, url_gt, title_gt, image_gte, image_gt, image_exists, title_gte, url_exists, url_in, title_lte, title_in, url_nin, image_in, url_gte, image_nin, image_lt, image, title_exists, image_lte, url_ne, url_lt, AND, title_lt, param) {
  return {
          title_nin: title_nin,
          url: url,
          title_ne: title_ne,
          title: title,
          OR: OR,
          image_ne: image_ne,
          url_lte: url_lte,
          url_gt: url_gt,
          title_gt: title_gt,
          image_gte: image_gte,
          image_gt: image_gt,
          image_exists: image_exists,
          title_gte: title_gte,
          url_exists: url_exists,
          url_in: url_in,
          title_lte: title_lte,
          title_in: title_in,
          url_nin: url_nin,
          image_in: image_in,
          url_gte: url_gte,
          image_nin: image_nin,
          image_lt: image_lt,
          image: image,
          title_exists: title_exists,
          image_lte: image_lte,
          url_ne: url_ne,
          url_lt: url_lt,
          AND: AND,
          title_lt: title_lt
        };
}

function makeDefaultVariables$1(param) {
  return {
          query: undefined
        };
}

var GetGiftProfilesQuery_inner = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  serializeInputObjectGiftProfileQueryInput: serializeInputObjectGiftProfileQueryInput$2,
  serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput$2,
  serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput$2,
  serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput$2,
  serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput$2,
  serializeInputObjectGiftProfilePurchasesQueryInput: serializeInputObjectGiftProfilePurchasesQueryInput$2,
  serializeInputObjectGiftProfileProfileQueryInput: serializeInputObjectGiftProfileProfileQueryInput$2,
  serializeInputObjectGiftProfileRecommendationsQueryInput: serializeInputObjectGiftProfileRecommendationsQueryInput$2,
  serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput: serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput$2,
  serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput: serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput$2,
  makeVariables: makeVariables$2,
  makeInputObjectGiftProfileQueryInput: makeInputObjectGiftProfileQueryInput$2,
  makeInputObjectGiftProfileRecommendationsByCategoryQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryQueryInput$2,
  makeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput$2,
  makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput$2,
  makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput$2,
  makeInputObjectGiftProfilePurchasesQueryInput: makeInputObjectGiftProfilePurchasesQueryInput$2,
  makeInputObjectGiftProfileProfileQueryInput: makeInputObjectGiftProfileProfileQueryInput$2,
  makeInputObjectGiftProfileRecommendationsQueryInput: makeInputObjectGiftProfileRecommendationsQueryInput$2,
  makeInputObjectGiftProfileRecommendationsGiftProviderQueryInput: makeInputObjectGiftProfileRecommendationsGiftProviderQueryInput$2,
  makeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput: makeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput$2,
  makeDefaultVariables: makeDefaultVariables$1
};

var include$2 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$2,
      Raw: Raw$2,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables$2
    });

var GetGiftProfilesQuery_refetchQueryDescription = include$2.refetchQueryDescription;

var GetGiftProfilesQuery_use = include$2.use;

var GetGiftProfilesQuery_useLazy = include$2.useLazy;

var GetGiftProfilesQuery_useLazyWithVariables = include$2.useLazyWithVariables;

var GetGiftProfilesQuery = {
  GetGiftProfilesQuery_inner: GetGiftProfilesQuery_inner,
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  serializeInputObjectGiftProfileQueryInput: serializeInputObjectGiftProfileQueryInput$2,
  serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryQueryInput$2,
  serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput$2,
  serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput$2,
  serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput: serializeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput$2,
  serializeInputObjectGiftProfilePurchasesQueryInput: serializeInputObjectGiftProfilePurchasesQueryInput$2,
  serializeInputObjectGiftProfileProfileQueryInput: serializeInputObjectGiftProfileProfileQueryInput$2,
  serializeInputObjectGiftProfileRecommendationsQueryInput: serializeInputObjectGiftProfileRecommendationsQueryInput$2,
  serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput: serializeInputObjectGiftProfileRecommendationsGiftProviderQueryInput$2,
  serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput: serializeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput$2,
  makeVariables: makeVariables$2,
  makeInputObjectGiftProfileQueryInput: makeInputObjectGiftProfileQueryInput$2,
  makeInputObjectGiftProfileRecommendationsByCategoryQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryQueryInput$2,
  makeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryLikedProductsQueryInput$2,
  makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsQueryInput$2,
  makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput: makeInputObjectGiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput$2,
  makeInputObjectGiftProfilePurchasesQueryInput: makeInputObjectGiftProfilePurchasesQueryInput$2,
  makeInputObjectGiftProfileProfileQueryInput: makeInputObjectGiftProfileProfileQueryInput$2,
  makeInputObjectGiftProfileRecommendationsQueryInput: makeInputObjectGiftProfileRecommendationsQueryInput$2,
  makeInputObjectGiftProfileRecommendationsGiftProviderQueryInput: makeInputObjectGiftProfileRecommendationsGiftProviderQueryInput$2,
  makeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput: makeInputObjectGiftProfileRecommendationsAssociatedProductsQueryInput$2,
  makeDefaultVariables: makeDefaultVariables$1,
  refetchQueryDescription: GetGiftProfilesQuery_refetchQueryDescription,
  use: GetGiftProfilesQuery_use,
  useLazy: GetGiftProfilesQuery_useLazy,
  useLazyWithVariables: GetGiftProfilesQuery_useLazyWithVariables
};

export {
  GetGiftProfileQuery ,
  UpdateOneGiftProfileMut ,
  GetGiftProfilesQuery ,
  
}
/* query Not a pure module */
