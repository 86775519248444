// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  query GetGiftCategoriesQuery($query: CategoryQueryInput)  {
    categorys(query: $query)  {
      __typename
      _id
      value
      relatedValues
      icon
    }
  }
`);

function parse(value) {
  var value$1 = value.categorys;
  return {
          categorys: value$1.map(function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value._id;
                var value$2 = value.value;
                var value$3 = value.relatedValues;
                var value$4 = value.icon;
                return {
                        __typename: value.__typename,
                        _id: !(value$1 == null) ? Caml_option.some(value$1) : undefined,
                        value: !(value$2 == null) ? value$2 : undefined,
                        relatedValues: !(value$3 == null) ? value$3.map(function (value) {
                                if (!(value == null)) {
                                  return value;
                                }
                                
                              }) : undefined,
                        icon: !(value$4 == null) ? value$4 : undefined
                      };
              })
        };
}

function serialize(value) {
  var value$1 = value.categorys;
  var categorys = value$1.map(function (value) {
        if (value === undefined) {
          return null;
        }
        var value$1 = value.icon;
        var icon = value$1 !== undefined ? value$1 : null;
        var value$2 = value.relatedValues;
        var relatedValues = value$2 !== undefined ? value$2.map(function (value) {
                if (value !== undefined) {
                  return value;
                } else {
                  return null;
                }
              }) : null;
        var value$3 = value.value;
        var value$4 = value$3 !== undefined ? value$3 : null;
        var value$5 = value._id;
        var _id = value$5 !== undefined ? Caml_option.valFromOption(value$5) : null;
        var value$6 = value.__typename;
        return {
                __typename: value$6,
                _id: _id,
                value: value$4,
                relatedValues: relatedValues,
                icon: icon
              };
      });
  return {
          categorys: categorys
        };
}

function serializeInputObjectCategoryQueryInput(inp) {
  var a = inp.icon_exists;
  var a$1 = inp.icon_lte;
  var a$2 = inp.value_gte;
  var a$3 = inp.value_exists;
  var a$4 = inp.value_gt;
  var a$5 = inp.value_in;
  var a$6 = inp.icon_lt;
  var a$7 = inp.value_lt;
  var a$8 = inp.icon;
  var a$9 = inp.icon_nin;
  var a$10 = inp.icon_ne;
  var a$11 = inp.icon_gt;
  var a$12 = inp._id_gt;
  var a$13 = inp._id_lt;
  var a$14 = inp.relatedValues;
  var a$15 = inp._id_gte;
  var a$16 = inp.OR;
  var a$17 = inp.value_nin;
  var a$18 = inp.relatedValues_exists;
  var a$19 = inp._id_lte;
  var a$20 = inp.AND;
  var a$21 = inp._id_in;
  var a$22 = inp._id;
  var a$23 = inp.value_lte;
  var a$24 = inp.relatedValues_in;
  var a$25 = inp._id_ne;
  var a$26 = inp.value_ne;
  var a$27 = inp._id_nin;
  var a$28 = inp._id_exists;
  var a$29 = inp.icon_gte;
  var a$30 = inp.relatedValues_nin;
  var a$31 = inp.value;
  var a$32 = inp.icon_in;
  return {
          icon_exists: a !== undefined ? a : undefined,
          icon_lte: a$1 !== undefined ? a$1 : undefined,
          value_gte: a$2 !== undefined ? a$2 : undefined,
          value_exists: a$3 !== undefined ? a$3 : undefined,
          value_gt: a$4 !== undefined ? a$4 : undefined,
          value_in: a$5 !== undefined ? a$5.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          icon_lt: a$6 !== undefined ? a$6 : undefined,
          value_lt: a$7 !== undefined ? a$7 : undefined,
          icon: a$8 !== undefined ? a$8 : undefined,
          icon_nin: a$9 !== undefined ? a$9.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          icon_ne: a$10 !== undefined ? a$10 : undefined,
          icon_gt: a$11 !== undefined ? a$11 : undefined,
          _id_gt: a$12 !== undefined ? Caml_option.valFromOption(a$12) : undefined,
          _id_lt: a$13 !== undefined ? Caml_option.valFromOption(a$13) : undefined,
          relatedValues: a$14 !== undefined ? a$14.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          _id_gte: a$15 !== undefined ? Caml_option.valFromOption(a$15) : undefined,
          OR: a$16 !== undefined ? a$16.map(serializeInputObjectCategoryQueryInput) : undefined,
          value_nin: a$17 !== undefined ? a$17.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          relatedValues_exists: a$18 !== undefined ? a$18 : undefined,
          _id_lte: a$19 !== undefined ? Caml_option.valFromOption(a$19) : undefined,
          AND: a$20 !== undefined ? a$20.map(serializeInputObjectCategoryQueryInput) : undefined,
          _id_in: a$21 !== undefined ? a$21.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          _id: a$22 !== undefined ? Caml_option.valFromOption(a$22) : undefined,
          value_lte: a$23 !== undefined ? a$23 : undefined,
          relatedValues_in: a$24 !== undefined ? a$24.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          _id_ne: a$25 !== undefined ? Caml_option.valFromOption(a$25) : undefined,
          value_ne: a$26 !== undefined ? a$26 : undefined,
          _id_nin: a$27 !== undefined ? a$27.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          _id_exists: a$28 !== undefined ? a$28 : undefined,
          icon_gte: a$29 !== undefined ? a$29 : undefined,
          relatedValues_nin: a$30 !== undefined ? a$30.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          value: a$31 !== undefined ? a$31 : undefined,
          icon_in: a$32 !== undefined ? a$32.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.query;
  return {
          query: a !== undefined ? serializeInputObjectCategoryQueryInput(a) : undefined
        };
}

function makeVariables(query, param) {
  return {
          query: query
        };
}

function makeInputObjectCategoryQueryInput(icon_exists, icon_lte, value_gte, value_exists, value_gt, value_in, icon_lt, value_lt, icon, icon_nin, icon_ne, icon_gt, _id_gt, _id_lt, relatedValues, _id_gte, OR, value_nin, relatedValues_exists, _id_lte, AND, _id_in, _id, value_lte, relatedValues_in, _id_ne, value_ne, _id_nin, _id_exists, icon_gte, relatedValues_nin, value, icon_in, param) {
  return {
          icon_exists: icon_exists,
          icon_lte: icon_lte,
          value_gte: value_gte,
          value_exists: value_exists,
          value_gt: value_gt,
          value_in: value_in,
          icon_lt: icon_lt,
          value_lt: value_lt,
          icon: icon,
          icon_nin: icon_nin,
          icon_ne: icon_ne,
          icon_gt: icon_gt,
          _id_gt: _id_gt,
          _id_lt: _id_lt,
          relatedValues: relatedValues,
          _id_gte: _id_gte,
          OR: OR,
          value_nin: value_nin,
          relatedValues_exists: relatedValues_exists,
          _id_lte: _id_lte,
          AND: AND,
          _id_in: _id_in,
          _id: _id,
          value_lte: value_lte,
          relatedValues_in: relatedValues_in,
          _id_ne: _id_ne,
          value_ne: value_ne,
          _id_nin: _id_nin,
          _id_exists: _id_exists,
          icon_gte: icon_gte,
          relatedValues_nin: relatedValues_nin,
          value: value,
          icon_in: icon_in
        };
}

function makeDefaultVariables(param) {
  return {
          query: undefined
        };
}

var GetGiftCategoriesQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectCategoryQueryInput: serializeInputObjectCategoryQueryInput,
  makeVariables: makeVariables,
  makeInputObjectCategoryQueryInput: makeInputObjectCategoryQueryInput,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var GetGiftCategoriesQuery_refetchQueryDescription = include.refetchQueryDescription;

var GetGiftCategoriesQuery_use = include.use;

var GetGiftCategoriesQuery_useLazy = include.useLazy;

var GetGiftCategoriesQuery_useLazyWithVariables = include.useLazyWithVariables;

var GetGiftCategoriesQuery = {
  GetGiftCategoriesQuery_inner: GetGiftCategoriesQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectCategoryQueryInput: serializeInputObjectCategoryQueryInput,
  makeVariables: makeVariables,
  makeInputObjectCategoryQueryInput: makeInputObjectCategoryQueryInput,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: GetGiftCategoriesQuery_refetchQueryDescription,
  use: GetGiftCategoriesQuery_use,
  useLazy: GetGiftCategoriesQuery_useLazy,
  useLazyWithVariables: GetGiftCategoriesQuery_useLazyWithVariables
};

export {
  GetGiftCategoriesQuery ,
  
}
/* query Not a pure module */
