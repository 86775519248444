import gql from "graphql-tag";

export const insertOneTransaction = gql`
  mutation InsertOneTransactionMutation($data: TransactionInsertInput!) {
    insertOneTransaction(data: $data) {
      _id
    }
  }
`;

export const updateOneTransaction = gql`
  mutation UpdateOneTransactionMutation($query: TransactionQueryInput!, $set: TransactionUpdateInput!) {
    updateOneTransaction(query: $query, set: $set) {
      _id
    }
  }
`;

export const deleteOneTransaction = gql`
  mutation DeleteOneTransactionMutation($query: TransactionQueryInput!) {
    deleteOneTransaction(query: $query) {
      _id
    }
  }
`;
