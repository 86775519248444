import gql from 'graphql-tag';

export const getGiftCategories = gql`
  query getGiftCategories($query: CategoryQueryInput) {
    categorys(query: $query) {
      _id
      value
      relatedValues
      icon
    }
  }
`;
