import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import { makeStyles, Typography } from '@material-ui/core';

import { addGiftType, removeGiftType } from '../../../redux/onboarding/actions';
import { OnboardingRootState } from '../../../redux/onboarding/reducers';
import { RootState } from '../../../redux/store';
import InterestSelect from '../../gifts/InterestSelect';
import { OnboardingWizardSteps } from '../OnboardingWizard';
import OnboardingStep from './OnboardingStep';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(12),
    maxWidth: 600,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
    },
  },
  specificsButton: {
    marginBottom: theme.spacing(1),
  },
}));

type Props = {} & Partial<StepWizardChildProps>;

const GiftTypesInput: FC<Props> = ({ previousStep, nextStep, currentStep }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const onboardingState = useSelector<RootState, OnboardingRootState>((state) => {
    return state.onboarding;
  });

  const addGiftTypeHandler = (type: string) => {
    if (type !== "") {
      if (onboardingState.giftTypes.includes(type)) {
        deleteGiftType(type);
      } else {
        dispatch({ ...addGiftType(type) });
      }
    }
  };

  const deleteGiftType = (type: string) => {
    dispatch({ ...removeGiftType(type) });
  };

  return (
    <OnboardingStep
      step={currentStep ?? OnboardingWizardSteps.Introduction}
      onNext={nextStep}
      onPrevious={previousStep}
    >
      <>
        <div className={classes.container}>
          <Typography variant="h5" align="center" gutterBottom>
            What kind of gifts do you like to receive?
          </Typography>
          <Typography variant="h6" align="center" gutterBottom>
            Please provide 3 to 5 categories
          </Typography>
          <InterestSelect value={onboardingState.giftTypes} onChange={(i) => addGiftTypeHandler(i)} />
        </div>
      </>
    </OnboardingStep>
  );
};

export default GiftTypesInput;
