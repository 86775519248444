import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from '@apollo/client';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import useTheme from '@material-ui/core/styles/useTheme';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useAuthContext } from '../../auth/authContext';
import { getNotificationsQuery } from '../../graphql/queries/notifications';
import {
  getNotifications, getNotificationsVariables
} from '../../graphql/queries/notifications/__generated__/getNotifications';
import { closeUserMenu, openNotificationsMenu, toggleUserMenu } from '../../redux/general/actions';
import { RootState } from '../../redux/store';
import NotificationsPanel from '../notifications/NotificationsPanel';
import SettingsMenu from '../user/SettingsMenu';
import UserAvatar from './UserAvatar';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    position: "fixed",
    top: 0,
    zIndex: 100,
    color: theme.palette.common.white,
  },
  appBar: {
    background: "none",
    boxShadow: "none",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  logo: {
    height: 30,
    marginRight: theme.spacing(1),
  },
  title: {
    color: theme.palette.text.primary,
    fontFamily: "Sacramento",
    fontSize: 32,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(9),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: "flex",
  },
  notificationIconButton: {
    width: "64px",
  },
  notificationIcon: {
    fontSize: 32,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.common.white,
    },
  },
  inviteButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  inviteText: {
    backgrondColor: "none",
    color: theme.palette.text.primary,
  },
}));

type Props = {
  children?: ReactNode;
};

const MainAppBar: FC<Props> = (props: Props) => {
  const { authState } = useAuthContext();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [notificationsCount, setNotificationsCount] = useState<number>(0);

  const { data } = useQuery<getNotifications, getNotificationsVariables>(getNotificationsQuery, {
    variables: {
      query: {
        userId: authState.user.auth.id,
      },
    },
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (data?.notifications) {
      const newNotifications = (data?.notifications ?? []).filter((n) => n?.new);
      setNotificationsCount(newNotifications.length);
    }
  }, [data]);

  const dispatch = useDispatch();
  const isMenuOpen = useSelector<RootState, boolean>((state) => {
    return state.general.userMenuShow;
  });

  const handleUserAvatarClick = (_event: React.SyntheticEvent) => {
    dispatch({ ...toggleUserMenu() });
  };

  const handleMenuClose = () => {
    dispatch({ ...closeUserMenu() });
  };

  const handleNotificationButtonClick = () => dispatch({ ...openNotificationsMenu() });

  const avatarName = authState.user.customData.name ?? authState.user.customData.email;
  const avatarPicture = authState.user.customData.picture;
  const logoSrc = theme.palette.type === "light" ? "/images/logos/contrast-logo.png" : "/images/logos/light-logo.png";

  return (
    <div className={classes.root}>
      <AppBar position="static" classes={{ root: classes.appBar }}>
        <Toolbar>
          {isMobile ? (
            <Grid container direction="row" alignItems="center" justifyContent="flex-start">
              <img className={classes.logo} src={logoSrc} />
              <Typography variant="h6" color="inherit" className={classes.title} noWrap>
                Occasional.ly
              </Typography>
            </Grid>
          ) : null}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {!isMobile ? (
              <IconButton
                color="inherit"
                onClick={handleNotificationButtonClick}
                className={classes.notificationIconButton}
              >
                <Badge badgeContent={notificationsCount} color="secondary">
                  <Icon className={classes.notificationIcon}>
                    <span className="lnr lnr-alarm"></span>
                  </Icon>
                </Badge>
              </IconButton>
            ) : null}
            <NotificationsPanel notificationsCount={notificationsCount} />
            <IconButton
              aria-owns={isMenuOpen ? "material-appbar" : undefined}
              aria-haspopup="true"
              onClick={handleUserAvatarClick}
              color="inherit"
            >
              <UserAvatar small={true} name={avatarName ?? ""} picture={avatarPicture} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {/* <Modal showModal={state.accountSettingsShow} closeModal={this.closeAccountSettings}>

      </Modal>

      <Modal showModal={state.inviteFriendShow} closeModal={this.closeInvitefriend} title="Invite Friend"> */}
      {/* <FriendInvite user={props.user} /> */}
      {/* </Modal> */}
      <SettingsMenu showMenu={isMenuOpen} closeSettingsMenu={handleMenuClose} />
    </div>
  );
};

export default MainAppBar;
