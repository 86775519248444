import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography/Typography";
import classNames from "classnames";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowGiftProfileCreatedDialog } from "../../redux/general/actions";
import { GeneralRootState } from "../../redux/general/reducers";
import { RootState } from "../../redux/store";
import PrimaryButton from "../general-ui/buttons/PrimaryButton";
import SecondaryModal from "../general-ui/modals/SecondaryModal";

const useStyles = makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(2),
  },
}));

const GiftProfileCreatedDialog: FC = () => {
  const classes = useStyles();
  const showGiftProfileCreatedDialog = useSelector<RootState, GeneralRootState>((state) => {
    return state.general;
  });
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({ ...setShowGiftProfileCreatedDialog(false) });
  };

  return (
    <SecondaryModal
      showModal={showGiftProfileCreatedDialog.giftProfileCreatedShow}
      closeModal={handleClose}
      secondModal={true}
    >
      <div className={classes.text}>
        <Typography align="center" variant="h5" gutterBottom>
          Thank you for creating your gift profile!
        </Typography>
        <Typography align="center" variant="h6" gutterBottom>
          Now that you have created your gift profile, friends and family will know exactly what to get you.
        </Typography>
      </div>
      <PrimaryButton onClick={handleClose}>You're very welcome</PrimaryButton>
    </SecondaryModal>
  );
};

export default GiftProfileCreatedDialog;
