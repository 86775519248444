type gifteeRouteReturn = {
  INFO: string;
  OCCASIONS: string;
  GIFT_IDEAS: string;
  INTERESTS: string;
};

export const useGifteeRoutes = (
  gifteeId: string
): [ReadonlyArray<{ to: string; label: string; icon: string }>, gifteeRouteReturn] => {
  const gifteeRoutes = gifteeRoutesStringMap(gifteeId);
  return [
    [
      { to: gifteeRoutes.INFO, label: "Info", icon: "lnr lnr-user" },
      { to: gifteeRoutes.OCCASIONS, label: "Occasions", icon: "lnr lnr-calendar-full" },
      { to: gifteeRoutes.INTERESTS, label: "Interests", icon: "lnr lnr-brain" },
      { to: gifteeRoutes.GIFT_IDEAS, label: "Gift Ideas", icon: "lnr lnr-gift" },
    ],
    gifteeRoutes,
  ];
};

const gifteeRoutesStringMap = (gifteeId: string) => ({
  INFO: `/giftee/${gifteeId}/info`,
  OCCASIONS: `/giftee/${gifteeId}/occasions`,
  INTERESTS: `/giftee/${gifteeId}/interests`,
  GIFT_IDEAS: `/giftee/${gifteeId}/gift-ideas`,
});
