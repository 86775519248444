import React, { FC } from "react";

import DesktopLoginSignup from "./DesktopLoginSignup";
import useIsMobile from "../../hooks/useIsMobile";
import MobileSignup from "./MobileLoginSignup";

const LoginSignup: FC = () => {
  const isMobile = useIsMobile();

  if (!isMobile) {
    return <DesktopLoginSignup />;
  } else {
    return <MobileSignup />;
  }
};

export default LoginSignup;
