import gql from "graphql-tag";

export const insertOneOccasion = gql`
  mutation InsertOneOccasionMutation($data: OccasionInsertInput!) {
    insertOneOccasion(data: $data) {
      _id
    }
  }
`;

export const updateOneOccasion = gql`
  mutation UpdateOneOccasionMutation($query: OccasionQueryInput!, $set: OccasionUpdateInput!) {
    updateOneOccasion(query: $query, set: $set) {
      _id
    }
  }
`;

export const deleteOneOccasion = gql`
  mutation DeleteOneOccasionMutation($query: OccasionQueryInput!) {
    deleteOneOccasion(query: $query) {
      _id
    }
  }
`;
