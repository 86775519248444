import gql from 'graphql-tag';

export const giftIdeaSearch = gql`
  query GiftIdeaSearch($input: String) {
    GiftIdeaSearch(input: $input) {
      _id
      createdAt
      description
      giftSource
      image
      price
      tags
      title
      updatedAt
      url
    }
  }
`;

export const getGiftIdeasQuery = gql`
  query getGiftIdeas($query: ProductQueryInput) {
    products(query: $query) {
      _id
      createdAt
      description
      giftSource
      image
      price
      tags
      title
      updatedAt
      url
    }
  }
`;
