import React, { FC } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { StepWizardChildProps } from "react-step-wizard";
import OnboardingStep from "./OnboardingStep";
import useIsMobile from "../../../hooks/useIsMobile";
import { OnboardingWizardSteps } from "../OnboardingWizard";

const useStyles = makeStyles((theme) => ({
  intro: {
    position: "relative",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    maxWidth: 600,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      alignItems: "flex-start",
      paddingTop: "40vh",
      "&:before": {
        width: "75vw",
        height: "48vh",
        top: "-5vh",
        left: "40%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
        backgroundSize: "contain",
        backgroundImage: 'url("/images/open-gift-streamers.png")',
        zIndex: 0,
        content: '""',
        position: "absolute",
      },
    },
  },
  headline: {
    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
    marginBottom: theme.spacing(3),
  },
  mainImage: {
    width: "50%",
    maxWidth: 225,
    objectFit: "contain",
    margin: "30px 0px",
  },
}));

type Props = Partial<StepWizardChildProps>;

const GiftProfileCreationOutro: FC<Props> = ({ nextStep, previousStep, currentStep }) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  return (
    <OnboardingStep
      step={currentStep ?? OnboardingWizardSteps.Introduction}
      onNext={nextStep}
      onPrevious={previousStep}
    >
      <div className={classes.intro} id="occasion-instructions">
        {!isMobile && <img className={classes.mainImage} src="/images/open-gift-streamers.png" />}
        <Typography variant="h5" align={isMobile ? "left" : "center"} className={classes.headline}>
          You're all done! Thanks!
        </Typography>
      </div>
    </OnboardingStep>
  );
};

export default GiftProfileCreationOutro;
