import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import { useApolloClient, useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { useAuthContext } from '../../../auth/authContext';
import { getGifteesQuery } from '../../../graphql/queries/giftees';
import {
  getGiftees, getGiftees_giftees as GifteeType, getGifteesVariables
} from '../../../graphql/queries/giftees/__generated__/getGiftees';
import { UserSearch_UserSearch } from '../../../graphql/queries/users/__generated__/UserSearch';
import useUserSearch from '../../../hooks/useUserSearch';
import { AddGifteeRootState } from '../../../redux/add-giftee/reducers';
import { RootState } from '../../../redux/store';
import SecondaryButton from '../../general-ui/buttons/SecondaryButton';
import IconInput from '../../general-ui/inputs/IconInput';
import ModalPage from '../../general-ui/modals/ModalPage';
import UserSearchResult from '../../general-ui/UserSearchResult';
import WizardStep from '../general-wizard-ui/WizardStep';

const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.text.primary,
    height: "100%",
    padding: 0,
    overflow: "hidden",
    overflowY: "scroll",
    justifyContent: "space-between",
  },
  formHeader: {
    color: theme.palette.text.primary,
    position: "relative",
    zIndex: 10,
    padding: 16,
    "& h5": {
      color: theme.palette.text.primary,
      padding: "10px 0px",
      fontSize: 18,
    },
  },

  gifteeSearch: {
    color: theme.palette.text.primary,
    position: "relative",
    height: "calc(100vh - 300px)",
    overflow: "hidden",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 300px)",
    },
  },
  gifteeSearchResults: {
    zIndex: 10,
    overflow: "hidden",
    overflowY: "scroll",
    height: "100%",
  },
  confirm: {
    width: "100%",
    justifySelf: "flex-end",
  },
  confirmButton: {
    padding: "0px 10px",
    marginBottom: 10,
  },
}));

type Props = {
  userSelectCallback: (user: UserSearch_UserSearch) => void;
} & Partial<StepWizardChildProps>;

const AddUserPageOne: FC<Props> = ({ nextStep, userSelectCallback }) => {
  const classes = useStyles();
  const { authState } = useAuthContext();
  const [userSearchString, setUserSearchString] = useState("");
  const getGifteesResult = useQuery<getGiftees, getGifteesVariables>(getGifteesQuery, {
    variables: {
      query: {
        userId: authState.user.auth.id,
      },
    },
    fetchPolicy: "cache-only",
  });
  const addGifteeState = useSelector<RootState, AddGifteeRootState>((state) => {
    return state.addGiftee;
  });

  const client = useApolloClient();
  const [callUserSearch, { data, loading, called }] = useUserSearch(client);
  useEffect(() => {
    const trimmedSearch = userSearchString.trim();
    if (trimmedSearch.length > 2) {
      callUserSearch(userSearchString);
    }
  }, [userSearchString]);

  const userResultClick = (user: UserSearch_UserSearch) => {
    userSelectCallback(user);
  };

  const gifteeUserIds = useMemo(() => {
    const { data } = getGifteesResult;
    const giftees = data?.giftees ?? [];
    let gifteeUserIds: string[] = [];
    if (giftees?.length > 0) {
      gifteeUserIds = giftees
        .map<any>((giftee): giftee is GifteeType => giftee?.sourceUser?._id)
        .filter<string>((id): id is string => id != null);
    }
    return gifteeUserIds;
  }, [getGifteesResult?.data?.giftees]);

  const users = useMemo(() => {
    return (
      data?.UserSearch?.filter<UserSearch_UserSearch>(
        (u): u is UserSearch_UserSearch => u != null && !gifteeUserIds.includes(u._id)
      ) ?? []
    );
  }, [data?.UserSearch, loading, called]);

  const renderUserSearch = () => {
    if (loading || !called) {
      return (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      );
    } else if (users.length > 0) {
      return users.map((user) => (
        <UserSearchResult
          key={user._id}
          user={user}
          selected={addGifteeState.userSelected?._id === user._id}
          userResultClick={userResultClick}
        />
      ));
    } else if (users.length === 0 && userSearchString !== "") {
      return (
        <Grid container justifyContent="center" alignItems="center">
          <Typography>No users found...</Typography>
        </Grid>
      );
    } else {
      return null;
    }
  };

  return (
    <WizardStep>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        wrap="nowrap"
        className={classes.container}
      >
        <div className={classes.formHeader}>
          <Typography variant="h5">Search for another user to add as a Giftee.</Typography>
          <IconInput
            value={userSearchString}
            changeCallback={(event) => setUserSearchString(event?.target.value)}
            confirmation={false}
            icon="lnr-magnifier"
          />
        </div>
        <div className={classes.gifteeSearch}>
          <div className={classes.gifteeSearchResults}>{userSearchString === "" ? "" : renderUserSearch()}</div>
        </div>
      </Grid>
    </WizardStep>
  );
};

export default AddUserPageOne;
