import React, { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StepWizard from 'react-step-wizard';

import { useApolloClient, useQuery } from '@apollo/client';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { useAuthContext } from '../../auth/authContext';
import { getNotificationsQuery } from '../../graphql/queries/notifications/';
import {
  getNotifications, getNotifications_notifications, getNotificationsVariables
} from '../../graphql/queries/notifications/__generated__/getNotifications';
import useIsMounted from '../../hooks/useIsMounted';
import useNotificationSub from '../../hooks/useNotificationSub';
import { closeNotificationsMenu } from '../../redux/general/actions';
import { RootState } from '../../redux/store';
import NotificationSettings from './NotificationSettings';
import NotificationsList from './NotificationsList';
import NotificationsNav from './NotificationsNav';

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    flexShrink: 0,
    minWidth: 455,
    [theme.breakpoints.up("sm")]: {
      width: 240,
      flexShrink: 0,
    },
    "& .MuiDrawer-paperAnchorRight": {
      background: theme.palette.background.background,
      width: 455,
      maxWidth: "100%",
      overflow: "hidden",
    },
  },
}));

type Props = {
  notificationsCountCallback?: (count: number) => void;
  notificationsCount: number;
};

function sortAndDeduplicateNotifications(
  notifications: getNotifications_notifications[]
): getNotifications_notifications[] {
  const notificationsCopy = [...notifications];
  notificationsCopy.forEach((notification, i) => {
    const countArray = notifications.filter((n) => n._id === notification._id);
    //general deduplication
    if (countArray.length > 1) {
      notificationsCopy.splice(i, 1);
    }
  });

  const unarchivedNotifications = notificationsCopy.filter((n) => !n.archived);

  return unarchivedNotifications.sort((notificationA, notificationB) => {
    if (notificationA.createdAt < notificationB.createdAt) {
      return 1;
    }
    return -1;
  });
}

const NotificationsPanel: FC<Props> = () => {
  const { authState } = useAuthContext();
  const isMounted = useIsMounted();
  let queryState: "loading" | "ready" | "error" = "loading";
  const client = useApolloClient();

  const notificationPanelShow = useSelector<RootState, boolean>((state) => {
    return state.general.notificationPanelShow;
  });
  const notificationQueryVariables = {
    variables: {
      query: {
        userId: authState.user.auth.id,
      },
    },
  };
  const { data, error } = useQuery<getNotifications, getNotificationsVariables>(getNotificationsQuery, {
    ...notificationQueryVariables,
    skip: !notificationPanelShow,
  });
  useNotificationSub((newNotifications) => {
    if (isMounted) {
      const data = client.readQuery<getNotifications>({ query: getNotificationsQuery, ...notificationQueryVariables });
      const oldNotifications = (data?.notifications.filter(Boolean) ?? []) as getNotifications_notifications[];
      const sortedNotifications = sortAndDeduplicateNotifications([...newNotifications, ...oldNotifications]);

      client.writeQuery({
        query: getNotificationsQuery,
        ...notificationQueryVariables,
        data: {
          notifications: sortedNotifications,
        },
      });
    }
  });

  const notifications = (data?.notifications.filter(Boolean) ?? []) as getNotifications_notifications[];
  const sortedNotifications = useMemo(() => sortAndDeduplicateNotifications(notifications), [notifications]);
  const newNotificationsCount = notifications.filter((n) => n.new).length;

  if (error) {
    queryState = "error";
  } else if (data) {
    queryState = "ready";
  }

  const dispatch = useDispatch();

  const handleDrawerClose = () => dispatch({ ...closeNotificationsMenu() });

  const classes = useStyles();

  return (
    <Drawer anchor="right" className={classes.drawer} open={notificationPanelShow} onClose={handleDrawerClose}>
      <StepWizard
        nav={<NotificationsNav notificationsCount={newNotificationsCount} closeNotifications={handleDrawerClose} />}
      >
        <NotificationsList data={{ notifications: sortedNotifications }} queryState={queryState} />
        <NotificationSettings />
      </StepWizard>
    </Drawer>
  );
};

export default NotificationsPanel;
