import React, { FC, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PrimaryButton from "../general-ui/buttons/PrimaryButton";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { Giftee } from "../../@types/giftee";
import { updateOneGiftee } from "../../graphql/mutations/giftees";
import { useMutation } from "@apollo/client";
import { closeAddInterestDialog } from "../../redux/active-giftee/actions";
import { useDispatch } from "react-redux";
import { CircularProgress, Grid, Icon } from "@material-ui/core";
import AnimatedCheckmark from "../general-ui/AnimatedCheckmark";
import GlobalMessageDispatch from "../general-ui/global-messages/GlobalMessageDispatch";
import {
  UpdateOneGifteeMutation,
  UpdateOneGifteeMutationVariables,
} from "../../graphql/mutations/giftees/__generated__/UpdateOneGifteeMutation";
import { updateGifteeInCache } from "../../graphql/update-cache/giftees";
import Loader from "../general-ui/Loader";
import { getGifteeQuery } from "../../graphql/queries/giftees";
import {
  UpdateOneGiftProfileMutation,
  UpdateOneGiftProfileMutationVariables,
} from "../../graphql/mutations/gift-profiles/__generated__/UpdateOneGiftProfileMutation";
import { updateOneGiftProfile } from "../../graphql/mutations/gift-profiles";
import { getGiftProfileQuery } from "../../graphql/queries/gift-profiles";

const useStyles = makeStyles((theme) => ({
  form: {
    padding: 10,
    paddingTop: 0,
    display: "flex",
    height: "100%",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  formControlHeader: {
    marginBottom: theme.spacing(1),
    marginTop: "20px",
    color: theme.palette.text.secondary,
  },
  formControl: {
    width: "100%",
  },
  textField: {
    "& input": {
      color: theme.palette.text.primary,
    },
    "& label": {
      color: theme.palette.text.secondary,
      opacity: 0.7,
    },
    "& .MuiInput-underline-196:before": {
      borderBottomColor: "rgba(255, 255, 255, 0.7)",
    },
  },
}));

type Props = {
  giftee: Giftee;
};

const AddInterestForm: FC<Props> = ({ giftee }) => {
  const classes = useStyles();

  const [newInterestName, setNewInterestName] = useState("");
  const [success, setSuccess] = useState(false);

  const [updateGiftee, { loading: gifteeLoading }] = useMutation<
    UpdateOneGifteeMutation,
    UpdateOneGifteeMutationVariables
  >(updateOneGiftee);
  const [updateGiftProfile, { loading: profileLoading }] = useMutation<
    UpdateOneGiftProfileMutation,
    UpdateOneGiftProfileMutationVariables
  >(updateOneGiftProfile);

  const loading = gifteeLoading || profileLoading;

  const dispatch = useDispatch();

  const handleInterestChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewInterestName(event.target.value);
  };

  const insertInterestKeydownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key == "Enter") {
      insertInterest();
    }
  };

  const insertInterest = async () => {
    const currentInterests = giftee.interests ?? [];
    const newInterestNames = newInterestName
      .split(",")
      .map((i) => i.trim())
      .filter((i) => i.trim() !== "");
    const newProfileInterests = [...currentInterests, ...newInterestNames];
    const newProfile = newProfileInterests.map((i) => ({ title: i }));
    const gifteeVariables = {
      query: {
        _id: giftee._id,
      },
      set: {
        interests: newProfileInterests,
      },
    };
    const profileVariables = {
      query: {
        gifteeId: giftee._id,
      },
      set: {
        interests: newProfileInterests,
        profile: newProfile,
      },
    };
    await updateGiftee({
      variables: gifteeVariables,
      //TODO: cache update not working, that's why refetching
      refetchQueries: [
        {
          query: getGifteeQuery,
          variables: {
            query: {
              _id: giftee._id,
            },
          },
        },
      ],
      update: (cache, result) => {
        updateGifteeInCache(cache, gifteeVariables, result);
      },
    });
    await updateGiftProfile({
      variables: profileVariables,
      //TODO: cache update not working, that's why refetching
      refetchQueries: [
        {
          query: getGiftProfileQuery,
          variables: {
            query: {
              gifteeId: giftee._id,
            },
          },
        },
      ],
      update: (cache, result) => {
        updateGifteeInCache(cache, profileVariables, result);
      },
    });
    dispatch({ ...closeAddInterestDialog() });
    setNewInterestName("");
    setSuccess(true);
  };

  let descriptionString =
    "What is " + giftee.name + " interested in? What can inspire you to get " + giftee.name + " a thoughtful gift?";

  return (
    <div className={classes.form}>
      <div>
        <Typography variant="body1" className={classes.formControlHeader}>
          {descriptionString}
          (e.g. organic cookie dough)
        </Typography>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            id="standard-name"
            label="New Interest"
            className={classes.textField}
            value={newInterestName}
            onKeyPress={insertInterestKeydownHandler}
            onChange={handleInterestChange}
            margin="normal"
          />
        </FormControl>
      </div>
      <PrimaryButton disabled={loading} onClick={insertInterest}>
        {!loading ? "Add Interest" : <CircularProgress size={20} />}
      </PrimaryButton>
      {success && (
        <GlobalMessageDispatch>
          <Grid container direction="row" alignItems="center">
            <AnimatedCheckmark
              otherStyles={{ width: 40, height: 40 }}
              checked={true}
              icon={
                <Icon>
                  <span className="lnr lnr-rocket"></span>
                </Icon>
              }
            />

            <Typography variant="body1" color="inherit">
              &nbsp;Interest successfully added!
            </Typography>
          </Grid>
        </GlobalMessageDispatch>
      )}
    </div>
  );
};

export default AddInterestForm;
