import React, { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { OccasionType } from '../../../__generated__/globalTypes';
import { useQueryUsersWhoAddedMe } from '../../../hooks/useQueryUsersWhoAddedMe';
import {
  setOccasionDate, setOccasionName, setOccasionType
} from '../../../redux/new-occasion/actions';
import { NewOccasionRootState } from '../../../redux/new-occasion/reducers';
import { RootState } from '../../../redux/store';
import getNextOccasionDate from '../../../utils/occasionDates';
import PrimaryButton from '../../general-ui/buttons/PrimaryButton';
import getOccasionNameFromType from '../../occasions/occasionName';
import WizardStep from '../general-wizard-ui/WizardStep';
import { NewOccasionWizardSteps } from '../NewOccasionWizard';

const useStyles = makeStyles((theme: Theme) => ({
  formControlHeader: {
    marginBottom: theme.spacing(1),
    marginTop: "20px",
    width: "100%",
  },
  formControl: {
    width: "100%",
  },
  selectOccasionContent: {
    minHeight: "400px",
    height: "100%",
  },
}));

type Props = { isUser: boolean } & Partial<StepWizardChildProps>;

const SelectOccasionTypes: FC<Props> = ({ goToStep, isUser }) => {
  const classes = useStyles();
  const [usersToNotify] = useQueryUsersWhoAddedMe();
  const [showCustomName, setShowCustomName] = useState(false);
  const [customName, setCustomName] = useState("");
  const dispatch = useDispatch();
  const { occasionName, occasionType } = useSelector<RootState, NewOccasionRootState>((state) => {
    return state.newOccasion;
  });

  //handle occasion type change
  const occasionTypeHandler = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
      options: { text: string }[];
      selectedIndex: number;
    }>
  ) => {
    dispatch({ ...setOccasionType(e.target.value as OccasionType) });
    dispatch({ ...setOccasionName(e.target.options[e.target.selectedIndex].text as string) });
    if (e.target.value === OccasionType.CUSTOM) {
      setShowCustomName(true);
    } else {
      setShowCustomName(false);
    }
  };

  const handleGoToStep = (step: number) => {
    if (goToStep) {
      goToStep(step);
    }
  };

  //confirm occasion type
  const confirmOccasionTypeHandler = () => {
    if (occasionType != "") {
      const occasionNameValue = customName ? customName : occasionName;
      dispatch({ ...setOccasionType(occasionType) });
      dispatch({ ...setOccasionName(occasionNameValue) });
      if (
        occasionType === OccasionType.BIRTHDAY ||
        occasionType === OccasionType.WEDDING ||
        occasionType === OccasionType.ANNIVERSARY ||
        occasionType === OccasionType.CUSTOM
      ) {
        handleGoToStep(NewOccasionWizardSteps.SelectOccasionDate);
      } else {
        const nextOccasionDate = getNextOccasionDate(occasionType);
        const dayMonthDate = {
          day: nextOccasionDate?.getUTCDate() ?? 0,
          month: nextOccasionDate?.getUTCMonth() ?? 0,
        };
        dispatch({ ...setOccasionDate(dayMonthDate) });
        handleGoToStep(
          isUser && usersToNotify.length > 0
            ? NewOccasionWizardSteps.SelectUsersToNotify
            : NewOccasionWizardSteps.ConfirmOccasion
        );
      }
    }
  };

  const handleCustomNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomName(e.target.value);
  };

  const occasionOptions = useMemo(() => {
    return Object.values(OccasionType).sort();
  }, [Object.values(OccasionType).join(",")]);

  return (
    <WizardStep>
      <div className={classes.selectOccasionContent}>
        <Typography variant="body1" className={classes.formControlHeader}>
          Select the Type of Occasion. (i.e. Christmas, Birthday, Kwanzaa)
        </Typography>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="outlined-age-native-simple">Occasion Type</InputLabel>
          <Select
            native
            value={occasionType}
            onChange={occasionTypeHandler as any}
            input={<OutlinedInput name="Occasion Type" id="outlined-age-native-simple" labelWidth={120} />}
          >
            <option value=""></option>
            {occasionOptions.map((type) => {
              const occasion = {
                type,
                name: getOccasionNameFromType(type),
              };
              return <option value={occasion.type}>{occasion.name}</option>;
            })}
          </Select>
        </FormControl>
        {showCustomName ? (
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Typography variant="body1" className={classes.formControlHeader}>
              What is the name of this occasion?
            </Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                id="standard-name"
                label="Occasion Name"
                value={customName}
                onChange={handleCustomNameChange}
                margin="normal"
              />
            </FormControl>
          </Grid>
        ) : (
          ""
        )}
      </div>
      <PrimaryButton onClick={confirmOccasionTypeHandler}>Confirm Occasion Type</PrimaryButton>
    </WizardStep>
  );
};

export default SelectOccasionTypes;
