import gql from "graphql-tag";

export const insertOneGiftProfile = gql`
  mutation InsertOneGiftProfileMutation($data: GiftProfileInsertInput!) {
    insertOneGiftProfile(data: $data) {
      _id
    }
  }
`;

export const updateOneGiftProfile = gql`
  mutation UpdateOneGiftProfileMutation($query: GiftProfileQueryInput!, $set: GiftProfileUpdateInput!) {
    updateOneGiftProfile(query: $query, set: $set) {
      _id
      purchases {
        giftIdeaId
        transactionId
        date
        targetYear
      }
    }
  }
`;

export const deleteOneGiftProfile = gql`
  mutation DeleteOneGiftProfileMutation($query: GiftProfileQueryInput!) {
    deleteOneGiftProfile(query: $query) {
      _id
    }
  }
`;
