import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, { FC } from "react";

const useStyles = makeStyles((theme) => ({
  browserShareButton: {
    border: "1px solid " + theme.palette.primary.main,
    background: "none",
    height: 60,
    width: 60,
    color: theme.palette.primary.main,
    fontSize: 35,
  },
}));

type Props = {
  url: string;
  text: string;
  title: string;
  onShare?: () => void;
};

const ShareButton: FC<Props> = ({ url, text, title, onShare }) => {
  const classes = useStyles();
  if (navigator.share == null) {
    return null;
  }

  const share = async () => {
    try {
      await navigator.share({ url, text, title });
    }
    finally {
      onShare?.();
    }
  };

  return (
    <Button className={classes.browserShareButton} onClick={share}>
      <span className="lnr lnr-share2"></span>
    </Button>
  );
};

export default ShareButton;
