// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function make(logoOpt, nameOpt, param) {
  var logo = logoOpt !== undefined ? logoOpt : "";
  var name = nameOpt !== undefined ? nameOpt : "";
  return {
          logo: logo,
          name: name
        };
}

function fromGiftProviderProductQuery(giftProvider) {
  return {
          logo: Belt_Option.getWithDefault(giftProvider.logo, ""),
          name: Belt_Option.getWithDefault(giftProvider.name, "")
        };
}

export {
  make ,
  fromGiftProviderProductQuery ,
  
}
/* No side effect */
