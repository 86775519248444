/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum GiftProfileGender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  UNISEX = "UNISEX",
}

export enum GiftProviderType {
  BESTBUY = "BESTBUY",
  CJ = "CJ",
  ETSY = "ETSY",
  IMPACT = "IMPACT",
  OCCASIONAL_LY = "OCCASIONAL_LY",
  SHAREASALE = "SHAREASALE",
  ZAZZLE = "ZAZZLE",
}

export enum GifteeRelationship {
  AUNT = "AUNT",
  BOYFRIEND = "BOYFRIEND",
  BROTHER = "BROTHER",
  BROTHER_IN_LAW = "BROTHER_IN_LAW",
  COUSIN = "COUSIN",
  FATHER = "FATHER",
  FATHER_IN_LAW = "FATHER_IN_LAW",
  FRIEND = "FRIEND",
  GENERAL_FAMILY = "GENERAL_FAMILY",
  GIRLFRIEND = "GIRLFRIEND",
  GRANDMA = "GRANDMA",
  GRANDPA = "GRANDPA",
  HUSBAND = "HUSBAND",
  MOTHER = "MOTHER",
  MOTHER_IN_LAW = "MOTHER_IN_LAW",
  OTHER = "OTHER",
  SISTER = "SISTER",
  SISTER_IN_LAW = "SISTER_IN_LAW",
  UNCLE = "UNCLE",
  WIFE = "WIFE",
}

export enum GifteeStatus {
  ADDED = "ADDED",
  APPROVED = "APPROVED",
  PENDING = "PENDING",
}

export enum NotificationType {
  ADDGIFTEEREQUEST = "ADDGIFTEEREQUEST",
  GIFTEEOCCASIONADDED = "GIFTEEOCCASIONADDED",
  GIFTEEREQUESTACCEPTED = "GIFTEEREQUESTACCEPTED",
  GIFTEEREQUESTDENIED = "GIFTEEREQUESTDENIED",
  GIFTPROFILEREQUEST = "GIFTPROFILEREQUEST",
  GIFTPROFILESETUPPROMPT = "GIFTPROFILESETUPPROMPT",
  OCCASION = "OCCASION",
  REMINDER = "REMINDER",
  USERINVITEACCEPTED = "USERINVITEACCEPTED",
}

export enum OccasionType {
  ANNIVERSARY = "ANNIVERSARY",
  BIRTHDAY = "BIRTHDAY",
  CHRISTMAS = "CHRISTMAS",
  CUSTOM = "CUSTOM",
  EASTER = "EASTER",
  FATHERSDAY = "FATHERSDAY",
  HANUKKAH = "HANUKKAH",
  KWANZAA = "KWANZAA",
  MOTHERSDAY = "MOTHERSDAY",
  VALENTINES = "VALENTINES",
  WEDDING = "WEDDING",
}

export enum ProductGender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  UNISEX = "UNISEX",
}

export enum ProductStatus {
  BROKENIMAGE = "BROKENIMAGE",
  BROKENLINK = "BROKENLINK",
  DUPLICATE = "DUPLICATE",
  DUPLICATEMERGED = "DUPLICATEMERGED",
  LIVE = "LIVE",
  RETIRED = "RETIRED",
}

export interface CategoryQueryInput {
  _id_lt?: any | null;
  icon_in?: (string | null)[] | null;
  _id_nin?: (any | null)[] | null;
  relatedValues_in?: (string | null)[] | null;
  _id_exists?: boolean | null;
  icon_exists?: boolean | null;
  OR?: CategoryQueryInput[] | null;
  value_in?: (string | null)[] | null;
  _id_gt?: any | null;
  _id_lte?: any | null;
  _id_ne?: any | null;
  value_nin?: (string | null)[] | null;
  _id_in?: (any | null)[] | null;
  icon_nin?: (string | null)[] | null;
  value_lte?: string | null;
  icon_ne?: string | null;
  AND?: CategoryQueryInput[] | null;
  value_gt?: string | null;
  _id?: any | null;
  icon?: string | null;
  icon_gt?: string | null;
  value_exists?: boolean | null;
  _id_gte?: any | null;
  icon_lt?: string | null;
  value_ne?: string | null;
  value_gte?: string | null;
  icon_gte?: string | null;
  relatedValues_nin?: (string | null)[] | null;
  relatedValues_exists?: boolean | null;
  value?: string | null;
  value_lt?: string | null;
  icon_lte?: string | null;
  relatedValues?: (string | null)[] | null;
}

export interface GiftProfileInsertInput {
  recommendations?: (GiftProfileRecommendationsInsertInput | null)[] | null;
  updatedAt?: any | null;
  priceMin?: number | null;
  profile?: (GiftProfileProfileInsertInput | null)[] | null;
  _id?: any | null;
  priceMax?: number | null;
  gifteeId?: any | null;
  gender?: GiftProfileGender | null;
  interests?: (string | null)[] | null;
  purchases?: (GiftProfilePurchasesInsertInput | null)[] | null;
  recommendationsByCategory?: (GiftProfileRecommendationsByCategoryInsertInput | null)[] | null;
  userId?: any | null;
  createdAt?: any | null;
}

export interface GiftProfileProfileInsertInput {
  productId?: string | null;
  title?: string | null;
  weight?: any | null;
  image?: string | null;
  interest?: string | null;
}

export interface GiftProfileProfileQueryInput {
  productId_exists?: boolean | null;
  interest_lt?: string | null;
  image_gte?: string | null;
  image_gt?: string | null;
  title_lt?: string | null;
  weight_gte?: any | null;
  interest_gt?: string | null;
  productId_ne?: string | null;
  image?: string | null;
  productId_lte?: string | null;
  title_exists?: boolean | null;
  weight_lt?: any | null;
  title_gt?: string | null;
  image_lte?: string | null;
  interest_gte?: string | null;
  weight_ne?: any | null;
  title_lte?: string | null;
  productId_gte?: string | null;
  title_in?: (string | null)[] | null;
  image_ne?: string | null;
  image_lt?: string | null;
  weight_nin?: (any | null)[] | null;
  title?: string | null;
  interest_nin?: (string | null)[] | null;
  image_in?: (string | null)[] | null;
  title_nin?: (string | null)[] | null;
  weight_lte?: any | null;
  weight_in?: (any | null)[] | null;
  interest_ne?: string | null;
  weight_exists?: boolean | null;
  image_nin?: (string | null)[] | null;
  interest_lte?: string | null;
  interest_in?: (string | null)[] | null;
  productId_in?: (string | null)[] | null;
  productId_nin?: (string | null)[] | null;
  title_gte?: string | null;
  image_exists?: boolean | null;
  interest?: string | null;
  productId_gt?: string | null;
  AND?: GiftProfileProfileQueryInput[] | null;
  OR?: GiftProfileProfileQueryInput[] | null;
  productId_lt?: string | null;
  weight?: any | null;
  weight_gt?: any | null;
  title_ne?: string | null;
  interest_exists?: boolean | null;
  productId?: string | null;
}

export interface GiftProfileProfileUpdateInput {
  title?: string | null;
  weight_unset?: boolean | null;
  productId_unset?: boolean | null;
  weight?: any | null;
  interest_unset?: boolean | null;
  image_unset?: boolean | null;
  interest?: string | null;
  title_unset?: boolean | null;
  image?: string | null;
  productId?: string | null;
}

export interface GiftProfilePurchasesInsertInput {
  date?: any | null;
  giftIdeaId?: any | null;
  targetYear?: number | null;
  transactionId?: any | null;
}

export interface GiftProfilePurchasesQueryInput {
  transactionId_gt?: any | null;
  targetYear_in?: (number | null)[] | null;
  transactionId_exists?: boolean | null;
  transactionId_ne?: any | null;
  transactionId_in?: (any | null)[] | null;
  giftIdeaId_lt?: any | null;
  giftIdeaId_ne?: any | null;
  giftIdeaId_gte?: any | null;
  transactionId_nin?: (any | null)[] | null;
  date_gt?: any | null;
  giftIdeaId_gt?: any | null;
  targetYear_lt?: number | null;
  targetYear_gte?: number | null;
  giftIdeaId?: any | null;
  date?: any | null;
  date_lt?: any | null;
  transactionId_lt?: any | null;
  giftIdeaId_in?: (any | null)[] | null;
  targetYear_nin?: (number | null)[] | null;
  date_gte?: any | null;
  transactionId_lte?: any | null;
  date_nin?: (any | null)[] | null;
  giftIdeaId_lte?: any | null;
  OR?: GiftProfilePurchasesQueryInput[] | null;
  transactionId?: any | null;
  targetYear_gt?: number | null;
  giftIdeaId_exists?: boolean | null;
  AND?: GiftProfilePurchasesQueryInput[] | null;
  date_lte?: any | null;
  giftIdeaId_nin?: (any | null)[] | null;
  targetYear_lte?: number | null;
  targetYear_ne?: number | null;
  date_ne?: any | null;
  targetYear_exists?: boolean | null;
  transactionId_gte?: any | null;
  targetYear?: number | null;
  date_in?: (any | null)[] | null;
  date_exists?: boolean | null;
}

export interface GiftProfilePurchasesUpdateInput {
  targetYear_inc?: number | null;
  giftIdeaId_unset?: boolean | null;
  targetYear?: number | null;
  date?: any | null;
  date_unset?: boolean | null;
  targetYear_unset?: boolean | null;
  transactionId?: any | null;
  giftIdeaId?: any | null;
  transactionId_unset?: boolean | null;
}

export interface GiftProfileQueryInput {
  createdAt?: any | null;
  gifteeId_gt?: any | null;
  gifteeId?: any | null;
  interests_nin?: (string | null)[] | null;
  createdAt_gte?: any | null;
  _id_ne?: any | null;
  gender_nin?: (GiftProfileGender | null)[] | null;
  updatedAt_gte?: any | null;
  gifteeId_lte?: any | null;
  interests_in?: (string | null)[] | null;
  recommendationsByCategory?: (GiftProfileRecommendationsByCategoryQueryInput | null)[] | null;
  gender?: GiftProfileGender | null;
  profile_exists?: boolean | null;
  priceMax_ne?: number | null;
  userId_nin?: (any | null)[] | null;
  gifteeId_exists?: boolean | null;
  priceMax_lt?: number | null;
  userId_ne?: any | null;
  createdAt_in?: (any | null)[] | null;
  createdAt_gt?: any | null;
  updatedAt_in?: (any | null)[] | null;
  gifteeId_ne?: any | null;
  recommendations?: (GiftProfileRecommendationsQueryInput | null)[] | null;
  updatedAt_lt?: any | null;
  _id?: any | null;
  priceMax_gt?: number | null;
  priceMax?: number | null;
  profile_in?: (GiftProfileProfileQueryInput | null)[] | null;
  gender_lt?: GiftProfileGender | null;
  gifteeId_in?: (any | null)[] | null;
  _id_exists?: boolean | null;
  userId_lte?: any | null;
  gifteeId_lt?: any | null;
  updatedAt_ne?: any | null;
  _id_nin?: (any | null)[] | null;
  createdAt_ne?: any | null;
  priceMin_lt?: number | null;
  recommendationsByCategory_in?: (GiftProfileRecommendationsByCategoryQueryInput | null)[] | null;
  gifteeId_nin?: (any | null)[] | null;
  profile_nin?: (GiftProfileProfileQueryInput | null)[] | null;
  purchases_nin?: (GiftProfilePurchasesQueryInput | null)[] | null;
  priceMin?: number | null;
  gender_gte?: GiftProfileGender | null;
  priceMin_lte?: number | null;
  updatedAt_lte?: any | null;
  OR?: GiftProfileQueryInput[] | null;
  gender_exists?: boolean | null;
  gender_gt?: GiftProfileGender | null;
  priceMin_gt?: number | null;
  priceMax_nin?: (number | null)[] | null;
  updatedAt?: any | null;
  userId_gt?: any | null;
  _id_gt?: any | null;
  gender_in?: (GiftProfileGender | null)[] | null;
  priceMin_in?: (number | null)[] | null;
  _id_lte?: any | null;
  purchases_exists?: boolean | null;
  recommendationsByCategory_exists?: boolean | null;
  updatedAt_exists?: boolean | null;
  purchases_in?: (GiftProfilePurchasesQueryInput | null)[] | null;
  purchases?: (GiftProfilePurchasesQueryInput | null)[] | null;
  _id_gte?: any | null;
  priceMax_exists?: boolean | null;
  priceMin_exists?: boolean | null;
  interests?: (string | null)[] | null;
  updatedAt_gt?: any | null;
  priceMax_in?: (number | null)[] | null;
  userId_gte?: any | null;
  profile?: (GiftProfileProfileQueryInput | null)[] | null;
  gender_ne?: GiftProfileGender | null;
  createdAt_exists?: boolean | null;
  _id_in?: (any | null)[] | null;
  priceMax_lte?: number | null;
  priceMax_gte?: number | null;
  updatedAt_nin?: (any | null)[] | null;
  AND?: GiftProfileQueryInput[] | null;
  gifteeId_gte?: any | null;
  createdAt_lt?: any | null;
  userId_in?: (any | null)[] | null;
  createdAt_nin?: (any | null)[] | null;
  recommendations_nin?: (GiftProfileRecommendationsQueryInput | null)[] | null;
  gender_lte?: GiftProfileGender | null;
  recommendations_exists?: boolean | null;
  recommendationsByCategory_nin?: (GiftProfileRecommendationsByCategoryQueryInput | null)[] | null;
  createdAt_lte?: any | null;
  _id_lt?: any | null;
  interests_exists?: boolean | null;
  priceMin_gte?: number | null;
  userId_lt?: any | null;
  priceMin_ne?: number | null;
  userId_exists?: boolean | null;
  priceMin_nin?: (number | null)[] | null;
  userId?: any | null;
  recommendations_in?: (GiftProfileRecommendationsQueryInput | null)[] | null;
}

export interface GiftProfileRecommendationsAssociatedProductsInsertInput {
  url?: string | null;
  image?: string | null;
  title?: string | null;
}

export interface GiftProfileRecommendationsAssociatedProductsQueryInput {
  image_lt?: string | null;
  image_gt?: string | null;
  title_exists?: boolean | null;
  url_gt?: string | null;
  image_gte?: string | null;
  url_in?: (string | null)[] | null;
  AND?: GiftProfileRecommendationsAssociatedProductsQueryInput[] | null;
  image_lte?: string | null;
  url_lt?: string | null;
  image_ne?: string | null;
  title_ne?: string | null;
  image_exists?: boolean | null;
  url_ne?: string | null;
  title_in?: (string | null)[] | null;
  image?: string | null;
  url?: string | null;
  image_nin?: (string | null)[] | null;
  title_lte?: string | null;
  title_gte?: string | null;
  url_lte?: string | null;
  title?: string | null;
  url_gte?: string | null;
  image_in?: (string | null)[] | null;
  title_lt?: string | null;
  title_gt?: string | null;
  title_nin?: (string | null)[] | null;
  url_nin?: (string | null)[] | null;
  OR?: GiftProfileRecommendationsAssociatedProductsQueryInput[] | null;
  url_exists?: boolean | null;
}

export interface GiftProfileRecommendationsAssociatedProductsUpdateInput {
  title_unset?: boolean | null;
  url?: string | null;
  url_unset?: boolean | null;
  image?: string | null;
  image_unset?: boolean | null;
  title?: string | null;
}

export interface GiftProfileRecommendationsByCategoryInsertInput {
  category?: string | null;
  likedProducts?: (GiftProfileRecommendationsByCategoryLikedProductsInsertInput | null)[] | null;
  recommendations?: (GiftProfileRecommendationsByCategoryRecommendationsInsertInput | null)[] | null;
}

export interface GiftProfileRecommendationsByCategoryLikedProductsInsertInput {
  image?: string | null;
  productId?: string | null;
  title?: string | null;
  weight?: any | null;
}

export interface GiftProfileRecommendationsByCategoryLikedProductsQueryInput {
  weight_lte?: any | null;
  title_nin?: (string | null)[] | null;
  weight?: any | null;
  productId_nin?: (string | null)[] | null;
  image_lt?: string | null;
  title_ne?: string | null;
  image_nin?: (string | null)[] | null;
  weight_gt?: any | null;
  title_in?: (string | null)[] | null;
  image_in?: (string | null)[] | null;
  productId_lte?: string | null;
  image_ne?: string | null;
  title_lt?: string | null;
  AND?: GiftProfileRecommendationsByCategoryLikedProductsQueryInput[] | null;
  image_gt?: string | null;
  title?: string | null;
  weight_nin?: (any | null)[] | null;
  title_lte?: string | null;
  weight_in?: (any | null)[] | null;
  OR?: GiftProfileRecommendationsByCategoryLikedProductsQueryInput[] | null;
  image?: string | null;
  productId_exists?: boolean | null;
  weight_ne?: any | null;
  productId_gt?: string | null;
  image_exists?: boolean | null;
  productId_in?: (string | null)[] | null;
  title_gt?: string | null;
  productId_lt?: string | null;
  title_gte?: string | null;
  productId?: string | null;
  weight_gte?: any | null;
  image_lte?: string | null;
  weight_exists?: boolean | null;
  productId_ne?: string | null;
  title_exists?: boolean | null;
  productId_gte?: string | null;
  weight_lt?: any | null;
  image_gte?: string | null;
}

export interface GiftProfileRecommendationsByCategoryLikedProductsUpdateInput {
  productId_unset?: boolean | null;
  title?: string | null;
  title_unset?: boolean | null;
  weight?: any | null;
  weight_unset?: boolean | null;
  image?: string | null;
  image_unset?: boolean | null;
  productId?: string | null;
}

export interface GiftProfileRecommendationsByCategoryQueryInput {
  recommendations?: (GiftProfileRecommendationsByCategoryRecommendationsQueryInput | null)[] | null;
  category_gte?: string | null;
  likedProducts_exists?: boolean | null;
  AND?: GiftProfileRecommendationsByCategoryQueryInput[] | null;
  recommendations_in?: (GiftProfileRecommendationsByCategoryRecommendationsQueryInput | null)[] | null;
  category_lt?: string | null;
  category_lte?: string | null;
  category_nin?: (string | null)[] | null;
  category_in?: (string | null)[] | null;
  recommendations_nin?: (GiftProfileRecommendationsByCategoryRecommendationsQueryInput | null)[] | null;
  category_ne?: string | null;
  category_gt?: string | null;
  category_exists?: boolean | null;
  OR?: GiftProfileRecommendationsByCategoryQueryInput[] | null;
  likedProducts_in?: (GiftProfileRecommendationsByCategoryLikedProductsQueryInput | null)[] | null;
  recommendations_exists?: boolean | null;
  likedProducts?: (GiftProfileRecommendationsByCategoryLikedProductsQueryInput | null)[] | null;
  likedProducts_nin?: (GiftProfileRecommendationsByCategoryLikedProductsQueryInput | null)[] | null;
  category?: string | null;
}

export interface GiftProfileRecommendationsByCategoryRecommendationsGiftProviderInsertInput {
  logo?: string | null;
  name?: string | null;
}

export interface GiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput {
  logo_lt?: string | null;
  logo?: string | null;
  AND?: GiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput[] | null;
  name?: string | null;
  name_gte?: string | null;
  name_lt?: string | null;
  logo_gte?: string | null;
  name_gt?: string | null;
  logo_gt?: string | null;
  OR?: GiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput[] | null;
  logo_in?: (string | null)[] | null;
  logo_nin?: (string | null)[] | null;
  name_exists?: boolean | null;
  logo_ne?: string | null;
  logo_exists?: boolean | null;
  logo_lte?: string | null;
  name_lte?: string | null;
  name_ne?: string | null;
  name_nin?: (string | null)[] | null;
  name_in?: (string | null)[] | null;
}

export interface GiftProfileRecommendationsByCategoryRecommendationsGiftProviderUpdateInput {
  logo_unset?: boolean | null;
  name?: string | null;
  name_unset?: boolean | null;
  logo?: string | null;
}

export interface GiftProfileRecommendationsByCategoryRecommendationsInsertInput {
  similarity?: number | null;
  title?: string | null;
  _id?: any | null;
  giftProvider?: GiftProfileRecommendationsByCategoryRecommendationsGiftProviderInsertInput | null;
  image?: string | null;
  price?: number | null;
}

export interface GiftProfileRecommendationsByCategoryRecommendationsQueryInput {
  price_lt?: number | null;
  price_exists?: boolean | null;
  title_gte?: string | null;
  _id_lt?: any | null;
  price_gte?: number | null;
  title?: string | null;
  price?: number | null;
  _id_gt?: any | null;
  image_lte?: string | null;
  image_nin?: (string | null)[] | null;
  AND?: GiftProfileRecommendationsByCategoryRecommendationsQueryInput[] | null;
  similarity_gt?: number | null;
  _id?: any | null;
  _id_exists?: boolean | null;
  price_in?: (number | null)[] | null;
  title_gt?: string | null;
  similarity_nin?: (number | null)[] | null;
  OR?: GiftProfileRecommendationsByCategoryRecommendationsQueryInput[] | null;
  price_lte?: number | null;
  price_nin?: (number | null)[] | null;
  title_lte?: string | null;
  title_exists?: boolean | null;
  _id_gte?: any | null;
  _id_nin?: (any | null)[] | null;
  giftProvider_exists?: boolean | null;
  image_in?: (string | null)[] | null;
  similarity_lte?: number | null;
  image_lt?: string | null;
  price_gt?: number | null;
  image_gte?: string | null;
  similarity_exists?: boolean | null;
  title_in?: (string | null)[] | null;
  price_ne?: number | null;
  title_lt?: string | null;
  similarity_gte?: number | null;
  _id_lte?: any | null;
  _id_ne?: any | null;
  title_ne?: string | null;
  similarity_lt?: number | null;
  title_nin?: (string | null)[] | null;
  image_exists?: boolean | null;
  similarity?: number | null;
  image?: string | null;
  _id_in?: (any | null)[] | null;
  giftProvider?: GiftProfileRecommendationsByCategoryRecommendationsGiftProviderQueryInput | null;
  similarity_ne?: number | null;
  similarity_in?: (number | null)[] | null;
  image_gt?: string | null;
  image_ne?: string | null;
}

export interface GiftProfileRecommendationsByCategoryRecommendationsUpdateInput {
  image_unset?: boolean | null;
  giftProvider_unset?: boolean | null;
  title_unset?: boolean | null;
  title?: string | null;
  similarity?: number | null;
  _id_unset?: boolean | null;
  image?: string | null;
  giftProvider?: GiftProfileRecommendationsByCategoryRecommendationsGiftProviderUpdateInput | null;
  price?: number | null;
  price_inc?: number | null;
  price_unset?: boolean | null;
  similarity_unset?: boolean | null;
  _id?: any | null;
  similarity_inc?: number | null;
}

export interface GiftProfileRecommendationsByCategoryUpdateInput {
  likedProducts?: (GiftProfileRecommendationsByCategoryLikedProductsUpdateInput | null)[] | null;
  likedProducts_unset?: boolean | null;
  recommendations?: (GiftProfileRecommendationsByCategoryRecommendationsUpdateInput | null)[] | null;
  recommendations_unset?: boolean | null;
  category?: string | null;
  category_unset?: boolean | null;
}

export interface GiftProfileRecommendationsGiftProviderInsertInput {
  name?: string | null;
  logo?: string | null;
}

export interface GiftProfileRecommendationsGiftProviderQueryInput {
  name_gt?: string | null;
  logo_lte?: string | null;
  logo?: string | null;
  logo_lt?: string | null;
  name_gte?: string | null;
  name_lte?: string | null;
  logo_exists?: boolean | null;
  logo_gte?: string | null;
  name_ne?: string | null;
  name?: string | null;
  name_in?: (string | null)[] | null;
  AND?: GiftProfileRecommendationsGiftProviderQueryInput[] | null;
  logo_gt?: string | null;
  OR?: GiftProfileRecommendationsGiftProviderQueryInput[] | null;
  name_exists?: boolean | null;
  logo_nin?: (string | null)[] | null;
  logo_in?: (string | null)[] | null;
  name_nin?: (string | null)[] | null;
  name_lt?: string | null;
  logo_ne?: string | null;
}

export interface GiftProfileRecommendationsGiftProviderUpdateInput {
  name?: string | null;
  name_unset?: boolean | null;
  logo?: string | null;
  logo_unset?: boolean | null;
}

export interface GiftProfileRecommendationsInsertInput {
  giftProvider?: GiftProfileRecommendationsGiftProviderInsertInput | null;
  giftSource?: string | null;
  interest?: string | null;
  associatedProducts?: (GiftProfileRecommendationsAssociatedProductsInsertInput | null)[] | null;
  description?: string | null;
  image?: string | null;
  title?: string | null;
  _id?: any | null;
  url?: string | null;
  price?: number | null;
  similarity?: number | null;
  avgReview?: number | null;
}

export interface GiftProfileRecommendationsQueryInput {
  avgReview_lt?: number | null;
  _id?: any | null;
  avgReview_nin?: (number | null)[] | null;
  title_lt?: string | null;
  url_gt?: string | null;
  similarity_lte?: number | null;
  avgReview_ne?: number | null;
  avgReview_exists?: boolean | null;
  title_ne?: string | null;
  title?: string | null;
  image_gt?: string | null;
  image_exists?: boolean | null;
  title_lte?: string | null;
  interest_exists?: boolean | null;
  price_ne?: number | null;
  price_lte?: number | null;
  interest_gte?: string | null;
  url_lte?: string | null;
  giftSource_nin?: (string | null)[] | null;
  image_nin?: (string | null)[] | null;
  description_gt?: string | null;
  url_exists?: boolean | null;
  title_gt?: string | null;
  image?: string | null;
  associatedProducts?: (GiftProfileRecommendationsAssociatedProductsQueryInput | null)[] | null;
  interest_ne?: string | null;
  description_ne?: string | null;
  avgReview_lte?: number | null;
  price?: number | null;
  description_lte?: string | null;
  interest_gt?: string | null;
  OR?: GiftProfileRecommendationsQueryInput[] | null;
  description?: string | null;
  price_gte?: number | null;
  associatedProducts_exists?: boolean | null;
  similarity_nin?: (number | null)[] | null;
  AND?: GiftProfileRecommendationsQueryInput[] | null;
  interest_in?: (string | null)[] | null;
  giftSource_ne?: string | null;
  similarity_gt?: number | null;
  url_nin?: (string | null)[] | null;
  interest_lt?: string | null;
  interest?: string | null;
  giftSource_in?: (string | null)[] | null;
  giftSource_lt?: string | null;
  giftSource_lte?: string | null;
  url?: string | null;
  avgReview_gte?: number | null;
  similarity_gte?: number | null;
  similarity_lt?: number | null;
  _id_nin?: (any | null)[] | null;
  title_exists?: boolean | null;
  url_in?: (string | null)[] | null;
  avgReview_in?: (number | null)[] | null;
  avgReview_gt?: number | null;
  associatedProducts_nin?: (GiftProfileRecommendationsAssociatedProductsQueryInput | null)[] | null;
  image_ne?: string | null;
  description_in?: (string | null)[] | null;
  title_nin?: (string | null)[] | null;
  interest_lte?: string | null;
  _id_exists?: boolean | null;
  description_lt?: string | null;
  description_gte?: string | null;
  price_in?: (number | null)[] | null;
  url_gte?: string | null;
  title_in?: (string | null)[] | null;
  price_gt?: number | null;
  similarity?: number | null;
  interest_nin?: (string | null)[] | null;
  giftProvider_exists?: boolean | null;
  _id_gte?: any | null;
  associatedProducts_in?: (GiftProfileRecommendationsAssociatedProductsQueryInput | null)[] | null;
  image_lt?: string | null;
  image_in?: (string | null)[] | null;
  similarity_exists?: boolean | null;
  giftSource_gte?: string | null;
  price_exists?: boolean | null;
  _id_in?: (any | null)[] | null;
  image_lte?: string | null;
  _id_lte?: any | null;
  price_nin?: (number | null)[] | null;
  _id_gt?: any | null;
  title_gte?: string | null;
  giftSource?: string | null;
  similarity_ne?: number | null;
  description_nin?: (string | null)[] | null;
  price_lt?: number | null;
  similarity_in?: (number | null)[] | null;
  _id_lt?: any | null;
  url_lt?: string | null;
  giftSource_exists?: boolean | null;
  url_ne?: string | null;
  description_exists?: boolean | null;
  _id_ne?: any | null;
  giftProvider?: GiftProfileRecommendationsGiftProviderQueryInput | null;
  image_gte?: string | null;
  avgReview?: number | null;
  giftSource_gt?: string | null;
}

export interface GiftProfileRecommendationsUpdateInput {
  interest_unset?: boolean | null;
  price_inc?: number | null;
  _id?: any | null;
  title_unset?: boolean | null;
  description_unset?: boolean | null;
  url?: string | null;
  description?: string | null;
  giftProvider?: GiftProfileRecommendationsGiftProviderUpdateInput | null;
  associatedProducts?: (GiftProfileRecommendationsAssociatedProductsUpdateInput | null)[] | null;
  avgReview?: number | null;
  image_unset?: boolean | null;
  price_unset?: boolean | null;
  _id_unset?: boolean | null;
  associatedProducts_unset?: boolean | null;
  giftSource_unset?: boolean | null;
  avgReview_unset?: boolean | null;
  giftProvider_unset?: boolean | null;
  avgReview_inc?: number | null;
  interest?: string | null;
  giftSource?: string | null;
  similarity_inc?: number | null;
  title?: string | null;
  price?: number | null;
  image?: string | null;
  similarity_unset?: boolean | null;
  similarity?: number | null;
  url_unset?: boolean | null;
}

export interface GiftProfileUpdateInput {
  purchases?: (GiftProfilePurchasesUpdateInput | null)[] | null;
  priceMin?: number | null;
  recommendationsByCategory_unset?: boolean | null;
  updatedAt?: any | null;
  priceMax_inc?: number | null;
  profile?: (GiftProfileProfileUpdateInput | null)[] | null;
  priceMax_unset?: boolean | null;
  createdAt_unset?: boolean | null;
  updatedAt_unset?: boolean | null;
  priceMin_inc?: number | null;
  _id?: any | null;
  _id_unset?: boolean | null;
  createdAt?: any | null;
  priceMax?: number | null;
  priceMin_unset?: boolean | null;
  gender_unset?: boolean | null;
  recommendationsByCategory?: (GiftProfileRecommendationsByCategoryUpdateInput | null)[] | null;
  profile_unset?: boolean | null;
  userId?: any | null;
  userId_unset?: boolean | null;
  recommendations_unset?: boolean | null;
  interests?: (string | null)[] | null;
  gifteeId?: any | null;
  gifteeId_unset?: boolean | null;
  purchases_unset?: boolean | null;
  recommendations?: (GiftProfileRecommendationsUpdateInput | null)[] | null;
  gender?: GiftProfileGender | null;
  interests_unset?: boolean | null;
}

export interface GiftProviderQueryInput {
  _id_gte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_in?: (any | null)[] | null;
  externalId_in?: (string | null)[] | null;
  logo_gt?: string | null;
  type_lte?: GiftProviderType | null;
  _id_gt?: any | null;
  _id_ne?: any | null;
  name_gte?: string | null;
  _id_lte?: any | null;
  createdAt_lte?: any | null;
  createdAt_exists?: boolean | null;
  createdAt_gte?: any | null;
  _id_exists?: boolean | null;
  updatedAt_lt?: any | null;
  name_nin?: (string | null)[] | null;
  updatedAt_ne?: any | null;
  type_gte?: GiftProviderType | null;
  externalId_lte?: string | null;
  logo_lt?: string | null;
  logo_lte?: string | null;
  type_in?: (GiftProviderType | null)[] | null;
  type_nin?: (GiftProviderType | null)[] | null;
  _id?: any | null;
  name_in?: (string | null)[] | null;
  externalId?: string | null;
  externalId_nin?: (string | null)[] | null;
  name_gt?: string | null;
  logo_exists?: boolean | null;
  name?: string | null;
  logo?: string | null;
  createdAt_nin?: (any | null)[] | null;
  logo_in?: (string | null)[] | null;
  updatedAt_exists?: boolean | null;
  externalId_lt?: string | null;
  name_exists?: boolean | null;
  updatedAt_gte?: any | null;
  externalId_gte?: string | null;
  AND?: GiftProviderQueryInput[] | null;
  name_ne?: string | null;
  logo_ne?: string | null;
  name_lte?: string | null;
  createdAt_gt?: any | null;
  updatedAt?: any | null;
  externalId_exists?: boolean | null;
  type?: GiftProviderType | null;
  _id_lt?: any | null;
  type_ne?: GiftProviderType | null;
  type_gt?: GiftProviderType | null;
  type_lt?: GiftProviderType | null;
  updatedAt_nin?: (any | null)[] | null;
  OR?: GiftProviderQueryInput[] | null;
  externalId_gt?: string | null;
  logo_nin?: (string | null)[] | null;
  updatedAt_lte?: any | null;
  createdAt_ne?: any | null;
  externalId_ne?: string | null;
  _id_in?: (any | null)[] | null;
  createdAt?: any | null;
  name_lt?: string | null;
  type_exists?: boolean | null;
  _id_nin?: (any | null)[] | null;
  createdAt_lt?: any | null;
  createdAt_in?: (any | null)[] | null;
  logo_gte?: string | null;
}

export interface GifteeInsertInput {
  _id?: any | null;
  relationship?: GifteeRelationship | null;
  userId?: any | null;
  picture?: string | null;
  status?: GifteeStatus | null;
  createdAt?: any | null;
  interests?: (string | null)[] | null;
  name?: string | null;
  updatedAt?: any | null;
  age?: number | null;
  isCustom?: boolean | null;
  isPendingAcceptance?: boolean | null;
  sourceUser?: GifteeSourceUserRelationInput | null;
}

export interface GifteeQueryInput {
  updatedAt_exists?: boolean | null;
  createdAt_gt?: any | null;
  relationship_nin?: (GifteeRelationship | null)[] | null;
  picture?: string | null;
  interests_exists?: boolean | null;
  updatedAt_gte?: any | null;
  _id_exists?: boolean | null;
  relationship_lt?: GifteeRelationship | null;
  age_gte?: number | null;
  isPendingAcceptance_ne?: boolean | null;
  status_exists?: boolean | null;
  _id?: any | null;
  relationship_gte?: GifteeRelationship | null;
  status_nin?: (GifteeStatus | null)[] | null;
  age?: number | null;
  userId_gt?: any | null;
  status_gte?: GifteeStatus | null;
  name_ne?: string | null;
  userId_in?: (any | null)[] | null;
  status_in?: (GifteeStatus | null)[] | null;
  updatedAt_in?: (any | null)[] | null;
  age_in?: (number | null)[] | null;
  relationship_in?: (GifteeRelationship | null)[] | null;
  updatedAt_gt?: any | null;
  createdAt_ne?: any | null;
  userId_lt?: any | null;
  OR?: GifteeQueryInput[] | null;
  relationship_lte?: GifteeRelationship | null;
  picture_gt?: string | null;
  _id_lt?: any | null;
  status_lte?: GifteeStatus | null;
  createdAt?: any | null;
  createdAt_exists?: boolean | null;
  updatedAt?: any | null;
  interests?: (string | null)[] | null;
  interests_in?: (string | null)[] | null;
  createdAt_lt?: any | null;
  updatedAt_lte?: any | null;
  picture_ne?: string | null;
  picture_exists?: boolean | null;
  relationship_gt?: GifteeRelationship | null;
  name_nin?: (string | null)[] | null;
  relationship?: GifteeRelationship | null;
  userId?: any | null;
  age_lt?: number | null;
  age_nin?: (number | null)[] | null;
  name_lt?: string | null;
  status?: GifteeStatus | null;
  userId_ne?: any | null;
  userId_gte?: any | null;
  name_lte?: string | null;
  picture_nin?: (string | null)[] | null;
  relationship_exists?: boolean | null;
  createdAt_nin?: (any | null)[] | null;
  createdAt_gte?: any | null;
  name_in?: (string | null)[] | null;
  updatedAt_ne?: any | null;
  _id_gt?: any | null;
  age_lte?: number | null;
  status_ne?: GifteeStatus | null;
  createdAt_lte?: any | null;
  isPendingAcceptance_exists?: boolean | null;
  createdAt_in?: (any | null)[] | null;
  name_exists?: boolean | null;
  userId_exists?: boolean | null;
  updatedAt_nin?: (any | null)[] | null;
  age_exists?: boolean | null;
  age_ne?: number | null;
  isCustom_ne?: boolean | null;
  AND?: GifteeQueryInput[] | null;
  status_lt?: GifteeStatus | null;
  status_gt?: GifteeStatus | null;
  userId_nin?: (any | null)[] | null;
  picture_lt?: string | null;
  name_gt?: string | null;
  isCustom_exists?: boolean | null;
  _id_nin?: (any | null)[] | null;
  userId_lte?: any | null;
  name?: string | null;
  isPendingAcceptance?: boolean | null;
  _id_lte?: any | null;
  picture_in?: (string | null)[] | null;
  isCustom?: boolean | null;
  _id_gte?: any | null;
  sourceUser?: UserQueryInput | null;
  _id_in?: (any | null)[] | null;
  age_gt?: number | null;
  _id_ne?: any | null;
  picture_gte?: string | null;
  relationship_ne?: GifteeRelationship | null;
  updatedAt_lt?: any | null;
  interests_nin?: (string | null)[] | null;
  sourceUser_exists?: boolean | null;
  picture_lte?: string | null;
  name_gte?: string | null;
}

export interface GifteeSourceUserRelationInput {
  create?: UserInsertInput | null;
  link?: any | null;
}

export interface GifteeUpdateInput {
  sourceUser?: GifteeSourceUserRelationInput | null;
  status?: GifteeStatus | null;
  age_inc?: number | null;
  interests_unset?: boolean | null;
  name_unset?: boolean | null;
  createdAt?: any | null;
  sourceUser_unset?: boolean | null;
  interests?: (string | null)[] | null;
  isCustom?: boolean | null;
  status_unset?: boolean | null;
  isPendingAcceptance_unset?: boolean | null;
  _id_unset?: boolean | null;
  age_unset?: boolean | null;
  name?: string | null;
  relationship?: GifteeRelationship | null;
  picture?: string | null;
  picture_unset?: boolean | null;
  createdAt_unset?: boolean | null;
  isPendingAcceptance?: boolean | null;
  relationship_unset?: boolean | null;
  userId?: any | null;
  userId_unset?: boolean | null;
  updatedAt_unset?: boolean | null;
  _id?: any | null;
  isCustom_unset?: boolean | null;
  updatedAt?: any | null;
  age?: number | null;
}

export interface NotificationGifteeRelationInput {
  create?: GifteeInsertInput | null;
  link?: any | null;
}

export interface NotificationInsertInput {
  requestee?: NotificationRequesteeRelationInput | null;
  updatedAt?: any | null;
  archived?: boolean | null;
  invitee?: NotificationInviteeRelationInput | null;
  new?: boolean | null;
  requester?: NotificationRequesterRelationInput | null;
  occasion?: NotificationOccasionRelationInput | null;
  userId?: any | null;
  _id?: any | null;
  giftee?: NotificationGifteeRelationInput | null;
  type?: NotificationType | null;
  createdAt?: any | null;
}

export interface NotificationInviteeRelationInput {
  create?: UserInsertInput | null;
  link?: any | null;
}

export interface NotificationOccasionRelationInput {
  link?: any | null;
  create?: OccasionInsertInput | null;
}

export interface NotificationQueryInput {
  userId_in?: (any | null)[] | null;
  archived_ne?: boolean | null;
  updatedAt_lt?: any | null;
  createdAt_in?: (any | null)[] | null;
  _id?: any | null;
  occasion_exists?: boolean | null;
  createdAt_lte?: any | null;
  updatedAt_lte?: any | null;
  userId_ne?: any | null;
  updatedAt_in?: (any | null)[] | null;
  AND?: NotificationQueryInput[] | null;
  new_exists?: boolean | null;
  type?: NotificationType | null;
  _id_gte?: any | null;
  new?: boolean | null;
  userId_lte?: any | null;
  userId_exists?: boolean | null;
  invitee_exists?: boolean | null;
  archived?: boolean | null;
  type_gt?: NotificationType | null;
  createdAt?: any | null;
  type_exists?: boolean | null;
  createdAt_gt?: any | null;
  _id_nin?: (any | null)[] | null;
  userId_gt?: any | null;
  occasion?: OccasionQueryInput | null;
  updatedAt_gte?: any | null;
  createdAt_exists?: boolean | null;
  giftee?: GifteeQueryInput | null;
  invitee?: UserQueryInput | null;
  new_ne?: boolean | null;
  OR?: NotificationQueryInput[] | null;
  updatedAt?: any | null;
  requestee_exists?: boolean | null;
  archived_exists?: boolean | null;
  updatedAt_gt?: any | null;
  type_gte?: NotificationType | null;
  _id_lt?: any | null;
  userId_gte?: any | null;
  updatedAt_nin?: (any | null)[] | null;
  _id_gt?: any | null;
  _id_in?: (any | null)[] | null;
  userId?: any | null;
  type_lte?: NotificationType | null;
  type_lt?: NotificationType | null;
  updatedAt_exists?: boolean | null;
  type_in?: (NotificationType | null)[] | null;
  createdAt_ne?: any | null;
  userId_lt?: any | null;
  type_ne?: NotificationType | null;
  createdAt_nin?: (any | null)[] | null;
  _id_lte?: any | null;
  _id_exists?: boolean | null;
  giftee_exists?: boolean | null;
  updatedAt_ne?: any | null;
  userId_nin?: (any | null)[] | null;
  requester_exists?: boolean | null;
  createdAt_lt?: any | null;
  _id_ne?: any | null;
  createdAt_gte?: any | null;
  requester?: UserQueryInput | null;
  requestee?: UserQueryInput | null;
  type_nin?: (NotificationType | null)[] | null;
}

export interface NotificationRequesteeRelationInput {
  create?: UserInsertInput | null;
  link?: any | null;
}

export interface NotificationRequesterRelationInput {
  create?: UserInsertInput | null;
  link?: any | null;
}

export interface NotificationSettingInsertInput {
  _id?: any | null;
  createdAt?: any | null;
  notificationTypes?: (NotificationSettingNotificationTypesInsertInput | null)[] | null;
  updatedAt?: any | null;
  userId?: any | null;
}

export interface NotificationSettingNotificationTypesInsertInput {
  deviceType?: string | null;
  enabled?: boolean | null;
  type?: string | null;
  deviceName?: string | null;
  deviceToken?: string | null;
}

export interface NotificationSettingNotificationTypesQueryInput {
  type_in?: (string | null)[] | null;
  AND?: NotificationSettingNotificationTypesQueryInput[] | null;
  type_lte?: string | null;
  deviceToken_lte?: string | null;
  deviceName?: string | null;
  deviceType_lte?: string | null;
  deviceToken_gte?: string | null;
  deviceType_exists?: boolean | null;
  enabled_exists?: boolean | null;
  type_gte?: string | null;
  OR?: NotificationSettingNotificationTypesQueryInput[] | null;
  enabled?: boolean | null;
  deviceToken_nin?: (string | null)[] | null;
  deviceToken_in?: (string | null)[] | null;
  deviceName_nin?: (string | null)[] | null;
  deviceToken_exists?: boolean | null;
  type_nin?: (string | null)[] | null;
  deviceName_gt?: string | null;
  enabled_ne?: boolean | null;
  deviceType_ne?: string | null;
  deviceToken?: string | null;
  deviceToken_ne?: string | null;
  deviceName_in?: (string | null)[] | null;
  deviceType_in?: (string | null)[] | null;
  deviceName_ne?: string | null;
  type_lt?: string | null;
  type?: string | null;
  type_ne?: string | null;
  deviceName_gte?: string | null;
  deviceToken_lt?: string | null;
  deviceName_lt?: string | null;
  deviceType?: string | null;
  deviceType_nin?: (string | null)[] | null;
  deviceName_lte?: string | null;
  deviceType_gt?: string | null;
  deviceName_exists?: boolean | null;
  deviceType_gte?: string | null;
  type_gt?: string | null;
  deviceToken_gt?: string | null;
  type_exists?: boolean | null;
  deviceType_lt?: string | null;
}

export interface NotificationSettingNotificationTypesUpdateInput {
  deviceToken?: string | null;
  deviceType_unset?: boolean | null;
  enabled?: boolean | null;
  enabled_unset?: boolean | null;
  type?: string | null;
  deviceType?: string | null;
  type_unset?: boolean | null;
  deviceName_unset?: boolean | null;
  deviceToken_unset?: boolean | null;
  deviceName?: string | null;
}

export interface NotificationSettingQueryInput {
  updatedAt_exists?: boolean | null;
  _id?: any | null;
  createdAt_lte?: any | null;
  createdAt_exists?: boolean | null;
  createdAt_gt?: any | null;
  _id_lte?: any | null;
  userId_nin?: (any | null)[] | null;
  userId?: any | null;
  _id_in?: (any | null)[] | null;
  createdAt_lt?: any | null;
  userId_lte?: any | null;
  updatedAt_ne?: any | null;
  notificationTypes_exists?: boolean | null;
  createdAt?: any | null;
  userId_ne?: any | null;
  updatedAt_nin?: (any | null)[] | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  OR?: NotificationSettingQueryInput[] | null;
  createdAt_in?: (any | null)[] | null;
  notificationTypes_in?: (NotificationSettingNotificationTypesQueryInput | null)[] | null;
  _id_gt?: any | null;
  _id_nin?: (any | null)[] | null;
  _id_lt?: any | null;
  _id_gte?: any | null;
  _id_exists?: boolean | null;
  notificationTypes_nin?: (NotificationSettingNotificationTypesQueryInput | null)[] | null;
  AND?: NotificationSettingQueryInput[] | null;
  createdAt_gte?: any | null;
  userId_lt?: any | null;
  userId_in?: (any | null)[] | null;
  userId_gt?: any | null;
  updatedAt?: any | null;
  updatedAt_in?: (any | null)[] | null;
  userId_gte?: any | null;
  updatedAt_lt?: any | null;
  userId_exists?: boolean | null;
  notificationTypes?: (NotificationSettingNotificationTypesQueryInput | null)[] | null;
  updatedAt_gte?: any | null;
  createdAt_ne?: any | null;
  createdAt_nin?: (any | null)[] | null;
  _id_ne?: any | null;
}

export interface NotificationSettingUpdateInput {
  notificationTypes_unset?: boolean | null;
  userId?: any | null;
  createdAt_unset?: boolean | null;
  createdAt?: any | null;
  _id_unset?: boolean | null;
  notificationTypes?: (NotificationSettingNotificationTypesUpdateInput | null)[] | null;
  updatedAt?: any | null;
  updatedAt_unset?: boolean | null;
  userId_unset?: boolean | null;
  _id?: any | null;
}

export interface NotificationUpdateInput {
  giftee_unset?: boolean | null;
  invitee?: NotificationInviteeRelationInput | null;
  requester_unset?: boolean | null;
  updatedAt?: any | null;
  createdAt?: any | null;
  createdAt_unset?: boolean | null;
  invitee_unset?: boolean | null;
  occasion_unset?: boolean | null;
  _id?: any | null;
  new_unset?: boolean | null;
  requester?: NotificationRequesterRelationInput | null;
  userId_unset?: boolean | null;
  type?: NotificationType | null;
  requestee?: NotificationRequesteeRelationInput | null;
  updatedAt_unset?: boolean | null;
  giftee?: NotificationGifteeRelationInput | null;
  occasion?: NotificationOccasionRelationInput | null;
  requestee_unset?: boolean | null;
  archived?: boolean | null;
  type_unset?: boolean | null;
  archived_unset?: boolean | null;
  userId?: any | null;
  _id_unset?: boolean | null;
  new?: boolean | null;
}

export interface OccasionDayMonthDateInsertInput {
  day?: number | null;
  month?: number | null;
}

export interface OccasionDayMonthDateQueryInput {
  month_exists?: boolean | null;
  month?: number | null;
  month_lt?: number | null;
  day_lt?: number | null;
  day_exists?: boolean | null;
  day_lte?: number | null;
  month_gt?: number | null;
  day_ne?: number | null;
  month_nin?: (number | null)[] | null;
  day_gte?: number | null;
  AND?: OccasionDayMonthDateQueryInput[] | null;
  month_in?: (number | null)[] | null;
  month_lte?: number | null;
  month_gte?: number | null;
  OR?: OccasionDayMonthDateQueryInput[] | null;
  day_gt?: number | null;
  day_nin?: (number | null)[] | null;
  month_ne?: number | null;
  day_in?: (number | null)[] | null;
  day?: number | null;
}

export interface OccasionDayMonthDateUpdateInput {
  day_inc?: number | null;
  day_unset?: boolean | null;
  month?: number | null;
  month_inc?: number | null;
  month_unset?: boolean | null;
  day?: number | null;
}

export interface OccasionGifteeRelationInput {
  create?: GifteeInsertInput | null;
  link?: any | null;
}

export interface OccasionInsertInput {
  createdAt?: any | null;
  updatedAt?: any | null;
  userId?: any | null;
  name?: string | null;
  annual?: boolean | null;
  deleted?: boolean | null;
  purchases?: (OccasionPurchasesInsertInput | null)[] | null;
  dayMonthDate?: OccasionDayMonthDateInsertInput | null;
  giftee?: OccasionGifteeRelationInput | null;
  nextOccasionDate?: any | null;
  type?: OccasionType | null;
  _id?: any | null;
}

export interface OccasionPurchasesInsertInput {
  date?: any | null;
  giftIdeaId?: any | null;
  targetYear?: number | null;
  transactionId?: any | null;
}

export interface OccasionPurchasesQueryInput {
  transactionId?: any | null;
  targetYear_lte?: number | null;
  transactionId_ne?: any | null;
  targetYear_gte?: number | null;
  transactionId_in?: (any | null)[] | null;
  date_ne?: any | null;
  giftIdeaId_lte?: any | null;
  giftIdeaId_in?: (any | null)[] | null;
  transactionId_exists?: boolean | null;
  date_lte?: any | null;
  targetYear_nin?: (number | null)[] | null;
  targetYear_in?: (number | null)[] | null;
  date?: any | null;
  transactionId_gt?: any | null;
  giftIdeaId_nin?: (any | null)[] | null;
  giftIdeaId_ne?: any | null;
  date_gt?: any | null;
  AND?: OccasionPurchasesQueryInput[] | null;
  transactionId_nin?: (any | null)[] | null;
  transactionId_gte?: any | null;
  date_in?: (any | null)[] | null;
  giftIdeaId_exists?: boolean | null;
  OR?: OccasionPurchasesQueryInput[] | null;
  date_exists?: boolean | null;
  transactionId_lt?: any | null;
  targetYear_lt?: number | null;
  date_gte?: any | null;
  date_lt?: any | null;
  transactionId_lte?: any | null;
  date_nin?: (any | null)[] | null;
  giftIdeaId_gte?: any | null;
  targetYear_ne?: number | null;
  targetYear_exists?: boolean | null;
  targetYear?: number | null;
  targetYear_gt?: number | null;
  giftIdeaId_lt?: any | null;
  giftIdeaId_gt?: any | null;
  giftIdeaId?: any | null;
}

export interface OccasionPurchasesUpdateInput {
  date_unset?: boolean | null;
  targetYear?: number | null;
  targetYear_unset?: boolean | null;
  transactionId?: any | null;
  date?: any | null;
  giftIdeaId_unset?: boolean | null;
  giftIdeaId?: any | null;
  targetYear_inc?: number | null;
  transactionId_unset?: boolean | null;
}

export interface OccasionQueryInput {
  createdAt_lte?: any | null;
  updatedAt_nin?: (any | null)[] | null;
  giftee?: GifteeQueryInput | null;
  _id?: any | null;
  nextOccasionDate_nin?: (any | null)[] | null;
  nextOccasionDate_gt?: any | null;
  createdAt_exists?: boolean | null;
  _id_ne?: any | null;
  createdAt_ne?: any | null;
  annual_ne?: boolean | null;
  userId_in?: (any | null)[] | null;
  dayMonthDate_exists?: boolean | null;
  createdAt?: any | null;
  userId?: any | null;
  updatedAt_exists?: boolean | null;
  type_gt?: OccasionType | null;
  type_exists?: boolean | null;
  updatedAt_in?: (any | null)[] | null;
  userId_lte?: any | null;
  type_lte?: OccasionType | null;
  userId_lt?: any | null;
  giftee_exists?: boolean | null;
  updatedAt_lte?: any | null;
  deleted_ne?: boolean | null;
  type_lt?: OccasionType | null;
  annual?: boolean | null;
  deleted?: boolean | null;
  updatedAt_lt?: any | null;
  purchases_nin?: (OccasionPurchasesQueryInput | null)[] | null;
  name_nin?: (string | null)[] | null;
  name_exists?: boolean | null;
  updatedAt_gt?: any | null;
  _id_lt?: any | null;
  name_lte?: string | null;
  annual_exists?: boolean | null;
  type_gte?: OccasionType | null;
  userId_gte?: any | null;
  purchases?: (OccasionPurchasesQueryInput | null)[] | null;
  updatedAt_gte?: any | null;
  name_gte?: string | null;
  nextOccasionDate_lte?: any | null;
  _id_lte?: any | null;
  createdAt_lt?: any | null;
  name_ne?: string | null;
  userId_ne?: any | null;
  userId_exists?: boolean | null;
  nextOccasionDate_exists?: boolean | null;
  _id_exists?: boolean | null;
  _id_nin?: (any | null)[] | null;
  createdAt_in?: (any | null)[] | null;
  type?: OccasionType | null;
  purchases_in?: (OccasionPurchasesQueryInput | null)[] | null;
  dayMonthDate?: OccasionDayMonthDateQueryInput | null;
  deleted_exists?: boolean | null;
  nextOccasionDate?: any | null;
  updatedAt_ne?: any | null;
  nextOccasionDate_gte?: any | null;
  createdAt_gte?: any | null;
  createdAt_nin?: (any | null)[] | null;
  name?: string | null;
  nextOccasionDate_ne?: any | null;
  nextOccasionDate_lt?: any | null;
  _id_gte?: any | null;
  updatedAt?: any | null;
  OR?: OccasionQueryInput[] | null;
  type_in?: (OccasionType | null)[] | null;
  userId_gt?: any | null;
  nextOccasionDate_in?: (any | null)[] | null;
  userId_nin?: (any | null)[] | null;
  createdAt_gt?: any | null;
  name_in?: (string | null)[] | null;
  name_gt?: string | null;
  purchases_exists?: boolean | null;
  _id_gt?: any | null;
  AND?: OccasionQueryInput[] | null;
  type_nin?: (OccasionType | null)[] | null;
  _id_in?: (any | null)[] | null;
  name_lt?: string | null;
  type_ne?: OccasionType | null;
}

export interface OccasionUpdateInput {
  _id_unset?: boolean | null;
  annual?: boolean | null;
  updatedAt_unset?: boolean | null;
  userId_unset?: boolean | null;
  dayMonthDate_unset?: boolean | null;
  purchases_unset?: boolean | null;
  name?: string | null;
  type?: OccasionType | null;
  _id?: any | null;
  annual_unset?: boolean | null;
  type_unset?: boolean | null;
  deleted_unset?: boolean | null;
  purchases?: (OccasionPurchasesUpdateInput | null)[] | null;
  giftee_unset?: boolean | null;
  updatedAt?: any | null;
  nextOccasionDate_unset?: boolean | null;
  createdAt_unset?: boolean | null;
  dayMonthDate?: OccasionDayMonthDateUpdateInput | null;
  createdAt?: any | null;
  deleted?: boolean | null;
  name_unset?: boolean | null;
  nextOccasionDate?: any | null;
  giftee?: OccasionGifteeRelationInput | null;
  userId?: any | null;
}

export interface ProductAssociatedProductsQueryInput {
  title_ne?: string | null;
  url_gt?: string | null;
  image_lt?: string | null;
  title_exists?: boolean | null;
  url_nin?: (string | null)[] | null;
  url_lte?: string | null;
  url_gte?: string | null;
  title?: string | null;
  title_lte?: string | null;
  title_nin?: (string | null)[] | null;
  image_in?: (string | null)[] | null;
  image_lte?: string | null;
  title_lt?: string | null;
  title_in?: (string | null)[] | null;
  AND?: ProductAssociatedProductsQueryInput[] | null;
  image_exists?: boolean | null;
  image_gt?: string | null;
  OR?: ProductAssociatedProductsQueryInput[] | null;
  title_gt?: string | null;
  image?: string | null;
  url_lt?: string | null;
  image_nin?: (string | null)[] | null;
  title_gte?: string | null;
  url_exists?: boolean | null;
  url_ne?: string | null;
  url_in?: (string | null)[] | null;
  url?: string | null;
  image_gte?: string | null;
  image_ne?: string | null;
}

export interface ProductMetaDataQueryInput {
  numberOfReviews_gte?: number | null;
  avgReview_in?: (number | null)[] | null;
  etsyFavorers?: number | null;
  avgReview_ne?: number | null;
  etsyFavorers_in?: (number | null)[] | null;
  OR?: ProductMetaDataQueryInput[] | null;
  numberOfReviews_lt?: number | null;
  numberOfReviews_lte?: number | null;
  numberOfReviews?: number | null;
  avgReview_nin?: (number | null)[] | null;
  etsyFavorers_gte?: number | null;
  numberOfReviews_exists?: boolean | null;
  avgReview_lte?: number | null;
  etsyFavorers_lt?: number | null;
  etsyFavorers_exists?: boolean | null;
  numberOfReviews_in?: (number | null)[] | null;
  numberOfReviews_ne?: number | null;
  avgReview_gt?: number | null;
  numberOfReviews_gt?: number | null;
  avgReview_exists?: boolean | null;
  etsyFavorers_gt?: number | null;
  etsyFavorers_nin?: (number | null)[] | null;
  avgReview_lt?: number | null;
  numberOfReviews_nin?: (number | null)[] | null;
  AND?: ProductMetaDataQueryInput[] | null;
  avgReview_gte?: number | null;
  avgReview?: number | null;
  etsyFavorers_ne?: number | null;
  etsyFavorers_lte?: number | null;
}

export interface ProductQueryInput {
  giftSource_lt?: string | null;
  gender_ne?: ProductGender | null;
  createdAt?: any | null;
  status_lte?: ProductStatus | null;
  AND?: ProductQueryInput[] | null;
  associatedProducts_in?: (ProductAssociatedProductsQueryInput | null)[] | null;
  _id_gt?: any | null;
  title_gt?: string | null;
  externalId_gte?: string | null;
  gender_nin?: (ProductGender | null)[] | null;
  price_in?: (number | null)[] | null;
  createdAt_lt?: any | null;
  updatedAt?: any | null;
  title?: string | null;
  updatedAt_gte?: any | null;
  image_gt?: string | null;
  description_nin?: (string | null)[] | null;
  gender_lte?: ProductGender | null;
  giftSource_nin?: (string | null)[] | null;
  price_lt?: number | null;
  _id_lt?: any | null;
  metaData?: ProductMetaDataQueryInput | null;
  tagScores_nin?: (ProductTagScoresQueryInput | null)[] | null;
  createdAt_gte?: any | null;
  status?: ProductStatus | null;
  createdAt_ne?: any | null;
  status_nin?: (ProductStatus | null)[] | null;
  image?: string | null;
  provider_exists?: boolean | null;
  price?: number | null;
  url_exists?: boolean | null;
  image_gte?: string | null;
  title_in?: (string | null)[] | null;
  associatedProducts?: (ProductAssociatedProductsQueryInput | null)[] | null;
  tags?: (string | null)[] | null;
  tags_nin?: (string | null)[] | null;
  _id_ne?: any | null;
  _id_lte?: any | null;
  createdAt_in?: (any | null)[] | null;
  externalId_gt?: string | null;
  tagScores?: (ProductTagScoresQueryInput | null)[] | null;
  giftSource?: string | null;
  updatedAt_ne?: any | null;
  giftSource_gte?: string | null;
  updatedAt_in?: (any | null)[] | null;
  externalId_lt?: string | null;
  _id_nin?: (any | null)[] | null;
  tags_in?: (string | null)[] | null;
  title_gte?: string | null;
  description_gt?: string | null;
  status_exists?: boolean | null;
  provider?: GiftProviderQueryInput | null;
  image_lt?: string | null;
  status_in?: (ProductStatus | null)[] | null;
  title_lt?: string | null;
  status_gt?: ProductStatus | null;
  gender_gt?: ProductGender | null;
  title_ne?: string | null;
  image_exists?: boolean | null;
  gender_gte?: ProductGender | null;
  description?: string | null;
  url_in?: (string | null)[] | null;
  _id?: any | null;
  gender_lt?: ProductGender | null;
  description_exists?: boolean | null;
  tagScores_exists?: boolean | null;
  giftSource_exists?: boolean | null;
  url_lte?: string | null;
  url?: string | null;
  createdAt_gt?: any | null;
  createdAt_lte?: any | null;
  url_gte?: string | null;
  updatedAt_nin?: (any | null)[] | null;
  createdAt_exists?: boolean | null;
  updatedAt_exists?: boolean | null;
  url_lt?: string | null;
  externalId_nin?: (string | null)[] | null;
  gender_exists?: boolean | null;
  title_exists?: boolean | null;
  giftSource_ne?: string | null;
  url_ne?: string | null;
  status_gte?: ProductStatus | null;
  description_ne?: string | null;
  externalId_ne?: string | null;
  title_lte?: string | null;
  associatedProducts_exists?: boolean | null;
  giftSource_in?: (string | null)[] | null;
  OR?: ProductQueryInput[] | null;
  _id_in?: (any | null)[] | null;
  price_exists?: boolean | null;
  externalId?: string | null;
  price_nin?: (number | null)[] | null;
  createdAt_nin?: (any | null)[] | null;
  image_ne?: string | null;
  _id_gte?: any | null;
  status_ne?: ProductStatus | null;
  price_gt?: number | null;
  image_in?: (string | null)[] | null;
  url_gt?: string | null;
  externalId_in?: (string | null)[] | null;
  url_nin?: (string | null)[] | null;
  updatedAt_lte?: any | null;
  description_lt?: string | null;
  metaData_exists?: boolean | null;
  image_nin?: (string | null)[] | null;
  title_nin?: (string | null)[] | null;
  price_ne?: number | null;
  tagScores_in?: (ProductTagScoresQueryInput | null)[] | null;
  gender_in?: (ProductGender | null)[] | null;
  giftSource_lte?: string | null;
  giftSource_gt?: string | null;
  externalId_exists?: boolean | null;
  price_gte?: number | null;
  tags_exists?: boolean | null;
  _id_exists?: boolean | null;
  description_in?: (string | null)[] | null;
  gender?: ProductGender | null;
  description_gte?: string | null;
  associatedProducts_nin?: (ProductAssociatedProductsQueryInput | null)[] | null;
  status_lt?: ProductStatus | null;
  externalId_lte?: string | null;
  image_lte?: string | null;
  updatedAt_gt?: any | null;
  updatedAt_lt?: any | null;
  price_lte?: number | null;
  description_lte?: string | null;
}

export interface ProductTagScoresQueryInput {
  score_in?: (number | null)[] | null;
  tag_gte?: string | null;
  tag_lt?: string | null;
  score?: number | null;
  tag_gt?: string | null;
  score_nin?: (number | null)[] | null;
  score_exists?: boolean | null;
  tag_lte?: string | null;
  score_lte?: number | null;
  score_gt?: number | null;
  tag?: string | null;
  AND?: ProductTagScoresQueryInput[] | null;
  score_ne?: number | null;
  score_gte?: number | null;
  tag_ne?: string | null;
  score_lt?: number | null;
  tag_nin?: (string | null)[] | null;
  tag_in?: (string | null)[] | null;
  tag_exists?: boolean | null;
  OR?: ProductTagScoresQueryInput[] | null;
}

export interface ReminderInsertInput {
  createdAt?: any | null;
  date?: any | null;
  fired?: boolean | null;
  occasion?: ReminderOccasionRelationInput | null;
  updatedAt?: any | null;
  _id?: any | null;
}

export interface ReminderOccasionRelationInput {
  create?: OccasionInsertInput | null;
  link?: any | null;
}

export interface ReminderQueryInput {
  createdAt_in?: (any | null)[] | null;
  fired?: boolean | null;
  createdAt_exists?: boolean | null;
  fired_exists?: boolean | null;
  updatedAt_lt?: any | null;
  createdAt_gt?: any | null;
  date_gte?: any | null;
  date_nin?: (any | null)[] | null;
  AND?: ReminderQueryInput[] | null;
  _id?: any | null;
  date_lte?: any | null;
  occasion_exists?: boolean | null;
  updatedAt_nin?: (any | null)[] | null;
  createdAt_lt?: any | null;
  _id_ne?: any | null;
  _id_in?: (any | null)[] | null;
  updatedAt_exists?: boolean | null;
  date?: any | null;
  _id_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt?: any | null;
  updatedAt_gt?: any | null;
  date_lt?: any | null;
  _id_lte?: any | null;
  _id_lt?: any | null;
  date_ne?: any | null;
  updatedAt_gte?: any | null;
  OR?: ReminderQueryInput[] | null;
  createdAt_lte?: any | null;
  _id_gte?: any | null;
  _id_nin?: (any | null)[] | null;
  updatedAt_lte?: any | null;
  date_gt?: any | null;
  date_in?: (any | null)[] | null;
  fired_ne?: boolean | null;
  updatedAt?: any | null;
  updatedAt_in?: (any | null)[] | null;
  updatedAt_ne?: any | null;
  createdAt_ne?: any | null;
  date_exists?: boolean | null;
  createdAt_nin?: (any | null)[] | null;
  occasion?: OccasionQueryInput | null;
  _id_exists?: boolean | null;
}

export interface ReminderUpdateInput {
  fired?: boolean | null;
  fired_unset?: boolean | null;
  occasion?: ReminderOccasionRelationInput | null;
  occasion_unset?: boolean | null;
  updatedAt_unset?: boolean | null;
  _id_unset?: boolean | null;
  createdAt_unset?: boolean | null;
  date_unset?: boolean | null;
  _id?: any | null;
  createdAt?: any | null;
  date?: any | null;
  updatedAt?: any | null;
}

export interface TransactionInsertInput {
  giftIdeaId?: any | null;
  giftIdeaImage?: string | null;
  giftIdeaTitle?: string | null;
  userId?: any | null;
  occasionId?: any | null;
  createdAt?: any | null;
  giftSourceName?: string | null;
  _id?: any | null;
  amount?: number | null;
  updatedAt?: any | null;
  gifteeId?: any | null;
}

export interface TransactionQueryInput {
  userId_exists?: boolean | null;
  amount?: number | null;
  AND?: TransactionQueryInput[] | null;
  amount_nin?: (number | null)[] | null;
  giftSourceName_gte?: string | null;
  gifteeId_lte?: any | null;
  createdAt_gt?: any | null;
  giftIdeaId_gt?: any | null;
  _id_in?: (any | null)[] | null;
  amount_gte?: number | null;
  giftIdeaImage_nin?: (string | null)[] | null;
  createdAt_nin?: (any | null)[] | null;
  createdAt?: any | null;
  giftIdeaId_in?: (any | null)[] | null;
  giftIdeaId_lt?: any | null;
  occasionId?: any | null;
  giftIdeaTitle_in?: (string | null)[] | null;
  createdAt_in?: (any | null)[] | null;
  giftSourceName_lt?: string | null;
  giftIdeaImage_gte?: string | null;
  giftIdeaImage_ne?: string | null;
  userId_lt?: any | null;
  giftSourceName_gt?: string | null;
  giftIdeaTitle_gt?: string | null;
  updatedAt_nin?: (any | null)[] | null;
  updatedAt_lte?: any | null;
  amount_in?: (number | null)[] | null;
  userId_nin?: (any | null)[] | null;
  giftIdeaTitle_gte?: string | null;
  _id_ne?: any | null;
  amount_exists?: boolean | null;
  userId_ne?: any | null;
  amount_ne?: number | null;
  updatedAt_gt?: any | null;
  _id_gt?: any | null;
  gifteeId_gte?: any | null;
  giftIdeaId_nin?: (any | null)[] | null;
  giftIdeaTitle_nin?: (string | null)[] | null;
  giftIdeaImage?: string | null;
  giftSourceName_exists?: boolean | null;
  updatedAt_gte?: any | null;
  giftSourceName?: string | null;
  _id_exists?: boolean | null;
  userId_gte?: any | null;
  occasionId_ne?: any | null;
  _id_gte?: any | null;
  userId_lte?: any | null;
  updatedAt?: any | null;
  giftIdeaId_ne?: any | null;
  _id?: any | null;
  giftIdeaTitle?: string | null;
  giftSourceName_in?: (string | null)[] | null;
  updatedAt_in?: (any | null)[] | null;
  giftIdeaImage_gt?: string | null;
  gifteeId_ne?: any | null;
  createdAt_exists?: boolean | null;
  occasionId_exists?: boolean | null;
  gifteeId_exists?: boolean | null;
  giftIdeaImage_in?: (string | null)[] | null;
  createdAt_ne?: any | null;
  _id_lte?: any | null;
  gifteeId_lt?: any | null;
  occasionId_lt?: any | null;
  giftSourceName_ne?: string | null;
  gifteeId_in?: (any | null)[] | null;
  giftSourceName_lte?: string | null;
  createdAt_lte?: any | null;
  userId?: any | null;
  _id_lt?: any | null;
  createdAt_lt?: any | null;
  giftIdeaImage_lte?: string | null;
  giftIdeaTitle_exists?: boolean | null;
  giftIdeaId_lte?: any | null;
  amount_lte?: number | null;
  createdAt_gte?: any | null;
  giftIdeaTitle_ne?: string | null;
  OR?: TransactionQueryInput[] | null;
  userId_gt?: any | null;
  gifteeId_nin?: (any | null)[] | null;
  occasionId_in?: (any | null)[] | null;
  updatedAt_ne?: any | null;
  giftSourceName_nin?: (string | null)[] | null;
  _id_nin?: (any | null)[] | null;
  occasionId_gt?: any | null;
  occasionId_gte?: any | null;
  giftIdeaId_gte?: any | null;
  updatedAt_exists?: boolean | null;
  amount_gt?: number | null;
  occasionId_nin?: (any | null)[] | null;
  gifteeId?: any | null;
  giftIdeaId?: any | null;
  giftIdeaTitle_lte?: string | null;
  occasionId_lte?: any | null;
  amount_lt?: number | null;
  updatedAt_lt?: any | null;
  giftIdeaId_exists?: boolean | null;
  giftIdeaImage_exists?: boolean | null;
  userId_in?: (any | null)[] | null;
  giftIdeaImage_lt?: string | null;
  giftIdeaTitle_lt?: string | null;
  gifteeId_gt?: any | null;
}

export interface TransactionUpdateInput {
  giftIdeaTitle_unset?: boolean | null;
  _id?: any | null;
  giftIdeaImage_unset?: boolean | null;
  occasionId_unset?: boolean | null;
  userId_unset?: boolean | null;
  amount?: number | null;
  amount_unset?: boolean | null;
  giftIdeaImage?: string | null;
  userId?: any | null;
  amount_inc?: number | null;
  giftSourceName?: string | null;
  giftSourceName_unset?: boolean | null;
  gifteeId_unset?: boolean | null;
  giftIdeaId_unset?: boolean | null;
  gifteeId?: any | null;
  giftIdeaId?: any | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  giftIdeaTitle?: string | null;
  updatedAt_unset?: boolean | null;
  createdAt_unset?: boolean | null;
  occasionId?: any | null;
  _id_unset?: boolean | null;
}

export interface UserAddressInsertInput {
  zip?: string | null;
  city?: string | null;
  state?: string | null;
  street1?: string | null;
  street2?: string | null;
}

export interface UserAddressQueryInput {
  zip_gt?: string | null;
  street1_gt?: string | null;
  state_lt?: string | null;
  street1_nin?: (string | null)[] | null;
  zip?: string | null;
  street1_lt?: string | null;
  state?: string | null;
  state_in?: (string | null)[] | null;
  state_exists?: boolean | null;
  street1?: string | null;
  OR?: UserAddressQueryInput[] | null;
  zip_in?: (string | null)[] | null;
  state_gt?: string | null;
  zip_exists?: boolean | null;
  city_lt?: string | null;
  street2_lte?: string | null;
  street2_gte?: string | null;
  state_lte?: string | null;
  city_gt?: string | null;
  street1_exists?: boolean | null;
  city_exists?: boolean | null;
  street2_ne?: string | null;
  zip_lte?: string | null;
  city_nin?: (string | null)[] | null;
  street1_ne?: string | null;
  street1_gte?: string | null;
  street1_in?: (string | null)[] | null;
  state_gte?: string | null;
  zip_nin?: (string | null)[] | null;
  city?: string | null;
  city_gte?: string | null;
  street2_nin?: (string | null)[] | null;
  zip_gte?: string | null;
  street2?: string | null;
  city_ne?: string | null;
  street2_lt?: string | null;
  zip_ne?: string | null;
  city_lte?: string | null;
  street2_exists?: boolean | null;
  street2_gt?: string | null;
  city_in?: (string | null)[] | null;
  state_ne?: string | null;
  zip_lt?: string | null;
  AND?: UserAddressQueryInput[] | null;
  street1_lte?: string | null;
  street2_in?: (string | null)[] | null;
  state_nin?: (string | null)[] | null;
}

export interface UserAddressUpdateInput {
  city?: string | null;
  state?: string | null;
  street2?: string | null;
  state_unset?: boolean | null;
  street1?: string | null;
  street2_unset?: boolean | null;
  zip_unset?: boolean | null;
  city_unset?: boolean | null;
  zip?: string | null;
  street1_unset?: boolean | null;
}

export interface UserInsertInput {
  name?: string | null;
  gender?: string | null;
  firstName?: string | null;
  email?: string | null;
  _id?: any | null;
  address?: UserAddressInsertInput | null;
  createdAt?: any | null;
  lastLogin?: any | null;
  phone?: string | null;
  lastName?: string | null;
  picture?: string | null;
  birthday?: any | null;
  sentAbandonedOnboardingEmail?: boolean | null;
  authId?: string | null;
  updatedAt?: any | null;
  finishedOnboarding?: boolean | null;
}

export interface UserQueryInput {
  finishedOnboarding_ne?: boolean | null;
  gender_lt?: string | null;
  name_nin?: (string | null)[] | null;
  _id_lt?: any | null;
  picture?: string | null;
  updatedAt_gt?: any | null;
  birthday_lt?: any | null;
  lastLogin_gt?: any | null;
  phone_exists?: boolean | null;
  email_gt?: string | null;
  sentAbandonedOnboardingEmail_exists?: boolean | null;
  authId_lte?: string | null;
  lastName_gte?: string | null;
  gender_gte?: string | null;
  authId_ne?: string | null;
  firstName_lt?: string | null;
  phone?: string | null;
  _id_gte?: any | null;
  lastName_gt?: string | null;
  updatedAt_nin?: (any | null)[] | null;
  createdAt_in?: (any | null)[] | null;
  createdAt_exists?: boolean | null;
  email_gte?: string | null;
  AND?: UserQueryInput[] | null;
  phone_nin?: (string | null)[] | null;
  lastLogin_gte?: any | null;
  authId_nin?: (string | null)[] | null;
  lastLogin_in?: (any | null)[] | null;
  firstName_gt?: string | null;
  createdAt?: any | null;
  email_lt?: string | null;
  _id?: any | null;
  firstName_gte?: string | null;
  phone_ne?: string | null;
  updatedAt_ne?: any | null;
  birthday_ne?: any | null;
  updatedAt_gte?: any | null;
  _id_lte?: any | null;
  sentAbandonedOnboardingEmail?: boolean | null;
  gender_in?: (string | null)[] | null;
  authId_lt?: string | null;
  birthday_exists?: boolean | null;
  updatedAt_exists?: boolean | null;
  gender_ne?: string | null;
  name_exists?: boolean | null;
  birthday?: any | null;
  picture_lte?: string | null;
  _id_ne?: any | null;
  OR?: UserQueryInput[] | null;
  lastLogin_exists?: boolean | null;
  email?: string | null;
  lastName_in?: (string | null)[] | null;
  createdAt_nin?: (any | null)[] | null;
  address_exists?: boolean | null;
  createdAt_lte?: any | null;
  birthday_gt?: any | null;
  phone_lt?: string | null;
  phone_gte?: string | null;
  picture_lt?: string | null;
  authId_in?: (string | null)[] | null;
  firstName?: string | null;
  _id_gt?: any | null;
  picture_exists?: boolean | null;
  createdAt_gt?: any | null;
  lastLogin?: any | null;
  gender_gt?: string | null;
  finishedOnboarding_exists?: boolean | null;
  phone_lte?: string | null;
  _id_exists?: boolean | null;
  lastName_nin?: (string | null)[] | null;
  lastName_lt?: string | null;
  birthday_nin?: (any | null)[] | null;
  picture_gte?: string | null;
  name_ne?: string | null;
  email_exists?: boolean | null;
  picture_ne?: string | null;
  picture_in?: (string | null)[] | null;
  email_ne?: string | null;
  birthday_in?: (any | null)[] | null;
  birthday_gte?: any | null;
  gender_lte?: string | null;
  firstName_in?: (string | null)[] | null;
  lastLogin_lt?: any | null;
  sentAbandonedOnboardingEmail_ne?: boolean | null;
  createdAt_lt?: any | null;
  firstName_exists?: boolean | null;
  name_lte?: string | null;
  email_lte?: string | null;
  authId_gt?: string | null;
  authId_exists?: boolean | null;
  createdAt_gte?: any | null;
  name_gte?: string | null;
  address?: UserAddressQueryInput | null;
  email_in?: (string | null)[] | null;
  lastName_ne?: string | null;
  firstName_lte?: string | null;
  _id_nin?: (any | null)[] | null;
  phone_gt?: string | null;
  lastLogin_ne?: any | null;
  gender?: string | null;
  name_gt?: string | null;
  _id_in?: (any | null)[] | null;
  lastLogin_nin?: (any | null)[] | null;
  email_nin?: (string | null)[] | null;
  lastName_lte?: string | null;
  updatedAt_in?: (any | null)[] | null;
  phone_in?: (string | null)[] | null;
  firstName_nin?: (string | null)[] | null;
  picture_nin?: (string | null)[] | null;
  name_in?: (string | null)[] | null;
  updatedAt_lt?: any | null;
  updatedAt?: any | null;
  createdAt_ne?: any | null;
  birthday_lte?: any | null;
  authId?: string | null;
  authId_gte?: string | null;
  lastName?: string | null;
  lastName_exists?: boolean | null;
  lastLogin_lte?: any | null;
  gender_nin?: (string | null)[] | null;
  gender_exists?: boolean | null;
  updatedAt_lte?: any | null;
  name_lt?: string | null;
  firstName_ne?: string | null;
  finishedOnboarding?: boolean | null;
  name?: string | null;
  picture_gt?: string | null;
}

export interface UserUpdateInput {
  _id_unset?: boolean | null;
  email_unset?: boolean | null;
  address_unset?: boolean | null;
  updatedAt?: any | null;
  sentAbandonedOnboardingEmail_unset?: boolean | null;
  gender_unset?: boolean | null;
  authId_unset?: boolean | null;
  updatedAt_unset?: boolean | null;
  birthday_unset?: boolean | null;
  picture_unset?: boolean | null;
  createdAt?: any | null;
  picture?: string | null;
  phone?: string | null;
  sentAbandonedOnboardingEmail?: boolean | null;
  name_unset?: boolean | null;
  address?: UserAddressUpdateInput | null;
  firstName?: string | null;
  birthday?: any | null;
  lastName?: string | null;
  email?: string | null;
  firstName_unset?: boolean | null;
  _id?: any | null;
  createdAt_unset?: boolean | null;
  phone_unset?: boolean | null;
  lastName_unset?: boolean | null;
  gender?: string | null;
  name?: string | null;
  authId?: string | null;
  finishedOnboarding_unset?: boolean | null;
  lastLogin_unset?: boolean | null;
  finishedOnboarding?: boolean | null;
  lastLogin?: any | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
