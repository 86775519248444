import React, { FC, useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import useTheme from "@material-ui/core/styles/useTheme";
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
// import { Meteor } from 'meteor/meteor';
import { makeStyles, Theme } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import PrimaryButton from "../general-ui/buttons/PrimaryButton";
import { useDispatch } from "react-redux";
import { setShowImportGifteeWizard, selectUser } from "../../redux/add-giftee/actions";
import { closeNotificationsMenu } from "../../redux/general/actions";
import { getNotifications_notifications } from "../../graphql/queries/notifications/__generated__/getNotifications";
import { updateGifteeInCache } from "../../graphql/update-cache/giftees";
import { useMutation } from "@apollo/client/react/hooks/useMutation";
import {
  InsertOneGifteeMutation,
  InsertOneGifteeMutationVariables,
} from "../../graphql/mutations/giftees/__generated__/InsertOneGifteeMutation";
import { insertOneGiftee } from "../../graphql/mutations/giftees";

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    height: 60,
    width: 60,
    marginRight: 10,
  },
  notificationWrapper: {
    marginBottom: 10,
  },
  notification: {
    height: "100px",
    padding: "0px",
    paddingLeft: "10px",
    boxShadow: theme.shadows[2],
    "& .MuiListItemText-primary": {
      fontSize: 14,
    },
  },
  notificationButtons: {
    height: "100%",
  },
  notificationButtonAdd: {
    height: "100%",
    width: "100px",
    borderRadius: "0px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    "&:hover": {
      background: theme.palette.error.dark,
    },
  },
}));

type Props = {
  notification: getNotifications_notifications;
  timeAgo: string;
};

const NotificationUserInviteAccepted: FC<Props> = ({ notification, timeAgo }) => {
  const { invitee } = notification;
  const theme = useTheme();
  const [openExpansion, setOpenExpansion] = useState(false);
  const [addGiftee] = useMutation<InsertOneGifteeMutation, InsertOneGifteeMutationVariables>(insertOneGiftee);
  const dispatch = useDispatch();

  const toggleExpansion = () => {
    setOpenExpansion(!openExpansion);
  };

  const importGiftee = async () => {
    dispatch({ ...closeNotificationsMenu() });
    if (invitee) {
      await addGiftee({
        variables: {
          data: {
            name: invitee?.name ?? invitee?.email,
            userId: notification.userId,
            createdAt: new Date(),
            isPendingAcceptance: false,
            picture: invitee?.picture,
            sourceUser: {
              link: invitee?._id,
            },
          },
        },
        update: (cache, data) => {
          updateGifteeInCache(cache, notification.userId, data);
        },
      });
      dispatch({ ...selectUser(invitee) });
    }
    dispatch({ ...setShowImportGifteeWizard(true) });
  };

  const classes = useStyles();

  const inviteeName = invitee?.name ?? invitee?.email;

  const userAvatar = invitee?.picture ? (
    <ListItemAvatar>
      <Avatar src={invitee?.picture} className={classes.avatar} />
    </ListItemAvatar>
  ) : (
    <ListItemAvatar>
      <Avatar className={classes.avatar}>{inviteeName?.charAt(0)}</Avatar>
    </ListItemAvatar>
  );

  return (
    <div className={classes.notificationWrapper}>
      <ListItem button key={notification._id} className={classes.notification} onClick={toggleExpansion}>
        {userAvatar}
        <ListItemText secondary={timeAgo}>
          <span style={{ color: theme.palette.text.primaryBranded }}>{inviteeName}</span> has accepted your invite to
          Occasional.ly! You can now add them as a giftee.
        </ListItemText>
        {openExpansion ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openExpansion} timeout="auto" unmountOnExit>
        <ListItem className={classes.notificationButtons} disableGutters>
          <PrimaryButton onClick={importGiftee}>Add as Giftee</PrimaryButton>
        </ListItem>
      </Collapse>
    </div>
  );
};

export default NotificationUserInviteAccepted;
