import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import { useMutation } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Giftee as GifteeType } from '../../../@types/giftee';
import { Occasion } from '../../../@types/occasions';
import { NotificationType, OccasionType } from '../../../__generated__/globalTypes';
import { insertOneNotification } from '../../../graphql/mutations/notifications';
import {
  InsertOneNotificationMutation, InsertOneNotificationMutationVariables
} from '../../../graphql/mutations/notifications/__generated__/InsertOneNotificationMutation';
import { updateOneUser } from '../../../graphql/mutations/users';
import {
  UpdateOneUserMutation, UpdateOneUserMutationVariables
} from '../../../graphql/mutations/users/__generated__/UpdateOneUserMutation';
import useAddOccasion from '../../../hooks/useAddOccasion';
import {
  setConfirmed, setOccasionDate, setOccasionName, setOccasionType, setUsersToNotify
} from '../../../redux/new-occasion/actions';
import { NewOccasionRootState } from '../../../redux/new-occasion/reducers';
import { RootState } from '../../../redux/store';
import { months } from '../../../utils/constants/dateConstants';
import getNextOccasionDate from '../../../utils/occasionDates';
import PrimaryButton from '../../general-ui/buttons/PrimaryButton';
import SecondaryButton from '../../general-ui/buttons/SecondaryButton';
import occasionIcons from '../../occasions/occasionIcons';
import WizardStep from '../general-wizard-ui/WizardStep';
import { NewOccasionWizardSteps } from '../NewOccasionWizard';

const useStyles = makeStyles((theme: Theme) => ({
  selectOccasionContent: {
    height: "400px",
  },
  occasionDisplay: {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  occasionIcon: {
    width: "40%",
    display: "flex",
    justifyContent: "center",
    "& .lnr": {
      fontSize: 65,
      color: theme.palette.primary.main,
    },
  },
  confirmContent: {
    textAlign: "center",
  },
  actionButtons: {
    width: "100%",
    height: 100,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
  },
}));

type Props = { isUser: boolean; userId?: string; giftee?: GifteeType } & Partial<StepWizardChildProps>;

const getDateEnding = (i: number) => {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
};

const ConfirmOccasion: FC<Props> = ({ isUser, userId, giftee, goToStep, previousStep }) => {
  let refetchVariables: Record<string, any>;
  if (isUser) {
    refetchVariables = {
      userId,
    };
  } else {
    refetchVariables = {
      giftee: {
        _id: giftee?._id,
      },
    };
  }

  const [addOccasion] = useAddOccasion(refetchVariables);
  const [updateUser] = useMutation<UpdateOneUserMutation, UpdateOneUserMutationVariables>(updateOneUser);
  const [insertNotification] = useMutation<InsertOneNotificationMutation, InsertOneNotificationMutationVariables>(
    insertOneNotification
  );
  const classes = useStyles();

  const dispatch = useDispatch();
  const { occasionName, occasionType, occasionDate, usersToNotify } = useSelector<RootState, NewOccasionRootState>(
    (state) => {
      return state.newOccasion;
    }
  );

  const { day: date, month } = occasionDate;

  const handleGoToStep = (step: NewOccasionWizardSteps) => {
    if (goToStep) {
      goToStep(step);
    }
  };

  const handlePreviousStep = () => {
    if (previousStep) {
      previousStep();
    }
  };

  const confirmOccasionHandler = () => {
    confirmOccasion();
    handleGoToStep(NewOccasionWizardSteps.OccasionConfirmed);
  };

  const customDateOccasion =
    occasionType === OccasionType.BIRTHDAY ||
    occasionType === OccasionType.WEDDING ||
    occasionType === OccasionType.ANNIVERSARY ||
    occasionType === OccasionType.CUSTOM;

  const occasionIconClass = occasionIcons(occasionType !== "" ? occasionType : OccasionType.BIRTHDAY);
  const occasionIcon = <span className={occasionIconClass}></span>;

  const confirmOccasion = async () => {
    const nextOccasionDate = getNextOccasionDate(occasionType as Occasion["type"], occasionDate);
    if (occasionType != "") {
      if (!isUser && giftee) {
        await addOccasion({
          annual: true,
          name: occasionName,
          type: occasionType,
          createdAt: new Date(),
          nextOccasionDate: new Date(nextOccasionDate ?? ""),
          dayMonthDate: occasionDate,
          giftee: {
            link: giftee._id,
          },
        });
        dispatch({ ...setConfirmed(true) });
        dispatch({ ...setOccasionName("") });
        dispatch({ ...setOccasionType("") });
        dispatch({ ...setOccasionDate({ day: 0, month: 0 }) });
        dispatch({ ...setUsersToNotify([]) });
      }
      //Different logic to handle when user is adding occasions for their own profile, don't add reminders
      else {
        const occasionAddResult = await addOccasion({
          annual: true,
          name: occasionName,
          type: occasionType,
          createdAt: new Date(),
          nextOccasionDate: new Date(nextOccasionDate ?? ""),
          dayMonthDate: occasionDate,
          userId,
        });
        if (userId && occasionType === OccasionType.BIRTHDAY) {
          updateUser({
            variables: {
              query: {
                _id: userId,
              },
              set: {
                birthday: new Date(nextOccasionDate ?? ""),
              },
            },
          });
        }
        if (usersToNotify.length > 0 && occasionAddResult?.insertOneOccasion?._id) {
          for (let i = 0; i < usersToNotify.length; i++) {
            const userToNotifyId = usersToNotify[i];
            insertNotification({
              variables: {
                data: {
                  requester: {
                    link: userId,
                  },
                  occasion: {
                    link: occasionAddResult.insertOneOccasion._id,
                  },
                  userId: userToNotifyId,
                  createdAt: new Date(),
                  type: NotificationType.GIFTEEOCCASIONADDED,
                  new: true,
                },
              },
            });
          }
        }
      }
      dispatch({ ...setConfirmed(true) });
      dispatch({ ...setOccasionName("") });
      dispatch({ ...setOccasionType("") });
      dispatch({ ...setOccasionDate({ day: 0, month: 0 }) });
      dispatch({ ...setUsersToNotify([]) });
    }
  };

  const handleGoBack = useCallback(() => {
    if (isUser && usersToNotify.length > 0) {
      handleGoToStep(NewOccasionWizardSteps.SelectUsersToNotify);
    } else {
      handleGoToStep(
        customDateOccasion ? NewOccasionWizardSteps.SelectOccasionDate : NewOccasionWizardSteps.SelectOccasionType
      );
    }
  }, [isUser, usersToNotify]);

  return (
    <WizardStep>
      <div className={classes.occasionDisplay}>
        <div className={classes.occasionIcon}>{occasionIcon}</div>
        <Typography variant="h6" className={classes.confirmContent}>
          Occasion: {occasionName}
        </Typography>
        {date ? (
          <div>
            <Typography variant="h6" className={classes.confirmContent}>
              {months[month ?? 0] + ", " + getDateEnding(date)}
            </Typography>
          </div>
        ) : (
          ""
        )}
        <Typography variant="h5" className={classes.confirmContent}>
          Does everything look good? Go ahead and confirm!
        </Typography>
      </div>
      <div className={classes.actionButtons}>
        <PrimaryButton onClick={confirmOccasionHandler}>Confirm Occasion</PrimaryButton>
        <br></br>
        <SecondaryButton onClick={handleGoBack}>
          <span className="lnr lnr-arrow-left"></span>
          &nbsp;Go Back
        </SecondaryButton>
      </div>
    </WizardStep>
  );
};

export default ConfirmOccasion;
