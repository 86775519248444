import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Switch from "@material-ui/core/Switch";
import React, { FC } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useUpdateNotificationSetting from "../../hooks/useUpdateNotificationSetting";

const useStyles = makeStyles((theme) => ({
  notificationSetting: {
    background: theme.palette.background.paper,
  },
  notificationIcon: {
    color: theme.palette.text.primary,
  },
}));

type NotificationType = {
  type: string;
  enabled: boolean;
};

type Props = {
  notificationType: NotificationType;
  notificationTypes: NotificationType[];
};

const PushNotificationSetting: FC<Props> = ({ notificationType, notificationTypes }) => {
  const classes = useStyles();
  const [updatePushSetting] = useUpdateNotificationSetting(notificationTypes, "push");

  return (
    <ListItem className={classes.notificationSetting}>
      <ListItemIcon className={classes.notificationIcon}>
        <span className="lnr lnr-smartphone-notification"></span>
      </ListItemIcon>
      <ListItemText>Push Notifications</ListItemText>
      <ListItemSecondaryAction>
        <Switch
          checked={notificationType.enabled}
          onChange={updatePushSetting}
          name="Push notification toggle"
          inputProps={{ "aria-label": "push notification toggle" }}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default PushNotificationSetting;
