import React, { FC } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { OnboardingWizardSteps } from '../OnboardingWizard';
import OnboardingStep from './OnboardingStep';

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    background: theme.palette.background.background,
    color: theme.palette.text.primary,
  },
  occasionSelectWrapper: {
    height: "100%",
  },
  occasionSelectHeader: {
    background: "url('/images/iso-gift-town-small.png')",
    backgroundSize: "100%",
    backgroundPosition: "top",
    backgroundColor: theme.palette.primary.transparent,
    borderRadius: theme.shape.borderRadius,
    height: 270,
    maxWidth: 600,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "180vw",
    },
  },
  crazyButton: {
    background: "linear-gradient(90deg, #E2207B, #C68A77)",
    border: `2px solid ${theme.palette.primary.main}`,
  },
  mainImage: {
    width: 48,
    objectFit: "contain",
    marginBottom: theme.spacing(2),
  },
  header: {
    color: theme.palette.primary.main,
  },
}));

const OnboardingConfirmation: FC<Partial<StepWizardChildProps>> = ({ previousStep, currentStep }) => {
  const classes = useStyles();

  return (
    <OnboardingStep step={currentStep ?? OnboardingWizardSteps.Introduction} onPrevious={previousStep}>
      <Grid
        container
        wrap="nowrap"
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        className={classes.occasionSelectWrapper}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          wrap="nowrap"
          className={classes.form}
        >
          <Grid
            container
            className={classes.occasionSelectHeader}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
          ></Grid>
          <img src="/images/logos/contrast-logo.png" className={classes.mainImage} />
          <Typography variant="h4">You're Ready!</Typography>
          <Typography variant="subtitle1" align="center">
            Excellent! You are ready to start using Occasional.ly! Click the button below to continue.
          </Typography>
        </Grid>
      </Grid>
    </OnboardingStep>
  );
};

export default OnboardingConfirmation;
