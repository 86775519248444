import { useMemo } from "react";
import { AddGifteeRootState } from "../../../redux/add-giftee/reducers";

export type WizardNavProps = {
  disabled?: boolean;
  hideNav?: boolean;
  hideBack?: boolean;
  buttonText: string;
};

export const useWizardNavProps = (
  step: number,
  wizardState: AddGifteeRootState,
  stepCallback: (step: number, wizardState: AddGifteeRootState) => WizardNavProps
) => {
  return stepCallback(step, wizardState);
};

export const useWizardHandleBack = (step: number, stepCallback: (step: number) => () => void | undefined) => {
  return stepCallback(step);
};
export const useWizardHandleNext = (
  step: number,
  stepCallback: (step: number, defaultNext: () => void) => (callback?: () => void) => void,
  defaultNext: () => void
) => {
  return useMemo(() => {
    return stepCallback(step, defaultNext);
  }, [step]);
};
