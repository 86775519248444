import classNames from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import {
  openGiftIdeaDialog, openMiniGiftIdeaDialog, setActiveGiftIdea
} from '../../redux/giftideas/actions';
import GifteeMatchChip from './GifteeMatchChip';
import GifteeMatchChipContainer from './GifteeMatchChipContainer';
import RetailerLogo from './RetailerLogo';
import { GiftIdea as GiftIdeaType } from './types';
import { formatSimilarity, getMatchGradient } from './utils';

const giftIdeaDesktopSize = 150;
const giftIdeaMobileSize = 120;

const useStyles = makeStyles((theme: Theme) => ({
  giftIdeaCard: {
    borderRadius: 20,
    overflow: "hidden",
    boxShadow: "none",
    margin: "30px 20px",
    zIndex: 1,
    color: theme.palette.text.primary,
    background: "none",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      margin: "20px 00px",
    },
  },
  giftIdeaGrid: {
    width: giftIdeaDesktopSize,
    [theme.breakpoints.down("sm")]: {
      width: giftIdeaMobileSize,
    },
  },
  giftIdeaCardSelected: {
    overflow: "visible",
    boxShadow: "none",
    position: "relative",
    "&:after": {
      color: theme.palette.common.white,
      position: "absolute",
      top: -15,
      right: -15,
      height: 30,
      width: 30,
      background: theme.palette.primary.main,
      content: "'\\e934'",
      fontFamily: "Linearicons",
      borderRadius: "50%",
      boxShadow: theme.shadows[2],
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  productImage: {
    width: giftIdeaDesktopSize,
    height: giftIdeaDesktopSize,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    overflow: "hidden",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    [theme.breakpoints.down("sm")]: {
      width: giftIdeaMobileSize,
      height: giftIdeaMobileSize,
    },
  },
  productImageWrapper: {
    position: "relative",
    marginBottom: theme.spacing(0.5),
  },
  productContent: {
    padding: "0px 4px",
    color: theme.palette.text.primary,
  },
  giftProviderLogoContainer: {
    width: "40%",
    marginLeft: theme.spacing(1),
    height: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.background.background,
    borderRadius: 4,
    overflow: "hidden",
  },
  productTitle: {
    height: 62,
    whiteSpace: "initial",
    overflow: "hidden",
  },
  productMatch: {
    fontWeight: 600,
  },
  gifteeChipContainer: {
    position: "absolute",
    right: 0,
    bottom: -5,
  },
  giftIdeaPrice: {
    marginRight: theme.spacing(0.5),
  },
}));

type Props = {
  mode?: "grid" | "list";
  disabled?: boolean;
  index: number;
  intersected?: (i: number) => void;
  multiSelect?: boolean;
  product: GiftIdeaType;
  selected?: boolean;
  showPrice?: boolean;
  showLogo?: boolean;
};

const GiftIdea: FC<Props> = ({
  product,
  multiSelect,
  index,
  intersected,
  disabled,
  selected,
  mode = "grid",
  showPrice = true,
  showLogo = true,
}) => {
  const similarityNumber = product?.similarity ?? 0;
  const matchGradient = getMatchGradient(similarityNumber);
  const similarityFormatted = formatSimilarity(similarityNumber);
  const observer = useRef<IntersectionObserver>();
  const productImageEl = useRef<HTMLDivElement>(null);

  const [intersectedFeed, setIntersectedFeed] = useState(false);
  const [broken, setBroken] = useState(false);

  useEffect(() => {
    if (intersected && productImageEl.current) {
      const lazyLoadCallback: IntersectionObserverCallback = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          const { isIntersecting } = entry;
          if (isIntersecting) {
            setIntersectedFeed(true);
            observer.current?.disconnect();
            intersected(index);
          }
        });
      };

      const options = {
        rootMargin: "0px",
        threshold: 0.5,
      };

      observer.current = new IntersectionObserver(lazyLoadCallback, options);
      observer.current.observe(productImageEl.current as Element);
    } else {
      setIntersectedFeed(true);
    }
  }, [intersected, productImageEl.current]);

  const dispatch = useDispatch();

  const classes = useStyles();

  const selectGifteeIdea = () => {
    dispatch({ ...setActiveGiftIdea(product as GiftIdeaType) });
    dispatch({ ...openGiftIdeaDialog() });
  };

  const multiSelectGiftIdea = () => {
    dispatch({ ...setActiveGiftIdea(product as GiftIdeaType) });
    dispatch({ ...openMiniGiftIdeaDialog() });
  };

  const cardClasses = classNames({
    [classes.giftIdeaCard]: true,
    [classes.giftIdeaGrid]: mode === "grid",
    [classes.giftIdeaCardSelected]: selected,
  });
  const price = parseFloat(product.price?.toString() ?? "0").toFixed(2);

  if (broken) {
    return null;
  }

  return mode === "grid" ? (
    <Card className={cardClasses}>
      <CardActionArea onClick={!multiSelect ? selectGifteeIdea : multiSelectGiftIdea} disabled={disabled}>
        <div className={classes.productImageWrapper}>
          <CardMedia ref={productImageEl} classes={{ root: classes.productImage }}>
            {intersectedFeed ? (
              <img src={product.image ?? undefined} onError={() => setBroken(true)} />
            ) : (
              <CircularProgress />
            )}
          </CardMedia>
          {!!product.giftees ? (
            <GifteeMatchChipContainer
              giftees={product.giftees}
              similarity={similarityNumber}
              match={matchGradient}
              similarityFormatted={similarityFormatted}
            />
          ) : null}
        </div>
        <CardContent className={classes.productContent}>
          <Typography variant="subtitle2" className={classes.productTitle}>
            {product.title}
          </Typography>
          {!!product?.similarity && !product.giftees ? (
            <Typography variant="subtitle2" className={classes.productMatch} style={{ color: matchGradient }}>
              {similarityFormatted}
            </Typography>
          ) : null}
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" wrap="nowrap">
            {showPrice && <p className={classes.giftIdeaPrice}>${price}</p>}
            {showLogo && (
              <div className={classes.giftProviderLogoContainer}>
                <RetailerLogo provider={product?.giftProvider ?? undefined} />
              </div>
            )}
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  ) : (
    <Card className={cardClasses}>
      <CardActionArea onClick={!multiSelect ? selectGifteeIdea : multiSelectGiftIdea} disabled={disabled}>
        <Grid container wrap="nowrap">
          <div className={classes.productImageWrapper}>
            <CardMedia ref={productImageEl} classes={{ root: classes.productImage }}>
              {intersectedFeed ? (
                <img src={product.image ?? undefined} onError={() => setBroken(true)} />
              ) : (
                <CircularProgress />
              )}
            </CardMedia>
            {!!product.giftees ? (
              <div className={classes.gifteeChipContainer}>
                {product.giftees.map((giftee) => (
                  <GifteeMatchChip
                    gifteeName={giftee.name ?? ""}
                    similarity={similarityNumber}
                    matchGradient={matchGradient}
                    similarityFormatted={similarityFormatted}
                  />
                ))}
              </div>
            ) : null}
          </div>
          <CardContent className={classes.productContent}>
            <Typography variant="subtitle2" className={classes.productTitle}>
              {product.title}
            </Typography>
            {!!product?.similarity && (
              <Typography variant="subtitle2" className={classes.productMatch} style={{ color: matchGradient }}>
                {similarityFormatted}
              </Typography>
            )}
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" wrap="nowrap">
              <p className={classes.giftIdeaPrice}>${price}</p>
              <RetailerLogo provider={product?.giftProvider ?? undefined} />
            </Grid>
          </CardContent>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

export default GiftIdea;
