import { OccasionType } from "../../__generated__/globalTypes";

export default function getOccasionNameFromType(occasionType: OccasionType) {
  switch (occasionType) {
    case OccasionType.BIRTHDAY:
      return "Birthday";

    case OccasionType.VALENTINES:
      return "Valentine's Day";

    case OccasionType.EASTER:
      return "Easter";

    case OccasionType.CHRISTMAS:
      return "Christmas";

    case OccasionType.CUSTOM:
      return "Custom";

    case OccasionType.MOTHERSDAY:
      return "Mother's Day";

    case OccasionType.FATHERSDAY:
      return "Father's Day";

    case OccasionType.ANNIVERSARY:
      return "Anniversary";

    case OccasionType.WEDDING:
      return "Wedding";

    case OccasionType.HANUKKAH:
      return "Hannukah";

    case OccasionType.KWANZAA:
      return "Kwanzaa";

    default:
      return "Special Occasion";
  }
}
