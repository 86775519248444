/* TypeScript file generated from GiftIdeaModal.res by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as GiftIdeaModalBS__Es6Import from './GiftIdeaModal.bs';
const GiftIdeaModalBS: any = GiftIdeaModalBS__Es6Import;

// tslint:disable-next-line:interface-over-type-literal
export type Props = {};

export const make: React.ComponentType<{}> = GiftIdeaModalBS.make;
