import gql from 'graphql-tag';

export const getGiftProfileQuery = gql`
  query getGiftProfile($query: GiftProfileQueryInput) {
    giftProfile(query: $query) {
      _id
      profile {
        title
      }
      recommendationsByCategory {
        recommendations {
          _id
          image
          price
          similarity
          title
          giftProvider {
            logo
          }
        }
        category
      }
      purchases {
        giftIdeaId
        transactionId
        date
        targetYear
      }
      userId
      gifteeId
      interests
      gender
    }
  }
`;
export const getGiftProfilesQuery = gql`
  query getGiftProfiles($query: GiftProfileQueryInput) {
    giftProfiles(query: $query) {
      _id
      profile {
        title
      }
      recommendations {
        _id
        title
        image
        url
        price
        avgReview
        giftSource
        similarity
        description
        interest
        associatedProducts {
          title
          image
          url
        }
        giftProvider {
          logo
          name
        }
      }
      purchases {
        giftIdeaId
        transactionId
        date
        targetYear
      }
      userId
      gifteeId
      interests
    }
  }
`;
