import { Badge } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tab from "@material-ui/core/Tab/Tab";
import clsx from "clsx";
import React from "react";
import { FC } from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  tabWrapper: {
    position: "relative",
  },
  root: {
    display: "flex",
    height: 40,
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    "& .lnr": {
      fontSize: 22,
    },
    "& .mobile-tab-label": {
      overflow: "hidden",
      position: "absolute",
      bottom: 4,
    },
    transition: theme.transitions.create("transform"),
  },
  selected: {
    transform: "translateY(-8px)",
    color: theme.palette.text.primaryBrandedHint,
    "& .mobile-tab-label": {
      width: "inherit",
    },
  },
  label: {
    opacity: 0,
    color: theme.palette.text.primaryBrandedHint,
    fontSize: 12,
    position: "absolute",
    bottom: "0",
    width: "100%",
    textAlign: "center",
  },
  labelActive: {
    opacity: 1,
  },
  badge: {
    position: "absolute",
    top: 20,
    right: 22,
  },
}));

type TabProps = {
  to?: string;
  onClick?: () => void;
  label?: React.ReactElement;
  icon: React.ReactElement;
  isActive?: boolean;
  badge?: React.ReactNode;
};

const MobileTab: FC<TabProps> = ({ badge, to, label, icon, isActive, onClick }) => {
  const classes = useStyles();

  const tabClass = clsx({
    [classes.selected]: isActive,
    [classes.root]: true,
  });
  const labelClass = clsx({
    [classes.labelActive]: isActive,
    [classes.label]: true,
  });
  return (
    <div className={classes.tabWrapper}>
      {to ? (
        <Tab
          label={badge ? <Badge className={classes.badge} badgeContent={badge} color="primary" /> : undefined}
          disableRipple
          classes={{ root: tabClass }}
          component={Link}
          to={to}
          icon={icon}
        />
      ) : (
        <Tab
          label={badge ? <Badge className={classes.badge} badgeContent={badge} color="primary" /> : undefined}
          onClick={onClick}
          to={window.location.pathname}
          disableRipple
          classes={{ root: tabClass }}
          component={Link}
          icon={icon}
        />
      )}
      <div className={labelClass}>{label}</div>
    </div>
  );
};

export default MobileTab;
