import firebase from "firebase";

export const initializeFirebase = (registration: ServiceWorkerRegistration) => {
  //Firebase setup

  // Your web app's Firebase configuration
  var firebaseConfig = {
    apiKey: "AIzaSyCQ-4lpa37nWgT5VtrTP4Rn1JLHl1f5Xao",
    authDomain: "occasionally-1543965533123.firebaseapp.com",
    databaseURL: "https://occasionally-1543965533123.firebaseio.com",
    projectId: "occasionally-1543965533123",
    storageBucket: "occasionally-1543965533123.appspot.com",
    messagingSenderId: "1063401301135",
    appId: "1:1063401301135:web:604cb0cfaaba2c041c7cfa",
    measurementId: "G-MKN81ZCV2L",
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  // Push event listener aux function:

  // const messaging = firebase.messaging();

  // When to Show Notifications:
  // If the user is already using your application there is no need to display a
  // notification. You can manage this logic on the server, but it is easier to
  // do it in the push handler inside your service worker:
  // the 'clients' global in the service worker lists all of the active push
  // clients on this machine. If there are no clients active, the user must be
  // in another app. We should show a notification in this case. If there are
  // active clients it means that the user has your site open in one or more
  // windows. The best practice is to relay the message to each of those windows.
  // Source: https://developers.google.com/web/ilt/pwa/introduction-to-push-notifications
  // Source: https://developers.google.com/web/fundamentals/codelabs/push-notifications/
  // messaging.onMessage((evt) => {
  //   console.log("[Service Worker] Push Received.");
  //   console.log("[Service Worker] Push had this data:");
  //   console.log(evt.data.text());

  //   const pushJson = evt.data.json().data;
  //   console.log("pushJson", pushJson);
  // });
};

export const askPermission = (): Promise<void | "default" | "denied" | "granted"> => {
  return new Promise(function (resolve, reject) {
    const permissionResult = Notification.requestPermission(function (result) {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  }).then(function (permissionResult) {
    if (permissionResult !== "granted") {
      throw new Error("We weren't granted permission.");
    }
  });
};

export const askForPermissionToReceiveNotifications = async (
  registration?: ServiceWorkerRegistration
): Promise<string | undefined> => {
  try {
    const messaging = firebase.messaging();
    await askPermission();
    const token = await messaging.getToken({
      vapidKey: process.env.FCM_VAPID_KEY,
      serviceWorkerRegistration: registration,
    });
    return token;
  } catch (error) {
    console.error(error);
  }
};

export const showLocalNotification = (title: string, body: string, swRegistration: ServiceWorkerRegistration) => {
  const options = {
    body,
    // here you can add more properties like icon, image, vibrate, etc.
  };
  swRegistration.showNotification(title, options);
};
