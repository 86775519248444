import React, { FC } from "react";
import Modal from "../general-ui/modals/Modal";
import PolicyContainer from "./PolicyContainer";

type Props = { open: boolean; onClose: () => void };

const PrivacyPolicyDialog: FC<Props> = ({ open, onClose }) => {
  return (
    <Modal header="Privacy Policy" title="Privacy Policy" showModal={open} closeModal={onClose}>
      <PolicyContainer policyUrl="https://app.termly.io/document/privacy-policy/56af6dce-33de-4379-92ce-c022b1600479" />
    </Modal>
  );
};

export default PrivacyPolicyDialog;
