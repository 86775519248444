import gql from "graphql-tag";

export const getGifteeQuery = gql`
  query getGiftee($query: GifteeQueryInput) {
    giftee(query: $query) {
      _id
      age
      createdAt
      isCustom
      isPendingAcceptance
      interests
      name
      picture
      relationship
      status
      sourceUser {
        _id
      }
      userId
    }
  }
`;
export const getGifteesQuery = gql`
  query getGiftees($query: GifteeQueryInput) {
    giftees(query: $query) {
      _id
      age
      createdAt
      isCustom
      isPendingAcceptance
      interests
      name
      picture
      relationship
      status
      sourceUser {
        _id
      }
      userId
    }
  }
`;
