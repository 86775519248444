import classes from "*.module.css";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography/Typography";
import React, { FC } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import useIsMobile from "../../../hooks/useIsMobile";
import useIsXsMobile from "../../../hooks/useIsXsMobile";
import { OnboardingWizardSteps } from "../OnboardingWizard";

import OnboardingStep from "./OnboardingStep";
const useStyles = makeStyles((theme) => ({
  intro: {
    position: "relative",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    maxWidth: 600,
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end",
      justifyContent: "flex-start",
      paddingTop: "40vh",
      "&:before": {
        width: "235vw",
        height: "100vh",
        bottom: "30vh",
        right: -16,
        backgroundSize: "cover",
        backgroundImage: 'url("/images/iso-gift-town.png")',
        zIndex: 0,
        content: '""',
        position: "absolute",
      },
    },
    "@media(max-height: 460px)": {
      "&:before": {
        display: "none",
      },
    },
  },
  headline: {
    [theme.breakpoints.down("xs")]: {},
    marginBottom: theme.spacing(3),
  },
  mainImage: {
    width: "50%",
    maxWidth: 225,
    objectFit: "contain",
    margin: "30px 0px",
  },
  subtitle: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

const Introduction: FC<Partial<StepWizardChildProps>> = ({ nextStep, currentStep }) => {
  const classes = useStyles();
  const isMobile = useIsXsMobile();
  return (
    <OnboardingStep step={currentStep ?? OnboardingWizardSteps.Introduction} onNext={nextStep}>
      <div className={classes.intro} id="intro">
        {!isMobile && <img className={classes.mainImage} src="/images/gift-party.png" />}
        <Typography variant="h4" align={isMobile ? "right" : "center"} className={classes.headline}>
          Welcome to Occasional.ly!
        </Typography>
        <Typography className={classes.subtitle} variant="h6" align={isMobile ? "right" : "center"}>
          Before you enjoy how gloriously easy gift giving can really be, we want to help you get set up
        </Typography>
      </div>
    </OnboardingStep>
  );
};

export default Introduction;
