import React, { FC, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { MongoDBRealmError } from 'realm-web';

import { useMutation } from '@apollo/client';
import { CircularProgress, IconButton, Snackbar } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { AuthActionTypes } from '../../@types/auth';
import { useAuthContext } from '../../auth/authContext';
import { insertOneUser } from '../../graphql/mutations/users';
import {
  InsertOneUserMutation, InsertOneUserMutationVariables
} from '../../graphql/mutations/users/__generated__/InsertOneUserMutation';
import useAnalyticsEvent from '../../hooks/analytics/useAnalyticsEvent';
import useSearchParams from '../../hooks/useSearchParams';
import app from '../../realm';
import { loginWithEmailAndPass, signupWithEmailAndPass } from '../../realm/auth';
import { validateEmail } from '../../utils/helpers/stringHelper';
import GoogleLoginButton from '../general-ui/buttons/GoogleLoginButton';
import PrimaryButton from '../general-ui/buttons/PrimaryButton';
import SecondaryButton from '../general-ui/buttons/SecondaryButton';
import OutlinedInput from '../general-ui/inputs/OutlinedInput';
import PasswordInput from '../general-ui/inputs/PasswordInput';
import AcceptTermsCheckbox from './AcceptTermsCheckbox';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 10,
  },
  inputWrapper: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  submitButton: {
    padding: 0,
    fontSize: "1rem",
    marginTop: 10,
    "& button": {
      padding: "15px 13px",
    },
    "& .MuiCircularProgress-root": {
      color: theme.palette.text.primary,
    },
  },
  orDivider: {
    color: theme.palette.text.primary,
    fontWeight: 900,
    fontSize: 16,
    margin: "10px 0px",
  },
}));

const SignupForm: FC = () => {
  const classes = useStyles();

  const searchParams = useSearchParams();
  const invite = searchParams.get("invite");
  useEffect(() => {
    if (invite) {
      localStorage.setItem("occ-invite", invite);
    }
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [toHome, setToHome] = useState(false);
  const [formError, setFormError] = useState(false);
  const [errorLog, setErrorLog] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [acceptPolicies, setAcceptPolicies] = useState(false);

  const fireGoogleAnalytics = useAnalyticsEvent("users", "create", "Signup Form");

  const [insertUser] = useMutation<InsertOneUserMutation, InsertOneUserMutationVariables>(insertOneUser);

  const { authDispatch } = useAuthContext();

  const handlePolicyAcceptChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setAcceptPolicies(checked);
  };

  const signupFormChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const etarget = event.target;

    if (etarget.name === "email") {
      setEmail(etarget.value?.toLowerCase()?.trim());
    } else {
      setPassword(etarget.value);
    }
  };

  const closeError = () => {
    setFormError(false);
  };

  const submitNewUser = async () => {
    //create new user and set the new user ID in the state
    try {
      if (!validateEmail(email)) {
        setErrorLog("Invalid email.");
        setFormError(true);
        return;
      }
      setSubmitting(true);
      await signupWithEmailAndPass(email, password);
      const { user, accessToken } = await loginWithEmailAndPass(email, password);
      authDispatch({
        type: AuthActionTypes.LOGIN_USER,
        payload: {
          realmUser: user,
          accessToken: accessToken ?? "",
          email,
        },
      });
      await insertUser({
        variables: {
          data: {
            _id: user.id,
            authId: user.id,
            email: user.profile.email,
            name: user.profile.name,
            firstName: user.profile.firstName,
            lastName: user.profile.lastName,
            picture: user.profile.pictureUrl,
            birthday: user.profile.birthday,
            gender: user.profile.gender,
            createdAt: new Date(),
          },
        },
      });
      await app?.currentUser?.refreshCustomData();

      fireGoogleAnalytics();
      heap.identify(user.id);
      setToHome(true);
    } catch (e) {
      const err = e as unknown as MongoDBRealmError;
      console.error(e);
      if (err?.errorCode === "AccountNameInUse") {
        setErrorLog("Email already in use.");
      } else {
        setErrorLog(err?.error ?? "");
      }
      setFormError(true);
    }
    setSubmitting(false);
  };

  const maybeSubmit = (e: React.KeyboardEvent<Element>) => {
    if (e.key === "Enter") {
      submitNewUser();
    }
  };

  if (toHome === true) {
    return <Redirect to="/home" />;
  }

  const signupDisabled = !validateEmail(email) || password.trim() === "" || !acceptPolicies;

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.container}>
      <div className={classes.inputWrapper}>
        <OutlinedInput
          label="Email"
          onKeyPress={maybeSubmit}
          onChange={signupFormChange}
          value={email}
          name="email"
          variant="light"
          type="email"
        />
      </div>
      <PasswordInput
        label="Password"
        onKeyPress={maybeSubmit}
        onChange={signupFormChange}
        value={password}
        name="password"
        variant="light"
      />
      <AcceptTermsCheckbox handlePolicyAcceptChange={handlePolicyAcceptChange} />
      <Grid container justifyContent="center" alignItems="center" className={classes.submitButton}>
        <PrimaryButton disabled={signupDisabled} onClick={submitNewUser}>
          {submitting ? <CircularProgress size={25} /> : "Sign up"}
        </PrimaryButton>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" className={classes.submitButton}>
        <GoogleLoginButton disabled={signupDisabled} variant="outlined">
          Sign up with Google
        </GoogleLoginButton>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={formError}
        autoHideDuration={6000}
        onClose={closeError}
        message={
          <Grid container justifyContent="center" alignItems="center">
            <span className="lnr lnr-warning"></span> &nbsp;
            {errorLog}
          </Grid>
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={closeError}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Grid>
  );
};

export default SignupForm;
