import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";

const useStyles = makeStyles((theme) => ({
  giftIdeaLoader: {
    overflow: "hidden",
    boxShadow: "none",
    margin: "10px 0px",
    zIndex: 1,
    animation: "4s $pulse infinite",
  },
  productImageWrapper: {
    position: "relative",
    marginBottom: theme.spacing(1),
    background: theme.palette.text.primary,
    borderRadius: 4,
  },
  productImage: {
    width: 120,
    height: 120,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  text: {
    borderRadius: 4,
    width: "100%",
    height: 20,
    marginBottom: theme.spacing(1),
    background: theme.palette.text.primary,
  },
  textShort: {
    borderRadius: 4,
    width: "70%",
    height: 20,
    marginBottom: theme.spacing(1),
    background: theme.palette.text.primary,
  },
  textShortDim: {
    borderRadius: 4,
    width: "60%",
    height: 16,
    marginBottom: theme.spacing(1),
    background: theme.palette.text.primary,
  },
  "@keyframes pulse": {
    "0%": {
      opacity: 1,
    },
    "50%": {
      opacity: 0.3,
    },
    "100%": {
      opacity: 1,
    },
  },
}));

type Props = {
  color?: string;
};

const GiftIdeaLoader: FC<Props> = ({ color }) => {
  const classes = useStyles();
  return (
    <div className={classes.giftIdeaLoader}>
      <div className={classes.productImageWrapper}>
        <div className={classes.productImage}></div>
      </div>
      <div>
        <div className={classes.text} />
        <div className={classes.textShort} />
        <div className={classes.textShortDim} />
      </div>
    </div>
  );
};

export default GiftIdeaLoader;
