import { useCallback, useState } from "react";
import { ApolloError } from "@apollo/client";
import { User } from "../@types/user";
import { useAuthContext } from "../auth/authContext";
import { getAccessToken } from "../realm/auth";

export interface MutationResult<TData> {
  called: boolean;
  data?: TData;
  error?: ApolloError;
  hasError: boolean;
  loading: boolean;
}

type UseInviteMutationType = [
  (invitee: User["customData"]) => Promise<{ results: any } | false | null>,
  MutationResult<any>
];

const initialState = {
  called: false,
  data: undefined,
  error: undefined,
  hasError: false,
  loading: false,
};

const useInviteMutations = (): UseInviteMutationType => {
  const [result, setResult] = useState(initialState);
  const { authState } = useAuthContext();

  const fireFunction = useCallback(async (invitee: User["customData"]) => {
    try {
      const initiatorId = localStorage.getItem("occ-invite");
      setResult((prev) => ({
        ...prev,
        called: true,
        loading: true,
      }));
      const url = process.env.REACT_APP_OCC_API_URL + "/invite";
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", authState.accessToken);
      const options = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ invitee, initiatorId }),
      };
      let inviteRequest = await fetch(url, options);
      if (inviteRequest.status === 401) {
        const newToken = (await getAccessToken()) as string;
        myHeaders.append("Authorization", newToken);
        options.headers = myHeaders;
        inviteRequest = await fetch(url, options);
      }
      const inviteResponse = await inviteRequest.json();

      setResult((prev) => ({
        ...prev,
        data: inviteResponse as any,
        loading: false,
      }));
      localStorage.removeItem("occ-invite");
      return inviteResponse;
    } catch (e) {
      console.error(e);
      setResult((prev) => ({
        ...prev,
        error: e,
        hasError: true,
      }));
      localStorage.removeItem("occ-invite");
      return false;
    }
  }, []);

  return [fireFunction, result];
};

export default useInviteMutations;
