import React, { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import {
  closeMiniGiftIdeaDialog, deselectGiftIdea, selectGiftIdea
} from '../../redux/giftideas/actions';
import { GiftIdeaRootState } from '../../redux/giftideas/reducers';
import { RootState } from '../../redux/store';
// import GA from '../../utils/ga';
import PrimaryButton from '../general-ui/buttons/PrimaryButton';
import SecondaryButton from '../general-ui/buttons/SecondaryButton';
import SecondaryModal from '../general-ui/modals/SecondaryModal';
import GiftIdeaDetailHeader from './GiftIdeaDetailHeader';
import RetailerLogo from './RetailerLogo';

const useStyles = makeStyles((theme: Theme) => ({
  headerActions: {
    zIndex: 10,
  },
  closeButton: {
    background: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  giftIdeaHeader: {
    position: "relative",
    color: theme.palette.text.primary,
  },
  giftImage: {
    marginBottom: theme.spacing(1),
    width: "100%",
    borderRadius: 30,
    zIndex: 10,
    objectFit: "cover",
  },
  giftTitle: {
    flex: 3,
  },
  giftLogoWrapper: {
    flex: 1,
    marginLeft: theme.spacing(1),
    height: 50,
    background: theme.palette.common.white,
    padding: 2,
    overflow: "hidden",
    borderRadius: 8,
  },
  giftIdeaDescription: {
    position: "relative",
    color: theme.palette.text.primary,
    paddingTop: 16,
    paddingBottom: 16,
    "& p": {
      overflowY: "scroll",
      zIndex: 10,
      maxHeight: "calc(100vh - 650px)",
      width: "100%",
      whiteSpace: "pre-wrap",
    },
    "& h2": {
      zIndex: 10,
      minHeight: 27,
    },
  },
  viewProduct: {
    paddingTop: 16,
    paddingBottom: 16,
  },
}));

const GiftMiniDialog: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const giftIdeasState = useSelector<RootState, GiftIdeaRootState>((state) => {
    return state.giftIdeas;
  });

  const { activeGiftIdea, giftIdeaMiniDialogOpen } = giftIdeasState;
  const giftsSelectedTitles = useMemo(
    () => giftIdeasState.selectedGifts.map((gift) => gift.title),
    [giftIdeasState.selectedGifts]
  );
  const selected = giftsSelectedTitles.includes(activeGiftIdea?.title ?? "");

  const closeMiniDialog = () => {
    dispatch({ ...closeMiniGiftIdeaDialog() });
  };

  const selectGift = () => {
    if (activeGiftIdea) {
      dispatch({ ...selectGiftIdea(activeGiftIdea) });
    }

    closeMiniDialog();
  };

  const deselectGift = () => {
    if (activeGiftIdea) {
      dispatch({ ...deselectGiftIdea(activeGiftIdea) });
    }

    closeMiniDialog();
  };

  return (
    <SecondaryModal showModal={giftIdeaMiniDialogOpen} closeModal={closeMiniDialog} secondModal={true}>
      <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.giftIdeaHeader}>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center" className={classes.headerActions}>
          <IconButton onClick={closeMiniDialog} className={classes.closeButton} aria-label="close product" size="small">
            <Icon>
              <span className="lnr lnr-cross"></span>
            </Icon>
          </IconButton>
        </Grid>
        <GiftIdeaDetailHeader showPrice={false} giftIdea={activeGiftIdea} />
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.giftIdeaDescription}
      >
        <Typography variant="body1" noWrap>
          <>
            {activeGiftIdea?.giftSource === "zazzle" ? null : (
              <p dangerouslySetInnerHTML={{ __html: activeGiftIdea?.description ?? "" }}></p>
            )}
          </>
        </Typography>
      </Grid>
      <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.viewProduct}>
        {selected ? (
          <SecondaryButton onClick={deselectGift}>Deselect Product</SecondaryButton>
        ) : (
          <PrimaryButton onClick={selectGift}>Select Product</PrimaryButton>
        )}
      </Grid>
    </SecondaryModal>
  );
};

export default GiftMiniDialog;
