import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import GradientButton from '../components/general-ui/buttons/GradientButton';
import PasswordInput from '../components/general-ui/inputs/PasswordInput';
import useSearchParams from '../hooks/useSearchParams';
import app from '../realm';

const useStyles = makeStyles((theme) => ({
  forgotPassPage: {
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.primary.gradient,
  },
  forgotPassForm: {
    padding: 16,
  },
  submitButton: {
    "& button": {
      padding: "15px 13px",
    },
  },
}));

const ResetPassword: FC = () => {
  const classes = useStyles();
  const searchParams = useSearchParams();
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(e.target.value);
  };
  const handleSubmit = async () => {
    const token = searchParams.get("token");
    const tokenId = searchParams.get("tokenId");
    if (token && tokenId) {
      try {
        await app.emailPasswordAuth.resetPassword(token, tokenId, password);
      } catch (e) {
        console.error(e);
      } finally {
        setSubmitted(true);
        setTimeout(() => {
          history.push("/login");
        }, 3000);
      }
    } else {
      throw "need a token to reset password";
    }
  };
  const potentialSubmit = (e: React.KeyboardEvent<Element>) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className={classes.forgotPassPage}>
      <Card className={classes.forgotPassForm}>
        <Typography variant="h5" gutterBottom>
          Reset Password
        </Typography>
        {!submitted ? (
          <>
            <Typography variant="h6" gutterBottom>
              Know one has to know about this!
            </Typography>
            <Typography variant="body2" gutterBottom>
              Please provide a new password
            </Typography>
            <PasswordInput
              label="Password"
              onChange={handlePasswordChange}
              onKeyPress={potentialSubmit}
              value={password}
              name="username"
            />
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: 20 }}
              className={classes.submitButton}
            >
              <GradientButton onClick={handleSubmit}>Reset Password</GradientButton>
            </Grid>
          </>
        ) : (
          <>
            <Typography variant="h6" gutterBottom>
              Nicely done!
            </Typography>
            <Typography variant="body2" gutterBottom>
              Your password has been reset.
            </Typography>
          </>
        )}
      </Card>
    </div>
  );
};

export default ResetPassword;
