// Generated by ReScript, PLEASE EDIT WITH CARE

import * as TS from "../bindings/TS.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as GQL__Giftees from "../graphql/queries/GQL__Giftees.bs.js";
import * as Entity__Product from "../entities/Entity__Product.bs.js";
import * as GQL__GiftProfile from "../graphql/queries/GQL__GiftProfile.bs.js";
import * as GQL__GiftCategories from "../graphql/queries/GQL__GiftCategories.bs.js";
import * as GiftIdeasByCategoryFeed from "./GiftIdeasByCategoryFeed.bs.js";

function reduceRecommendationsToFeeds(productFeeds, nextProduct) {
  var interest = nextProduct.interest;
  if (interest === undefined) {
    return productFeeds;
  }
  var index = productFeeds.findIndex(function (productFeed) {
        return Caml_obj.caml_equal(productFeed[0], interest);
      });
  if (index === -1) {
    return productFeeds;
  }
  var match = Caml_array.get(productFeeds, index);
  var newRecommendations = match[1].concat([nextProduct]);
  Caml_array.set(productFeeds, index, [
        match[0],
        newRecommendations
      ]);
  return productFeeds;
}

function GiftIdeaFeedForMultiGiftee(Props) {
  var gifteeIds = Props.gifteeIds;
  var userIds = Props.userIds;
  var authContext = TS.AuthContext.useContext(undefined);
  var userId = authContext.authState.user.auth.id;
  var getGifteesInputQuery = GQL__Giftees.GetGifteesQuery.makeInputObjectGifteeQueryInput(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(userId), undefined, undefined);
  var getGifteesQueryVariables = GQL__Giftees.GetGifteesQuery.makeVariables(getGifteesInputQuery, undefined);
  var getGifteesQueryResult = Curry.app(GQL__Giftees.GetGifteesQuery.use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        getGifteesQueryVariables
      ]);
  var profileQueryInputUserIds = GQL__GiftProfile.GetGiftProfilesQuery.makeInputObjectGiftProfileQueryInput(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, userIds, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  var profileQueryInputGifteeIds = GQL__GiftProfile.GetGiftProfilesQuery.makeInputObjectGiftProfileQueryInput(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, gifteeIds, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  var orQueryInput = GQL__GiftProfile.GetGiftProfilesQuery.makeInputObjectGiftProfileQueryInput(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
        profileQueryInputGifteeIds,
        profileQueryInputUserIds
      ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  var profileQueryVariables = GQL__GiftProfile.GetGiftProfilesQuery.makeVariables(orQueryInput, undefined);
  var profileResult = Curry.app(GQL__GiftProfile.GetGiftProfilesQuery.use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        profileQueryVariables
      ]);
  var giftCategoriesVariables = GQL__GiftCategories.GetGiftCategoriesQuery.makeDefaultVariables(undefined);
  var giftCategoriesResult = Curry.app(GQL__GiftCategories.GetGiftCategoriesQuery.use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        giftCategoriesVariables
      ]);
  var match = getGifteesQueryResult.data;
  var giftees = match !== undefined ? match.giftees : [];
  var emptyFeeds = React.useMemo(function () {
        var match = giftCategoriesResult.data;
        if (match !== undefined) {
          return match.categorys.filter(function (giftCategory) {
                        return giftCategory !== undefined;
                      }).map(function (giftCategory) {
                      if (giftCategory !== undefined) {
                        return [
                                giftCategory.value,
                                []
                              ];
                      } else {
                        return [
                                undefined,
                                []
                              ];
                      }
                    });
        } else {
          return [];
        }
      });
  var unPurchasedRecommendations = React.useMemo(function () {
        var match = profileResult.data;
        if (match !== undefined) {
          return match.giftProfiles.reduce((function (accumulatedRecommendations, giftProfile) {
                        var match;
                        if (giftProfile !== undefined) {
                          var recommendations = giftProfile.recommendations;
                          match = recommendations !== undefined ? [
                              recommendations,
                              giftProfile.purchases,
                              giftProfile.gifteeId
                            ] : [
                              [],
                              [],
                              undefined
                            ];
                        } else {
                          match = [
                            [],
                            [],
                            undefined
                          ];
                        }
                        var gifteeId = match[2];
                        var purchases = match[1];
                        var purchases$1 = purchases !== undefined ? purchases : [];
                        var purchaseIds = purchases$1.map(function (purchase) {
                              if (purchase !== undefined) {
                                return JSON.stringify(Belt_Option.getWithDefault(purchase.giftIdeaId, ""));
                              } else {
                                return "";
                              }
                            });
                        var nextRecommendations = match[0].filter(function (recommendation) {
                              if (recommendation !== undefined) {
                                return !purchaseIds.includes(JSON.stringify(Belt_Option.getWithDefault(recommendation._id, "")));
                              } else {
                                return false;
                              }
                            });
                        var productsFromRecommendations = nextRecommendations.map(function (nextRec) {
                              var match = nextRec !== undefined ? [
                                  Entity__Product.fromGiftProfilesRecommendation(nextRec),
                                  nextRec.similarity
                                ] : [
                                  Entity__Product.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                                  0
                                ];
                              var giftee = giftees.find(function (giftee) {
                                    if (giftee !== undefined) {
                                      return Caml_obj.caml_equal(giftee._id, gifteeId);
                                    } else {
                                      return false;
                                    }
                                  });
                              var match$1 = giftee !== undefined && giftee !== undefined ? [
                                  giftee.name,
                                  giftee.picture
                                ] : [
                                  "",
                                  ""
                                ];
                              var gifteeMatch_name = Belt_Option.getWithDefault(match$1[0], "");
                              var gifteeMatch_image = match$1[1];
                              var gifteeMatch_similarity = Belt_Option.getWithDefault(match[1], 0);
                              var gifteeMatch = {
                                name: gifteeMatch_name,
                                image: gifteeMatch_image,
                                similarity: gifteeMatch_similarity
                              };
                              return Entity__Product.withGifteeMatch(match[0], [gifteeMatch]);
                            });
                        return productsFromRecommendations.concat(accumulatedRecommendations);
                      }), []);
        } else {
          return [];
        }
      });
  var productFeeds = React.useMemo(function () {
        return unPurchasedRecommendations.reduce(reduceRecommendationsToFeeds, emptyFeeds);
      });
  if (profileResult.loading) {
    return React.createElement(TS.Loader.make, {});
  } else {
    return React.createElement(GiftIdeasByCategoryFeed.make, {
                productFeeds: productFeeds
              });
  }
}

var make = GiftIdeaFeedForMultiGiftee;

export {
  reduceRecommendationsToFeeds ,
  make ,
  
}
/* TS Not a pure module */
