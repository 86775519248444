import React, { FC, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { getGiftCategories } from '../../graphql/queries/gift-categories';
import {
  getGiftCategories as getGiftCategoriesQuery, getGiftCategoriesVariables
} from '../../graphql/queries/gift-categories/__generated__/getGiftCategories';
import {
  make as GiftCategorySelectButton
} from '../../rescript/components/GiftCategorySelectButton.gen';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(12),
    maxWidth: 600,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
    },
  },
  giftTypeSamplesWrapper: {
    marginTop: theme.spacing(1.5),
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  giftTypeSample: {
    display: "flex",
    color: theme.palette.text.primary,
    textAlign: "left",
    fontSize: 16,
    lineHeight: "1em",
    justifyContent: "flex-start",
    alignItems: "center",
    boxSizing: "border-box",
    padding: theme.spacing(2),
    margin: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    height: 45,
    borderRadius: 16,
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {},
    "& img": {
      width: "50%",
    },
    "& .lnr": {
      fontSize: 26,
    },
  },
  specificsButton: {
    marginBottom: theme.spacing(1),
  },
}));

type Props = {
  value: string[];
  onChange: (interest: string) => void;
  disabled?: boolean;
  hideUnselectedCategories?: boolean;
};

const InterestSelect: FC<Props> = ({ value, onChange, disabled, hideUnselectedCategories }) => {
  const classes = useStyles();
  const { data } = useQuery<getGiftCategoriesQuery, getGiftCategoriesVariables>(getGiftCategories, {});

  const giftCategories = useMemo(() => {
    const allCategories = data?.categorys ?? [];
    if (hideUnselectedCategories) {
      return allCategories.filter((c) => value.includes(c?.value ?? ""));
    }
    return allCategories;
  }, [data?.categorys, hideUnselectedCategories, value]);

  const handleGiftTypeSampleClick = (type: string) => {
    onChange(type);
  };
  return (
    <div>
      <Grid className={classes.giftTypeSamplesWrapper}>
        {giftCategories.map((type, i) => (
          <GiftCategorySelectButton
            category={type?.value ?? ""}
            key={i}
            onClick={(value) => handleGiftTypeSampleClick(value)}
            selected={value.includes(type?.value ?? "")}
            icon={type?.icon ?? "lnr-gift"}
            disabled={disabled ?? false}
          />
        ))}
      </Grid>
    </div>
  );
};

export default InterestSelect;
