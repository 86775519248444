import classNames from 'classnames';
import React, { FC } from 'react';

import { ClickAwayListener, IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  modalOpen: {
    position: "fixed",
    zIndex: theme.zIndex.modal - 20,
    animation: "$fadeIn 0.25s",
  },
  modal: {
    overflow: "hidden",
    width: 600,
    height: "calc(100vh - 60px)",
    top: "5vh",
    borderRadius: 30,
    left: "calc(50% - 250px)",
    [theme.breakpoints.down("sm")]: {
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      width: "100vw",
      height: "100vh",
      borderRadius: 0,
    },
  },
  modalContent: {
    height: "100%",
    justifyContent: "flex-start",
    background: theme.palette.background.paper,
    '& [class^="rsw"]': {
      flexDirection: "column-reverse",
      display: "flex",
    },
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  hasOverlay: {
    position: "absolute",
    "&:after": {
      content: "''",
      position: "fixed",
      width: "100vw",
      height: "100vh",
      background: theme.palette.background.background,
      opacity: 0.5,
      zIndex: theme.zIndex.modal - 40,
      top: 0,
      left: 0,
    },
  },
  modalContentDark: {
    height: "100%",
    justifyContent: "flex-start",
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
    '& [class^="rsw"]': {
      flexDirection: "column-reverse",
      display: "flex",
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
      height: "100%",
    },
  },
  modalHeader: {
    width: "100%",
    padding: "0px 10px",
    height: 50,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.background,
  },
  closer: {
    fontSize: "20px",
    borderRadius: "50%",
    padding: 4,
    height: 36,
    width: 36,
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
    "&:hover": {
      opacity: 0.7,
    },
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
}));

type Props = {
  showModal: boolean;
  closeModal: () => void;
  header?: string;
  title?: string;
  variant?: string;
  secondModal?: boolean;
  isBehindSecondModal?: boolean;
};

const Modal: FC<Props> = ({
  showModal,
  closeModal,
  header,
  title,
  variant,
  secondModal,
  children,
  isBehindSecondModal,
}) => {
  const hideModal = () => {
    if (showModal) {
      closeModal();
    }
  };

  const classes = useStyles();

  const modalContainerClasses = classNames({
    [classes.modalOpen]: showModal,
    [classes.modal]: true,
    // [classes.hasOverlay]: true,
  });

  const modalContentClasses = classNames({
    [classes.modalContent]: variant !== "dark",
    [classes.modalContentDark]: variant === "dark",
  });
  if (!showModal) {
    return null;
  }

  const handleClickAway = () => {
    if (showModal && !isBehindSecondModal) {
      hideModal();
    }
  };

  return (
    <>
      <div className={classes.hasOverlay}></div>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div
          className={modalContainerClasses}
          style={secondModal ? { zIndex: 1500 } : {}}
          data-testid={`modal-${title}`}
        >
          {header ? (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.modalHeader}
            >
              <h4>{title}</h4>
              <IconButton className={classes.closer} onClick={hideModal}>
                <span className="lnr lnr-cross"></span>
              </IconButton>
            </Grid>
          ) : (
            ""
          )}
          <div className={modalContentClasses}>{showModal ? children : null}</div>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default Modal;
