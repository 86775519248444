import gql from "graphql-tag";

export const getUserQuery = gql`
  query getUser($query: UserQueryInput) {
    user(query: $query) {
      _id
      email
      firstName
      lastName
      name
      picture
    }
  }
`;

export const getUsersQuery = gql`
  query getUsers($query: UserQueryInput) {
    users(query: $query) {
      authId
      email
      firstName
      lastName
      name
      picture
    }
  }
`;

export const userSearch = gql`
  query UserSearch($input: String) {
    UserSearch(input: $input) {
      _id
      email
      firstName
      lastName
      name
      picture
    }
  }
`;
