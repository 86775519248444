import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import React, { FC, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { OccasionType } from '../../__generated__/globalTypes';
import { useAuthContext } from '../../auth/authContext';
import { updateOneOccasion } from '../../graphql/mutations/occasions';
import {
  UpdateOneOccasionMutation, UpdateOneOccasionMutationVariables
} from '../../graphql/mutations/occasions/__generated__/UpdateOneOccasionMutation';
import { getOccasionsQuery } from '../../graphql/queries/occasions';
import { getOccasion_occasion } from '../../graphql/queries/occasions/__generated__/getOccasion';
import AnimatedDelete from '../general-ui/AnimatedDelete';
import DeleteButton from '../general-ui/buttons/DeleteButton';
import SecondaryButton from '../general-ui/buttons/SecondaryButton';
import TertiaryButton from '../general-ui/buttons/TertiaryButton';
import GlobalMessageDispatch from '../general-ui/global-messages/GlobalMessageDispatch';
import SecondaryModal from '../general-ui/modals/SecondaryModal';
import occasionIcons from './occasionIcons';

dayjs.extend(advancedFormat);
dayjs.extend(utc);

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: theme.palette.primary.main,
    background: theme.palette.text.primary,
    height: "55px",
    padding: theme.spacing(3),
  },
  occasionSelect: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    cursor: "pointer",
    zIndex: 10,
    boxShadow: "inset 0 0 2px #d90386",
  },
  occasionMain: {
    minHeight: "40px",
    overflow: "hidden",
  },
  occasionExpand: {
    color: theme.palette.primary.main,
  },
  occasionExpanded: {
    boxShadow:
      "0px 1px 3px 0px " +
      theme.palette.primary.transparent +
      ", 0px 1px 1px 0px " +
      theme.palette.primary.transparent +
      ", 0px 2px 1px -1px " +
      theme.palette.primary.transparent,
  },
  occasionDate: {
    marginRight: theme.spacing(4),
  },
  occasionDetails: {
    padding: "0px",
  },
  occasionDetailHeader: {
    background: theme.palette.text.primary,
    padding: theme.spacing(3),
    color: "#fff",
  },
  occasionDetailBody: {
    padding: theme.spacing(3),
  },
  addReminder: {
    alignSelf: "flex-end",
    marginTop: " 10px",
    marginRight: "10px",
    color: theme.palette.text.primary,
  },
  bottomRow: {
    padding: "0px 8px",
    color: theme.palette.text.primary,
    background: theme.palette.background.paper2DP,
  },
}));

type Props = {
  occasion: Partial<getOccasion_occasion>;
};

const OccasionUser: FC<Props> = ({ occasion }) => {
  const { authState } = useAuthContext();
  const [deleteOccasionShow, setDeleteOccasionShow] = useState(false);
  const [deleteSnackbarShow, setDeleteSnackbarShow] = useState(false);
  const [updateOccasion] = useMutation<UpdateOneOccasionMutation, UpdateOneOccasionMutationVariables>(
    updateOneOccasion
  );

  const deleteOccasionHandler = async () => {
    setDeleteOccasionShow(false);
    await updateOccasion({
      variables: {
        query: {
          _id: occasion._id,
        },
        set: {
          deleted: true,
        },
      },
      refetchQueries: [
        {
          query: getOccasionsQuery,
          variables: {
            query: {
              userId: authState.user.auth.id,
            },
          },
        },
      ],
    });

    setDeleteSnackbarShow(true);
  };

  const deleteOccasionFormShow = () => {
    setDeleteOccasionShow(true);
  };

  const deleteOccasionFormHide = () => {
    setDeleteOccasionShow(false);
  };
  const classes = useStyles();

  const occasionIconClass = occasionIcons(occasion.type ?? OccasionType.BIRTHDAY);
  const occasionIcon = <span className={occasionIconClass}></span>;

  return (
    <Accordion classes={{ root: classes.occasionMain, expanded: classes.occasionExpanded }}>
      <AccordionSummary expandIcon={<span className={classes.occasionExpand + " lnr lnr-chevron-down-circle"}></span>}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <div>
            {occasionIcon}&nbsp;{occasion.name}
          </div>
          <div className={classes.occasionDate}>
            {occasion.nextOccasionDate ? dayjs(occasion.nextOccasionDate).utc().format("MMM Do") : ""}
          </div>
        </Grid>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.occasionDetails }} className="flex-column-center">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          classes={{ container: classes.bottomRow }}
        >
          Delete from profile?
          <DeleteButton variant="light" onClick={deleteOccasionFormShow}></DeleteButton>
        </Grid>
      </AccordionDetails>
      <SecondaryModal showModal={deleteOccasionShow} closeModal={deleteOccasionFormHide} title="Delete Occasion">
        <h3>Do you want to delete {occasion.name}?</h3>
        <div className="dialog-btn-set">
          <TertiaryButton onClick={deleteOccasionFormHide}>Cancel</TertiaryButton>
          <SecondaryButton onClick={deleteOccasionHandler}>Delete</SecondaryButton>
        </div>
      </SecondaryModal>
      {deleteSnackbarShow && (
        <GlobalMessageDispatch finalText="Occasion Deleted">
          <Grid container direction="row" alignItems="center">
            <AnimatedDelete
              otherStyles={{ width: 40, height: 40 }}
              checked={true}
              icon={
                <Icon>
                  <span className="lnr lnd-calendar-cross"></span>
                </Icon>
              }
            />
            <Typography variant="body1" color="inherit">
              Occasion deleted
            </Typography>
            {/* <SecondaryButton onClick={handleCancelDeleteGiftee}>Cancel</SecondaryButton> */}
          </Grid>
        </GlobalMessageDispatch>
      )}
    </Accordion>
  );
};

export default OccasionUser;
