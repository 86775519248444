// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Entity__GiftProvider from "./Entity__GiftProvider.bs.js";

function make(_idOpt, associatedProductsOpt, createdAtOpt, descriptionOpt, externalIdOpt, genderOpt, giftSourceOpt, imageOpt, interestOpt, priceOpt, giftProviderOpt, statusOpt, tagsOpt, titleOpt, updatedAtOpt, updatedByOpt, urlOpt, param) {
  var _id = _idOpt !== undefined ? _idOpt : "";
  var associatedProducts = associatedProductsOpt !== undefined ? associatedProductsOpt : [];
  var createdAt = createdAtOpt !== undefined ? createdAtOpt : "";
  var description = descriptionOpt !== undefined ? descriptionOpt : "";
  var externalId = externalIdOpt !== undefined ? externalIdOpt : "";
  var gender = genderOpt !== undefined ? genderOpt : "unisex";
  var giftSource = giftSourceOpt !== undefined ? giftSourceOpt : "";
  var image = imageOpt !== undefined ? imageOpt : "";
  var interest = interestOpt !== undefined ? Caml_option.valFromOption(interestOpt) : undefined;
  var price = priceOpt !== undefined ? priceOpt : 0;
  var giftProvider = giftProviderOpt !== undefined ? Caml_option.valFromOption(giftProviderOpt) : undefined;
  var status = statusOpt !== undefined ? statusOpt : "live";
  var tags = tagsOpt !== undefined ? tagsOpt : [];
  var title = titleOpt !== undefined ? titleOpt : "";
  var updatedAt = updatedAtOpt !== undefined ? updatedAtOpt : "";
  var updatedBy = updatedByOpt !== undefined ? updatedByOpt : "";
  var url = urlOpt !== undefined ? urlOpt : "";
  return {
          _id: _id,
          associatedProducts: associatedProducts,
          createdAt: createdAt,
          description: description,
          externalId: externalId,
          gender: gender,
          giftSource: giftSource,
          image: image,
          interest: interest,
          price: price,
          giftProvider: giftProvider,
          status: status,
          tags: tags,
          title: title,
          updatedAt: updatedAt,
          updatedBy: updatedBy,
          url: url,
          gifteeMatches: []
        };
}

function makeAssociatedProductsFromGetProductQuery(associatedProducts) {
  if (associatedProducts !== undefined) {
    return associatedProducts.reduce((function (accumulator, product) {
                  if (product !== undefined) {
                    Belt_Option.getWithDefault(product.image, "");
                    Belt_Option.getWithDefault(product.title, "");
                    Belt_Option.getWithDefault(product.url, "");
                    return accumulator.concat([{
                                  image: Belt_Option.getWithDefault(product.image, ""),
                                  title: Belt_Option.getWithDefault(product.title, ""),
                                  url: Belt_Option.getWithDefault(product.url, "")
                                }]);
                  } else {
                    return accumulator;
                  }
                }), []);
  } else {
    return [];
  }
}

function getGenderFromQueryGender(gender) {
  if (gender === "FEMALE") {
    return "female";
  } else if (gender === "MALE") {
    return "male";
  } else {
    return "unisex";
  }
}

function fromGiftIdeaQuery(giftIdea) {
  var image = Belt_Option.getWithDefault(giftIdea.image, "");
  var title = Belt_Option.getWithDefault(giftIdea.title, "");
  var description = Belt_Option.getWithDefault(giftIdea.description, "");
  var externalId = Belt_Option.getWithDefault(giftIdea.externalId, "");
  var url = Belt_Option.getWithDefault(giftIdea.url, "");
  var gender = Belt_Option.getWithDefault(giftIdea.gender, "UNISEX");
  var tags = Belt_Option.getWithDefault(giftIdea.tags, []);
  var price = Belt_Option.getWithDefault(giftIdea.price, 0);
  var id = JSON.stringify(Belt_Option.getWithDefault(giftIdea._id, ""));
  var provider = giftIdea.provider;
  var giftProvider = provider !== undefined ? Entity__GiftProvider.fromGiftProviderProductQuery(provider) : undefined;
  return {
          _id: id,
          associatedProducts: makeAssociatedProductsFromGetProductQuery(giftIdea.associatedProducts),
          createdAt: "",
          description: description,
          externalId: externalId,
          gender: getGenderFromQueryGender(gender),
          giftSource: "",
          image: image,
          interest: undefined,
          price: price,
          giftProvider: giftProvider,
          status: "live",
          tags: tags,
          title: title,
          updatedAt: "",
          updatedBy: "",
          url: url,
          gifteeMatches: []
        };
}

function fromGiftProfilesRecommendation(giftIdea) {
  var id = JSON.stringify(Belt_Option.getWithDefault(giftIdea._id, ""));
  var provider = giftIdea.giftProvider;
  var match = provider !== undefined ? [
      "",
      Belt_Option.getWithDefault(provider.logo, "")
    ] : [
      "",
      ""
    ];
  var giftProvider = Entity__GiftProvider.make(match[1], match[0], undefined);
  return {
          _id: id,
          associatedProducts: [],
          createdAt: "",
          description: "",
          externalId: "",
          gender: "unisex",
          giftSource: "",
          image: Belt_Option.getWithDefault(giftIdea.image, ""),
          interest: giftIdea.interest,
          price: Belt_Option.getWithDefault(giftIdea.price, 0),
          giftProvider: giftProvider,
          status: "live",
          tags: [giftIdea.interest],
          title: Belt_Option.getWithDefault(giftIdea.title, ""),
          updatedAt: "",
          updatedBy: "",
          url: "",
          gifteeMatches: []
        };
}

function fromGetGiftProfileByCategoryRecommendation(giftIdea) {
  var id = JSON.stringify(Belt_Option.getWithDefault(giftIdea._id, ""));
  var provider = giftIdea.giftProvider;
  var match = provider !== undefined ? [
      "",
      Belt_Option.getWithDefault(provider.logo, "")
    ] : [
      "",
      ""
    ];
  var giftProvider = Entity__GiftProvider.make(match[1], match[0], undefined);
  return {
          _id: id,
          associatedProducts: [],
          createdAt: "",
          description: "",
          externalId: "",
          gender: "unisex",
          giftSource: "",
          image: Belt_Option.getWithDefault(giftIdea.image, ""),
          interest: undefined,
          price: Belt_Option.getWithDefault(giftIdea.price, 0),
          giftProvider: giftProvider,
          status: "live",
          tags: [],
          title: Belt_Option.getWithDefault(giftIdea.title, ""),
          updatedAt: "",
          updatedBy: "",
          url: "",
          gifteeMatches: []
        };
}

function withGifteeMatch(product, gifteeMatches) {
  return {
          _id: product._id,
          associatedProducts: product.associatedProducts,
          createdAt: product.createdAt,
          description: product.description,
          externalId: product.externalId,
          gender: product.gender,
          giftSource: product.giftSource,
          image: product.image,
          interest: product.interest,
          price: product.price,
          giftProvider: product.giftProvider,
          status: product.status,
          tags: product.tags,
          title: product.title,
          updatedAt: product.updatedAt,
          updatedBy: product.updatedBy,
          url: product.url,
          gifteeMatches: product.gifteeMatches.concat(gifteeMatches)
        };
}

export {
  make ,
  makeAssociatedProductsFromGetProductQuery ,
  getGenderFromQueryGender ,
  fromGiftIdeaQuery ,
  fromGiftProfilesRecommendation ,
  fromGetGiftProfileByCategoryRecommendation ,
  withGifteeMatch ,
  
}
/* No side effect */
