import clsx from 'clsx';
import React, { FC } from 'react';

import Grid from '@material-ui/core/Grid/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { wizardModalStyles } from '../../../utils/styleHelpers';

const useStyles = makeStyles((theme) => ({
  wizardModalStyles: wizardModalStyles(theme),
  container: {
    flex: 1,
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "calc(100vh - 60px)",
    background: theme.palette.background.background,
    color: theme.palette.text.primary,
    padding: 0,
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 108px)",
      minWidth: 600,
    },
  },
  mainImage: {
    width: "50%",
    maxWidth: 225,
    objectFit: "contain",
    margin: "30px 0px",
  },
  header: {
    color: theme.palette.primary.main,
  },
  ctaButton: {
    "& .MuiButtonBase-root": {
      height: 56,
      fontSize: 20,
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(4),
      alignSelf: "flex-end",
    },
  },
}));

type Props = {
  children: React.ReactElement;
};

const ModalPage: FC<Props> = ({ children }) => {
  const classes = useStyles();
  const containerClasses = clsx({
    [classes.mainContainer]: true,
    [classes.wizardModalStyles]: true,
  });

  return (
    <div className={containerClasses}>
      <Grid
        container
        wrap="nowrap"
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        {children}
      </Grid>
    </div>
  );
};

export default ModalPage;
