import { OccasionType } from "../../__generated__/globalTypes";

export default function occasionIcons(occasionType: OccasionType) {
  let occasionIcon;

  switch (occasionType) {
    case OccasionType.BIRTHDAY:
      occasionIcon = "lnr lnr-cake";
      break;

    case OccasionType.VALENTINES:
      occasionIcon = "lnr lnr-heart";
      break;

    case OccasionType.CHRISTMAS:
      occasionIcon = "lnr lnr-christmas";
      break;

    case OccasionType.BIRTHDAY:
      occasionIcon = "lnr lnr-mustache-glasses";
      break;

    case OccasionType.MOTHERSDAY:
      occasionIcon = "lnr lnr-woman";
      break;
    case OccasionType.FATHERSDAY:
      occasionIcon = "lnr lnr-mustache-glasses";
      break;

    case OccasionType.ANNIVERSARY:
      occasionIcon = "lnr lnr-wink";
      break;

    case OccasionType.WEDDING:
      occasionIcon = "lnr lnr-heart";
      break;

    case OccasionType.HANUKKAH:
      occasionIcon = "lnr lnr-glass";
      break;

    case OccasionType.KWANZAA:
      occasionIcon = "lnr lnr-grapes";
      break;
    case OccasionType.EASTER:
      occasionIcon = "lnr lnr-easter-egg";
      break;
    default:
      occasionIcon = "lnr lnr-balloon";
  }

  return occasionIcon;
}
