import React, { FC, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Badge, Tab, Tabs, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { AuthActionTypes } from '../../@types/auth';
import { User, UserCustomData } from '../../@types/user';
import { GiftProfileGender } from '../../__generated__/globalTypes';
import { useAuthContext } from '../../auth/authContext';
import { updateOneGiftProfile } from '../../graphql/mutations/gift-profiles';
import { updateOneUser } from '../../graphql/mutations/users';
import {
  UpdateOneUserMutation, UpdateOneUserMutationVariables
} from '../../graphql/mutations/users/__generated__/UpdateOneUserMutation';
import { getGiftProfileQuery } from '../../graphql/queries/gift-profiles';
import {
  getGiftProfile, getGiftProfileVariables
} from '../../graphql/queries/gift-profiles/__generated__/getGiftProfile';
import app from '../../realm';
import { gender as Gender, make as GenderSelect } from '../../rescript/components/GenderSelect.gen';
import {
  UpdateOneGiftProfileMut, UpdateOneGiftProfileMutVariables
} from '../../rescript/graphql/queries/__generated__/UpdateOneGiftProfileMut';
import { validateEmail } from '../../utils/helpers/stringHelper';
import CloudinaryPhotoUpload from '../general-ui/CloudinaryPhotoUpload';
import IconFormFieldWrapper from '../general-ui/inputs/IconFormFieldWrapper';
import IconInput from '../general-ui/inputs/IconInput';
import TabPanel from '../general-ui/TabPanel';
import UserAddressSettings from './UserAddressSettings';

const useStyles = makeStyles((theme: Theme) => ({
  settingsContainer: {
    color: theme.palette.text.primary,
    padding: 16,
  },
  formInputWrapper: {
    marginBottom: theme.spacing(1),
  },
  inputClass: {
    "& label": {
      color: theme.palette.text.primary,
    },
  },
  reminderSettings: {
    marginTop: 30,
    "& label": {
      color: theme.palette.text.primary,
      "&:before": {
        background: theme.palette.primary.main,
      },
    },
  },
  accountTabs: {
    padding: 0,
    marginTop: theme.spacing(3),
  },
  missingInfoBadge: {
    "&.MuiBadge-badge": {
      right: 0,
      minWidth: 10,
      maxWidth: 10,
      height: 10,
      background: theme.palette.error.dark,
    },
  },
}));

type Props = {
  user: User;
};

const getGenderFromProfileGender = (profileGender: GiftProfileGender | null): Gender | undefined => {
  let gender: "UNISEX" | "MALE" | "FEMALE" | undefined;
  switch (profileGender) {
    case GiftProfileGender.MALE:
      gender = "MALE";
      break;
    case GiftProfileGender.FEMALE:
      gender = "FEMALE";
      break;
    case GiftProfileGender.UNISEX:
      gender = "UNISEX";
      break;
    case null:
      gender = undefined;
  }
  return gender;
};

const getProfileGenderFromGender = (gender: Gender): GiftProfileGender => {
  switch (gender) {
    case "MALE":
      return GiftProfileGender.MALE;
    case "FEMALE":
      return GiftProfileGender.FEMALE;
    case "UNISEX":
      return GiftProfileGender.UNISEX;
  }
};

const AccountSettings: FC<Props> = ({ user }) => {
  const { authDispatch } = useAuthContext();
  const getProfileVariables = {
    query: {
      userId: user.auth.id,
    },
  };
  const { data: giftProfileData } = useQuery<getGiftProfile, getGiftProfileVariables>(getGiftProfileQuery, {
    variables: getProfileVariables,
  });
  const classes = useStyles();
  const [newEmail, setNewEmail] = useState(user.customData.email ? user.customData.email : user.email);
  const [newPhone, setNewPhone] = useState(user.customData.phone ?? "");
  const [newName, setNewName] = useState(user.customData.name ?? "");
  const [newEmailConfirmation, setNewEmailConfirmation] = useState(false);
  const [newPhoneConfirmation, setNewPhoneConfirmation] = useState(false);
  const [newNameConfirmation, setNewNameConfirmation] = useState(false);
  const [newGenderConfirmation, setNewGenderConfirmation] = useState(false);
  const [newEmailInvalid, setNewEmailInvalid] = useState(false);
  const [updateOneUserMutation] = useMutation<UpdateOneUserMutation, UpdateOneUserMutationVariables>(updateOneUser);
  const [updateGiftProfile, { loading: loadingGiftProfile }] = useMutation<
    UpdateOneGiftProfileMut,
    UpdateOneGiftProfileMutVariables
  >(updateOneGiftProfile, {
    refetchQueries: [
      {
        query: getGiftProfileQuery,
        variables: getProfileVariables,
      },
    ],
  });

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  const newEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewEmail(event.target.value);
  };

  const newEmailSubmit = async () => {
    if (validateEmail(newEmail)) {
      await updateOneUserMutation({
        variables: {
          query: {
            _id: user.auth.id,
          },
          set: {
            email: newEmail,
          },
        },
      });
      handleUserDataUpdate();
      setNewEmailConfirmation(true);

      setTimeout(() => {
        setNewEmailConfirmation(false);
      }, 500);
    } else {
      setNewEmailInvalid(true);

      setTimeout(() => {
        setNewEmailInvalid(false);
      }, 1000);
    }
  };

  const potentialNewEmailSubmit = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      newEmailSubmit();
    }
  };

  const phoneFormat = (n: string) => {
    const regex: RegExpMatchArray | null = n.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    const x = regex ? regex : [];

    return !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
  };

  const newPhoneChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let formattedPhone = phoneFormat(event.target.value);
    setNewPhone(formattedPhone);
  };

  const handleUserDataUpdate = async () => {
    await app.currentUser?.refreshCustomData();
    authDispatch({
      type: AuthActionTypes.SET_USER_CUSTOM_DATA,
      payload: {
        customData: app.currentUser?.customData,
      },
    });
  };

  const newPhoneSubmit = async () => {
    await updateOneUserMutation({
      variables: {
        query: {
          _id: user.auth.id,
        },
        set: {
          phone: newPhone,
        },
      },
    });
    handleUserDataUpdate();
    setNewPhoneConfirmation(true);

    setTimeout(() => {
      setNewPhoneConfirmation(false);
    }, 500);
  };

  const potentialNewPhoneSubmit = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      newPhoneSubmit();
    }
  };

  const newNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewName(event.target.value);
  };

  const newNameSubmit = async () => {
    if (newName.trim() !== user.customData?.name?.trim()) {
      await updateOneUserMutation({
        variables: {
          query: {
            _id: user.auth.id,
          },
          set: {
            name: newName,
          },
        },
      });
      handleUserDataUpdate();
      setNewNameConfirmation(true);
      setTimeout(() => {
        setNewNameConfirmation(false);
      }, 500);
    }
  };

  const potentialNewNameSubmit = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      newNameSubmit();
    }
  };

  const cloudinaryResultHandler = async (file: string) => {
    await updateOneUserMutation({
      variables: {
        query: {
          authId: user.auth.id,
        },
        set: {
          picture: file,
        },
      },
    });
    handleUserDataUpdate();
  };

  const handleAddressSubmit = async (address: UserCustomData["address"]) => {
    await updateOneUserMutation({
      variables: {
        query: {
          authId: user.auth.id,
        },
        set: {
          address,
        },
      },
    });
    handleUserDataUpdate();
  };

  const handleGenderChange = async (gender: Gender) => {
    const profileGender = getProfileGenderFromGender(gender);
    await updateGiftProfile({ variables: { query: { userId: user.auth.id }, set: { gender: profileGender } } });
    setNewGenderConfirmation(true);
    setTimeout(() => {
      setNewGenderConfirmation(false);
    }, 500);
  };

  const userInfo = { text: user.customData.name ?? user.customData.email, image: user.customData.picture };
  const missingGeneralInfo = (user.customData.phone?.trim() ?? "") === "";
  const missingAddressInfo = !user.customData.address;
  const gender = getGenderFromProfileGender(giftProfileData?.giftProfile?.gender ?? null);

  return (
    <div className={"flex-column-center-center " + classes.settingsContainer}>
      <Typography variant="h5" gutterBottom>
        Account Info
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="account info tabs">
          <Tab
            label={
              <Badge
                classes={{ badge: classes.missingInfoBadge }}
                badgeContent={missingGeneralInfo ? "" : null}
                color="error"
              >
                General
              </Badge>
            }
          />
          <Tab
            label={
              <Badge
                classes={{ badge: classes.missingInfoBadge }}
                badgeContent={missingAddressInfo ? "" : null}
                color="error"
              >
                Address
              </Badge>
            }
          />
        </Tabs>
      </Typography>
      <TabPanel className={classes.accountTabs} value={activeTab} index={0}>
        <label>Profile pic</label>
        <Grid container alignItems="center" justifyContent="center">
          <div className="cloudinary-photo-upload-container">
            <CloudinaryPhotoUpload
              key={user.customData._id ?? user.auth.id}
              onUpload={cloudinaryResultHandler}
              parentData={userInfo}
            />
          </div>
        </Grid>
        <div className={classes.formInputWrapper}>
          <label>Name</label>
          <IconInput
            value={newName}
            confirmation={newNameConfirmation}
            icon="lnr-user"
            onBlur={newNameSubmit}
            changeCallback={newNameChange}
            keyPressCallback={potentialNewNameSubmit}
            className={classes.inputClass}
          />
        </div>
        <div className={classes.formInputWrapper}>
          <label>Email</label>
          <IconInput
            value={newEmail}
            confirmation={newEmailConfirmation}
            invalidation={newEmailInvalid}
            icon="lnr-envelope"
            onBlur={newEmailSubmit}
            changeCallback={newEmailChange}
            keyPressCallback={potentialNewEmailSubmit}
            className={classes.inputClass}
            disabled={true}
          />
        </div>
        <div className={classes.formInputWrapper}>
          <label>Phone Number</label>
          <IconInput
            type="tel"
            value={newPhone}
            confirmation={newPhoneConfirmation}
            missingInfo={newPhone.trim() === ""}
            icon="lnr-smartphone"
            onBlur={newPhoneSubmit}
            changeCallback={newPhoneChange}
            keyPressCallback={potentialNewPhoneSubmit}
            className={classes.inputClass}
          />
        </div>
        <div className={classes.formInputWrapper}>
          <label>Gender</label>
          <IconFormFieldWrapper loading={loadingGiftProfile} confirmation={newGenderConfirmation} icon="lnr-user">
            <GenderSelect gender={gender} onChange={handleGenderChange} noBorder={true} noLabel={true} />
          </IconFormFieldWrapper>
        </div>
      </TabPanel>
      <TabPanel className={classes.accountTabs} value={activeTab} index={1}>
        <UserAddressSettings user={user} onSubmit={handleAddressSubmit} />
      </TabPanel>
    </div>
  );
};

export default AccountSettings;
