import React, { FC, useMemo, useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import { getNotifications_notifications as NotificationType } from "../../graphql/queries/notifications/__generated__/getNotifications";
import PrimaryButton from "../general-ui/buttons/PrimaryButton";
import useTheme from "@material-ui/core/styles/useTheme";
import SecondaryButton from "../general-ui/buttons/SecondaryButton";
import SecondaryModal from "../general-ui/modals/SecondaryModal";
import useAddOccasion from "../../hooks/useAddOccasion";
import GlobalMessageDispatch from "../general-ui/global-messages/GlobalMessageDispatch";
import Grid from "@material-ui/core/Grid/Grid";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import AnimatedCheckmark from "../general-ui/AnimatedCheckmark";
import Typography from "@material-ui/core/Typography";
import { v4 as uuidv4 } from "uuid";
import GlobalMessageReplace from "../general-ui/global-messages/GlobalMessageReplace";
import { getGifteeQuery, getGifteesQuery } from "../../graphql/queries/giftees";
import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { getGiftees, getGifteesVariables } from "../../graphql/queries/giftees/__generated__/getGiftees";

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    height: 60,
    width: 60,
    marginRight: 10,
  },
  notificationWrapper: {
    marginBottom: 10,
  },
  notification: {
    height: "100px",
    padding: "0px",
    paddingLeft: "10px",
    boxShadow: theme.shadows[2],
  },
  notificationButtons: {
    height: "100%",
  },
  notificationButtonAdd: {
    height: "100%",
    width: "100px",
    borderRadius: "0px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    "&:hover": {
      background: theme.palette.error.dark,
    },
  },
}));

type Props = {
  notification: NotificationType;
  timeAgo: string;
  onDelete: () => void;
};
const NotificationGifteeAddedOccasion: FC<Props> = ({ notification, onDelete, timeAgo }) => {
  const theme = useTheme();
  const { requester, occasion, userId } = notification;
  const { data } = useQuery<getGiftees, getGifteesVariables>(getGifteesQuery, {
    variables: {
      query: {
        userId,
      },
    },
    fetchPolicy: "cache-first",
    skip: !userId,
  });

  const giftee = useMemo(() => data?.giftees.find((g) => g?.sourceUser?._id === requester?._id), [data?.giftees]);
  const [openExpansion, setOpenExpansion] = useState(false);
  const [addedOccasion, setAddedOccasion] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [addOccasion] = useAddOccasion();
  const occasionAddedNotificationId = useMemo(() => uuidv4(), []);

  const toggleExpansion = () => {
    setOpenExpansion(!openExpansion);
  };

  const handleYes = async () => {
    setLoading(true);
    setAddedOccasion(true);
    const { __typename, ...cleanedOccasion } = occasion ?? {};
    await addOccasion({
      ...cleanedOccasion,
      userId: undefined,
      giftee: { link: giftee?._id },
    });
    setLoading(false);
  };
  const handleNo = () => {
    setShowDeclineModal(true);
  };
  const closeDeclineModal = () => {
    setShowDeclineModal(false);
  };

  const classes = useStyles();

  const userAvatar = requester?.picture ? (
    <ListItemAvatar>
      <Avatar src={requester?.picture} className={classes.avatar} />
    </ListItemAvatar>
  ) : (
    <ListItemAvatar>
      <Avatar className={classes.avatar}>{requester?.name?.charAt(0)}</Avatar>
    </ListItemAvatar>
  );

  return (
    <>
      <SecondaryModal showModal={showDeclineModal} closeModal={closeDeclineModal} title="Decline adding reminders">
        Are you sure you don't want reminders for {occasion?.name ?? ""}?
        <br />
        <div>
          <PrimaryButton onClick={onDelete}>Yes</PrimaryButton>
          <SecondaryButton onClick={closeDeclineModal}>No</SecondaryButton>
        </div>
      </SecondaryModal>
      <div className={classes.notificationWrapper}>
        <ListItem button key={notification._id} className={classes.notification} onClick={toggleExpansion}>
          {userAvatar}
          <ListItemText secondary={timeAgo}>
            <span style={{ color: theme.palette.text.primaryBranded }}>{requester?.name}</span> added {occasion?.name}{" "}
            to their occasions. Do you want reminders for {occasion?.name}?
          </ListItemText>
          {openExpansion ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openExpansion} timeout="auto" unmountOnExit>
          <ListItem className={classes.notificationButtons} disableGutters>
            <PrimaryButton onClick={handleYes}>Yes</PrimaryButton>
            <SecondaryButton onClick={handleNo}>No</SecondaryButton>
          </ListItem>
        </Collapse>
      </div>
      {addedOccasion && loading && (
        <GlobalMessageDispatch id={occasionAddedNotificationId}>
          <Grid container direction="row" alignItems="center">
            <CircularProgress />

            <Typography variant="body1" color="inherit">
              Adding occasion...
            </Typography>
          </Grid>
        </GlobalMessageDispatch>
      )}
      {addedOccasion && !loading && (
        <GlobalMessageReplace id={occasionAddedNotificationId}>
          <Grid container direction="row" alignItems="center">
            <AnimatedCheckmark otherStyles={{ width: 40, height: 40, marginRight: 8 }} checked={true} />{" "}
            <Typography variant="body1" color="inherit">
              Occasion added!
            </Typography>
          </Grid>
        </GlobalMessageReplace>
      )}
    </>
  );
};

export default NotificationGifteeAddedOccasion;
