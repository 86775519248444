import { ApolloClient } from "@apollo/client";
import { useLazyQuery } from "@apollo/client/react/hooks/useLazyQuery";
import { LazyQueryResult } from "@apollo/client/react/types/types";
import { useCallback, useRef } from "react";
import { giftIdeaSearch } from "../graphql/queries/gift-ideas";
import { GiftIdeaSearch, GiftIdeaSearchVariables } from "../graphql/queries/gift-ideas/__generated__/GiftIdeaSearch";
import { debounceString } from "../utils/helpers/functionHelpers";

const useGiftIdeaSearch = (
  client?: ApolloClient<object>
): [(arg: string) => void, LazyQueryResult<GiftIdeaSearch, GiftIdeaSearchVariables>] => {
  const [searchGifts, searchGiftsContext] = useLazyQuery<GiftIdeaSearch, GiftIdeaSearchVariables>(giftIdeaSearch, {
    fetchPolicy: "network-only",
    client,
  });
  const initialSearchGifts = useRef(searchGifts);
  const callGiftSearch = useCallback(
    debounceString(async (search: string) => {
      await searchGifts({
        variables: {
          input: search,
        },
      });
    }, 1000),
    [initialSearchGifts.current]
  );

  return [callGiftSearch, searchGiftsContext];
};

export default useGiftIdeaSearch;
