import { GifteeMatch, GiftIdea } from '../../../components/gifts/types';
import {
  CLOSE_GIFT_IDEA_DIALOG, CLOSE_GIFT_IDEA_FILTER, CLOSE_MARK_GIFT_PURCHASED_DIALOG,
  CLOSE_MINI_GIFT_IDEA_DIALOG, CLOSE_PURCHASE_QUESTION_PROMPT_DIALOG, DESELECT_GIFT_IDEA,
  OPEN_GIFT_IDEA_DIALOG, OPEN_GIFT_IDEA_FILTER, OPEN_MARK_GIFT_PURCHASED_DIALOG,
  OPEN_MINI_GIFT_IDEA_DIALOG, OPEN_PURCHASE_QUESTION_PROMPT_DIALOG, SELECT_GIFT_IDEA,
  SET_ACTIVE_GIFT_IDEA, SET_ACTIVE_GIFT_IDEA_GIFTEE_MATCHES, SET_ACTIVE_GIFT_IDEA_ID, SET_INTERESTS,
  SET_INTERESTS_SELECTED, SET_PRICE_CHANGED, SET_PRICE_MAX, SET_PRICE_MIN
} from '../constants/action-types';
import { GiftIdeaAction } from '../types';

export type GiftIdeaRootState = {
  giftIdeaDialogOpen: boolean;
  giftIdeaMiniDialogOpen: boolean;
  markGiftPurchasedDialogOpen: boolean;
  purchaseQuestionPromptDialogOpen: boolean;
  activeGiftIdea?: GiftIdea;
  activeGiftIdeaId?: string;
  activeGiftIdeaGifteeMatches: GifteeMatch[];
  selectedGifts: GiftIdea[];
  giftIdeaFilterOpen: boolean;
  priceMax: number;
  priceMin: number;
  priceChanged: boolean;
  interestsSelected: string[];
  interests: string[];
};

const initialState = {
  giftIdeaDialogOpen: false,
  giftIdeaMiniDialogOpen: false,
  markGiftPurchasedDialogOpen: false,
  purchaseQuestionPromptDialogOpen: false,
  activeGiftIdea: undefined,
  activeGiftIdeaId: undefined,
  activeGiftIdeaGifteeMatches: [],
  selectedGifts: [],
  giftIdeaFilterOpen: false,
  priceMax: 500,
  priceMin: 10,
  priceChanged: false,
  interestsSelected: [],
  interests: [],
};

const giftIdeasReducer = (state: GiftIdeaRootState = initialState, action: GiftIdeaAction) => {
  switch (action.type) {
    case OPEN_GIFT_IDEA_DIALOG:
      return { ...state, giftIdeaDialogOpen: true };
    case CLOSE_GIFT_IDEA_DIALOG:
      return { ...state, giftIdeaDialogOpen: false };
    case OPEN_MINI_GIFT_IDEA_DIALOG:
      return { ...state, giftIdeaMiniDialogOpen: true };
    case CLOSE_MINI_GIFT_IDEA_DIALOG:
      return { ...state, giftIdeaMiniDialogOpen: false };
    case SELECT_GIFT_IDEA:
      return { ...state, selectedGifts: [...state.selectedGifts, action.payload as string] };
    case OPEN_MARK_GIFT_PURCHASED_DIALOG:
      return { ...state, markGiftPurchasedDialogOpen: true };
    case CLOSE_MARK_GIFT_PURCHASED_DIALOG:
      return { ...state, markGiftPurchasedDialogOpen: false };
    case OPEN_PURCHASE_QUESTION_PROMPT_DIALOG:
      return { ...state, purchaseQuestionPromptDialogOpen: true };
    case CLOSE_PURCHASE_QUESTION_PROMPT_DIALOG:
      return { ...state, purchaseQuestionPromptDialogOpen: false };
    case OPEN_GIFT_IDEA_FILTER:
      return { ...state, giftIdeaFilterOpen: true };
    case CLOSE_GIFT_IDEA_FILTER:
      return { ...state, giftIdeaFilterOpen: false };
    case DESELECT_GIFT_IDEA:
      const currentSelectedGifts = [...state.selectedGifts];
      const index = currentSelectedGifts.findIndex((giftIdea) => giftIdea.title === state.activeGiftIdea?.title);
      currentSelectedGifts.splice(index, 1);
      return { ...state, selectedGifts: currentSelectedGifts };
    case SET_ACTIVE_GIFT_IDEA:
      return { ...state, activeGiftIdea: action.payload };
    case SET_ACTIVE_GIFT_IDEA_GIFTEE_MATCHES:
      return { ...state, activeGiftIdeaGifteeMatches: action.payload };
    case SET_ACTIVE_GIFT_IDEA_ID:
      return { ...state, activeGiftIdeaId: action.payload };
    case SET_PRICE_MIN:
      return { ...state, priceMin: action.payload };
    case SET_PRICE_MAX:
      return { ...state, priceMax: action.payload };
    case SET_PRICE_CHANGED:
      return { ...state, priceChanged: action.payload };
    case SET_INTERESTS_SELECTED:
      return { ...state, interestsSelected: action.payload };
    case SET_INTERESTS:
      return { ...state, interests: action.payload };
    default:
      return state;
  }
};

export default giftIdeasReducer;
