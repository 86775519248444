import React, { FC } from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import Zoom from "@material-ui/core/Zoom";

const useStyles = makeStyles((theme) => ({
  floatingActionButton: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 54,
      width: "100vw",
      zIndex: 1100,
    },
    position: "absolute",
    bottom: 90,
    "& .pop-up-options": {
      position: "absolute",
    },
    "&.center": {
      left: "calc(50% - 28px)",
    },
    "&.right": {
      right: 50,
    },
    "&.left": {
      left: 50,
    },
  },
  icon: {
    color: theme.palette.common.white,
  },
  "@keyframes reveal": {
    "0%": {
      transform: "translateX(0px)",
      opacity: 0,
    },
    "20%": {
      transform: "translateX(90px)",
      opacity: 1,
    },
    "80%": {
      transform: "translateX(90px)",
      opacity: 1,
    },
    "100%": {
      transform: "translateX(0px)",
      opacity: 0,
    },
  },
}));

type Props = {
  options?: string[];
  link?: string;
  message?: string;
  icon?: string;
  position?: string;
  messagePosition?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  animateButton?: boolean;
  css?: React.CSSProperties;
  color?: "primary" | "inherit" | "secondary" | "default";
  iconColor?: string;
  size?: "small" | "medium" | "large";
  handler?: () => void;
  "data-testid"?: string;
};

const FloatingActionButton: FC<Props> = ({
  options = [],
  link,
  message,
  icon,
  position,
  messagePosition,
  animateButton,
  css,
  color = "primary",
  iconColor,
  size,
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  let { handler } = props;

  if (link) {
    handler = () => {
      window.location.replace(link);
    };
  }

  const popUpOptions: JSX.Element[] = [];
  options?.forEach((option, i) => {
    popUpOptions.push(
      <div className="option" key={i}>
        {option}
      </div>
    );
  });

  return (
    <div className={classes.floatingActionButton + " " + position} style={css}>
      <div className="pop-up-options">{popUpOptions}</div>
      <Zoom
        key={color}
        timeout={transitionDuration}
        in={true}
        style={{
          transitionDelay: `0ms`,
        }}
        unmountOnExit
      >
        {message ? (
          <Tooltip title={message} aria-label={message} placement={messagePosition}>
            <Fab data-testid={props["data-testid"]} onClick={handler} color={color} size={size}>
              <Icon className={classes.icon} style={css}>
                <span className={"lnr " + icon}></span>
              </Icon>
            </Fab>
          </Tooltip>
        ) : (
          <Fab data-testid={props["data-testid"]} onClick={handler} color={color} size={size}>
            <Icon className={classes.icon} style={css}>
              <span className={"lnr " + icon}></span>
            </Icon>
          </Fab>
        )}
      </Zoom>
    </div>
  );
};

export default FloatingActionButton;
