import classnames from "classnames";
import React, { FC, useEffect, useState } from "react";

import { makeStyles, Theme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import LoadingCircle from "./LoadingCircle";
import PhotoAlterDialog from "./PhotoAlterDialog";
import SecondaryModal from "./modals/SecondaryModal";

const useStyles = makeStyles((theme: Theme) => ({
  avatarBackground: {
    background: theme.palette.primary.main,
    borderRadius: "50%",
    "& svg": {
      position: "absolute",
    },
  },
  avatarText: {
    margin: 0,
    fontSize: 50,
  },
  cloudinaryPhotoUpload: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 150,
    width: 150,
  },
  userPicture: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    height: "100%",
    width: "100%",
    color: theme.palette.common.white,
    cursor: "pointer",
    "&:hover": {
      "&:before, &:after": {
        transform: "scale(1.2)",
        opacity: 1,
      },
    },
    "& img": {
      objectFit: "cover",
      borderRadius: "50%",
      height: "100%",
      width: "100%",
    },
    "& input[type=file]": {
      display: "none",
    },
    "&:before": {
      content: "'\\e704'",
      fontFamily: "Linearicons",
      position: "absolute",
      color: theme.palette.common.white,
      background: "#C68A77",
      height: 30,
      width: 30,
      borderRadius: "50%",
      fontSize: 18,
      transition: theme.transitions.easing.easeInOut,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      bottom: "5%",
      right: "5%",
      zIndex: 10,
    },
    "&:after": {
      content: "''",
      position: "absolute",
      height: 100,
      width: 100,
    },
  },
  userPictureVacant: {
    background: theme.palette.primary.main,
  },
  userPictureLoading: {
    "&:before": {
      content: "'\\e64b'",
      background: "#5FB9A6",
      color: theme.palette.common.white,
    },
  },
  userPictureError: {
    "&:before": {
      content: "'\\e658'",
      background: "#CD5C5C",
      color: theme.palette.common.white,
    },
  },
  userPictureDone: {
    "&:before": {
      content: "\\e659",
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    "& circle": {
      opacity: 0,
    },
  },
  noUpload: {
    "&:before": {
      display: "none",
    },
  },
}));

type Props = {
  onUpload: (file: string) => void;
  parentData: {
    image?: string | null;
    text?: string | null;
  };
  allowUpload?: boolean;
};

enum LoadingStatus {
  None,
  Done,
  Error,
  Loading,
}

const CloudinaryPhotoUpload: FC<Props> = ({ onUpload, parentData, allowUpload = true }) => {
  // const [cloudinaryResult, setCloudinaryResult] = useState<null | object>(null);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.None);
  const [openPhotoAlter, setOpenPhotoAlter] = useState(false);
  const [savedAlter, setSavedAlter] = useState("");
  const [readFileUri, setReadFileUri] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const closePhotoAlter = () => {
    setOpenPhotoAlter(false);
  };

  const readFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("reading file");
    // console.log(event);

    const FR = new FileReader();

    FR.addEventListener("load", (e) => {
      setReadFileUri(e?.target?.result as string);
      setOpenPhotoAlter(true);
    });

    const files = (event?.target?.files ?? []) as FileList;
    if (files.length > 0) {
      FR.readAsDataURL(files[0]);
    }
  };

  const uploadFile = (base64: string) => {
    var file = base64;

    var url = `https://api.cloudinary.com/v1_1/occasionally/upload`;
    var xhr = new XMLHttpRequest();
    var fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        // File uploaded successfully
        const response = JSON.parse(xhr.responseText);
        // console.log(response);

        // setCloudinaryResult(response);
        setLoadingStatus(LoadingStatus.Done);
        setLoadingProgress(0);

        setTimeout(() => {
          setLoadingStatus(LoadingStatus.None);
        }, 500);
        onUpload(response.secure_url);
      } else if (xhr.status !== 200 && xhr.readyState === 4) {
        // console.error(xhr.responseText);

        const response = JSON.parse(xhr.responseText);

        const error = response.error ? response.error : {};
        setSavedAlter(parentData.image ?? "");
        setError(true);
        setLoadingStatus(LoadingStatus.Error);
        setErrorMessage(error.message);
        setTimeout(() => {
          setLoadingStatus(LoadingStatus.None);
          setError(false);
        }, 3000);
      }
    };

    xhr.upload.addEventListener(
      "progress",
      (e) => {
        const percentage = (e.loaded / e.total) * 100;
        setLoadingStatus(LoadingStatus.Loading);
        setLoadingProgress(percentage);
      },
      false
    );

    // console.log("appending base64 in xhr file");
    // console.log(file);

    fd.append("upload_preset", "lo9yutst");
    fd.append("tags", "occasionally_app");
    fd.append("file", file);
    xhr.send(fd);
  };

  const handleSavedAlter = (base64: string) => {
    // console.log("photo alter base64");
    // console.log(base64);

    setOpenPhotoAlter(false);
    setSavedAlter(base64);
    uploadFile(base64);
  };

  const handleClick = (event: React.MouseEvent) => {
    if (allowUpload) {
      const target: any = event.target;
      const parentDiv = target.closest("div");
      parentDiv.children.fileElem.click();
    }
  };
  const classes = useStyles();

  const userPictureClass = classnames({
    [classes.userPicture]: true,
    [classes.userPictureVacant]: loadingStatus === LoadingStatus.None,
    [classes.userPictureLoading]: loadingStatus === LoadingStatus.Loading,
    [classes.userPictureError]: loadingStatus === LoadingStatus.Error,
    [classes.userPictureDone]: loadingStatus === LoadingStatus.Done,
    [classes.noUpload]: !allowUpload,
  });

  const imageUrl = savedAlter || parentData?.image;

  useEffect(() => {
    if (error) {
      const timeout = setTimeout(() => {
        setError(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [error]);

  return (
    <div className={classes.cloudinaryPhotoUpload}>
      <SecondaryModal title="Edit Photo" showModal={openPhotoAlter} closeModal={closePhotoAlter}>
        <PhotoAlterDialog onClose={closePhotoAlter} src={readFileUri} onSave={handleSavedAlter}></PhotoAlterDialog>
      </SecondaryModal>
      <Tooltip open={error} title={errorMessage}>
        <div className={userPictureClass} onClick={handleClick}>
          <input type="file" id="fileElem" accept="image/*" onChange={readFile} />
          {parentData?.text && !imageUrl && (
            <p className={classes.avatarText}>{parentData.text.charAt(0).toUpperCase()}</p>
          )}
          {imageUrl && (
            <LoadingCircle
              errorMessage={errorMessage}
              loadingProgress={loadingProgress}
              color={error ? "#CD5C5C" : "#5FB9A6"}
              url={imageUrl}
            />
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default CloudinaryPhotoUpload;
