import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  bigIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "70px",
    height: "100px",
    width: "100px",
    color: theme.palette.primary.main,
    opacity: 0.6,
    borderRadius: "50%",
  },
}));

type Props = {
  icon: string;
};

const BigIcon: FC<Props> = ({ icon }) => {
  const classes = useStyles();

  return <div className={classes.bigIcon + " lnr " + icon}></div>;
};

export default BigIcon;
