import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import { LinearProgress, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';

import useGiftIdeaRequest from '../../../hooks/useGiftIdeaRequest';
import { getGiftIdeasGroupedByInterest } from '../../../hooks/useGiftIdeasGroupedByInterest';
import { GiftIdeaRootState } from '../../../redux/giftideas/reducers';
import { OnboardingRootState } from '../../../redux/onboarding/reducers';
import { RootState } from '../../../redux/store';
import GiftIdea from '../../gifts/GiftIdea';
import GiftIdeaLoader from '../../gifts/GiftIdeaLoader';
import GiftMiniDialog from '../../gifts/GiftMiniDialog';
import { GiftIdea as GiftIdeaType, Product } from '../../gifts/types';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    background: theme.palette.background.background,
    color: theme.palette.text.primary,
  },
  giftFeedArea: {
    height: "70vh",
    overflowY: "scroll",
  },
  occasionSelectHeader: {
    background: theme.palette.primary.transparent,
    borderRadius: theme.shape.borderRadius,
    height: 115,
    padding: theme.spacing(1),
  },
  headerImage: {
    height: 105,
    marginBottom: -35,
    zIndex: 10,
  },
  confirmSelectionsButtonWrapper: {
    position: "fixed",
    bottom: 100,
    zIndex: 1000,
  },
  confirmSelectionsButton: {
    height: 56,
  },
  hide: {
    opacity: "0",
    transform: "translateY(20px)",
  },
  easing: {
    transition: ".25s " + theme.transitions.easing.easeInOut,
  },
  loadingContainer: {
    height: "70vh",
  },
  loadingGifts: {
    color: theme.palette.primary.main,
  },
  giftSelectionContainer: {
    height: "100%",
    padding: 16,
    paddingBottom: 0,
    maxWidth: 600,
  },
}));

const minGifts = 3;
const maxGifts = 10;

type Props = {} & Partial<StepWizardChildProps>;

const GiftSelection: FC<Props> = ({ nextStep, isActive }) => {
  const classes = useStyles();
  const giftIdeasState = useSelector<RootState, GiftIdeaRootState>((state) => {
    return state.giftIdeas;
  });
  const { giftTypes, gender } = useSelector<RootState, OnboardingRootState>((state) => {
    return state.onboarding;
  });

  const [giftLoading, setGiftLoading] = useState(true);
  const [error, setError] = useState(false);
  const [gifts, setGifts] = useState<Product[]>([]);
  const [getGiftIdeas] = useGiftIdeaRequest();
  const giftInterests = useMemo(
    () =>
      giftTypes.map((giftType) => {
        return { title: giftType };
      }),
    [giftTypes]
  );

  useEffect(() => {
    (async () => {
      if (isActive && gifts.length === 0) {
        try {
          const giftIdeaResponse = await getGiftIdeas({ interests: giftInterests, gender });
          if (Array.isArray(giftIdeaResponse)) {
            const giftsForSelection = getGiftIdeasGroupedByInterest(
              giftIdeaResponse,
              giftInterests.map((i) => i.title).filter(Boolean),
              20
            );
            const sortedGiftIdeas = Object.values(giftsForSelection)
              .flat()
              .sort((a, b) => {
                if ((b.similarity ?? 0) > (a.similarity ?? 0)) {
                  return 1;
                }
                return -1;
              });
            setGifts(sortedGiftIdeas);
          } else {
            setError(false);
          }
        } catch (e) {
          setError(true);
        }
        setGiftLoading(false);
      }
    })();
  }, [isActive]);

  const giftsSelectedLength = giftIdeasState.selectedGifts.length;
  const giftsSelectedIds = useMemo(
    () => giftIdeasState.selectedGifts.map((gift) => gift._id),
    [giftIdeasState.selectedGifts.length]
  );
  const giftSelectionProgress = (giftsSelectedLength / minGifts) * 100;

  const renderGifts = () => {
    if (!giftLoading) {
      return gifts.map((gift, i) => {
        if (gift !== null) {
          return (
            <GiftIdea
              selected={giftsSelectedIds.includes(gift._id)}
              disabled={giftsSelectedLength >= maxGifts}
              key={gift._id}
              index={i}
              product={gift as GiftIdeaType}
              multiSelect={true}
              showPrice={false}
              showLogo={false}
            />
          );
        } else {
          return;
        }
      });
    } else if (error) {
      return "An error occurred";
    } else {
      return (
        <>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((v) => (
            <GiftIdeaLoader key={v} />
          ))}
        </>
      );
    }
  };

  return (
    <div className={classes.form + " modal-content-no-margin"}>
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        className={classes.giftSelectionContainer}
      >
        <Grid
          container
          className={classes.occasionSelectHeader}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <div>
            <Typography variant="h6" align="left" gutterBottom>
              Pick gifts you like
            </Typography>
            <div>
              {giftsSelectedLength < maxGifts && (
                <Typography>
                  Select {minGifts} to {maxGifts} products
                </Typography>
              )}
              {giftsSelectedLength >= maxGifts && <Typography color="primary">Max products selected</Typography>}
              <LinearProgress
                color={giftsSelectedLength < minGifts ? "secondary" : "primary"}
                variant="determinate"
                value={giftSelectionProgress > 100 ? 100 : giftSelectionProgress}
              />
            </div>
          </div>
          <img className={classes.headerImage} src="/images/gift-collection.png" />
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          classes={{ container: "flex-1" }}
          wrap="nowrap"
          className={classes.giftFeedArea}
        >
          <Grid container direction="row" justifyContent="space-evenly" alignItems="center" className={classes.easing}>
            {renderGifts()}
          </Grid>
          <GiftMiniDialog />
        </Grid>
      </Grid>
    </div>
  );
};

export default GiftSelection;
