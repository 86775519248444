import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { useQueryUsersWhoAddedMe } from '../../../hooks/useQueryUsersWhoAddedMe';
import { setOccasionDate } from '../../../redux/new-occasion/actions';
import { months } from '../../../utils/constants/dateConstants';
import PrimaryButton from '../../general-ui/buttons/PrimaryButton';
import SecondaryButton from '../../general-ui/buttons/SecondaryButton';
import WizardStep from '../general-wizard-ui/WizardStep';
import { NewOccasionWizardSteps } from '../NewOccasionWizard';

const useStyles = makeStyles((theme: Theme) => ({
  formControlHeader: {
    marginBottom: theme.spacing(1),
    marginTop: "20px",
  },
  formControl: {
    width: "100%",
  },
  selectOccasionContent: {
    minHeight: "400px",
    width: "100%",
    height: "100%",
  },
  actionButtons: {
    width: "100%",
    height: 100,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
  },
}));

type Props = {
  isUser: boolean;
} & Partial<StepWizardChildProps>;

const SelectOccasionDate: FC<Props> = ({ isUser, goToStep, previousStep }) => {
  const classes = useStyles();
  const [occasionMonth, setOccasionMonth] = useState(0);
  const [occasionDate, setOccasionDay] = useState(1);
  const [users] = useQueryUsersWhoAddedMe();

  const dispatch = useDispatch();

  const handleGoToStep = (step: number) => {
    if (goToStep) {
      goToStep(step);
    }
  };

  let dates = [];

  for (var i = 1; i <= 31; i++) {
    dates.push(i);
  }

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newMonth = parseInt(e.target.value);
    setOccasionMonth(newMonth);
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newDate = parseInt(e.target.value);
    setOccasionDay(newDate);
  };

  const confirmOccasionDateHandler = () => {
    const dateObj = { day: occasionDate, month: occasionMonth };
    dispatch({ ...setOccasionDate(dateObj) });
    if (isUser && users.length > 0) {
      handleGoToStep(NewOccasionWizardSteps.SelectUsersToNotify);
    } else {
      handleGoToStep(NewOccasionWizardSteps.ConfirmOccasion);
    }
  };

  return (
    <WizardStep>
      <div className={classes.selectOccasionContent}>
        <div className={classes.formControl}>
          <Typography variant="body1" className={classes.formControlHeader}>
            Select the Month of the Occasion
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-month-native-simple">Occasion Month</InputLabel>
            <Select
              native
              value={occasionMonth}
              onChange={handleMonthChange as any}
              input={<OutlinedInput name="Occasion Month" id="outlined-month-native-simple" labelWidth={120} />}
            >
              <option value="" />
              {months.map((month, i) => (
                <option value={i} key={i}>
                  {month}
                </option>
              ))}
            </Select>
          </FormControl>
          <Typography variant="body1" className={classes.formControlHeader}>
            Select the Date of the Occasion
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-date-native-simple">Occasion Date</InputLabel>
            <Select
              native
              value={occasionDate}
              onChange={handleDateChange as any}
              input={<OutlinedInput name="Occasion Date" id="outlined-date-native-simple" labelWidth={120} />}
            >
              <option value="" />
              {dates.map((date) => (
                <option value={date} key={date}>
                  {date}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className={classes.actionButtons}>
        <PrimaryButton onClick={confirmOccasionDateHandler}>Confirm Occasion Date</PrimaryButton>
        <SecondaryButton onClick={previousStep as () => void}>
          <span className="lnr lnr-arrow-left"></span>
          &nbsp;Go Back
        </SecondaryButton>
      </div>
    </WizardStep>
  );
};

export default SelectOccasionDate;
