import gql from "graphql-tag";

export const insertOneUser = gql`
  mutation InsertOneUserMutation($data: UserInsertInput!) {
    insertOneUser(data: $data) {
      _id
    }
  }
`;

export const updateOneUser = gql`
  mutation UpdateOneUserMutation($query: UserQueryInput!, $set: UserUpdateInput!) {
    updateOneUser(query: $query, set: $set) {
      _id
      name
    }
  }
`;
