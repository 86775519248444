import React, { FC } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import BigIcon from '../../general-ui/BigIcon';
import UserActionCard from '../../general-ui/cards/UserActionCard';
import { AddGifteeWizardSteps } from '../AddGifteeWizard';
import WizardStep from '../general-wizard-ui/WizardStep';

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
}));

type Props = { hideModal: () => void } & Partial<StepWizardChildProps>;

const AddGifteePageOne: FC<Props> = ({ goToStep, hideModal }) => {
  const classes = useStyles();

  return (
    <WizardStep>
      <Grid
        className={classes.container}
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Typography variant="h4" align="center">
          How do you want to add your new Giftee?
        </Typography>
        <UserActionCard onClick={() => goToStep?.(AddGifteeWizardSteps.AddUserPageOne)}>
          <>
            <BigIcon icon="lnr-magnifier" />
            <Typography align="center" variant="h6">
              Add someone already on Occasional.ly
            </Typography>
          </>
        </UserActionCard>
        <UserActionCard onClick={() => goToStep?.(AddGifteeWizardSteps.InviteUserOrAddCustomGiftee)}>
          <>
            <BigIcon icon="lnr-user" />
            <Typography align="center" variant="h6">
              Add someone not on Occasional.ly
            </Typography>
          </>
        </UserActionCard>
      </Grid>
    </WizardStep>
  );
};

export default AddGifteePageOne;
