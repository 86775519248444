import { Observable } from "@apollo/client/utilities";
import { FetchResult } from "@apollo/client/link/core/types";

export const promiseToObservable = (promise: Promise<string | null | undefined>) =>
  new Observable<FetchResult<{ [key: string]: any }, Record<string, any>, Record<string, any>>>(
    (subscriber: Record<string, any>) => {
      promise.then(
        (value) => {
          if (subscriber.closed) return;
          subscriber.next(value);
          subscriber.complete();
        },
        (err) => subscriber.error(err)
      );
    }
  );
