import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { QueryResult } from '@apollo/client/react/types/types';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';

import {
  getGiftees, getGifteesVariables
} from '../../graphql/queries/giftees/__generated__/getGiftees';
import { setShowAddGifteeWizard } from '../../redux/add-giftee/actions';
import PrimaryButton from '../general-ui/buttons/PrimaryButton';
import GiftBoxMessage from '../general-ui/GiftBoxMessage';
import Loader from '../general-ui/Loader';
import ErrorState from '../general-ui/state-messages/ErrorState';
import Giftee from '../giftees/Giftee';

const useStyles = makeStyles((theme: Theme) => ({
  gifteesList: {
    width: "100%",
    overflowY: "scroll",
    maxHeight: "calc(100vh - 100px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "calc(100vh - 200px)",
    },
  },
  gifteesLoading: {
    marginTop: theme.spacing(2),
  },
  promptFirstGifteeContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    "& button": {
      maxWidth: 400,
    },
  },
  promptFirstGiftee: {
    maxWidth: 300,
    zIndex: 2,
    padding: theme.spacing(0.5),
  },
  promptFirstGifteeButton: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0.5),
  },
}));

type Props = {
  queryContext: QueryResult<getGiftees, getGifteesVariables>;
};

const GifteeList: FC<Props> = ({ queryContext }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleNewGifteeButton = () => {
    dispatch({ ...setShowAddGifteeWizard(true) });
  };

  const { data, loading, error } = queryContext;

  const renderGiftees = () => {
    if (loading) {
      return (
        <div className={classes.gifteesLoading}>
          <Loader />
        </div>
      );
    } else if (data?.giftees?.length === 0) {
      return (
        <div className={classes.promptFirstGifteeContainer}>
          <div className={classes.promptFirstGiftee}>
            <GiftBoxMessage primary="You don't have any giftees..." opacity={0.7} />
            <div className={classes.promptFirstGifteeButton}>
              <PrimaryButton onClick={handleNewGifteeButton}>Add Your First Giftee</PrimaryButton>
            </div>
          </div>
        </div>
      );
    } else if (data?.giftees) {
      return data?.giftees?.map((giftee) => giftee && <Giftee key={giftee._id} giftee={giftee} />);
    } else if (error) {
      console.error(error);
      return <ErrorState />;
    }
  };

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" wrap="wrap">
      <div className={classes.gifteesList}>{renderGiftees()}</div>
    </Grid>
  );
};

export default GifteeList;
