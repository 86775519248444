import "./stitch";
import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";

//material ui theme
import { MuiThemeProvider } from "@material-ui/core/styles";

import App from "./App";
import { AuthWrapper } from "./auth/AuthWrapper";
import GraphQLWrapper from "./graphql/GraphQLWrapper";
import { store } from "./redux/store";
import GlobalMessageWrapper from "./components/general-ui/global-messages/GlobalMessageWrapper";
import { darkTheme as theme } from "./theme";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import ErrorPage from "./components/general-ui/state-messages/ErrorPage";
import ThemeWrapper from "./ThemeWrapper";

export const renderRoutes = () => {
  const releaseStage = window.location.hostname === "app.occasional.ly" ? "production" : "development";
  Bugsnag.start({
    apiKey: "2c57b037be00cbfbf5a98269b31e3099",
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ["production"],
    releaseStage,
  });

  const ErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React);

  if (!ErrorBoundary) {
    return (
      <MuiThemeProvider theme={theme}>
        <AuthWrapper>
          <GraphQLWrapper>
            <ReduxProvider store={store}>
              <GlobalMessageWrapper>
                <App />
              </GlobalMessageWrapper>
            </ReduxProvider>
          </GraphQLWrapper>
        </AuthWrapper>
      </MuiThemeProvider>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <AuthWrapper>
        <GraphQLWrapper>
          <ReduxProvider store={store}>
            <ThemeWrapper>
              <GlobalMessageWrapper>
                <App />
              </GlobalMessageWrapper>
            </ThemeWrapper>
          </ReduxProvider>
        </GraphQLWrapper>
      </AuthWrapper>
    </ErrorBoundary>
  );
};

ReactDOM.render(renderRoutes(), document.querySelector("#root"));
