import 'date-fns';

import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { getOccasion_occasion } from '../../../graphql/queries/occasions/__generated__/getOccasion';
import { removeOccasion, selectOccasion } from '../../../redux/add-giftee/actions';
import { AddGifteeRootState } from '../../../redux/add-giftee/reducers';
import { RootState } from '../../../redux/store';
import { wizardModalStyles } from '../../../utils/styleHelpers';
import PrimaryButton from '../../general-ui/buttons/PrimaryButton';
import SecondaryButton from '../../general-ui/buttons/SecondaryButton';
import OccasionSelectionList, { occasionSelection } from '../../occasions/OccasionSelectionList';
import WizardStep from '../general-wizard-ui/WizardStep';

const useStyles = makeStyles((theme: Theme) => ({
  modalContentNoMargin: wizardModalStyles(theme),
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    background: theme.palette.background.background,
    overflowY: "scroll",
  },
  formHeaderText: {
    color: theme.palette.text.primary,
    marginBottom: 10,
  },
  label: {
    color: theme.palette.text.primary,
    marginBottom: "5px",
  },
  submitButton: {
    width: "100%",
  },
  flexOne: {
    flex: 1,
  },
  formControl: {
    width: "100%",
    margin: "4px 0px",
    "& label": {
      color: theme.palette.common.white,
    },
    "& .Mui-focused select option": {
      background: theme.palette.background.background,
    },
  },
  input: {
    color: theme.palette.common.white,
    "& fieldset": {
      borderColor: theme.palette.common.white,
    },
    "& label": {
      color: theme.palette.common.white,
    },
  },
}));

type Props = {
  hideModal: () => void;
} & Partial<StepWizardChildProps>;

const AddCustomGiftee: FC<Props> = ({ previousStep, nextStep }) => {
  const dispatch = useDispatch();
  const addGifteeState = useSelector<RootState, AddGifteeRootState>((state) => {
    return state.addGiftee;
  });
  const { occasions } = addGifteeState;
  const selectedOccasionTypes = occasions.map((occasion) => occasion.type ?? "");
  const classes = useStyles();

  let dates = [];

  for (var i = 1; i <= 31; i++) {
    dates.push(i);
  }
  const onSelectOccasion = (occasion: occasionSelection) => {
    dispatch({
      ...selectOccasion(occasion),
    });
  };

  const deselectOccasion = (occasion: Partial<getOccasion_occasion>) => {
    dispatch({ ...removeOccasion(occasion) });
  };

  return (
    <WizardStep>
      <>
        <Grid
          container
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          classes={{ container: "flex-2" }}
        >
          <Grid
            container
            className={classes.formHeaderText}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h4" align="center" gutterBottom>
              Select Occasions
            </Typography>
            <Typography variant="body1" align="center" gutterBottom>
              Select the occasions that you want to remember for this giftee, then click "Confirm Occasions" to finish
            </Typography>
          </Grid>
        </Grid>
        <OccasionSelectionList
          onDeselectOccasion={deselectOccasion}
          onSelectOccasion={onSelectOccasion}
          selectedOccasionTypes={selectedOccasionTypes}
        />
      </>
    </WizardStep>
  );
};

export default AddCustomGiftee;
