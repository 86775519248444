import React, { FC, useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
// import { Meteor } from 'meteor/meteor';
import { makeStyles, Theme } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import { getNotifications_notifications as NotificationType } from "../../graphql/queries/notifications/__generated__/getNotifications";
import PrimaryButton from "../general-ui/buttons/PrimaryButton";
import { useDispatch } from "react-redux";
import { setShowImportGifteeWizard, selectUser } from "../../redux/add-giftee/actions";
import { closeNotificationsMenu } from "../../redux/general/actions";
import useTheme from "@material-ui/core/styles/useTheme";

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    height: 60,
    width: 60,
    marginRight: 10,
  },
  notificationWrapper: {
    marginBottom: 10,
  },
  notification: {
    height: "100px",
    padding: "0px",
    paddingLeft: "10px",
    boxShadow: theme.shadows[2],
  },
  notificationButtons: {
    height: "100%",
  },
  notificationButtonAdd: {
    height: "100%",
    width: "100px",
    borderRadius: "0px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    "&:hover": {
      background: theme.palette.error.dark,
    },
  },
}));

type Props = {
  notification: NotificationType;
  timeAgo: string;
};
const NotificationApprovedGiftee: FC<Props> = ({ notification, timeAgo }) => {
  const theme = useTheme();
  const { requestee } = notification;
  const [openExpansion, setOpenExpansion] = useState(false);
  const dispatch = useDispatch();

  const toggleExpansion = () => {
    setOpenExpansion(!openExpansion);
  };

  const importGiftee = () => {
    dispatch({ ...setShowImportGifteeWizard(true) });
    dispatch({ ...closeNotificationsMenu() });
    if (requestee) {
      dispatch({ ...selectUser(requestee) });
    }
  };

  const classes = useStyles();

  const userAvatar = requestee?.picture ? (
    <ListItemAvatar>
      <Avatar src={requestee?.picture} className={classes.avatar} />
    </ListItemAvatar>
  ) : (
    <ListItemAvatar>
      <Avatar className={classes.avatar}>{requestee?.firstName?.charAt(0) ?? requestee?.email?.charAt(0)}</Avatar>
    </ListItemAvatar>
  );

  return (
    <div className={classes.notificationWrapper}>
      <ListItem button key={notification._id} className={classes.notification} onClick={toggleExpansion}>
        {userAvatar}
        <ListItemText secondary={timeAgo}>
          <span style={{ color: theme.palette.text.primaryBranded }}>{requestee?.name ?? requestee?.email}</span> has
          approved your giftee request!
        </ListItemText>
        {openExpansion ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openExpansion} timeout="auto" unmountOnExit>
        <ListItem className={classes.notificationButtons} disableGutters>
          <PrimaryButton onClick={importGiftee}>Add Occasions</PrimaryButton>
        </ListItem>
      </Collapse>
    </div>
  );
};

export default NotificationApprovedGiftee;
