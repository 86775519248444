import React, { FC, useState } from 'react';

import { OutlinedInput } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import GradientButton from '../components/general-ui/buttons/GradientButton';
import app from '../realm';

const useStyles = makeStyles((theme) => ({
  forgotPassPage: {
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.primary.gradient,
  },
  emailInput: {
    width: "100%",
  },
  forgotPassForm: {
    padding: 16,
  },
  submitButton: {
    "& button": {
      padding: "15px 13px",
    },
  },
}));

const ForgotPassword: FC = () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    setEmail(value);
  };

  const handleSubmit = async () => {
    try {
      await app.emailPasswordAuth.sendResetPasswordEmail(email);
    } catch (e) {
      console.error(e);
    } finally {
      setSubmitted(true);
    }
  };

  const potentialSubmit = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className={classes.forgotPassPage}>
      <Card className={classes.forgotPassForm}>
        <Typography variant="h5" gutterBottom>
          Recover Password
        </Typography>
        {!submitted ? (
          <>
            <Typography variant="h6" gutterBottom>
              Don't worry, happens to the best of us.
            </Typography>
            <Typography variant="body2" gutterBottom>
              Please provide your email
            </Typography>
            <OutlinedInput
              label="Email"
              onChange={handleEmailChange}
              onKeyUp={potentialSubmit}
              value={email}
              name="email"
              className={classes.emailInput}
            />
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: 20 }}
              className={classes.submitButton}
            >
              <GradientButton onClick={handleSubmit}>Email me a Recovery link</GradientButton>
            </Grid>
          </>
        ) : (
          <>
            <Typography variant="h6" gutterBottom>
              Your password recovery request has been sent
            </Typography>
            <Typography variant="body2" gutterBottom>
              If there is an account associated with this email, a recovery link will be sent to it.
            </Typography>
          </>
        )}
      </Card>
    </div>
  );
};

export default ForgotPassword;
