import React, { FC, useState } from "react";

import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import IconFormFieldWrapper from "./IconFormFieldWrapper";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    borderRadius: 30,
    background: theme.palette.background.paper2DP,
  },
  inputWrap: {
    width: "calc(100% - 56px)",
    border: "none",
  },
  inputWrapNoIcon: {
    width: "100%",
    border: "none",
  },
  input: {
    height: 56,
    width: "100%",
    borderRadius: 30,
    "& input": {
      boxShadow: "none",
    },
    "& fieldset": {
      borderRadius: 30,
      marginLeft: -56,
      border: "none",
    },
    "& label": {
      transform: "translate(14px, 2px) scale(0.75) !important",
    },
    "& .Mui-disabled": {
      color: theme.palette.text.primary,
    },
  },
  icon: {
    background: theme.palette.text.overlay,
    height: 56,
    width: 56,
    borderRadius: "50%",
    color: theme.palette.text.hint,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .lnr": {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: "0.25s cubic-bezier(0.4, 0.0, 0.2, 1)",
    },
    "& .lnr-pencil": {
      color: theme.palette.primary.dark,
    },
    "& .lnr-checkmark-circle": {
      color: theme.palette.primary.main,
    },
    "& .lnr-cross-circle": {
      color: theme.palette.secondary.main,
    },
    "& .lnr-notification-circle": {
      color: theme.palette.text.primary,
      boxShadow: `0px 0px 8px ${theme.palette.warning.dark}`,
    },
    "& .hide": {
      opacity: 0,
    },
  },
}));

type Props = {
  type?: string;
  value: string;
  invalidation?: boolean;
  confirmation?: boolean;
  icon: string;
  label?: string;
  name?: string;
  missingInfo?: boolean;
  changeCallback?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  keyPressCallback?: (event: React.KeyboardEvent<Element>) => void;
  className?: string;
  disabled?: boolean;
  onBlur?: (e?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

const IconInput: FC<Props> = ({
  type,
  value,
  invalidation,
  confirmation,
  missingInfo,
  icon,
  label,
  name,
  changeCallback,
  keyPressCallback,
  className,
  disabled,
  onBlur,
}) => {
  let inputType = "text";

  if (type) {
    inputType = type;
  }

  const [focus, setFocus] = useState(false);

  const nonStandard = focus || confirmation || invalidation || missingInfo;

  const classes = useStyles();

  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFocus(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <IconFormFieldWrapper
      focus={focus}
      confirmation={confirmation}
      invalidation={invalidation}
      icon={icon}
      missingInfo={missingInfo}
    >
      <TextField
        name={name ?? label}
        data-testid={label}
        label={label}
        type={inputType}
        onFocus={() => setFocus(true)}
        value={value ? value : ""}
        onChange={changeCallback}
        onBlur={handleBlur}
        onKeyPress={keyPressCallback}
        disabled={disabled}
        className={classes.input + " " + className}
        variant="outlined"
      />
    </IconFormFieldWrapper>
  );
};

export default IconInput;
