import React, { FC, useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    borderRadius: 30,
    background: theme.palette.background.paper2DP,
  },
  inputWrap: {
    width: "calc(100% - 56px)",
    border: "none",
  },
  inputWrapNoIcon: {
    width: "100%",
    border: "none",
  },
  input: {
    height: 56,
    width: "100%",
    borderRadius: 30,
    "& input": {
      boxShadow: "none",
    },
    "& fieldset": {
      borderRadius: 30,
      marginLeft: -56,
      border: "none",
    },
    "& label": {
      transform: "translate(14px, 2px) scale(0.75) !important",
    },
    "& .Mui-disabled": {
      color: theme.palette.text.primary,
    },
  },
  icon: {
    background: theme.palette.text.overlay,
    height: 56,
    width: 56,
    borderRadius: "50%",
    color: theme.palette.text.hint,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .lnr": {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: "0.25s cubic-bezier(0.4, 0.0, 0.2, 1)",
    },
    "& .lnr-pencil": {
      color: theme.palette.primary.dark,
    },
    "& .lnr-checkmark-circle": {
      color: theme.palette.primary.main,
    },
    "& .lnr-cross-circle": {
      color: theme.palette.secondary.main,
    },
    "& .lnr-notification-circle": {
      color: theme.palette.text.primary,
      boxShadow: `0px 0px 8px ${theme.palette.warning.dark}`,
    },
    "& .hide": {
      opacity: 0,
    },
  },
  loading: {
    position: "absolute",
    top: 0,
    left: 0,
  },
}));

type Props = {
  type?: string;
  invalidation?: boolean;
  confirmation?: boolean;
  icon: string;
  missingInfo?: boolean;
  className?: string;
  focus?: boolean;
  loading?: boolean;
};

const IconFormFieldWrapper: FC<Props> = ({
  type,
  invalidation,
  confirmation,
  missingInfo,
  icon,
  children,
  focus,
  loading,
}) => {
  let inputType = "text";
  if (type) {
    inputType = type;
  }

  const nonStandard = focus || confirmation || invalidation || missingInfo;

  const classes = useStyles();

  return (
    <Grid container spacing={0} alignItems="flex-end" className={classes.wrapper}>
      {icon ? (
        <Grid item className={classes.icon}>
          <Icon>
            {loading && <CircularProgress size={56} thickness={3} color="primary" className={classes.loading} />}
            <span className={nonStandard ? "hide lnr " + icon : "lnr " + icon}></span>
            <span className={focus ? "lnr lnr-pencil" : "lnr lnr-pencil hide"}> </span>
            <span className={confirmation ? "lnr lnr-checkmark-circle" : "lnr lnr-checkmark-circle hide"}> </span>
            <span className={invalidation ? "lnr lnr-cross-circle" : "lnr lnr-cross-circle hide"}> </span>
            <span className={missingInfo ? "lnr lnr-notification-circle" : "lnr lnr-notification-circle hide"}> </span>
          </Icon>
        </Grid>
      ) : (
        ""
      )}
      <Grid item className={icon ? classes.inputWrap : classes.inputWrapNoIcon}>
        {children}
      </Grid>
    </Grid>
  );
};

export default IconFormFieldWrapper;
