import React, { FC } from "react";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useRouteMatch } from "react-router-dom";

type Props = {
  children: React.ReactNode;
  route: string;
  index: number;
  className?: string;
};

const TabPanelRoute: FC<Props> = ({ children, route, index, className, ...other }) => {
  const match = useRouteMatch(route);
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={!match?.isExact}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={className}
      {...other}
    >
      {match?.isExact && (
        <Box p={3} className={className}>
          {children}
        </Box>
      )}
    </Typography>
  );
};

export default TabPanelRoute;
