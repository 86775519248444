import classnames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import React, { FC, useState } from 'react';

import { useMutation } from '@apollo/client';
import DateUtils from '@date-io/date-fns';
import { CircularProgress, LinearProgress, Typography } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { Reminder as ReminderType } from '../../@types/reminders';
import { deleteOneReminder, updateOneReminder } from '../../graphql/mutations/reminders';
import {
  DeleteOneReminderMutation, DeleteOneReminderMutationVariables
} from '../../graphql/mutations/reminders/__generated__/DeleteOneReminderMutation';
import {
  UpdateOneReminderMutation, UpdateOneReminderMutationVariables
} from '../../graphql/mutations/reminders/__generated__/UpdateOneReminderMutation';
import { getRemindersQuery } from '../../graphql/queries/reminders';
import AnimatedDelete from '../general-ui/AnimatedDelete';
import DeleteButton from '../general-ui/buttons/DeleteButton';
import SecondaryButton from '../general-ui/buttons/SecondaryButton';
import TertiaryButton from '../general-ui/buttons/TertiaryButton';
import GlobalMessageDispatch from '../general-ui/global-messages/GlobalMessageDispatch';
import SecondaryModal from '../general-ui/modals/SecondaryModal';

dayjs.extend(advancedFormat);
dayjs.extend(calendar);

const useStyles = makeStyles((theme: Theme) => ({
  reminderContainer: {
    position: "relative",
  },
  loadingBar: {
    position: "absolute",
    bottom: 4,
    left: 0,
    right: 0,
  },
  reminder: {
    display: "flex",
    alignItems: "center",
    borderRadius: "30px",
    margin: 10,
    marginBottom: 18,
    height: 50,
    width: 50,
    transition: ".2s " + theme.transitions.easing.easeInOut,
  },
  datePicker: {
    margin: "0px 16px",
    color: theme.palette.text.primary,
    border: "1px solid #ced4da",
    padding: "0px 4px",
    borderRadius: 15,
    height: 40,
    justifyContent: "center",
    "& .MuiPickersDay-dayDisabled": {
      opacity: 0.5,
    },
    "& .MuiInput-root": {
      justifyContent: "center",
    },
    "& .MuiIconButton-root": {
      visibility: "hidden",
    },
    "& .MuiInput-root:before": {
      content: "'\\e789'",
      fontFamily: "Linearicons",
      color: theme.palette.text.primary,
      position: "absolute",
      left: "initial",
      right: 0,
      top: 0,
      bottom: 0,
      border: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      fontSize: 20,
    },
  },
  iconButton: {
    height: 50,
    width: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    color: theme.palette.text.primary,
  },
  reminderExpanded: {
    width: "calc(100% - 10px)",
  },
  reminderNotFired: {
    background: theme.palette.background.activatedPaper2,
  },
  reminderFired: {
    background: theme.palette.info.dark,
    color: theme.palette.text.primary,
    "& button": {
      position: "relative",
    },
    "& button:after": {
      position: "absolute",
      bottom: -28,
      content: '""',
      height: 28,
      width: 1,
      border: "1px dashed " + theme.palette.info.dark,
    },
  },
  reminderInfo: {
    position: "absolute",
    top: "calc(50% - 30px)",
    left: 70,
    height: 50,
    color: theme.palette.text.primary,
    borderBottom: "1px solid white",
    width: "calc(100% - 70px)",
    display: "flex",
    "& span": {
      fontSize: 20,
    },
  },
  alreadySentInfo: {
    color: theme.palette.info.dark,
  },
  reminderDelete: {
    textDecoration: "none !important",
  },
  reminderExpandButton: {
    width: 20,
    maxHeight: 20,
    minHeight: 20,
    fontSize: 18,
  },
  reminderExpandButtonSent: {
    background: theme.palette.info.dark,
  },
}));

type Props = {
  reminder: ReminderType;
};

const formatReminderDate = (date: string | null) => {
  return new Date(date ?? "").toLocaleDateString();
};

const Reminder: FC<Props> = ({ reminder }) => {
  const [deleteReminder, deleteReminderContext] = useMutation<
    DeleteOneReminderMutation,
    DeleteOneReminderMutationVariables
  >(deleteOneReminder);
  const [updateReminder, updateReminderContext] = useMutation<
    UpdateOneReminderMutation,
    UpdateOneReminderMutationVariables
  >(updateOneReminder);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [reminderDate, setReminderDate] = useState<null | Dayjs>(dayjs(reminder.date ?? ""));

  const handleReminderDateChange = (date: MaterialUiPickersDate) => {
    updateReminder({
      variables: {
        query: {
          _id: reminder._id,
        },
        set: {
          date: date,
        },
      },
      refetchQueries: [
        {
          query: getRemindersQuery,
          variables: {
            query: {
              occasion: {
                _id: reminder.occasion?._id,
              },
            },
          },
        },
      ],
    });
    setReminderDate(dayjs(date));
  };

  const toggleReminderDetails = () => {
    setShowDetails(!showDetails);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const deleteReminderHandler = async () => {
    // Meteor.call("reminders.remove", this.props.reminder._id);
    await deleteReminder({
      variables: {
        query: {
          _id: reminder._id,
        },
      },
      refetchQueries: [
        {
          query: getRemindersQuery,
          variables: {
            query: {
              occasion: {
                _id: reminder.occasion?._id,
              },
            },
          },
        },
      ],
    });
    const { loading, error } = deleteReminderContext;
    if (!error && !loading) {
      //success
      setShowDeleteModal(false);
      setShowDeleteSuccess(true);
    } else {
      // handle error
    }
  };

  const classes = useStyles();

  const reminderClass = classnames({
    [classes.reminderExpanded]: showDetails,
    [classes.reminder]: true,
  });

  if (reminder.fired === true) {
    return (
      <div className={classes.reminderContainer}>
        <Paper classes={{ root: classes.reminderFired + " " + classes.reminder }}>
          <IconButton className={classes.iconButton}>
            <Icon>
              <span className="lnr lnr-check"></span>
            </Icon>
          </IconButton>
        </Paper>
        <div className={classes.reminderInfo}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            wrap="nowrap"
            className={classes.alreadySentInfo}
          >
            <Grid container direction="column" justifyContent="center" alignItems="flex-start" className="flex-1">
              <span>{formatReminderDate(reminder.date)}</span>
            </Grid>
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" className="flex-1">
              Already Sent &nbsp;
              <Fab
                size="small"
                aria-label="Add"
                color="secondary"
                className={classes.reminderExpandButton + " " + classes.reminderExpandButtonSent}
              >
                <Icon>check</Icon>
              </Fab>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.reminderContainer}>
        <Paper classes={{ root: classes.reminderNotFired + " " + reminderClass }}>
          <IconButton onClick={toggleReminderDetails} className={classes.iconButton}>
            <Icon>
              <span className="lnr lnr-bullhorn"></span>
            </Icon>
          </IconButton>
          {showDetails ? (
            <Grid container direction="row" justifyContent="space-between" alignItems="center" wrap="nowrap">
              <div>
                <MuiPickersUtilsProvider utils={DateUtils}>
                  <DatePicker
                    className={classes.datePicker}
                    disablePast={true}
                    value={reminderDate}
                    onChange={handleReminderDateChange}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div>
                <DeleteButton onClick={toggleDeleteModal} />
              </div>
            </Grid>
          ) : (
            " "
          )}
          <SecondaryModal
            title="Delete Reminder"
            showModal={showDeleteModal}
            secondModal={true}
            closeModal={toggleDeleteModal}
          >
            <>
              {deleteReminderContext.loading && (
                <Grid container justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Grid>
              )}
              {!deleteReminderContext.loading && (
                <>
                  <Typography align="center" variant="body1" gutterBottom>
                    Delete this reminder scheduled for {formatReminderDate(reminder.date)}?
                  </Typography>
                  <Grid container wrap="nowrap">
                    <TertiaryButton onClick={toggleDeleteModal}>No</TertiaryButton>
                    <SecondaryButton onClick={deleteReminderHandler}>Yes</SecondaryButton>
                  </Grid>
                </>
              )}
            </>
          </SecondaryModal>
        </Paper>
        {!showDetails ? (
          <div className={classes.reminderInfo}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" wrap="nowrap">
              <Grid container direction="column" justifyContent="center" alignItems="flex-start" className="flex-1">
                <span>{formatReminderDate(reminder.date)}</span>
              </Grid>
              <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" className="flex-1">
                EDIT &nbsp;
                <Fab
                  size="small"
                  aria-label="Add"
                  className={classes.reminderExpandButton}
                  onClick={toggleReminderDetails}
                >
                  <Icon>edit</Icon>
                </Fab>
              </Grid>
            </Grid>
          </div>
        ) : (
          ""
        )}
        {updateReminderContext.loading && (
          <div className={classes.loadingBar}>
            <LinearProgress />
          </div>
        )}
        {showDeleteSuccess && (
          <GlobalMessageDispatch>
            <Grid container direction="row" alignItems="center">
              <AnimatedDelete
                otherStyles={{ width: 40, height: 40 }}
                checked={true}
                icon={
                  <Icon>
                    <span className="lnr lnr-bullhorn"></span>
                  </Icon>
                }
              />

              <Typography variant="body1" color="inherit">
                &nbsp;Reminder deleted
              </Typography>
            </Grid>
          </GlobalMessageDispatch>
        )}
      </div>
    );
  }
};

export default Reminder;
