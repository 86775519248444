import React, { FC, useState } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { GiftProvider } from '../../rescript/entities/Entity__GiftProvider.gen';

type Props = {
  provider?: GiftProvider | null;
};

const useStyles = makeStyles((theme) => ({
  retailerLogo: {
    background: theme.palette.common.white,
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
}));

const RetailerLogo: FC<Props> = ({ provider }) => {
  const [error, setError] = useState(false);
  const classes = useStyles();
  if (error) {
    return null;
  }
  return provider ? (
    <img onError={() => setError(true)} className={classes.retailerLogo} src={provider.logo ?? ""} />
  ) : null;
};

export default RetailerLogo;
