import React, { FC, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import OnboardingWizard from "../components/wizards/OnboardingWizard";
import { getAccessToken } from "../realm/auth";
import { useAuthContext } from "../auth/authContext";
import { AuthActionTypes } from "../@types/auth";
import { useDispatch } from "react-redux";
import { setName } from "../redux/onboarding/actions";

const useStyles = makeStyles((theme) => ({
  "@global": {
    html: { background: theme.palette.background.background },
  },
  background: {
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.background.background,
  },
}));

const Welcome: FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { authDispatch, authState } = useAuthContext();

  useEffect(() => {
    const { customData } = authState.user;
    dispatch({ ...setName(customData.name ?? "") });
  }, []);

  //Users can land here with stale authentications, let's try to refresh
  useEffect(() => {
    (async () => {
      const accessToken = await getAccessToken();
      if (accessToken != null) {
        authDispatch({
          type: AuthActionTypes.SET_TOKEN,
          payload: { accessToken },
        });
      }
    })();
  }, []);

  return (
    <div className={classes.background}>
      <OnboardingWizard />
    </div>
  );
};

export default Welcome;
