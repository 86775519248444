import gql from "graphql-tag";

export const getOccasionQuery = gql`
  query getOccasion($query: OccasionQueryInput) {
    occasion(query: $query) {
      _id
      createdAt
      name
      userId
      annual
      giftee {
        _id
        picture
        name
      }
      purchases {
        targetYear
        giftIdeaId
        transactionId
        date
      }
      dayMonthDate {
        day
        month
      }
      nextOccasionDate
      type
    }
  }
`;
export const getOccasionsQuery = gql`
  query getOccasions($query: OccasionQueryInput) {
    occasions(query: $query, sortBy: NEXTOCCASIONDATE_ASC) {
      _id
      createdAt
      name
      userId
      annual
      purchases {
        targetYear
        giftIdeaId
        transactionId
        date
      }
      dayMonthDate {
        day
        month
      }
      nextOccasionDate
      type
      giftee {
        _id
        picture
        name
      }
    }
  }
`;
