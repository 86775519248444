import React, { FC, useEffect } from "react";
import GlobalSnackbar from "./GlobalSnackbar";
import { useGlobalMessageContext } from "./globalMessageContext";
import { GlobalMessage } from "../../../@types/global-messages";
import { Grid } from "@material-ui/core";

const GlobalMessageFeed: FC = () => {
  const { globalMessageState } = useGlobalMessageContext();
  return (
    <Grid container direction="column">
      {globalMessageState.globalMessages.map((message: GlobalMessage, index: number) => (
        <div key={index}>
          <GlobalSnackbar countdown={message.countdown} callback={message.callback} notCloseable={message.notCloseable}>
            {message.children}
          </GlobalSnackbar>
        </div>
      ))}
    </Grid>
  );
};

export default GlobalMessageFeed;
