import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import MobileMenuTabs from "./MobileMenuTabs";

const useStyles = makeStyles((theme) => ({
  mobileNav: {
    position: "fixed",
    bottom: -1,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.appBar,
  },
}));

type Props = {
  homeRoutes: ReadonlyArray<{
    to?: string;
    onClick?: () => void;
    label: string;
    icon: string;
    badge?: React.ReactNode;
  }>;
};

const HomeMobileMenu: FC<Props> = ({ homeRoutes }) => {
  const notificationPanelShow = useSelector<RootState, boolean>((state) => {
    return state.general.notificationPanelShow;
  });
  const classes = useStyles();
  return (
    <div className={classes.mobileNav}>
      <MobileMenuTabs
        tabs={homeRoutes}
        activeTab={notificationPanelShow ? 3 : homeRoutes.findIndex((r) => r.to === window.location.pathname) ?? 0}
      />
    </div>
  );
};

export default HomeMobileMenu;
