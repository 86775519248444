import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import React, { FC, useMemo } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { GifteeRelationship } from "../../__generated__/globalTypes";
import { toSentenceCase } from "../../utils/helpers/stringHelper";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "100%",
    margin: 0,
    "& label": {
      color: theme.palette.text.primary,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(16px, 2px) scale(0.75)",
    },
    "& .Mui-focused select option": {
      background: theme.palette.background.background,
    },
  },
  input: {
    color: theme.palette.text.primary,
    "& fieldset": {
      borderColor: theme.palette.text.primary,
    },
    "& label": {
      color: theme.palette.text.primary,
    },
  },
  inputNoBorder: {
    color: theme.palette.text.primary,
    "& fieldset": {
      border: "none",
    },
    "& label": {
      color: theme.palette.text.primary,
    },
  },
}));

export const gifteeRelationshipStartPhrase = "This giftee is my..." as const;

export type GifteeRelationshipStartPhraseType = typeof gifteeRelationshipStartPhrase;

type Props = {
  value: GifteeRelationship | GifteeRelationshipStartPhraseType;
  onChange: any;
  noBorder?: boolean;
};

export const getRelationshipTypes: () => ReadonlyArray<{ value: GifteeRelationship; name: string }> = () => {
  const values = Object.values<GifteeRelationship>(GifteeRelationship);
  return values.map((v) => ({ value: v, name: toSentenceCase(v.toLowerCase()).replaceAll("_", "-") }));
};

const GifteeRelationshipSelect: FC<Props> = ({ value, onChange, noBorder }) => {
  const classes = useStyles();
  const relationshipTypesArray = useMemo(() => getRelationshipTypes(), []);
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel htmlFor="outlined-month-native-simple" shrink={true}>
        Relationship
      </InputLabel>
      <Select
        native
        value={value}
        onChange={onChange as any}
        input={
          <OutlinedInput
            name="Relationship"
            labelWidth={100}
            className={noBorder ? classes.inputNoBorder : classes.input}
          />
        }
      >
        <option value={gifteeRelationshipStartPhrase}>{gifteeRelationshipStartPhrase}</option>
        {relationshipTypesArray.map((relationship, i) => (
          <option value={relationship.value} key={i}>
            {relationship.name}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export default GifteeRelationshipSelect;
