import gql from "graphql-tag";

export const getNotificationSettingQuery = gql`
  query getNotificationSetting($query: NotificationSettingQueryInput) {
    notificationSetting(query: $query) {
      _id
      notificationTypes {
        deviceName
        type
        deviceType
        deviceToken
        enabled
      }
    }
  }
`;
export const getNotificationSettingsQuery = gql`
  query getNotificationSettings($query: NotificationSettingQueryInput) {
    notificationSettings(query: $query) {
      _id
      notificationTypes {
        deviceName
        type
        deviceType
        deviceToken
        enabled
      }
    }
  }
`;
