// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/styles";

var useStyles = Styles.makeStyles(function (theme) {
      return {
              giftTypeSamplesWrapper: {
                color: theme.palette.text.primary,
                display: "flex",
                margin: "4px",
                marginTop: "16px",
                alignItems: "center",
                flexWrap: "wrap"
              },
              giftTypeSamplesWrapperSelected: {
                color: theme.palette.common.white
              },
              buttonDisabled: {
                backgroundColor: theme.palette.primary.main + " !important",
                color: theme.palette.common.white + " !important"
              },
              icon: {
                marginRight: "8px"
              }
            };
    });

function GiftCategorySelectButton(Props) {
  var onClick = Props.onClick;
  var category = Props.category;
  var selected = Props.selected;
  var icon = Props.icon;
  var disabled = Props.disabled;
  var classes = useStyles();
  var match = selected ? [
      classes.giftTypeSamplesWrapper + " " + classes.giftTypeSamplesWrapperSelected,
      "contained",
      "lnr lnr-check " + classes.icon
    ] : [
      classes.giftTypeSamplesWrapper,
      "outlined",
      "lnr " + icon + " " + classes.icon
    ];
  var classes$1 = {
    root: match[0],
    disabled: classes.buttonDisabled
  };
  return React.createElement(Core.Button, {
              onClick: (function (param) {
                  return Curry._1(onClick, category);
                }),
              children: null,
              classes: classes$1,
              color: "primary",
              disabled: disabled,
              variant: match[1]
            }, React.createElement("span", {
                  className: match[2]
                }), category);
}

var make = GiftCategorySelectButton;

export {
  useStyles ,
  make ,
  
}
/* useStyles Not a pure module */
