import React, { FC } from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  loadingCircle: {
    transition: "stroke-dashoffset .2s linear",
    transform: "rotate(45deg)",
    transformOrigin: "center",
  },
}));

type Props = {
  url?: string;
  color?: string;
  loadingProgress: number;
  errorMessage: string;
};

const LoadingCircle: FC<Props> = ({ url, color, loadingProgress }) => {
  const classes = useStyles();

  return (
    <svg width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="circleView">
          <circle cx="75" cy="75" r="75" />
        </clipPath>
      </defs>
      {url ? (
        <image
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid slice"
          xlinkHref={url}
          clipPath="url(#circleView)"
        />
      ) : (
        " "
      )}
      <circle
        cx="75"
        cy="75"
        r="72"
        fill="none"
        stroke={color}
        strokeWidth="4"
        strokeDasharray="500"
        strokeDashoffset={500 - loadingProgress * ((1 / 100) * 500)}
        className={classes.loadingCircle}
      ></circle>
    </svg>
  );
};

export default LoadingCircle;
