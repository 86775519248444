import React, { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon/Icon';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import useIsMobile from '../../../hooks/useIsMobile';
import { addInvite, removeInvite } from '../../../redux/onboarding/actions';
import { OnboardingRootState } from '../../../redux/onboarding/reducers';
import { RootState } from '../../../redux/store';
import { validateEmail } from '../../../utils/helpers/stringHelper';
import GlobalMessageDispatch from '../../general-ui/global-messages/GlobalMessageDispatch';
import MultiChipTextField from '../../general-ui/MultiChipTextField';
import { OnboardingWizardSteps } from '../OnboardingWizard';
import OnboardingStep from './OnboardingStep';

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "space-evenly",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
    background: theme.palette.background.background,
    color: theme.palette.text.primary,
    // padding: "60px 16px",
    // height: "calc(100vh - 120px)",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  mainImage: {
    height: 180,
    objectFit: "contain",
  },
  headlineContainer: {},
  headline: {
    flex: 1,
    marginBottom: theme.spacing(2),
  },
  invitesContainer: {
    background: theme.palette.primary.transparent,
    height: 200,
    width: "100%",
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },
}));

type Props = {} & Partial<StepWizardChildProps>;

const OnboardingInviteFriends: FC<Props> = ({ nextStep, previousStep, currentStep }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [invalidEmail, setInvalidEmail] = useState(false);
  const onboardingState = useSelector<RootState, OnboardingRootState>((state) => {
    return state.onboarding;
  });

  const handleInviteAdd = (inviteString: string) => {
    const invites = inviteString.split(",");
    invites.forEach((i) => validateAndAddInvite(i.trim()));
  };

  const validateAndAddInvite = (invite: string) => {
    if (validateEmail(invite)) {
      dispatch({ ...addInvite({ value: invite, type: "email" }) });
    } else {
      setInvalidEmail(true);
    }
  };

  const handleInviteRemove = (invite: string) => {
    dispatch({ ...removeInvite({ value: invite, type: "email" }) });
  };

  const inviteChips = useMemo(
    () =>
      onboardingState.invites.map((i) => ({
        value: i.value,
        icon: i.type === "email" ? "lnr lnr-envelope" : "lnr lnr-smartphone",
      })),
    [onboardingState.invites]
  );

  return (
    <OnboardingStep step={currentStep ?? OnboardingWizardSteps.Introduction} onPrevious={previousStep}>
      <div className={classes.form}>
        <Grid container direction="column" justifyContent="space-evenly" alignItems="center">
          <Grid container direction="column" justifyContent="center" alignItems={isMobile ? "flex-start" : "center"}>
            <Grid container direction="column" justifyContent="center" alignItems="center" wrap="nowrap">
              <img src="/images/invitation.png" className={classes.mainImage} />
              <Typography align="center" variant="h4" className={classes.headline}>
                Invite Friends & Family
              </Typography>
            </Grid>
            <Typography variant="body1" align="center" gutterBottom>
              Hit the ground running by inviting one or a few special people in your life that you want to get amazing
              gift recommendations for!
              <br />
              <br /> Add emails for these special individuals and hit enter or space after each email. Once they sign
              up, they'll be one of your "giftees".
            </Typography>
            <div className={classes.invitesContainer}>
              <MultiChipTextField chips={inviteChips} onAdd={handleInviteAdd} onDelete={handleInviteRemove} />
            </div>
          </Grid>
        </Grid>
        {invalidEmail && (
          <GlobalMessageDispatch>
            <Grid container direction="row" alignItems="center">
              <Icon>
                <span className="lnr lnr-poop"></span>
              </Icon>
              <Typography variant="body1" color="inherit">
                &nbsp;Invalid email format
              </Typography>
            </Grid>
          </GlobalMessageDispatch>
        )}
      </div>
    </OnboardingStep>
  );
};

export default OnboardingInviteFriends;
