import classNames from 'classnames';
import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { useAuthContext } from '../../../auth/authContext';
import useIsMobile from '../../../hooks/useIsMobile';
import { setBirthday } from '../../../redux/onboarding/actions';
import { OnboardingRootState } from '../../../redux/onboarding/reducers';
import { RootState } from '../../../redux/store';
import PrimaryButton from '../../general-ui/buttons/PrimaryButton';
import TertiaryButton from '../../general-ui/buttons/TertiaryButton';
import { OnboardingWizardSteps } from '../OnboardingWizard';
import { useButtonProps, useHandleNext } from './hooks';
import OnboardingStepIndicator from './OnboardingStepIndicator';

type Props = { step: OnboardingWizardSteps; onFinish: () => void } & Partial<StepWizardChildProps>;

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    [theme.breakpoints.down("xs")]: {
      position: "fixed",
      bottom: 0,
      width: "100vw",
      display: "flex",
    },
  },
  header: {
    position: "fixed",
    top: 20,
    left: 200,
    zIndex: 100,
    [theme.breakpoints.down("sm")]: {
      top: 2,
      left: 10,
    },
  },
  ctaButton: {
    paddingTop: theme.spacing(2),
    "& .MuiButtonBase-root": {
      height: 56,
      fontSize: 20,
      [theme.breakpoints.up("sm")]: {
        maxWidth: 275,
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingBottom: theme.spacing(1),
      alignSelf: "flex-end",
    },
  },
  onboardingStepIndicatorContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: 25,
    width: 800,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      width: 96,
    },
  },
  crazyButton: {
    background: theme.palette.info.gradient,
    border: `2px solid ${theme.palette.primary.main}`,
  },
  skipButton: {
    animation: "$fadeIn 2s",
    [theme.breakpoints.down("sm")]: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      width: "45% !important",
    },
  },
  nextButton: {
    [theme.breakpoints.down("sm")]: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      width: "45%",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  buttonContainerTwoButtons: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
}));

enum OnboardingStepsIndicated {
  Welcome,
  SelectOccasions,
  Info,
  Invites,
  Finish,
}

const getStepIndicatedByOnboardingWizardStep = (step: OnboardingWizardSteps): OnboardingStepsIndicated => {
  switch (step) {
    case OnboardingWizardSteps.Introduction:
      return OnboardingStepsIndicated.Welcome;
    case OnboardingWizardSteps.SelectOccasionInstructions:
      return OnboardingStepsIndicated.SelectOccasions;
    case OnboardingWizardSteps.SelectOccasions:
      return OnboardingStepsIndicated.SelectOccasions;
    case OnboardingWizardSteps.SetNameAndPhone:
      return OnboardingStepsIndicated.Info;
    case OnboardingWizardSteps.SetBirthday:
      return OnboardingStepsIndicated.Info;
    case OnboardingWizardSteps.InviteFriends:
      return OnboardingStepsIndicated.Invites;
    case OnboardingWizardSteps.ProfileConfirmation:
      return OnboardingStepsIndicated.Finish;
  }
};

const OnboardingNav: FC<Props> = ({ step, nextStep, previousStep, onFinish, goToStep }) => {
  const { authState } = useAuthContext();
  const showSetNameAndPhone = !authState.user.customData.phone || !authState.user.customData.name;
  const classes = useStyles();
  const isMobile = useIsMobile();
  const onboardingState = useSelector<RootState, OnboardingRootState>((state) => {
    return state.onboarding;
  });
  const dispatch = useDispatch();
  const { hideBackButton, text, testId, buttonDisabled, skippable } = useButtonProps(step, onboardingState);
  const stepIndicated = getStepIndicatedByOnboardingWizardStep(step);
  const handleClick = useHandleNext(step, showSetNameAndPhone, onFinish, goToStep, nextStep);

  const handleSkip = useCallback(() => {
    switch (step) {
      case OnboardingWizardSteps.SelectOccasions:
        if (showSetNameAndPhone) {
          nextStep?.();
        } else {
          goToStep?.(OnboardingWizardSteps.SetBirthday);
        }
        break;
      case OnboardingWizardSteps.SetBirthday:
        dispatch({ ...setBirthday(null) });
        nextStep?.();
        break;
      default:
        nextStep?.();
        break;
    }
  }, [onFinish, nextStep]);
  const buttonClass = classNames({
    [classes.crazyButton]: step === OnboardingWizardSteps.ProfileConfirmation,
    [classes.nextButton]: skippable,
  });
  const buttonContainerClass = classNames({
    [classes.buttonContainer]: !skippable,
    [classes.buttonContainerTwoButtons]: skippable,
  });
  const onboardingSteps = useMemo(() => {
    return Object.values(OnboardingStepsIndicated)
      .filter((val) => isNaN(Number(val)) === false)
      .map((val) => parseInt(val as string));
  }, [Object.values(OnboardingStepsIndicated).join(",")]);
  return (
    <div>
      <div className={classes.header}>
        {!hideBackButton && (
          <IconButton data-testid="onboarding-back-button" onClick={() => previousStep?.()}>
            <span className="lnr lnr-arrow-left"></span>
          </IconButton>
        )}
      </div>
      <div className={classes.wrapper}>
        <Grid
          container
          direction={isMobile ? "column" : "column-reverse"}
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          className={classes.ctaButton}
        >
          <div className={classes.onboardingStepIndicatorContainer}>
            {onboardingSteps.map((onboardingStep, i) => (
              <OnboardingStepIndicator
                stepLabels={["Welcome", "Select Occasions", "Set Birthday", "Invites", "Done"]}
                key={`${i}-onboarding-step`}
                isComplete={onboardingStep < stepIndicated}
                isActive={onboardingStep === stepIndicated}
                step={onboardingStep}
              />
            ))}{" "}
          </div>
          <div className={buttonContainerClass}>
            {skippable && (
              <TertiaryButton disabled={buttonDisabled ?? false} className={classes.skipButton} onClick={handleSkip}>
                SKIP
              </TertiaryButton>
            )}
            <PrimaryButton disabled={buttonDisabled} className={buttonClass} dataTestid={testId} onClick={handleClick}>
              {onboardingState.loading ? <CircularProgress size={25} /> : <>{skippable ? "NEXT" : text}</>}
            </PrimaryButton>
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default OnboardingNav;
