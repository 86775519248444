import { ApolloError } from "@apollo/client";
import React, { FC } from "react";

import { CircularProgress } from "@material-ui/core";

import { Reminder as ReminderType } from "../../@types/reminders";
import Reminder from "./Reminder";
import ErrorState from "../general-ui/state-messages/ErrorState";

type Props = {
  loading?: boolean;
  error?: ApolloError;
  reminders: ReminderType[];
};

const ReminderFeed: FC<Props> = ({ loading, error, reminders }) => {
  if (loading) {
    return <CircularProgress />;
  }
  if (error) {
    return <ErrorState />;
  }

  return (
    <>
      {reminders.map((reminder) => (
        <Reminder key={reminder._id ?? ""} reminder={reminder} />
      ))}
    </>
  );
};

export default ReminderFeed;
