import { useMutation } from "@apollo/client/react/hooks/useMutation";
import { useAuthContext } from "../auth/authContext";
import { updateOneNotificationSetting } from "../graphql/mutations/notification-settings";
import { getNotificationSettingQuery } from "../graphql/queries/notification-settings";

type NotificationType = {
  type: string;
  enabled: boolean;
  __typename?: string;
};

const useUpdateNotificationSetting = (notificationTypes: NotificationType[], type: string) => {
  const [updateNotificationSetting] = useMutation(updateOneNotificationSetting);
  const { authState } = useAuthContext();
  const callUpdateSetting = (e: React.ChangeEvent, checked: boolean) => {
    const newNotificationTypes = notificationTypes.map((n) => {
      const notificationType = { ...n };
      if (notificationType.type === type) {
        notificationType.enabled = checked;
      }
      delete notificationType.__typename;
      return notificationType;
    });

    updateNotificationSetting({
      variables: {
        query: {
          userId: authState.user.customData._id,
        },
        set: {
          notificationTypes: newNotificationTypes,
          updatedAt: new Date(),
        },
      },
      refetchQueries: [
        {
          query: getNotificationSettingQuery,
          variables: {
            query: {
              userId: authState.user.customData._id,
            },
          },
        },
      ],
    });
  };
  return [callUpdateSetting];
};

export default useUpdateNotificationSetting;
