import { Theme, makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React, { FC } from "react";
import useIsMobile from "../../../hooks/useIsMobile";
import { OnboardingWizardSteps } from "../OnboardingWizard";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    color: theme.palette.text.primary,
  },
  onboardingStep: {
    color: theme.palette.common.white,
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.dark,
    transition: theme.transitions.create(["background-color", "width"]),
    height: 30,
    minWidth: 30,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 3,
      height: theme.spacing(1),
      width: theme.spacing(1),
      minWidth: 0,
    },
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      transform: "scaleX(1.8)",
    },
  },
}));

type Props = { isActive: boolean; step: OnboardingWizardSteps; isComplete: boolean; stepLabels: string[] };

const OnboardingStepIndicator: FC<Props> = ({ isComplete, isActive, step, stepLabels }) => {
  const classes = useStyles();
  const stepClass = classNames({
    [classes.onboardingStep]: true,
    [classes.active]: isActive,
  });
  const isMobile = useIsMobile();

  return (
    <div className={classes.container}>
      <div className={stepClass}>{!isMobile && (!isComplete ? step + 1 : <div className="lnr lnr-check"></div>)}</div>
      {!isMobile && stepLabels[step]}
    </div>
  );
};

export default OnboardingStepIndicator;
