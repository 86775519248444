import { User as RealmUser } from "realm-web";

import { User } from "../user";

export type AuthState = {
  user: User;
  accessToken: string;
  isAuthenticated: boolean;
};

export type LoginAction = {
  type: AuthActionTypes.LOGIN_USER;
  payload: {
    realmUser: RealmUser;
    email?: string;
    accessToken: string;
  };
};
export type LogoutAction = {
  type: AuthActionTypes.LOGOUT_USER;
};
export type SetTokenAction = {
  type: AuthActionTypes.SET_TOKEN;
  payload: {
    accessToken: string;
  };
};

export type SetCustomDataAction = {
  type: AuthActionTypes.SET_USER_CUSTOM_DATA;
  payload: {
    customData?: Partial<User["customData"]>;
  };
};

export enum AuthActionTypes {
  LOGIN_USER = "loginUser",
  LOGOUT_USER = "logoutUser",
  SET_TOKEN = "setToken",
  SET_USER_CUSTOM_DATA = "setUserCustomData",
}

export type AuthActions = LoginAction | LogoutAction | SetTokenAction | SetCustomDataAction;
