import { OccasionDayMonthDateInsertInput, OccasionType } from "../../../__generated__/globalTypes";
import {
  SET_OCCASION_NAME,
  SET_OCCASION_TYPE,
  SET_USERS_TO_NOTIFY,
  SET_OCCASION_DATE,
  SET_CONFIRMED,
} from "../constants/action-types";

export const setOccasionName = (payload: string) => {
  return { type: SET_OCCASION_NAME, payload };
};
export const setOccasionType = (payload: OccasionType | "") => {
  return { type: SET_OCCASION_TYPE, payload };
};
export const setUsersToNotify = (payload: string[]) => {
  return { type: SET_USERS_TO_NOTIFY, payload };
};
export const setOccasionDate = (payload: OccasionDayMonthDateInsertInput) => {
  return { type: SET_OCCASION_DATE, payload };
};
export const setConfirmed = (payload: boolean) => {
  return { type: SET_CONFIRMED, payload };
};
