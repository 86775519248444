import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import { Theme, withStyles } from "@material-ui/core/styles";
import { User } from "realm-web";

import { openGoogleLogin } from "../../../realm/auth";
import { useAuthContext } from "../../../auth/authContext";
import { AuthActionTypes } from "../../../@types/auth";
import { useMutation } from "@apollo/client/react/hooks/useMutation";
import { insertOneUser, updateOneUser } from "../../../graphql/mutations/users";
import {
  InsertOneUserMutation,
  InsertOneUserMutationVariables,
} from "../../../graphql/mutations/users/__generated__/InsertOneUserMutation";
import {
  UpdateOneUserMutation,
  UpdateOneUserMutationVariables,
} from "../../../graphql/mutations/users/__generated__/UpdateOneUserMutation";
import { useLazyQuery } from "@apollo/client";
import { getUserVariables, getUser } from "../../../graphql/queries/users/__generated__/getUser";
import { useHistory } from "react-router-dom";
import { getUserQuery } from "../../../graphql/queries/users";
import app from "../../../realm";
import CircularProgress from "@material-ui/core/CircularProgress";
import useAnalyticsEvent from "../../../hooks/analytics/useAnalyticsEvent";

const styles = (theme: Theme) => ({
  googleButton: {
    height: 54,
    padding: "8px 12px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.common.white,
    color: theme.palette.common.black,
    opacity: 1,
    "&:hover": {
      opacity: 0.8,
    },
  },
  googleButtonOutlined: {
    height: 54,
    padding: "8px 12px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    border: "1px solid " + theme.palette.primary.main,
    background: "none",
    color: theme.palette.primary.main,
    opacity: 1,
    "&:hover": {
      opacity: 0.8,
    },
  },
  googleButtonLogo: {
    width: 20,
    marginRight: 10,
  },
});

interface Props {
  classes: {
    googleButton: string;
    googleButtonOutlined: string;
    googleButtonLogo: string;
  };
  variant?: string;
  disabled?: boolean;
}

const GoogleLoginButton: React.FC<Props> = ({ classes, disabled, variant, children }) => {
  const fireGoogleAnalytics = useAnalyticsEvent("users", "create", "Signup Form");
  const { authDispatch, authState } = useAuthContext();
  const [insertUser, { loading: insertUserLoading }] = useMutation<
    InsertOneUserMutation,
    InsertOneUserMutationVariables
  >(insertOneUser);
  const [updateUser, { loading: updateUserLoading }] = useMutation<
    UpdateOneUserMutation,
    UpdateOneUserMutationVariables
  >(updateOneUser);
  const [getUser, { data, called, loading: getUserLoading }] = useLazyQuery<getUser, getUserVariables>(getUserQuery);
  const [authUser, setAuthUser] = useState<User | null>(null);
  const history = useHistory();
  const loading = insertUserLoading || updateUserLoading || getUserLoading;
  const handleClick = async () => {
    try {
      const user = await openGoogleLogin();
      await user?.refreshCustomData();
      if (user != null) {
        authDispatch({
          type: AuthActionTypes.LOGIN_USER,
          payload: {
            realmUser: user,
            accessToken: user.accessToken ?? "",
            email: user.profile.email,
          },
        });
        setAuthUser(user);
        await getUser({
          variables: {
            query: {
              authId: user?.id,
            },
          },
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    (async () => {
      if (data) {
        if (!data?.user) {
          const userData: Record<string, unknown> = {
            _id: authUser?.id,
            authId: authUser?.id,
            email: authUser?.profile.email,
            name: authUser?.profile.name,
            firstName: authUser?.profile.firstName,
            lastName: authUser?.profile.lastName,
            picture: authUser?.profile.pictureUrl,
            birthday: authUser?.profile.birthday,
            lastLogin: new Date(),
            createdAt: new Date(),
          };
          if (authUser?.profile.gender != null) {
            userData.gender = authUser?.profile.gender;
          }
          await insertUser({
            variables: {
              data: userData,
            },
          });
          await app.currentUser?.refreshCustomData();
          authDispatch({
            type: AuthActionTypes.SET_USER_CUSTOM_DATA,
            payload: {
              customData: {
                ...authState.user.customData,
                ...userData,
              },
            },
          });
          fireGoogleAnalytics();
          history.push("/home");
        } else if (called) {
          const setData = {
            lastLogin: new Date(),
          };
          await updateUser({
            variables: {
              query: {
                _id: authUser?.id,
              },
              set: setData,
            },
          });
          await app.currentUser?.refreshCustomData();
          authDispatch({
            type: AuthActionTypes.SET_USER_CUSTOM_DATA,
            payload: {
              customData: {
                ...authState.user.customData,
                ...setData,
              },
            },
          });
          history.push("/home");
        }
      }
    })();
  }, [authUser, data, called]);

  return (
    <Button
      onClick={handleClick}
      variant="contained"
      disabled={disabled}
      className={variant === "outlined" ? classes.googleButtonOutlined : classes.googleButton}
    >
      {loading ? (
        <CircularProgress size={20} color="primary" />
      ) : (
        <>
          <img alt="Google Logo" className={classes.googleButtonLogo} src="/images/vendor/g-logo.png"></img>
          {children}
        </>
      )}
    </Button>
  );
};

export default withStyles(styles)(GoogleLoginButton);
