import React, { FC, useState } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { MongoDBRealmError } from 'realm-web';

import { useMutation } from '@apollo/client/react/hooks';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { AuthActionTypes } from '../../@types/auth';
import { useAuthContext } from '../../auth/authContext';
import { updateOneUser } from '../../graphql/mutations/users';
import {
  UpdateOneUserMutation, UpdateOneUserMutationVariables
} from '../../graphql/mutations/users/__generated__/UpdateOneUserMutation';
import { loginWithEmailAndPass } from '../../realm/auth';
import GoogleLoginButton from '../general-ui/buttons/GoogleLoginButton';
import PrimaryButton from '../general-ui/buttons/PrimaryButton';
import OutlinedInput from '../general-ui/inputs/OutlinedInput';
import PasswordInput from '../general-ui/inputs/PasswordInput';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  inputWrapper: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  forgotPass: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(0.5),
    "& a": {
      color: theme.palette.text.primary,
      fontSize: 14,
    },
  },
  submitButton: {
    "& button": {
      padding: "15px 13px",
    },
  },
  orDivider: {
    color: theme.palette.text.primary,
    fontWeight: 900,
    fontSize: 16,
    margin: "10px 0px",
  },
}));

const LoginForm: FC = () => {
  const classes = useStyles();
  const [updateUser] = useMutation<UpdateOneUserMutation, UpdateOneUserMutationVariables>(updateOneUser);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [toHome, setToHome] = useState(false);
  const [formError, setFormError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorLog, setErrorLog] = useState("");

  const { authDispatch } = useAuthContext();

  const loginFormChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    if (name === "email") {
      setEmail(value?.toLowerCase()?.trim());
    } else if (name === "pass") {
      setPassword(value);
    }
  };

  const closeError = () => {
    setFormError(false);
  };

  const loginUser = async () => {
    setLoading(true);
    try {
      const { user, accessToken } = await loginWithEmailAndPass(email, password);
      authDispatch({
        type: AuthActionTypes.LOGIN_USER,
        payload: {
          realmUser: user,
          email,
          accessToken: accessToken ?? "",
        },
      });
      await updateUser({
        variables: {
          query: {
            _id: user.customData._id,
          },
          set: {
            lastLogin: new Date(),
          },
        },
      });
      heap.identify(user.customData._id);
      setToHome(true);
    } catch (e) {
      const error = e as unknown as MongoDBRealmError;
      setErrorLog(error?.error ?? "");
      setFormError(true);
    }
    setLoading(false);
  };

  const potentialSignIn = (event: React.KeyboardEvent<Element>) => {
    if (event.key === "Enter") {
      loginUser();
    }
  };
  if (toHome === true) {
    return <Redirect to="/home" />;
  }

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.container}>
      <div className={classes.inputWrapper}>
        <OutlinedInput
          variant="light"
          label="Email"
          onKeyPress={potentialSignIn}
          onChange={loginFormChange}
          value={email}
          name="email"
          type="email"
        />
      </div>
      <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start" className={classes.forgotPass}>
        <Link to="/forgot-password">Forgot Password?</Link>
      </Grid>
      <PasswordInput
        variant="light"
        label="Password"
        onKeyPress={potentialSignIn}
        onChange={loginFormChange}
        value={password}
        name="pass"
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: 20 }}
        className={classes.submitButton}
      >
        <PrimaryButton disabled={loading} onClick={loginUser}>
          Log in
        </PrimaryButton>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: 8 }}
        className={classes.submitButton}
      >
        <GoogleLoginButton disabled={loading} variant="outlined">
          Log in with Google
        </GoogleLoginButton>
      </Grid>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={formError}
        autoHideDuration={6000}
        onClose={closeError}
        message={
          <Grid container justifyContent="center" alignItems="center">
            <span className="lnr lnr-warning"></span> &nbsp;
            {errorLog}
          </Grid>
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={closeError}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Grid>
  );
};

export default LoginForm;
