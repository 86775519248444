import clsx from 'clsx';
import React, { FC, useEffect, useRef } from 'react';

import Grid from '@material-ui/core/Grid/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography/Typography';

import { useAuthContext } from '../../../auth/authContext';
import { wizardModalStyles } from '../../../utils/styleHelpers';
import { OnboardingWizardSteps } from '../OnboardingWizard';
import { useHandleNext } from './hooks';

const useStyles = makeStyles((theme) => ({
  header: {
    position: "absolute",
    top: 100,
    left: 20,
    [theme.breakpoints.down("sm")]: {
      top: 10,
      left: 10,
    },
  },
  wizardModalStyles: wizardModalStyles(theme),
  container: {
    flex: 1,
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      padding: theme.spacing(1),
    },
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "calc(100vh - 108px)",
    color: theme.palette.text.primary,
    padding: 0,
    overflow: "hidden",
    overflowY: "scroll",
    [theme.breakpoints.up("sm")]: {
      maxHeight: 600,
      minWidth: 600,
    },
  },
  mainImage: {
    width: "50%",
    maxWidth: 225,
    objectFit: "contain",
    margin: "30px 0px",
  },
}));

type Props = {
  mainImageSrc?: string;
  headline?: string;
  description?: React.ReactElement | string;
  children?: React.ReactElement;
  onNext?: () => void;
  step: OnboardingWizardSteps;
  goToStep?: (step: OnboardingWizardSteps) => void;
  onPrevious?: () => void;
  onFinish?: () => void;
};

const OnboardingStep: FC<Props> = ({
  headline,
  description,
  mainImageSrc,
  children,
  onNext,
  onPrevious,
  step,
  onFinish,
  goToStep,
}) => {
  const { authState } = useAuthContext();
  const showSetNameAndPhone = !authState.user.customData.phone || !authState.user.customData.name;
  const classes = useStyles();
  const containerClasses = clsx({
    [classes.mainContainer]: true,
    [classes.wizardModalStyles]: true,
  });

  const swipeElement = useRef<null | HTMLDivElement>();
  const handleNext = useHandleNext(step, showSetNameAndPhone, onFinish, goToStep, onNext);

  useEffect(() => {
    swipeElement.current?.addEventListener("swiped-right", () => {
      onPrevious?.();
    });
    swipeElement.current?.addEventListener("swiped-left", () => {
      handleNext();
    });
  }, [swipeElement]);

  return (
    <div ref={(el) => (swipeElement.current = el)} className={containerClasses}>
      <Grid
        container
        wrap="nowrap"
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        {children ?? (
          <>
            <img src={mainImageSrc} className={classes.mainImage} />
            <Typography variant="h5" align="center" gutterBottom>
              {headline}
            </Typography>
            <Typography variant="h6" align="center">
              {description}
            </Typography>
          </>
        )}
      </Grid>
    </div>
  );
};

export default OnboardingStep;
