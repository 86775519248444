import gql from 'graphql-tag';

export const insertOneReminder = gql`
  mutation InsertOneReminderMutation($data: ReminderInsertInput!) {
    insertOneReminder(data: $data) {
      _id
      date
      fired
      occasion {
        name
        _id
      }
    }
  }
`;

export const insertManyReminders = gql`
  mutation InsertManyRemindersMutation($data: [ReminderInsertInput!]!) {
    insertManyReminders(data: $data) {
      insertedIds
    }
  }
`;

export const updateOneReminder = gql`
  mutation UpdateOneReminderMutation($query: ReminderQueryInput!, $set: ReminderUpdateInput!) {
    updateOneReminder(query: $query, set: $set) {
      _id
    }
  }
`;

export const deleteOneReminder = gql`
  mutation DeleteOneReminderMutation($query: ReminderQueryInput!) {
    deleteOneReminder(query: $query) {
      _id
    }
  }
`;
