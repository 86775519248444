import React from 'react';

import Grid from '@material-ui/core/Grid';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      height: "100%",
      "& h3, h6": {
        margin: 0,
      },
      "& img": {
        width: "100%",
      },
    },
  });

interface Props {
  opacity?: number;
  classes: {
    container: string;
  };
  variant?: string;
  width?: number;
  primary?: string;
  secondary?: string;
}

const GiftBoxMessage: React.FC<Props> = (props: Props) => {
  const { classes } = props;

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      wrap="nowrap"
      style={{ opacity: props.opacity }}
      className={classes.container}
    >
      <img alt={props.primary} src="/images/empty-box-green.png" style={{ width: props.width, objectFit: "contain" }} />
      <Typography variant="h6" className="flex-center-center">
        {props.primary}
      </Typography>
      <Typography variant="h6" className="flex-center-center">
        {props.secondary}
      </Typography>
    </Grid>
  );
};

export default withStyles(styles)(GiftBoxMessage);
