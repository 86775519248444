import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import React, { FC } from 'react';

import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { OccasionType } from '../../__generated__/globalTypes';
import { getOccasions_occasions } from '../../graphql/queries/occasions/__generated__/getOccasions';
import { momentCalendarFormat } from '../../utils/constants/dateConstants';
import occasionIcons from '../occasions/occasionIcons';

dayjs.extend(calendar);

const useStyles = makeStyles((theme: Theme) => ({
  reminderContainer: {
    position: "relative",
    color: theme.palette.primary.main,
    margin: 10,
    marginTop: 20,
  },
  reminder: {
    display: "flex",
    alignItems: "center",
    borderRadius: "30px",
    margin: 10,
    marginBottom: 18,
    height: 50,
    width: 50,
    transition: ".2s " + theme.transitions.easing.easeInOut,
  },
  iconButton: {
    height: 50,
    width: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.primary.main,
    color: theme.palette.text.primary,
  },
  icon: {
    color: theme.palette.text.primary,
    overflow: "visible",
  },
  reminderExpanded: {
    width: "calc(100% - 10px)",
  },
  reminderInfo: {
    position: "absolute",
    top: "calc(50% - 30px)",
    left: 70,
    height: 50,
    color: theme.palette.text.primary,
    borderBottom: "1px solid " + theme.palette.primary.main,
    width: "calc(100% - 50px)",
    display: "flex",
    "& span": {
      fontSize: 20,
    },
  },
  alreadySentInfo: {
    color: theme.palette.primary.main,
  },
  reminderDelete: {
    textDecoration: "none !important",
  },
  reminderExpandButton: {
    width: 20,
    maxHeight: 20,
    minHeight: 20,
    fontSize: 18,
  },
}));

type Props = {
  occasion: getOccasions_occasions | null;
};

const OccasionReminder: FC<Props> = ({ occasion }) => {
  const classes = useStyles();
  if (occasion == null) {
    return null;
  }
  const occasionIcon = occasionIcons(occasion.type ?? OccasionType.BIRTHDAY);
  const nextOccasionDate = dayjs(occasion?.nextOccasionDate ?? "");

  return (
    <div className={classes.reminderContainer}>
      <IconButton className={classes.iconButton}>
        <Icon className={classes.icon}>
          <span className={"lnr " + occasionIcon}></span>
        </Icon>
      </IconButton>
      <div className={classes.reminderInfo}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          className={classes.alreadySentInfo}
        >
          <Grid container direction="column" justifyContent="center" alignItems="flex-start" className="flex-1">
            <span>{nextOccasionDate.calendar(null, momentCalendarFormat)}</span>
            {occasion.name}
          </Grid>
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" className="flex-1"></Grid>
        </Grid>
      </div>
    </div>
  );
};

export default OccasionReminder;
