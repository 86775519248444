import { useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getGifteeQuery } from "../../graphql/queries/giftees";
import { getGiftee, getGifteeVariables } from "../../graphql/queries/giftees/__generated__/getGiftee";
import useIsMobile from "../../hooks/useIsMobile";
import { closeMarkGiftPurchasedDialog, setActiveGiftIdea } from "../../redux/giftideas/actions";
import { GiftIdeaRootState } from "../../redux/giftideas/reducers";
import { RootState } from "../../redux/store";
import Modal from "../general-ui/modals/Modal";
import SecondaryModal from "../general-ui/modals/SecondaryModal";
import CreateGiftPurchaseWizard from "../wizards/CreateGiftPurchaseWizard";

const useStyles = makeStyles((theme) => ({
  modal: {
    background: theme.palette.background.paper,
    overflow: "hidden",
    padding: 16,
    minHeight: "100vh",
  },
}));

type Props = {
  gifteeId: string | null;
};

const CreateGiftPurchaseDialog: FC<Props> = ({ gifteeId }) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const { data } = useQuery<getGiftee, getGifteeVariables>(getGifteeQuery, {
    variables: {
      query: {
        _id: gifteeId,
      },
    },
  });
  const userId = data?.giftee?.sourceUser?._id ?? null;

  const dispatch = useDispatch();
  const { markGiftPurchasedDialogOpen } = useSelector<RootState, GiftIdeaRootState>((state) => {
    return state.giftIdeas;
  });

  const closeDialog = () => {
    dispatch({ ...closeMarkGiftPurchasedDialog() });
    dispatch({ ...setActiveGiftIdea({}) });
  };

  const content = (
    <div className={classes.modal}>
      <CreateGiftPurchaseWizard gifteeId={gifteeId} userId={userId} />
    </div>
  );

  if (!isMobile) {
    return (
      <SecondaryModal closeModal={closeDialog} showModal={markGiftPurchasedDialogOpen} secondModal={true}>
        {content}
      </SecondaryModal>
    );
  }

  return (
    <Modal closeModal={closeDialog} showModal={markGiftPurchasedDialogOpen} secondModal={true}>
      {content}
    </Modal>
  );
};

export default CreateGiftPurchaseDialog;
