import dayjs from 'dayjs';
import { useCallback, useState } from 'react';

import { ApolloError, MutationFunctionOptions, useMutation } from '@apollo/client';

import { OccasionInsertInput, ReminderInsertInput } from '../__generated__/globalTypes';
import { insertOneOccasion } from '../graphql/mutations/occasions';
import {
  InsertOneOccasionMutation, InsertOneOccasionMutationVariables
} from '../graphql/mutations/occasions/__generated__/InsertOneOccasionMutation';
import { insertManyReminders } from '../graphql/mutations/reminders';
import {
  InsertManyRemindersMutation, InsertManyRemindersMutationVariables
} from '../graphql/mutations/reminders/__generated__/InsertManyRemindersMutation';
import { getOccasionsQuery } from '../graphql/queries/occasions';
import { getRemindersQuery } from '../graphql/queries/reminders';

export interface MutationResult<TData> {
  called: boolean;
  data?: TData | null;
  error?: ApolloError;
  hasError: boolean;
  loading: boolean;
}

type useAddOccasionType = [
  (occasionData: OccasionInsertInput) => Promise<InsertOneOccasionMutation | null | undefined>,
  MutationResult<InsertOneOccasionMutation>
];

const getInitialState = () => ({
  called: false,
  data: undefined,
  error: undefined,
  hasError: false,
  loading: false,
});

const useAddOccasion = (refetchVariables?: { [key: string]: any }): useAddOccasionType => {
  const [addOccasion] = useMutation<InsertOneOccasionMutation, InsertOneOccasionMutationVariables>(insertOneOccasion);
  const [insertReminders] = useMutation<InsertManyRemindersMutation, InsertManyRemindersMutationVariables>(
    insertManyReminders
  );
  const [result, setResult] = useState<MutationResult<InsertOneOccasionMutation>>(getInitialState);

  const fireFunction = useCallback(async (occasionData: OccasionInsertInput) => {
    try {
      setResult((prev) => ({
        ...prev,
        called: true,
        loading: true,
      }));
      const addOccasionOptions: MutationFunctionOptions<InsertOneOccasionMutation, InsertOneOccasionMutationVariables> =
        {
          variables: {
            data: occasionData,
          },
        };
      if (refetchVariables) {
        addOccasionOptions.refetchQueries = [
          {
            query: getOccasionsQuery,
            variables: {
              query: refetchVariables,
            },
          },
        ];
      }
      const newOccasion = await addOccasion(addOccasionOptions);
      if (newOccasion.data) {
        const { insertOneOccasion } = newOccasion.data;

        const reminderDays = [7, 14, 21];

        const reminders = reminderDays.reduce<ReminderInsertInput[]>((reminders, days) => {
          const date = dayjs(occasionData.nextOccasionDate).subtract(days, "days");
          if (date.isAfter(dayjs())) {
            return [
              {
                date,
                occasion: {
                  link: insertOneOccasion?._id,
                },
              },
              ...reminders,
            ];
          }
          return reminders;
        }, []);

        await insertReminders({
          variables: {
            data: reminders,
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: getRemindersQuery,
              variables: {
                query: { occasion: { _id: insertOneOccasion?._id } },
              },
            },
          ],
          // update: (cache, result) =>
          //   insertRemindersInCache(
          //     cache,
          //     { occasion: { _id: insertOneOccasion?._id } },
          //     result.data?.insertOneReminder as getReminders_reminders
          //   ),
        });
      }
      setResult((prev) => ({
        ...prev,
        data: newOccasion.data,
        loading: false,
      }));
      return newOccasion.data;
    } catch (e) {
      setResult((prev) => ({
        ...prev,
        error: e,
        hasError: true,
      }));
    }
  }, []);

  return [fireFunction, result];
};

export default useAddOccasion;
