import React, { FC } from 'react';

import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import { makeStyles, Theme } from '@material-ui/core/styles';

import {
  getNotifications_notifications as NotificationType
} from '../../graphql/queries/notifications/__generated__/getNotifications';
import GiftBoxMessage from '../general-ui/GiftBoxMessage';
import NotificationBar from './Notification';

const useStyles = makeStyles(() => ({
  notificationsList: {
    width: "100%",
    maxHeight: "calc(100vh - 112px)",
    overflowY: "scroll",
  },
}));

type Props = {
  data: {
    notifications: NotificationType[];
  };
  queryState: string;
};

const NotificationsList: FC<Props> = ({ data, queryState }) => {
  const classes = useStyles();

  const renderNotifications = () => {
    if (queryState === "ready" && data.notifications?.length > 0) {
      const { notifications } = data;
      return (
        <List className={classes.notificationsList}>
          {notifications.map((notification) => (
            <NotificationBar notification={notification} key={notification._id} />
          ))}
        </List>
      );
    } else if (queryState === "ready" && data.notifications?.length === 0) {
      return (
        <GiftBoxMessage
          primary="No Notifications"
          secondary="nothing to read here!"
          opacity={0.7}
          width={250}
        ></GiftBoxMessage>
      );
    } else if (queryState === "error") {
      return (
        <GiftBoxMessage
          primary="An error occurred"
          secondary="Something went wrong finding your notifications. We apologize for the inconvenience"
          opacity={0.7}
          width={250}
        ></GiftBoxMessage>
      );
    } else if (queryState === "loading") {
      return <CircularProgress />;
    }
  };

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" wrap="nowrap">
      {renderNotifications()}
    </Grid>
  );
};

export default NotificationsList;
