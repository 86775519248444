import React, { FC, useMemo } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { OccasionType } from '../../__generated__/globalTypes';
import {
  getOccasions_occasions as OccasionEntityType
} from '../../graphql/queries/occasions/__generated__/getOccasions';
import Occasion from './Occasion';
import getOccasionNameFromType from './occasionName';

const useStyles = makeStyles((theme) => ({
  occasionList: {
    marginTop: 50,
    width: "100%",
    height: "calc(100vh - 350px)",
    overflowY: "scroll",
    padding: theme.spacing(2),
  },
}));

export type occasionSelection = {
  type: OccasionType;
  name: string;
};

type Props = {
  onSelectOccasion: (o: occasionSelection) => void;
  onDeselectOccasion: (o: occasionSelection) => void;
  selectedOccasionTypes: string[];
  excludedOccasions?: OccasionType[];
};

const OccasionSelectionList: FC<Props> = ({
  onSelectOccasion,
  onDeselectOccasion,
  selectedOccasionTypes,
  excludedOccasions,
}) => {
  const classes = useStyles();
  const occasionOptions = useMemo(() => {
    return Object.values(OccasionType).sort();
  }, [Object.values(OccasionType).join(",")]);

  const allExcludedOccasions = [
    OccasionType.ANNIVERSARY,
    OccasionType.BIRTHDAY,
    OccasionType.CUSTOM,
    OccasionType.WEDDING,
    ...(excludedOccasions ?? []),
  ];

  const optionsFiltered = occasionOptions.filter((o) => !allExcludedOccasions.includes(o));

  return (
    <div className={classes.occasionList}>
      {optionsFiltered.map((type) => {
        const occasion = {
          type,
          name: getOccasionNameFromType(type),
        };
        return (
          <Occasion
            onSelect={() => onSelectOccasion(occasion)}
            onDeselect={() => onDeselectOccasion(occasion)}
            occasionSelected={selectedOccasionTypes.includes(type)}
            key={type}
            occasion={occasion as OccasionEntityType}
            selectable={true}
          />
        );
      })}
    </div>
  );
};

export default OccasionSelectionList;
