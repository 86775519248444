/* TypeScript file generated from GiftIdeaFeedForMultiGiftee.res by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as GiftIdeaFeedForMultiGifteeBS__Es6Import from './GiftIdeaFeedForMultiGiftee.bs';
const GiftIdeaFeedForMultiGifteeBS: any = GiftIdeaFeedForMultiGifteeBS__Es6Import;

import type {Json_t as Js_Json_t} from '../../../src/shims/Js.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Props = { readonly gifteeIds: Array<(null | undefined | Js_Json_t)>; readonly userIds: Array<(null | undefined | Js_Json_t)> };

export const make: React.ComponentType<{ readonly gifteeIds: Array<(null | undefined | Js_Json_t)>; readonly userIds: Array<(null | undefined | Js_Json_t)> }> = function GiftIdeaFeedForMultiGiftee(Arg1: any) {
  const $props = {gifteeIds:Arg1.gifteeIds.map(function _element(ArrayItem: any) { return (ArrayItem == null ? undefined : ArrayItem)}), userIds:Arg1.userIds.map(function _element(ArrayItem1: any) { return (ArrayItem1 == null ? undefined : ArrayItem1)})};
  const result = React.createElement(GiftIdeaFeedForMultiGifteeBS.make, $props);
  return result
};
