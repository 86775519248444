import React, { FC } from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const styles = (theme: Theme) =>
  createStyles({
    gradientButton: {
      padding: "8px 12px",
      display: "flex",
      alignItems: "center",
      width: "100%",
      borderRadius: theme.shape.borderRadius,
      background: theme.palette.primary.gradient,
      color: theme.palette.common.white,
    },
  });

type Props = {
  classes: {
    gradientButton: string;
  };
  variant?: string;
  onClick: () => void;
  disabled?: boolean;
};

const GradientButton: FC<Props> = ({ classes, onClick, disabled, children }) => {
  return (
    <Button disabled={disabled} onClick={onClick} variant="contained" className={classes.gradientButton}>
      {children}
    </Button>
  );
};

export default withStyles(styles)(GradientButton);
