import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';

import { useAuthContext } from '../../../auth/authContext';
import { setName, setPhone } from '../../../redux/onboarding/actions';
import { OnboardingRootState } from '../../../redux/onboarding/reducers';
import { RootState } from '../../../redux/store';
import { phoneFormat } from '../../../utils/helpers/stringHelper';
import { OnboardingWizardSteps } from '../OnboardingWizard';
import OnboardingStep from './OnboardingStep';

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    width: "100%",
    height: "30vh",
    maxHeight: 250,
    [theme.breakpoints.down("sm")]: {
      height: "30.5vh",
    },
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainImage: {
    height: "30vh",
    objectFit: "contain",
    marginBottom: theme.spacing(2),
  },
  headline: {
    marginBottom: theme.spacing(2),
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "100%",
    maxWidth: 600,
    // [theme.breakpoints.down("sm")]: {
    //   justifyContent: "flex-start",
    //   alignItems: "flex-start",
    // },
    background: theme.palette.background.background,
    color: theme.palette.text.primary,
    // padding: "60px 16px",
    // height: "calc(100vh - 120px)",
  },
  formControlHeader: {
    marginBottom: theme.spacing(1),
    marginTop: "20px",
  },
  formControl: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& label": {
      color: theme.palette.text.primary,
    },
    "& .MuiSelect-select option": {
      background: theme.palette.background.background,
    },
    "& .Mui-focused select option": {
      background: theme.palette.background.background,
    },
  },
  header: {
    color: theme.palette.primary.main,
  },
  input: {
    color: theme.palette.text.primary,
    "& fieldset": {
      borderColor: theme.palette.text.primary,
    },
  },
  inputWrap: {
    marginTop: 20,
    position: "relative",
    "& button": {
      position: "absolute",
      right: 4,
      top: "calc(50% - 20px)",
      color: theme.palette.text.primary,
    },
  },
  confirmButtonContainer: {
    marginTop: theme.spacing(3),
  },
}));

type Props = {} & Partial<StepWizardChildProps>;

const SetNameAndPhone: FC<Props> = ({ currentStep, nextStep, previousStep }) => {
  const classes = useStyles();
  const { authState } = useAuthContext();
  const dispatch = useDispatch();
  const onboardingState = useSelector<RootState, OnboardingRootState>((state) => {
    return state.onboarding;
  });

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ ...setName(e.target.value ?? "") });
  };
  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phoneNumber = phoneFormat(e.target.value ?? "");
    dispatch({ ...setPhone(phoneNumber) });
  };

  return (
    <OnboardingStep
      step={currentStep ?? OnboardingWizardSteps.Introduction}
      onNext={nextStep}
      onPrevious={previousStep}
    >
      <div className={classes.form}>
        <Grid container direction="column" justifyContent="space-evenly" alignItems="center">
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <div className={classes.imageContainer}>
              <img src="/images/you-and-dog.png" className={classes.mainImage} />
            </div>
            <Typography variant="h5" align="center" className={classes.headline}>
              About you
            </Typography>
            <>
              <Typography variant="body1" align="center" gutterBottom>
                We'd love to be able to refer to you by name instead of as {authState.user.customData.email}!
              </Typography>
              <TextField
                variant="outlined"
                className={classes.formControl}
                label="Name"
                onChange={handleChangeName}
                value={onboardingState.userName}
              />
            </>
            {/* <>
              <Typography variant="body1" align="center" gutterBottom>
                If you want, you can also provide a cell phone number for text message occasion reminders
              </Typography>
              <TextField
                variant="outlined"
                className={classes.formControl}
                label="Phone"
                onChange={handleChangePhone}
                value={onboardingState.phone}
                type="tel"
              />
            </> */}
          </Grid>
        </Grid>
      </div>
    </OnboardingStep>
  );
};

export default SetNameAndPhone;
