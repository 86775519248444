import React, { ElementType } from "react";
import clsx from "classnames";

import { makeStyles, Theme } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "8px 12px",
    opacity: 1,
    boxSizing: "border-box",
    textTransform: "uppercase",
    textAlign: "center",
    color: "white",
    fontSize: "0.875rem",
    justifyContent: "center",
    "&.MuiTypography-colorPrimary": {
      color: theme.palette.common.white,
    },
    "&:hover": {
      opacity: 0.8,
    },
  },
  primaryButton: {
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.primary.main,
  },
  primaryButtonOutlined: {
    borderRadius: theme.shape.borderRadius,
    border: "2px solid " + theme.palette.primary.main,
    background: "none",
  },
}));

interface Props {
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  href?: string;
  target?: string;
  rel?: string;
  component?: ElementType<any>;
  variant?: string;
  children: (string | JSX.Element | undefined)[] | (string | JSX.Element | undefined);
  "data-testid"?: string;
  endIcon?: React.ReactNode;
  className?: string;
}

const PrimaryLink: React.FC<Props> = (props) => {
  const { onClick, href, variant, endIcon, className, ...rest } = props;
  const classes = useStyles();

  const linkClasses = clsx({
    [classes.button]: true,
    [classes.primaryButton]: variant !== "outlined",
    [classes.primaryButtonOutlined]: variant === "outlined",
    [className ?? ""]: !!className,
  });

  return (
    <Link onClick={onClick} href={href} className={linkClasses} data-testid={props["data-testid"]} {...rest}>
      {props.children} {!!endIcon ? endIcon : null}
    </Link>
  );
};

export default PrimaryLink;
