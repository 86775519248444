import React, { FC } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import useIsXsMobile from '../../../hooks/useIsXsMobile';
import { OnboardingWizardSteps } from '../OnboardingWizard';
import OnboardingStep from './OnboardingStep';

const useStyles = makeStyles((theme) => ({
  intro: {
    position: "relative",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    maxWidth: 600,
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
      alignItems: "flex-start",
      paddingTop: "40vh",
      "&:before": {
        width: "235vw",
        height: "100vh",
        bottom: "35vh",
        left: -16,
        backgroundSize: "cover",
        backgroundImage: 'url("/images/iso-gift-town.png")',
        zIndex: 0,
        content: '""',
        position: "absolute",
      },
    },
    "@media(max-height: 480px)": {
      paddingTop: "0px",
      "&:before": {
        display: "none",
      },
    },
  },
  headline: {
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
    "@media(max-height: 480px)": {
      width: "100%",
      textAlign: "center",
    },
    marginBottom: theme.spacing(3),
  },
  mainImage: {
    width: "50%",
    maxWidth: 225,
    objectFit: "contain",
    margin: "30px 0px",
  },
}));

const SelectOccasionInstructions: FC<Partial<StepWizardChildProps>> = ({ nextStep, previousStep, currentStep }) => {
  const classes = useStyles();
  const isMobile = useIsXsMobile();
  return (
    <OnboardingStep
      step={currentStep ?? OnboardingWizardSteps.Introduction}
      onNext={nextStep}
      onPrevious={previousStep}
    >
      <div className={classes.intro} id="occasion-instructions">
        {!isMobile && <img className={classes.mainImage} src="/images/calendar.png" />}
        <Typography variant="h5" align={isMobile ? "left" : "center"} className={classes.headline}>
          Occasions you celebrate
        </Typography>
        <Typography variant="h6" align={isMobile ? "left" : "center"}>
          Next, you'll get to pick the occasions that you celebrate. Your friends and family will see these occasions
          and use them for reminders + gift ideas!
        </Typography>
      </div>
    </OnboardingStep>
  );
};

export default SelectOccasionInstructions;
