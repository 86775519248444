import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { GifteeStatus } from '../../__generated__/globalTypes';
import { getGiftees_giftees } from '../../graphql/queries/giftees/__generated__/getGiftees';
import { getOccasionsQuery } from '../../graphql/queries/occasions';
import {
  getOccasionsQuery as getOccasionsQueryType, getOccasionsQueryVariables
} from '../../graphql/queries/occasions/__generated__/getOccasionsQuery';
import { selectUser, setShowImportGifteeWizard } from '../../redux/add-giftee/actions';
import { closeNotificationsMenu } from '../../redux/general/actions';
import UserAvatar from '../general-ui/UserAvatar';

dayjs.extend(relativeTime);

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0)",
    },
  },
  giftee: {
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "90px",
    padding: theme.spacing(1),
    width: "100%",
    borderRadius: 12,
    transition: theme.transitions.create("background"),
    "&:hover": {
      background: theme.palette.background.paper2DP,
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },
  pendingAcceptance: {
    fontSize: 12,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    "& .lnr": {
      fontSize: 14,
      marginLeft: "10px",
    },
  },
  gifteeAvatar: {
    width: "65px",
    height: "65px",
  },
  gifteeInfo: {
    flex: "3",
    marginLeft: "10px",
  },
  gifteeName: {
    margin: "0px",
    position: "relative",
    wordBreak: "break-word",
  },
  newGiftee: {
    color: theme.palette.text.primary,
    background: theme.palette.secondary.main,
    borderRadius: 15,
    padding: "4px",
    position: "absolute",
    top: 0,
    right: 0,
    fontSize: 11,
    fontWeight: 400,
  },
  gifteeButtonContainer: {
    flex: "1",
  },
  upcomingOccasion: {
    fontSize: 12,
    color: theme.palette.text.hint,
  },
  giftIdeas: {
    color: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
  },
  gifteeButton: {
    background: "none",
    color: theme.palette.text.primary,
    boxShadow: "none",
  },
}));

type Props = {
  giftee: getGiftees_giftees;
};

const Giftee: FC<Props> = ({ giftee }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const goToGifteeDetail = () => {
    history.push(`/giftee/${giftee._id}/info`);
  };
  const importGiftee = () => {
    dispatch({ ...setShowImportGifteeWizard(true) });
    dispatch({ ...closeNotificationsMenu() });
    if (giftee.sourceUser) {
      dispatch({ ...selectUser(giftee.sourceUser) });
    }
  };
  const classes = useStyles();
  const { data } = useQuery<getOccasionsQueryType, getOccasionsQueryVariables>(getOccasionsQuery, {
    variables: {
      query: {
        giftee: {
          _id: giftee._id,
        },
      },
    },
  });
  const pending = giftee.isPendingAcceptance || giftee.status === GifteeStatus.PENDING;
  const approved = giftee.status === GifteeStatus.APPROVED;
  let title = "";
  let icon = "";
  if (pending) {
    title = "Pending User Acceptance";
    icon = "lnr lnr-clock";
  } else if (approved) {
    title = "Approved";
    icon = "lnr lnr-check";
  }

  const upcomingOccasion = data?.occasions?.[0];

  return (
    <ListItem
      dense
      button
      onClick={approved ? importGiftee : goToGifteeDetail}
      classes={{ root: classes.listItem }}
      disabled={pending ?? false}
    >
      <Paper classes={{ root: classes.giftee }}>
        <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.gifteeAvatar}>
          <UserAvatar name={giftee.name ?? ""} picture={giftee.picture} />
        </Grid>
        <div className={classes.gifteeInfo}>
          <Typography variant="subtitle1" component="h6" className={classes.gifteeName}>
            {giftee.name}
          </Typography>
          {pending || approved ? (
            <div className={classes.pendingAcceptance}>
              <Typography variant="subtitle1" className={classes.pendingAcceptance}>
                {title}
              </Typography>
              <span className={icon}></span>
            </div>
          ) : (
            <Typography variant="subtitle1" className={classes.upcomingOccasion}>
              {upcomingOccasion ? (
                <div>
                  {upcomingOccasion.name} is {dayjs(new Date(upcomingOccasion.nextOccasionDate)).fromNow()}
                </div>
              ) : (
                <div>You can add occasions to this giftee!</div>
              )}
            </Typography>
          )}
        </div>
        {!pending && !approved ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.gifteeButtonContainer}
          >
            <Fab color="primary" aria-label="Add" className={classes.gifteeButton}>
              <Icon>arrow_right</Icon>
            </Fab>
          </Grid>
        ) : (
          ""
        )}
      </Paper>
    </ListItem>
  );
};

export default Giftee;
