import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { resetAddGifteeState } from '../../../redux/add-giftee/actions';
import { AddGifteeRootState } from '../../../redux/add-giftee/reducers';
import { RootState } from '../../../redux/store';
import AnimatedCheckmark from '../../general-ui/AnimatedCheckmark';
import PrimaryButton from '../../general-ui/buttons/PrimaryButton';

const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.text.primary,
    height: "calc(900px - 50px)",
    padding: 16,
    overflow: "hidden",
    overflowY: "scroll",
    background: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: {
      height: "100vh",
    },
  },
  success: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    cursor: "pointer",
    zIndex: 10,
    boxShadow: "inset 0 0 2px #d90386",
  },
}));

type Props = {} & Partial<StepWizardChildProps>;

const GifteeImportConfirmed: FC<Props> = ({ goToStep, currentStep }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const addGifteeState = useSelector<RootState, AddGifteeRootState>((state) => {
    return state.addGiftee;
  });
  const { userSelected } = addGifteeState;

  const hideModal = () => {
    dispatch({
      ...resetAddGifteeState(),
    });
    goToStep?.(1);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-evenly"
      alignItems="center"
      classes={{ container: "flex-1 " + classes.container }}
    >
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <div className={classes.success}>
          <AnimatedCheckmark checked={currentStep === 4} color="#5FB9A6" />
        </div>

        <h3>Giftee has been created!</h3>
        <h5>
          Hooray! You just finished creating{" "}
          <strong>{userSelected?.name ? userSelected?.name : userSelected?.email}</strong> as your new giftee! Now you
          have their occasions to make sure you get them the right gift at the right time!
        </h5>
        <PrimaryButton onClick={hideModal}>Cool!</PrimaryButton>
      </Grid>
    </Grid>
  );
};

export default GifteeImportConfirmed;
