import React, { useEffect } from "react";
import { useGlobalMessageContext } from "./globalMessageContext";

type Props = {
  children: React.ReactNode;
  countdown?: number;
  notCloseable?: boolean;
  finalText?: string;
  id?: string;
};

const GlobalMessageDispatch = ({ children, countdown, finalText, notCloseable, id }: Props) => {
  const { globalMessageDispatch } = useGlobalMessageContext();

  useEffect(() => {
    globalMessageDispatch({
      type: "ADD_GLOBAL_MESSAGE",
      payload: {
        children,
        countdown,
        finalText,
        notCloseable,
        id,
      },
    });
  }, []);

  return <></>;
};

export default GlobalMessageDispatch;
