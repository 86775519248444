// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Product from "./Product.bs.js";
import * as Core$Mui from "rescript-material-ui/src/Core.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactDOMStyle from "@rescript/react/src/ReactDOMStyle.bs.js";
import * as Core from "@material-ui/core";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as Styles from "@material-ui/styles";
import * as Styles$1 from "@material-ui/core/styles";

var useStyles = Styles.makeStyles({
      feedRowContainer: {
        position: "relative"
      },
      arrowContainer: {
        position: "relative",
        width: "100%"
      },
      feedRow: ReactDOMStyle.unsafeAddProp({
            display: "flex",
            overflowX: "scroll",
            position: "relative",
            width: "100%"
          }, "scroll-snap-type", "x"),
      feedRowArrowRight: {
        bottom: "16px",
        fontSize: "24px",
        height: "calc(100% - 16px)",
        position: "absolute",
        right: "0px",
        zIndex: "10"
      },
      feedRowArrowLeft: {
        bottom: "16px",
        fontSize: "24px",
        height: "calc(100% - 16px)",
        left: "0px",
        position: "absolute",
        zIndex: "10"
      }
    });

function ProductFeedRow(Props) {
  var productFeed = Props.productFeed;
  var products = productFeed[1];
  var feedRowRef = React.useRef(null);
  var classes = useStyles();
  var match = React.useState(function () {
        return 50;
      });
  var setGiftIdeaIndex = match[1];
  var giftIdeaIndex = match[0];
  var handleProductIntersect = function (i) {
    var currentIdeaLength = giftIdeaIndex - 1 | 0;
    if (i === currentIdeaLength) {
      return Curry._1(setGiftIdeaIndex, (function (giftIdeaIndex) {
                    return giftIdeaIndex + 50 | 0;
                  }));
    }
    
  };
  var theme = Styles$1.useTheme();
  var isMobile = Core.useMediaQuery(Core$Mui.Breakpoint.get(theme, {
            NAME: "down",
            VAL: "sm"
          }));
  var handleLeftArrowClick = function (param) {
    Js_null_undefined.iter(feedRowRef.current, (function (row) {
            row.scrollBy({
                  behavior: "smooth",
                  left: -600,
                  top: 0
                });
            
          }));
    
  };
  var handleRightArrowClick = function (param) {
    Js_null_undefined.iter(feedRowRef.current, (function (row) {
            row.scrollBy({
                  behavior: "smooth",
                  left: 600,
                  top: 0
                });
            
          }));
    
  };
  var match$1 = isMobile ? [
      null,
      null
    ] : [
      React.createElement(Core.Button, {
            onClick: handleLeftArrowClick,
            children: React.createElement("span", {
                  className: "lnr lnr-chevron-left"
                }),
            className: classes.feedRowArrowLeft,
            variant: "outlined"
          }),
      React.createElement(Core.Button, {
            onClick: handleRightArrowClick,
            children: React.createElement("span", {
                  className: "lnr lnr-chevron-right"
                }),
            className: classes.feedRowArrowRight,
            variant: "outlined"
          })
    ];
  var match$2 = products.length;
  if (match$2 !== 0) {
    return React.createElement("div", {
                className: classes.feedRowContainer
              }, React.createElement(Core.Typography, {
                    children: Belt_Option.getWithDefault(productFeed[0], ""),
                    gutterBottom: true,
                    variant: "h6"
                  }), React.createElement("div", {
                    className: classes.arrowContainer
                  }, match$1[0], React.createElement("div", {
                        ref: feedRowRef,
                        className: classes.feedRow
                      }, products.slice(0, 10).map(function (product, i) {
                            return React.createElement(Product.make, {
                                        image: product.image,
                                        title: product.title,
                                        price: product.price,
                                        giftProvider: product.giftProvider,
                                        onIntersect: handleProductIntersect,
                                        index: i,
                                        id: product._id,
                                        gifteeMatches: product.gifteeMatches
                                      });
                          })), match$1[1]));
  } else {
    return null;
  }
}

var make = ProductFeedRow;

export {
  useStyles ,
  make ,
  
}
/* useStyles Not a pure module */
