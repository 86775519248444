import { useCallback, useState } from "react";
import { ApolloError } from "@apollo/client";
import { useAuthContext } from "../auth/authContext";
import { getAccessToken } from "../realm/auth";
import { GiftIdea } from "../components/gifts/types";
import { Gender } from "../redux/onboarding/constants/action-types";

export interface MutationResult<TData> {
  called: boolean;
  data?: TData;
  error?: ApolloError;
  hasError: boolean;
  loading: boolean;
}

type GiftProfilePayload = {
  profile: Partial<GiftIdea>[] | null;
  interests: string[] | null;
  userId: string;
  gender: Gender | null;
};

type UseUpsertProfileMutationType = [
  (onboardingPayload: GiftProfilePayload) => Promise<{ results: any } | false | null>,
  MutationResult<any>
];

const initialState = {
  called: false,
  data: undefined,
  error: undefined,
  hasError: false,
  loading: false,
};

const useUpsertGiftProfile = (): UseUpsertProfileMutationType => {
  const [result, setResult] = useState(initialState);
  const { authState } = useAuthContext();

  const fireFunction = useCallback(async (giftProfilePayload: GiftProfilePayload) => {
    try {
      setResult((prev) => ({
        ...prev,
        called: true,
        loading: true,
      }));
      const url = process.env.REACT_APP_OCC_API_URL + "/gift-profile/upsert";
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", authState.accessToken);
      const options = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ update: { ...giftProfilePayload } }),
      };
      let giftProfileRequests = await fetch(url, options);
      if (giftProfileRequests.status === 401) {
        const newToken = (await getAccessToken()) as string;
        myHeaders.append("Authorization", newToken);
        options.headers = myHeaders;
        giftProfileRequests = await fetch(url, options);
      }
      const onboardingResponse = await giftProfileRequests.json();

      setResult((prev) => ({
        ...prev,
        data: onboardingResponse as any,
        loading: false,
      }));
      return onboardingResponse;
    } catch (e) {
      console.error(e);
      setResult((prev) => ({
        ...prev,
        error: e,
        hasError: true,
      }));
      return false;
    }
  }, []);

  return [fireFunction, result];
};

export default useUpsertGiftProfile;
