// Generated by ReScript, PLEASE EDIT WITH CARE

import * as TS from "../bindings/TS.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as GQL__GiftProfile from "../graphql/queries/GQL__GiftProfile.bs.js";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/styles";

var useStyles = Styles.makeStyles(function (theme) {
      return {
              snackbar: {
                background: theme.palette.background.paper,
                color: theme.palette.text.primary
              },
              snackBarMessage: {
                display: "flex"
              },
              snackBarMessageText: {
                marginLeft: "8px"
              }
            };
    });

function GifteeGiftProfileEdit(Props) {
  var gifteeId = Props.gifteeId;
  var sourceUserId = Props.sourceUserId;
  var classes = useStyles();
  var snackbarClasses = {
    root: classes.snackbar
  };
  var snackBarContentProps = {
    classes: snackbarClasses
  };
  var match = Curry.app(GQL__GiftProfile.UpdateOneGiftProfileMut.use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var updateGiftProfile = match[0];
  var match$1 = sourceUserId !== undefined ? [
      GQL__GiftProfile.GetGiftProfileQuery.makeInputObjectGiftProfileQueryInput(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Caml_option.valFromOption(sourceUserId)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      true
    ] : [
      GQL__GiftProfile.GetGiftProfileQuery.makeInputObjectGiftProfileQueryInput(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(gifteeId), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      false
    ];
  var isUser = match$1[1];
  var giftProfileVariables = GQL__GiftProfile.GetGiftProfileQuery.makeVariables(match$1[0], undefined);
  var giftProfileResult = Curry.app(GQL__GiftProfile.GetGiftProfileQuery.use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        giftProfileVariables
      ]);
  var onChange = function (interest) {
    var updateGiftProfileQueryVariables = GQL__GiftProfile.UpdateOneGiftProfileMut.makeInputObjectGiftProfileQueryInput(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(gifteeId), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    var match = giftProfileResult.data;
    var currentInterests;
    if (match !== undefined) {
      var match$1 = match.giftProfile;
      if (match$1 !== undefined) {
        var interests = match$1.interests;
        currentInterests = interests !== undefined ? interests : [];
      } else {
        currentInterests = [];
      }
    } else {
      currentInterests = [];
    }
    var index = currentInterests.findIndex(function (i) {
          return Caml_obj.caml_equal(i, interest);
        });
    var newInterests;
    if (index !== -1) {
      var beforeInterests = currentInterests.slice(0, index);
      var afterInterests = currentInterests.slice(index + 1 | 0, currentInterests.length);
      newInterests = Belt_Array.concat(beforeInterests, afterInterests);
    } else {
      newInterests = Belt_Array.concat(currentInterests, [interest]);
    }
    var setGiftProfile = GQL__GiftProfile.UpdateOneGiftProfileMut.makeInputObjectGiftProfileUpdateInput(undefined, undefined, undefined, undefined, undefined, undefined, undefined, newInterests, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    var updateGiftProfileVariables = GQL__GiftProfile.UpdateOneGiftProfileMut.makeVariables(updateGiftProfileQueryVariables, setGiftProfile, undefined);
    var refetch = Curry._3(GQL__GiftProfile.GetGiftProfileQuery.refetchQueryDescription, undefined, undefined, giftProfileVariables);
    Curry._8(updateGiftProfile, undefined, undefined, undefined, undefined, undefined, [refetch], undefined, updateGiftProfileVariables);
    
  };
  var loadingSnack = match[1].loading ? React.createElement(Core.Snackbar, {
          ContentProps: snackBarContentProps,
          message: React.createElement("div", {
                className: classes.snackBarMessage
              }, React.createElement(Core.CircularProgress, {
                    size: 20,
                    thickness: 2
                  }), React.createElement("div", {
                    className: classes.snackBarMessageText
                  }, " Updating gift profile")),
          open: true
        }) : null;
  var value = React.useMemo((function () {
          var match = giftProfileResult.data;
          if (match === undefined) {
            return [];
          }
          var match$1 = match.giftProfile;
          if (match$1 === undefined) {
            return [];
          }
          var interests = match$1.interests;
          if (interests !== undefined) {
            return Belt_Array.map(interests, (function (interest) {
                          return Belt_Option.getWithDefault(interest, "");
                        }));
          } else {
            return [];
          }
        }), [giftProfileResult]);
  var match$2 = giftProfileResult.data;
  if (match$2 !== undefined) {
    var match$3 = match$2.giftProfile;
    if (match$3 !== undefined && match$3.interests !== undefined) {
      return React.createElement("div", undefined, loadingSnack, React.createElement(TS.InterestSelect.make, {
                      disabled: isUser,
                      hideUnselectedCategories: isUser,
                      value: value,
                      onChange: onChange
                    }));
    }
    
  }
  return React.createElement(TS.Loader.make, {});
}

var make = GifteeGiftProfileEdit;

export {
  useStyles ,
  make ,
  
}
/* useStyles Not a pure module */
