import React, { FC } from 'react';

import { CircularProgress, Grid, IconButton } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    maxHeight: "100vh",
    height: "100%",
  },
  logoProgress: {
    background: theme.palette.common.white,
    height: 60,
    width: 60,
    padding: theme.spacing(0.5),
    position: "relative",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      width: "100%",
      objectFit: "contain",
    },
  },
  progress: {
    position: "absolute",
    top: -5,
    left: -5,
    zIndex: 1,
    strokeLinecap: "round",
  },
}));

const Loader: FC = () => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" justifyContent="center" className={classes.container}>
      <div className={classes.logoProgress}>
        <img src="/images/logos/dark-logo.png" />
        <CircularProgress size={70} className={classes.progress} />
      </div>
    </Grid>
  );
};

export default Loader;
