// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AuthContextTs from "../../auth/authContext.ts";
import Default from "../../components/general-ui/Loader.tsx";
import Default$1 from "../../components/gifts/InterestSelect.tsx";
import Default$2 from "../../components/general-ui/modals/Modal.tsx";
import Default$3 from "../../components/gifts/GiftIdeaDetailContent.tsx";
import Default$4 from "../../components/gifts/GiftProfileRequestCard.tsx";

var make = Default$3;

var GiftIdeaDetailContent = {
  make: make
};

var make$1 = Default$2;

var Modal = {
  make: make$1
};

var make$2 = Default$1;

var InterestSelect = {
  make: make$2
};

var make$3 = Default;

var Loader = {
  make: make$3
};

var make$4 = Default$4;

var GiftProfileRequestCard = {
  make: make$4
};

function useContext(prim) {
  return AuthContextTs.useAuthContext();
}

var AuthContext = {
  useContext: useContext
};

export {
  GiftIdeaDetailContent ,
  Modal ,
  InterestSelect ,
  Loader ,
  GiftProfileRequestCard ,
  AuthContext ,
  
}
/* make Not a pure module */
