// Generated by ReScript, PLEASE EDIT WITH CARE

import * as TS from "../bindings/TS.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as GQL__GiftProfile from "../graphql/queries/GQL__GiftProfile.bs.js";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/styles";
import * as GiftProfileInfoModal from "./GiftProfileInfoModal.bs.js";

var useStyles = Styles.makeStyles(function (theme) {
      return {
              container: {
                display: "flex",
                height: "calc(100vh - 150px)",
                width: "100%",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center"
              },
              snackbar: {
                background: theme.palette.background.paper,
                color: theme.palette.text.primary
              },
              snackBarMessage: {
                display: "flex"
              },
              snackBarMessageText: {
                marginLeft: "8px"
              }
            };
    });

function UserGiftProfileEdit(Props) {
  var userId = Props.userId;
  var classes = useStyles();
  var snackbarClasses = {
    root: classes.snackbar
  };
  var snackBarContentProps = {
    classes: snackbarClasses
  };
  var match = React.useState(function () {
        return false;
      });
  var setOpenInfoModel = match[1];
  var handleInfoIconClick = function (param) {
    return Curry._1(setOpenInfoModel, (function (param) {
                  return true;
                }));
  };
  var handleInfoModalClose = function (param) {
    return Curry._1(setOpenInfoModel, (function (param) {
                  return false;
                }));
  };
  var match$1 = Curry.app(GQL__GiftProfile.UpdateOneGiftProfileMut.use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var updateGiftProfile = match$1[0];
  var profileQueryInput = GQL__GiftProfile.GetGiftProfileQuery.makeInputObjectGiftProfileQueryInput(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(userId), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  var profileQueryVariables = GQL__GiftProfile.GetGiftProfileQuery.makeVariables(profileQueryInput, undefined);
  var profileResult = Curry.app(GQL__GiftProfile.GetGiftProfileQuery.use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        profileQueryVariables
      ]);
  var onChange = function (interest) {
    var updateGiftProfileQueryVariables = GQL__GiftProfile.UpdateOneGiftProfileMut.makeInputObjectGiftProfileQueryInput(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(userId), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    var match = profileResult.data;
    var currentInterests;
    if (match !== undefined) {
      var match$1 = match.giftProfile;
      if (match$1 !== undefined) {
        var interests = match$1.interests;
        currentInterests = interests !== undefined ? interests : [];
      } else {
        currentInterests = [];
      }
    } else {
      currentInterests = [];
    }
    var index = currentInterests.findIndex(function (i) {
          return Caml_obj.caml_equal(i, interest);
        });
    var newInterests;
    if (index !== -1) {
      var beforeInterests = currentInterests.slice(0, index);
      var afterInterests = currentInterests.slice(index + 1 | 0, currentInterests.length);
      newInterests = Belt_Array.concat(beforeInterests, afterInterests);
    } else {
      newInterests = Belt_Array.concat(currentInterests, [interest]);
    }
    var setGiftProfile = GQL__GiftProfile.UpdateOneGiftProfileMut.makeInputObjectGiftProfileUpdateInput(undefined, undefined, undefined, undefined, undefined, undefined, undefined, newInterests, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    var updateGiftProfileVariables = GQL__GiftProfile.UpdateOneGiftProfileMut.makeVariables(updateGiftProfileQueryVariables, setGiftProfile, undefined);
    var refetch = Curry._3(GQL__GiftProfile.GetGiftProfileQuery.refetchQueryDescription, undefined, undefined, profileQueryVariables);
    Curry._8(updateGiftProfile, undefined, undefined, undefined, undefined, undefined, [refetch], undefined, updateGiftProfileVariables);
    
  };
  var loadingSnack = match$1[1].loading ? React.createElement(Core.Snackbar, {
          ContentProps: snackBarContentProps,
          message: React.createElement("div", {
                className: classes.snackBarMessage
              }, React.createElement(Core.CircularProgress, {
                    size: 20,
                    thickness: 2
                  }), React.createElement("div", {
                    className: classes.snackBarMessageText
                  }, " Updating gift profile")),
          open: true
        }) : null;
  var match$2 = profileResult.data;
  var contents;
  var exit = 0;
  if (match$2 !== undefined) {
    var match$3 = match$2.giftProfile;
    if (match$3 !== undefined) {
      var interests = match$3.interests;
      if (interests !== undefined) {
        var value = Belt_Array.map(interests, (function (interest) {
                return Belt_Option.getWithDefault(interest, "");
              }));
        contents = React.createElement(TS.InterestSelect.make, {
              disabled: false,
              hideUnselectedCategories: false,
              value: value,
              onChange: onChange
            });
      } else {
        exit = 1;
      }
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    contents = React.createElement(Core.CircularProgress, {
          size: 60,
          thickness: 2
        });
  }
  return React.createElement("div", {
              className: classes.container
            }, React.createElement(GiftProfileInfoModal.make, {
                  open: match[0],
                  onClose: handleInfoModalClose
                }), React.createElement(Core.Grid, {
                  alignItems: "center",
                  children: null,
                  justifyContent: "center"
                }, React.createElement(Core.Typography, {
                      children: "What is this?",
                      variant: "caption"
                    }), React.createElement(Core.IconButton, {
                      onClick: handleInfoIconClick,
                      children: React.createElement("span", {
                            className: "lnr lnr-question-circle"
                          }),
                      color: "primary"
                    })), contents, loadingSnack);
}

var make = UserGiftProfileEdit;

export {
  useStyles ,
  make ,
  
}
/* useStyles Not a pure module */
