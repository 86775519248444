import React, { FC } from "react";
import Modal from "../general-ui/modals/Modal";
import PolicyContainer from "./PolicyContainer";

type Props = { open: boolean; onClose: () => void };

const CookiePolicyDialog: FC<Props> = ({ open, onClose }) => {
  return (
    <Modal header="Cookie Policy" title="Cookie Policy" showModal={open} closeModal={onClose}>
      <PolicyContainer policyUrl="https://app.termly.io/document/cookie-policy/27205361-e0a8-4a35-8f32-58cb21c675f7" />
    </Modal>
  );
};

export default CookiePolicyDialog;
