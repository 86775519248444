import gql from "graphql-tag";

export const insertOneNotification = gql`
  mutation InsertOneNotificationMutation($data: NotificationInsertInput!) {
    insertOneNotification(data: $data) {
      _id
    }
  }
`;

export const updateOneNotification = gql`
  mutation UpdateOneNotificationMutation($query: NotificationQueryInput!, $set: NotificationUpdateInput!) {
    updateOneNotification(query: $query, set: $set) {
      _id
    }
  }
`;

export const deleteOneNotification = gql`
  mutation DeleteOneNotificationMutation($query: NotificationQueryInput!) {
    deleteOneNotification(query: $query) {
      _id
    }
  }
`;
export const deleteManyNotifications = gql`
  mutation DeleteManyNotificationsMutation($query: NotificationQueryInput!) {
    deleteManyNotifications(query: $query) {
      deletedCount
    }
  }
`;
