export const OPEN_GIFT_IDEA_DIALOG = "OPEN_GIFT_IDEA_DIALOG";
export const CLOSE_GIFT_IDEA_DIALOG = "CLOSE_GIFT_IDEA_DIALOG";
export const OPEN_MINI_GIFT_IDEA_DIALOG = "OPEN_MINI_GIFT_IDEA_DIALOG";
export const CLOSE_MINI_GIFT_IDEA_DIALOG = "CLOSE_MINI_GIFT_IDEA_DIALOG";
export const SELECT_GIFT_IDEA = "SELECT_GIFT_IDEA";
export const SET_ACTIVE_GIFT_IDEA = "SET_ACTIVE_GIFT_IDEA";
export const SET_ACTIVE_GIFT_IDEA_ID = "SET_ACTIVE_GIFT_IDEA_ID";
export const SET_ACTIVE_GIFT_IDEA_GIFTEE_MATCHES = "SET_ACTIVE_GIFT_IDEA_GIFTEE_MATCHES";
export const DESELECT_GIFT_IDEA = "DESELECT_GIFT_IDEA";
export const OPEN_MARK_GIFT_PURCHASED_DIALOG = "OPEN_MARK_GIFT_PURCHASED_DIALOG";
export const CLOSE_MARK_GIFT_PURCHASED_DIALOG = "CLOSE_MARK_GIFT_PURCHASED_DIALOG";
export const OPEN_PURCHASE_QUESTION_PROMPT_DIALOG = "OPEN_PURCHASE_QUESTION_PROMPT_DIALOG";
export const CLOSE_PURCHASE_QUESTION_PROMPT_DIALOG = "CLOSE_PURCHASE_QUESTION_PROMPT_DIALOG";
export const OPEN_GIFT_IDEA_FILTER = "OPEN_GIFT_IDEA_FILTER";
export const CLOSE_GIFT_IDEA_FILTER = "CLOSE_GIFT_IDEA_FILTER";
export const SET_PRICE_MAX = "SET_PRICE_MAX";
export const SET_PRICE_MIN = "SET_PRICE_MIN";
export const SET_PRICE_CHANGED = "SET_PRICE_CHANGED";
export const SET_INTERESTS_SELECTED = "SET_INTERESTS_SELECTED";
export const SET_INTERESTS = "SET_INTERESTS";
