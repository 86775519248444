import { GlobalMessageState, GlobalMessageAction, GlobalMessage } from "../../../@types/global-messages";
import { v4 as uuidv4 } from "uuid";

export const reducer = (state: GlobalMessageState, action: GlobalMessageAction) => {
  switch (action.type) {
    case "ADD_GLOBAL_MESSAGE":
      const newMessage: GlobalMessage = {
        ...action.payload,
        id: action.payload.id ?? uuidv4(),
      };
      return {
        ...state,
        globalMessages: [...state.globalMessages, newMessage],
      };
    case "REPLACE_GLOBAL_MESSAGE":
      const newGlobalMessages = [...state.globalMessages];
      const indexOfMessage = newGlobalMessages.findIndex((g) => g.id === action.payload.id);
      const replacementMessage: GlobalMessage = { ...action.payload };
      newGlobalMessages[indexOfMessage] = replacementMessage;
      return {
        ...state,
        globalMessages: newGlobalMessages,
      };
  }
};
