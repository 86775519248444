import clsx from 'clsx';
import React, { FC } from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "8px 12px",
    opacity: 1,
    boxSizing: "border-box",
    "&:hover": {
      opacity: 0.8,
    },
  },
  tertiaryButton: {
    width: "100%",
    background: theme.palette.background.background,
    color: theme.palette.text.primary,
    "&:hover": {
      background: theme.palette.background.paper2DP,
    },
  },
  tertiaryButtonOutlined: {
    border: "2px solid " + theme.palette.common.white,
    background: "none",
    color: theme.palette.text.secondary,
  },
}));

type Props = {
  onClick: () => void;
  children: (string | JSX.Element)[] | (string | JSX.Element);
  disabled?: boolean;
  variant?: string;
  className?: string;
};

const TertiaryButton: FC<Props> = ({ onClick, children, disabled, variant, className }) => {
  const classes = useStyles();
  const buttonClasses = clsx({
    [className ?? ""]: !!className,
    [classes.button]: true,
    [classes.tertiaryButton]: variant !== "outlined",
    [classes.tertiaryButtonOutlined]: variant === "outlined",
  });
  return (
    <Button onClick={onClick} variant="contained" disabled={disabled} className={buttonClasses}>
      {children}
    </Button>
  );
};

export default TertiaryButton;
