import React, { FC } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { User } from '../../@types/user';

const useStyles = makeStyles((theme) => ({
  avatar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    cursor: "pointer",
  },
  avatarSmall: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "40px",
    width: "40px",
    cursor: "pointer",
  },
  avatarImg: {
    objectFit: "cover",
    borderRadius: "50%",
    height: "100%",
    width: "100%",
  },
  avatarPara: {
    fontSize: "36px",
    height: "100%",
    width: "100%",
    borderRadius: "50%",
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
  },
}));

type Props = {
  picture?: string | null;
  name: string;
  small?: boolean;
};

const UserAvatar: FC<Props> = ({ picture, name, small }) => {
  const classes = useStyles();

  return (
    <div data-testid="user-avatar" className={small ? classes.avatarSmall : classes.avatar}>
      {picture && <img className={classes.avatarImg} src={picture} />}
      {!picture && <p className={classes.avatarPara}>{name.charAt(0).toUpperCase()}</p>}
    </div>
  );
};

export default UserAvatar;
