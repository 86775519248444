/* TypeScript file generated from GiftCategorySelectButton.res by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as GiftCategorySelectButtonBS__Es6Import from './GiftCategorySelectButton.bs';
const GiftCategorySelectButtonBS: any = GiftCategorySelectButtonBS__Es6Import;

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly category: string; 
  readonly disabled: boolean; 
  readonly icon: string; 
  readonly onClick: (_1:string) => void; 
  readonly selected: boolean
};

export const make: React.ComponentType<{
  readonly category: string; 
  readonly disabled: boolean; 
  readonly icon: string; 
  readonly onClick: (_1:string) => void; 
  readonly selected: boolean
}> = GiftCategorySelectButtonBS.make;
