import { GifteeMatch, GiftIdea } from '../../../components/gifts/types';
import {
  CLOSE_GIFT_IDEA_DIALOG, CLOSE_GIFT_IDEA_FILTER, CLOSE_MARK_GIFT_PURCHASED_DIALOG,
  CLOSE_MINI_GIFT_IDEA_DIALOG, CLOSE_PURCHASE_QUESTION_PROMPT_DIALOG, DESELECT_GIFT_IDEA,
  OPEN_GIFT_IDEA_DIALOG, OPEN_GIFT_IDEA_FILTER, OPEN_MARK_GIFT_PURCHASED_DIALOG,
  OPEN_MINI_GIFT_IDEA_DIALOG, OPEN_PURCHASE_QUESTION_PROMPT_DIALOG, SELECT_GIFT_IDEA,
  SET_ACTIVE_GIFT_IDEA, SET_ACTIVE_GIFT_IDEA_GIFTEE_MATCHES, SET_ACTIVE_GIFT_IDEA_ID, SET_INTERESTS,
  SET_INTERESTS_SELECTED, SET_PRICE_CHANGED, SET_PRICE_MAX, SET_PRICE_MIN
} from '../constants/action-types';

export const openGiftIdeaDialog = () => {
  return { type: OPEN_GIFT_IDEA_DIALOG };
};
export const closeGiftIdeaDialog = () => {
  return { type: CLOSE_GIFT_IDEA_DIALOG };
};
export const openMiniGiftIdeaDialog = () => {
  return { type: OPEN_MINI_GIFT_IDEA_DIALOG };
};
export const closeMiniGiftIdeaDialog = () => {
  return { type: CLOSE_MINI_GIFT_IDEA_DIALOG };
};
export const selectGiftIdea = (giftIdea: GiftIdea) => {
  return { type: SELECT_GIFT_IDEA, payload: giftIdea };
};
export const deselectGiftIdea = (giftIdea: GiftIdea) => {
  return { type: DESELECT_GIFT_IDEA, payload: giftIdea };
};
export const setActiveGiftIdea = (giftIdea: Partial<GiftIdea>) => {
  return { type: SET_ACTIVE_GIFT_IDEA, payload: giftIdea };
};
export const setActiveGiftIdeaId = (id: string) => {
  return { type: SET_ACTIVE_GIFT_IDEA_ID, payload: id };
};
export const setActiveGiftIdeaGifteeMatches = (gifteeMatches: GifteeMatch[]) => {
  return { type: SET_ACTIVE_GIFT_IDEA_GIFTEE_MATCHES, payload: gifteeMatches };
};
export const openMarkGiftPurchasedDialog = () => {
  return { type: OPEN_MARK_GIFT_PURCHASED_DIALOG };
};
export const closeMarkGiftPurchasedDialog = () => {
  return { type: CLOSE_MARK_GIFT_PURCHASED_DIALOG };
};
export const openPurchaseQuestionPrompt = () => {
  return { type: OPEN_PURCHASE_QUESTION_PROMPT_DIALOG };
};
export const closePurchaseQuestionPrompt = () => {
  return { type: CLOSE_PURCHASE_QUESTION_PROMPT_DIALOG };
};
export const openGiftIdeaFilter = () => {
  return { type: OPEN_GIFT_IDEA_FILTER };
};
export const closeGiftIdeaFilter = () => {
  return { type: CLOSE_GIFT_IDEA_FILTER };
};
export const setPriceMax = (priceMax: number) => {
  return { type: SET_PRICE_MAX, payload: priceMax };
};
export const setPriceMin = (priceMin: number) => {
  return { type: SET_PRICE_MIN, payload: priceMin };
};
export const setPriceChanged = (priceChanged: boolean) => {
  return { type: SET_PRICE_CHANGED, payload: priceChanged };
};
export const setInterests = (interests: string[]) => {
  return { type: SET_INTERESTS, payload: interests };
};
export const setInterestsSelected = (interests: string[]) => {
  return { type: SET_INTERESTS_SELECTED, payload: interests };
};
