import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import { useMutation } from '@apollo/client';
import { CircularProgress, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { GifteeStatus, NotificationType, OccasionType } from '../../../__generated__/globalTypes';
import { useAuthContext } from '../../../auth/authContext';
import { updateOneGiftee, upsertOneGiftee } from '../../../graphql/mutations/giftees';
import {
  UpdateOneGifteeMutation, UpdateOneGifteeMutationVariables
} from '../../../graphql/mutations/giftees/__generated__/UpdateOneGifteeMutation';
import { deleteOneNotification } from '../../../graphql/mutations/notifications';
import {
  DeleteOneNotificationMutation, DeleteOneNotificationMutationVariables
} from '../../../graphql/mutations/notifications/__generated__/DeleteOneNotificationMutation';
import { getGifteesQuery } from '../../../graphql/queries/giftees';
import { getNotificationsQuery } from '../../../graphql/queries/notifications';
import useAnalyticsEvent from '../../../hooks/analytics/useAnalyticsEvent';
import useAddOccasion from '../../../hooks/useAddOccasion';
import { AddGifteeRootState } from '../../../redux/add-giftee/reducers';
import { RootState } from '../../../redux/store';
import SecondaryButton from '../../general-ui/buttons/SecondaryButton';
import TertiaryButton from '../../general-ui/buttons/TertiaryButton';
import GeneralAvatar from '../../general-ui/GeneralAvatar';
import { gifteeRelationshipStartPhrase } from '../../giftees/GifteeRelationshipSelect';
import occasionIcons from '../../occasions/occasionIcons';

dayjs.extend(advancedFormat);
dayjs.extend(utc);

const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.text.primary,
    height: "calc(900px - 50px)",
    maxHeight: "90vh",
    padding: 0,
    overflow: "hidden",
    overflowY: "scroll",
    background: theme.palette.background.paper,
    "@media(max-width: 600px)": {
      height: "100vh",
      maxHeight: "initial",
    },
    "& h3": {},
  },
  finalList: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    listStyle: "none",
    paddingLeft: "0px",
    zIndex: 15,
    maxHeight: "125px",
    overflowY: "scroll",
  },
  occasionListContainer: {
    flex: 2,
    maxHeight: "25%",
    overflowY: "scroll",
  },
  occasionList: {},
  gifteeInfo: {
    "& h2": {
      marginBottom: 0,
    },
  },
  buttons: {
    padding: 16,
  },
  occasionIcon: {
    width: 30,
    fontSize: 20,
    color: theme.palette.text.primary,
    position: "relative",
    "& .lnr": {
      position: "absolute",
      left: 0,
      top: 0,
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "&:after": {
      content: '""',
      position: "absolute",
      right: 0,
      height: "100%",
      width: 2,
      background: theme.palette.text.primary,
    },
  },
  occasionContents: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing(2),
    height: 50,
  },
  occasionSummaryDetails: {
    color: theme.palette.text.primary,
    paddingLeft: 10,
  },
  occasionSummaryName: {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
}));

type Props = {} & Partial<StepWizardChildProps>;

const ConfirmGifteePage: FC<Props> = ({ previousStep, nextStep }) => {
  const fireGoogleAnalytics = useAnalyticsEvent("giftees", "invite-accepted", "Import Confirmed");
  const { authState } = useAuthContext();
  const [updateGiftee] = useMutation<UpdateOneGifteeMutation, UpdateOneGifteeMutationVariables>(updateOneGiftee);
  const [addOccasion] = useAddOccasion();
  const [deleteNotification] = useMutation<DeleteOneNotificationMutation, DeleteOneNotificationMutationVariables>(
    deleteOneNotification
  );
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const addGifteeState = useSelector<RootState, AddGifteeRootState>((state) => {
    return state.addGiftee;
  });
  const { userSelected, relationship, occasionsSelected } = addGifteeState;

  const renderOccasionsAll = () => {
    if (!occasionsSelected) {
      return <CircularProgress />;
    } else if (occasionsSelected.length == 0) {
      return (
        <Grid container direction="column" justifyContent="center" alignItems="center">
          No occasions for this giftee...
        </Grid>
      );
    } else {
      return occasionsSelected.map((occasion) => {
        const occasionIconClass = occasionIcons(occasion.type ?? OccasionType.BIRTHDAY);
        const occasionIcon = <span className={occasionIconClass}></span>;
        return (
          <div className={classes.occasionContents}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.occasionIcon}
            >
              <div>{occasionIcon}</div>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="space-evenly"
              alignItems="flex-start"
              className={classes.occasionSummaryDetails}
            >
              <div className={classes.occasionSummaryName}>{occasion.name}</div>
              <div>{occasion.nextOccasionDate && dayjs(occasion.nextOccasionDate).utc().format("MMM Do")}</div>
            </Grid>
          </div>
        );
      });
    }
  };

  const confirmUserGiftee = async () => {
    setLoading(true);
    try {
      if (addGifteeState.relationship === gifteeRelationshipStartPhrase) return;
      const updateGifteeResult = await updateGiftee({
        variables: {
          query: {
            userId: authState.user.auth.id,
            sourceUser: {
              _id: userSelected?._id,
            },
          },
          set: {
            isPendingAcceptance: false,
            status: GifteeStatus.ADDED,
            relationship: addGifteeState.relationship,
          },
        },
        refetchQueries: [
          {
            query: getGifteesQuery,
            variables: {
              query: {
                userId: authState.user.auth.id,
              },
            },
          },
        ],
      });

      await Promise.all(
        occasionsSelected.map(async (occasion) => {
          return await addOccasion({
            ...occasion,
            giftee: {
              link: updateGifteeResult?.data?.updateOneGiftee?._id,
            },
            createdAt: new Date(),
            dayMonthDate: {
              ...occasion.dayMonthDate,
              //@ts-ignore need to make it undefined because we are spreading occasion above which has typename
              __typename: undefined,
            },
            userId: undefined,
            __typename: undefined,
            _id: undefined,
          });
        })
      );
      await deleteNotification({
        variables: {
          query: {
            type: NotificationType.GIFTEEREQUESTACCEPTED,
            userId: authState.user.auth.id,
            requestee: {
              _id: userSelected?._id,
            },
          },
        },
        refetchQueries: [
          {
            query: getNotificationsQuery,
            variables: {
              query: {
                userId: authState.user.auth.id,
              },
            },
          },
        ],
      });
      fireGoogleAnalytics();
      nextStep?.();
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const gifteeRelationship = useMemo(
    () => relationship.charAt(0) + relationship.toLowerCase().substring(1),
    [relationship]
  );

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      wrap="nowrap"
      classes={{ container: "flex-1 " + classes.container }}
    >
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        wrap="nowrap"
        classes={{ container: "flex-1 " + classes.gifteeInfo }}
      >
        <div className="cloudinary-photo-upload-container">
          {userSelected?.picture ? (
            <GeneralAvatar src={userSelected.picture}>{userSelected?.email?.charAt(0).toUpperCase()}</GeneralAvatar>
          ) : (
            <GeneralAvatar>{userSelected?.email?.charAt(0).toUpperCase()}</GeneralAvatar>
          )}
        </div>
      </Grid>
      <Grid container direction="column" justifyContent="flex-start" alignItems="center">
        <Typography variant="h5">{userSelected?.name ? userSelected?.name : userSelected?.email}</Typography>
        <Typography variant="subtitle1" gutterBottom>
          {gifteeRelationship}
        </Typography>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        wrap="nowrap"
        classes={{ container: classes.occasionListContainer }}
      >
        <Typography variant="h6" style={{ padding: "0px 16px" }}>
          Occasions:
        </Typography>
        <div className={classes.occasionList}>{renderOccasionsAll()}</div>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        alignItems="center"
        classes={{ container: "flex-1 " + classes.buttons }}
      >
        <SecondaryButton disabled={loading} onClick={confirmUserGiftee}>
          Confirm Giftee
        </SecondaryButton>
        <p></p>
        <TertiaryButton onClick={previousStep as () => void}>
          <span className="lnr lnr-arrow-left"></span>
          &nbsp;Go Back
        </TertiaryButton>
      </Grid>
    </Grid>
  );
};

export default ConfirmGifteePage;
