import { Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowAddFirstGifteeDialog, setShowAddGifteeWizard } from "../../redux/add-giftee/actions";
import { AddGifteeRootState } from "../../redux/add-giftee/reducers";
import { RootState } from "../../redux/store";
import PrimaryButton from "../general-ui/buttons/PrimaryButton";
import SecondaryModal from "../general-ui/modals/SecondaryModal";

const useStyles = makeStyles((theme: Theme) => ({
  mainImage: { width: "100%" },
  container: {
    padding: theme.spacing(3),
  },
}));

const AddFirstGifteeDialog: FC = () => {
  const classes = useStyles();
  const handleClose = () => {
    dispatch({ ...setShowAddFirstGifteeDialog(false) });
  };
  const dispatch = useDispatch();
  const addGifteeState = useSelector<RootState, AddGifteeRootState>((state) => {
    return state.addGiftee;
  });
  const { showAddFirstGifteeDialog } = addGifteeState;
  const handleClick = () => {
    dispatch({ ...setShowAddGifteeWizard(true) });
    handleClose();
  };

  return (
    <SecondaryModal showModal={showAddFirstGifteeDialog} closeModal={handleClose}>
      <div className={classes.container}>
        <img src="/images/you-and-dog.png" className={classes.mainImage} />
        <Typography variant="h5" align="center" gutterBottom>
          Let's add your first Giftee!
        </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom>
          Now that your account is setup, let's add your first giftee so you can start getting great gift
          recommendations!
        </Typography>
        <PrimaryButton onClick={handleClick}>Add First Giftee</PrimaryButton>
      </div>
    </SecondaryModal>
  );
};

export default AddFirstGifteeDialog;
