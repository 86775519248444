import {
  CANCEL_DELETE_GIFTEE, CLOSE_ADD_INTEREST_DIALOG, CLOSE_ADD_OCCASION_DIALOG,
  CLOSE_ADD_REMINDER_DIALOG, CLOSE_EDIT_GIFTEE_GIFT_PROFILE_INTERESTS_DIALOG,
  OPEN_ADD_INTEREST_DIALOG, OPEN_ADD_OCCASION_DIALOG, OPEN_ADD_REMINDER_DIALOG,
  OPEN_EDIT_GIFTEE_GIFT_PROFILE_INTERESTS_DIALOG
} from '../constants/action-types';
import { ActiveGifteeAction } from '../types';

export type ActiveGifteeRootState = {
  editGifteeGiftProfileInterestsDialogOpen: boolean;
  newOccasionDialogOpen: boolean;
  cancelDeleteGiftee: boolean;
  newInterestDialogOpen: boolean;
  newReminderDialogOpen: boolean;
};

const initialState = {
  editGifteeGiftProfileInterestsDialogOpen: false,
  newOccasionDialogOpen: false,
  cancelDeleteGiftee: false,
  newInterestDialogOpen: false,
  newReminderDialogOpen: false,
};

const reducer = (state: ActiveGifteeRootState = initialState, action: ActiveGifteeAction) => {
  switch (action.type) {
    case OPEN_ADD_OCCASION_DIALOG:
      return { ...state, newOccasionDialogOpen: true };
    case CLOSE_ADD_OCCASION_DIALOG:
      return { ...state, newOccasionDialogOpen: false };
    case CANCEL_DELETE_GIFTEE:
      return { ...state, cancelDeleteGiftee: true };
    case OPEN_ADD_INTEREST_DIALOG:
      return { ...state, newInterestDialogOpen: true };
    case CLOSE_ADD_INTEREST_DIALOG:
      return { ...state, newInterestDialogOpen: false };
    case OPEN_EDIT_GIFTEE_GIFT_PROFILE_INTERESTS_DIALOG:
      return { ...state, editGifteeGiftProfileInterestsDialogOpen: true };
    case CLOSE_EDIT_GIFTEE_GIFT_PROFILE_INTERESTS_DIALOG:
      return { ...state, editGifteeGiftProfileInterestsDialogOpen: false };
    case OPEN_ADD_REMINDER_DIALOG:
      return { ...state, newReminderDialogOpen: true };
    case CLOSE_ADD_REMINDER_DIALOG:
      return { ...state, newReminderDialogOpen: false };
    default:
      return state;
  }
};

export default reducer;
