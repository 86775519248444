import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { useQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem/ListItem';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { OccasionType } from '../../__generated__/globalTypes';
import { getOccasions_occasions } from '../../graphql/queries/occasions/__generated__/getOccasions';
import { getRemindersQuery } from '../../graphql/queries/reminders';
import {
  getReminders, getReminders_reminders, getRemindersVariables
} from '../../graphql/queries/reminders/__generated__/getReminders';
import { openViewOccasionDialog, setActiveOccasion } from '../../redux/occasions/actions';
import GeneralAvatar from '../general-ui/GeneralAvatar';
import occasionIcons from './occasionIcons';

dayjs.extend(advancedFormat);
dayjs.extend(utc);

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: theme.palette.primary.main,
    background: theme.palette.text.primary,
    padding: theme.spacing(3),
  },
  occasionSelect: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    cursor: "pointer",
    zIndex: 10,
    boxShadow: "inset 0 0 2px #d90386",
  },
  occasionMain: {
    background: theme.palette.background.paper,
    minHeight: "94px",
    overflow: "hidden",
    marginBottom: theme.spacing(1),
    width: "100%",
    borderRadius: 12,
    position: "relative",
    zIndex: 1,
    "&:after": {
      pointerEvents: "none",
      background: theme.palette.background.activatedPaperTransparent,
      content: "''",
      position: "absolute",
      width: "80%",
      height: "80%",
      top: 0,
      right: 0,
      transition: theme.transitions.create("transform"),
      transform: "translate(100%, -100%)",
      zIndex: 0,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      maxWidth: "none",
      borderRadius: 0,
    },
  },
  occasionIcon: {
    width: 50,
    fontSize: 30,
    paddingRight: theme.spacing(2),
    color: theme.palette.primary.main,
    position: "relative",
    "& .lnr": {
      position: "absolute",
      left: 0,
      top: 0,
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "&:after": {
      content: '""',
      position: "absolute",
      right: 0,
      height: "100%",
      width: 2,
      background: theme.palette.primary.light,
    },
  },
  occasionSummaryDetails: {
    color: theme.palette.text.secondary,
    paddingLeft: 10,
  },
  occasionSummaryName: {
    color: theme.palette.text.secondary,
  },
  gifteeAvatar: {
    position: "absolute",
    bottom: -5,
    right: 0,
  },
}));

type Props = {
  occasion: getOccasions_occasions;
  showGiftee?: boolean;
};

const OccasionGiftee: FC<Props> = ({ occasion, showGiftee }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch({ ...setActiveOccasion(occasion) });
    dispatch({ ...openViewOccasionDialog() });
  };

  const classes = useStyles();

  const occasionIconClass = occasionIcons(occasion.type ?? OccasionType.BIRTHDAY);
  const occasionIcon = <span className={occasionIconClass}></span>;
  const today = dayjs();
  const nextOccasionDate = dayjs(occasion.nextOccasionDate ?? "");
  const monthsAway = nextOccasionDate.diff(today, "months");
  today.add(monthsAway, "months");
  const weeksAway = nextOccasionDate.diff(today, "weeks");
  today.add(weeksAway, "weeks");

  const { data } = useQuery<getReminders, getRemindersVariables>(getRemindersQuery, {
    variables: {
      query: {
        occasion: {
          _id: occasion._id,
        },
      },
    },
  });

  const reminders = (data?.reminders ?? []).filter((r): r is getReminders_reminders => r != null);

  const remindersLeft = reminders?.filter((reminder) => !reminder.fired) ?? [];

  return (
    <ListItem button onClick={handleClick} classes={{ root: classes.occasionMain }}>
      <Grid direction="row" justifyContent="space-evenly" alignItems="center" className={classes.occasionIcon}>
        <div>{occasionIcon}</div>
        {showGiftee ? (
          <Grid direction="row" justifyContent="space-evenly" alignItems="center" className={classes.gifteeAvatar}>
            <GeneralAvatar size="extra-small" src={occasion.giftee?.picture ?? undefined}>
              {occasion.giftee?.name?.charAt(0) ?? ""}
            </GeneralAvatar>
          </Grid>
        ) : null}
      </Grid>

      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="flex-start"
        className={classes.occasionSummaryDetails}
      >
        <Typography variant="body1" className={classes.occasionSummaryName}>
          {occasion.name}
        </Typography>
        <div>
          <span className="lnr lnr-calendar-full"></span> &nbsp;
          {occasion.nextOccasionDate ? nextOccasionDate.utc().format("MMM Do") : ""}
        </div>
        <div>
          <span className="lnr lnr-alarm"></span> &nbsp;
          {remindersLeft.length} reminders left
        </div>
      </Grid>
    </ListItem>
  );
};

export default OccasionGiftee;
