import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CreateGiftProfileWizard from "../components/wizards/CreateGiftProfileWizard";

const useStyles = makeStyles((theme) => ({
  "@global": {
    html: { background: theme.palette.background.background },
  },
  background: {
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.background.background,
  },
}));

const CreateGiftProfile: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.background}>
      <CreateGiftProfileWizard />
    </div>
  );
};

export default CreateGiftProfile;
