import {
  ADD_GLOBAL_MESSAGE, CLOSE_NOTIFICATIONS_MENU, CLOSE_USER_MENU, GO_TO_MENU_STEP,
  OPEN_NOTIFICATIONS_MENU, OPEN_USER_MENU, RESET_GENERAL_STATE, SET_DARK_MODE,
  SET_SHOW_GIFT_PROFILE_CREATED, TOGGLE_DARK_MODE, TOGGLE_USER_MENU
} from '../constants/action-types';
import { GeneralAction } from '../types';

export type GeneralRootState = {
  notificationPanelShow: boolean;
  globalMessages: GlobalMessage[];
  userMenuShow: boolean;
  giftProfileCreatedShow: boolean;
  darkMode: boolean;
  goToMenuStep: number | null;
};

export type GlobalMessage = {
  children: React.ReactNode;
};

const initialState: GeneralRootState = {
  notificationPanelShow: false,
  globalMessages: [],
  userMenuShow: false,
  giftProfileCreatedShow: false,
  darkMode: true,
  goToMenuStep: null,
};

const generalReducer = (state: GeneralRootState = initialState, action: GeneralAction) => {
  switch (action.type) {
    case OPEN_NOTIFICATIONS_MENU:
      return { ...state, notificationPanelShow: true };
    case CLOSE_NOTIFICATIONS_MENU:
      return { ...state, notificationPanelShow: false };
    case OPEN_USER_MENU:
      return { ...state, userMenuShow: true };
    case TOGGLE_USER_MENU:
      return { ...state, userMenuShow: !state.userMenuShow };
    case CLOSE_USER_MENU:
      return { ...state, userMenuShow: false };
    case TOGGLE_DARK_MODE:
      return { ...state, darkMode: !state.darkMode };
    case SET_DARK_MODE:
      return { ...state, darkMode: action.payload };
    case ADD_GLOBAL_MESSAGE:
      return { ...state, globalMessages: [...state.globalMessages, { children: action.payload?.children }] };
    case SET_SHOW_GIFT_PROFILE_CREATED:
      return { ...state, giftProfileCreatedShow: action.payload };
    case GO_TO_MENU_STEP:
      return { ...state, goToMenuStep: action.payload };
    case RESET_GENERAL_STATE:
      return { ...initialState, darkMode: state.darkMode };
    default:
      return state;
  }
};

export default generalReducer;
