export const SET_LOADING = "SET_LOADING";
export const ADD_GIFT_TYPE = "ADD_GIFT_TYPE";
export const REMOVE_GIFT_TYPE = "REMOVE_GIFT_TYPE";
export const ADD_OCCASIONS_ONBOARDING = "ADD_OCCASIONS_ONBOARDING";
export const SET_ONBOARDING_OCCASIONS = "SET_ONBOARDING_OCCASIONS";
export const REMOVE_ONBOARDING_OCCASION = "REMOVE_ONBOARDING_OCCASION";
export const SET_NAME = "SET_NAME";
export const SET_PHONE = "SET_PHONE";
export const SET_BIRTHDAY = "SET_BIRTHDAY";
export const SET_GENDER = "SET_GENDER";
export const SELECT_GIFT_IDEA = "SELECT_GIFT_IDEA";
export const DESELECT_GIFT_IDEA = "DESELECT_GIFT_IDEA";
export const SET_GIFT_PROFILE = "SET_GIFT_PROFILE";
export const ADD_INVITE = "ADD_INVITE";
export const REMOVE_INVITE = "REMOVE_INVITE";
export const SET_ONBOARDING_LOADING = "SET_ONBOARDING_LOADING";

export enum Gender {
  Male = "male",
  Female = "female",
  Unisex = "unisex",
}
