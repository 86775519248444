import clsx from 'clsx';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import StepWizard from 'react-step-wizard';

import Drawer from '@material-ui/core/Drawer';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

import { useAuthContext } from '../../auth/authContext';
import { RootState } from '../../redux/store';
import { make as UseGiftProfileEdit } from '../../rescript/components/UserGiftProfileEdit.gen';
import AccountInfo from './AccountInfo';
import InviteFriendEmail from './InviteFriendEmail';
import InviteFriendLinkShare from './InviteFriendLinkShare';
import OccasionSettings from './OccasionSettings';
import SettingsAndProfile from './SettingsAndProfile';
import SettingsNav from './SettingsNav';

const useStyles = makeStyles((theme) => ({
  drawer: {
    background: theme.palette.background.gradientBackground,
    maxWidth: 410,
    overflow: "hidden",
    border: "none",
  },
  desktopDrawer: {
    width: "80vw",
    transition: "0.2s " + theme.transitions.easing.easeInOut,
    willChange: "width",
    zIndex: 120,
  },
  desktopDrawerClosed: {
    width: 90,
    transition: theme.transitions.create("width"),
    willChange: "width",
    zIndex: 5,
  },
  settingsWrapper: {
    overflowY: "scroll",
    overflowX: "hidden",
    background: theme.palette.background.gradientBackground,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      maxWidth: 410,
    },
    position: "relative",
    padding: 8,
  },
}));

type Props = {
  showMenu: boolean;
  closeSettingsMenu: () => void;
};

const SettingsMenu: FC<Props> = ({ closeSettingsMenu }) => {
  const isMenuOpen = useSelector<RootState, boolean>((state) => {
    return state.general.userMenuShow;
  });
  const classes = useStyles();
  const { authState } = useAuthContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const drawerClass = clsx({
    [classes.drawer]: true,
    [classes.desktopDrawer]: !isMobile && isMenuOpen,
    [classes.desktopDrawerClosed]: !isMobile && !isMenuOpen,
  });

  return (
    <Drawer
      variant={isMobile ? "temporary" : "permanent"}
      open={isMenuOpen}
      onClose={closeSettingsMenu}
      classes={{ paper: drawerClass }}
    >
      <div className={classes.settingsWrapper}>
        <StepWizard nav={<SettingsNav closeMenu={closeSettingsMenu} isMenuOpen={isMenuOpen} />}>
          <SettingsAndProfile showMenu={isMenuOpen} />
          <UseGiftProfileEdit userId={authState.user.auth.id} />
          <AccountInfo user={authState.user} />
          <OccasionSettings />
          <InviteFriendEmail user={authState.user} />
          <InviteFriendLinkShare user={authState.user} />
        </StepWizard>
      </div>
    </Drawer>
  );
};

export default SettingsMenu;
