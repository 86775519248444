import gql from "graphql-tag";

export const getReminderQuery = gql`
  query getReminder($query: ReminderQueryInput) {
    reminder(query: $query) {
      _id
      occasion {
        name
        nextOccasionDate
        type
      }
      date
      fired
    }
  }
`;
export const getRemindersQuery = gql`
  query getReminders($query: ReminderQueryInput) {
    reminders(query: $query, sortBy: DATE_ASC) {
      _id
      date
      fired
      occasion {
        _id
        name
      }
    }
  }
`;
