import React, { FC, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client/react/hooks/useMutation';
import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { makeStyles, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';

import { useAuthContext } from '../../auth/authContext';
import { askForPermissionToReceiveNotifications } from '../../firebaseRegister';
import { updateOneNotificationSetting } from '../../graphql/mutations/notification-settings';
import { updateOneUser } from '../../graphql/mutations/users';
import { getNotificationSettingQuery } from '../../graphql/queries/notification-settings';
import { getDeviceType } from '../../utils/helpers/userDataHelpers';
import PrimaryButton from '../general-ui/buttons/PrimaryButton';
import EmailNotificationSetting from './EmailNotificationSetting';
import PushNotificationSetting from './PushNotificationSetting';
import SmsNotificationSetting from './SmsNotificationSettings';

const useStyles = makeStyles((theme) => ({
  notificationSettings: {
    paddingTop: theme.spacing(1),
  },
  settingsList: {
    width: "100%",
  },
  enablePushButtonContainer: {
    margin: theme.spacing(1),
  },
}));

type NotificationType = {
  type: string;
  deviceToken: string;
  deviceType: string;
  enabled: boolean;
  __typename: string;
};

type NotificationSetting = { notificationTypes: NotificationType[] };

const NotificationSettings: FC = () => {
  const classes = useStyles();
  const { authState } = useAuthContext();
  const [showEnableTextMessageDialog, setShowEnableTextMessageDialog] = useState(false);
  const { data } = useQuery(getNotificationSettingQuery, {
    variables: {
      query: {
        userId: authState.user.customData._id,
      },
    },
  }) as { data: { notificationSetting: NotificationSetting } };

  const notificationTypes = data?.notificationSetting?.notificationTypes ?? [];
  const emailNotificationTypes = useMemo(
    () => notificationTypes.filter((n) => n.type === "email"),
    [notificationTypes]
  );
  const pushNotificationTypes = useMemo(() => notificationTypes.filter((n) => n.type === "push"), [notificationTypes]);
  const smsNotificationTypes = useMemo(() => notificationTypes.filter((n) => n.type === "sms"), [notificationTypes]);

  const [updateNotificationSetting] = useMutation(updateOneNotificationSetting);
  const [updateUser] = useMutation(updateOneUser);

  const enablePushNotifications = async () => {
    const registration = await navigator.serviceWorker.getRegistration();
    const token = await askForPermissionToReceiveNotifications(registration);
    if (!token) {
      return;
    }
    const pushNotificationType = {
      enabled: true,
      deviceToken: token,
      deviceType: getDeviceType(),
      type: "push",
    };
    const currentNotificationTypes = notificationTypes.map((n) => {
      return {
        enabled: n.enabled,
        deviceType: n.deviceType,
        deviceToken: n.deviceToken,
        type: n.type,
      };
    });
    await updateNotificationSetting({
      variables: {
        query: {
          userId: authState.user.customData._id,
        },
        set: {
          notificationTypes: [...currentNotificationTypes, pushNotificationType],
          updatedAt: new Date(),
        },
      },
      refetchQueries: [
        {
          query: getNotificationSettingQuery,
          variables: {
            query: {
              userId: authState.user.customData._id,
            },
          },
        },
      ],
    });
  };
  const openEnableTextMessageDialog = () => {
    setShowEnableTextMessageDialog(true);
  };
  const enableTextMessageNotifications = async (phoneNumber: string) => {
    const smsNotificationType = {
      enabled: true,
      type: "sms",
    };
    const currentNotificationTypes = notificationTypes.map((n) => {
      return {
        enabled: n.enabled,
        deviceType: n.deviceType,
        deviceToken: n.deviceToken,
        type: n.type,
      };
    });
    await updateUser({
      variables: {
        query: {
          userId: authState.user.customData._id,
        },
        set: {
          phone: phoneNumber,
          updatedAt: new Date(),
        },
      },
    });
    await updateNotificationSetting({
      variables: {
        query: {
          userId: authState.user.customData._id,
        },
        set: {
          notificationTypes: [...currentNotificationTypes, smsNotificationType],
          updatedAt: new Date(),
        },
      },
      refetchQueries: [
        {
          query: getNotificationSettingQuery,
          variables: {
            query: {
              userId: authState.user.customData._id,
            },
          },
        },
      ],
    });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      wrap="nowrap"
      className={classes.notificationSettings}
    >
      <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" wrap="nowrap">
        <Typography variant="h6">Notification Settings</Typography>
        <List className={classes.settingsList}>
          {emailNotificationTypes.map((n) => (
            <EmailNotificationSetting key="email" notificationType={n} notificationTypes={notificationTypes} />
          ))}
          {pushNotificationTypes.map((n) => (
            <PushNotificationSetting key={n.deviceToken} notificationType={n} notificationTypes={notificationTypes} />
          ))}
          {/* {smsNotificationTypes.map((n) => (
            <SmsNotificationSetting key="sms" notificationType={n} notificationTypes={notificationTypes} />
          ))} */}
          {pushNotificationTypes.length === 0 && (
            <Grid className={classes.enablePushButtonContainer}>
              <PrimaryButton onClick={enablePushNotifications}>Enable Push Notifications</PrimaryButton>
            </Grid>
          )}
          {/* {smsNotificationTypes.length === 0 && (
            <Grid className={classes.enablePushButtonContainer}>
              <PrimaryButton onClick={openEnableTextMessageDialog}>Enable Text Message Notifications</PrimaryButton>
            </Grid>
          )} */}
        </List>
      </Grid>
    </Grid>
  );
};

export default NotificationSettings;
