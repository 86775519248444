import 'date-fns';

import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import { FormControl, InputLabel, OutlinedInput, Select } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { GiftProfile } from '../../../@types/gift-profiles';
import { GifteeRelationship, GiftProfileGender } from '../../../__generated__/globalTypes';
import {
  setGifteeBirthday, setGifteeGender, setGifteeName, setRelationship
} from '../../../redux/add-giftee/actions';
import { AddGifteeRootState } from '../../../redux/add-giftee/reducers';
import { RootState } from '../../../redux/store';
import {
  gender as Gender, make as GenderSelect
} from '../../../rescript/components/GenderSelect.gen';
import { months } from '../../../utils/constants/dateConstants';
import GifteeRelationshipSelect from '../../giftees/GifteeRelationshipSelect';
import WizardStep from '../general-wizard-ui/WizardStep';

const useStyles = makeStyles((theme: Theme) => ({
  formHeaderText: {
    "& h4, h6": {
      color: theme.palette.text.primary,
    },
    marginBottom: 10,
  },
  label: {
    color: theme.palette.text.primary,
    marginBottom: "5px",
  },
  formInput: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "stretch",
    color: theme.palette.text.primary,
    marginTop: 10,
    marginBottom: 20,
    "& input": {
      color: theme.palette.text.primary,
    },
  },
  inputItem: {
    width: "100%",
    "& label": {
      color: theme.palette.text.primary,
    },
  },
  innerInput: {
    "&:after": {
      borderBottom: "2px solid " + theme.palette.primary.main,
    },
    "&:before": {
      borderBottom: "2px solid " + theme.palette.text.hint,
    },
  },
  photoUpload: {
    alignSelf: "center",
  },
  datePicker: {
    "& .MuiInputBase-root": {
      opacity: 0,
    },
  },
  submitButton: {
    width: "100%",
  },
  flexOne: {
    flex: 1,
  },
  formSection: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  formControl: {
    width: "100%",
    margin: "4px 0px",
    "& label": {
      color: theme.palette.text.hint,
    },
    "& .Mui-focused select option": {
      background: theme.palette.background.background,
    },
  },
  input: {
    width: "100%",
    color: theme.palette.text.primary,
    "& fieldset": {
      borderColor: theme.palette.text.primary,
    },
    "& label": {
      color: theme.palette.text.hint,
    },
  },
}));

type Props = {
  hideModal: () => void;
} & Partial<StepWizardChildProps>;

const getGenderFromProfileGender = (profileGender: GiftProfileGender | null): Gender | undefined => {
  let gender: "UNISEX" | "MALE" | "FEMALE" | undefined;
  switch (profileGender) {
    case GiftProfileGender.MALE:
      gender = "MALE";
      break;
    case GiftProfileGender.FEMALE:
      gender = "FEMALE";
      break;
    case GiftProfileGender.UNISEX:
      gender = "UNISEX";
      break;
    case null:
      gender = undefined;
  }
  return gender;
};

const getProfileGenderFromGender = (gender: Gender): GiftProfileGender => {
  switch (gender) {
    case "MALE":
      return GiftProfileGender.MALE;
    case "FEMALE":
      return GiftProfileGender.FEMALE;
    case "UNISEX":
      return GiftProfileGender.UNISEX;
  }
};

const AddCustomGiftee: FC<Props> = () => {
  const dispatch = useDispatch();
  const { gifteeName, gifteeGender, relationship } = useSelector<RootState, AddGifteeRootState>((state) => {
    return state.addGiftee;
  });
  const [birthdayMonth, setBirthdayMonth] = useState(0);
  const [birthdayDate, setBirthdayDate] = useState(1);
  const classes = useStyles();

  const newGifteeNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ ...setGifteeName(event.target.value) });
  };

  const handleRelationshipChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch({ ...setRelationship(e.target.value as GifteeRelationship) });
  };

  const handleGenderChange = (gender: Gender) => {
    const profileGender = getProfileGenderFromGender(gender);
    dispatch({ ...setGifteeGender(profileGender) });
  };

  const gender = getGenderFromProfileGender(gifteeGender);

  useEffect(() => {
    const gifteeBirthday = new Date(1999, birthdayMonth, birthdayDate);
    dispatch({ ...setGifteeBirthday(gifteeBirthday) });
  }, [birthdayMonth, birthdayDate]);

  let dates = [];

  for (var i = 1; i <= 31; i++) {
    dates.push(i);
  }

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newMonth = parseInt(e.target.value);
    setBirthdayMonth(newMonth);
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newDate = parseInt(e.target.value);
    setBirthdayDate(newDate);
  };

  return (
    <WizardStep>
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        classes={{ container: "flex-2" }}
      >
        <div className={classes.formHeaderText}>
          <Typography variant="h4" align="center">
            Build Giftee
          </Typography>
        </div>
        <Grid container direction="column" justifyContent="space-evenly" alignItems="center" style={{ flex: "3" }}>
          <div className={classes.formSection}>
            <Typography variant="h6" gutterBottom={true}>
              General Info <span className="lnr lnr-user"></span>
            </Typography>
            <TextField
              variant="outlined"
              type="text"
              label="Name"
              value={gifteeName}
              className={classes.input}
              placeholder="Type to enter the giftee's name"
              onChange={newGifteeNameChange}
              InputProps={{ className: classes.innerInput }}
            />
            <div className={classes.formControl}>
              <GifteeRelationshipSelect value={relationship} onChange={handleRelationshipChange} />
            </div>
            <div className={classes.formControl}>
              <GenderSelect gender={gender} onChange={handleGenderChange} />
            </div>
          </div>
          <div className={classes.formSection}>
            <Typography variant="h6" gutterBottom={true}>
              Birthday <span className="lnr lnr-cake"></span>
            </Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-month-native-simple">Birthday Month</InputLabel>
              <Select
                native
                value={birthdayMonth}
                onChange={handleMonthChange as any}
                input={
                  <OutlinedInput
                    name="Birthday Month"
                    id="outlined-month-native-simple"
                    labelWidth={125}
                    className={classes.input}
                  />
                }
              >
                <option value="" />
                {months.map((month, i) => (
                  <option value={i} key={i}>
                    {month}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-date-native-simple">Day of month</InputLabel>
              <Select
                native
                value={birthdayDate}
                onChange={handleDateChange as any}
                input={
                  <OutlinedInput
                    name="Birthday Date"
                    id="outlined-date-native-simple"
                    labelWidth={110}
                    className={classes.input}
                  />
                }
              >
                <option value="" />
                {dates.map((date) => (
                  <option value={date} key={date}>
                    {date}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>
        </Grid>
      </Grid>
    </WizardStep>
  );
};

export default AddCustomGiftee;
