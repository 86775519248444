import React, { FC, useReducer, useMemo } from "react";

import { AuthContext, initialState } from "./authContext";
import { reducer } from "./authReducer";

type Props = {
  children: (string | JSX.Element)[] | (string | JSX.Element);
};

export const AuthWrapper: FC<Props> = ({ children }) => {
  const [authState, authDispatch] = useReducer(reducer, initialState);
  const value = useMemo(
    () => ({
      authState,
      authDispatch,
    }),
    [authState]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
