import 'date-fns';

import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import { FormControl, InputLabel, OutlinedInput, Select } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { OccasionType } from '../../../__generated__/globalTypes';
import { useAuthContext } from '../../../auth/authContext';
import { setBirthday } from '../../../redux/onboarding/actions';
import { OnboardingRootState } from '../../../redux/onboarding/reducers';
import { RootState } from '../../../redux/store';
import { months } from '../../../utils/constants/dateConstants';
import getNextOccasionDate from '../../../utils/occasionDates';
import { OnboardingWizardSteps } from '../OnboardingWizard';
import OnboardingStep from './OnboardingStep';

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    width: "100%",
    height: "30vh",
    maxHeight: 250,
    [theme.breakpoints.down("sm")]: {
      height: "30.5vh",
    },
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainImage: {
    height: "30vh",
    objectFit: "contain",
  },
  headline: {
    marginBottom: theme.spacing(2),
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "100%",
    maxWidth: 600,
    // [theme.breakpoints.down("sm")]: {
    //   justifyContent: "flex-start",
    //   alignItems: "flex-start",
    // },
    background: theme.palette.background.background,
    color: theme.palette.text.primary,
    // padding: "60px 16px",
    // height: "calc(100vh - 120px)",
  },
  formControlHeader: {
    marginBottom: theme.spacing(1),
    marginTop: "20px",
  },
  formControl: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& label": {
      color: theme.palette.text.primary,
    },
    "& .MuiSelect-select option": {
      background: theme.palette.background.background,
    },
    "& .Mui-focused select option": {
      background: theme.palette.background.background,
    },
  },
  header: {
    color: theme.palette.primary.main,
  },
  input: {
    color: theme.palette.text.primary,
    "& fieldset": {
      borderColor: theme.palette.text.primary,
    },
  },
  inputWrap: {
    marginTop: 20,
    position: "relative",
    "& button": {
      position: "absolute",
      right: 4,
      top: "calc(50% - 20px)",
      color: theme.palette.text.primary,
    },
  },
  confirmButtonContainer: {
    marginTop: theme.spacing(3),
  },
}));

type Props = {} & Partial<StepWizardChildProps>;

const SetBirthday: FC<Props> = ({ nextStep, previousStep, currentStep, isActive }) => {
  const { authState } = useAuthContext();
  const showSetNameAndPhone = !authState.user.customData.phone || !authState.user.customData.name;
  const classes = useStyles();
  const dispatch = useDispatch();
  const onboardingState = useSelector<RootState, OnboardingRootState>((state) => {
    return state.onboarding;
  });
  const birthdayMonth = new Date(onboardingState.birthday ?? new Date().toLocaleDateString()).getUTCMonth();
  const birthdayDate = new Date(onboardingState.birthday ?? new Date().toLocaleDateString()).getUTCDate();
  //weird hack to deal with swiping and skipping setting the name
  useEffect(() => {
    if (onboardingState.userName.trim() === "" && isActive && showSetNameAndPhone) {
      previousStep?.();
    }
  }, [onboardingState.userName, isActive]);

  let dates = [];

  for (var i = 1; i <= 31; i++) {
    dates.push(i);
  }

  const handleMonthChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>, _child: React.ReactNode) => {
    const newMonth = parseInt(e.target.value as string);
    const nextBirthday =
      getNextOccasionDate(OccasionType.BIRTHDAY, { day: birthdayDate, month: newMonth }) || new Date();
    dispatch({ ...setBirthday(nextBirthday) });
  };

  const handleDateChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>, _child: React.ReactNode) => {
    const newDate = parseInt(e.target.value as string);
    const nextBirthday =
      getNextOccasionDate(OccasionType.BIRTHDAY, { day: newDate, month: birthdayMonth }) || new Date();
    dispatch({ ...setBirthday(nextBirthday) });
  };

  return (
    <OnboardingStep
      step={currentStep ?? OnboardingWizardSteps.Introduction}
      onNext={nextStep}
      onPrevious={previousStep}
    >
      <div className={classes.form}>
        <Grid container direction="column" justifyContent="space-evenly" alignItems="center">
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <div className={classes.imageContainer}>
              <img src="/images/birthday-dog.png" className={classes.mainImage} />
            </div>
            <Typography variant="h5" align="center" className={classes.headline}>
              When is your birthday?
            </Typography>
            <Typography variant="body1" align="center" gutterBottom>
              Select the month and day you were born. Friends and family will be able to see your birthday to set
              reminders and get gift ideas. We only use your birthday for this purpose.
            </Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-month-native-simple">Birthday Month</InputLabel>
              <Select
                native
                value={birthdayMonth}
                onChange={handleMonthChange}
                input={
                  <OutlinedInput
                    name="Birthday Month"
                    id="outlined-month-native-simple"
                    labelWidth={120}
                    className={classes.input}
                  />
                }
              >
                <option value="" />
                {months.map((month, i) => (
                  <option value={i} key={i}>
                    {month}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-date-native-simple">Birthday Date</InputLabel>
              <Select
                native
                value={birthdayDate}
                onChange={handleDateChange}
                input={
                  <OutlinedInput
                    name="Birthday Date"
                    id="outlined-date-native-simple"
                    labelWidth={120}
                    className={classes.input}
                  />
                }
              >
                <option value="" />
                {dates.map((date) => (
                  <option value={date} key={date}>
                    {date}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </OnboardingStep>
  );
};

export default SetBirthday;
