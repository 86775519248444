import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';

import { setGoToMenuStep } from '../../redux/general/actions';
import { GeneralRootState } from '../../redux/general/reducers';
import { RootState } from '../../redux/store';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
    paddingTop: 30,
    "&:before": {
      position: "absolute",
      content: '""',
      width: "100vw",
      height: "100vh",
      top: 40,
      background: theme.palette.background.background,
      zIndex: "-2",
    },
  },
  backButtonWrapper: {
    position: "absolute",
    left: 6,
    top: 6,
    "& button": {
      color: theme.palette.text.primary,
    },
  },
  lightLogo: {
    marginBottom: 60,
    width: 40,
  },
}));

type Props = {
  step?: number;
  goToStep?: (step: number) => void;
  currentStep?: number;
  closeMenu: () => void;
  isMenuOpen: boolean;
};

const SettingsNav: React.FC<Props> = ({ goToStep, currentStep, closeMenu, isMenuOpen }) => {
  const classes = useStyles();
  const theme = useTheme();
  const logoSrc = theme.palette.type === "light" ? "/images/logos/contrast-logo.png" : "/images/logos/light-logo.png";
  const { goToMenuStep } = useSelector<RootState, GeneralRootState>((state) => state.general);
  const dispatch = useDispatch();

  useEffect(() => {
    if (goToMenuStep !== null) {
      goToStep?.(goToMenuStep);
      dispatch({ ...setGoToMenuStep(null) });
    }
  }, [goToMenuStep]);

  const handleClick = () => {
    if (currentStep === 1) {
      closeMenu();
    } else {
      if (goToStep) goToStep(1);
    }
  };

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.container}>
      <img src={logoSrc} className={classes.lightLogo} />
      {isMenuOpen ? (
        <>
          <div className={classes.backButtonWrapper}>
            <IconButton onClick={handleClick}>
              <Icon>
                <span className="lnr lnr-chevron-left"></span>
              </Icon>
            </IconButton>
          </div>
        </>
      ) : null}
    </Grid>
  );
};

export default SettingsNav;
