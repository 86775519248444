import clsx from 'clsx';
import React, { FC } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';

type Props = {
  alt?: string;
  src?: string;
  size?: "big" | "small" | "extra-small" | "extremely-small";
  children: React.ReactNode;
};

const useStyles = makeStyles((theme: Theme) => ({
  bigAvatar: {
    margin: 10,
    width: 150,
    height: 150,
    fontSize: 60,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
  },
  smallAvatar: {
    margin: 10,
    width: 75,
    height: 75,
    fontSize: 40,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
  },
  extraSmallAvatar: {
    margin: 5,
    width: 30,
    height: 30,
    fontSize: 25,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
  },
  extremelySmallAvatar: {
    border: "2px solid #303030",
    width: 23,
    height: 23,
    fontSize: 18,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
  },
  noWidth: {
    width: "initial",
  },
}));

const GeneralAvatar: FC<Props> = ({ alt, src, size, children }) => {
  const classes = useStyles();

  const avatarClass = clsx({
    [classes.bigAvatar]: !size,
    [classes.smallAvatar]: size === "small",
    [classes.extraSmallAvatar]: size === "extra-small",
    [classes.extremelySmallAvatar]: size === "extremely-small",
  });

  return (
    <Grid
      container
      className={size === "extremely-small" ? classes.noWidth : undefined}
      justifyContent="center"
      alignItems="center"
    >
      <Avatar alt={alt} src={src} className={avatarClass}>
        {children}
      </Avatar>
    </Grid>
  );
};

export default GeneralAvatar;
