import { useCallback } from "react";

const useAnalyticsEvent = (category: string, action: string, label: string, value?: number) => {
  return useCallback(() => {
    if (process.env.NODE_ENV === "development") return;
    if (window.location.host.split(".")[0] !== "app") return;
    if (!value) {
      value = 1;
    }
    gtag("event", action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  }, [category, action, label, value]);
};

export default useAnalyticsEvent;
