/* TypeScript file generated from GenderSelect.res by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as GenderSelectBS__Es6Import from './GenderSelect.bs';
const GenderSelectBS: any = GenderSelectBS__Es6Import;

// tslint:disable-next-line:interface-over-type-literal
export type gender = "UNISEX" | "MALE" | "FEMALE";

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly gender?: 
    "FEMALE"
  | "MALE"
  | "UNISEX"; 
  readonly noBorder?: boolean; 
  readonly noLabel?: boolean; 
  readonly onChange: (_1:
    "FEMALE"
  | "MALE"
  | "UNISEX", _2:{
  }) => void
};

export const make: React.ComponentType<{
  readonly gender?: 
    "FEMALE"
  | "MALE"
  | "UNISEX"; 
  readonly noBorder?: boolean; 
  readonly noLabel?: boolean; 
  readonly onChange: (_1:
    "FEMALE"
  | "MALE"
  | "UNISEX", _2:{
  }) => void
}> = GenderSelectBS.make;
