import 'date-fns';

import dayjs, { Dayjs } from 'dayjs';
import React, { FC, useState } from 'react';

import { useMutation } from '@apollo/client';
import DateUtils from '@date-io/date-fns';
import { Icon, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { insertOneReminder } from '../../graphql/mutations/reminders';
import {
  InsertOneReminderMutation, InsertOneReminderMutationVariables
} from '../../graphql/mutations/reminders/__generated__/InsertOneReminderMutation';
import { getOccasion_occasion } from '../../graphql/queries/occasions/__generated__/getOccasion';
import { getRemindersQuery } from '../../graphql/queries/reminders';
import { insertRemindersInCache } from '../../graphql/update-cache/reminders';
import AnimatedCheckmark from '../general-ui/AnimatedCheckmark';
import PrimaryButton from '../general-ui/buttons/PrimaryButton';
import GlobalMessageDispatch from '../general-ui/global-messages/GlobalMessageDispatch';

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 30,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 4px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  form: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
  },
  formControl: {
    width: "100%",
  },
  datePicker: {
    color: theme.palette.text.secondary,
    width: "100%",
    "& label": {
      color: theme.palette.text.secondary,
    },
    "& input": {
      color: theme.palette.text.secondary,
    },
    "& .MuiPickersDay-dayDisabled": {
      opacity: 0.5,
    },
    "& :before": {
      borderColor: theme.palette.text.secondary,
    },
  },
  button: {
    width: "100%",
    marginTop: "20px",
  },
}));

type Props = {
  occasions: Partial<getOccasion_occasion>[];
  closeModal: () => void;
  occasion?: Partial<getOccasion_occasion>;
};

const AddReminderForm: FC<Props> = ({ occasions, closeModal, occasion }) => {
  const classes = useStyles();

  const [date, setDate] = useState<null | Date>(new Date());
  const [occasionSelected, setOccasionSelected] = useState(occasions[0]._id);
  const [success, setSuccess] = useState(false);
  const [insertReminder] = useMutation<InsertOneReminderMutation, InsertOneReminderMutationVariables>(
    insertOneReminder
  );

  const handleDateChange = (e: MaterialUiPickersDate) => {
    setDate(e);
  };

  const handleOccasionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setOccasionSelected(e.target.value);
  };

  const addReminderHandler = async () => {
    const occasionId = occasion ? occasion._id : occasionSelected;
    const newReminder = {
      date,
      occasion: {
        link: occasionId,
      },
      fired: false,
    };
    const remindersQueryVariables = {
      query: {
        occasion: { _id: occasionId },
      },
    };
    await insertReminder({
      variables: {
        data: newReminder,
      },
      update: (cache, result) =>
        insertRemindersInCache(cache, remindersQueryVariables, {
          ...newReminder,
          occasion: result.data?.insertOneReminder?.occasion ?? null,
          _id: result.data?.insertOneReminder?._id ?? null,
          __typename: "Reminder" as const,
        }),
      refetchQueries: [
        {
          query: getRemindersQuery,
          variables: remindersQueryVariables,
        },
      ],
    });
    setSuccess(true);
    closeModal();
  };

  const renderOccasionOptions = () => {
    return occasions.map((occasion) => {
      return <MenuItem value={occasion._id}>{occasion.name}</MenuItem>;
    });
  };

  return (
    <Grid container direction="column" justifyContent="space-between" alignItems="flex-start" className={classes.form}>
      <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
        {occasion ? (
          ""
        ) : (
          <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
            <h4>Select an Occasion:</h4>
            <FormControl className={classes.formControl}>
              <Select value={occasion} onChange={handleOccasionChange as any} input={<BootstrapInput />}>
                {renderOccasionOptions()}
              </Select>
            </FormControl>
          </Grid>
        )}
        <h4>Select a Reminder Date:</h4>
        <MuiPickersUtilsProvider utils={DateUtils}>
          <DatePicker
            margin="normal"
            label="Reminder Date"
            disablePast={true}
            value={date}
            onChange={handleDateChange}
            className={classes.datePicker}
            DialogProps={{
              className: classes.datePicker,
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <div className={classes.button}>
        <PrimaryButton onClick={addReminderHandler}>Add Reminder</PrimaryButton>
      </div>
      {success && (
        <GlobalMessageDispatch>
          <Grid container direction="row" alignItems="center">
            <AnimatedCheckmark
              otherStyles={{ width: 40, height: 40 }}
              checked={true}
              icon={
                <Icon>
                  <span className="lnr lnr-bullhorn"></span>
                </Icon>
              }
            />

            <Typography variant="body1" color="inherit">
              &nbsp;Reminder successfully added!
            </Typography>
          </Grid>
        </GlobalMessageDispatch>
      )}
    </Grid>
  );
};

export default AddReminderForm;
