import { useCallback, useState } from "react";
import { useAuthContext } from "../auth/authContext";
import { Product } from "../components/gifts/types";

import { Gender } from "../redux/onboarding/constants/action-types";
import { getAccessToken } from "../realm/auth";

export type GiftIdeaRequestResult = {
  called: boolean;
  data?: Product[];
  error?: Error;
  hasError: boolean;
  loading: boolean;
};
type UseGiftIdeaRequestType = [
  (params: GiftIdeaRequestParams) => Promise<Product[] | undefined>,
  GiftIdeaRequestResult
];

const getInitialState = () => ({
  called: false,
  data: undefined,
  error: undefined,
  hasError: false,
  loading: false,
});

type GiftIdeaRequestParams = {
  interests: { title: string | null }[];
  priceMin?: number;
  priceMax?: number;
  gender?: Gender | null;
};

const useGiftIdeaRequest = (): UseGiftIdeaRequestType => {
  const [result, setResult] = useState<GiftIdeaRequestResult>(getInitialState);
  const { authState } = useAuthContext();

  const fireFunction = useCallback(async ({ interests, priceMin, priceMax, gender }: GiftIdeaRequestParams) => {
    try {
      setResult((prev) => ({
        ...prev,
        called: true,
        loading: true,
      }));
      const url = process.env.REACT_APP_OCC_API_URL + "/gift-profile/get-recommendations";
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", authState.accessToken);
      const options = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          profile: interests,
          priceMin: priceMin ?? 0,
          priceMax: priceMax ?? 10000,
          numberRecommendations: 500,
          gender,
        }),
      };
      let giftIdeaRequest = await fetch(url, options);
      if (giftIdeaRequest.status === 401) {
        const newToken = (await getAccessToken()) as string;
        myHeaders.set("Authorization", newToken);
        options.headers = myHeaders;
        giftIdeaRequest = await fetch(url, options);
      }
      const giftIdeaResponse = (await giftIdeaRequest.json()) as
        | { newRecommendations: Product[]; status: boolean }
        | undefined;

      setResult((prev) => ({
        ...prev,
        data: giftIdeaResponse?.newRecommendations,
        loading: false,
      }));
      return giftIdeaResponse?.newRecommendations;
    } catch (e) {
      console.error(e);
      setResult((prev) => ({
        ...prev,
        error: e,
        hasError: true,
      }));
      return;
    }
  }, []);

  return [fireFunction, result];
};

export default useGiftIdeaRequest;
