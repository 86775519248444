import { OccasionDayMonthDateInsertInput, OccasionType } from "../../../__generated__/globalTypes";
import { NewOccasionAction } from "../types";
import {
  SET_OCCASION_NAME,
  SET_OCCASION_TYPE,
  SET_USERS_TO_NOTIFY,
  SET_OCCASION_DATE,
  SET_CONFIRMED,
  RESET_NEW_OCCASION_STATE,
} from "../constants/action-types";

export type NewOccasionRootState = {
  occasionName: string;
  occasionType: OccasionType | "";
  usersToNotify: string[];
  occasionDate: OccasionDayMonthDateInsertInput;
  confirmed: boolean;
};

const initialState = {
  occasionName: "",
  occasionType: "" as const,
  usersToNotify: [],
  occasionDate: { day: new Date().getDate(), month: new Date().getMonth() },
  confirmed: false,
};

const activeGifteeReducer = (state: NewOccasionRootState = initialState, action: NewOccasionAction) => {
  switch (action.type) {
    case SET_OCCASION_NAME:
      return { ...state, occasionName: action.payload };
    case SET_OCCASION_TYPE:
      return { ...state, occasionType: action.payload };
    case SET_USERS_TO_NOTIFY:
      return { ...state, usersToNotify: action.payload };
    case SET_OCCASION_DATE:
      return { ...state, occasionDate: action.payload };
    case SET_CONFIRMED:
      return { ...state, confirmed: action.payload };
    case RESET_NEW_OCCASION_STATE:
      return { ...initialState };
    default:
      return state;
  }
};

export default activeGifteeReducer;
