import { useCallback } from "react";
import { OnboardingRootState } from "../../../redux/onboarding/reducers";
import { OnboardingWizardSteps } from "../OnboardingWizard";

export const useHandleNext = (
  step: OnboardingWizardSteps,
  showSetNameAndPhone: boolean,
  onFinish?: () => void,
  goToStep?: (step: number) => void,
  nextStep?: () => void
) => {
  return useCallback(() => {
    switch (step) {
      case OnboardingWizardSteps.ProfileConfirmation:
        onFinish?.();
        break;
      case OnboardingWizardSteps.SelectOccasions:
        if (showSetNameAndPhone) {
          nextStep?.();
        } else {
          goToStep?.(OnboardingWizardSteps.SetBirthday);
        }

        break;
      default:
        nextStep?.();

        break;
    }
  }, [step, showSetNameAndPhone]);
};

export const useButtonProps = (
  step: OnboardingWizardSteps,
  onboardingState: OnboardingRootState
): { hideBackButton?: boolean; text: string; testId: string; buttonDisabled?: boolean; skippable?: boolean } => {
  switch (step) {
    case OnboardingWizardSteps.Introduction:
      return { hideBackButton: true, text: "Let's do this", testId: "introduction-to-occasions" };
    case OnboardingWizardSteps.SelectOccasionInstructions:
      return { text: "Start Selecting", testId: "to-occasion-selection" };
    case OnboardingWizardSteps.SelectOccasions:
      return { text: "Confirm Selections", testId: "confirm-occasions", skippable: true };
    case OnboardingWizardSteps.SetNameAndPhone:
      return {
        text: "Confirm Info",
        testId: "confirm-info-button",
        buttonDisabled: onboardingState.userName.trim() === "",
      };
    case OnboardingWizardSteps.SetBirthday:
      return { text: "Start", testId: "set-birthday-button", skippable: true };
    case OnboardingWizardSteps.InviteFriends:
      if (onboardingState.invites.length === 0) {
        return { text: "Skip invites", testId: "testId" };
      }
      return { text: "Confirm invites", testId: "testId" };
    case OnboardingWizardSteps.ProfileConfirmation:
      return { text: "Get to it", testId: "testId" };
    default:
      return { text: "Thank you, Next", testId: "testId" };
  }
};
