import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles((theme) => ({
  dialog: {
    minWidth: 400,
    maxWidth: 400,
    [theme.breakpoints.down("sm")]: {
      minWidth: "90vw",
      maxWidth: "100%",
    },
  },
  dialogContent: {
    overflowX: "hidden",
    overflowY: "scroll",
  },
  dialogTitle: {
    textAlign: "center",
  },
}));

type Props = {
  showModal: boolean;
  closeModal: () => void;
  isBehindSecondModal?: boolean;
  header?: string;
  title?: string;
  variant?: string;
  secondModal?: boolean;
  children: (string | JSX.Element)[] | (string | JSX.Element) | null;
};

const SecondaryModal: FC<Props> = ({ closeModal, showModal, secondModal, title, children, isBehindSecondModal }) => {
  const classes = useStyles();
  const hideModal = () => {
    if (!isBehindSecondModal) {
      closeModal();
    }
  };

  return (
    <Dialog
      open={showModal}
      onClose={hideModal}
      style={secondModal ? { zIndex: 1400 } : {}}
      classes={{ paperScrollPaper: classes.dialog }}
      aria-labelledby={title}
    >
      {title ? (
        <DialogTitle id={title} className={classes.dialogTitle}>
          {title}
        </DialogTitle>
      ) : (
        ""
      )}
      {showModal ? <DialogContent className={classes.dialogContent}>{children}</DialogContent> : null}
    </Dialog>
  );
};

export default SecondaryModal;
