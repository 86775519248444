import React, { FC, useEffect } from "react";
import StepWizard from "react-step-wizard";

import { Giftee as GifteeType, Giftee } from "../../@types/giftee";
import ConfirmOccasion from "./add-occasion/ConfirmOccasion";
import OccasionConfirmed from "./add-occasion/OccasionConfirmed";
import SelectOccasionDate from "./add-occasion/SelectOccasionDate";
import SelectOccasionType from "./add-occasion/SelectOccasionType";
import SelectUsersToNotify from "./add-occasion/SelectUsersToNotify";
import { useDispatch } from "react-redux";
import { setOccasionName, setOccasionType } from "../../redux/new-occasion/actions";
import { OccasionType } from "../../__generated__/globalTypes";

export enum NewOccasionWizardSteps {
  SelectOccasionType = 1,
  SelectOccasionDate,
  SelectUsersToNotify,
  ConfirmOccasion,
  OccasionConfirmed,
}

type Props = {
  hideModal: () => void;
  giftee?: GifteeType;
  forUser?: boolean;
  initialOccasionType?: OccasionType | "";
  userId?: string;
};

const NewOccasionWizard: FC<Props> = ({ giftee, forUser, initialOccasionType, hideModal, userId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (initialOccasionType) {
      dispatch({ ...setOccasionName(initialOccasionType[0].toUpperCase() + initialOccasionType.substring(1)) });
      dispatch({ ...setOccasionType(initialOccasionType) });
    }
  }, [initialOccasionType]);

  const hideModalHandler = () => {
    hideModal();
  };

  return (
    <>
      <StepWizard isLazyMount={true}>
        <SelectOccasionType isUser={forUser ?? false} />
        <SelectOccasionDate isUser={forUser ?? false} />
        <SelectUsersToNotify />
        <ConfirmOccasion isUser={forUser ?? false} userId={userId} giftee={giftee as Giftee} />
        <OccasionConfirmed giftee={giftee as Giftee} hideModal={hideModalHandler} />
      </StepWizard>
    </>
  );
};

export default NewOccasionWizard;
