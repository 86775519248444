// Generated by ReScript, PLEASE EDIT WITH CARE

import * as TS from "../bindings/TS.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Redux from "../redux/Redux.bs.js";
import * as React from "react";
import * as Core$Mui from "rescript-material-ui/src/Core.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactRedux from "react-redux";
import * as Entity__Product from "../entities/Entity__Product.bs.js";
import * as Core from "@material-ui/core";
import * as GQL__GiftsProduct from "../graphql/queries/GQL__GiftsProduct.bs.js";
import * as Styles from "@material-ui/core/styles";

function GiftIdeaModal(Props) {
  var dispatch = ReactRedux.useDispatch();
  var giftIdeaState = ReactRedux.useSelector(function (rootState) {
        return rootState.giftIdeas;
      });
  var theme = Styles.useTheme();
  var isMobile = Core.useMediaQuery(Core$Mui.Breakpoint.get(theme, {
            NAME: "down",
            VAL: "sm"
          }));
  var match = Curry.app(GQL__GiftsProduct.GetProductQuery.useLazy, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var giftIdeaQueryResult = match[1];
  var queryGiftIdea = match[0];
  var loading;
  loading = giftIdeaQueryResult.TAG === /* Executed */0 ? giftIdeaQueryResult._0.loading : false;
  var onClose = function (param) {
    return dispatch(Redux.Action.closeGiftIdeaDialog(undefined));
  };
  React.useEffect((function () {
          var match = giftIdeaState.giftIdeaDialogOpen;
          var match$1 = giftIdeaState.activeGiftIdeaId;
          if (match$1 !== "" && match) {
            var query = GQL__GiftsProduct.GetProductQuery.makeInputObjectProductQueryInput(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(giftIdeaState.activeGiftIdeaId.replace(/["]/g, "")), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
            var variables = GQL__GiftsProduct.GetProductQuery.makeVariables(query, undefined);
            Curry._3(queryGiftIdea, undefined, undefined, variables);
          }
          
        }), [
        giftIdeaState.giftIdeaDialogOpen,
        giftIdeaState.activeGiftIdeaId
      ]);
  React.useEffect((function () {
          if (giftIdeaQueryResult.TAG === /* Executed */0) {
            var match = giftIdeaQueryResult._0.data;
            if (match !== undefined) {
              var product = match.product;
              if (product !== undefined) {
                var productEntity = Entity__Product.fromGiftIdeaQuery(product);
                dispatch(Redux.Action.setActiveGiftIdea(productEntity));
              }
              
            }
            
          }
          
        }), [loading]);
  var giftIdea = giftIdeaState.activeGiftIdea;
  if (giftIdea !== undefined) {
    return React.createElement(TS.Modal.make, {
                variant: "Dark",
                closeModal: onClose,
                showModal: giftIdeaState.giftIdeaDialogOpen,
                children: React.createElement(TS.GiftIdeaDetailContent.make, {
                      onClose: onClose,
                      isMobile: isMobile,
                      giftIdea: giftIdea,
                      loading: loading
                    })
              });
  } else {
    return null;
  }
}

var make = GiftIdeaModal;

export {
  make ,
  
}
/* TS Not a pure module */
