import React, { FC, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import Icon from "@material-ui/core/Icon";
import { makeStyles, Theme } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

import { openAddInterestDialog, openAddOccasionDialog, openAddReminderDialog } from "../../redux/active-giftee/actions";
import { Giftee } from "../../@types/giftee";
import useIsMobile from "../../hooks/useIsMobile";

const useStyles = makeStyles((theme: Theme) => ({
  detailButtonContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fab: {
    color: theme.palette.common.white,
  },
  speedActions: {
    background: theme.palette.secondary.dark,
    color: theme.palette.common.white,
  },
  speedDial: {
    position: "absolute",
    "&.MuiSpeedDial-directionUp": {
      bottom: theme.spacing(2),
    },
  },
  tooltip: {
    textAlign: "center",
    width: 125,
  },
}));

type Props = {
  giftee: Giftee;
};

const GifteeDetailButton: FC<Props> = ({ giftee }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const isMobile = useIsMobile();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const dispatch = useDispatch();

  const handleOccasionClick = () => {
    dispatch({ ...openAddOccasionDialog() });
  };
  const handleReminderClick = () => {
    dispatch({ ...openAddReminderDialog() });
  };

  const handleInterestClick = () => {
    dispatch({ ...openAddInterestDialog() });
  };

  const isCustomGiftee = giftee.isCustom;
  const actions = useMemo(() => {
    const actions = [
      { icon: "lnr lnr-calendar-full", name: "Add new occasion", onClick: handleOccasionClick },
      { icon: "lnr lnr-bullhorn", name: "Add new reminder", onClick: handleReminderClick },
    ];
    const interestAction = { icon: "lnr lnr-heart", name: "Add new interest", onClick: handleInterestClick };
    return isCustomGiftee ? [...actions, interestAction] : actions;
  }, [isCustomGiftee]);

  return (
    <div className={classes.detailButtonContainer}>
      <SpeedDial
        ariaLabel="Giftee Actions Button"
        className={classes.speedDial}
        icon={<SpeedDialIcon classes={{ root: classes.fab }} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        FabProps={{ color: "secondary" }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            classes={{ fab: classes.speedActions, staticTooltipLabel: classes.tooltip }}
            key={action.name}
            icon={
              <Icon>
                <span className={action.icon}></span>
              </Icon>
            }
            tooltipTitle={action.name}
            tooltipOpen={isMobile}
            onClick={action.onClick}
          />
        ))}
      </SpeedDial>
    </div>
  );
};

export default React.memo(GifteeDetailButton);
