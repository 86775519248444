import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import { useMutation, useQuery } from '@apollo/client/react/hooks';
import { CircularProgress, makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import { useAuthContext } from '../../../auth/authContext';
import { updateOneGiftProfile } from '../../../graphql/mutations/gift-profiles';
import {
  UpdateOneGiftProfileMutation, UpdateOneGiftProfileMutationVariables
} from '../../../graphql/mutations/gift-profiles/__generated__/UpdateOneGiftProfileMutation';
import { updateOneOccasion } from '../../../graphql/mutations/occasions';
import {
  UpdateOneOccasionMutation, UpdateOneOccasionMutationVariables
} from '../../../graphql/mutations/occasions/__generated__/UpdateOneOccasionMutation';
import { insertOneTransaction } from '../../../graphql/mutations/transactions';
import {
  InsertOneTransactionMutation, InsertOneTransactionMutationVariables
} from '../../../graphql/mutations/transactions/__generated__/InsertOneTransactionMutation';
import { getGiftProfileQuery } from '../../../graphql/queries/gift-profiles';
import {
  getGiftProfile, getGiftProfileVariables
} from '../../../graphql/queries/gift-profiles/__generated__/getGiftProfile';
import { getOccasionsQuery } from '../../../graphql/queries/occasions';
import {
  getOccasions as getOccasionsQueryType, getOccasionsVariables as getOccasionsQueryVariables
} from '../../../graphql/queries/occasions/__generated__/getOccasions';
import { updateGiftProfilePurchasesInCache } from '../../../graphql/update-cache/gift-profiles';
import {
  closeGiftIdeaDialog, closeMarkGiftPurchasedDialog, setActiveGiftIdea
} from '../../../redux/giftideas/actions';
import { GiftIdeaRootState } from '../../../redux/giftideas/reducers';
import { OccasionRootState } from '../../../redux/occasions/reducers';
import { RootState } from '../../../redux/store';
import PrimaryButton from '../../general-ui/buttons/PrimaryButton';

const useStyles = makeStyles((theme) => ({
  modal: {
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "calc(100vh - 64px)",
    background: theme.palette.background.paper,
  },
  giftIdeaHeader: {
    position: "relative",
    padding: "10px 16px 40px 16px",
    color: theme.palette.text.primary,
    "& h5": {
      zIndex: 10,
      maxHeight: 60,
      whiteSpace: "initial",
    },
    "& h6": {
      zIndex: 10,
      maxHeight: 85,
      whiteSpace: "initial",
    },
  },
  giftImage: {
    marginTop: theme.spacing(2),
    width: "100%",
    borderRadius: 20,
    zIndex: 10,
  },
  productActions: {
    zIndex: 10,
  },
  form: {},
  formControl: {
    width: "100%",
    zIndex: 10,
    marginBottom: theme.spacing(1),
    "& label": {
      color: theme.palette.text.secondary,
    },
  },
  input: {
    color: theme.palette.text.secondary,
    "& fieldset": {
      borderColor: theme.palette.text.secondary,
    },
    "& .MuiSelect-select option": {
      background: theme.palette.background.background,
    },
  },
}));

type Props = {
  gifteeId: string | null;
  userId: string | null;
} & Partial<StepWizardChildProps>;

const MarkGiftIdeaPurchased: FC<Props> = ({ gifteeId, userId, goToStep, isActive }) => {
  const classes = useStyles();
  const { authState } = useAuthContext();
  const { activeGiftIdea } = useSelector<RootState, GiftIdeaRootState>((state) => {
    return state.giftIdeas;
  });
  const { activeOccasion } = useSelector<RootState, OccasionRootState>((state) => {
    return state.occasions;
  });
  const dispatch = useDispatch();
  const profileQueryVariables = gifteeId ? { gifteeId } : { userId };
  const [occasion, setOccasion] = useState<string | null | unknown>("");
  const [year, setYear] = useState<number | null | unknown>(new Date().getFullYear());
  const [createTransaction, createTransactionContext] = useMutation<
    InsertOneTransactionMutation,
    InsertOneTransactionMutationVariables
  >(insertOneTransaction);
  const [updateGiftProfile, updateGiftProfileContext] = useMutation<
    UpdateOneGiftProfileMutation,
    UpdateOneGiftProfileMutationVariables
  >(updateOneGiftProfile);

  const [updateOccasion, updateOccasionContext] = useMutation<
    UpdateOneOccasionMutation,
    UpdateOneOccasionMutationVariables
  >(updateOneOccasion, {
    refetchQueries: [
      {
        query: getOccasionsQuery,
        variables: {
          query: {
            giftee: {
              _id: gifteeId,
            },
          },
        },
      },
    ],
    awaitRefetchQueries: true,
  });
  const getOccasionsQueryContext = useQuery<getOccasionsQueryType, getOccasionsQueryVariables>(getOccasionsQuery, {
    variables: {
      query: {
        giftee: {
          _id: gifteeId,
        },
      },
    },
    fetchPolicy: "network-only",
  });
  const giftProfileQueryContext = useQuery<getGiftProfile, getGiftProfileVariables>(getGiftProfileQuery, {
    variables: {
      query: profileQueryVariables,
    },
    fetchPolicy: "cache-first",
  });
  useEffect(() => {
    const occasions = getOccasionsQueryContext?.data?.occasions;
    if (occasions && occasions.length > 0 && !occasion) {
      setOccasion(occasions[0]?._id);
    }
  }, [getOccasionsQueryContext?.data?.occasions]);
  useEffect(() => {
    if (activeOccasion) {
      setOccasion(activeOccasion._id);
    }
  }, [activeOccasion]);

  const currentPurchases =
    giftProfileQueryContext.data?.giftProfile?.purchases
      ?.filter((p) => !!p)
      .map((p) => {
        return {
          transactionId: p?.transactionId,
          giftIdeaId: p?.giftIdeaId,
          date: p?.date,
          targetYear: p?.targetYear,
        };
      }) ?? [];

  const occasionYears = () => {
    const currentYear = new Date().getFullYear();
    const yearArray: number[] = [currentYear];
    for (let i = currentYear + 1; i < currentYear + 5; i++) {
      yearArray.push(i);
    }
    return yearArray;
  };

  const closeDialog = () => {
    dispatch({ ...closeMarkGiftPurchasedDialog() });
    dispatch({ ...setActiveGiftIdea({}) });
    goToStep?.(1);
  };

  const submit = async () => {
    const transactionResult = await createTransaction({
      variables: {
        data: {
          gifteeId,
          giftIdeaId: activeGiftIdea?._id,
          giftIdeaTitle: activeGiftIdea?.title,
          giftIdeaImage: activeGiftIdea?.image,
          createdAt: new Date(),
          updatedAt: new Date(),
          occasionId: occasion,
          amount: activeGiftIdea?.price,
          userId: authState.user.customData._id,
        },
      },
    });
    const newPurchase = {
      transactionId: transactionResult.data?.insertOneTransaction?._id,
      giftIdeaId: activeGiftIdea?._id,
      date: new Date(),
      targetYear: year as number,
    };
    const newPurchases = [...currentPurchases, newPurchase];

    if (userId) {
      await updateGiftProfile({
        variables: {
          query: { userId },
          set: { purchases: newPurchases },
        },
        update: (cache) => {
          updateGiftProfilePurchasesInCache(
            cache,
            {
              query: profileQueryVariables,
            },
            newPurchases
          );
        },
      });
    } else if (gifteeId) {
      await updateGiftProfile({
        variables: {
          query: { gifteeId },
          set: { purchases: newPurchases },
        },
        update: (cache) => {
          updateGiftProfilePurchasesInCache(
            cache,
            {
              query: profileQueryVariables,
            },
            newPurchases
          );
        },
      });
    }
    const selectedOccasion = getOccasionsQueryContext?.data?.occasions.find((o) => o?._id === occasion);
    const occasionPurchases =
      selectedOccasion?.purchases
        ?.filter((p) => !!p)
        ?.map((p) => ({
          transactionId: p?.transactionId,
          giftIdeaId: p?.giftIdeaId,
          date: p?.date,
          targetYear: p?.targetYear,
        })) ?? [];
    await updateOccasion({
      variables: {
        query: {
          _id: occasion,
        },
        set: {
          purchases: [...occasionPurchases, newPurchase],
        },
      },
    });
    dispatch({ ...closeGiftIdeaDialog() });
    goToStep?.(1);
    closeDialog();
  };

  const giftIdeaTitle = activeGiftIdea?.title;

  useEffect(() => {
    if (giftIdeaTitle == null && isActive) {
      goToStep?.(1);
    }
  }, [giftIdeaTitle, isActive]);

  const loading =
    giftProfileQueryContext.loading ||
    updateOccasionContext.loading ||
    getOccasionsQueryContext.loading ||
    createTransactionContext.loading ||
    updateGiftProfileContext.loading;
  return (
    <div className={classes.modal}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        className={classes.giftIdeaHeader}
      >
        <img src={activeGiftIdea?.image ?? ""} className={classes.giftImage} />
        <Typography variant="h6" noWrap gutterBottom>
          {activeGiftIdea?.title}
        </Typography>
        <Typography variant="h5" noWrap>
          Mark Gift idea Purchased
        </Typography>
        <Typography variant="subtitle1" noWrap>
          When you mark this gift as purchased, it will no longer show up in gift recommendations for this giftee.
        </Typography>
      </Grid>
      <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.form}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel shrink={true} htmlFor="outlined-age-native-simple">
            Occasion gift is for:
          </InputLabel>
          <Select
            value={occasion ?? ""}
            native
            labelWidth={160}
            input={<OutlinedInput name="Occasion" id="occasion" className={classes.input} labelWidth={160} />}
            onChange={(e) => {
              if (e.target.value) {
                setOccasion(e.target.value ?? "");
              }
            }}
          >
            {getOccasionsQueryContext?.data?.occasions?.map((occasion) => (
              <option value={occasion?._id ?? ""}>{occasion?.name ?? ""}</option>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="outlined-age-native-simple">Year gift is for:</InputLabel>
          <Select
            value={year}
            native
            input={<OutlinedInput name="Year" id="year" className={classes.input} labelWidth={120} />}
            onChange={(e) => {
              setYear(e.target.value);
            }}
          >
            <option value=""></option>
            {occasionYears().map((year) => (
              <option value={year}>{year}</option>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.productActions}>
        <PrimaryButton onClick={submit}>
          {loading ? <CircularProgress size={20} color="primary" /> : "Mark Gift as Purchased"}
        </PrimaryButton>
      </Grid>
    </div>
  );
};
export default MarkGiftIdeaPurchased;
