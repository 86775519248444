export const RESET_ADD_GIFTEE = "RESET_ADD_GIFTEE";
export const ADD_GIFTEE_OCCASION = "ADD_GIFTEE_OCCASION";
export const REMOVE_GIFTEE_OCCASION = "REMOVE_GIFTEE_OCCASION";
export const SET_GIFTEE_OCCASIONS = "SET_GIFTEE_OCCASIONS";
export const SELECT_OCCASION = "SELECT_OCCASION";
export const DESELECT_OCCASION = "DESELECT_OCCASION";
export const SELECT_USER = "SELECT_USER";
export const DESELECT_USER = "DESELECT_USER";
export const SET_RELATIONSHIP = "SET_RELATIONSHIP";
export const SET_INVITE_SENT = "SET_INVITE_SENT";
export const SET_SHOW_ADD_GIFTEE_WIZARD = "SET_SHOW_ADD_GIFTEE_WIZARD";
export const SET_SHOW_IMPORT_GIFTEE_WIZARD = "SET_SHOW_IMPORT_GIFTEE_WIZARD";
export const SET_SHOW_ADD_GIFTEE_SUGGESTION_DIALOG = "SET_SHOW_ADD_GIFTEE_SUGGESTION_DIALOG";
export const SET_SHOW_ADD_FIRST_GIFTEE_DIALOG = "SET_SHOW_ADD_FIRST_GIFTEE_DIALOG";
export const SET_GIFTEE_INTERESTS = "SET_GIFTEE_INTERESTS";
export const SET_GIFTEE_NAME = "SET_GIFTEE_NAME";
export const SET_GIFTEE_PICTURE = "SET_GIFTEE_PICTURE";
export const SET_GIFTEE_BIRTHDAY = "SET_GIFTEE_BIRTHDAY";
export const SET_GIFTEE_GENDER = "SET_GIFTEE_GENDER";
export const SET_ADD_GIFTEE_LOADING = "SET_ADD_GIFTEE_LOADING";
