import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { FC, useEffect, useMemo, useRef } from 'react';

import { useMutation } from '@apollo/client/react/hooks/useMutation';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { NotificationType as NotificationTypeEnum } from '../../__generated__/globalTypes';
import {
  deleteOneNotification, updateOneNotification
} from '../../graphql/mutations/notifications';
import {
  DeleteOneNotificationMutation, DeleteOneNotificationMutationVariables
} from '../../graphql/mutations/notifications/__generated__/DeleteOneNotificationMutation';
import {
  UpdateOneNotificationMutation, UpdateOneNotificationMutationVariables
} from '../../graphql/mutations/notifications/__generated__/UpdateOneNotificationMutation';
import {
  getNotifications_notifications as NotificationType
} from '../../graphql/queries/notifications/__generated__/getNotifications';
import {
  archiveNotificationInCache, deleteNotificationFromCache
} from '../../graphql/update-cache/notifications';
import { useGlobalMessageContext } from '../general-ui/global-messages/globalMessageContext';
import NotificationApprovedGiftee from './NotificationApprovedGiftee';
import NotificationDeniedGiftee from './NotificationDeniedGiftee';
import NotificationGifteeAddedOccasion from './NotificationGifteeAddedOccasion';
import NotificationGifteeRequest from './NotificationGifteeRequest';
import NotificationGiftProfileRequest from './NotificationGiftProfileRequest';
import NotificationGiftProfileSetupPrompt from './NotificationGiftProfileSetupPrompt';
import NotificationOccasion from './NotificationOccasion';
import NotificationReminder from './NotificationReminder';
import NotificationUserInviteAccepted from './NotificationUserInviteAccepted';

dayjs.extend(relativeTime);

const useStyles = makeStyles((theme) => ({
  notification: {
    background: theme.palette.background.paper,
  },
}));

type Props = {
  notification: NotificationType;
};

const Notification: FC<Props> = ({ notification }) => {
  const classes = useStyles();
  const { globalMessageDispatch } = useGlobalMessageContext();
  const timeAgo = dayjs(notification.createdAt).fromNow();
  const [updateNotification] = useMutation<UpdateOneNotificationMutation, UpdateOneNotificationMutationVariables>(
    updateOneNotification
  );
  const [deleteNotification] = useMutation<DeleteOneNotificationMutation, DeleteOneNotificationMutationVariables>(
    deleteOneNotification
  );
  const observer = useRef<IntersectionObserver>();
  const notificationEl = useRef<HTMLDivElement>(null);
  useEffect(() => {
    //mark as not new/read once notification is viewed
    const lazyLoadCallback: IntersectionObserverCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(async (entry) => {
        const { isIntersecting } = entry;
        if (isIntersecting && notification.new) {
          await updateNotification({
            variables: {
              query: {
                _id: notification._id,
              },
              set: {
                new: false,
              },
            },
          });
          observer.current?.disconnect();
        }
      });
    };

    const options = {
      rootMargin: "0px",
      threshold: 0.5,
    };

    observer.current = new IntersectionObserver(lazyLoadCallback, options);
    observer.current.observe(notificationEl.current as Element);
  }, []);
  const onDelete = async () => {
    await deleteNotification({
      variables: {
        query: {
          _id: notification._id,
        },
      },
      update: (cache, result) =>
        deleteNotificationFromCache(
          cache,
          {
            query: {
              userId: notification.userId,
            },
          },
          result
        ),
    });
    const message = <>Notification Deleted</>;
    globalMessageDispatch({
      type: "ADD_GLOBAL_MESSAGE",
      payload: {
        children: message,
      },
    });
  };

  const onArchive = async () => {
    await updateNotification({
      variables: {
        query: {
          _id: notification._id,
        },
        set: {
          archived: true,
        },
      },
      update: (cache, result) =>
        archiveNotificationInCache(
          cache,
          {
            query: {
              userId: notification.userId,
            },
          },
          result
        ),
    });
    const message = <>Notification Archived</>;
    globalMessageDispatch({
      type: "ADD_GLOBAL_MESSAGE",
      payload: {
        children: message,
      },
    });
  };

  const notificationElement = useMemo(() => {
    if (notification.type != null) {
      switch (notification.type) {
        case NotificationTypeEnum.ADDGIFTEEREQUEST:
          return <NotificationGifteeRequest onDelete={onDelete} notification={notification} timeAgo={timeAgo} />;
        case NotificationTypeEnum.GIFTEEREQUESTACCEPTED:
          return <NotificationApprovedGiftee notification={notification} timeAgo={timeAgo} />;
        case NotificationTypeEnum.GIFTEEREQUESTDENIED:
          return (
            <NotificationDeniedGiftee
              onDelete={onDelete}
              onArchive={onArchive}
              notification={notification}
              timeAgo={timeAgo}
            />
          );
        case NotificationTypeEnum.OCCASION:
          return (
            <NotificationOccasion
              onDelete={onDelete}
              onArchive={onArchive}
              notification={notification}
              timeAgo={timeAgo}
            />
          );
        case NotificationTypeEnum.REMINDER:
          return (
            <NotificationReminder
              onDelete={onDelete}
              onArchive={onArchive}
              notification={notification}
              timeAgo={timeAgo}
            />
          );
        case NotificationTypeEnum.USERINVITEACCEPTED:
          return <NotificationUserInviteAccepted notification={notification} timeAgo={timeAgo} />;
        case NotificationTypeEnum.GIFTPROFILEREQUEST:
          return <NotificationGiftProfileRequest notification={notification} timeAgo={timeAgo} />;
        case NotificationTypeEnum.GIFTPROFILESETUPPROMPT:
          return <NotificationGiftProfileSetupPrompt notification={notification} timeAgo={timeAgo} />;
        case NotificationTypeEnum.GIFTEEOCCASIONADDED:
          return <NotificationGifteeAddedOccasion notification={notification} timeAgo={timeAgo} onDelete={onDelete} />;
        default:
          return null;
      }
    }
    return null;
  }, [notification.type]);

  return (
    <div ref={notificationEl} className={classes.notification}>
      {notificationElement}
    </div>
  );
};

export default Notification;
