import React, { createContext, useContext } from "react";

import { AuthActions, AuthState } from "../@types/auth";

const localStorageUser = localStorage.getItem("occ-user");

export const blankUserCustomData = {
  authId: "",
  email: "",
  __typename: "User" as const,
  _id: "",
  lastName: "",
  name: "",
  phone: "",
  picture: "",
  address: {
    street1: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
  },
  birthday: "",
  finishedOnboarding: false,
};

export const initialState: AuthState = {
  user: {
    email: "",
    auth: {
      type: "",
      id: "",
    },
    customData: blankUserCustomData,
    profile: {
      data: {},
    },
  },
  accessToken: "",
  isAuthenticated: false,
};

if (localStorageUser) {
  initialState.user = JSON.parse(localStorageUser as string).user;
  initialState.isAuthenticated = JSON.parse(localStorageUser as string).isAuthenticated;
  initialState.accessToken = JSON.parse(localStorageUser as string).accessToken;
}

const initialContext: { authState: AuthState; authDispatch: React.Dispatch<AuthActions> } = {
  authState: initialState,
  authDispatch: () => {},
};

export const AuthContext = createContext(initialContext);

export const useAuthContext = () => useContext(AuthContext);
