import classnames from 'classnames';
import React, { FC } from 'react';

import Button from '@material-ui/core/Button';
import { Theme, withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => ({
  primaryButton: {
    padding: "8px 12px",
    color: "white",
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.primary.main,
    opacity: 1,
    transition: theme.transitions.create("width"),
    "&:hover": {
      opacity: 0.8,
    },
    "&.Mui-disabled": {
      background: theme.palette.primary.main,
      opacity: 0.6,
    },
  },
  primaryButtonOutlined: {
    fontSize: "1.5rem",
    padding: "8px 12px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    border: "2px solid " + theme.palette.primary.main,
    background: "none",
    color: "white",
    opacity: 1,
    "&:hover": {
      opacity: 0.8,
    },
  },
});

interface Props {
  classes: {
    primaryButton: string;
    primaryButtonOutlined: string;
  };
  onClick: () => void;
  href?: string;
  variant?: string;
  disabled?: boolean;
  dataTestid?: string;
  className?: string;
}

const PrimaryButton: FC<Props> = ({ classes, onClick, href, variant, disabled, children, dataTestid, className }) => {
  const classesObject = {
    [classes.primaryButtonOutlined]: variant === "outlined",
    [classes.primaryButton]: variant !== "outlined",
  };
  if (className) {
    classesObject[className] = true;
  }
  const buttonClasses = classnames(classesObject);

  return (
    <Button
      onClick={onClick}
      href={href}
      variant="contained"
      disabled={disabled}
      data-testid={dataTestid}
      className={buttonClasses}
    >
      {children}
    </Button>
  );
};

export default withStyles(styles)(PrimaryButton);
