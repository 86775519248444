import React, { FC } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';

import { useAuthContext } from '../../../auth/authContext';
import ModalPage from '../../general-ui/modals/ModalPage';
import InviteFriendEmail from '../../user/InviteFriendEmail';
import InviteFriendLinkShare from '../../user/InviteFriendLinkShare';
import WizardStep from '../general-wizard-ui/WizardStep';

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
  },
  closeButton: {
    color: theme.palette.text.primary,
    position: "absolute",
    top: 5,
    right: 5,
  },
  formHeader: {
    color: theme.palette.text.primary,
  },
}));

type Props = { hideModal: () => void } & Partial<StepWizardChildProps>;

const AddGifteePageOne: FC<Props> = ({ goToStep, hideModal }) => {
  const classes = useStyles();
  const { authState } = useAuthContext();

  return (
    <WizardStep>
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        className={classes.container}
      >
        <Typography variant="h4" align="center">
          Invite Friends & Family
        </Typography>
        <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.formHeader}>
          <InviteFriendEmail user={authState.user} onComplete={hideModal} />
          <InviteFriendLinkShare user={authState.user} onComplete={hideModal} />
        </Grid>
      </Grid>
    </WizardStep>
  );
};

export default AddGifteePageOne;
