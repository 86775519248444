import React, { Component, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { User } from '../../../@types/user';
import { GifteeRelationship } from '../../../__generated__/globalTypes';
import { setRelationship } from '../../../redux/add-giftee/actions';
import { AddGifteeRootState } from '../../../redux/add-giftee/reducers';
import { RootState } from '../../../redux/store';
import SecondaryButton from '../../general-ui/buttons/SecondaryButton';
import TertiaryButton from '../../general-ui/buttons/TertiaryButton';
import GeneralAvatar from '../../general-ui/GeneralAvatar';
import GifteeRelationshipSelect from '../../giftees/GifteeRelationshipSelect';
import Occasion from '../../occasions/Occasion';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    height: "calc(900px - 50px)",
    padding: 0,
    overflow: "hidden",
    overflowY: "scroll",
    "@media(max-width: 600px)": {
      height: "100vh",
    },
  },
  gifteeInfo: {
    color: theme.palette.text.primary,
    zIndex: 100,
  },
  formHeader: {
    color: theme.palette.text.primary,
    position: "relative",
    zIndex: 10,
    padding: 16,
    "& h5": {
      color: theme.palette.text.primary,
      margin: "0px",
      fontSize: 18,
    },
  },
  formControl: {
    width: "100%",
    padding: theme.spacing(2),
  },
  inputItem: {
    width: "100%",
    "& label": {
      color: theme.palette.text.primary,
    },
  },
  innerInput: {
    color: theme.palette.text.primary,
    "&:after": {
      borderBottom: "2px solid " + theme.palette.primary.main,
    },
    "&:before": {
      borderBottom: "2px solid rgba(255, 255, 255, 0.54)",
    },
  },
  list: {
    color: theme.palette.text.primary,
    position: "relative",
    paddingTop: 60,
    width: "100%",
    padding: "0px 16px",
    overflow: "hidden",
  },
  buttons: {
    padding: 16,
  },
}));

type Props = {} & Partial<StepWizardChildProps>;

const AddUserRelationship: FC<Props> = ({ nextStep, previousStep }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const addGifteeState = useSelector<RootState, AddGifteeRootState>((state) => {
    return state.addGiftee;
  });
  const { userSelected, relationship } = addGifteeState;

  const relationshipChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch({ ...setRelationship(e.target.value as GifteeRelationship) });
  };

  const confirmRelationship = () => {
    nextStep?.();
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      wrap="nowrap"
      classes={{ container: "flex-1 " + classes.container }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        wrap="nowrap"
        classes={{ container: classes.gifteeInfo }}
      >
        <div className="cloudinary-photo-upload-container">
          {userSelected ? (
            <GeneralAvatar src={userSelected?.picture ?? ""}>{userSelected?.email?.charAt(0)}</GeneralAvatar>
          ) : (
            <GeneralAvatar>
              <i className="lnr lnr-gift"></i>
            </GeneralAvatar>
          )}
        </div>
      </Grid>
      <Grid container direction="column" justifyContent="flex-start" alignItems="center">
        <h2>{userSelected?.name ? userSelected?.name : userSelected?.email}</h2>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        alignItems="center"
        classes={{ container: classes.formHeader }}
      >
        <h5>What is your relationship to this Giftee? (e.g. Sister, Friend, Father)</h5>
      </Grid>
      <div className={classes.formControl}>
        <GifteeRelationshipSelect value={relationship} onChange={relationshipChange} />
      </div>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        alignItems="center"
        classes={{ container: "flex-1 " + classes.buttons }}
      >
        <SecondaryButton onClick={confirmRelationship}>Confirm Relationship</SecondaryButton>
        <p></p>
        <TertiaryButton onClick={() => previousStep?.()}>
          <span className="lnr lnr-arrow-left"></span>
          &nbsp;Go Back
        </TertiaryButton>
      </Grid>
    </Grid>
  );
};

export default AddUserRelationship;
