import Grid from "@material-ui/core/Grid/Grid";
import { Theme } from "@material-ui/core/styles/createTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography/Typography";
import React, { FC, useCallback, useState } from "react";
import { User, UserCustomData } from "../../@types/user";
import { debounceString } from "../../utils/helpers/functionHelpers";
import AnimatedCheckmark from "../general-ui/AnimatedCheckmark";
import GlobalMessageDispatch from "../general-ui/global-messages/GlobalMessageDispatch";
import IconInput from "../general-ui/inputs/IconInput";

const useStyles = makeStyles((theme: Theme) => ({
  formInputWrapper: {
    marginBottom: theme.spacing(1),
  },
  inputClass: {
    "& label": {
      color: theme.palette.text.primary,
    },
  },
}));

type Props = {
  user: User;
  onSubmit: (address: User["customData"]["address"]) => Promise<void>;
};

const UserAddressSettings: FC<Props> = ({ user, onSubmit }) => {
  const classes = useStyles();
  const [street1Confirmation, setStreet1Confirmation] = useState(false);
  const [street2Confirmation, setStreet2Confirmation] = useState(false);
  const [cityConfirmation, setCityConfirmation] = useState(false);
  const [stateConfirmation, setStateConfirmation] = useState(false);
  const [zipConfirmation, setZipConfirmation] = useState(false);
  const [newStreet1, setNewStreet1] = useState(user.customData.address?.street1 ?? "");
  const handleStreet1Change = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewStreet1(event.target.value);
  };
  const [newStreet2, setNewStreet2] = useState(user.customData.address?.street2 ?? "");
  const handleStreet2Change = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewStreet2(event.target.value);
  };
  const [newCity, setNewCity] = useState(user.customData.address?.city ?? "");
  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewCity(event.target.value);
  };
  const [newState, setNewState] = useState(user.customData.address?.state ?? "");
  const handleStateChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewState(event.target.value);
  };
  const [newZip, setNewZip] = useState(user.customData.address?.zip ?? 0);
  const handleZipChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.value.length > 5) {
      //invalid zip code length, do nothing
      return;
    }
    setNewZip(Number(event.target.value));
  };

  const submitAddress = useCallback(
    debounceString(async (address: UserCustomData["address"]) => {
      await onSubmit(address);
    }, 1000),
    []
  );

  const address = {
    street1: newStreet1,
    street2: newStreet2,
    city: newCity,
    state: newState,
    zip: newZip,
  };

  const handleBlur = async (e?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (JSON.stringify(address) !== JSON.stringify(user.customData.address)) {
      submitAddress(address);
      switch (e?.target.name) {
        case "street1":
          setStreet1Confirmation(true);
          break;
        case "street2":
          setStreet2Confirmation(true);
          break;
        case "city":
          setCityConfirmation(true);
          break;
        case "state":
          setStateConfirmation(true);
          break;
        case "zip":
          setZipConfirmation(true);
          break;
        default:
          break;
        //do nothing
      }
      setTimeout(() => {
        setStreet1Confirmation(false);
        setStreet2Confirmation(false);
        setCityConfirmation(false);
        setStateConfirmation(false);
        setZipConfirmation(false);
      }, 500);
    }
  };

  return (
    <>
      <div className={classes.formInputWrapper}>
        <label>Street 1</label>
        <IconInput
          confirmation={street1Confirmation}
          name="street1"
          value={newStreet1}
          missingInfo={newStreet1.trim() === ""}
          icon="lnr-home2"
          onBlur={handleBlur}
          changeCallback={handleStreet1Change}
          className={classes.inputClass}
        />
      </div>
      <div className={classes.formInputWrapper}>
        <label>Street 2</label>
        <IconInput
          confirmation={street2Confirmation}
          name="street2"
          value={newStreet2}
          icon="lnr-apartment"
          onBlur={handleBlur}
          changeCallback={handleStreet2Change}
          className={classes.inputClass}
        />
      </div>
      <div className={classes.formInputWrapper}>
        <label>City</label>
        <IconInput
          confirmation={cityConfirmation}
          name="city"
          value={newCity}
          missingInfo={newCity.trim() === ""}
          icon="lnr-city"
          onBlur={handleBlur}
          changeCallback={handleCityChange}
          className={classes.inputClass}
        />
      </div>
      <div className={classes.formInputWrapper}>
        <label>State</label>
        <IconInput
          confirmation={stateConfirmation}
          name="state"
          value={newState}
          missingInfo={newState.trim() === ""}
          icon="lnr-map2"
          onBlur={handleBlur}
          changeCallback={handleStateChange}
          className={classes.inputClass}
        />
      </div>
      <div className={classes.formInputWrapper}>
        <label>Zip</label>
        <IconInput
          confirmation={zipConfirmation}
          name="zip"
          type="tel"
          value={newZip === 0 ? "" : newZip.toString()}
          missingInfo={newZip === 0}
          icon="lnr-mailbox-full"
          onBlur={handleBlur}
          changeCallback={handleZipChange}
          className={classes.inputClass}
        />
      </div>
    </>
  );
};

export default UserAddressSettings;
