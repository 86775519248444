import Chip from "@material-ui/core/Chip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, { FC, useRef, useState } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
  },
  input: {
    background: "none",
    border: "none",
    color: theme.palette.text.primary,
    "&:focus-visible": {
      outline: "none",
    },
  },
  chip: {
    "& .MuiChip-icon": {
      fontSize: 18,
    },
  },
}));

type chip = {
  value: string;
  icon: string;
};

type Props = {
  chips: chip[];
  onAdd: (added: string) => void;
  onDelete: (deleted: string) => void;
};

const MultiChipTextField: FC<Props> = ({ chips, onAdd, onDelete }) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      onAdd(inputValue);
      setInputValue("");
    } else {
      setInputValue(e.currentTarget.value);
    }
  };

  const handleDelete = (e: any) => {
    onDelete(e.target.value as string);
  };

  return (
    <div className={classes.container} onClick={() => inputRef.current?.focus()}>
      {chips.map((chip) => (
        <Chip
          className={classes.chip}
          icon={<span className={chip.icon}></span>}
          label={chip.value}
          onDelete={handleDelete}
        />
      ))}
      <input
        className={classes.input}
        ref={(ref) => (inputRef.current = ref)}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value ?? "")}
        onKeyPress={handleKeyPress}
      />
    </div>
  );
};

export default MultiChipTextField;
