import 'date-fns';

import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { useAuthContext } from '../../../auth/authContext';
import { setGifteeInterests } from '../../../redux/add-giftee/actions';
import { AddGifteeRootState } from '../../../redux/add-giftee/reducers';
import { RootState } from '../../../redux/store';
import { wizardModalStyles } from '../../../utils/styleHelpers';
import Interest from '../../giftees/Interest';
import InterestSelect from '../../gifts/InterestSelect';
import WizardStep from '../general-wizard-ui/WizardStep';

const useStyles = makeStyles((theme: Theme) => ({
  modalContentNoMargin: wizardModalStyles(theme),
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    background: theme.palette.background.background,
    overflowY: "scroll",
  },
  formHeaderText: {
    color: theme.palette.text.primary,
    marginBottom: 10,
  },
  label: {
    color: theme.palette.text.primary,
    marginBottom: "5px",
  },
  submitButton: {
    width: "100%",
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
    flex: 1,
  },
  formControl: {
    width: "100%",
    margin: "4px 0px",
    "& label": {
      color: theme.palette.common.white,
    },
    "& .Mui-focused select option": {
      background: theme.palette.background.background,
    },
  },
  input: {
    color: theme.palette.common.white,
    "& fieldset": {
      borderColor: theme.palette.common.white,
    },
    "& label": {
      color: theme.palette.common.white,
    },
  },
  interestContainer: {
    width: "100%",
  },
}));

const AddCustomGifteeInterests: FC = () => {
  const dispatch = useDispatch();
  const addGifteeState = useSelector<RootState, AddGifteeRootState>((state) => {
    return state.addGiftee;
  });
  const { gifteeInterests } = addGifteeState;
  const classes = useStyles();
  const addInterestHandler = (interest: string) => {
    if (interest !== "") {
      dispatch({ ...setGifteeInterests([interest, ...addGifteeState.gifteeInterests]) });
    }
    if (addGifteeState.gifteeInterests.includes(interest)) {
      deleteInterestHandler(interest);
    }
  };

  const deleteInterestHandler = (interest: string) => {
    const interestIndex = gifteeInterests.indexOf(interest);
    const newInterests = gifteeInterests.slice(0, interestIndex).concat(gifteeInterests.slice(interestIndex + 1));
    dispatch({ ...setGifteeInterests(newInterests) });
  };

  return (
    <WizardStep>
      <>
        <Grid
          container
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          classes={{ container: "flex-2" }}
        >
          <Grid
            container
            className={classes.formHeaderText}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h4" align="center" gutterBottom>
              What's your Giftee into?
            </Typography>
            <Typography variant="body2" align="center" gutterBottom>
              Select 3 to 5 gft categories to tell us what your giftee likes
            </Typography>
          </Grid>
        </Grid>
        <InterestSelect value={gifteeInterests} onChange={(i: string) => addInterestHandler(i)} />
      </>
    </WizardStep>
  );
};

export default AddCustomGifteeInterests;
