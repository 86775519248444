import { Credentials, handleAuthRedirect } from "realm-web";

import app from "../";

export const openGoogleLogin = async () => {
  const redirectUrl = `${window.location.origin}/handle-redirect`;
  localStorage.setItem("redirectUrl", redirectUrl);
  const credential = Credentials.google(redirectUrl);
  return await app.logIn(credential);
};

export const loginWithEmailAndPass = async (email: string, pass: string) => {
  try {
    const credential = Credentials.emailPassword(email, pass);
    const user = await app.logIn(credential);
    return { user, accessToken: user.accessToken };
  } catch (e) {
    throw e;
  }
};

export const signupWithEmailAndPass = async (email: string, pass: string) => {
  try {
    await app.emailPasswordAuth.registerUser(email, pass);
  } catch (e) {
    throw e;
  }
};

export const logout = async () => {
  try {
    const user = app.currentUser;
    await user?.logOut();
  } catch (e) {
    throw e;
  }
};

export const handleRedirect = async () => {
  try {
    handleAuthRedirect();
  } catch (e) {
    throw e;
  }
};

// Get the logged in user's access token from the StitchAuth interface
export const getRefreshRoken = (): string | undefined | null => {
  try {
    const user = app.currentUser;
    return user?.refreshToken;
  } catch (e) {
    console.error(e);
    window.location.replace("/login");
    throw e;
  }
};

export const getAccessToken = async (): Promise<string | undefined | null> => {
  try {
    const refreshToken = getRefreshRoken();
    const refreshRequest = await fetch("https://realm.mongodb.com/api/client/v2.0/auth/session", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + refreshToken,
        "Content-Type": "application/json",
      },
    });
    const response = await refreshRequest.json();
    return response["access_token"];
  } catch (e) {
    console.error(e);
    window.location.replace("/login");
    throw e;
  }
};
