import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import { useApolloClient } from '@apollo/client/react/hooks/useApolloClient';
import { makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Typography from '@material-ui/core/Typography';

import {
  GiftIdeaSearch_GiftIdeaSearch
} from '../../../graphql/queries/gift-ideas/__generated__/GiftIdeaSearch';
import useGiftIdeaSearch from '../../../hooks/useGiftIdeaSearch';
import { GiftIdeaRootState } from '../../../redux/giftideas/reducers';
import { RootState } from '../../../redux/store';
import GiftIdea from '../../gifts/GiftIdea';
import { GiftIdea as GiftIdeaType } from '../../gifts/types';

const useStyles = makeStyles((theme) => ({
  header: {
    position: "relative",
    padding: "10px 16px 40px 16px",
    color: theme.palette.text.primary,
    "& h5, h6": {
      zIndex: 10,
      maxHeight: 60,
      whiteSpace: "initial",
    },
  },
  giftImage: {
    width: 150,
    height: 150,
    borderRadius: 30,
    zIndex: 10,
  },
  productActions: {
    marginTop: theme.spacing(6),
    zIndex: 10,
  },
  form: {},
  formControl: {
    width: "100%",
    zIndex: 10,
    marginBottom: theme.spacing(1),
    "& label": {
      color: theme.palette.text.primary,
    },
  },
  input: {
    color: theme.palette.text.primary,
    "& fieldset": {
      borderColor: theme.palette.text.primary,
    },
    "& .MuiSelect-select option": {
      background: theme.palette.background.background,
    },
  },
  giftIdeaList: {
    overflowY: "scroll",
    height: "calc(100vh - 250px)",
  },
}));

type Props = Partial<StepWizardChildProps>;

const SearchGiftIdea: FC<Props> = ({ goToStep, isActive }) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  const { activeGiftIdea } = useSelector<RootState, GiftIdeaRootState>((state) => {
    return state.giftIdeas;
  });
  const client = useApolloClient();
  const [callGiftSearch, { data }] = useGiftIdeaSearch(client);
  const activeGiftIdeaTitle = activeGiftIdea?.title;
  useEffect(() => {
    if (isActive && activeGiftIdeaTitle != null) {
      goToStep?.(2);
    }
  }, [isActive, activeGiftIdeaTitle]);
  useEffect(() => {
    if (searchText.trim() !== "") {
      callGiftSearch(searchText);
    }
  }, [searchText]);

  const giftIdeas = useMemo(
    () =>
      (data?.GiftIdeaSearch ?? []).reduce<GiftIdeaSearch_GiftIdeaSearch[]>((giftIdeas, currentGift) => {
        if (currentGift) {
          return [
            ...giftIdeas,
            {
              ...currentGift,
              similarity: null,
            },
          ];
        }
        return giftIdeas;
      }, []),
    [data?.GiftIdeaSearch]
  ) as unknown as GiftIdeaType[];

  return (
    <div>
      <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.header}>
        <Typography variant="h5" noWrap>
          Record Gift Purchase
        </Typography>
        <Typography variant="subtitle1" noWrap>
          Search for the gift you purchased by name:
        </Typography>
      </Grid>
      <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.form}>
        <FormControl variant="outlined" className={classes.formControl}>
          <OutlinedInput
            value={searchText}
            name="giftIdeaSearch"
            id="giftIdeaSearch"
            inputProps={{ "data-testid": "giftIdeaSearch" }}
            onChange={(e) => {
              if (e.target.value != null) {
                setSearchText(e.target.value ?? "");
              }
            }}
          />
        </FormControl>
      </Grid>
      <div className={classes.giftIdeaList}>
        {giftIdeas.map((g, i) => (
          <GiftIdea product={g} index={i} mode="list" />
        ))}
      </div>
    </div>
  );
};
export default SearchGiftIdea;
