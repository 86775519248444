import React, { FC, useState } from 'react';

import { useMutation } from '@apollo/client';
import { makeStyles, Theme } from '@material-ui/core';

import { GifteeRelationship } from '../../../__generated__/globalTypes';
import { updateOneGiftee } from '../../../graphql/mutations/giftees';
import {
  UpdateOneGifteeMutation, UpdateOneGifteeMutationVariables
} from '../../../graphql/mutations/giftees/__generated__/UpdateOneGifteeMutation';
import { getGifteeQuery, getGifteesQuery } from '../../../graphql/queries/giftees';
import { getGiftee_giftee } from '../../../graphql/queries/giftees/__generated__/getGiftee';
import IconFormFieldWrapper from '../../general-ui/inputs/IconFormFieldWrapper';
import IconInput from '../../general-ui/inputs/IconInput';
import GifteeRelationshipSelect, {
  gifteeRelationshipStartPhrase, GifteeRelationshipStartPhraseType
} from '../GifteeRelationshipSelect';

const useStyles = makeStyles((theme: Theme) => ({
  gifteeSettingWrapper: {
    marginTop: 30,
  },
}));

type Props = {
  giftee: getGiftee_giftee;
};

const GifteeDetailInfo: FC<Props> = ({ giftee }) => {
  const classes = useStyles();

  const [newName, setNewName] = useState(giftee.name ?? "");
  const [newRelationship, setNewRelationship] = useState<GifteeRelationship | GifteeRelationshipStartPhraseType>(
    giftee.relationship ?? gifteeRelationshipStartPhrase
  );
  const [newNameConfirmation, setNewNameConfirmation] = useState(false);
  const [newRelationshipConfirmation, setNewRelationshipConfirmation] = useState(false);
  const [updateGiftee, { loading, error, data }] = useMutation<
    UpdateOneGifteeMutation,
    UpdateOneGifteeMutationVariables
  >(updateOneGiftee);

  const newNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewName(event.target.value);
  };

  const newNameSubmit = async () => {
    if (newName.trim() === "") return;
    await updateGiftee({
      variables: {
        query: {
          _id: giftee._id,
        },
        set: {
          name: newName,
        },
      },
    });
    // TODO: Update local state of giftee
    if (!error) {
      setNewNameConfirmation(true);
      setTimeout(() => {
        setNewNameConfirmation(false);
      }, 500);
    }
  };

  const newRelationshipSubmit = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const relationshipSubmitted = event.target.value as GifteeRelationship | GifteeRelationshipStartPhraseType;
    setNewRelationship(relationshipSubmitted);
    if (relationshipSubmitted == null || relationshipSubmitted === gifteeRelationshipStartPhrase) return;
    await updateGiftee({
      variables: {
        query: {
          _id: giftee._id,
        },
        set: {
          relationship: relationshipSubmitted,
        },
      },
      refetchQueries: [
        {
          query: getGifteeQuery,
          variables: {
            _id: giftee._id,
          },
        },
      ],
    });
    if (!error) {
      setNewRelationshipConfirmation(true);
      setTimeout(() => {
        setNewRelationshipConfirmation(false);
      }, 500);
    }
  };

  const potentialNewNameSubmit = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      newNameSubmit();
    }
  };

  return (
    <div>
      <div className={classes.gifteeSettingWrapper}>
        <IconInput
          value={newName ?? ""}
          confirmation={newNameConfirmation}
          icon="lnr-user"
          label="Name"
          changeCallback={newNameChange}
          keyPressCallback={potentialNewNameSubmit}
          onBlur={newNameSubmit}
          disabled={!!giftee.sourceUser}
        />
      </div>
      <div className={classes.gifteeSettingWrapper}>
        <IconFormFieldWrapper confirmation={newRelationshipConfirmation} icon="lnr-tree">
          <GifteeRelationshipSelect noBorder={true} value={newRelationship} onChange={newRelationshipSubmit} />
        </IconFormFieldWrapper>
      </div>
      <br></br>
    </div>
  );
};

export default GifteeDetailInfo;
