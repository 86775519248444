import { combineReducers } from "redux";

import { configureStore } from "@reduxjs/toolkit";

import activeGifteeReducer from "./active-giftee/reducers";
import generalReducer from "./general/reducers";
import giftIdeasReducer from "./giftideas/reducers";
import occasionsReducer from "./occasions/reducers";
import newOccasionReducer from "./new-occasion/reducers";
import onboardingReducer from "./onboarding/reducers";
import addGifteeReducer from "./add-giftee/reducers";

const rootReducer = combineReducers({
  general: generalReducer,
  activeGiftee: activeGifteeReducer,
  giftIdeas: giftIdeasReducer,
  occasions: occasionsReducer,
  newOccasion: newOccasionReducer,
  onboarding: onboardingReducer,
  addGiftee: addGifteeReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
