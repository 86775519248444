import React, { FC } from 'react';

import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

import { Giftee } from '../../@types/giftee';
import {
  getGiftProfile_giftProfile
} from '../../graphql/queries/gift-profiles/__generated__/getGiftProfile';
import GiftBoxMessage from '../general-ui/GiftBoxMessage';
import Loader from '../general-ui/Loader';
import Interest from './Interest';

const useStyles = makeStyles(() => ({
  userProfileLink: {
    width: "100%",
  },
  userProfileImage: {
    width: "100%",
  },
}));

type Props = {
  giftProfile: getGiftProfile_giftProfile | undefined;
};

const InterestsList: FC<Props> = ({ giftProfile }) => {
  const classes = useStyles();

  if (!giftProfile?.interests) {
    return <Loader />;
  } else if (giftProfile?.interests.length == 0) {
    return <GiftBoxMessage primary="No interests added for this giftee..." />;
  } else {
    return <>{giftProfile?.interests.map((interest) => interest && <Interest key={interest} interest={interest} />)}</>;
  }
};

export default InterestsList;
