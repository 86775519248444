import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";

const useStyles = makeStyles((theme) => ({
  policyContainer: {
    width: "100%",
    height: "100%",
    background: theme.palette.common.white,
  },
  policyFrame: {
    width: "100%",
    height: "100%",
  },
}));

type Props = {
  policyUrl: string;
};

const PolicyContainer: FC<Props> = ({ policyUrl }) => {
  const classes = useStyles();
  return (
    <div className={classes.policyContainer}>
      <iframe className={classes.policyFrame} src={policyUrl}></iframe>
    </div>
  );
};

export default PolicyContainer;
