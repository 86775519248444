import makeStyles from "@material-ui/core/styles/makeStyles";
import React, { FC, useMemo } from "react";
import GifteeMatchChip from "./GifteeMatchChip";
import GifteeMatchMulti from "./GifteeMatchChipMulti";
import { GifteeMatch } from "./types";

type Props = {
  giftees: GifteeMatch[];
  similarity: number;
  match: string;
  similarityFormatted: string;
};

const useStyles = makeStyles(() => ({
  gifteeChipContainer: {
    position: "absolute",
    right: 0,
    bottom: -5,
  },
}));

const GifteeMatchChipContainer: FC<Props> = ({ giftees, similarity, match, similarityFormatted }) => {
  const classes = useStyles();
  const gifteesSorted = useMemo(
    () =>
      giftees.sort((g1, g2) => {
        if (g1.similarity > g2.similarity) {
          return -1;
        }
        return 1;
      }),
    [giftees.length]
  );
  return (
    <div className={classes.gifteeChipContainer}>
      {giftees.length < 2 ? (
        <>
          {giftees.map((giftee) => (
            <GifteeMatchChip
              gifteeName={giftee.name ?? ""}
              gifteePicture={giftee.picture ?? undefined}
              similarity={similarity}
              matchGradient={match}
              similarityFormatted={similarityFormatted}
            />
          ))}{" "}
        </>
      ) : (
        <GifteeMatchMulti giftees={gifteesSorted} />
      )}
    </div>
  );
};

export default GifteeMatchChipContainer;
