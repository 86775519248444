import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StepWizard from 'react-step-wizard';

import { useMutation } from '@apollo/client/react/hooks';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

import { User, UserCustomData } from '../../@types/user';
import { GifteeStatus, NotificationType } from '../../__generated__/globalTypes';
import { useAuthContext } from '../../auth/authContext';
import { insertOneGiftee } from '../../graphql/mutations/giftees';
import {
  InsertOneGifteeMutation, InsertOneGifteeMutationVariables
} from '../../graphql/mutations/giftees/__generated__/InsertOneGifteeMutation';
import { insertOneNotification } from '../../graphql/mutations/notifications';
import {
  InsertOneNotificationMutation, InsertOneNotificationMutationVariables
} from '../../graphql/mutations/notifications/__generated__/InsertOneNotificationMutation';
import { getGifteesQuery } from '../../graphql/queries/giftees';
import { UserSearch_UserSearch } from '../../graphql/queries/users/__generated__/UserSearch';
import {
  resetAddGifteeState, selectUser, setInviteSent, setShowAddGifteeWizard
} from '../../redux/add-giftee/actions';
import { RESET_ADD_GIFTEE } from '../../redux/add-giftee/constants/action-types';
import { AddGifteeRootState } from '../../redux/add-giftee/reducers';
import { RootState } from '../../redux/store';
import Modal from '../general-ui/modals/Modal';
import { gifteeRelationshipStartPhrase } from '../giftees/GifteeRelationshipSelect';
import AddCustomGiftee from './add-giftee/AddCustomGiftee';
import AddCustomGifteeInterests from './add-giftee/AddCustomGifteeInterests';
import AddCustomGifteeOccasions from './add-giftee/AddCustomGifteeOccasions';
import AddGifteePageOne from './add-giftee/AddGifteePageOne';
import AddUserPageOne from './add-giftee/AddUserPageOne';
import CreatingCustomGiftee from './add-giftee/CreatingCustomGiftee';
import GifteeInvitationSent from './add-giftee/GifteeInvitationSent';
import InviteFriendGiftee from './add-giftee/InviteFriendGiftee';
import InviteUser from './add-giftee/InviteUser';
import InviteUserOrAddCustomGiftee from './add-giftee/InviteUserOrAddCustomGiftee';
import { WizardNavProps } from './general-wizard-ui/hooks';
import WizardNav from './general-wizard-ui/WizardNav';

type Props = {
  initialStep: number;
};

export enum AddGifteeWizardSteps {
  AddGifteePageOne = 1,
  InviteUserOrAddCustomGiftee,
  InviteFriendGiftee,
  AddCustomGiftee,
  AddCustomGifteeOccasions,
  AddCustomGifteeInterests,
  CreatingCustomGiftee,
  AddUserPageOne,
  InviteUser,
  GifteeInvitationSent,
}

const AddGifteeWizard: FC<Props> = ({ initialStep }) => {
  const dispatch = useDispatch();
  const { authState } = useAuthContext();
  const [addGiftee] = useMutation<InsertOneGifteeMutation, InsertOneGifteeMutationVariables>(insertOneGiftee);
  const [addNotification] = useMutation<InsertOneNotificationMutation, InsertOneNotificationMutationVariables>(
    insertOneNotification
  );

  const showAddGifteeWizard = useSelector<RootState, boolean>((state) => {
    return state.addGiftee.showAddGifteeWizard;
  });
  const user = useSelector<RootState, UserCustomData | undefined>((state) => {
    return state.addGiftee.userSelected;
  });

  const inviteUser = async (callback?: () => void) => {
    const birthdayDate = user?.birthday;
    let age: number | undefined;
    if (birthdayDate) {
      age = new Date().getFullYear() - new Date(birthdayDate).getFullYear() || 0;
    }

    const userId = authState.user.auth.id;
    await addGiftee({
      variables: {
        data: {
          name: user?.name ?? user?.email,
          age,
          userId,
          createdAt: new Date(),
          status: GifteeStatus.PENDING,
          picture: user?.picture,
          sourceUser: {
            link: user?._id,
          },
        },
      },
      refetchQueries: [
        {
          query: getGifteesQuery,
          variables: {
            query: {
              userId,
            },
          },
        },
      ],
    });
    await addNotification({
      variables: {
        data: {
          createdAt: new Date(),
          type: NotificationType.ADDGIFTEEREQUEST,
          userId: user?._id,
          requester: {
            link: userId,
          },
          new: true,
          archived: false,
        },
      },
    });
    callback?.();
    dispatch({ ...setInviteSent(true) });
  };

  const selectUserHandler = (user: UserSearch_UserSearch) => {
    dispatch({ ...selectUser(user) });
  };
  const hideNewGifteeForm = () => {
    dispatch({ type: RESET_ADD_GIFTEE });
    dispatch({ ...setShowAddGifteeWizard(false) });
  };

  const handleNextResolver: (step: AddGifteeWizardSteps, defaultNext: () => void) => (callback?: () => void) => void =
    useCallback(
      (step: AddGifteeWizardSteps, defaultNext: () => void) => {
        switch (step) {
          case AddGifteeWizardSteps.CreatingCustomGiftee:
            return () => {
              hideNewGifteeForm();
            };
          case AddGifteeWizardSteps.InviteUser:
            return (callback?: () => void) => {
              inviteUser(defaultNext);
            };
          case AddGifteeWizardSteps.GifteeInvitationSent:
            return () => {
              hideNewGifteeForm();
              dispatch({ type: RESET_ADD_GIFTEE });
            };
          default:
            return defaultNext;
        }
      },
      [user]
    );

  const navPropsCallback = useCallback(
    (step: AddGifteeWizardSteps, addGifteeState: AddGifteeRootState): WizardNavProps => {
      switch (step) {
        case AddGifteeWizardSteps.AddGifteePageOne:
          return { hideBack: true, hideNav: true, disabled: false, buttonText: "Next" };
        case AddGifteeWizardSteps.AddUserPageOne:
          return { disabled: addGifteeState.userSelected == null, buttonText: "Confirm Selection" };
        case AddGifteeWizardSteps.InviteUser:
          return { buttonText: "Send Invite" };
        case AddGifteeWizardSteps.GifteeInvitationSent:
          return { buttonText: "Finish" };
        case AddGifteeWizardSteps.InviteUserOrAddCustomGiftee:
          return { hideNav: true, disabled: false, buttonText: "Next" };
        case AddGifteeWizardSteps.InviteFriendGiftee:
          return { hideNav: true, disabled: false, buttonText: "Next" };
        case AddGifteeWizardSteps.AddCustomGiftee:
          let buttonDisabled =
            addGifteeState.gifteeName.trim() === "" || addGifteeState.relationship === gifteeRelationshipStartPhrase;
          return { hideNav: false, disabled: buttonDisabled, buttonText: "Confirm Info" };
        case AddGifteeWizardSteps.AddCustomGifteeOccasions:
          return { buttonText: "Confirm Occasions" };
        case AddGifteeWizardSteps.AddCustomGifteeInterests:
          return {
            hideNav: false,
            disabled: addGifteeState.gifteeInterests.length < 2,
            buttonText: "Create Giftee",
          };
        case AddGifteeWizardSteps.CreatingCustomGiftee:
          return {
            disabled: addGifteeState.loading,
            buttonText: "Finish",
          };
        default:
          return { hideNav: false, disabled: false, buttonText: "Next" };
      }
    },
    []
  );

  return (
    <Modal showModal={showAddGifteeWizard} closeModal={hideNewGifteeForm} title="Add a Giftee" variant="dark">
      <StepWizard
        initialStep={initialStep}
        nav={<WizardNav navPropsCallback={navPropsCallback} handleNextResolver={handleNextResolver} />}
      >
        <AddGifteePageOne hideModal={hideNewGifteeForm} />
        <InviteUserOrAddCustomGiftee hideModal={hideNewGifteeForm} />
        <InviteFriendGiftee hideModal={hideNewGifteeForm} />
        <AddCustomGiftee hideModal={hideNewGifteeForm} />
        <AddCustomGifteeOccasions hideModal={hideNewGifteeForm} />
        <AddCustomGifteeInterests />
        <CreatingCustomGiftee />
        <AddUserPageOne userSelectCallback={selectUserHandler} />
        <InviteUser />
        <GifteeInvitationSent hideModal={hideNewGifteeForm} />
      </StepWizard>
    </Modal>
  );
};

export default AddGifteeWizard;
