import React, { FC } from "react";
import Modal from "../general-ui/modals/Modal";
import PolicyContainer from "./PolicyContainer";

type Props = { open: boolean; onClose: () => void };

const TermsAndConditionsDialog: FC<Props> = ({ open, onClose }) => {
  return (
    <Modal header="Terms & Conditions" title="Terms & Conditions" showModal={open} closeModal={onClose}>
      <PolicyContainer policyUrl="https://app.termly.io/document/terms-of-use-for-website/e37c72f2-c9c0-4e0d-bd34-2014be940c77" />
    </Modal>
  );
};

export default TermsAndConditionsDialog;
