import { Product } from '../components/gifts/types';

export const useGiftIdeasGroupedByInterest = (
  giftIdeas: Product[],
  interests: string[],
  length: number
): Record<string, Product[]> => {
  const groupedByInterest: Record<string, Product[]> = {};
  for (let i = 0; i < interests.length; i++) {
    const interest = interests[i];
    const sortedInterestGrouping = giftIdeas.filter((g) => g.interest === interest);
    groupedByInterest[interest] = sortedInterestGrouping.slice(0, length);
  }
  return groupedByInterest;
};

export const getGiftIdeasGroupedByInterest = (
  giftIdeas: Product[],
  interests: string[],
  length: number
): Record<string, Product[]> => {
  const groupedByInterest: Record<string, Product[]> = {};
  for (let i = 0; i < interests.length; i++) {
    const interest = interests[i];
    const sortedInterestGrouping = giftIdeas.filter((g) => g.interest === interest);
    groupedByInterest[interest] = sortedInterestGrouping.slice(0, length);
  }
  return groupedByInterest;
};
