import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLazyQuery, useQuery } from '@apollo/client';
import Fab from '@material-ui/core/Fab/Fab';
import Grid from '@material-ui/core/Grid/Grid';
import Icon from '@material-ui/core/Icon/Icon';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { getGiftIdeasQuery } from '../../graphql/queries/gift-ideas';
import {
  getGiftIdeas as GetGiftIdeasQuery, getGiftIdeas_products, getGiftIdeasVariables
} from '../../graphql/queries/gift-ideas/__generated__/getGiftIdeas';
import { openMarkGiftPurchasedDialog } from '../../redux/giftideas/actions';
import { closeGiftsPickedDialog } from '../../redux/occasions/actions';
import { OccasionRootState } from '../../redux/occasions/reducers';
import { RootState } from '../../redux/store';
import TertiaryButton from '../general-ui/buttons/TertiaryButton';
import SecondaryModal from '../general-ui/modals/SecondaryModal';
import GiftIdea from '../gifts/GiftIdea';
import { GiftIdea as GiftIdeaType } from '../gifts/types';

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 300,
  },
  giftsPickedWrapper: {
    overflowY: "scroll",
    flex: 1,
    marginTop: theme.spacing(1),
  },
  addReminder: {
    alignSelf: "flex-end",
    marginTop: " 10px",
    marginRight: "10px",
  },
}));

const GiftsPickedDialog: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { openGiftsPickedDialog, activeOccasion } = useSelector<RootState, OccasionRootState>((state) => {
    return state.occasions;
  });
  const closeDialog = () => {
    dispatch({ ...closeGiftsPickedDialog() });
  };
  const handleAddGiftPurchased = () => {
    closeDialog();
    dispatch({ ...openMarkGiftPurchasedDialog() });
  };
  const purchaseIds = activeOccasion?.purchases?.map((p) => p?.giftIdeaId) ?? [];
  const [getGiftIdeas, { data }] = useLazyQuery<GetGiftIdeasQuery, getGiftIdeasVariables>(getGiftIdeasQuery);
  useEffect(() => {
    if (openGiftsPickedDialog) {
      getGiftIdeas({
        variables: {
          query: {
            _id_in: purchaseIds,
          },
        },
      });
    }
  }, [openGiftsPickedDialog]);
  const giftIdeas = (data?.products ?? []).reduce<getGiftIdeas_products[]>((giftIdeas, currentGift) => {
    if (currentGift) {
      return [
        ...giftIdeas,
        {
          ...currentGift,
          similarity: null,
        },
      ];
    }
    return giftIdeas;
  }, []) as unknown as GiftIdeaType[];
  return (
    <SecondaryModal
      showModal={openGiftsPickedDialog}
      closeModal={closeDialog}
      title={`Gifts bought for ${activeOccasion?.name}`}
    >
      <Grid container direction="column" wrap="nowrap" className={classes.container}>
        <TertiaryButton variant="outlined" onClick={handleAddGiftPurchased}>
          Add Gift Bought <Icon fontSize="small">add</Icon>
        </TertiaryButton>

        <div className={classes.giftsPickedWrapper}>
          {giftIdeas.map((g, i) => (
            <GiftIdea disabled={true} product={g} mode="list" index={i} />
          ))}
        </div>
      </Grid>
    </SecondaryModal>
  );
};

export default GiftsPickedDialog;
