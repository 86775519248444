import classNames from 'classnames';
import React, { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Grid from '@material-ui/core/Grid/Grid';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { makeStyles, Theme } from '@material-ui/core/styles';

import useIsMobile from '../../../hooks/useIsMobile';
import { OnboardingRootState } from '../../../redux/onboarding/reducers';
import { RootState } from '../../../redux/store';
import PrimaryButton from '../../general-ui/buttons/PrimaryButton';
import TertiaryButton from '../../general-ui/buttons/TertiaryButton';
import { CreateGiftProfileWizardSteps } from '../CreateGiftProfileWizard';
import OnboardingStepIndicator from './OnboardingStepIndicator';

type Props = {
  step: CreateGiftProfileWizardSteps;
  onFinish: () => void;
} & Partial<StepWizardChildProps>;

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    position: "fixed",
    top: 20,
    left: 200,
    zIndex: 100,
    [theme.breakpoints.down("sm")]: {
      top: 2,
      left: 10,
    },
  },
  ctaButton: {
    paddingTop: theme.spacing(2),
    "& .MuiButtonBase-root": {
      height: 56,
      fontSize: 20,
      [theme.breakpoints.up("sm")]: {
        maxWidth: 275,
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingBottom: theme.spacing(4),
      alignSelf: "flex-end",
    },
  },
  onboardingStepIndicatorContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: 25,
    width: 800,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      width: 96,
    },
  },
  crazyButton: {
    background: "linear-gradient(90deg, #E2207B, #C68A77)",
    border: `2px solid ${theme.palette.primary.main}`,
  },
  skipButton: {
    animation: "$fadeIn 2s",
    [theme.breakpoints.down("sm")]: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      width: "45% !important",
    },
  },
  nextButton: {
    [theme.breakpoints.down("sm")]: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      width: "45%",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  buttonContainerTwoButtons: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
}));

enum GiftProfileStepsIndicated {
  Welcome,
  GiftTypes,
  GiftSelection,
  Finish,
}

const getStepIndicatedByOnboardingWizardStep = (step: CreateGiftProfileWizardSteps): GiftProfileStepsIndicated => {
  switch (step) {
    case CreateGiftProfileWizardSteps.CreateProfileIntro:
      return GiftProfileStepsIndicated.Welcome;
    case CreateGiftProfileWizardSteps.GiftTypesInput:
      return GiftProfileStepsIndicated.GiftTypes;
    case CreateGiftProfileWizardSteps.SetGender:
      return GiftProfileStepsIndicated.GiftTypes;
    case CreateGiftProfileWizardSteps.GiftSelection:
      return GiftProfileStepsIndicated.GiftSelection;
    case CreateGiftProfileWizardSteps.Finish:
      return GiftProfileStepsIndicated.Finish;
  }
  return GiftProfileStepsIndicated.Welcome;
};

const getButtonProps = (
  step: CreateGiftProfileWizardSteps,
  onboardingState: OnboardingRootState
): { hideBackButton?: boolean; text: string; testId: string; buttonDisabled?: boolean; skippable?: boolean } => {
  switch (step) {
    case CreateGiftProfileWizardSteps.CreateProfileIntro:
      return { text: "Start", testId: "create-gift-profile-button", hideBackButton: true };
    case CreateGiftProfileWizardSteps.GiftTypesInput:
      return {
        text: "Confirm Selections",
        testId: "testId",
        buttonDisabled: onboardingState.giftTypes.length < 3 || onboardingState.giftTypes.length > 5,
      };
    case CreateGiftProfileWizardSteps.SetGender:
      return { text: "Confirm Gender", testId: "confirm-gender-button" };
    case CreateGiftProfileWizardSteps.GiftSelection:
      return {
        text: "Thank you, Next",
        testId: "testId",
        buttonDisabled: onboardingState.selectedGiftIdeas.length < 3 || onboardingState.selectedGiftIdeas.length > 10,
      };
    default:
      return { text: "Thank you, Next", testId: "testId" };
  }
};

const CreateGiftProfileNav: FC<Props> = ({ step, nextStep, previousStep, onFinish, goToStep }) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const onboardingState = useSelector<RootState, OnboardingRootState>((state) => {
    return state.onboarding;
  });
  const { hideBackButton, text, testId, buttonDisabled, skippable } = getButtonProps(step, onboardingState);
  const stepIndicated = getStepIndicatedByOnboardingWizardStep(step);
  const handleClick = useCallback(() => {
    switch (step) {
      case CreateGiftProfileWizardSteps.Finish:
        onFinish();
        break;
      default:
        nextStep?.();
        break;
    }
  }, [onFinish, nextStep]);

  const handleSkip = useCallback(() => {
    switch (step) {
      default:
        nextStep?.();
        break;
    }
  }, [onFinish, nextStep]);
  const buttonClass = classNames({
    [classes.crazyButton]: step === CreateGiftProfileWizardSteps.CreateProfileIntro,
    [classes.nextButton]: skippable,
  });
  const buttonContainerClass = classNames({
    [classes.buttonContainer]: !skippable,
    [classes.buttonContainerTwoButtons]: skippable,
  });
  const onboardingSteps = useMemo(() => {
    return Object.values(GiftProfileStepsIndicated)
      .filter((val) => isNaN(Number(val)) === false)
      .map((val) => parseInt(val as string));
  }, [Object.values(GiftProfileStepsIndicated).join(",")]);
  return (
    <div>
      <div className={classes.header}>
        {!hideBackButton && (
          <IconButton onClick={() => previousStep?.()}>
            <span className="lnr lnr-arrow-left"></span>
          </IconButton>
        )}
      </div>
      <Grid
        container
        direction={isMobile ? "column" : "column-reverse"}
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        className={classes.ctaButton}
      >
        <div className={classes.onboardingStepIndicatorContainer}>
          {onboardingSteps.map((onboardingStep, i) => (
            <OnboardingStepIndicator
              stepLabels={["Introduction", "Gift Types", "Gift Selection", "Done"]}
              key={`${i}-onboarding-step`}
              isComplete={onboardingStep < stepIndicated}
              isActive={onboardingStep === stepIndicated}
              step={onboardingStep}
            />
          ))}{" "}
        </div>
        <div className={buttonContainerClass}>
          {skippable && (
            <TertiaryButton disabled={buttonDisabled ?? false} className={classes.skipButton} onClick={handleSkip}>
              SKIP
            </TertiaryButton>
          )}
          <PrimaryButton
            disabled={buttonDisabled ?? false}
            className={buttonClass}
            dataTestid={testId}
            onClick={handleClick}
          >
            {onboardingState.loading ? <CircularProgress size={25} /> : <>{skippable ? "NEXT" : text}</>}
          </PrimaryButton>
        </div>
      </Grid>
    </div>
  );
};

export default CreateGiftProfileNav;
