// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Redux from "../redux/Redux.bs.js";
import * as React from "react";
import * as Core$Mui from "rescript-material-ui/src/Core.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactRedux from "react-redux";
import * as Intersection from "../bindings/Intersection.bs.js";
import * as ReactDOMStyle from "@rescript/react/src/ReactDOMStyle.bs.js";
import * as Core from "@material-ui/core";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as Styles from "@material-ui/styles";
import * as Styles$1 from "@material-ui/core/styles";

var useStyles = Styles.makeStyles({
      product: ReactDOMStyle.unsafeAddProp({
            backgroundColor: "white",
            color: "#333",
            display: "flex",
            height: "170px",
            marginRight: "16px",
            minWidth: "140px",
            position: "relative",
            width: "140px",
            borderRadius: "20px",
            alignItems: "center",
            flexWrap: "wrap"
          }, "scroll-snap-align", "start"),
      productDesktop: {
        minWidth: "260px"
      },
      productImage: {
        height: "100%",
        position: "absolute",
        width: "100%",
        objectFit: "cover"
      },
      productInner: {
        backgroundImage: "linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(27, 44, 50, 0.6) 100%)",
        color: "white",
        display: "flex",
        height: "100%",
        padding: "8px",
        paddingBottom: "12px",
        width: "100%",
        alignItems: "flex-start",
        flexDirection: "column",
        justifyContent: "flex-end"
      },
      productTitle: {
        height: "60px",
        overflow: "hidden",
        overflowWrap: "anywhere"
      },
      price: {
        color: "#ffded2"
      }
    });

function Product(Props) {
  var image = Props.image;
  var title = Props.title;
  var price = Props.price;
  var onIntersect = Props.onIntersect;
  var index = Props.index;
  var id = Props.id;
  var gifteeMatches = Props.gifteeMatches;
  var classes = useStyles();
  var dispatch = ReactRedux.useDispatch();
  var theme = Styles$1.useTheme();
  var isDesktop = Core.useMediaQuery(Core$Mui.Breakpoint.get(theme, {
            NAME: "up",
            VAL: "sm"
          }));
  var match = React.useState(function () {
        return false;
      });
  var setIntersectedFeed = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setBroken = match$1[1];
  var handleClick = function (param) {
    dispatch(Redux.Action.setActiveGiftIdeaGifteeMatches(gifteeMatches));
    dispatch(Redux.Action.setActiveGiftIdeaId(id));
    return dispatch(Redux.Action.openGiftIdeaDialog(undefined));
  };
  var productRef = React.useRef(null);
  var intersectionObserverRef = React.useRef(null);
  var productClass = isDesktop ? classes.product + " " + classes.productDesktop : classes.product;
  React.useEffect((function () {
          var match = productRef.current;
          var match$1 = intersectionObserverRef.current;
          if (!(match == null) && (match$1 == null)) {
            var handleEntries = function (entries) {
              Belt_Array.forEach(entries, (function (entry) {
                      var isIntersecting = entry.isIntersecting;
                      if (isIntersecting) {
                        Curry._1(setIntersectedFeed, (function (param) {
                                return true;
                              }));
                        Js_null_undefined.iter(intersectionObserverRef.current, (function (observer) {
                                observer.disconnect();
                                
                              }));
                        return Curry._1(onIntersect, index);
                      }
                      
                    }));
              
            };
            intersectionObserverRef.current = new IntersectionObserver(handleEntries, Intersection.ObserverOptions.make(undefined, undefined, 0.5, undefined));
            Js_null_undefined.iter(intersectionObserverRef.current, (function (observer) {
                    observer.observe(match);
                    
                  }));
          }
          return (function (param) {
                    return Js_null_undefined.iter(intersectionObserverRef.current, (function (observer) {
                                  observer.disconnect();
                                  
                                }));
                  });
        }), [productRef.current]);
  React.useEffect((function () {
          var imageEl = new Image();
          imageEl.setAttribute("src", image);
          imageEl.addEventListener("error", (function (param) {
                  return Curry._1(setBroken, (function (param) {
                                return true;
                              }));
                }));
          
        }), [image]);
  if (match$1[0]) {
    return null;
  } else {
    return React.createElement(Core.Card, {
                children: null,
                className: productClass,
                ref: productRef
              }, React.createElement("img", {
                    className: classes.productImage,
                    src: image
                  }), React.createElement(Core.CardActionArea, {
                    onClick: handleClick,
                    children: null,
                    className: classes.productInner
                  }, React.createElement(Core.Typography, {
                        children: title,
                        className: classes.productTitle,
                        variant: "subtitle1"
                      }), React.createElement(Core.Typography, {
                        children: "$" + String(price),
                        className: classes.price,
                        variant: "subtitle2"
                      })));
  }
}

var make = Product;

export {
  useStyles ,
  make ,
  
}
/* useStyles Not a pure module */
