import { split, HttpLink, ApolloLink } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import React, { FC } from "react";
import { ApolloProvider } from "@apollo/client";

import { getAccessToken } from "../realm/auth";
import { promiseToObservable } from "../utils/apollo-utils";
import { useAuthContext } from "../auth/authContext";
import { AuthActionTypes } from "../@types/auth";

type Props = {
  children: (string | JSX.Element)[] | (string | JSX.Element);
};

const occUser = localStorage.getItem("occ-user");
const occUserParsed = JSON.parse(occUser as string);
let accessToken = "";
if (occUserParsed) {
  accessToken = occUserParsed.accessToken;
}

const GraphQLWrapper: FC<Props> = ({ children }) => {
  const { authState, authDispatch } = useAuthContext();
  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors)
      for (let err of graphQLErrors) {
        // handle errors differently based on its error code
        console.log(err);
      }

    if (networkError) {
      const errorString = JSON.stringify(networkError);
      if (errorString.includes("401")) {
        return promiseToObservable(getAccessToken()).flatMap((token: unknown) => {
          if (token == null || token === "") {
            authDispatch({
              type: AuthActionTypes.LOGOUT_USER,
            });
          }
          accessToken = token as string;
          const latestOccUser = localStorage.getItem("occ-user");
          const latestOccUserParsed = JSON.parse(latestOccUser as string);
          if (latestOccUserParsed) {
            latestOccUserParsed.accessToken = token;
            localStorage.setItem("occ-user", JSON.stringify(latestOccUserParsed));
          }
          const headers = operation.getContext().headers;
          headers.Authorization = `Bearer ${accessToken}`;
          authDispatch({
            type: AuthActionTypes.SET_TOKEN,
            payload: { accessToken },
          });
          operation.setContext({
            headers,
          });
          return forward(operation);
        });
      }
    }
  });

  const authorizationHeaderLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${authState.accessToken}`,
      },
    };
  });

  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_NOTIFICATIONS_WS_URL ?? "",
    options: {
      reconnect: true,
    },
  });

  const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    httpLink
  );

  const link = ApolloLink.from([errorLink, authorizationHeaderLink, splitLink]);

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache({
      typePolicies: {
        GiftProfileRecommendations: {
          keyFields: ["_id", "similarity"],
        },
      },
    }),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphQLWrapper;
