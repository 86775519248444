import React, { FC } from "react";
import clsx from "classnames";
import Button from "@material-ui/core/Button";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "8px 12px",
    opacity: 1,
    boxSizing: "border-box",
    "&:hover": {
      opacity: 0.8,
    },
  },
  secondaryButton: {
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.secondary.main,
    color: "white",
  },
  secondaryButtonOutlined: {
    fontSize: "1.5rem",
    borderRadius: theme.shape.borderRadius,
    border: "2px solid " + theme.palette.secondary.main,
    background: "none",
    color: "white",
  },
}));

interface Props {
  onClick: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  href?: string;
  target?: string;
  rel?: string;
  component?: string;
  variant?: string;
  disabled?: boolean;
  "data-testid"?: string;
}

const SecondaryButton: FC<Props> = (props) => {
  const { onClick, href, variant, disabled } = props;
  const classes = useStyles();

  const buttonClasses = clsx({
    [classes.button]: true,
    [classes.secondaryButton]: variant !== "outlined",
    [classes.secondaryButtonOutlined]: variant === "outlined",
  });

  return (
    <Button
      onClick={onClick}
      href={href}
      variant="contained"
      disabled={disabled}
      className={buttonClasses}
      data-testid={props["data-testid"]}
    >
      {props.children}
    </Button>
  );
};

export default SecondaryButton;
