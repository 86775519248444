import React, { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { useMutation } from '@apollo/client/react/hooks/useMutation';
import { Grid, Icon, makeStyles, Tab, Tabs } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { Giftee } from '../../@types/giftee';
import { useAuthContext } from '../../auth/authContext';
import { deleteOneGiftee, updateOneGiftee } from '../../graphql/mutations/giftees';
import {
  DeleteOneGifteeMutation, DeleteOneGifteeMutationVariables
} from '../../graphql/mutations/giftees/__generated__/DeleteOneGifteeMutation';
import {
  UpdateOneGifteeMutation, UpdateOneGifteeMutationVariables
} from '../../graphql/mutations/giftees/__generated__/UpdateOneGifteeMutation';
import { deleteGifteeFromCache } from '../../graphql/update-cache/giftees';
import { ActiveGifteeRootState } from '../../redux/active-giftee/reducers';
import { RootState } from '../../redux/store';
import { getSideBarNavColor, getSideBarTextColor } from '../../theme';
import AnimatedDelete from '../general-ui/AnimatedDelete';
import DeleteButton from '../general-ui/buttons/DeleteButton';
import SecondaryButton from '../general-ui/buttons/SecondaryButton';
import TertiaryButton from '../general-ui/buttons/TertiaryButton';
import CloudinaryPhotoUpload from '../general-ui/CloudinaryPhotoUpload';
import GlobalMessageDispatch from '../general-ui/global-messages/GlobalMessageDispatch';
import SecondaryModal from '../general-ui/modals/SecondaryModal';
import SidebarTabs from '../general-ui/SidebarTabs';
import { useGifteeRoutes } from './gifteeRoutes';

type Props = {
  giftee: Giftee;
};
const useStyles = makeStyles((theme) => ({
  gifteeNav: {
    background: getSideBarNavColor(theme),
    height: "100vh",
    width: 362,
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    paddingTop: 60,
  },
  gifteeNavTabs: {
    color: theme.palette.common.white,
    width: 320,
    alignSelf: "flex-end",
    overflow: "visible",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    "& .MuiTabs-indicator": {
      background: "none",
      width: "100%",
    },
    "& .MuiTabs-scroller": {
      overflow: "visible",
    },
  },
  gifteeNavTab: {
    width: "100%",
    zIndex: 10,
    "&.Mui-selected": {
      color: theme.palette.primary.dark,
    },
  },
  tabBackground: {
    background: theme.palette.background.paper2DP,
    borderTopLeftRadius: 28,
    borderBottomLeftRadius: 28,
    width: "100%",
    height: "100%",
    position: "relative",
  },
  tabCornersWhite: {
    position: "absolute",
    right: 0,
    width: 30,
    height: 30,
    background: theme.palette.common.white,
    "&:nth-child(1)": {
      top: -30,
    },
    "&:nth-child(2)": {
      bottom: -30,
    },
  },
  tabCornersGreen: {
    position: "absolute",
    borderRadius: "50%",
    right: 0,
    width: 60,
    height: 60,
    background: theme.palette.background.paper2DP,
    "&:nth-child(3)": {
      top: -60,
    },
    "&:nth-child(4)": {
      bottom: -60,
    },
  },
  gifteePhoto: {
    zIndex: 10,
    alignSelf: "center",
  },
  deleteButtonWrapper: {
    position: "absolute",
    bottom: 10,
  },
}));

const GifteeDesktopSidebarNav: FC<Props> = ({ giftee }) => {
  const { authState } = useAuthContext();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [updateGiftee] = useMutation<UpdateOneGifteeMutation, UpdateOneGifteeMutationVariables>(updateOneGiftee);
  const [cloudinaryResult, setCloudinaryResult] = useState<null | string>(null);

  const [deleteGifteeShow, setDeleteGifteeShow] = useState(false);
  const [deleteGifteeSnackbarShow, setDeleteGifteeSnackbarShow] = useState(false);
  const [deleteGiftee] = useMutation<DeleteOneGifteeMutation, DeleteOneGifteeMutationVariables>(deleteOneGiftee);
  const activeGifteeState = useSelector<RootState, ActiveGifteeRootState>((state) => {
    return state.activeGiftee;
  });
  const [routes, routeStringMap] = useGifteeRoutes(giftee._id);
  const desktopRoutes = useMemo(() => routes.filter((r) => r.to !== routeStringMap.INTERESTS), [routes]);
  const cloudinaryResultHandler = useCallback(
    async (file: string) => {
      setCloudinaryResult(file);
      await updateGiftee({
        variables: {
          query: {
            _id: giftee._id,
          },
          set: {
            picture: file,
          },
        },
      });
    },
    [setCloudinaryResult, updateGiftee]
  );

  const deleteGifteeForm = () => {
    setDeleteGifteeShow(true);
  };

  const deleteGifteeFormHide = () => {
    setDeleteGifteeShow(false);
  };

  const deleteGifteeHandler = async () => {
    const gifteeId = giftee._id;
    setDeleteGifteeShow(false);
    setDeleteGifteeSnackbarShow(true);
    const variables = {
      query: {
        _id: gifteeId,
      },
    };
    await deleteGiftee({
      variables,
      update: (cache, result) =>
        deleteGifteeFromCache(
          cache,
          {
            query: {
              userId: authState.user.auth.id,
            },
          },
          result
        ),
    });

    history.push("/home");

    //TODO: Add cancellation timeout
    setTimeout(() => {
      if (!activeGifteeState.cancelDeleteGiftee) {
        //if no cancel, then delete
        // Meteor.call("giftees.remove", gifteeId);
        //TODO ADD API CALL
      } else {
        //otherwise, do not delete and reset everything
        //TODO: Handle not deleting giftee
        // this.setState({
        //   gifteeDeletingSnack: false,
        //   cancelDeleteGiftee: false,
        //   gifteeDeletingSnackMessage: "Deleting Giftee...",
        //   deleted: true,
        // });
      }
    }, 5000);
  };

  const parentData = {
    image: cloudinaryResult ?? giftee?.picture,
    text: giftee?.name,
  };

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="center" className={classes.gifteeNav}>
      <SecondaryModal
        title={"Delete Giftee: " + giftee.name}
        showModal={deleteGifteeShow}
        closeModal={deleteGifteeFormHide}
      >
        <h3>Do you want to delete {giftee.name} as a giftee?</h3>
        <div className="dialog-btn-set">
          <TertiaryButton onClick={deleteGifteeFormHide}>Cancel</TertiaryButton>
          <SecondaryButton onClick={deleteGifteeHandler}>Delete</SecondaryButton>
        </div>
      </SecondaryModal>
      <div className={"cloudinary-photo-upload-container " + classes.gifteePhoto}>
        <CloudinaryPhotoUpload
          key={giftee._id}
          onUpload={cloudinaryResultHandler}
          parentData={parentData}
          allowUpload={!giftee.sourceUser}
        />
      </div>
      <SidebarTabs tabs={desktopRoutes} activeTab={desktopRoutes.findIndex((r) => r.to === location.pathname)} />
      <div className={classes.deleteButtonWrapper}>
        <DeleteButton onClick={deleteGifteeForm} variant="light"></DeleteButton>
      </div>
      {deleteGifteeSnackbarShow && (
        <GlobalMessageDispatch finalText="Occasion Deleted">
          <Grid container direction="row" alignItems="center">
            <AnimatedDelete
              otherStyles={{ width: 40, height: 40 }}
              checked={true}
              icon={
                <Icon>
                  <span className="lnr lnd-calendar-cross"></span>
                </Icon>
              }
            />

            <Typography variant="body1" color="inherit">
              Giftee deleted
            </Typography>
            {/* <SecondaryButton onClick={handleCancelDeleteGiftee}>Cancel</SecondaryButton> */}
          </Grid>
        </GlobalMessageDispatch>
      )}
    </Grid>
  );
};

export default GifteeDesktopSidebarNav;
