import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import Typography from "@material-ui/core/Typography";

import { RootState } from "../../redux/store";
import PrimaryButton from "../general-ui/buttons/PrimaryButton";
import SecondaryButton from "../general-ui/buttons/SecondaryButton";
import SecondaryModal from "../general-ui/modals/SecondaryModal";
import { AddGifteeRootState } from "../../redux/add-giftee/reducers";
import { setShowAddGifteeSuggestionDialog, setShowImportGifteeWizard } from "../../redux/add-giftee/actions";
import { useMutation } from "@apollo/client/react/hooks/useMutation";
import { useAuthContext } from "../../auth/authContext";
import {
  InsertOneGifteeMutation,
  InsertOneGifteeMutationVariables,
} from "../../graphql/mutations/giftees/__generated__/InsertOneGifteeMutation";
import { insertOneGiftee } from "../../graphql/mutations/giftees";
import { updateGifteesInCache } from "../../graphql/update-cache/giftees";
import { closeNotificationsMenu } from "../../redux/general/actions";

const AddGifteeSuggestionDialog: FC = () => {
  const [insertGiftee] = useMutation<InsertOneGifteeMutation, InsertOneGifteeMutationVariables>(insertOneGiftee);
  const dispatch = useDispatch();
  const { authState } = useAuthContext();
  const addGifteeState = useSelector<RootState, AddGifteeRootState>((state) => {
    return state.addGiftee;
  });
  const { userSelected, showAddGifteeSuggestionDialog } = addGifteeState;
  const closeDialog = () => {
    dispatch({ ...setShowAddGifteeSuggestionDialog(false) });
  };
  const addGiftee = async () => {
    //first import giftee and all that),
    const birthdayDate = userSelected?.birthday;
    let age: number | undefined;
    if (birthdayDate) {
      age = new Date().getFullYear() - new Date(birthdayDate).getFullYear() || 0;
    }
    const userId = authState.user.auth.id;
    await insertGiftee({
      variables: {
        data: {
          name: userSelected?.name ?? userSelected?.email,
          age,
          userId,
          createdAt: new Date(),
          isPendingAcceptance: false,
          picture: userSelected?.picture,
          sourceUser: {
            link: userSelected?._id,
          },
        },
      },
      update: (cache, data) => {
        updateGifteesInCache(cache, { query: { userId } }, data);
      },
    });
    dispatch({ ...setShowImportGifteeWizard(true) });
    dispatch({ ...setShowAddGifteeSuggestionDialog(false) });
    dispatch({ ...closeNotificationsMenu() });
  };
  const decline = async () => {
    dispatch({ ...setShowAddGifteeSuggestionDialog(false) });
  };
  const userName = userSelected?.firstName ?? userSelected?.email;

  return (
    <SecondaryModal showModal={showAddGifteeSuggestionDialog} closeModal={closeDialog} secondModal={true}>
      <Typography variant="h6">
        Now that you are {userName}'s giftee, would you also like to add them as a giftee?
      </Typography>
      <PrimaryButton onClick={addGiftee}>Yes</PrimaryButton>
      <SecondaryButton onClick={decline}>Naw</SecondaryButton>
    </SecondaryModal>
  );
};

export default AddGifteeSuggestionDialog;
