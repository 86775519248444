import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useMutation } from '@apollo/client/react/hooks/useMutation';
import { useQuery } from '@apollo/client/react/hooks/useQuery';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Divider from '@material-ui/core/Divider/Divider';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { updateOneOccasion } from '../../graphql/mutations/occasions';
import {
  UpdateOneOccasionMutation, UpdateOneOccasionMutationVariables
} from '../../graphql/mutations/occasions/__generated__/UpdateOneOccasionMutation';
import { getOccasionsQuery } from '../../graphql/queries/occasions';
import { getRemindersQuery } from '../../graphql/queries/reminders';
import {
  getReminders, getReminders_reminders, getRemindersVariables
} from '../../graphql/queries/reminders/__generated__/getReminders';
import { GiftIdeaRootState } from '../../redux/giftideas/reducers';
import { closeViewOccasionDialog } from '../../redux/occasions/actions';
import { OccasionRootState } from '../../redux/occasions/reducers';
import { RootState } from '../../redux/store';
import AddReminderForm from '../forms/AddReminderForm';
import AnimatedDelete from '../general-ui/AnimatedDelete';
import DeleteButton from '../general-ui/buttons/DeleteButton';
import SecondaryButton from '../general-ui/buttons/SecondaryButton';
import TertiaryButton from '../general-ui/buttons/TertiaryButton';
import GlobalMessageDispatch from '../general-ui/global-messages/GlobalMessageDispatch';
import Modal from '../general-ui/modals/Modal';
import SecondaryModal from '../general-ui/modals/SecondaryModal';
import OccasionReminder from '../reminders/OccasionReminder';
import ReminderFeed from '../reminders/ReminderFeed';
import GiftsPickedDialog from './GiftsPickedDialog';
import OccasionGiftPick from './OccasionGiftPick';

const useStyles = makeStyles((theme) => ({
  occasionSummaryDetails: {
    color: theme.palette.primary.light,
    paddingLeft: 10,
  },
  occasionSummaryName: {
    color: theme.palette.text.secondary,
  },
  occasionExpand: {
    color: theme.palette.primary.main,
  },
  occasionExpanded: {
    "&:after": {
      transform: "translate(0%, 0%)",
    },
    boxShadow:
      "0px 1px 3px 0px " +
      theme.palette.primary.transparent +
      ", 0px 1px 1px 0px " +
      theme.palette.primary.transparent +
      ", 0px 2px 1px -1px " +
      theme.palette.primary.transparent,
  },
  summaryHeader: {
    zIndex: 100,
  },
  occasionDetails: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    overflowY: "scroll",
    background: theme.palette.background.activatedPaper,
    position: "relative",
    paddingTop: theme.spacing(8),
    padding: theme.spacing(2),
  },
  occasionDetailHeader: {
    zIndex: 10,
    borderBottom: "1px solid white",
    paddingBottom: 10,
    color: theme.palette.text.primary,
    flexWrap: "nowrap",
  },
  occasionDetailBody: {},
  countDownDisplay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flex: "1",
  },
  countDown: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: "1",
    fontSize: 13,
    "& span": {
      fontSize: 40,
    },
  },
  changeDate: {
    background: theme.palette.text.primary,
    border: "2px solid " + theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    flex: "1",
    "&[disabled]": {
      color: theme.palette.secondary.main,
    },
    "& .material-icons": {
      marginLeft: 4,
    },
  },
  addReminder: {
    alignSelf: "flex-end",
    marginTop: " 10px",
    marginRight: "10px",
  },
  bottomRow: {
    marginTop: 20,
  },
  gifteeAvatar: {
    position: "absolute",
    bottom: -5,
    right: 0,
  },
  header: {
    padding: theme.spacing(2),
    display: "flex",
    position: "absolute",
    top: 0,
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.palette.text.primary,
  },
  headerLeftPad: {
    width: 48,
  },
}));

const ViewOccasionDialog: FC = () => {
  const classes = useStyles();
  const [updateOccasion, updateOccasionContext] = useMutation<
    UpdateOneOccasionMutation,
    UpdateOneOccasionMutationVariables
  >(updateOneOccasion);
  const dispatch = useDispatch();
  const [isBehind, setIsBehind] = useState(false);
  const [deleteOccasionShow, setDeleteOccasionShow] = useState(false);
  const [deleteSnackbarShow, setDeleteSnackbarShow] = useState(false);
  const [showAddReminder, setShowAddReminder] = useState(false);
  const { openViewOccasionDialog, activeOccasion: occasion } = useSelector<RootState, OccasionRootState>((state) => {
    return state.occasions;
  });
  const { markGiftPurchasedDialogOpen } = useSelector<RootState, GiftIdeaRootState>((state) => {
    return state.giftIdeas;
  });

  const { data, loading, error } = useQuery<getReminders, getRemindersVariables>(getRemindersQuery, {
    variables: {
      query: {
        occasion: {
          _id: occasion?._id,
        },
      },
    },
    skip: occasion == null,
  });

  useEffect(() => {
    const isBehind = deleteOccasionShow || markGiftPurchasedDialogOpen;
    if (isBehind) {
      setIsBehind(true);
    }
  }, [markGiftPurchasedDialogOpen, deleteOccasionShow]);

  useEffect(() => {
    setIsBehind(false);
  }, [openViewOccasionDialog]);

  if (occasion == null) {
    return null;
  }
  const today = dayjs();
  const nextOccasionDate = dayjs(occasion.nextOccasionDate ?? "");
  const monthsAway = nextOccasionDate.diff(today, "months");
  const todayPlusMonthsDifference = today.add(monthsAway, "months");
  const weeksAway = nextOccasionDate.diff(todayPlusMonthsDifference, "weeks");
  const todayPlusMonthsAndWeeksDifference = todayPlusMonthsDifference.add(weeksAway, "weeks");
  const daysAway = nextOccasionDate.diff(todayPlusMonthsAndWeeksDifference, "days");

  const reminders = (data?.reminders ?? []).filter((r): r is getReminders_reminders => r != null);

  const handleClose = () => {
    dispatch({ ...closeViewOccasionDialog() });
  };

  const deleteOccasionHandler = async () => {
    setDeleteOccasionShow(false);
    await updateOccasion({
      variables: {
        query: {
          _id: occasion._id,
        },
        set: {
          deleted: true,
        },
      },
      refetchQueries: [
        {
          query: getOccasionsQuery,
          variables: {
            query: {
              giftee: {
                _id: occasion.giftee?._id,
              },
            },
          },
        },
      ],
    });
    handleClose();
    setDeleteSnackbarShow(true);
  };

  const deleteOccasionFormShow = () => {
    setDeleteOccasionShow(true);
  };

  const deleteOccasionFormHide = () => {
    setDeleteOccasionShow(false);
  };

  const toggleAddReminder = () => {
    setShowAddReminder(!showAddReminder);
  };

  return (
    <Modal showModal={openViewOccasionDialog} closeModal={handleClose} isBehindSecondModal={isBehind}>
      <div className={classes.occasionDetails}>
        <div className={classes.header}>
          <div className={classes.headerLeftPad}></div>
          <Typography variant="h6">{occasion.name}</Typography>
          <IconButton className={"lnr lnr-cross "} onClick={handleClose}></IconButton>
        </div>
        <Grid style={{ width: "100%", zIndex: 100 }}>
          <Grid
            container
            direction="column"
            justifyContent="space-evenly"
            classes={{ container: classes.occasionDetailHeader }}
          >
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <div className={classes.countDownDisplay}>
                <div className={classes.countDown}>
                  <span>{monthsAway}</span>
                  months
                </div>
                <div className={classes.countDown}>
                  <span>{weeksAway}</span>
                  weeks
                </div>
                <div className={classes.countDown}>
                  <span>{daysAway}</span>
                  days
                </div>
              </div>
            </Grid>
          </Grid>
          <Divider light={true} />
          <Grid
            container
            direction="column"
            justifyContent="space-evenly"
            classes={{ container: classes.occasionDetailBody }}
          >
            <Grid container direction="column">
              <Typography variant="subtitle1">Reminders</Typography>
              <ReminderFeed loading={loading} error={error} reminders={reminders ?? []} />
              <Fab
                data-testid="add-reminder-button"
                size="small"
                aria-label="Add"
                onClick={toggleAddReminder}
                className={classes.addReminder}
              >
                <Icon fontSize="small">add</Icon>
              </Fab>
            </Grid>
            <div>
              <OccasionReminder occasion={occasion} />
            </div>
            <div>
              <OccasionGiftPick occasion={occasion} />
            </div>
          </Grid>
        </Grid>
        <GiftsPickedDialog />
        <SecondaryModal title="Add Reminder" showModal={showAddReminder} closeModal={toggleAddReminder}>
          <AddReminderForm occasion={occasion} occasions={[occasion]} closeModal={toggleAddReminder} />
        </SecondaryModal>

        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          classes={{ container: classes.bottomRow }}
        >
          <DeleteButton variant="light" onClick={deleteOccasionFormShow}></DeleteButton>
        </Grid>
      </div>
      <SecondaryModal showModal={deleteOccasionShow} closeModal={deleteOccasionFormHide} title="Delete Occasion">
        <>
          {updateOccasionContext.loading && (
            <Grid container justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          )}
          {!updateOccasionContext.loading && (
            <>
              <h3>Do you want to delete {occasion.name}?</h3>
              <div className="dialog-btn-set">
                <TertiaryButton onClick={deleteOccasionFormHide}>Cancel</TertiaryButton>
                <SecondaryButton onClick={deleteOccasionHandler}>Delete</SecondaryButton>
              </div>
            </>
          )}
        </>
      </SecondaryModal>
      {deleteSnackbarShow && (
        <GlobalMessageDispatch finalText="Occasion Deleted">
          <Grid container direction="row" alignItems="center">
            <AnimatedDelete
              otherStyles={{ width: 40, height: 40 }}
              checked={true}
              icon={
                <Icon>
                  <span className="lnr lnd-calendar-cross"></span>
                </Icon>
              }
            />

            <Typography variant="body1" color="inherit">
              Occasion deleted
            </Typography>
          </Grid>
        </GlobalMessageDispatch>
      )}
    </Modal>
  );
};

export default ViewOccasionDialog;
