// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  query GetProductQuery($query: ProductQueryInput)  {
    product(query: $query)  {
      __typename
      _id
      associatedProducts  {
        __typename
        image
        title
        url
      }
      description
      externalId
      gender
      image
      price
      provider  {
        __typename
        logo
        name
      }
      status
      tags
      title
      url
    }
  }
`);

function parse(value) {
  var value$1 = value.product;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1._id;
    var value$3 = value$1.associatedProducts;
    var value$4 = value$1.description;
    var value$5 = value$1.externalId;
    var value$6 = value$1.gender;
    var tmp$1;
    if (value$6 == null) {
      tmp$1 = undefined;
    } else {
      var tmp$2;
      switch (value$6) {
        case "FEMALE" :
            tmp$2 = "FEMALE";
            break;
        case "MALE" :
            tmp$2 = "MALE";
            break;
        case "UNISEX" :
            tmp$2 = "UNISEX";
            break;
        default:
          tmp$2 = {
            NAME: "FutureAddedValue",
            VAL: value$6
          };
      }
      tmp$1 = tmp$2;
    }
    var value$7 = value$1.image;
    var value$8 = value$1.price;
    var value$9 = value$1.provider;
    var tmp$3;
    if (value$9 == null) {
      tmp$3 = undefined;
    } else {
      var value$10 = value$9.logo;
      var value$11 = value$9.name;
      tmp$3 = {
        __typename: value$9.__typename,
        logo: !(value$10 == null) ? value$10 : undefined,
        name: !(value$11 == null) ? value$11 : undefined
      };
    }
    var value$12 = value$1.status;
    var tmp$4;
    if (value$12 == null) {
      tmp$4 = undefined;
    } else {
      var tmp$5;
      switch (value$12) {
        case "BROKENIMAGE" :
            tmp$5 = "BROKENIMAGE";
            break;
        case "BROKENLINK" :
            tmp$5 = "BROKENLINK";
            break;
        case "DUPLICATE" :
            tmp$5 = "DUPLICATE";
            break;
        case "DUPLICATEMERGED" :
            tmp$5 = "DUPLICATEMERGED";
            break;
        case "LIVE" :
            tmp$5 = "LIVE";
            break;
        case "RETIRED" :
            tmp$5 = "RETIRED";
            break;
        default:
          tmp$5 = {
            NAME: "FutureAddedValue",
            VAL: value$12
          };
      }
      tmp$4 = tmp$5;
    }
    var value$13 = value$1.tags;
    var value$14 = value$1.title;
    var value$15 = value$1.url;
    tmp = {
      __typename: value$1.__typename,
      _id: !(value$2 == null) ? Caml_option.some(value$2) : undefined,
      associatedProducts: !(value$3 == null) ? value$3.map(function (value) {
              if (value == null) {
                return ;
              }
              var value$1 = value.image;
              var value$2 = value.title;
              var value$3 = value.url;
              return {
                      __typename: value.__typename,
                      image: !(value$1 == null) ? value$1 : undefined,
                      title: !(value$2 == null) ? value$2 : undefined,
                      url: !(value$3 == null) ? value$3 : undefined
                    };
            }) : undefined,
      description: !(value$4 == null) ? value$4 : undefined,
      externalId: !(value$5 == null) ? value$5 : undefined,
      gender: tmp$1,
      image: !(value$7 == null) ? value$7 : undefined,
      price: !(value$8 == null) ? value$8 : undefined,
      provider: tmp$3,
      status: tmp$4,
      tags: !(value$13 == null) ? value$13.map(function (value) {
              if (!(value == null)) {
                return value;
              }
              
            }) : undefined,
      title: !(value$14 == null) ? value$14 : undefined,
      url: !(value$15 == null) ? value$15 : undefined
    };
  }
  return {
          product: tmp
        };
}

function serialize(value) {
  var value$1 = value.product;
  var product;
  if (value$1 !== undefined) {
    var value$2 = value$1.url;
    var url = value$2 !== undefined ? value$2 : null;
    var value$3 = value$1.title;
    var title = value$3 !== undefined ? value$3 : null;
    var value$4 = value$1.tags;
    var tags = value$4 !== undefined ? value$4.map(function (value) {
            if (value !== undefined) {
              return value;
            } else {
              return null;
            }
          }) : null;
    var value$5 = value$1.status;
    var status = value$5 !== undefined ? (
        typeof value$5 === "object" ? value$5.VAL : (
            value$5 === "DUPLICATEMERGED" ? "DUPLICATEMERGED" : (
                value$5 === "RETIRED" ? "RETIRED" : (
                    value$5 === "DUPLICATE" ? "DUPLICATE" : (
                        value$5 === "BROKENLINK" ? "BROKENLINK" : (
                            value$5 === "LIVE" ? "LIVE" : "BROKENIMAGE"
                          )
                      )
                  )
              )
          )
      ) : null;
    var value$6 = value$1.provider;
    var provider;
    if (value$6 !== undefined) {
      var value$7 = value$6.name;
      var name = value$7 !== undefined ? value$7 : null;
      var value$8 = value$6.logo;
      var logo = value$8 !== undefined ? value$8 : null;
      var value$9 = value$6.__typename;
      provider = {
        __typename: value$9,
        logo: logo,
        name: name
      };
    } else {
      provider = null;
    }
    var value$10 = value$1.price;
    var price = value$10 !== undefined ? value$10 : null;
    var value$11 = value$1.image;
    var image = value$11 !== undefined ? value$11 : null;
    var value$12 = value$1.gender;
    var gender = value$12 !== undefined ? (
        typeof value$12 === "object" ? value$12.VAL : (
            value$12 === "UNISEX" ? "UNISEX" : (
                value$12 === "MALE" ? "MALE" : "FEMALE"
              )
          )
      ) : null;
    var value$13 = value$1.externalId;
    var externalId = value$13 !== undefined ? value$13 : null;
    var value$14 = value$1.description;
    var description = value$14 !== undefined ? value$14 : null;
    var value$15 = value$1.associatedProducts;
    var associatedProducts = value$15 !== undefined ? value$15.map(function (value) {
            if (value === undefined) {
              return null;
            }
            var value$1 = value.url;
            var url = value$1 !== undefined ? value$1 : null;
            var value$2 = value.title;
            var title = value$2 !== undefined ? value$2 : null;
            var value$3 = value.image;
            var image = value$3 !== undefined ? value$3 : null;
            var value$4 = value.__typename;
            return {
                    __typename: value$4,
                    image: image,
                    title: title,
                    url: url
                  };
          }) : null;
    var value$16 = value$1._id;
    var _id = value$16 !== undefined ? Caml_option.valFromOption(value$16) : null;
    var value$17 = value$1.__typename;
    product = {
      __typename: value$17,
      _id: _id,
      associatedProducts: associatedProducts,
      description: description,
      externalId: externalId,
      gender: gender,
      image: image,
      price: price,
      provider: provider,
      status: status,
      tags: tags,
      title: title,
      url: url
    };
  } else {
    product = null;
  }
  return {
          product: product
        };
}

function serializeInputObjectProductQueryInput(inp) {
  var a = inp.title_lt;
  var a$1 = inp.createdAt_ne;
  var a$2 = inp._id_nin;
  var a$3 = inp.giftSource_ne;
  var a$4 = inp.gender_lte;
  var a$5 = inp._id_exists;
  var a$6 = inp.externalId_in;
  var a$7 = inp.status_gte;
  var a$8 = inp._id_ne;
  var a$9 = inp.createdAt_lt;
  var a$10 = inp.price_lt;
  var a$11 = inp.title_gte;
  var a$12 = inp.tagScores;
  var a$13 = inp.giftSource_gte;
  var a$14 = inp.gender;
  var a$15 = inp.price_nin;
  var a$16 = inp.status_lte;
  var a$17 = inp.gender_gt;
  var a$18 = inp.image_nin;
  var a$19 = inp.updatedAt_lte;
  var a$20 = inp.title_nin;
  var a$21 = inp.url_nin;
  var a$22 = inp.status_ne;
  var a$23 = inp.url;
  var a$24 = inp.url_lte;
  var a$25 = inp.price;
  var a$26 = inp.associatedProducts_exists;
  var a$27 = inp.updatedAt;
  var a$28 = inp.externalId_lt;
  var a$29 = inp.externalId;
  var a$30 = inp.giftSource;
  var a$31 = inp.externalId_ne;
  var a$32 = inp.giftSource_in;
  var a$33 = inp.tags;
  var a$34 = inp.associatedProducts;
  var a$35 = inp.url_gt;
  var a$36 = inp._id;
  var a$37 = inp.url_lt;
  var a$38 = inp.giftSource_nin;
  var a$39 = inp.gender_nin;
  var a$40 = inp.gender_lt;
  var a$41 = inp.title_exists;
  var a$42 = inp.externalId_gte;
  var a$43 = inp.description;
  var a$44 = inp.OR;
  var a$45 = inp._id_gt;
  var a$46 = inp.description_ne;
  var a$47 = inp.status_nin;
  var a$48 = inp.provider_exists;
  var a$49 = inp.description_gte;
  var a$50 = inp.metaData;
  var a$51 = inp.title_in;
  var a$52 = inp.description_exists;
  var a$53 = inp.createdAt_exists;
  var a$54 = inp.price_gt;
  var a$55 = inp.externalId_exists;
  var a$56 = inp.createdAt;
  var a$57 = inp.provider;
  var a$58 = inp.createdAt_lte;
  var a$59 = inp.price_in;
  var a$60 = inp.description_in;
  var a$61 = inp.url_gte;
  var a$62 = inp.updatedAt_nin;
  var a$63 = inp.tags_in;
  var a$64 = inp.giftSource_gt;
  var a$65 = inp.description_lt;
  var a$66 = inp.externalId_nin;
  var a$67 = inp.description_nin;
  var a$68 = inp.url_exists;
  var a$69 = inp._id_lt;
  var a$70 = inp.status_in;
  var a$71 = inp.description_gt;
  var a$72 = inp.externalId_gt;
  var a$73 = inp.createdAt_nin;
  var a$74 = inp._id_gte;
  var a$75 = inp.tagScores_exists;
  var a$76 = inp.status;
  var a$77 = inp.tags_exists;
  var a$78 = inp.price_exists;
  var a$79 = inp.status_exists;
  var a$80 = inp.image;
  var a$81 = inp.image_gt;
  var a$82 = inp.updatedAt_ne;
  var a$83 = inp.AND;
  var a$84 = inp.createdAt_gte;
  var a$85 = inp.gender_exists;
  var a$86 = inp.price_gte;
  var a$87 = inp._id_in;
  var a$88 = inp.status_lt;
  var a$89 = inp.tagScores_nin;
  var a$90 = inp.price_ne;
  var a$91 = inp.giftSource_lte;
  var a$92 = inp.url_ne;
  var a$93 = inp.updatedAt_exists;
  var a$94 = inp.giftSource_exists;
  var a$95 = inp.image_gte;
  var a$96 = inp.title;
  var a$97 = inp.createdAt_in;
  var a$98 = inp.image_in;
  var a$99 = inp.image_exists;
  var a$100 = inp.gender_in;
  var a$101 = inp.metaData_exists;
  var a$102 = inp.price_lte;
  var a$103 = inp._id_lte;
  var a$104 = inp.image_lt;
  var a$105 = inp.giftSource_lt;
  var a$106 = inp.associatedProducts_nin;
  var a$107 = inp.updatedAt_lt;
  var a$108 = inp.gender_gte;
  var a$109 = inp.createdAt_gt;
  var a$110 = inp.image_lte;
  var a$111 = inp.externalId_lte;
  var a$112 = inp.gender_ne;
  var a$113 = inp.url_in;
  var a$114 = inp.title_gt;
  var a$115 = inp.tagScores_in;
  var a$116 = inp.title_lte;
  var a$117 = inp.updatedAt_in;
  var a$118 = inp.updatedAt_gt;
  var a$119 = inp.status_gt;
  var a$120 = inp.description_lte;
  var a$121 = inp.tags_nin;
  var a$122 = inp.updatedAt_gte;
  var a$123 = inp.image_ne;
  var a$124 = inp.title_ne;
  var a$125 = inp.associatedProducts_in;
  return {
          title_lt: a !== undefined ? a : undefined,
          createdAt_ne: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          _id_nin: a$2 !== undefined ? a$2.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          giftSource_ne: a$3 !== undefined ? a$3 : undefined,
          gender_lte: a$4 !== undefined ? (
              a$4 === "UNISEX" ? "UNISEX" : (
                  a$4 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          _id_exists: a$5 !== undefined ? a$5 : undefined,
          externalId_in: a$6 !== undefined ? a$6.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          status_gte: a$7 !== undefined ? (
              a$7 === "DUPLICATEMERGED" ? "DUPLICATEMERGED" : (
                  a$7 === "RETIRED" ? "RETIRED" : (
                      a$7 === "DUPLICATE" ? "DUPLICATE" : (
                          a$7 === "BROKENLINK" ? "BROKENLINK" : (
                              a$7 === "LIVE" ? "LIVE" : "BROKENIMAGE"
                            )
                        )
                    )
                )
            ) : undefined,
          _id_ne: a$8 !== undefined ? Caml_option.valFromOption(a$8) : undefined,
          createdAt_lt: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          price_lt: a$10 !== undefined ? a$10 : undefined,
          title_gte: a$11 !== undefined ? a$11 : undefined,
          tagScores: a$12 !== undefined ? a$12.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectProductTagScoresQueryInput(b);
                  }
                  
                }) : undefined,
          giftSource_gte: a$13 !== undefined ? a$13 : undefined,
          gender: a$14 !== undefined ? (
              a$14 === "UNISEX" ? "UNISEX" : (
                  a$14 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          price_nin: a$15 !== undefined ? a$15.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          status_lte: a$16 !== undefined ? (
              a$16 === "DUPLICATEMERGED" ? "DUPLICATEMERGED" : (
                  a$16 === "RETIRED" ? "RETIRED" : (
                      a$16 === "DUPLICATE" ? "DUPLICATE" : (
                          a$16 === "BROKENLINK" ? "BROKENLINK" : (
                              a$16 === "LIVE" ? "LIVE" : "BROKENIMAGE"
                            )
                        )
                    )
                )
            ) : undefined,
          gender_gt: a$17 !== undefined ? (
              a$17 === "UNISEX" ? "UNISEX" : (
                  a$17 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          image_nin: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          updatedAt_lte: a$19 !== undefined ? Caml_option.valFromOption(a$19) : undefined,
          title_nin: a$20 !== undefined ? a$20.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          url_nin: a$21 !== undefined ? a$21.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          status_ne: a$22 !== undefined ? (
              a$22 === "DUPLICATEMERGED" ? "DUPLICATEMERGED" : (
                  a$22 === "RETIRED" ? "RETIRED" : (
                      a$22 === "DUPLICATE" ? "DUPLICATE" : (
                          a$22 === "BROKENLINK" ? "BROKENLINK" : (
                              a$22 === "LIVE" ? "LIVE" : "BROKENIMAGE"
                            )
                        )
                    )
                )
            ) : undefined,
          url: a$23 !== undefined ? a$23 : undefined,
          url_lte: a$24 !== undefined ? a$24 : undefined,
          price: a$25 !== undefined ? a$25 : undefined,
          associatedProducts_exists: a$26 !== undefined ? a$26 : undefined,
          updatedAt: a$27 !== undefined ? Caml_option.valFromOption(a$27) : undefined,
          externalId_lt: a$28 !== undefined ? a$28 : undefined,
          externalId: a$29 !== undefined ? a$29 : undefined,
          giftSource: a$30 !== undefined ? a$30 : undefined,
          externalId_ne: a$31 !== undefined ? a$31 : undefined,
          giftSource_in: a$32 !== undefined ? a$32.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          tags: a$33 !== undefined ? a$33.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          associatedProducts: a$34 !== undefined ? a$34.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectProductAssociatedProductsQueryInput(b);
                  }
                  
                }) : undefined,
          url_gt: a$35 !== undefined ? a$35 : undefined,
          _id: a$36 !== undefined ? Caml_option.valFromOption(a$36) : undefined,
          url_lt: a$37 !== undefined ? a$37 : undefined,
          giftSource_nin: a$38 !== undefined ? a$38.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          gender_nin: a$39 !== undefined ? a$39.map(function (b) {
                  if (b !== undefined) {
                    if (b === "UNISEX") {
                      return "UNISEX";
                    } else if (b === "MALE") {
                      return "MALE";
                    } else {
                      return "FEMALE";
                    }
                  }
                  
                }) : undefined,
          gender_lt: a$40 !== undefined ? (
              a$40 === "UNISEX" ? "UNISEX" : (
                  a$40 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          title_exists: a$41 !== undefined ? a$41 : undefined,
          externalId_gte: a$42 !== undefined ? a$42 : undefined,
          description: a$43 !== undefined ? a$43 : undefined,
          OR: a$44 !== undefined ? a$44.map(serializeInputObjectProductQueryInput) : undefined,
          _id_gt: a$45 !== undefined ? Caml_option.valFromOption(a$45) : undefined,
          description_ne: a$46 !== undefined ? a$46 : undefined,
          status_nin: a$47 !== undefined ? a$47.map(function (b) {
                  if (b !== undefined) {
                    if (b === "DUPLICATEMERGED") {
                      return "DUPLICATEMERGED";
                    } else if (b === "RETIRED") {
                      return "RETIRED";
                    } else if (b === "DUPLICATE") {
                      return "DUPLICATE";
                    } else if (b === "BROKENLINK") {
                      return "BROKENLINK";
                    } else if (b === "LIVE") {
                      return "LIVE";
                    } else {
                      return "BROKENIMAGE";
                    }
                  }
                  
                }) : undefined,
          provider_exists: a$48 !== undefined ? a$48 : undefined,
          description_gte: a$49 !== undefined ? a$49 : undefined,
          metaData: a$50 !== undefined ? serializeInputObjectProductMetaDataQueryInput(a$50) : undefined,
          title_in: a$51 !== undefined ? a$51.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          description_exists: a$52 !== undefined ? a$52 : undefined,
          createdAt_exists: a$53 !== undefined ? a$53 : undefined,
          price_gt: a$54 !== undefined ? a$54 : undefined,
          externalId_exists: a$55 !== undefined ? a$55 : undefined,
          createdAt: a$56 !== undefined ? Caml_option.valFromOption(a$56) : undefined,
          provider: a$57 !== undefined ? serializeInputObjectGiftProviderQueryInput(a$57) : undefined,
          createdAt_lte: a$58 !== undefined ? Caml_option.valFromOption(a$58) : undefined,
          price_in: a$59 !== undefined ? a$59.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          description_in: a$60 !== undefined ? a$60.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          url_gte: a$61 !== undefined ? a$61 : undefined,
          updatedAt_nin: a$62 !== undefined ? a$62.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          tags_in: a$63 !== undefined ? a$63.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          giftSource_gt: a$64 !== undefined ? a$64 : undefined,
          description_lt: a$65 !== undefined ? a$65 : undefined,
          externalId_nin: a$66 !== undefined ? a$66.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          description_nin: a$67 !== undefined ? a$67.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          url_exists: a$68 !== undefined ? a$68 : undefined,
          _id_lt: a$69 !== undefined ? Caml_option.valFromOption(a$69) : undefined,
          status_in: a$70 !== undefined ? a$70.map(function (b) {
                  if (b !== undefined) {
                    if (b === "DUPLICATEMERGED") {
                      return "DUPLICATEMERGED";
                    } else if (b === "RETIRED") {
                      return "RETIRED";
                    } else if (b === "DUPLICATE") {
                      return "DUPLICATE";
                    } else if (b === "BROKENLINK") {
                      return "BROKENLINK";
                    } else if (b === "LIVE") {
                      return "LIVE";
                    } else {
                      return "BROKENIMAGE";
                    }
                  }
                  
                }) : undefined,
          description_gt: a$71 !== undefined ? a$71 : undefined,
          externalId_gt: a$72 !== undefined ? a$72 : undefined,
          createdAt_nin: a$73 !== undefined ? a$73.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          _id_gte: a$74 !== undefined ? Caml_option.valFromOption(a$74) : undefined,
          tagScores_exists: a$75 !== undefined ? a$75 : undefined,
          status: a$76 !== undefined ? (
              a$76 === "DUPLICATEMERGED" ? "DUPLICATEMERGED" : (
                  a$76 === "RETIRED" ? "RETIRED" : (
                      a$76 === "DUPLICATE" ? "DUPLICATE" : (
                          a$76 === "BROKENLINK" ? "BROKENLINK" : (
                              a$76 === "LIVE" ? "LIVE" : "BROKENIMAGE"
                            )
                        )
                    )
                )
            ) : undefined,
          tags_exists: a$77 !== undefined ? a$77 : undefined,
          price_exists: a$78 !== undefined ? a$78 : undefined,
          status_exists: a$79 !== undefined ? a$79 : undefined,
          image: a$80 !== undefined ? a$80 : undefined,
          image_gt: a$81 !== undefined ? a$81 : undefined,
          updatedAt_ne: a$82 !== undefined ? Caml_option.valFromOption(a$82) : undefined,
          AND: a$83 !== undefined ? a$83.map(serializeInputObjectProductQueryInput) : undefined,
          createdAt_gte: a$84 !== undefined ? Caml_option.valFromOption(a$84) : undefined,
          gender_exists: a$85 !== undefined ? a$85 : undefined,
          price_gte: a$86 !== undefined ? a$86 : undefined,
          _id_in: a$87 !== undefined ? a$87.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          status_lt: a$88 !== undefined ? (
              a$88 === "DUPLICATEMERGED" ? "DUPLICATEMERGED" : (
                  a$88 === "RETIRED" ? "RETIRED" : (
                      a$88 === "DUPLICATE" ? "DUPLICATE" : (
                          a$88 === "BROKENLINK" ? "BROKENLINK" : (
                              a$88 === "LIVE" ? "LIVE" : "BROKENIMAGE"
                            )
                        )
                    )
                )
            ) : undefined,
          tagScores_nin: a$89 !== undefined ? a$89.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectProductTagScoresQueryInput(b);
                  }
                  
                }) : undefined,
          price_ne: a$90 !== undefined ? a$90 : undefined,
          giftSource_lte: a$91 !== undefined ? a$91 : undefined,
          url_ne: a$92 !== undefined ? a$92 : undefined,
          updatedAt_exists: a$93 !== undefined ? a$93 : undefined,
          giftSource_exists: a$94 !== undefined ? a$94 : undefined,
          image_gte: a$95 !== undefined ? a$95 : undefined,
          title: a$96 !== undefined ? a$96 : undefined,
          createdAt_in: a$97 !== undefined ? a$97.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          image_in: a$98 !== undefined ? a$98.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image_exists: a$99 !== undefined ? a$99 : undefined,
          gender_in: a$100 !== undefined ? a$100.map(function (b) {
                  if (b !== undefined) {
                    if (b === "UNISEX") {
                      return "UNISEX";
                    } else if (b === "MALE") {
                      return "MALE";
                    } else {
                      return "FEMALE";
                    }
                  }
                  
                }) : undefined,
          metaData_exists: a$101 !== undefined ? a$101 : undefined,
          price_lte: a$102 !== undefined ? a$102 : undefined,
          _id_lte: a$103 !== undefined ? Caml_option.valFromOption(a$103) : undefined,
          image_lt: a$104 !== undefined ? a$104 : undefined,
          giftSource_lt: a$105 !== undefined ? a$105 : undefined,
          associatedProducts_nin: a$106 !== undefined ? a$106.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectProductAssociatedProductsQueryInput(b);
                  }
                  
                }) : undefined,
          updatedAt_lt: a$107 !== undefined ? Caml_option.valFromOption(a$107) : undefined,
          gender_gte: a$108 !== undefined ? (
              a$108 === "UNISEX" ? "UNISEX" : (
                  a$108 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          createdAt_gt: a$109 !== undefined ? Caml_option.valFromOption(a$109) : undefined,
          image_lte: a$110 !== undefined ? a$110 : undefined,
          externalId_lte: a$111 !== undefined ? a$111 : undefined,
          gender_ne: a$112 !== undefined ? (
              a$112 === "UNISEX" ? "UNISEX" : (
                  a$112 === "MALE" ? "MALE" : "FEMALE"
                )
            ) : undefined,
          url_in: a$113 !== undefined ? a$113.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          title_gt: a$114 !== undefined ? a$114 : undefined,
          tagScores_in: a$115 !== undefined ? a$115.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectProductTagScoresQueryInput(b);
                  }
                  
                }) : undefined,
          title_lte: a$116 !== undefined ? a$116 : undefined,
          updatedAt_in: a$117 !== undefined ? a$117.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          updatedAt_gt: a$118 !== undefined ? Caml_option.valFromOption(a$118) : undefined,
          status_gt: a$119 !== undefined ? (
              a$119 === "DUPLICATEMERGED" ? "DUPLICATEMERGED" : (
                  a$119 === "RETIRED" ? "RETIRED" : (
                      a$119 === "DUPLICATE" ? "DUPLICATE" : (
                          a$119 === "BROKENLINK" ? "BROKENLINK" : (
                              a$119 === "LIVE" ? "LIVE" : "BROKENIMAGE"
                            )
                        )
                    )
                )
            ) : undefined,
          description_lte: a$120 !== undefined ? a$120 : undefined,
          tags_nin: a$121 !== undefined ? a$121.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          updatedAt_gte: a$122 !== undefined ? Caml_option.valFromOption(a$122) : undefined,
          image_ne: a$123 !== undefined ? a$123 : undefined,
          title_ne: a$124 !== undefined ? a$124 : undefined,
          associatedProducts_in: a$125 !== undefined ? a$125.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectProductAssociatedProductsQueryInput(b);
                  }
                  
                }) : undefined
        };
}

function serializeInputObjectProductAssociatedProductsQueryInput(inp) {
  var a = inp.title_ne;
  var a$1 = inp.url_in;
  var a$2 = inp.url_gt;
  var a$3 = inp.image_exists;
  var a$4 = inp.title;
  var a$5 = inp.OR;
  var a$6 = inp.image_ne;
  var a$7 = inp.title_nin;
  var a$8 = inp.image_in;
  var a$9 = inp.title_gt;
  var a$10 = inp.title_lte;
  var a$11 = inp.title_in;
  var a$12 = inp.url_nin;
  var a$13 = inp.url_exists;
  var a$14 = inp.title_lt;
  var a$15 = inp.url_ne;
  var a$16 = inp.url_lt;
  var a$17 = inp.image_lte;
  var a$18 = inp.title_exists;
  var a$19 = inp.image_gte;
  var a$20 = inp.image_gt;
  var a$21 = inp.title_gte;
  var a$22 = inp.image_nin;
  var a$23 = inp.AND;
  var a$24 = inp.image_lt;
  var a$25 = inp.image;
  var a$26 = inp.url;
  var a$27 = inp.url_gte;
  var a$28 = inp.url_lte;
  return {
          title_ne: a !== undefined ? a : undefined,
          url_in: a$1 !== undefined ? a$1.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          url_gt: a$2 !== undefined ? a$2 : undefined,
          image_exists: a$3 !== undefined ? a$3 : undefined,
          title: a$4 !== undefined ? a$4 : undefined,
          OR: a$5 !== undefined ? a$5.map(serializeInputObjectProductAssociatedProductsQueryInput) : undefined,
          image_ne: a$6 !== undefined ? a$6 : undefined,
          title_nin: a$7 !== undefined ? a$7.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          image_in: a$8 !== undefined ? a$8.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          title_gt: a$9 !== undefined ? a$9 : undefined,
          title_lte: a$10 !== undefined ? a$10 : undefined,
          title_in: a$11 !== undefined ? a$11.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          url_nin: a$12 !== undefined ? a$12.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          url_exists: a$13 !== undefined ? a$13 : undefined,
          title_lt: a$14 !== undefined ? a$14 : undefined,
          url_ne: a$15 !== undefined ? a$15 : undefined,
          url_lt: a$16 !== undefined ? a$16 : undefined,
          image_lte: a$17 !== undefined ? a$17 : undefined,
          title_exists: a$18 !== undefined ? a$18 : undefined,
          image_gte: a$19 !== undefined ? a$19 : undefined,
          image_gt: a$20 !== undefined ? a$20 : undefined,
          title_gte: a$21 !== undefined ? a$21 : undefined,
          image_nin: a$22 !== undefined ? a$22.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          AND: a$23 !== undefined ? a$23.map(serializeInputObjectProductAssociatedProductsQueryInput) : undefined,
          image_lt: a$24 !== undefined ? a$24 : undefined,
          image: a$25 !== undefined ? a$25 : undefined,
          url: a$26 !== undefined ? a$26 : undefined,
          url_gte: a$27 !== undefined ? a$27 : undefined,
          url_lte: a$28 !== undefined ? a$28 : undefined
        };
}

function serializeInputObjectGiftProviderQueryInput(inp) {
  var a = inp.updatedAt_lte;
  var a$1 = inp.createdAt_gt;
  var a$2 = inp.createdAt_nin;
  var a$3 = inp.logo_ne;
  var a$4 = inp.name_exists;
  var a$5 = inp.createdAt_ne;
  var a$6 = inp.type_lte;
  var a$7 = inp.name_nin;
  var a$8 = inp.logo_in;
  var a$9 = inp._id_ne;
  var a$10 = inp._id_in;
  var a$11 = inp.type_gte;
  var a$12 = inp.externalId_nin;
  var a$13 = inp.updatedAt_gt;
  var a$14 = inp.externalId_gt;
  var a$15 = inp.name_gt;
  var a$16 = inp._id_exists;
  var a$17 = inp.type_gt;
  var a$18 = inp.createdAt_gte;
  var a$19 = inp.createdAt_lt;
  var a$20 = inp.type_nin;
  var a$21 = inp.logo_exists;
  var a$22 = inp.externalId_in;
  var a$23 = inp.type_lt;
  var a$24 = inp.type_in;
  var a$25 = inp.type;
  var a$26 = inp.externalId_ne;
  var a$27 = inp.name;
  var a$28 = inp.logo_nin;
  var a$29 = inp.updatedAt;
  var a$30 = inp.updatedAt_exists;
  var a$31 = inp.externalId_lte;
  var a$32 = inp.createdAt_in;
  var a$33 = inp.updatedAt_nin;
  var a$34 = inp.externalId_gte;
  var a$35 = inp.logo_lt;
  var a$36 = inp._id_lt;
  var a$37 = inp.externalId;
  var a$38 = inp.updatedAt_in;
  var a$39 = inp.updatedAt_lt;
  var a$40 = inp.logo_gte;
  var a$41 = inp.logo_lte;
  var a$42 = inp._id_gt;
  var a$43 = inp.name_lte;
  var a$44 = inp.updatedAt_gte;
  var a$45 = inp.createdAt_exists;
  var a$46 = inp.OR;
  var a$47 = inp.updatedAt_ne;
  var a$48 = inp.createdAt;
  var a$49 = inp.name_gte;
  var a$50 = inp.externalId_exists;
  var a$51 = inp.type_exists;
  var a$52 = inp.AND;
  var a$53 = inp.createdAt_lte;
  var a$54 = inp.name_in;
  var a$55 = inp._id;
  var a$56 = inp.externalId_lt;
  var a$57 = inp.logo;
  var a$58 = inp._id_nin;
  var a$59 = inp.logo_gt;
  var a$60 = inp._id_gte;
  var a$61 = inp.name_ne;
  var a$62 = inp._id_lte;
  var a$63 = inp.type_ne;
  var a$64 = inp.name_lt;
  return {
          updatedAt_lte: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          createdAt_gt: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          createdAt_nin: a$2 !== undefined ? a$2.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          logo_ne: a$3 !== undefined ? a$3 : undefined,
          name_exists: a$4 !== undefined ? a$4 : undefined,
          createdAt_ne: a$5 !== undefined ? Caml_option.valFromOption(a$5) : undefined,
          type_lte: a$6 !== undefined ? (
              a$6 === "CJ" ? "CJ" : (
                  a$6 === "OCCASIONAL_LY" ? "OCCASIONAL_LY" : (
                      a$6 === "ZAZZLE" ? "ZAZZLE" : (
                          a$6 === "ETSY" ? "ETSY" : (
                              a$6 === "IMPACT" ? "IMPACT" : (
                                  a$6 === "SHAREASALE" ? "SHAREASALE" : "BESTBUY"
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          name_nin: a$7 !== undefined ? a$7.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          logo_in: a$8 !== undefined ? a$8.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          _id_ne: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          _id_in: a$10 !== undefined ? a$10.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          type_gte: a$11 !== undefined ? (
              a$11 === "CJ" ? "CJ" : (
                  a$11 === "OCCASIONAL_LY" ? "OCCASIONAL_LY" : (
                      a$11 === "ZAZZLE" ? "ZAZZLE" : (
                          a$11 === "ETSY" ? "ETSY" : (
                              a$11 === "IMPACT" ? "IMPACT" : (
                                  a$11 === "SHAREASALE" ? "SHAREASALE" : "BESTBUY"
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          externalId_nin: a$12 !== undefined ? a$12.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          updatedAt_gt: a$13 !== undefined ? Caml_option.valFromOption(a$13) : undefined,
          externalId_gt: a$14 !== undefined ? a$14 : undefined,
          name_gt: a$15 !== undefined ? a$15 : undefined,
          _id_exists: a$16 !== undefined ? a$16 : undefined,
          type_gt: a$17 !== undefined ? (
              a$17 === "CJ" ? "CJ" : (
                  a$17 === "OCCASIONAL_LY" ? "OCCASIONAL_LY" : (
                      a$17 === "ZAZZLE" ? "ZAZZLE" : (
                          a$17 === "ETSY" ? "ETSY" : (
                              a$17 === "IMPACT" ? "IMPACT" : (
                                  a$17 === "SHAREASALE" ? "SHAREASALE" : "BESTBUY"
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          createdAt_gte: a$18 !== undefined ? Caml_option.valFromOption(a$18) : undefined,
          createdAt_lt: a$19 !== undefined ? Caml_option.valFromOption(a$19) : undefined,
          type_nin: a$20 !== undefined ? a$20.map(function (b) {
                  if (b !== undefined) {
                    if (b === "CJ") {
                      return "CJ";
                    } else if (b === "OCCASIONAL_LY") {
                      return "OCCASIONAL_LY";
                    } else if (b === "ZAZZLE") {
                      return "ZAZZLE";
                    } else if (b === "ETSY") {
                      return "ETSY";
                    } else if (b === "IMPACT") {
                      return "IMPACT";
                    } else if (b === "SHAREASALE") {
                      return "SHAREASALE";
                    } else {
                      return "BESTBUY";
                    }
                  }
                  
                }) : undefined,
          logo_exists: a$21 !== undefined ? a$21 : undefined,
          externalId_in: a$22 !== undefined ? a$22.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          type_lt: a$23 !== undefined ? (
              a$23 === "CJ" ? "CJ" : (
                  a$23 === "OCCASIONAL_LY" ? "OCCASIONAL_LY" : (
                      a$23 === "ZAZZLE" ? "ZAZZLE" : (
                          a$23 === "ETSY" ? "ETSY" : (
                              a$23 === "IMPACT" ? "IMPACT" : (
                                  a$23 === "SHAREASALE" ? "SHAREASALE" : "BESTBUY"
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          type_in: a$24 !== undefined ? a$24.map(function (b) {
                  if (b !== undefined) {
                    if (b === "CJ") {
                      return "CJ";
                    } else if (b === "OCCASIONAL_LY") {
                      return "OCCASIONAL_LY";
                    } else if (b === "ZAZZLE") {
                      return "ZAZZLE";
                    } else if (b === "ETSY") {
                      return "ETSY";
                    } else if (b === "IMPACT") {
                      return "IMPACT";
                    } else if (b === "SHAREASALE") {
                      return "SHAREASALE";
                    } else {
                      return "BESTBUY";
                    }
                  }
                  
                }) : undefined,
          type: a$25 !== undefined ? (
              a$25 === "CJ" ? "CJ" : (
                  a$25 === "OCCASIONAL_LY" ? "OCCASIONAL_LY" : (
                      a$25 === "ZAZZLE" ? "ZAZZLE" : (
                          a$25 === "ETSY" ? "ETSY" : (
                              a$25 === "IMPACT" ? "IMPACT" : (
                                  a$25 === "SHAREASALE" ? "SHAREASALE" : "BESTBUY"
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          externalId_ne: a$26 !== undefined ? a$26 : undefined,
          name: a$27 !== undefined ? a$27 : undefined,
          logo_nin: a$28 !== undefined ? a$28.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          updatedAt: a$29 !== undefined ? Caml_option.valFromOption(a$29) : undefined,
          updatedAt_exists: a$30 !== undefined ? a$30 : undefined,
          externalId_lte: a$31 !== undefined ? a$31 : undefined,
          createdAt_in: a$32 !== undefined ? a$32.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          updatedAt_nin: a$33 !== undefined ? a$33.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          externalId_gte: a$34 !== undefined ? a$34 : undefined,
          logo_lt: a$35 !== undefined ? a$35 : undefined,
          _id_lt: a$36 !== undefined ? Caml_option.valFromOption(a$36) : undefined,
          externalId: a$37 !== undefined ? a$37 : undefined,
          updatedAt_in: a$38 !== undefined ? a$38.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          updatedAt_lt: a$39 !== undefined ? Caml_option.valFromOption(a$39) : undefined,
          logo_gte: a$40 !== undefined ? a$40 : undefined,
          logo_lte: a$41 !== undefined ? a$41 : undefined,
          _id_gt: a$42 !== undefined ? Caml_option.valFromOption(a$42) : undefined,
          name_lte: a$43 !== undefined ? a$43 : undefined,
          updatedAt_gte: a$44 !== undefined ? Caml_option.valFromOption(a$44) : undefined,
          createdAt_exists: a$45 !== undefined ? a$45 : undefined,
          OR: a$46 !== undefined ? a$46.map(serializeInputObjectGiftProviderQueryInput) : undefined,
          updatedAt_ne: a$47 !== undefined ? Caml_option.valFromOption(a$47) : undefined,
          createdAt: a$48 !== undefined ? Caml_option.valFromOption(a$48) : undefined,
          name_gte: a$49 !== undefined ? a$49 : undefined,
          externalId_exists: a$50 !== undefined ? a$50 : undefined,
          type_exists: a$51 !== undefined ? a$51 : undefined,
          AND: a$52 !== undefined ? a$52.map(serializeInputObjectGiftProviderQueryInput) : undefined,
          createdAt_lte: a$53 !== undefined ? Caml_option.valFromOption(a$53) : undefined,
          name_in: a$54 !== undefined ? a$54.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          _id: a$55 !== undefined ? Caml_option.valFromOption(a$55) : undefined,
          externalId_lt: a$56 !== undefined ? a$56 : undefined,
          logo: a$57 !== undefined ? a$57 : undefined,
          _id_nin: a$58 !== undefined ? a$58.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          logo_gt: a$59 !== undefined ? a$59 : undefined,
          _id_gte: a$60 !== undefined ? Caml_option.valFromOption(a$60) : undefined,
          name_ne: a$61 !== undefined ? a$61 : undefined,
          _id_lte: a$62 !== undefined ? Caml_option.valFromOption(a$62) : undefined,
          type_ne: a$63 !== undefined ? (
              a$63 === "CJ" ? "CJ" : (
                  a$63 === "OCCASIONAL_LY" ? "OCCASIONAL_LY" : (
                      a$63 === "ZAZZLE" ? "ZAZZLE" : (
                          a$63 === "ETSY" ? "ETSY" : (
                              a$63 === "IMPACT" ? "IMPACT" : (
                                  a$63 === "SHAREASALE" ? "SHAREASALE" : "BESTBUY"
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          name_lt: a$64 !== undefined ? a$64 : undefined
        };
}

function serializeInputObjectProductMetaDataQueryInput(inp) {
  var a = inp.etsyFavorers_exists;
  var a$1 = inp.etsyFavorers_gte;
  var a$2 = inp.avgReview_lt;
  var a$3 = inp.avgReview_in;
  var a$4 = inp.avgReview_exists;
  var a$5 = inp.etsyFavorers_gt;
  var a$6 = inp.avgReview_lte;
  var a$7 = inp.etsyFavorers_nin;
  var a$8 = inp.etsyFavorers_lte;
  var a$9 = inp.numberOfReviews_gte;
  var a$10 = inp.avgReview_ne;
  var a$11 = inp.numberOfReviews_exists;
  var a$12 = inp.OR;
  var a$13 = inp.avgReview;
  var a$14 = inp.etsyFavorers_in;
  var a$15 = inp.numberOfReviews;
  var a$16 = inp.etsyFavorers;
  var a$17 = inp.numberOfReviews_ne;
  var a$18 = inp.numberOfReviews_in;
  var a$19 = inp.avgReview_gt;
  var a$20 = inp.numberOfReviews_nin;
  var a$21 = inp.etsyFavorers_ne;
  var a$22 = inp.avgReview_gte;
  var a$23 = inp.numberOfReviews_lt;
  var a$24 = inp.avgReview_nin;
  var a$25 = inp.numberOfReviews_lte;
  var a$26 = inp.AND;
  var a$27 = inp.numberOfReviews_gt;
  var a$28 = inp.etsyFavorers_lt;
  return {
          etsyFavorers_exists: a !== undefined ? a : undefined,
          etsyFavorers_gte: a$1 !== undefined ? a$1 : undefined,
          avgReview_lt: a$2 !== undefined ? a$2 : undefined,
          avgReview_in: a$3 !== undefined ? a$3.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          avgReview_exists: a$4 !== undefined ? a$4 : undefined,
          etsyFavorers_gt: a$5 !== undefined ? a$5 : undefined,
          avgReview_lte: a$6 !== undefined ? a$6 : undefined,
          etsyFavorers_nin: a$7 !== undefined ? a$7.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          etsyFavorers_lte: a$8 !== undefined ? a$8 : undefined,
          numberOfReviews_gte: a$9 !== undefined ? a$9 : undefined,
          avgReview_ne: a$10 !== undefined ? a$10 : undefined,
          numberOfReviews_exists: a$11 !== undefined ? a$11 : undefined,
          OR: a$12 !== undefined ? a$12.map(serializeInputObjectProductMetaDataQueryInput) : undefined,
          avgReview: a$13 !== undefined ? a$13 : undefined,
          etsyFavorers_in: a$14 !== undefined ? a$14.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          numberOfReviews: a$15 !== undefined ? a$15 : undefined,
          etsyFavorers: a$16 !== undefined ? a$16 : undefined,
          numberOfReviews_ne: a$17 !== undefined ? a$17 : undefined,
          numberOfReviews_in: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          avgReview_gt: a$19 !== undefined ? a$19 : undefined,
          numberOfReviews_nin: a$20 !== undefined ? a$20.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          etsyFavorers_ne: a$21 !== undefined ? a$21 : undefined,
          avgReview_gte: a$22 !== undefined ? a$22 : undefined,
          numberOfReviews_lt: a$23 !== undefined ? a$23 : undefined,
          avgReview_nin: a$24 !== undefined ? a$24.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          numberOfReviews_lte: a$25 !== undefined ? a$25 : undefined,
          AND: a$26 !== undefined ? a$26.map(serializeInputObjectProductMetaDataQueryInput) : undefined,
          numberOfReviews_gt: a$27 !== undefined ? a$27 : undefined,
          etsyFavorers_lt: a$28 !== undefined ? a$28 : undefined
        };
}

function serializeInputObjectProductTagScoresQueryInput(inp) {
  var a = inp.AND;
  var a$1 = inp.score_lte;
  var a$2 = inp.tag;
  var a$3 = inp.score_in;
  var a$4 = inp.tag_lte;
  var a$5 = inp.score_exists;
  var a$6 = inp.score_lt;
  var a$7 = inp.tag_nin;
  var a$8 = inp.tag_lt;
  var a$9 = inp.score;
  var a$10 = inp.score_ne;
  var a$11 = inp.score_gt;
  var a$12 = inp.tag_exists;
  var a$13 = inp.OR;
  var a$14 = inp.score_nin;
  var a$15 = inp.tag_ne;
  var a$16 = inp.tag_gt;
  var a$17 = inp.tag_gte;
  var a$18 = inp.tag_in;
  var a$19 = inp.score_gte;
  return {
          AND: a !== undefined ? a.map(serializeInputObjectProductTagScoresQueryInput) : undefined,
          score_lte: a$1 !== undefined ? a$1 : undefined,
          tag: a$2 !== undefined ? a$2 : undefined,
          score_in: a$3 !== undefined ? a$3.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          tag_lte: a$4 !== undefined ? a$4 : undefined,
          score_exists: a$5 !== undefined ? a$5 : undefined,
          score_lt: a$6 !== undefined ? a$6 : undefined,
          tag_nin: a$7 !== undefined ? a$7.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          tag_lt: a$8 !== undefined ? a$8 : undefined,
          score: a$9 !== undefined ? a$9 : undefined,
          score_ne: a$10 !== undefined ? a$10 : undefined,
          score_gt: a$11 !== undefined ? a$11 : undefined,
          tag_exists: a$12 !== undefined ? a$12 : undefined,
          OR: a$13 !== undefined ? a$13.map(serializeInputObjectProductTagScoresQueryInput) : undefined,
          score_nin: a$14 !== undefined ? a$14.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          tag_ne: a$15 !== undefined ? a$15 : undefined,
          tag_gt: a$16 !== undefined ? a$16 : undefined,
          tag_gte: a$17 !== undefined ? a$17 : undefined,
          tag_in: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          score_gte: a$19 !== undefined ? a$19 : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.query;
  return {
          query: a !== undefined ? serializeInputObjectProductQueryInput(a) : undefined
        };
}

function makeVariables(query, param) {
  return {
          query: query
        };
}

function makeInputObjectProductQueryInput(title_lt, createdAt_ne, _id_nin, giftSource_ne, gender_lte, _id_exists, externalId_in, status_gte, _id_ne, createdAt_lt, price_lt, title_gte, tagScores, giftSource_gte, gender, price_nin, status_lte, gender_gt, image_nin, updatedAt_lte, title_nin, url_nin, status_ne, url, url_lte, price, associatedProducts_exists, updatedAt, externalId_lt, externalId, giftSource, externalId_ne, giftSource_in, tags, associatedProducts, url_gt, _id, url_lt, giftSource_nin, gender_nin, gender_lt, title_exists, externalId_gte, description, OR, _id_gt, description_ne, status_nin, provider_exists, description_gte, metaData, title_in, description_exists, createdAt_exists, price_gt, externalId_exists, createdAt, provider, createdAt_lte, price_in, description_in, url_gte, updatedAt_nin, tags_in, giftSource_gt, description_lt, externalId_nin, description_nin, url_exists, _id_lt, status_in, description_gt, externalId_gt, createdAt_nin, _id_gte, tagScores_exists, status, tags_exists, price_exists, status_exists, image, image_gt, updatedAt_ne, AND, createdAt_gte, gender_exists, price_gte, _id_in, status_lt, tagScores_nin, price_ne, giftSource_lte, url_ne, updatedAt_exists, giftSource_exists, image_gte, title, createdAt_in, image_in, image_exists, gender_in, metaData_exists, price_lte, _id_lte, image_lt, giftSource_lt, associatedProducts_nin, updatedAt_lt, gender_gte, createdAt_gt, image_lte, externalId_lte, gender_ne, url_in, title_gt, tagScores_in, title_lte, updatedAt_in, updatedAt_gt, status_gt, description_lte, tags_nin, updatedAt_gte, image_ne, title_ne, associatedProducts_in, param) {
  return {
          title_lt: title_lt,
          createdAt_ne: createdAt_ne,
          _id_nin: _id_nin,
          giftSource_ne: giftSource_ne,
          gender_lte: gender_lte,
          _id_exists: _id_exists,
          externalId_in: externalId_in,
          status_gte: status_gte,
          _id_ne: _id_ne,
          createdAt_lt: createdAt_lt,
          price_lt: price_lt,
          title_gte: title_gte,
          tagScores: tagScores,
          giftSource_gte: giftSource_gte,
          gender: gender,
          price_nin: price_nin,
          status_lte: status_lte,
          gender_gt: gender_gt,
          image_nin: image_nin,
          updatedAt_lte: updatedAt_lte,
          title_nin: title_nin,
          url_nin: url_nin,
          status_ne: status_ne,
          url: url,
          url_lte: url_lte,
          price: price,
          associatedProducts_exists: associatedProducts_exists,
          updatedAt: updatedAt,
          externalId_lt: externalId_lt,
          externalId: externalId,
          giftSource: giftSource,
          externalId_ne: externalId_ne,
          giftSource_in: giftSource_in,
          tags: tags,
          associatedProducts: associatedProducts,
          url_gt: url_gt,
          _id: _id,
          url_lt: url_lt,
          giftSource_nin: giftSource_nin,
          gender_nin: gender_nin,
          gender_lt: gender_lt,
          title_exists: title_exists,
          externalId_gte: externalId_gte,
          description: description,
          OR: OR,
          _id_gt: _id_gt,
          description_ne: description_ne,
          status_nin: status_nin,
          provider_exists: provider_exists,
          description_gte: description_gte,
          metaData: metaData,
          title_in: title_in,
          description_exists: description_exists,
          createdAt_exists: createdAt_exists,
          price_gt: price_gt,
          externalId_exists: externalId_exists,
          createdAt: createdAt,
          provider: provider,
          createdAt_lte: createdAt_lte,
          price_in: price_in,
          description_in: description_in,
          url_gte: url_gte,
          updatedAt_nin: updatedAt_nin,
          tags_in: tags_in,
          giftSource_gt: giftSource_gt,
          description_lt: description_lt,
          externalId_nin: externalId_nin,
          description_nin: description_nin,
          url_exists: url_exists,
          _id_lt: _id_lt,
          status_in: status_in,
          description_gt: description_gt,
          externalId_gt: externalId_gt,
          createdAt_nin: createdAt_nin,
          _id_gte: _id_gte,
          tagScores_exists: tagScores_exists,
          status: status,
          tags_exists: tags_exists,
          price_exists: price_exists,
          status_exists: status_exists,
          image: image,
          image_gt: image_gt,
          updatedAt_ne: updatedAt_ne,
          AND: AND,
          createdAt_gte: createdAt_gte,
          gender_exists: gender_exists,
          price_gte: price_gte,
          _id_in: _id_in,
          status_lt: status_lt,
          tagScores_nin: tagScores_nin,
          price_ne: price_ne,
          giftSource_lte: giftSource_lte,
          url_ne: url_ne,
          updatedAt_exists: updatedAt_exists,
          giftSource_exists: giftSource_exists,
          image_gte: image_gte,
          title: title,
          createdAt_in: createdAt_in,
          image_in: image_in,
          image_exists: image_exists,
          gender_in: gender_in,
          metaData_exists: metaData_exists,
          price_lte: price_lte,
          _id_lte: _id_lte,
          image_lt: image_lt,
          giftSource_lt: giftSource_lt,
          associatedProducts_nin: associatedProducts_nin,
          updatedAt_lt: updatedAt_lt,
          gender_gte: gender_gte,
          createdAt_gt: createdAt_gt,
          image_lte: image_lte,
          externalId_lte: externalId_lte,
          gender_ne: gender_ne,
          url_in: url_in,
          title_gt: title_gt,
          tagScores_in: tagScores_in,
          title_lte: title_lte,
          updatedAt_in: updatedAt_in,
          updatedAt_gt: updatedAt_gt,
          status_gt: status_gt,
          description_lte: description_lte,
          tags_nin: tags_nin,
          updatedAt_gte: updatedAt_gte,
          image_ne: image_ne,
          title_ne: title_ne,
          associatedProducts_in: associatedProducts_in
        };
}

function makeInputObjectProductTagScoresQueryInput(AND, score_lte, tag, score_in, tag_lte, score_exists, score_lt, tag_nin, tag_lt, score, score_ne, score_gt, tag_exists, OR, score_nin, tag_ne, tag_gt, tag_gte, tag_in, score_gte, param) {
  return {
          AND: AND,
          score_lte: score_lte,
          tag: tag,
          score_in: score_in,
          tag_lte: tag_lte,
          score_exists: score_exists,
          score_lt: score_lt,
          tag_nin: tag_nin,
          tag_lt: tag_lt,
          score: score,
          score_ne: score_ne,
          score_gt: score_gt,
          tag_exists: tag_exists,
          OR: OR,
          score_nin: score_nin,
          tag_ne: tag_ne,
          tag_gt: tag_gt,
          tag_gte: tag_gte,
          tag_in: tag_in,
          score_gte: score_gte
        };
}

function makeInputObjectProductAssociatedProductsQueryInput(title_ne, url_in, url_gt, image_exists, title, OR, image_ne, title_nin, image_in, title_gt, title_lte, title_in, url_nin, url_exists, title_lt, url_ne, url_lt, image_lte, title_exists, image_gte, image_gt, title_gte, image_nin, AND, image_lt, image, url, url_gte, url_lte, param) {
  return {
          title_ne: title_ne,
          url_in: url_in,
          url_gt: url_gt,
          image_exists: image_exists,
          title: title,
          OR: OR,
          image_ne: image_ne,
          title_nin: title_nin,
          image_in: image_in,
          title_gt: title_gt,
          title_lte: title_lte,
          title_in: title_in,
          url_nin: url_nin,
          url_exists: url_exists,
          title_lt: title_lt,
          url_ne: url_ne,
          url_lt: url_lt,
          image_lte: image_lte,
          title_exists: title_exists,
          image_gte: image_gte,
          image_gt: image_gt,
          title_gte: title_gte,
          image_nin: image_nin,
          AND: AND,
          image_lt: image_lt,
          image: image,
          url: url,
          url_gte: url_gte,
          url_lte: url_lte
        };
}

function makeInputObjectProductMetaDataQueryInput(etsyFavorers_exists, etsyFavorers_gte, avgReview_lt, avgReview_in, avgReview_exists, etsyFavorers_gt, avgReview_lte, etsyFavorers_nin, etsyFavorers_lte, numberOfReviews_gte, avgReview_ne, numberOfReviews_exists, OR, avgReview, etsyFavorers_in, numberOfReviews, etsyFavorers, numberOfReviews_ne, numberOfReviews_in, avgReview_gt, numberOfReviews_nin, etsyFavorers_ne, avgReview_gte, numberOfReviews_lt, avgReview_nin, numberOfReviews_lte, AND, numberOfReviews_gt, etsyFavorers_lt, param) {
  return {
          etsyFavorers_exists: etsyFavorers_exists,
          etsyFavorers_gte: etsyFavorers_gte,
          avgReview_lt: avgReview_lt,
          avgReview_in: avgReview_in,
          avgReview_exists: avgReview_exists,
          etsyFavorers_gt: etsyFavorers_gt,
          avgReview_lte: avgReview_lte,
          etsyFavorers_nin: etsyFavorers_nin,
          etsyFavorers_lte: etsyFavorers_lte,
          numberOfReviews_gte: numberOfReviews_gte,
          avgReview_ne: avgReview_ne,
          numberOfReviews_exists: numberOfReviews_exists,
          OR: OR,
          avgReview: avgReview,
          etsyFavorers_in: etsyFavorers_in,
          numberOfReviews: numberOfReviews,
          etsyFavorers: etsyFavorers,
          numberOfReviews_ne: numberOfReviews_ne,
          numberOfReviews_in: numberOfReviews_in,
          avgReview_gt: avgReview_gt,
          numberOfReviews_nin: numberOfReviews_nin,
          etsyFavorers_ne: etsyFavorers_ne,
          avgReview_gte: avgReview_gte,
          numberOfReviews_lt: numberOfReviews_lt,
          avgReview_nin: avgReview_nin,
          numberOfReviews_lte: numberOfReviews_lte,
          AND: AND,
          numberOfReviews_gt: numberOfReviews_gt,
          etsyFavorers_lt: etsyFavorers_lt
        };
}

function makeInputObjectGiftProviderQueryInput(updatedAt_lte, createdAt_gt, createdAt_nin, logo_ne, name_exists, createdAt_ne, type_lte, name_nin, logo_in, _id_ne, _id_in, type_gte, externalId_nin, updatedAt_gt, externalId_gt, name_gt, _id_exists, type_gt, createdAt_gte, createdAt_lt, type_nin, logo_exists, externalId_in, type_lt, type_in, type_, externalId_ne, name, logo_nin, updatedAt, updatedAt_exists, externalId_lte, createdAt_in, updatedAt_nin, externalId_gte, logo_lt, _id_lt, externalId, updatedAt_in, updatedAt_lt, logo_gte, logo_lte, _id_gt, name_lte, updatedAt_gte, createdAt_exists, OR, updatedAt_ne, createdAt, name_gte, externalId_exists, type_exists, AND, createdAt_lte, name_in, _id, externalId_lt, logo, _id_nin, logo_gt, _id_gte, name_ne, _id_lte, type_ne, name_lt, param) {
  return {
          updatedAt_lte: updatedAt_lte,
          createdAt_gt: createdAt_gt,
          createdAt_nin: createdAt_nin,
          logo_ne: logo_ne,
          name_exists: name_exists,
          createdAt_ne: createdAt_ne,
          type_lte: type_lte,
          name_nin: name_nin,
          logo_in: logo_in,
          _id_ne: _id_ne,
          _id_in: _id_in,
          type_gte: type_gte,
          externalId_nin: externalId_nin,
          updatedAt_gt: updatedAt_gt,
          externalId_gt: externalId_gt,
          name_gt: name_gt,
          _id_exists: _id_exists,
          type_gt: type_gt,
          createdAt_gte: createdAt_gte,
          createdAt_lt: createdAt_lt,
          type_nin: type_nin,
          logo_exists: logo_exists,
          externalId_in: externalId_in,
          type_lt: type_lt,
          type_in: type_in,
          type: type_,
          externalId_ne: externalId_ne,
          name: name,
          logo_nin: logo_nin,
          updatedAt: updatedAt,
          updatedAt_exists: updatedAt_exists,
          externalId_lte: externalId_lte,
          createdAt_in: createdAt_in,
          updatedAt_nin: updatedAt_nin,
          externalId_gte: externalId_gte,
          logo_lt: logo_lt,
          _id_lt: _id_lt,
          externalId: externalId,
          updatedAt_in: updatedAt_in,
          updatedAt_lt: updatedAt_lt,
          logo_gte: logo_gte,
          logo_lte: logo_lte,
          _id_gt: _id_gt,
          name_lte: name_lte,
          updatedAt_gte: updatedAt_gte,
          createdAt_exists: createdAt_exists,
          OR: OR,
          updatedAt_ne: updatedAt_ne,
          createdAt: createdAt,
          name_gte: name_gte,
          externalId_exists: externalId_exists,
          type_exists: type_exists,
          AND: AND,
          createdAt_lte: createdAt_lte,
          name_in: name_in,
          _id: _id,
          externalId_lt: externalId_lt,
          logo: logo,
          _id_nin: _id_nin,
          logo_gt: logo_gt,
          _id_gte: _id_gte,
          name_ne: name_ne,
          _id_lte: _id_lte,
          type_ne: type_ne,
          name_lt: name_lt
        };
}

function makeDefaultVariables(param) {
  return {
          query: undefined
        };
}

var GetProductQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectProductQueryInput: serializeInputObjectProductQueryInput,
  serializeInputObjectProductTagScoresQueryInput: serializeInputObjectProductTagScoresQueryInput,
  serializeInputObjectProductAssociatedProductsQueryInput: serializeInputObjectProductAssociatedProductsQueryInput,
  serializeInputObjectProductMetaDataQueryInput: serializeInputObjectProductMetaDataQueryInput,
  serializeInputObjectGiftProviderQueryInput: serializeInputObjectGiftProviderQueryInput,
  makeVariables: makeVariables,
  makeInputObjectProductQueryInput: makeInputObjectProductQueryInput,
  makeInputObjectProductTagScoresQueryInput: makeInputObjectProductTagScoresQueryInput,
  makeInputObjectProductAssociatedProductsQueryInput: makeInputObjectProductAssociatedProductsQueryInput,
  makeInputObjectProductMetaDataQueryInput: makeInputObjectProductMetaDataQueryInput,
  makeInputObjectGiftProviderQueryInput: makeInputObjectGiftProviderQueryInput,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var GetProductQuery_refetchQueryDescription = include.refetchQueryDescription;

var GetProductQuery_use = include.use;

var GetProductQuery_useLazy = include.useLazy;

var GetProductQuery_useLazyWithVariables = include.useLazyWithVariables;

var GetProductQuery = {
  GetProductQuery_inner: GetProductQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectProductQueryInput: serializeInputObjectProductQueryInput,
  serializeInputObjectProductTagScoresQueryInput: serializeInputObjectProductTagScoresQueryInput,
  serializeInputObjectProductAssociatedProductsQueryInput: serializeInputObjectProductAssociatedProductsQueryInput,
  serializeInputObjectProductMetaDataQueryInput: serializeInputObjectProductMetaDataQueryInput,
  serializeInputObjectGiftProviderQueryInput: serializeInputObjectGiftProviderQueryInput,
  makeVariables: makeVariables,
  makeInputObjectProductQueryInput: makeInputObjectProductQueryInput,
  makeInputObjectProductTagScoresQueryInput: makeInputObjectProductTagScoresQueryInput,
  makeInputObjectProductAssociatedProductsQueryInput: makeInputObjectProductAssociatedProductsQueryInput,
  makeInputObjectProductMetaDataQueryInput: makeInputObjectProductMetaDataQueryInput,
  makeInputObjectGiftProviderQueryInput: makeInputObjectGiftProviderQueryInput,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: GetProductQuery_refetchQueryDescription,
  use: GetProductQuery_use,
  useLazy: GetProductQuery_useLazy,
  useLazyWithVariables: GetProductQuery_useLazyWithVariables
};

export {
  GetProductQuery ,
  
}
/* query Not a pure module */
