// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  query GetGifteesQuery($query: GifteeQueryInput)  {
    giftees(query: $query)  {
      __typename
      _id
      age
      createdAt
      isCustom
      isPendingAcceptance
      interests
      name
      picture
      relationship
      status
      sourceUser  {
        __typename
        _id
      }
      userId
    }
  }
`);

function parse(value) {
  var value$1 = value.giftees;
  return {
          giftees: value$1.map(function (value) {
                if (value == null) {
                  return ;
                }
                var value$1 = value._id;
                var value$2 = value.age;
                var value$3 = value.createdAt;
                var value$4 = value.isCustom;
                var value$5 = value.isPendingAcceptance;
                var value$6 = value.interests;
                var value$7 = value.name;
                var value$8 = value.picture;
                var value$9 = value.relationship;
                var tmp;
                if (value$9 == null) {
                  tmp = undefined;
                } else {
                  var tmp$1;
                  switch (value$9) {
                    case "AUNT" :
                        tmp$1 = "AUNT";
                        break;
                    case "BOYFRIEND" :
                        tmp$1 = "BOYFRIEND";
                        break;
                    case "BROTHER" :
                        tmp$1 = "BROTHER";
                        break;
                    case "BROTHER_IN_LAW" :
                        tmp$1 = "BROTHER_IN_LAW";
                        break;
                    case "COUSIN" :
                        tmp$1 = "COUSIN";
                        break;
                    case "FATHER" :
                        tmp$1 = "FATHER";
                        break;
                    case "FATHER_IN_LAW" :
                        tmp$1 = "FATHER_IN_LAW";
                        break;
                    case "FRIEND" :
                        tmp$1 = "FRIEND";
                        break;
                    case "GENERAL_FAMILY" :
                        tmp$1 = "GENERAL_FAMILY";
                        break;
                    case "GIRLFRIEND" :
                        tmp$1 = "GIRLFRIEND";
                        break;
                    case "GRANDMA" :
                        tmp$1 = "GRANDMA";
                        break;
                    case "GRANDPA" :
                        tmp$1 = "GRANDPA";
                        break;
                    case "HUSBAND" :
                        tmp$1 = "HUSBAND";
                        break;
                    case "MOTHER" :
                        tmp$1 = "MOTHER";
                        break;
                    case "MOTHER_IN_LAW" :
                        tmp$1 = "MOTHER_IN_LAW";
                        break;
                    case "OTHER" :
                        tmp$1 = "OTHER";
                        break;
                    case "SISTER" :
                        tmp$1 = "SISTER";
                        break;
                    case "SISTER_IN_LAW" :
                        tmp$1 = "SISTER_IN_LAW";
                        break;
                    case "UNCLE" :
                        tmp$1 = "UNCLE";
                        break;
                    case "WIFE" :
                        tmp$1 = "WIFE";
                        break;
                    default:
                      tmp$1 = {
                        NAME: "FutureAddedValue",
                        VAL: value$9
                      };
                  }
                  tmp = tmp$1;
                }
                var value$10 = value.status;
                var tmp$2;
                if (value$10 == null) {
                  tmp$2 = undefined;
                } else {
                  var tmp$3;
                  switch (value$10) {
                    case "ADDED" :
                        tmp$3 = "ADDED";
                        break;
                    case "APPROVED" :
                        tmp$3 = "APPROVED";
                        break;
                    case "PENDING" :
                        tmp$3 = "PENDING";
                        break;
                    default:
                      tmp$3 = {
                        NAME: "FutureAddedValue",
                        VAL: value$10
                      };
                  }
                  tmp$2 = tmp$3;
                }
                var value$11 = value.sourceUser;
                var tmp$4;
                if (value$11 == null) {
                  tmp$4 = undefined;
                } else {
                  var value$12 = value$11._id;
                  tmp$4 = {
                    __typename: value$11.__typename,
                    _id: !(value$12 == null) ? Caml_option.some(value$12) : undefined
                  };
                }
                var value$13 = value.userId;
                return {
                        __typename: value.__typename,
                        _id: !(value$1 == null) ? Caml_option.some(value$1) : undefined,
                        age: !(value$2 == null) ? value$2 : undefined,
                        createdAt: !(value$3 == null) ? Caml_option.some(value$3) : undefined,
                        isCustom: !(value$4 == null) ? value$4 : undefined,
                        isPendingAcceptance: !(value$5 == null) ? value$5 : undefined,
                        interests: !(value$6 == null) ? value$6.map(function (value) {
                                if (!(value == null)) {
                                  return value;
                                }
                                
                              }) : undefined,
                        name: !(value$7 == null) ? value$7 : undefined,
                        picture: !(value$8 == null) ? value$8 : undefined,
                        relationship: tmp,
                        status: tmp$2,
                        sourceUser: tmp$4,
                        userId: !(value$13 == null) ? Caml_option.some(value$13) : undefined
                      };
              })
        };
}

function serialize(value) {
  var value$1 = value.giftees;
  var giftees = value$1.map(function (value) {
        if (value === undefined) {
          return null;
        }
        var value$1 = value.userId;
        var userId = value$1 !== undefined ? Caml_option.valFromOption(value$1) : null;
        var value$2 = value.sourceUser;
        var sourceUser;
        if (value$2 !== undefined) {
          var value$3 = value$2._id;
          var _id = value$3 !== undefined ? Caml_option.valFromOption(value$3) : null;
          var value$4 = value$2.__typename;
          sourceUser = {
            __typename: value$4,
            _id: _id
          };
        } else {
          sourceUser = null;
        }
        var value$5 = value.status;
        var status = value$5 !== undefined ? (
            typeof value$5 === "object" ? value$5.VAL : (
                value$5 === "ADDED" ? "ADDED" : (
                    value$5 === "PENDING" ? "PENDING" : "APPROVED"
                  )
              )
          ) : null;
        var value$6 = value.relationship;
        var relationship = value$6 !== undefined ? (
            typeof value$6 === "object" ? value$6.VAL : (
                value$6 === "FRIEND" ? "FRIEND" : (
                    value$6 === "SISTER" ? "SISTER" : (
                        value$6 === "GRANDMA" ? "GRANDMA" : (
                            value$6 === "GRANDPA" ? "GRANDPA" : (
                                value$6 === "MOTHER" ? "MOTHER" : (
                                    value$6 === "COUSIN" ? "COUSIN" : (
                                        value$6 === "GIRLFRIEND" ? "GIRLFRIEND" : (
                                            value$6 === "FATHER_IN_LAW" ? "FATHER_IN_LAW" : (
                                                value$6 === "MOTHER_IN_LAW" ? "MOTHER_IN_LAW" : (
                                                    value$6 === "FATHER" ? "FATHER" : (
                                                        value$6 === "BROTHER_IN_LAW" ? "BROTHER_IN_LAW" : (
                                                            value$6 === "HUSBAND" ? "HUSBAND" : (
                                                                value$6 === "UNCLE" ? "UNCLE" : (
                                                                    value$6 === "AUNT" ? "AUNT" : (
                                                                        value$6 === "SISTER_IN_LAW" ? "SISTER_IN_LAW" : (
                                                                            value$6 === "OTHER" ? "OTHER" : (
                                                                                value$6 === "BROTHER" ? "BROTHER" : (
                                                                                    value$6 === "WIFE" ? "WIFE" : (
                                                                                        value$6 === "GENERAL_FAMILY" ? "GENERAL_FAMILY" : "BOYFRIEND"
                                                                                      )
                                                                                  )
                                                                              )
                                                                          )
                                                                      )
                                                                  )
                                                              )
                                                          )
                                                      )
                                                  )
                                              )
                                          )
                                      )
                                  )
                              )
                          )
                      )
                  )
              )
          ) : null;
        var value$7 = value.picture;
        var picture = value$7 !== undefined ? value$7 : null;
        var value$8 = value.name;
        var name = value$8 !== undefined ? value$8 : null;
        var value$9 = value.interests;
        var interests = value$9 !== undefined ? value$9.map(function (value) {
                if (value !== undefined) {
                  return value;
                } else {
                  return null;
                }
              }) : null;
        var value$10 = value.isPendingAcceptance;
        var isPendingAcceptance = value$10 !== undefined ? value$10 : null;
        var value$11 = value.isCustom;
        var isCustom = value$11 !== undefined ? value$11 : null;
        var value$12 = value.createdAt;
        var createdAt = value$12 !== undefined ? Caml_option.valFromOption(value$12) : null;
        var value$13 = value.age;
        var age = value$13 !== undefined ? value$13 : null;
        var value$14 = value._id;
        var _id$1 = value$14 !== undefined ? Caml_option.valFromOption(value$14) : null;
        var value$15 = value.__typename;
        return {
                __typename: value$15,
                _id: _id$1,
                age: age,
                createdAt: createdAt,
                isCustom: isCustom,
                isPendingAcceptance: isPendingAcceptance,
                interests: interests,
                name: name,
                picture: picture,
                relationship: relationship,
                status: status,
                sourceUser: sourceUser,
                userId: userId
              };
      });
  return {
          giftees: giftees
        };
}

function serializeInputObjectGifteeQueryInput(inp) {
  var a = inp.age_ne;
  var a$1 = inp.interests_exists;
  var a$2 = inp.createdAt_gte;
  var a$3 = inp.picture_exists;
  var a$4 = inp.age_lt;
  var a$5 = inp._id;
  var a$6 = inp.picture_in;
  var a$7 = inp.AND;
  var a$8 = inp.updatedAt_gt;
  var a$9 = inp.updatedAt_lte;
  var a$10 = inp.status_gte;
  var a$11 = inp.sourceUser_exists;
  var a$12 = inp._id_exists;
  var a$13 = inp.createdAt_ne;
  var a$14 = inp.isCustom_exists;
  var a$15 = inp.updatedAt_gte;
  var a$16 = inp.status_lte;
  var a$17 = inp.name_gte;
  var a$18 = inp.createdAt_exists;
  var a$19 = inp.age_nin;
  var a$20 = inp.picture;
  var a$21 = inp.interests_nin;
  var a$22 = inp.interests_in;
  var a$23 = inp.relationship_gte;
  var a$24 = inp.relationship_lte;
  var a$25 = inp.age_exists;
  var a$26 = inp.name;
  var a$27 = inp.name_lte;
  var a$28 = inp.userId_in;
  var a$29 = inp.isPendingAcceptance;
  var a$30 = inp.relationship_ne;
  var a$31 = inp.age_in;
  var a$32 = inp.name_nin;
  var a$33 = inp.picture_gte;
  var a$34 = inp.status_lt;
  var a$35 = inp.age;
  var a$36 = inp.updatedAt_exists;
  var a$37 = inp.userId_nin;
  var a$38 = inp._id_gt;
  var a$39 = inp.userId_ne;
  var a$40 = inp.status_exists;
  var a$41 = inp.sourceUser;
  var a$42 = inp.userId_gt;
  var a$43 = inp._id_gte;
  var a$44 = inp.createdAt_in;
  var a$45 = inp.updatedAt_ne;
  var a$46 = inp.isPendingAcceptance_ne;
  var a$47 = inp.relationship_exists;
  var a$48 = inp._id_lte;
  var a$49 = inp._id_ne;
  var a$50 = inp.isCustom;
  var a$51 = inp._id_nin;
  var a$52 = inp.status_ne;
  var a$53 = inp.createdAt_nin;
  var a$54 = inp.status_nin;
  var a$55 = inp.userId_lte;
  var a$56 = inp.name_ne;
  var a$57 = inp.picture_lte;
  var a$58 = inp.createdAt_lt;
  var a$59 = inp.status_in;
  var a$60 = inp.updatedAt_in;
  var a$61 = inp._id_in;
  var a$62 = inp._id_lt;
  var a$63 = inp.createdAt;
  var a$64 = inp.userId_exists;
  var a$65 = inp.age_gte;
  var a$66 = inp.picture_lt;
  var a$67 = inp.updatedAt;
  var a$68 = inp.name_lt;
  var a$69 = inp.status_gt;
  var a$70 = inp.relationship_nin;
  var a$71 = inp.age_lte;
  var a$72 = inp.relationship_lt;
  var a$73 = inp.userId_gte;
  var a$74 = inp.status;
  var a$75 = inp.createdAt_lte;
  var a$76 = inp.name_in;
  var a$77 = inp.relationship_in;
  var a$78 = inp.isCustom_ne;
  var a$79 = inp.isPendingAcceptance_exists;
  var a$80 = inp.OR;
  var a$81 = inp.updatedAt_lt;
  var a$82 = inp.createdAt_gt;
  var a$83 = inp.relationship_gt;
  var a$84 = inp.name_gt;
  var a$85 = inp.interests;
  var a$86 = inp.age_gt;
  var a$87 = inp.picture_nin;
  var a$88 = inp.picture_ne;
  var a$89 = inp.relationship;
  var a$90 = inp.picture_gt;
  var a$91 = inp.name_exists;
  var a$92 = inp.userId_lt;
  var a$93 = inp.userId;
  var a$94 = inp.updatedAt_nin;
  return {
          age_ne: a !== undefined ? a : undefined,
          interests_exists: a$1 !== undefined ? a$1 : undefined,
          createdAt_gte: a$2 !== undefined ? Caml_option.valFromOption(a$2) : undefined,
          picture_exists: a$3 !== undefined ? a$3 : undefined,
          age_lt: a$4 !== undefined ? a$4 : undefined,
          _id: a$5 !== undefined ? Caml_option.valFromOption(a$5) : undefined,
          picture_in: a$6 !== undefined ? a$6.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          AND: a$7 !== undefined ? a$7.map(serializeInputObjectGifteeQueryInput) : undefined,
          updatedAt_gt: a$8 !== undefined ? Caml_option.valFromOption(a$8) : undefined,
          updatedAt_lte: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          status_gte: a$10 !== undefined ? (
              a$10 === "ADDED" ? "ADDED" : (
                  a$10 === "PENDING" ? "PENDING" : "APPROVED"
                )
            ) : undefined,
          sourceUser_exists: a$11 !== undefined ? a$11 : undefined,
          _id_exists: a$12 !== undefined ? a$12 : undefined,
          createdAt_ne: a$13 !== undefined ? Caml_option.valFromOption(a$13) : undefined,
          isCustom_exists: a$14 !== undefined ? a$14 : undefined,
          updatedAt_gte: a$15 !== undefined ? Caml_option.valFromOption(a$15) : undefined,
          status_lte: a$16 !== undefined ? (
              a$16 === "ADDED" ? "ADDED" : (
                  a$16 === "PENDING" ? "PENDING" : "APPROVED"
                )
            ) : undefined,
          name_gte: a$17 !== undefined ? a$17 : undefined,
          createdAt_exists: a$18 !== undefined ? a$18 : undefined,
          age_nin: a$19 !== undefined ? a$19.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          picture: a$20 !== undefined ? a$20 : undefined,
          interests_nin: a$21 !== undefined ? a$21.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          interests_in: a$22 !== undefined ? a$22.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          relationship_gte: a$23 !== undefined ? (
              a$23 === "FRIEND" ? "FRIEND" : (
                  a$23 === "SISTER" ? "SISTER" : (
                      a$23 === "GRANDMA" ? "GRANDMA" : (
                          a$23 === "GRANDPA" ? "GRANDPA" : (
                              a$23 === "MOTHER" ? "MOTHER" : (
                                  a$23 === "COUSIN" ? "COUSIN" : (
                                      a$23 === "GIRLFRIEND" ? "GIRLFRIEND" : (
                                          a$23 === "FATHER_IN_LAW" ? "FATHER_IN_LAW" : (
                                              a$23 === "MOTHER_IN_LAW" ? "MOTHER_IN_LAW" : (
                                                  a$23 === "FATHER" ? "FATHER" : (
                                                      a$23 === "BROTHER_IN_LAW" ? "BROTHER_IN_LAW" : (
                                                          a$23 === "HUSBAND" ? "HUSBAND" : (
                                                              a$23 === "UNCLE" ? "UNCLE" : (
                                                                  a$23 === "AUNT" ? "AUNT" : (
                                                                      a$23 === "SISTER_IN_LAW" ? "SISTER_IN_LAW" : (
                                                                          a$23 === "OTHER" ? "OTHER" : (
                                                                              a$23 === "BROTHER" ? "BROTHER" : (
                                                                                  a$23 === "WIFE" ? "WIFE" : (
                                                                                      a$23 === "GENERAL_FAMILY" ? "GENERAL_FAMILY" : "BOYFRIEND"
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          relationship_lte: a$24 !== undefined ? (
              a$24 === "FRIEND" ? "FRIEND" : (
                  a$24 === "SISTER" ? "SISTER" : (
                      a$24 === "GRANDMA" ? "GRANDMA" : (
                          a$24 === "GRANDPA" ? "GRANDPA" : (
                              a$24 === "MOTHER" ? "MOTHER" : (
                                  a$24 === "COUSIN" ? "COUSIN" : (
                                      a$24 === "GIRLFRIEND" ? "GIRLFRIEND" : (
                                          a$24 === "FATHER_IN_LAW" ? "FATHER_IN_LAW" : (
                                              a$24 === "MOTHER_IN_LAW" ? "MOTHER_IN_LAW" : (
                                                  a$24 === "FATHER" ? "FATHER" : (
                                                      a$24 === "BROTHER_IN_LAW" ? "BROTHER_IN_LAW" : (
                                                          a$24 === "HUSBAND" ? "HUSBAND" : (
                                                              a$24 === "UNCLE" ? "UNCLE" : (
                                                                  a$24 === "AUNT" ? "AUNT" : (
                                                                      a$24 === "SISTER_IN_LAW" ? "SISTER_IN_LAW" : (
                                                                          a$24 === "OTHER" ? "OTHER" : (
                                                                              a$24 === "BROTHER" ? "BROTHER" : (
                                                                                  a$24 === "WIFE" ? "WIFE" : (
                                                                                      a$24 === "GENERAL_FAMILY" ? "GENERAL_FAMILY" : "BOYFRIEND"
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          age_exists: a$25 !== undefined ? a$25 : undefined,
          name: a$26 !== undefined ? a$26 : undefined,
          name_lte: a$27 !== undefined ? a$27 : undefined,
          userId_in: a$28 !== undefined ? a$28.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          isPendingAcceptance: a$29 !== undefined ? a$29 : undefined,
          relationship_ne: a$30 !== undefined ? (
              a$30 === "FRIEND" ? "FRIEND" : (
                  a$30 === "SISTER" ? "SISTER" : (
                      a$30 === "GRANDMA" ? "GRANDMA" : (
                          a$30 === "GRANDPA" ? "GRANDPA" : (
                              a$30 === "MOTHER" ? "MOTHER" : (
                                  a$30 === "COUSIN" ? "COUSIN" : (
                                      a$30 === "GIRLFRIEND" ? "GIRLFRIEND" : (
                                          a$30 === "FATHER_IN_LAW" ? "FATHER_IN_LAW" : (
                                              a$30 === "MOTHER_IN_LAW" ? "MOTHER_IN_LAW" : (
                                                  a$30 === "FATHER" ? "FATHER" : (
                                                      a$30 === "BROTHER_IN_LAW" ? "BROTHER_IN_LAW" : (
                                                          a$30 === "HUSBAND" ? "HUSBAND" : (
                                                              a$30 === "UNCLE" ? "UNCLE" : (
                                                                  a$30 === "AUNT" ? "AUNT" : (
                                                                      a$30 === "SISTER_IN_LAW" ? "SISTER_IN_LAW" : (
                                                                          a$30 === "OTHER" ? "OTHER" : (
                                                                              a$30 === "BROTHER" ? "BROTHER" : (
                                                                                  a$30 === "WIFE" ? "WIFE" : (
                                                                                      a$30 === "GENERAL_FAMILY" ? "GENERAL_FAMILY" : "BOYFRIEND"
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          age_in: a$31 !== undefined ? a$31.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          name_nin: a$32 !== undefined ? a$32.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          picture_gte: a$33 !== undefined ? a$33 : undefined,
          status_lt: a$34 !== undefined ? (
              a$34 === "ADDED" ? "ADDED" : (
                  a$34 === "PENDING" ? "PENDING" : "APPROVED"
                )
            ) : undefined,
          age: a$35 !== undefined ? a$35 : undefined,
          updatedAt_exists: a$36 !== undefined ? a$36 : undefined,
          userId_nin: a$37 !== undefined ? a$37.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          _id_gt: a$38 !== undefined ? Caml_option.valFromOption(a$38) : undefined,
          userId_ne: a$39 !== undefined ? Caml_option.valFromOption(a$39) : undefined,
          status_exists: a$40 !== undefined ? a$40 : undefined,
          sourceUser: a$41 !== undefined ? serializeInputObjectUserQueryInput(a$41) : undefined,
          userId_gt: a$42 !== undefined ? Caml_option.valFromOption(a$42) : undefined,
          _id_gte: a$43 !== undefined ? Caml_option.valFromOption(a$43) : undefined,
          createdAt_in: a$44 !== undefined ? a$44.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          updatedAt_ne: a$45 !== undefined ? Caml_option.valFromOption(a$45) : undefined,
          isPendingAcceptance_ne: a$46 !== undefined ? a$46 : undefined,
          relationship_exists: a$47 !== undefined ? a$47 : undefined,
          _id_lte: a$48 !== undefined ? Caml_option.valFromOption(a$48) : undefined,
          _id_ne: a$49 !== undefined ? Caml_option.valFromOption(a$49) : undefined,
          isCustom: a$50 !== undefined ? a$50 : undefined,
          _id_nin: a$51 !== undefined ? a$51.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          status_ne: a$52 !== undefined ? (
              a$52 === "ADDED" ? "ADDED" : (
                  a$52 === "PENDING" ? "PENDING" : "APPROVED"
                )
            ) : undefined,
          createdAt_nin: a$53 !== undefined ? a$53.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          status_nin: a$54 !== undefined ? a$54.map(function (b) {
                  if (b !== undefined) {
                    if (b === "ADDED") {
                      return "ADDED";
                    } else if (b === "PENDING") {
                      return "PENDING";
                    } else {
                      return "APPROVED";
                    }
                  }
                  
                }) : undefined,
          userId_lte: a$55 !== undefined ? Caml_option.valFromOption(a$55) : undefined,
          name_ne: a$56 !== undefined ? a$56 : undefined,
          picture_lte: a$57 !== undefined ? a$57 : undefined,
          createdAt_lt: a$58 !== undefined ? Caml_option.valFromOption(a$58) : undefined,
          status_in: a$59 !== undefined ? a$59.map(function (b) {
                  if (b !== undefined) {
                    if (b === "ADDED") {
                      return "ADDED";
                    } else if (b === "PENDING") {
                      return "PENDING";
                    } else {
                      return "APPROVED";
                    }
                  }
                  
                }) : undefined,
          updatedAt_in: a$60 !== undefined ? a$60.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          _id_in: a$61 !== undefined ? a$61.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          _id_lt: a$62 !== undefined ? Caml_option.valFromOption(a$62) : undefined,
          createdAt: a$63 !== undefined ? Caml_option.valFromOption(a$63) : undefined,
          userId_exists: a$64 !== undefined ? a$64 : undefined,
          age_gte: a$65 !== undefined ? a$65 : undefined,
          picture_lt: a$66 !== undefined ? a$66 : undefined,
          updatedAt: a$67 !== undefined ? Caml_option.valFromOption(a$67) : undefined,
          name_lt: a$68 !== undefined ? a$68 : undefined,
          status_gt: a$69 !== undefined ? (
              a$69 === "ADDED" ? "ADDED" : (
                  a$69 === "PENDING" ? "PENDING" : "APPROVED"
                )
            ) : undefined,
          relationship_nin: a$70 !== undefined ? a$70.map(function (b) {
                  if (b !== undefined) {
                    if (b === "FRIEND") {
                      return "FRIEND";
                    } else if (b === "SISTER") {
                      return "SISTER";
                    } else if (b === "GRANDMA") {
                      return "GRANDMA";
                    } else if (b === "GRANDPA") {
                      return "GRANDPA";
                    } else if (b === "MOTHER") {
                      return "MOTHER";
                    } else if (b === "COUSIN") {
                      return "COUSIN";
                    } else if (b === "GIRLFRIEND") {
                      return "GIRLFRIEND";
                    } else if (b === "FATHER_IN_LAW") {
                      return "FATHER_IN_LAW";
                    } else if (b === "MOTHER_IN_LAW") {
                      return "MOTHER_IN_LAW";
                    } else if (b === "FATHER") {
                      return "FATHER";
                    } else if (b === "BROTHER_IN_LAW") {
                      return "BROTHER_IN_LAW";
                    } else if (b === "HUSBAND") {
                      return "HUSBAND";
                    } else if (b === "UNCLE") {
                      return "UNCLE";
                    } else if (b === "AUNT") {
                      return "AUNT";
                    } else if (b === "SISTER_IN_LAW") {
                      return "SISTER_IN_LAW";
                    } else if (b === "OTHER") {
                      return "OTHER";
                    } else if (b === "BROTHER") {
                      return "BROTHER";
                    } else if (b === "WIFE") {
                      return "WIFE";
                    } else if (b === "GENERAL_FAMILY") {
                      return "GENERAL_FAMILY";
                    } else {
                      return "BOYFRIEND";
                    }
                  }
                  
                }) : undefined,
          age_lte: a$71 !== undefined ? a$71 : undefined,
          relationship_lt: a$72 !== undefined ? (
              a$72 === "FRIEND" ? "FRIEND" : (
                  a$72 === "SISTER" ? "SISTER" : (
                      a$72 === "GRANDMA" ? "GRANDMA" : (
                          a$72 === "GRANDPA" ? "GRANDPA" : (
                              a$72 === "MOTHER" ? "MOTHER" : (
                                  a$72 === "COUSIN" ? "COUSIN" : (
                                      a$72 === "GIRLFRIEND" ? "GIRLFRIEND" : (
                                          a$72 === "FATHER_IN_LAW" ? "FATHER_IN_LAW" : (
                                              a$72 === "MOTHER_IN_LAW" ? "MOTHER_IN_LAW" : (
                                                  a$72 === "FATHER" ? "FATHER" : (
                                                      a$72 === "BROTHER_IN_LAW" ? "BROTHER_IN_LAW" : (
                                                          a$72 === "HUSBAND" ? "HUSBAND" : (
                                                              a$72 === "UNCLE" ? "UNCLE" : (
                                                                  a$72 === "AUNT" ? "AUNT" : (
                                                                      a$72 === "SISTER_IN_LAW" ? "SISTER_IN_LAW" : (
                                                                          a$72 === "OTHER" ? "OTHER" : (
                                                                              a$72 === "BROTHER" ? "BROTHER" : (
                                                                                  a$72 === "WIFE" ? "WIFE" : (
                                                                                      a$72 === "GENERAL_FAMILY" ? "GENERAL_FAMILY" : "BOYFRIEND"
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          userId_gte: a$73 !== undefined ? Caml_option.valFromOption(a$73) : undefined,
          status: a$74 !== undefined ? (
              a$74 === "ADDED" ? "ADDED" : (
                  a$74 === "PENDING" ? "PENDING" : "APPROVED"
                )
            ) : undefined,
          createdAt_lte: a$75 !== undefined ? Caml_option.valFromOption(a$75) : undefined,
          name_in: a$76 !== undefined ? a$76.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          relationship_in: a$77 !== undefined ? a$77.map(function (b) {
                  if (b !== undefined) {
                    if (b === "FRIEND") {
                      return "FRIEND";
                    } else if (b === "SISTER") {
                      return "SISTER";
                    } else if (b === "GRANDMA") {
                      return "GRANDMA";
                    } else if (b === "GRANDPA") {
                      return "GRANDPA";
                    } else if (b === "MOTHER") {
                      return "MOTHER";
                    } else if (b === "COUSIN") {
                      return "COUSIN";
                    } else if (b === "GIRLFRIEND") {
                      return "GIRLFRIEND";
                    } else if (b === "FATHER_IN_LAW") {
                      return "FATHER_IN_LAW";
                    } else if (b === "MOTHER_IN_LAW") {
                      return "MOTHER_IN_LAW";
                    } else if (b === "FATHER") {
                      return "FATHER";
                    } else if (b === "BROTHER_IN_LAW") {
                      return "BROTHER_IN_LAW";
                    } else if (b === "HUSBAND") {
                      return "HUSBAND";
                    } else if (b === "UNCLE") {
                      return "UNCLE";
                    } else if (b === "AUNT") {
                      return "AUNT";
                    } else if (b === "SISTER_IN_LAW") {
                      return "SISTER_IN_LAW";
                    } else if (b === "OTHER") {
                      return "OTHER";
                    } else if (b === "BROTHER") {
                      return "BROTHER";
                    } else if (b === "WIFE") {
                      return "WIFE";
                    } else if (b === "GENERAL_FAMILY") {
                      return "GENERAL_FAMILY";
                    } else {
                      return "BOYFRIEND";
                    }
                  }
                  
                }) : undefined,
          isCustom_ne: a$78 !== undefined ? a$78 : undefined,
          isPendingAcceptance_exists: a$79 !== undefined ? a$79 : undefined,
          OR: a$80 !== undefined ? a$80.map(serializeInputObjectGifteeQueryInput) : undefined,
          updatedAt_lt: a$81 !== undefined ? Caml_option.valFromOption(a$81) : undefined,
          createdAt_gt: a$82 !== undefined ? Caml_option.valFromOption(a$82) : undefined,
          relationship_gt: a$83 !== undefined ? (
              a$83 === "FRIEND" ? "FRIEND" : (
                  a$83 === "SISTER" ? "SISTER" : (
                      a$83 === "GRANDMA" ? "GRANDMA" : (
                          a$83 === "GRANDPA" ? "GRANDPA" : (
                              a$83 === "MOTHER" ? "MOTHER" : (
                                  a$83 === "COUSIN" ? "COUSIN" : (
                                      a$83 === "GIRLFRIEND" ? "GIRLFRIEND" : (
                                          a$83 === "FATHER_IN_LAW" ? "FATHER_IN_LAW" : (
                                              a$83 === "MOTHER_IN_LAW" ? "MOTHER_IN_LAW" : (
                                                  a$83 === "FATHER" ? "FATHER" : (
                                                      a$83 === "BROTHER_IN_LAW" ? "BROTHER_IN_LAW" : (
                                                          a$83 === "HUSBAND" ? "HUSBAND" : (
                                                              a$83 === "UNCLE" ? "UNCLE" : (
                                                                  a$83 === "AUNT" ? "AUNT" : (
                                                                      a$83 === "SISTER_IN_LAW" ? "SISTER_IN_LAW" : (
                                                                          a$83 === "OTHER" ? "OTHER" : (
                                                                              a$83 === "BROTHER" ? "BROTHER" : (
                                                                                  a$83 === "WIFE" ? "WIFE" : (
                                                                                      a$83 === "GENERAL_FAMILY" ? "GENERAL_FAMILY" : "BOYFRIEND"
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          name_gt: a$84 !== undefined ? a$84 : undefined,
          interests: a$85 !== undefined ? a$85.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          age_gt: a$86 !== undefined ? a$86 : undefined,
          picture_nin: a$87 !== undefined ? a$87.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          picture_ne: a$88 !== undefined ? a$88 : undefined,
          relationship: a$89 !== undefined ? (
              a$89 === "FRIEND" ? "FRIEND" : (
                  a$89 === "SISTER" ? "SISTER" : (
                      a$89 === "GRANDMA" ? "GRANDMA" : (
                          a$89 === "GRANDPA" ? "GRANDPA" : (
                              a$89 === "MOTHER" ? "MOTHER" : (
                                  a$89 === "COUSIN" ? "COUSIN" : (
                                      a$89 === "GIRLFRIEND" ? "GIRLFRIEND" : (
                                          a$89 === "FATHER_IN_LAW" ? "FATHER_IN_LAW" : (
                                              a$89 === "MOTHER_IN_LAW" ? "MOTHER_IN_LAW" : (
                                                  a$89 === "FATHER" ? "FATHER" : (
                                                      a$89 === "BROTHER_IN_LAW" ? "BROTHER_IN_LAW" : (
                                                          a$89 === "HUSBAND" ? "HUSBAND" : (
                                                              a$89 === "UNCLE" ? "UNCLE" : (
                                                                  a$89 === "AUNT" ? "AUNT" : (
                                                                      a$89 === "SISTER_IN_LAW" ? "SISTER_IN_LAW" : (
                                                                          a$89 === "OTHER" ? "OTHER" : (
                                                                              a$89 === "BROTHER" ? "BROTHER" : (
                                                                                  a$89 === "WIFE" ? "WIFE" : (
                                                                                      a$89 === "GENERAL_FAMILY" ? "GENERAL_FAMILY" : "BOYFRIEND"
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          picture_gt: a$90 !== undefined ? a$90 : undefined,
          name_exists: a$91 !== undefined ? a$91 : undefined,
          userId_lt: a$92 !== undefined ? Caml_option.valFromOption(a$92) : undefined,
          userId: a$93 !== undefined ? Caml_option.valFromOption(a$93) : undefined,
          updatedAt_nin: a$94 !== undefined ? a$94.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined
        };
}

function serializeInputObjectUserQueryInput(inp) {
  var a = inp._id_nin;
  var a$1 = inp.phone_gt;
  var a$2 = inp.phone_lte;
  var a$3 = inp.lastLogin_exists;
  var a$4 = inp.lastName_in;
  var a$5 = inp.gender_exists;
  var a$6 = inp.gender_lte;
  var a$7 = inp.lastName_gt;
  var a$8 = inp.lastLogin_ne;
  var a$9 = inp._id_lt;
  var a$10 = inp.lastLogin_gte;
  var a$11 = inp._id;
  var a$12 = inp.createdAt_nin;
  var a$13 = inp.gender_gte;
  var a$14 = inp.finishedOnboarding_exists;
  var a$15 = inp.birthday;
  var a$16 = inp.authId_ne;
  var a$17 = inp.sentAbandonedOnboardingEmail_exists;
  var a$18 = inp.authId_in;
  var a$19 = inp.OR;
  var a$20 = inp.picture;
  var a$21 = inp.phone_exists;
  var a$22 = inp.email_gte;
  var a$23 = inp.finishedOnboarding;
  var a$24 = inp.finishedOnboarding_ne;
  var a$25 = inp.updatedAt_in;
  var a$26 = inp.lastLogin_lte;
  var a$27 = inp.lastName_lt;
  var a$28 = inp.lastLogin;
  var a$29 = inp.gender_lt;
  var a$30 = inp.updatedAt_ne;
  var a$31 = inp.sentAbandonedOnboardingEmail;
  var a$32 = inp.lastLogin_in;
  var a$33 = inp.createdAt;
  var a$34 = inp.createdAt_gt;
  var a$35 = inp.picture_ne;
  var a$36 = inp.AND;
  var a$37 = inp._id_exists;
  var a$38 = inp.birthday_nin;
  var a$39 = inp.gender;
  var a$40 = inp.name_lte;
  var a$41 = inp.email_exists;
  var a$42 = inp.name_ne;
  var a$43 = inp.phone_ne;
  var a$44 = inp.email_in;
  var a$45 = inp.createdAt_in;
  var a$46 = inp.phone_gte;
  var a$47 = inp.updatedAt_gt;
  var a$48 = inp.firstName_exists;
  var a$49 = inp.authId;
  var a$50 = inp.updatedAt_nin;
  var a$51 = inp.updatedAt_lt;
  var a$52 = inp.lastName_ne;
  var a$53 = inp.picture_lt;
  var a$54 = inp.firstName_gte;
  var a$55 = inp.firstName_gt;
  var a$56 = inp._id_in;
  var a$57 = inp.lastLogin_nin;
  var a$58 = inp.birthday_in;
  var a$59 = inp.authId_gte;
  var a$60 = inp.createdAt_gte;
  var a$61 = inp.gender_gt;
  var a$62 = inp.lastName_exists;
  var a$63 = inp.updatedAt_exists;
  var a$64 = inp.createdAt_exists;
  var a$65 = inp.name_exists;
  var a$66 = inp.address;
  var a$67 = inp.birthday_gte;
  var a$68 = inp.name;
  var a$69 = inp.birthday_gt;
  var a$70 = inp.picture_nin;
  var a$71 = inp.lastName_nin;
  var a$72 = inp.phone_in;
  var a$73 = inp.picture_gte;
  var a$74 = inp._id_lte;
  var a$75 = inp.firstName_nin;
  var a$76 = inp.sentAbandonedOnboardingEmail_ne;
  var a$77 = inp.phone_lt;
  var a$78 = inp.birthday_ne;
  var a$79 = inp.name_nin;
  var a$80 = inp.firstName_lt;
  var a$81 = inp.phone;
  var a$82 = inp.email_ne;
  var a$83 = inp._id_gte;
  var a$84 = inp.email;
  var a$85 = inp.birthday_lt;
  var a$86 = inp.updatedAt_lte;
  var a$87 = inp.address_exists;
  var a$88 = inp.updatedAt_gte;
  var a$89 = inp.createdAt_ne;
  var a$90 = inp.lastName_lte;
  var a$91 = inp.birthday_exists;
  var a$92 = inp.authId_lt;
  var a$93 = inp.picture_exists;
  var a$94 = inp.updatedAt;
  var a$95 = inp.firstName_in;
  var a$96 = inp.name_gte;
  var a$97 = inp.picture_in;
  var a$98 = inp.phone_nin;
  var a$99 = inp.createdAt_lte;
  var a$100 = inp._id_ne;
  var a$101 = inp.firstName_lte;
  var a$102 = inp.authId_nin;
  var a$103 = inp.firstName_ne;
  var a$104 = inp.gender_nin;
  var a$105 = inp.picture_lte;
  var a$106 = inp.authId_exists;
  var a$107 = inp._id_gt;
  var a$108 = inp.lastLogin_lt;
  var a$109 = inp.gender_in;
  var a$110 = inp.lastName_gte;
  var a$111 = inp.authId_gt;
  var a$112 = inp.birthday_lte;
  var a$113 = inp.createdAt_lt;
  var a$114 = inp.firstName;
  var a$115 = inp.picture_gt;
  var a$116 = inp.email_lt;
  var a$117 = inp.lastLogin_gt;
  var a$118 = inp.lastName;
  var a$119 = inp.email_nin;
  var a$120 = inp.email_gt;
  var a$121 = inp.name_gt;
  var a$122 = inp.name_lt;
  var a$123 = inp.authId_lte;
  var a$124 = inp.gender_ne;
  var a$125 = inp.email_lte;
  var a$126 = inp.name_in;
  return {
          _id_nin: a !== undefined ? a.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          phone_gt: a$1 !== undefined ? a$1 : undefined,
          phone_lte: a$2 !== undefined ? a$2 : undefined,
          lastLogin_exists: a$3 !== undefined ? a$3 : undefined,
          lastName_in: a$4 !== undefined ? a$4.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          gender_exists: a$5 !== undefined ? a$5 : undefined,
          gender_lte: a$6 !== undefined ? a$6 : undefined,
          lastName_gt: a$7 !== undefined ? a$7 : undefined,
          lastLogin_ne: a$8 !== undefined ? Caml_option.valFromOption(a$8) : undefined,
          _id_lt: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          lastLogin_gte: a$10 !== undefined ? Caml_option.valFromOption(a$10) : undefined,
          _id: a$11 !== undefined ? Caml_option.valFromOption(a$11) : undefined,
          createdAt_nin: a$12 !== undefined ? a$12.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          gender_gte: a$13 !== undefined ? a$13 : undefined,
          finishedOnboarding_exists: a$14 !== undefined ? a$14 : undefined,
          birthday: a$15 !== undefined ? Caml_option.valFromOption(a$15) : undefined,
          authId_ne: a$16 !== undefined ? a$16 : undefined,
          sentAbandonedOnboardingEmail_exists: a$17 !== undefined ? a$17 : undefined,
          authId_in: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          OR: a$19 !== undefined ? a$19.map(serializeInputObjectUserQueryInput) : undefined,
          picture: a$20 !== undefined ? a$20 : undefined,
          phone_exists: a$21 !== undefined ? a$21 : undefined,
          email_gte: a$22 !== undefined ? a$22 : undefined,
          finishedOnboarding: a$23 !== undefined ? a$23 : undefined,
          finishedOnboarding_ne: a$24 !== undefined ? a$24 : undefined,
          updatedAt_in: a$25 !== undefined ? a$25.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          lastLogin_lte: a$26 !== undefined ? Caml_option.valFromOption(a$26) : undefined,
          lastName_lt: a$27 !== undefined ? a$27 : undefined,
          lastLogin: a$28 !== undefined ? Caml_option.valFromOption(a$28) : undefined,
          gender_lt: a$29 !== undefined ? a$29 : undefined,
          updatedAt_ne: a$30 !== undefined ? Caml_option.valFromOption(a$30) : undefined,
          sentAbandonedOnboardingEmail: a$31 !== undefined ? a$31 : undefined,
          lastLogin_in: a$32 !== undefined ? a$32.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          createdAt: a$33 !== undefined ? Caml_option.valFromOption(a$33) : undefined,
          createdAt_gt: a$34 !== undefined ? Caml_option.valFromOption(a$34) : undefined,
          picture_ne: a$35 !== undefined ? a$35 : undefined,
          AND: a$36 !== undefined ? a$36.map(serializeInputObjectUserQueryInput) : undefined,
          _id_exists: a$37 !== undefined ? a$37 : undefined,
          birthday_nin: a$38 !== undefined ? a$38.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          gender: a$39 !== undefined ? a$39 : undefined,
          name_lte: a$40 !== undefined ? a$40 : undefined,
          email_exists: a$41 !== undefined ? a$41 : undefined,
          name_ne: a$42 !== undefined ? a$42 : undefined,
          phone_ne: a$43 !== undefined ? a$43 : undefined,
          email_in: a$44 !== undefined ? a$44.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          createdAt_in: a$45 !== undefined ? a$45.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          phone_gte: a$46 !== undefined ? a$46 : undefined,
          updatedAt_gt: a$47 !== undefined ? Caml_option.valFromOption(a$47) : undefined,
          firstName_exists: a$48 !== undefined ? a$48 : undefined,
          authId: a$49 !== undefined ? a$49 : undefined,
          updatedAt_nin: a$50 !== undefined ? a$50.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          updatedAt_lt: a$51 !== undefined ? Caml_option.valFromOption(a$51) : undefined,
          lastName_ne: a$52 !== undefined ? a$52 : undefined,
          picture_lt: a$53 !== undefined ? a$53 : undefined,
          firstName_gte: a$54 !== undefined ? a$54 : undefined,
          firstName_gt: a$55 !== undefined ? a$55 : undefined,
          _id_in: a$56 !== undefined ? a$56.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          lastLogin_nin: a$57 !== undefined ? a$57.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          birthday_in: a$58 !== undefined ? a$58.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          authId_gte: a$59 !== undefined ? a$59 : undefined,
          createdAt_gte: a$60 !== undefined ? Caml_option.valFromOption(a$60) : undefined,
          gender_gt: a$61 !== undefined ? a$61 : undefined,
          lastName_exists: a$62 !== undefined ? a$62 : undefined,
          updatedAt_exists: a$63 !== undefined ? a$63 : undefined,
          createdAt_exists: a$64 !== undefined ? a$64 : undefined,
          name_exists: a$65 !== undefined ? a$65 : undefined,
          address: a$66 !== undefined ? serializeInputObjectUserAddressQueryInput(a$66) : undefined,
          birthday_gte: a$67 !== undefined ? Caml_option.valFromOption(a$67) : undefined,
          name: a$68 !== undefined ? a$68 : undefined,
          birthday_gt: a$69 !== undefined ? Caml_option.valFromOption(a$69) : undefined,
          picture_nin: a$70 !== undefined ? a$70.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          lastName_nin: a$71 !== undefined ? a$71.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          phone_in: a$72 !== undefined ? a$72.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          picture_gte: a$73 !== undefined ? a$73 : undefined,
          _id_lte: a$74 !== undefined ? Caml_option.valFromOption(a$74) : undefined,
          firstName_nin: a$75 !== undefined ? a$75.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          sentAbandonedOnboardingEmail_ne: a$76 !== undefined ? a$76 : undefined,
          phone_lt: a$77 !== undefined ? a$77 : undefined,
          birthday_ne: a$78 !== undefined ? Caml_option.valFromOption(a$78) : undefined,
          name_nin: a$79 !== undefined ? a$79.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          firstName_lt: a$80 !== undefined ? a$80 : undefined,
          phone: a$81 !== undefined ? a$81 : undefined,
          email_ne: a$82 !== undefined ? a$82 : undefined,
          _id_gte: a$83 !== undefined ? Caml_option.valFromOption(a$83) : undefined,
          email: a$84 !== undefined ? a$84 : undefined,
          birthday_lt: a$85 !== undefined ? Caml_option.valFromOption(a$85) : undefined,
          updatedAt_lte: a$86 !== undefined ? Caml_option.valFromOption(a$86) : undefined,
          address_exists: a$87 !== undefined ? a$87 : undefined,
          updatedAt_gte: a$88 !== undefined ? Caml_option.valFromOption(a$88) : undefined,
          createdAt_ne: a$89 !== undefined ? Caml_option.valFromOption(a$89) : undefined,
          lastName_lte: a$90 !== undefined ? a$90 : undefined,
          birthday_exists: a$91 !== undefined ? a$91 : undefined,
          authId_lt: a$92 !== undefined ? a$92 : undefined,
          picture_exists: a$93 !== undefined ? a$93 : undefined,
          updatedAt: a$94 !== undefined ? Caml_option.valFromOption(a$94) : undefined,
          firstName_in: a$95 !== undefined ? a$95.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          name_gte: a$96 !== undefined ? a$96 : undefined,
          picture_in: a$97 !== undefined ? a$97.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          phone_nin: a$98 !== undefined ? a$98.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          createdAt_lte: a$99 !== undefined ? Caml_option.valFromOption(a$99) : undefined,
          _id_ne: a$100 !== undefined ? Caml_option.valFromOption(a$100) : undefined,
          firstName_lte: a$101 !== undefined ? a$101 : undefined,
          authId_nin: a$102 !== undefined ? a$102.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          firstName_ne: a$103 !== undefined ? a$103 : undefined,
          gender_nin: a$104 !== undefined ? a$104.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          picture_lte: a$105 !== undefined ? a$105 : undefined,
          authId_exists: a$106 !== undefined ? a$106 : undefined,
          _id_gt: a$107 !== undefined ? Caml_option.valFromOption(a$107) : undefined,
          lastLogin_lt: a$108 !== undefined ? Caml_option.valFromOption(a$108) : undefined,
          gender_in: a$109 !== undefined ? a$109.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          lastName_gte: a$110 !== undefined ? a$110 : undefined,
          authId_gt: a$111 !== undefined ? a$111 : undefined,
          birthday_lte: a$112 !== undefined ? Caml_option.valFromOption(a$112) : undefined,
          createdAt_lt: a$113 !== undefined ? Caml_option.valFromOption(a$113) : undefined,
          firstName: a$114 !== undefined ? a$114 : undefined,
          picture_gt: a$115 !== undefined ? a$115 : undefined,
          email_lt: a$116 !== undefined ? a$116 : undefined,
          lastLogin_gt: a$117 !== undefined ? Caml_option.valFromOption(a$117) : undefined,
          lastName: a$118 !== undefined ? a$118 : undefined,
          email_nin: a$119 !== undefined ? a$119.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          email_gt: a$120 !== undefined ? a$120 : undefined,
          name_gt: a$121 !== undefined ? a$121 : undefined,
          name_lt: a$122 !== undefined ? a$122 : undefined,
          authId_lte: a$123 !== undefined ? a$123 : undefined,
          gender_ne: a$124 !== undefined ? a$124 : undefined,
          email_lte: a$125 !== undefined ? a$125 : undefined,
          name_in: a$126 !== undefined ? a$126.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined
        };
}

function serializeInputObjectUserAddressQueryInput(inp) {
  var a = inp.street1_lte;
  var a$1 = inp.zip_nin;
  var a$2 = inp.zip_exists;
  var a$3 = inp.city_lt;
  var a$4 = inp.zip_lt;
  var a$5 = inp.AND;
  var a$6 = inp.city;
  var a$7 = inp.state_lte;
  var a$8 = inp.street1_ne;
  var a$9 = inp.street1_exists;
  var a$10 = inp.state_ne;
  var a$11 = inp.street2_exists;
  var a$12 = inp.state_gt;
  var a$13 = inp.street1_in;
  var a$14 = inp.zip;
  var a$15 = inp.city_exists;
  var a$16 = inp.state;
  var a$17 = inp.street2_lte;
  var a$18 = inp.street2_in;
  var a$19 = inp.zip_gte;
  var a$20 = inp.state_nin;
  var a$21 = inp.city_ne;
  var a$22 = inp.city_lte;
  var a$23 = inp.street1_gte;
  var a$24 = inp.street2_ne;
  var a$25 = inp.city_nin;
  var a$26 = inp.zip_gt;
  var a$27 = inp.city_in;
  var a$28 = inp.street2_gte;
  var a$29 = inp.street2_gt;
  var a$30 = inp.zip_ne;
  var a$31 = inp.state_gte;
  var a$32 = inp.state_lt;
  var a$33 = inp.zip_lte;
  var a$34 = inp.state_in;
  var a$35 = inp.OR;
  var a$36 = inp.zip_in;
  var a$37 = inp.street2_nin;
  var a$38 = inp.street1_gt;
  var a$39 = inp.city_gte;
  var a$40 = inp.city_gt;
  var a$41 = inp.street2;
  var a$42 = inp.street2_lt;
  var a$43 = inp.street1;
  var a$44 = inp.street1_lt;
  var a$45 = inp.state_exists;
  var a$46 = inp.street1_nin;
  return {
          street1_lte: a !== undefined ? a : undefined,
          zip_nin: a$1 !== undefined ? a$1.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          zip_exists: a$2 !== undefined ? a$2 : undefined,
          city_lt: a$3 !== undefined ? a$3 : undefined,
          zip_lt: a$4 !== undefined ? a$4 : undefined,
          AND: a$5 !== undefined ? a$5.map(serializeInputObjectUserAddressQueryInput) : undefined,
          city: a$6 !== undefined ? a$6 : undefined,
          state_lte: a$7 !== undefined ? a$7 : undefined,
          street1_ne: a$8 !== undefined ? a$8 : undefined,
          street1_exists: a$9 !== undefined ? a$9 : undefined,
          state_ne: a$10 !== undefined ? a$10 : undefined,
          street2_exists: a$11 !== undefined ? a$11 : undefined,
          state_gt: a$12 !== undefined ? a$12 : undefined,
          street1_in: a$13 !== undefined ? a$13.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          zip: a$14 !== undefined ? a$14 : undefined,
          city_exists: a$15 !== undefined ? a$15 : undefined,
          state: a$16 !== undefined ? a$16 : undefined,
          street2_lte: a$17 !== undefined ? a$17 : undefined,
          street2_in: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          zip_gte: a$19 !== undefined ? a$19 : undefined,
          state_nin: a$20 !== undefined ? a$20.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          city_ne: a$21 !== undefined ? a$21 : undefined,
          city_lte: a$22 !== undefined ? a$22 : undefined,
          street1_gte: a$23 !== undefined ? a$23 : undefined,
          street2_ne: a$24 !== undefined ? a$24 : undefined,
          city_nin: a$25 !== undefined ? a$25.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          zip_gt: a$26 !== undefined ? a$26 : undefined,
          city_in: a$27 !== undefined ? a$27.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          street2_gte: a$28 !== undefined ? a$28 : undefined,
          street2_gt: a$29 !== undefined ? a$29 : undefined,
          zip_ne: a$30 !== undefined ? a$30 : undefined,
          state_gte: a$31 !== undefined ? a$31 : undefined,
          state_lt: a$32 !== undefined ? a$32 : undefined,
          zip_lte: a$33 !== undefined ? a$33 : undefined,
          state_in: a$34 !== undefined ? a$34.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          OR: a$35 !== undefined ? a$35.map(serializeInputObjectUserAddressQueryInput) : undefined,
          zip_in: a$36 !== undefined ? a$36.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          street2_nin: a$37 !== undefined ? a$37.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          street1_gt: a$38 !== undefined ? a$38 : undefined,
          city_gte: a$39 !== undefined ? a$39 : undefined,
          city_gt: a$40 !== undefined ? a$40 : undefined,
          street2: a$41 !== undefined ? a$41 : undefined,
          street2_lt: a$42 !== undefined ? a$42 : undefined,
          street1: a$43 !== undefined ? a$43 : undefined,
          street1_lt: a$44 !== undefined ? a$44 : undefined,
          state_exists: a$45 !== undefined ? a$45 : undefined,
          street1_nin: a$46 !== undefined ? a$46.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.query;
  return {
          query: a !== undefined ? serializeInputObjectGifteeQueryInput(a) : undefined
        };
}

function makeVariables(query, param) {
  return {
          query: query
        };
}

function makeInputObjectGifteeQueryInput(age_ne, interests_exists, createdAt_gte, picture_exists, age_lt, _id, picture_in, AND, updatedAt_gt, updatedAt_lte, status_gte, sourceUser_exists, _id_exists, createdAt_ne, isCustom_exists, updatedAt_gte, status_lte, name_gte, createdAt_exists, age_nin, picture, interests_nin, interests_in, relationship_gte, relationship_lte, age_exists, name, name_lte, userId_in, isPendingAcceptance, relationship_ne, age_in, name_nin, picture_gte, status_lt, age, updatedAt_exists, userId_nin, _id_gt, userId_ne, status_exists, sourceUser, userId_gt, _id_gte, createdAt_in, updatedAt_ne, isPendingAcceptance_ne, relationship_exists, _id_lte, _id_ne, isCustom, _id_nin, status_ne, createdAt_nin, status_nin, userId_lte, name_ne, picture_lte, createdAt_lt, status_in, updatedAt_in, _id_in, _id_lt, createdAt, userId_exists, age_gte, picture_lt, updatedAt, name_lt, status_gt, relationship_nin, age_lte, relationship_lt, userId_gte, status, createdAt_lte, name_in, relationship_in, isCustom_ne, isPendingAcceptance_exists, OR, updatedAt_lt, createdAt_gt, relationship_gt, name_gt, interests, age_gt, picture_nin, picture_ne, relationship, picture_gt, name_exists, userId_lt, userId, updatedAt_nin, param) {
  return {
          age_ne: age_ne,
          interests_exists: interests_exists,
          createdAt_gte: createdAt_gte,
          picture_exists: picture_exists,
          age_lt: age_lt,
          _id: _id,
          picture_in: picture_in,
          AND: AND,
          updatedAt_gt: updatedAt_gt,
          updatedAt_lte: updatedAt_lte,
          status_gte: status_gte,
          sourceUser_exists: sourceUser_exists,
          _id_exists: _id_exists,
          createdAt_ne: createdAt_ne,
          isCustom_exists: isCustom_exists,
          updatedAt_gte: updatedAt_gte,
          status_lte: status_lte,
          name_gte: name_gte,
          createdAt_exists: createdAt_exists,
          age_nin: age_nin,
          picture: picture,
          interests_nin: interests_nin,
          interests_in: interests_in,
          relationship_gte: relationship_gte,
          relationship_lte: relationship_lte,
          age_exists: age_exists,
          name: name,
          name_lte: name_lte,
          userId_in: userId_in,
          isPendingAcceptance: isPendingAcceptance,
          relationship_ne: relationship_ne,
          age_in: age_in,
          name_nin: name_nin,
          picture_gte: picture_gte,
          status_lt: status_lt,
          age: age,
          updatedAt_exists: updatedAt_exists,
          userId_nin: userId_nin,
          _id_gt: _id_gt,
          userId_ne: userId_ne,
          status_exists: status_exists,
          sourceUser: sourceUser,
          userId_gt: userId_gt,
          _id_gte: _id_gte,
          createdAt_in: createdAt_in,
          updatedAt_ne: updatedAt_ne,
          isPendingAcceptance_ne: isPendingAcceptance_ne,
          relationship_exists: relationship_exists,
          _id_lte: _id_lte,
          _id_ne: _id_ne,
          isCustom: isCustom,
          _id_nin: _id_nin,
          status_ne: status_ne,
          createdAt_nin: createdAt_nin,
          status_nin: status_nin,
          userId_lte: userId_lte,
          name_ne: name_ne,
          picture_lte: picture_lte,
          createdAt_lt: createdAt_lt,
          status_in: status_in,
          updatedAt_in: updatedAt_in,
          _id_in: _id_in,
          _id_lt: _id_lt,
          createdAt: createdAt,
          userId_exists: userId_exists,
          age_gte: age_gte,
          picture_lt: picture_lt,
          updatedAt: updatedAt,
          name_lt: name_lt,
          status_gt: status_gt,
          relationship_nin: relationship_nin,
          age_lte: age_lte,
          relationship_lt: relationship_lt,
          userId_gte: userId_gte,
          status: status,
          createdAt_lte: createdAt_lte,
          name_in: name_in,
          relationship_in: relationship_in,
          isCustom_ne: isCustom_ne,
          isPendingAcceptance_exists: isPendingAcceptance_exists,
          OR: OR,
          updatedAt_lt: updatedAt_lt,
          createdAt_gt: createdAt_gt,
          relationship_gt: relationship_gt,
          name_gt: name_gt,
          interests: interests,
          age_gt: age_gt,
          picture_nin: picture_nin,
          picture_ne: picture_ne,
          relationship: relationship,
          picture_gt: picture_gt,
          name_exists: name_exists,
          userId_lt: userId_lt,
          userId: userId,
          updatedAt_nin: updatedAt_nin
        };
}

function makeInputObjectUserQueryInput(_id_nin, phone_gt, phone_lte, lastLogin_exists, lastName_in, gender_exists, gender_lte, lastName_gt, lastLogin_ne, _id_lt, lastLogin_gte, _id, createdAt_nin, gender_gte, finishedOnboarding_exists, birthday, authId_ne, sentAbandonedOnboardingEmail_exists, authId_in, OR, picture, phone_exists, email_gte, finishedOnboarding, finishedOnboarding_ne, updatedAt_in, lastLogin_lte, lastName_lt, lastLogin, gender_lt, updatedAt_ne, sentAbandonedOnboardingEmail, lastLogin_in, createdAt, createdAt_gt, picture_ne, AND, _id_exists, birthday_nin, gender, name_lte, email_exists, name_ne, phone_ne, email_in, createdAt_in, phone_gte, updatedAt_gt, firstName_exists, authId, updatedAt_nin, updatedAt_lt, lastName_ne, picture_lt, firstName_gte, firstName_gt, _id_in, lastLogin_nin, birthday_in, authId_gte, createdAt_gte, gender_gt, lastName_exists, updatedAt_exists, createdAt_exists, name_exists, address, birthday_gte, name, birthday_gt, picture_nin, lastName_nin, phone_in, picture_gte, _id_lte, firstName_nin, sentAbandonedOnboardingEmail_ne, phone_lt, birthday_ne, name_nin, firstName_lt, phone, email_ne, _id_gte, email, birthday_lt, updatedAt_lte, address_exists, updatedAt_gte, createdAt_ne, lastName_lte, birthday_exists, authId_lt, picture_exists, updatedAt, firstName_in, name_gte, picture_in, phone_nin, createdAt_lte, _id_ne, firstName_lte, authId_nin, firstName_ne, gender_nin, picture_lte, authId_exists, _id_gt, lastLogin_lt, gender_in, lastName_gte, authId_gt, birthday_lte, createdAt_lt, firstName, picture_gt, email_lt, lastLogin_gt, lastName, email_nin, email_gt, name_gt, name_lt, authId_lte, gender_ne, email_lte, name_in, param) {
  return {
          _id_nin: _id_nin,
          phone_gt: phone_gt,
          phone_lte: phone_lte,
          lastLogin_exists: lastLogin_exists,
          lastName_in: lastName_in,
          gender_exists: gender_exists,
          gender_lte: gender_lte,
          lastName_gt: lastName_gt,
          lastLogin_ne: lastLogin_ne,
          _id_lt: _id_lt,
          lastLogin_gte: lastLogin_gte,
          _id: _id,
          createdAt_nin: createdAt_nin,
          gender_gte: gender_gte,
          finishedOnboarding_exists: finishedOnboarding_exists,
          birthday: birthday,
          authId_ne: authId_ne,
          sentAbandonedOnboardingEmail_exists: sentAbandonedOnboardingEmail_exists,
          authId_in: authId_in,
          OR: OR,
          picture: picture,
          phone_exists: phone_exists,
          email_gte: email_gte,
          finishedOnboarding: finishedOnboarding,
          finishedOnboarding_ne: finishedOnboarding_ne,
          updatedAt_in: updatedAt_in,
          lastLogin_lte: lastLogin_lte,
          lastName_lt: lastName_lt,
          lastLogin: lastLogin,
          gender_lt: gender_lt,
          updatedAt_ne: updatedAt_ne,
          sentAbandonedOnboardingEmail: sentAbandonedOnboardingEmail,
          lastLogin_in: lastLogin_in,
          createdAt: createdAt,
          createdAt_gt: createdAt_gt,
          picture_ne: picture_ne,
          AND: AND,
          _id_exists: _id_exists,
          birthday_nin: birthday_nin,
          gender: gender,
          name_lte: name_lte,
          email_exists: email_exists,
          name_ne: name_ne,
          phone_ne: phone_ne,
          email_in: email_in,
          createdAt_in: createdAt_in,
          phone_gte: phone_gte,
          updatedAt_gt: updatedAt_gt,
          firstName_exists: firstName_exists,
          authId: authId,
          updatedAt_nin: updatedAt_nin,
          updatedAt_lt: updatedAt_lt,
          lastName_ne: lastName_ne,
          picture_lt: picture_lt,
          firstName_gte: firstName_gte,
          firstName_gt: firstName_gt,
          _id_in: _id_in,
          lastLogin_nin: lastLogin_nin,
          birthday_in: birthday_in,
          authId_gte: authId_gte,
          createdAt_gte: createdAt_gte,
          gender_gt: gender_gt,
          lastName_exists: lastName_exists,
          updatedAt_exists: updatedAt_exists,
          createdAt_exists: createdAt_exists,
          name_exists: name_exists,
          address: address,
          birthday_gte: birthday_gte,
          name: name,
          birthday_gt: birthday_gt,
          picture_nin: picture_nin,
          lastName_nin: lastName_nin,
          phone_in: phone_in,
          picture_gte: picture_gte,
          _id_lte: _id_lte,
          firstName_nin: firstName_nin,
          sentAbandonedOnboardingEmail_ne: sentAbandonedOnboardingEmail_ne,
          phone_lt: phone_lt,
          birthday_ne: birthday_ne,
          name_nin: name_nin,
          firstName_lt: firstName_lt,
          phone: phone,
          email_ne: email_ne,
          _id_gte: _id_gte,
          email: email,
          birthday_lt: birthday_lt,
          updatedAt_lte: updatedAt_lte,
          address_exists: address_exists,
          updatedAt_gte: updatedAt_gte,
          createdAt_ne: createdAt_ne,
          lastName_lte: lastName_lte,
          birthday_exists: birthday_exists,
          authId_lt: authId_lt,
          picture_exists: picture_exists,
          updatedAt: updatedAt,
          firstName_in: firstName_in,
          name_gte: name_gte,
          picture_in: picture_in,
          phone_nin: phone_nin,
          createdAt_lte: createdAt_lte,
          _id_ne: _id_ne,
          firstName_lte: firstName_lte,
          authId_nin: authId_nin,
          firstName_ne: firstName_ne,
          gender_nin: gender_nin,
          picture_lte: picture_lte,
          authId_exists: authId_exists,
          _id_gt: _id_gt,
          lastLogin_lt: lastLogin_lt,
          gender_in: gender_in,
          lastName_gte: lastName_gte,
          authId_gt: authId_gt,
          birthday_lte: birthday_lte,
          createdAt_lt: createdAt_lt,
          firstName: firstName,
          picture_gt: picture_gt,
          email_lt: email_lt,
          lastLogin_gt: lastLogin_gt,
          lastName: lastName,
          email_nin: email_nin,
          email_gt: email_gt,
          name_gt: name_gt,
          name_lt: name_lt,
          authId_lte: authId_lte,
          gender_ne: gender_ne,
          email_lte: email_lte,
          name_in: name_in
        };
}

function makeInputObjectUserAddressQueryInput(street1_lte, zip_nin, zip_exists, city_lt, zip_lt, AND, city, state_lte, street1_ne, street1_exists, state_ne, street2_exists, state_gt, street1_in, zip, city_exists, state, street2_lte, street2_in, zip_gte, state_nin, city_ne, city_lte, street1_gte, street2_ne, city_nin, zip_gt, city_in, street2_gte, street2_gt, zip_ne, state_gte, state_lt, zip_lte, state_in, OR, zip_in, street2_nin, street1_gt, city_gte, city_gt, street2, street2_lt, street1, street1_lt, state_exists, street1_nin, param) {
  return {
          street1_lte: street1_lte,
          zip_nin: zip_nin,
          zip_exists: zip_exists,
          city_lt: city_lt,
          zip_lt: zip_lt,
          AND: AND,
          city: city,
          state_lte: state_lte,
          street1_ne: street1_ne,
          street1_exists: street1_exists,
          state_ne: state_ne,
          street2_exists: street2_exists,
          state_gt: state_gt,
          street1_in: street1_in,
          zip: zip,
          city_exists: city_exists,
          state: state,
          street2_lte: street2_lte,
          street2_in: street2_in,
          zip_gte: zip_gte,
          state_nin: state_nin,
          city_ne: city_ne,
          city_lte: city_lte,
          street1_gte: street1_gte,
          street2_ne: street2_ne,
          city_nin: city_nin,
          zip_gt: zip_gt,
          city_in: city_in,
          street2_gte: street2_gte,
          street2_gt: street2_gt,
          zip_ne: zip_ne,
          state_gte: state_gte,
          state_lt: state_lt,
          zip_lte: zip_lte,
          state_in: state_in,
          OR: OR,
          zip_in: zip_in,
          street2_nin: street2_nin,
          street1_gt: street1_gt,
          city_gte: city_gte,
          city_gt: city_gt,
          street2: street2,
          street2_lt: street2_lt,
          street1: street1,
          street1_lt: street1_lt,
          state_exists: state_exists,
          street1_nin: street1_nin
        };
}

function makeDefaultVariables(param) {
  return {
          query: undefined
        };
}

var GetGifteesQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectGifteeQueryInput: serializeInputObjectGifteeQueryInput,
  serializeInputObjectUserQueryInput: serializeInputObjectUserQueryInput,
  serializeInputObjectUserAddressQueryInput: serializeInputObjectUserAddressQueryInput,
  makeVariables: makeVariables,
  makeInputObjectGifteeQueryInput: makeInputObjectGifteeQueryInput,
  makeInputObjectUserQueryInput: makeInputObjectUserQueryInput,
  makeInputObjectUserAddressQueryInput: makeInputObjectUserAddressQueryInput,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var GetGifteesQuery_refetchQueryDescription = include.refetchQueryDescription;

var GetGifteesQuery_use = include.use;

var GetGifteesQuery_useLazy = include.useLazy;

var GetGifteesQuery_useLazyWithVariables = include.useLazyWithVariables;

var GetGifteesQuery = {
  GetGifteesQuery_inner: GetGifteesQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectGifteeQueryInput: serializeInputObjectGifteeQueryInput,
  serializeInputObjectUserQueryInput: serializeInputObjectUserQueryInput,
  serializeInputObjectUserAddressQueryInput: serializeInputObjectUserAddressQueryInput,
  makeVariables: makeVariables,
  makeInputObjectGifteeQueryInput: makeInputObjectGifteeQueryInput,
  makeInputObjectUserQueryInput: makeInputObjectUserQueryInput,
  makeInputObjectUserAddressQueryInput: makeInputObjectUserAddressQueryInput,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: GetGifteesQuery_refetchQueryDescription,
  use: GetGifteesQuery_use,
  useLazy: GetGifteesQuery_useLazy,
  useLazyWithVariables: GetGifteesQuery_useLazyWithVariables
};

var Raw$1 = {};

var query$1 = (require("@apollo/client").gql`
  query GetGifteeQuery($query: GifteeQueryInput)  {
    giftee(query: $query)  {
      __typename
      _id
      age
      createdAt
      isCustom
      isPendingAcceptance
      interests
      name
      picture
      relationship
      status
      sourceUser  {
        __typename
        _id
      }
      userId
    }
  }
`);

function parse$1(value) {
  var value$1 = value.giftee;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1._id;
    var value$3 = value$1.age;
    var value$4 = value$1.createdAt;
    var value$5 = value$1.isCustom;
    var value$6 = value$1.isPendingAcceptance;
    var value$7 = value$1.interests;
    var value$8 = value$1.name;
    var value$9 = value$1.picture;
    var value$10 = value$1.relationship;
    var tmp$1;
    if (value$10 == null) {
      tmp$1 = undefined;
    } else {
      var tmp$2;
      switch (value$10) {
        case "AUNT" :
            tmp$2 = "AUNT";
            break;
        case "BOYFRIEND" :
            tmp$2 = "BOYFRIEND";
            break;
        case "BROTHER" :
            tmp$2 = "BROTHER";
            break;
        case "BROTHER_IN_LAW" :
            tmp$2 = "BROTHER_IN_LAW";
            break;
        case "COUSIN" :
            tmp$2 = "COUSIN";
            break;
        case "FATHER" :
            tmp$2 = "FATHER";
            break;
        case "FATHER_IN_LAW" :
            tmp$2 = "FATHER_IN_LAW";
            break;
        case "FRIEND" :
            tmp$2 = "FRIEND";
            break;
        case "GENERAL_FAMILY" :
            tmp$2 = "GENERAL_FAMILY";
            break;
        case "GIRLFRIEND" :
            tmp$2 = "GIRLFRIEND";
            break;
        case "GRANDMA" :
            tmp$2 = "GRANDMA";
            break;
        case "GRANDPA" :
            tmp$2 = "GRANDPA";
            break;
        case "HUSBAND" :
            tmp$2 = "HUSBAND";
            break;
        case "MOTHER" :
            tmp$2 = "MOTHER";
            break;
        case "MOTHER_IN_LAW" :
            tmp$2 = "MOTHER_IN_LAW";
            break;
        case "OTHER" :
            tmp$2 = "OTHER";
            break;
        case "SISTER" :
            tmp$2 = "SISTER";
            break;
        case "SISTER_IN_LAW" :
            tmp$2 = "SISTER_IN_LAW";
            break;
        case "UNCLE" :
            tmp$2 = "UNCLE";
            break;
        case "WIFE" :
            tmp$2 = "WIFE";
            break;
        default:
          tmp$2 = {
            NAME: "FutureAddedValue",
            VAL: value$10
          };
      }
      tmp$1 = tmp$2;
    }
    var value$11 = value$1.status;
    var tmp$3;
    if (value$11 == null) {
      tmp$3 = undefined;
    } else {
      var tmp$4;
      switch (value$11) {
        case "ADDED" :
            tmp$4 = "ADDED";
            break;
        case "APPROVED" :
            tmp$4 = "APPROVED";
            break;
        case "PENDING" :
            tmp$4 = "PENDING";
            break;
        default:
          tmp$4 = {
            NAME: "FutureAddedValue",
            VAL: value$11
          };
      }
      tmp$3 = tmp$4;
    }
    var value$12 = value$1.sourceUser;
    var tmp$5;
    if (value$12 == null) {
      tmp$5 = undefined;
    } else {
      var value$13 = value$12._id;
      tmp$5 = {
        __typename: value$12.__typename,
        _id: !(value$13 == null) ? Caml_option.some(value$13) : undefined
      };
    }
    var value$14 = value$1.userId;
    tmp = {
      __typename: value$1.__typename,
      _id: !(value$2 == null) ? Caml_option.some(value$2) : undefined,
      age: !(value$3 == null) ? value$3 : undefined,
      createdAt: !(value$4 == null) ? Caml_option.some(value$4) : undefined,
      isCustom: !(value$5 == null) ? value$5 : undefined,
      isPendingAcceptance: !(value$6 == null) ? value$6 : undefined,
      interests: !(value$7 == null) ? value$7.map(function (value) {
              if (!(value == null)) {
                return value;
              }
              
            }) : undefined,
      name: !(value$8 == null) ? value$8 : undefined,
      picture: !(value$9 == null) ? value$9 : undefined,
      relationship: tmp$1,
      status: tmp$3,
      sourceUser: tmp$5,
      userId: !(value$14 == null) ? Caml_option.some(value$14) : undefined
    };
  }
  return {
          giftee: tmp
        };
}

function serialize$1(value) {
  var value$1 = value.giftee;
  var giftee;
  if (value$1 !== undefined) {
    var value$2 = value$1.userId;
    var userId = value$2 !== undefined ? Caml_option.valFromOption(value$2) : null;
    var value$3 = value$1.sourceUser;
    var sourceUser;
    if (value$3 !== undefined) {
      var value$4 = value$3._id;
      var _id = value$4 !== undefined ? Caml_option.valFromOption(value$4) : null;
      var value$5 = value$3.__typename;
      sourceUser = {
        __typename: value$5,
        _id: _id
      };
    } else {
      sourceUser = null;
    }
    var value$6 = value$1.status;
    var status = value$6 !== undefined ? (
        typeof value$6 === "object" ? value$6.VAL : (
            value$6 === "ADDED" ? "ADDED" : (
                value$6 === "PENDING" ? "PENDING" : "APPROVED"
              )
          )
      ) : null;
    var value$7 = value$1.relationship;
    var relationship = value$7 !== undefined ? (
        typeof value$7 === "object" ? value$7.VAL : (
            value$7 === "FRIEND" ? "FRIEND" : (
                value$7 === "SISTER" ? "SISTER" : (
                    value$7 === "GRANDMA" ? "GRANDMA" : (
                        value$7 === "GRANDPA" ? "GRANDPA" : (
                            value$7 === "MOTHER" ? "MOTHER" : (
                                value$7 === "COUSIN" ? "COUSIN" : (
                                    value$7 === "GIRLFRIEND" ? "GIRLFRIEND" : (
                                        value$7 === "FATHER_IN_LAW" ? "FATHER_IN_LAW" : (
                                            value$7 === "MOTHER_IN_LAW" ? "MOTHER_IN_LAW" : (
                                                value$7 === "FATHER" ? "FATHER" : (
                                                    value$7 === "BROTHER_IN_LAW" ? "BROTHER_IN_LAW" : (
                                                        value$7 === "HUSBAND" ? "HUSBAND" : (
                                                            value$7 === "UNCLE" ? "UNCLE" : (
                                                                value$7 === "AUNT" ? "AUNT" : (
                                                                    value$7 === "SISTER_IN_LAW" ? "SISTER_IN_LAW" : (
                                                                        value$7 === "OTHER" ? "OTHER" : (
                                                                            value$7 === "BROTHER" ? "BROTHER" : (
                                                                                value$7 === "WIFE" ? "WIFE" : (
                                                                                    value$7 === "GENERAL_FAMILY" ? "GENERAL_FAMILY" : "BOYFRIEND"
                                                                                  )
                                                                              )
                                                                          )
                                                                      )
                                                                  )
                                                              )
                                                          )
                                                      )
                                                  )
                                              )
                                          )
                                      )
                                  )
                              )
                          )
                      )
                  )
              )
          )
      ) : null;
    var value$8 = value$1.picture;
    var picture = value$8 !== undefined ? value$8 : null;
    var value$9 = value$1.name;
    var name = value$9 !== undefined ? value$9 : null;
    var value$10 = value$1.interests;
    var interests = value$10 !== undefined ? value$10.map(function (value) {
            if (value !== undefined) {
              return value;
            } else {
              return null;
            }
          }) : null;
    var value$11 = value$1.isPendingAcceptance;
    var isPendingAcceptance = value$11 !== undefined ? value$11 : null;
    var value$12 = value$1.isCustom;
    var isCustom = value$12 !== undefined ? value$12 : null;
    var value$13 = value$1.createdAt;
    var createdAt = value$13 !== undefined ? Caml_option.valFromOption(value$13) : null;
    var value$14 = value$1.age;
    var age = value$14 !== undefined ? value$14 : null;
    var value$15 = value$1._id;
    var _id$1 = value$15 !== undefined ? Caml_option.valFromOption(value$15) : null;
    var value$16 = value$1.__typename;
    giftee = {
      __typename: value$16,
      _id: _id$1,
      age: age,
      createdAt: createdAt,
      isCustom: isCustom,
      isPendingAcceptance: isPendingAcceptance,
      interests: interests,
      name: name,
      picture: picture,
      relationship: relationship,
      status: status,
      sourceUser: sourceUser,
      userId: userId
    };
  } else {
    giftee = null;
  }
  return {
          giftee: giftee
        };
}

function serializeInputObjectGifteeQueryInput$1(inp) {
  var a = inp.age_ne;
  var a$1 = inp.interests_exists;
  var a$2 = inp.createdAt_gte;
  var a$3 = inp.picture_exists;
  var a$4 = inp.age_lt;
  var a$5 = inp._id;
  var a$6 = inp.picture_in;
  var a$7 = inp.AND;
  var a$8 = inp.updatedAt_gt;
  var a$9 = inp.updatedAt_lte;
  var a$10 = inp.status_gte;
  var a$11 = inp.sourceUser_exists;
  var a$12 = inp._id_exists;
  var a$13 = inp.createdAt_ne;
  var a$14 = inp.isCustom_exists;
  var a$15 = inp.updatedAt_gte;
  var a$16 = inp.status_lte;
  var a$17 = inp.name_gte;
  var a$18 = inp.createdAt_exists;
  var a$19 = inp.age_nin;
  var a$20 = inp.picture;
  var a$21 = inp.interests_nin;
  var a$22 = inp.interests_in;
  var a$23 = inp.relationship_gte;
  var a$24 = inp.relationship_lte;
  var a$25 = inp.age_exists;
  var a$26 = inp.name;
  var a$27 = inp.name_lte;
  var a$28 = inp.userId_in;
  var a$29 = inp.isPendingAcceptance;
  var a$30 = inp.relationship_ne;
  var a$31 = inp.age_in;
  var a$32 = inp.name_nin;
  var a$33 = inp.picture_gte;
  var a$34 = inp.status_lt;
  var a$35 = inp.age;
  var a$36 = inp.updatedAt_exists;
  var a$37 = inp.userId_nin;
  var a$38 = inp._id_gt;
  var a$39 = inp.userId_ne;
  var a$40 = inp.status_exists;
  var a$41 = inp.sourceUser;
  var a$42 = inp.userId_gt;
  var a$43 = inp._id_gte;
  var a$44 = inp.createdAt_in;
  var a$45 = inp.updatedAt_ne;
  var a$46 = inp.isPendingAcceptance_ne;
  var a$47 = inp.relationship_exists;
  var a$48 = inp._id_lte;
  var a$49 = inp._id_ne;
  var a$50 = inp.isCustom;
  var a$51 = inp._id_nin;
  var a$52 = inp.status_ne;
  var a$53 = inp.createdAt_nin;
  var a$54 = inp.status_nin;
  var a$55 = inp.userId_lte;
  var a$56 = inp.name_ne;
  var a$57 = inp.picture_lte;
  var a$58 = inp.createdAt_lt;
  var a$59 = inp.status_in;
  var a$60 = inp.updatedAt_in;
  var a$61 = inp._id_in;
  var a$62 = inp._id_lt;
  var a$63 = inp.createdAt;
  var a$64 = inp.userId_exists;
  var a$65 = inp.age_gte;
  var a$66 = inp.picture_lt;
  var a$67 = inp.updatedAt;
  var a$68 = inp.name_lt;
  var a$69 = inp.status_gt;
  var a$70 = inp.relationship_nin;
  var a$71 = inp.age_lte;
  var a$72 = inp.relationship_lt;
  var a$73 = inp.userId_gte;
  var a$74 = inp.status;
  var a$75 = inp.createdAt_lte;
  var a$76 = inp.name_in;
  var a$77 = inp.relationship_in;
  var a$78 = inp.isCustom_ne;
  var a$79 = inp.isPendingAcceptance_exists;
  var a$80 = inp.OR;
  var a$81 = inp.updatedAt_lt;
  var a$82 = inp.createdAt_gt;
  var a$83 = inp.relationship_gt;
  var a$84 = inp.name_gt;
  var a$85 = inp.interests;
  var a$86 = inp.age_gt;
  var a$87 = inp.picture_nin;
  var a$88 = inp.picture_ne;
  var a$89 = inp.relationship;
  var a$90 = inp.picture_gt;
  var a$91 = inp.name_exists;
  var a$92 = inp.userId_lt;
  var a$93 = inp.userId;
  var a$94 = inp.updatedAt_nin;
  return {
          age_ne: a !== undefined ? a : undefined,
          interests_exists: a$1 !== undefined ? a$1 : undefined,
          createdAt_gte: a$2 !== undefined ? Caml_option.valFromOption(a$2) : undefined,
          picture_exists: a$3 !== undefined ? a$3 : undefined,
          age_lt: a$4 !== undefined ? a$4 : undefined,
          _id: a$5 !== undefined ? Caml_option.valFromOption(a$5) : undefined,
          picture_in: a$6 !== undefined ? a$6.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          AND: a$7 !== undefined ? a$7.map(serializeInputObjectGifteeQueryInput$1) : undefined,
          updatedAt_gt: a$8 !== undefined ? Caml_option.valFromOption(a$8) : undefined,
          updatedAt_lte: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          status_gte: a$10 !== undefined ? (
              a$10 === "ADDED" ? "ADDED" : (
                  a$10 === "PENDING" ? "PENDING" : "APPROVED"
                )
            ) : undefined,
          sourceUser_exists: a$11 !== undefined ? a$11 : undefined,
          _id_exists: a$12 !== undefined ? a$12 : undefined,
          createdAt_ne: a$13 !== undefined ? Caml_option.valFromOption(a$13) : undefined,
          isCustom_exists: a$14 !== undefined ? a$14 : undefined,
          updatedAt_gte: a$15 !== undefined ? Caml_option.valFromOption(a$15) : undefined,
          status_lte: a$16 !== undefined ? (
              a$16 === "ADDED" ? "ADDED" : (
                  a$16 === "PENDING" ? "PENDING" : "APPROVED"
                )
            ) : undefined,
          name_gte: a$17 !== undefined ? a$17 : undefined,
          createdAt_exists: a$18 !== undefined ? a$18 : undefined,
          age_nin: a$19 !== undefined ? a$19.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          picture: a$20 !== undefined ? a$20 : undefined,
          interests_nin: a$21 !== undefined ? a$21.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          interests_in: a$22 !== undefined ? a$22.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          relationship_gte: a$23 !== undefined ? (
              a$23 === "FRIEND" ? "FRIEND" : (
                  a$23 === "SISTER" ? "SISTER" : (
                      a$23 === "GRANDMA" ? "GRANDMA" : (
                          a$23 === "GRANDPA" ? "GRANDPA" : (
                              a$23 === "MOTHER" ? "MOTHER" : (
                                  a$23 === "COUSIN" ? "COUSIN" : (
                                      a$23 === "GIRLFRIEND" ? "GIRLFRIEND" : (
                                          a$23 === "FATHER_IN_LAW" ? "FATHER_IN_LAW" : (
                                              a$23 === "MOTHER_IN_LAW" ? "MOTHER_IN_LAW" : (
                                                  a$23 === "FATHER" ? "FATHER" : (
                                                      a$23 === "BROTHER_IN_LAW" ? "BROTHER_IN_LAW" : (
                                                          a$23 === "HUSBAND" ? "HUSBAND" : (
                                                              a$23 === "UNCLE" ? "UNCLE" : (
                                                                  a$23 === "AUNT" ? "AUNT" : (
                                                                      a$23 === "SISTER_IN_LAW" ? "SISTER_IN_LAW" : (
                                                                          a$23 === "OTHER" ? "OTHER" : (
                                                                              a$23 === "BROTHER" ? "BROTHER" : (
                                                                                  a$23 === "WIFE" ? "WIFE" : (
                                                                                      a$23 === "GENERAL_FAMILY" ? "GENERAL_FAMILY" : "BOYFRIEND"
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          relationship_lte: a$24 !== undefined ? (
              a$24 === "FRIEND" ? "FRIEND" : (
                  a$24 === "SISTER" ? "SISTER" : (
                      a$24 === "GRANDMA" ? "GRANDMA" : (
                          a$24 === "GRANDPA" ? "GRANDPA" : (
                              a$24 === "MOTHER" ? "MOTHER" : (
                                  a$24 === "COUSIN" ? "COUSIN" : (
                                      a$24 === "GIRLFRIEND" ? "GIRLFRIEND" : (
                                          a$24 === "FATHER_IN_LAW" ? "FATHER_IN_LAW" : (
                                              a$24 === "MOTHER_IN_LAW" ? "MOTHER_IN_LAW" : (
                                                  a$24 === "FATHER" ? "FATHER" : (
                                                      a$24 === "BROTHER_IN_LAW" ? "BROTHER_IN_LAW" : (
                                                          a$24 === "HUSBAND" ? "HUSBAND" : (
                                                              a$24 === "UNCLE" ? "UNCLE" : (
                                                                  a$24 === "AUNT" ? "AUNT" : (
                                                                      a$24 === "SISTER_IN_LAW" ? "SISTER_IN_LAW" : (
                                                                          a$24 === "OTHER" ? "OTHER" : (
                                                                              a$24 === "BROTHER" ? "BROTHER" : (
                                                                                  a$24 === "WIFE" ? "WIFE" : (
                                                                                      a$24 === "GENERAL_FAMILY" ? "GENERAL_FAMILY" : "BOYFRIEND"
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          age_exists: a$25 !== undefined ? a$25 : undefined,
          name: a$26 !== undefined ? a$26 : undefined,
          name_lte: a$27 !== undefined ? a$27 : undefined,
          userId_in: a$28 !== undefined ? a$28.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          isPendingAcceptance: a$29 !== undefined ? a$29 : undefined,
          relationship_ne: a$30 !== undefined ? (
              a$30 === "FRIEND" ? "FRIEND" : (
                  a$30 === "SISTER" ? "SISTER" : (
                      a$30 === "GRANDMA" ? "GRANDMA" : (
                          a$30 === "GRANDPA" ? "GRANDPA" : (
                              a$30 === "MOTHER" ? "MOTHER" : (
                                  a$30 === "COUSIN" ? "COUSIN" : (
                                      a$30 === "GIRLFRIEND" ? "GIRLFRIEND" : (
                                          a$30 === "FATHER_IN_LAW" ? "FATHER_IN_LAW" : (
                                              a$30 === "MOTHER_IN_LAW" ? "MOTHER_IN_LAW" : (
                                                  a$30 === "FATHER" ? "FATHER" : (
                                                      a$30 === "BROTHER_IN_LAW" ? "BROTHER_IN_LAW" : (
                                                          a$30 === "HUSBAND" ? "HUSBAND" : (
                                                              a$30 === "UNCLE" ? "UNCLE" : (
                                                                  a$30 === "AUNT" ? "AUNT" : (
                                                                      a$30 === "SISTER_IN_LAW" ? "SISTER_IN_LAW" : (
                                                                          a$30 === "OTHER" ? "OTHER" : (
                                                                              a$30 === "BROTHER" ? "BROTHER" : (
                                                                                  a$30 === "WIFE" ? "WIFE" : (
                                                                                      a$30 === "GENERAL_FAMILY" ? "GENERAL_FAMILY" : "BOYFRIEND"
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          age_in: a$31 !== undefined ? a$31.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          name_nin: a$32 !== undefined ? a$32.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          picture_gte: a$33 !== undefined ? a$33 : undefined,
          status_lt: a$34 !== undefined ? (
              a$34 === "ADDED" ? "ADDED" : (
                  a$34 === "PENDING" ? "PENDING" : "APPROVED"
                )
            ) : undefined,
          age: a$35 !== undefined ? a$35 : undefined,
          updatedAt_exists: a$36 !== undefined ? a$36 : undefined,
          userId_nin: a$37 !== undefined ? a$37.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          _id_gt: a$38 !== undefined ? Caml_option.valFromOption(a$38) : undefined,
          userId_ne: a$39 !== undefined ? Caml_option.valFromOption(a$39) : undefined,
          status_exists: a$40 !== undefined ? a$40 : undefined,
          sourceUser: a$41 !== undefined ? serializeInputObjectUserQueryInput$1(a$41) : undefined,
          userId_gt: a$42 !== undefined ? Caml_option.valFromOption(a$42) : undefined,
          _id_gte: a$43 !== undefined ? Caml_option.valFromOption(a$43) : undefined,
          createdAt_in: a$44 !== undefined ? a$44.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          updatedAt_ne: a$45 !== undefined ? Caml_option.valFromOption(a$45) : undefined,
          isPendingAcceptance_ne: a$46 !== undefined ? a$46 : undefined,
          relationship_exists: a$47 !== undefined ? a$47 : undefined,
          _id_lte: a$48 !== undefined ? Caml_option.valFromOption(a$48) : undefined,
          _id_ne: a$49 !== undefined ? Caml_option.valFromOption(a$49) : undefined,
          isCustom: a$50 !== undefined ? a$50 : undefined,
          _id_nin: a$51 !== undefined ? a$51.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          status_ne: a$52 !== undefined ? (
              a$52 === "ADDED" ? "ADDED" : (
                  a$52 === "PENDING" ? "PENDING" : "APPROVED"
                )
            ) : undefined,
          createdAt_nin: a$53 !== undefined ? a$53.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          status_nin: a$54 !== undefined ? a$54.map(function (b) {
                  if (b !== undefined) {
                    if (b === "ADDED") {
                      return "ADDED";
                    } else if (b === "PENDING") {
                      return "PENDING";
                    } else {
                      return "APPROVED";
                    }
                  }
                  
                }) : undefined,
          userId_lte: a$55 !== undefined ? Caml_option.valFromOption(a$55) : undefined,
          name_ne: a$56 !== undefined ? a$56 : undefined,
          picture_lte: a$57 !== undefined ? a$57 : undefined,
          createdAt_lt: a$58 !== undefined ? Caml_option.valFromOption(a$58) : undefined,
          status_in: a$59 !== undefined ? a$59.map(function (b) {
                  if (b !== undefined) {
                    if (b === "ADDED") {
                      return "ADDED";
                    } else if (b === "PENDING") {
                      return "PENDING";
                    } else {
                      return "APPROVED";
                    }
                  }
                  
                }) : undefined,
          updatedAt_in: a$60 !== undefined ? a$60.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          _id_in: a$61 !== undefined ? a$61.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          _id_lt: a$62 !== undefined ? Caml_option.valFromOption(a$62) : undefined,
          createdAt: a$63 !== undefined ? Caml_option.valFromOption(a$63) : undefined,
          userId_exists: a$64 !== undefined ? a$64 : undefined,
          age_gte: a$65 !== undefined ? a$65 : undefined,
          picture_lt: a$66 !== undefined ? a$66 : undefined,
          updatedAt: a$67 !== undefined ? Caml_option.valFromOption(a$67) : undefined,
          name_lt: a$68 !== undefined ? a$68 : undefined,
          status_gt: a$69 !== undefined ? (
              a$69 === "ADDED" ? "ADDED" : (
                  a$69 === "PENDING" ? "PENDING" : "APPROVED"
                )
            ) : undefined,
          relationship_nin: a$70 !== undefined ? a$70.map(function (b) {
                  if (b !== undefined) {
                    if (b === "FRIEND") {
                      return "FRIEND";
                    } else if (b === "SISTER") {
                      return "SISTER";
                    } else if (b === "GRANDMA") {
                      return "GRANDMA";
                    } else if (b === "GRANDPA") {
                      return "GRANDPA";
                    } else if (b === "MOTHER") {
                      return "MOTHER";
                    } else if (b === "COUSIN") {
                      return "COUSIN";
                    } else if (b === "GIRLFRIEND") {
                      return "GIRLFRIEND";
                    } else if (b === "FATHER_IN_LAW") {
                      return "FATHER_IN_LAW";
                    } else if (b === "MOTHER_IN_LAW") {
                      return "MOTHER_IN_LAW";
                    } else if (b === "FATHER") {
                      return "FATHER";
                    } else if (b === "BROTHER_IN_LAW") {
                      return "BROTHER_IN_LAW";
                    } else if (b === "HUSBAND") {
                      return "HUSBAND";
                    } else if (b === "UNCLE") {
                      return "UNCLE";
                    } else if (b === "AUNT") {
                      return "AUNT";
                    } else if (b === "SISTER_IN_LAW") {
                      return "SISTER_IN_LAW";
                    } else if (b === "OTHER") {
                      return "OTHER";
                    } else if (b === "BROTHER") {
                      return "BROTHER";
                    } else if (b === "WIFE") {
                      return "WIFE";
                    } else if (b === "GENERAL_FAMILY") {
                      return "GENERAL_FAMILY";
                    } else {
                      return "BOYFRIEND";
                    }
                  }
                  
                }) : undefined,
          age_lte: a$71 !== undefined ? a$71 : undefined,
          relationship_lt: a$72 !== undefined ? (
              a$72 === "FRIEND" ? "FRIEND" : (
                  a$72 === "SISTER" ? "SISTER" : (
                      a$72 === "GRANDMA" ? "GRANDMA" : (
                          a$72 === "GRANDPA" ? "GRANDPA" : (
                              a$72 === "MOTHER" ? "MOTHER" : (
                                  a$72 === "COUSIN" ? "COUSIN" : (
                                      a$72 === "GIRLFRIEND" ? "GIRLFRIEND" : (
                                          a$72 === "FATHER_IN_LAW" ? "FATHER_IN_LAW" : (
                                              a$72 === "MOTHER_IN_LAW" ? "MOTHER_IN_LAW" : (
                                                  a$72 === "FATHER" ? "FATHER" : (
                                                      a$72 === "BROTHER_IN_LAW" ? "BROTHER_IN_LAW" : (
                                                          a$72 === "HUSBAND" ? "HUSBAND" : (
                                                              a$72 === "UNCLE" ? "UNCLE" : (
                                                                  a$72 === "AUNT" ? "AUNT" : (
                                                                      a$72 === "SISTER_IN_LAW" ? "SISTER_IN_LAW" : (
                                                                          a$72 === "OTHER" ? "OTHER" : (
                                                                              a$72 === "BROTHER" ? "BROTHER" : (
                                                                                  a$72 === "WIFE" ? "WIFE" : (
                                                                                      a$72 === "GENERAL_FAMILY" ? "GENERAL_FAMILY" : "BOYFRIEND"
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          userId_gte: a$73 !== undefined ? Caml_option.valFromOption(a$73) : undefined,
          status: a$74 !== undefined ? (
              a$74 === "ADDED" ? "ADDED" : (
                  a$74 === "PENDING" ? "PENDING" : "APPROVED"
                )
            ) : undefined,
          createdAt_lte: a$75 !== undefined ? Caml_option.valFromOption(a$75) : undefined,
          name_in: a$76 !== undefined ? a$76.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          relationship_in: a$77 !== undefined ? a$77.map(function (b) {
                  if (b !== undefined) {
                    if (b === "FRIEND") {
                      return "FRIEND";
                    } else if (b === "SISTER") {
                      return "SISTER";
                    } else if (b === "GRANDMA") {
                      return "GRANDMA";
                    } else if (b === "GRANDPA") {
                      return "GRANDPA";
                    } else if (b === "MOTHER") {
                      return "MOTHER";
                    } else if (b === "COUSIN") {
                      return "COUSIN";
                    } else if (b === "GIRLFRIEND") {
                      return "GIRLFRIEND";
                    } else if (b === "FATHER_IN_LAW") {
                      return "FATHER_IN_LAW";
                    } else if (b === "MOTHER_IN_LAW") {
                      return "MOTHER_IN_LAW";
                    } else if (b === "FATHER") {
                      return "FATHER";
                    } else if (b === "BROTHER_IN_LAW") {
                      return "BROTHER_IN_LAW";
                    } else if (b === "HUSBAND") {
                      return "HUSBAND";
                    } else if (b === "UNCLE") {
                      return "UNCLE";
                    } else if (b === "AUNT") {
                      return "AUNT";
                    } else if (b === "SISTER_IN_LAW") {
                      return "SISTER_IN_LAW";
                    } else if (b === "OTHER") {
                      return "OTHER";
                    } else if (b === "BROTHER") {
                      return "BROTHER";
                    } else if (b === "WIFE") {
                      return "WIFE";
                    } else if (b === "GENERAL_FAMILY") {
                      return "GENERAL_FAMILY";
                    } else {
                      return "BOYFRIEND";
                    }
                  }
                  
                }) : undefined,
          isCustom_ne: a$78 !== undefined ? a$78 : undefined,
          isPendingAcceptance_exists: a$79 !== undefined ? a$79 : undefined,
          OR: a$80 !== undefined ? a$80.map(serializeInputObjectGifteeQueryInput$1) : undefined,
          updatedAt_lt: a$81 !== undefined ? Caml_option.valFromOption(a$81) : undefined,
          createdAt_gt: a$82 !== undefined ? Caml_option.valFromOption(a$82) : undefined,
          relationship_gt: a$83 !== undefined ? (
              a$83 === "FRIEND" ? "FRIEND" : (
                  a$83 === "SISTER" ? "SISTER" : (
                      a$83 === "GRANDMA" ? "GRANDMA" : (
                          a$83 === "GRANDPA" ? "GRANDPA" : (
                              a$83 === "MOTHER" ? "MOTHER" : (
                                  a$83 === "COUSIN" ? "COUSIN" : (
                                      a$83 === "GIRLFRIEND" ? "GIRLFRIEND" : (
                                          a$83 === "FATHER_IN_LAW" ? "FATHER_IN_LAW" : (
                                              a$83 === "MOTHER_IN_LAW" ? "MOTHER_IN_LAW" : (
                                                  a$83 === "FATHER" ? "FATHER" : (
                                                      a$83 === "BROTHER_IN_LAW" ? "BROTHER_IN_LAW" : (
                                                          a$83 === "HUSBAND" ? "HUSBAND" : (
                                                              a$83 === "UNCLE" ? "UNCLE" : (
                                                                  a$83 === "AUNT" ? "AUNT" : (
                                                                      a$83 === "SISTER_IN_LAW" ? "SISTER_IN_LAW" : (
                                                                          a$83 === "OTHER" ? "OTHER" : (
                                                                              a$83 === "BROTHER" ? "BROTHER" : (
                                                                                  a$83 === "WIFE" ? "WIFE" : (
                                                                                      a$83 === "GENERAL_FAMILY" ? "GENERAL_FAMILY" : "BOYFRIEND"
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          name_gt: a$84 !== undefined ? a$84 : undefined,
          interests: a$85 !== undefined ? a$85.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          age_gt: a$86 !== undefined ? a$86 : undefined,
          picture_nin: a$87 !== undefined ? a$87.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          picture_ne: a$88 !== undefined ? a$88 : undefined,
          relationship: a$89 !== undefined ? (
              a$89 === "FRIEND" ? "FRIEND" : (
                  a$89 === "SISTER" ? "SISTER" : (
                      a$89 === "GRANDMA" ? "GRANDMA" : (
                          a$89 === "GRANDPA" ? "GRANDPA" : (
                              a$89 === "MOTHER" ? "MOTHER" : (
                                  a$89 === "COUSIN" ? "COUSIN" : (
                                      a$89 === "GIRLFRIEND" ? "GIRLFRIEND" : (
                                          a$89 === "FATHER_IN_LAW" ? "FATHER_IN_LAW" : (
                                              a$89 === "MOTHER_IN_LAW" ? "MOTHER_IN_LAW" : (
                                                  a$89 === "FATHER" ? "FATHER" : (
                                                      a$89 === "BROTHER_IN_LAW" ? "BROTHER_IN_LAW" : (
                                                          a$89 === "HUSBAND" ? "HUSBAND" : (
                                                              a$89 === "UNCLE" ? "UNCLE" : (
                                                                  a$89 === "AUNT" ? "AUNT" : (
                                                                      a$89 === "SISTER_IN_LAW" ? "SISTER_IN_LAW" : (
                                                                          a$89 === "OTHER" ? "OTHER" : (
                                                                              a$89 === "BROTHER" ? "BROTHER" : (
                                                                                  a$89 === "WIFE" ? "WIFE" : (
                                                                                      a$89 === "GENERAL_FAMILY" ? "GENERAL_FAMILY" : "BOYFRIEND"
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          picture_gt: a$90 !== undefined ? a$90 : undefined,
          name_exists: a$91 !== undefined ? a$91 : undefined,
          userId_lt: a$92 !== undefined ? Caml_option.valFromOption(a$92) : undefined,
          userId: a$93 !== undefined ? Caml_option.valFromOption(a$93) : undefined,
          updatedAt_nin: a$94 !== undefined ? a$94.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined
        };
}

function serializeInputObjectUserQueryInput$1(inp) {
  var a = inp._id_nin;
  var a$1 = inp.phone_gt;
  var a$2 = inp.phone_lte;
  var a$3 = inp.lastLogin_exists;
  var a$4 = inp.lastName_in;
  var a$5 = inp.gender_exists;
  var a$6 = inp.gender_lte;
  var a$7 = inp.lastName_gt;
  var a$8 = inp.lastLogin_ne;
  var a$9 = inp._id_lt;
  var a$10 = inp.lastLogin_gte;
  var a$11 = inp._id;
  var a$12 = inp.createdAt_nin;
  var a$13 = inp.gender_gte;
  var a$14 = inp.finishedOnboarding_exists;
  var a$15 = inp.birthday;
  var a$16 = inp.authId_ne;
  var a$17 = inp.sentAbandonedOnboardingEmail_exists;
  var a$18 = inp.authId_in;
  var a$19 = inp.OR;
  var a$20 = inp.picture;
  var a$21 = inp.phone_exists;
  var a$22 = inp.email_gte;
  var a$23 = inp.finishedOnboarding;
  var a$24 = inp.finishedOnboarding_ne;
  var a$25 = inp.updatedAt_in;
  var a$26 = inp.lastLogin_lte;
  var a$27 = inp.lastName_lt;
  var a$28 = inp.lastLogin;
  var a$29 = inp.gender_lt;
  var a$30 = inp.updatedAt_ne;
  var a$31 = inp.sentAbandonedOnboardingEmail;
  var a$32 = inp.lastLogin_in;
  var a$33 = inp.createdAt;
  var a$34 = inp.createdAt_gt;
  var a$35 = inp.picture_ne;
  var a$36 = inp.AND;
  var a$37 = inp._id_exists;
  var a$38 = inp.birthday_nin;
  var a$39 = inp.gender;
  var a$40 = inp.name_lte;
  var a$41 = inp.email_exists;
  var a$42 = inp.name_ne;
  var a$43 = inp.phone_ne;
  var a$44 = inp.email_in;
  var a$45 = inp.createdAt_in;
  var a$46 = inp.phone_gte;
  var a$47 = inp.updatedAt_gt;
  var a$48 = inp.firstName_exists;
  var a$49 = inp.authId;
  var a$50 = inp.updatedAt_nin;
  var a$51 = inp.updatedAt_lt;
  var a$52 = inp.lastName_ne;
  var a$53 = inp.picture_lt;
  var a$54 = inp.firstName_gte;
  var a$55 = inp.firstName_gt;
  var a$56 = inp._id_in;
  var a$57 = inp.lastLogin_nin;
  var a$58 = inp.birthday_in;
  var a$59 = inp.authId_gte;
  var a$60 = inp.createdAt_gte;
  var a$61 = inp.gender_gt;
  var a$62 = inp.lastName_exists;
  var a$63 = inp.updatedAt_exists;
  var a$64 = inp.createdAt_exists;
  var a$65 = inp.name_exists;
  var a$66 = inp.address;
  var a$67 = inp.birthday_gte;
  var a$68 = inp.name;
  var a$69 = inp.birthday_gt;
  var a$70 = inp.picture_nin;
  var a$71 = inp.lastName_nin;
  var a$72 = inp.phone_in;
  var a$73 = inp.picture_gte;
  var a$74 = inp._id_lte;
  var a$75 = inp.firstName_nin;
  var a$76 = inp.sentAbandonedOnboardingEmail_ne;
  var a$77 = inp.phone_lt;
  var a$78 = inp.birthday_ne;
  var a$79 = inp.name_nin;
  var a$80 = inp.firstName_lt;
  var a$81 = inp.phone;
  var a$82 = inp.email_ne;
  var a$83 = inp._id_gte;
  var a$84 = inp.email;
  var a$85 = inp.birthday_lt;
  var a$86 = inp.updatedAt_lte;
  var a$87 = inp.address_exists;
  var a$88 = inp.updatedAt_gte;
  var a$89 = inp.createdAt_ne;
  var a$90 = inp.lastName_lte;
  var a$91 = inp.birthday_exists;
  var a$92 = inp.authId_lt;
  var a$93 = inp.picture_exists;
  var a$94 = inp.updatedAt;
  var a$95 = inp.firstName_in;
  var a$96 = inp.name_gte;
  var a$97 = inp.picture_in;
  var a$98 = inp.phone_nin;
  var a$99 = inp.createdAt_lte;
  var a$100 = inp._id_ne;
  var a$101 = inp.firstName_lte;
  var a$102 = inp.authId_nin;
  var a$103 = inp.firstName_ne;
  var a$104 = inp.gender_nin;
  var a$105 = inp.picture_lte;
  var a$106 = inp.authId_exists;
  var a$107 = inp._id_gt;
  var a$108 = inp.lastLogin_lt;
  var a$109 = inp.gender_in;
  var a$110 = inp.lastName_gte;
  var a$111 = inp.authId_gt;
  var a$112 = inp.birthday_lte;
  var a$113 = inp.createdAt_lt;
  var a$114 = inp.firstName;
  var a$115 = inp.picture_gt;
  var a$116 = inp.email_lt;
  var a$117 = inp.lastLogin_gt;
  var a$118 = inp.lastName;
  var a$119 = inp.email_nin;
  var a$120 = inp.email_gt;
  var a$121 = inp.name_gt;
  var a$122 = inp.name_lt;
  var a$123 = inp.authId_lte;
  var a$124 = inp.gender_ne;
  var a$125 = inp.email_lte;
  var a$126 = inp.name_in;
  return {
          _id_nin: a !== undefined ? a.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          phone_gt: a$1 !== undefined ? a$1 : undefined,
          phone_lte: a$2 !== undefined ? a$2 : undefined,
          lastLogin_exists: a$3 !== undefined ? a$3 : undefined,
          lastName_in: a$4 !== undefined ? a$4.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          gender_exists: a$5 !== undefined ? a$5 : undefined,
          gender_lte: a$6 !== undefined ? a$6 : undefined,
          lastName_gt: a$7 !== undefined ? a$7 : undefined,
          lastLogin_ne: a$8 !== undefined ? Caml_option.valFromOption(a$8) : undefined,
          _id_lt: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          lastLogin_gte: a$10 !== undefined ? Caml_option.valFromOption(a$10) : undefined,
          _id: a$11 !== undefined ? Caml_option.valFromOption(a$11) : undefined,
          createdAt_nin: a$12 !== undefined ? a$12.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          gender_gte: a$13 !== undefined ? a$13 : undefined,
          finishedOnboarding_exists: a$14 !== undefined ? a$14 : undefined,
          birthday: a$15 !== undefined ? Caml_option.valFromOption(a$15) : undefined,
          authId_ne: a$16 !== undefined ? a$16 : undefined,
          sentAbandonedOnboardingEmail_exists: a$17 !== undefined ? a$17 : undefined,
          authId_in: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          OR: a$19 !== undefined ? a$19.map(serializeInputObjectUserQueryInput$1) : undefined,
          picture: a$20 !== undefined ? a$20 : undefined,
          phone_exists: a$21 !== undefined ? a$21 : undefined,
          email_gte: a$22 !== undefined ? a$22 : undefined,
          finishedOnboarding: a$23 !== undefined ? a$23 : undefined,
          finishedOnboarding_ne: a$24 !== undefined ? a$24 : undefined,
          updatedAt_in: a$25 !== undefined ? a$25.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          lastLogin_lte: a$26 !== undefined ? Caml_option.valFromOption(a$26) : undefined,
          lastName_lt: a$27 !== undefined ? a$27 : undefined,
          lastLogin: a$28 !== undefined ? Caml_option.valFromOption(a$28) : undefined,
          gender_lt: a$29 !== undefined ? a$29 : undefined,
          updatedAt_ne: a$30 !== undefined ? Caml_option.valFromOption(a$30) : undefined,
          sentAbandonedOnboardingEmail: a$31 !== undefined ? a$31 : undefined,
          lastLogin_in: a$32 !== undefined ? a$32.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          createdAt: a$33 !== undefined ? Caml_option.valFromOption(a$33) : undefined,
          createdAt_gt: a$34 !== undefined ? Caml_option.valFromOption(a$34) : undefined,
          picture_ne: a$35 !== undefined ? a$35 : undefined,
          AND: a$36 !== undefined ? a$36.map(serializeInputObjectUserQueryInput$1) : undefined,
          _id_exists: a$37 !== undefined ? a$37 : undefined,
          birthday_nin: a$38 !== undefined ? a$38.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          gender: a$39 !== undefined ? a$39 : undefined,
          name_lte: a$40 !== undefined ? a$40 : undefined,
          email_exists: a$41 !== undefined ? a$41 : undefined,
          name_ne: a$42 !== undefined ? a$42 : undefined,
          phone_ne: a$43 !== undefined ? a$43 : undefined,
          email_in: a$44 !== undefined ? a$44.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          createdAt_in: a$45 !== undefined ? a$45.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          phone_gte: a$46 !== undefined ? a$46 : undefined,
          updatedAt_gt: a$47 !== undefined ? Caml_option.valFromOption(a$47) : undefined,
          firstName_exists: a$48 !== undefined ? a$48 : undefined,
          authId: a$49 !== undefined ? a$49 : undefined,
          updatedAt_nin: a$50 !== undefined ? a$50.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          updatedAt_lt: a$51 !== undefined ? Caml_option.valFromOption(a$51) : undefined,
          lastName_ne: a$52 !== undefined ? a$52 : undefined,
          picture_lt: a$53 !== undefined ? a$53 : undefined,
          firstName_gte: a$54 !== undefined ? a$54 : undefined,
          firstName_gt: a$55 !== undefined ? a$55 : undefined,
          _id_in: a$56 !== undefined ? a$56.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          lastLogin_nin: a$57 !== undefined ? a$57.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          birthday_in: a$58 !== undefined ? a$58.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          authId_gte: a$59 !== undefined ? a$59 : undefined,
          createdAt_gte: a$60 !== undefined ? Caml_option.valFromOption(a$60) : undefined,
          gender_gt: a$61 !== undefined ? a$61 : undefined,
          lastName_exists: a$62 !== undefined ? a$62 : undefined,
          updatedAt_exists: a$63 !== undefined ? a$63 : undefined,
          createdAt_exists: a$64 !== undefined ? a$64 : undefined,
          name_exists: a$65 !== undefined ? a$65 : undefined,
          address: a$66 !== undefined ? serializeInputObjectUserAddressQueryInput$1(a$66) : undefined,
          birthday_gte: a$67 !== undefined ? Caml_option.valFromOption(a$67) : undefined,
          name: a$68 !== undefined ? a$68 : undefined,
          birthday_gt: a$69 !== undefined ? Caml_option.valFromOption(a$69) : undefined,
          picture_nin: a$70 !== undefined ? a$70.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          lastName_nin: a$71 !== undefined ? a$71.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          phone_in: a$72 !== undefined ? a$72.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          picture_gte: a$73 !== undefined ? a$73 : undefined,
          _id_lte: a$74 !== undefined ? Caml_option.valFromOption(a$74) : undefined,
          firstName_nin: a$75 !== undefined ? a$75.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          sentAbandonedOnboardingEmail_ne: a$76 !== undefined ? a$76 : undefined,
          phone_lt: a$77 !== undefined ? a$77 : undefined,
          birthday_ne: a$78 !== undefined ? Caml_option.valFromOption(a$78) : undefined,
          name_nin: a$79 !== undefined ? a$79.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          firstName_lt: a$80 !== undefined ? a$80 : undefined,
          phone: a$81 !== undefined ? a$81 : undefined,
          email_ne: a$82 !== undefined ? a$82 : undefined,
          _id_gte: a$83 !== undefined ? Caml_option.valFromOption(a$83) : undefined,
          email: a$84 !== undefined ? a$84 : undefined,
          birthday_lt: a$85 !== undefined ? Caml_option.valFromOption(a$85) : undefined,
          updatedAt_lte: a$86 !== undefined ? Caml_option.valFromOption(a$86) : undefined,
          address_exists: a$87 !== undefined ? a$87 : undefined,
          updatedAt_gte: a$88 !== undefined ? Caml_option.valFromOption(a$88) : undefined,
          createdAt_ne: a$89 !== undefined ? Caml_option.valFromOption(a$89) : undefined,
          lastName_lte: a$90 !== undefined ? a$90 : undefined,
          birthday_exists: a$91 !== undefined ? a$91 : undefined,
          authId_lt: a$92 !== undefined ? a$92 : undefined,
          picture_exists: a$93 !== undefined ? a$93 : undefined,
          updatedAt: a$94 !== undefined ? Caml_option.valFromOption(a$94) : undefined,
          firstName_in: a$95 !== undefined ? a$95.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          name_gte: a$96 !== undefined ? a$96 : undefined,
          picture_in: a$97 !== undefined ? a$97.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          phone_nin: a$98 !== undefined ? a$98.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          createdAt_lte: a$99 !== undefined ? Caml_option.valFromOption(a$99) : undefined,
          _id_ne: a$100 !== undefined ? Caml_option.valFromOption(a$100) : undefined,
          firstName_lte: a$101 !== undefined ? a$101 : undefined,
          authId_nin: a$102 !== undefined ? a$102.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          firstName_ne: a$103 !== undefined ? a$103 : undefined,
          gender_nin: a$104 !== undefined ? a$104.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          picture_lte: a$105 !== undefined ? a$105 : undefined,
          authId_exists: a$106 !== undefined ? a$106 : undefined,
          _id_gt: a$107 !== undefined ? Caml_option.valFromOption(a$107) : undefined,
          lastLogin_lt: a$108 !== undefined ? Caml_option.valFromOption(a$108) : undefined,
          gender_in: a$109 !== undefined ? a$109.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          lastName_gte: a$110 !== undefined ? a$110 : undefined,
          authId_gt: a$111 !== undefined ? a$111 : undefined,
          birthday_lte: a$112 !== undefined ? Caml_option.valFromOption(a$112) : undefined,
          createdAt_lt: a$113 !== undefined ? Caml_option.valFromOption(a$113) : undefined,
          firstName: a$114 !== undefined ? a$114 : undefined,
          picture_gt: a$115 !== undefined ? a$115 : undefined,
          email_lt: a$116 !== undefined ? a$116 : undefined,
          lastLogin_gt: a$117 !== undefined ? Caml_option.valFromOption(a$117) : undefined,
          lastName: a$118 !== undefined ? a$118 : undefined,
          email_nin: a$119 !== undefined ? a$119.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          email_gt: a$120 !== undefined ? a$120 : undefined,
          name_gt: a$121 !== undefined ? a$121 : undefined,
          name_lt: a$122 !== undefined ? a$122 : undefined,
          authId_lte: a$123 !== undefined ? a$123 : undefined,
          gender_ne: a$124 !== undefined ? a$124 : undefined,
          email_lte: a$125 !== undefined ? a$125 : undefined,
          name_in: a$126 !== undefined ? a$126.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined
        };
}

function serializeInputObjectUserAddressQueryInput$1(inp) {
  var a = inp.street1_lte;
  var a$1 = inp.zip_nin;
  var a$2 = inp.zip_exists;
  var a$3 = inp.city_lt;
  var a$4 = inp.zip_lt;
  var a$5 = inp.AND;
  var a$6 = inp.city;
  var a$7 = inp.state_lte;
  var a$8 = inp.street1_ne;
  var a$9 = inp.street1_exists;
  var a$10 = inp.state_ne;
  var a$11 = inp.street2_exists;
  var a$12 = inp.state_gt;
  var a$13 = inp.street1_in;
  var a$14 = inp.zip;
  var a$15 = inp.city_exists;
  var a$16 = inp.state;
  var a$17 = inp.street2_lte;
  var a$18 = inp.street2_in;
  var a$19 = inp.zip_gte;
  var a$20 = inp.state_nin;
  var a$21 = inp.city_ne;
  var a$22 = inp.city_lte;
  var a$23 = inp.street1_gte;
  var a$24 = inp.street2_ne;
  var a$25 = inp.city_nin;
  var a$26 = inp.zip_gt;
  var a$27 = inp.city_in;
  var a$28 = inp.street2_gte;
  var a$29 = inp.street2_gt;
  var a$30 = inp.zip_ne;
  var a$31 = inp.state_gte;
  var a$32 = inp.state_lt;
  var a$33 = inp.zip_lte;
  var a$34 = inp.state_in;
  var a$35 = inp.OR;
  var a$36 = inp.zip_in;
  var a$37 = inp.street2_nin;
  var a$38 = inp.street1_gt;
  var a$39 = inp.city_gte;
  var a$40 = inp.city_gt;
  var a$41 = inp.street2;
  var a$42 = inp.street2_lt;
  var a$43 = inp.street1;
  var a$44 = inp.street1_lt;
  var a$45 = inp.state_exists;
  var a$46 = inp.street1_nin;
  return {
          street1_lte: a !== undefined ? a : undefined,
          zip_nin: a$1 !== undefined ? a$1.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          zip_exists: a$2 !== undefined ? a$2 : undefined,
          city_lt: a$3 !== undefined ? a$3 : undefined,
          zip_lt: a$4 !== undefined ? a$4 : undefined,
          AND: a$5 !== undefined ? a$5.map(serializeInputObjectUserAddressQueryInput$1) : undefined,
          city: a$6 !== undefined ? a$6 : undefined,
          state_lte: a$7 !== undefined ? a$7 : undefined,
          street1_ne: a$8 !== undefined ? a$8 : undefined,
          street1_exists: a$9 !== undefined ? a$9 : undefined,
          state_ne: a$10 !== undefined ? a$10 : undefined,
          street2_exists: a$11 !== undefined ? a$11 : undefined,
          state_gt: a$12 !== undefined ? a$12 : undefined,
          street1_in: a$13 !== undefined ? a$13.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          zip: a$14 !== undefined ? a$14 : undefined,
          city_exists: a$15 !== undefined ? a$15 : undefined,
          state: a$16 !== undefined ? a$16 : undefined,
          street2_lte: a$17 !== undefined ? a$17 : undefined,
          street2_in: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          zip_gte: a$19 !== undefined ? a$19 : undefined,
          state_nin: a$20 !== undefined ? a$20.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          city_ne: a$21 !== undefined ? a$21 : undefined,
          city_lte: a$22 !== undefined ? a$22 : undefined,
          street1_gte: a$23 !== undefined ? a$23 : undefined,
          street2_ne: a$24 !== undefined ? a$24 : undefined,
          city_nin: a$25 !== undefined ? a$25.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          zip_gt: a$26 !== undefined ? a$26 : undefined,
          city_in: a$27 !== undefined ? a$27.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          street2_gte: a$28 !== undefined ? a$28 : undefined,
          street2_gt: a$29 !== undefined ? a$29 : undefined,
          zip_ne: a$30 !== undefined ? a$30 : undefined,
          state_gte: a$31 !== undefined ? a$31 : undefined,
          state_lt: a$32 !== undefined ? a$32 : undefined,
          zip_lte: a$33 !== undefined ? a$33 : undefined,
          state_in: a$34 !== undefined ? a$34.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          OR: a$35 !== undefined ? a$35.map(serializeInputObjectUserAddressQueryInput$1) : undefined,
          zip_in: a$36 !== undefined ? a$36.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          street2_nin: a$37 !== undefined ? a$37.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          street1_gt: a$38 !== undefined ? a$38 : undefined,
          city_gte: a$39 !== undefined ? a$39 : undefined,
          city_gt: a$40 !== undefined ? a$40 : undefined,
          street2: a$41 !== undefined ? a$41 : undefined,
          street2_lt: a$42 !== undefined ? a$42 : undefined,
          street1: a$43 !== undefined ? a$43 : undefined,
          street1_lt: a$44 !== undefined ? a$44 : undefined,
          state_exists: a$45 !== undefined ? a$45 : undefined,
          street1_nin: a$46 !== undefined ? a$46.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined
        };
}

function serializeVariables$1(inp) {
  var a = inp.query;
  return {
          query: a !== undefined ? serializeInputObjectGifteeQueryInput$1(a) : undefined
        };
}

function makeVariables$1(query, param) {
  return {
          query: query
        };
}

function makeInputObjectGifteeQueryInput$1(age_ne, interests_exists, createdAt_gte, picture_exists, age_lt, _id, picture_in, AND, updatedAt_gt, updatedAt_lte, status_gte, sourceUser_exists, _id_exists, createdAt_ne, isCustom_exists, updatedAt_gte, status_lte, name_gte, createdAt_exists, age_nin, picture, interests_nin, interests_in, relationship_gte, relationship_lte, age_exists, name, name_lte, userId_in, isPendingAcceptance, relationship_ne, age_in, name_nin, picture_gte, status_lt, age, updatedAt_exists, userId_nin, _id_gt, userId_ne, status_exists, sourceUser, userId_gt, _id_gte, createdAt_in, updatedAt_ne, isPendingAcceptance_ne, relationship_exists, _id_lte, _id_ne, isCustom, _id_nin, status_ne, createdAt_nin, status_nin, userId_lte, name_ne, picture_lte, createdAt_lt, status_in, updatedAt_in, _id_in, _id_lt, createdAt, userId_exists, age_gte, picture_lt, updatedAt, name_lt, status_gt, relationship_nin, age_lte, relationship_lt, userId_gte, status, createdAt_lte, name_in, relationship_in, isCustom_ne, isPendingAcceptance_exists, OR, updatedAt_lt, createdAt_gt, relationship_gt, name_gt, interests, age_gt, picture_nin, picture_ne, relationship, picture_gt, name_exists, userId_lt, userId, updatedAt_nin, param) {
  return {
          age_ne: age_ne,
          interests_exists: interests_exists,
          createdAt_gte: createdAt_gte,
          picture_exists: picture_exists,
          age_lt: age_lt,
          _id: _id,
          picture_in: picture_in,
          AND: AND,
          updatedAt_gt: updatedAt_gt,
          updatedAt_lte: updatedAt_lte,
          status_gte: status_gte,
          sourceUser_exists: sourceUser_exists,
          _id_exists: _id_exists,
          createdAt_ne: createdAt_ne,
          isCustom_exists: isCustom_exists,
          updatedAt_gte: updatedAt_gte,
          status_lte: status_lte,
          name_gte: name_gte,
          createdAt_exists: createdAt_exists,
          age_nin: age_nin,
          picture: picture,
          interests_nin: interests_nin,
          interests_in: interests_in,
          relationship_gte: relationship_gte,
          relationship_lte: relationship_lte,
          age_exists: age_exists,
          name: name,
          name_lte: name_lte,
          userId_in: userId_in,
          isPendingAcceptance: isPendingAcceptance,
          relationship_ne: relationship_ne,
          age_in: age_in,
          name_nin: name_nin,
          picture_gte: picture_gte,
          status_lt: status_lt,
          age: age,
          updatedAt_exists: updatedAt_exists,
          userId_nin: userId_nin,
          _id_gt: _id_gt,
          userId_ne: userId_ne,
          status_exists: status_exists,
          sourceUser: sourceUser,
          userId_gt: userId_gt,
          _id_gte: _id_gte,
          createdAt_in: createdAt_in,
          updatedAt_ne: updatedAt_ne,
          isPendingAcceptance_ne: isPendingAcceptance_ne,
          relationship_exists: relationship_exists,
          _id_lte: _id_lte,
          _id_ne: _id_ne,
          isCustom: isCustom,
          _id_nin: _id_nin,
          status_ne: status_ne,
          createdAt_nin: createdAt_nin,
          status_nin: status_nin,
          userId_lte: userId_lte,
          name_ne: name_ne,
          picture_lte: picture_lte,
          createdAt_lt: createdAt_lt,
          status_in: status_in,
          updatedAt_in: updatedAt_in,
          _id_in: _id_in,
          _id_lt: _id_lt,
          createdAt: createdAt,
          userId_exists: userId_exists,
          age_gte: age_gte,
          picture_lt: picture_lt,
          updatedAt: updatedAt,
          name_lt: name_lt,
          status_gt: status_gt,
          relationship_nin: relationship_nin,
          age_lte: age_lte,
          relationship_lt: relationship_lt,
          userId_gte: userId_gte,
          status: status,
          createdAt_lte: createdAt_lte,
          name_in: name_in,
          relationship_in: relationship_in,
          isCustom_ne: isCustom_ne,
          isPendingAcceptance_exists: isPendingAcceptance_exists,
          OR: OR,
          updatedAt_lt: updatedAt_lt,
          createdAt_gt: createdAt_gt,
          relationship_gt: relationship_gt,
          name_gt: name_gt,
          interests: interests,
          age_gt: age_gt,
          picture_nin: picture_nin,
          picture_ne: picture_ne,
          relationship: relationship,
          picture_gt: picture_gt,
          name_exists: name_exists,
          userId_lt: userId_lt,
          userId: userId,
          updatedAt_nin: updatedAt_nin
        };
}

function makeInputObjectUserQueryInput$1(_id_nin, phone_gt, phone_lte, lastLogin_exists, lastName_in, gender_exists, gender_lte, lastName_gt, lastLogin_ne, _id_lt, lastLogin_gte, _id, createdAt_nin, gender_gte, finishedOnboarding_exists, birthday, authId_ne, sentAbandonedOnboardingEmail_exists, authId_in, OR, picture, phone_exists, email_gte, finishedOnboarding, finishedOnboarding_ne, updatedAt_in, lastLogin_lte, lastName_lt, lastLogin, gender_lt, updatedAt_ne, sentAbandonedOnboardingEmail, lastLogin_in, createdAt, createdAt_gt, picture_ne, AND, _id_exists, birthday_nin, gender, name_lte, email_exists, name_ne, phone_ne, email_in, createdAt_in, phone_gte, updatedAt_gt, firstName_exists, authId, updatedAt_nin, updatedAt_lt, lastName_ne, picture_lt, firstName_gte, firstName_gt, _id_in, lastLogin_nin, birthday_in, authId_gte, createdAt_gte, gender_gt, lastName_exists, updatedAt_exists, createdAt_exists, name_exists, address, birthday_gte, name, birthday_gt, picture_nin, lastName_nin, phone_in, picture_gte, _id_lte, firstName_nin, sentAbandonedOnboardingEmail_ne, phone_lt, birthday_ne, name_nin, firstName_lt, phone, email_ne, _id_gte, email, birthday_lt, updatedAt_lte, address_exists, updatedAt_gte, createdAt_ne, lastName_lte, birthday_exists, authId_lt, picture_exists, updatedAt, firstName_in, name_gte, picture_in, phone_nin, createdAt_lte, _id_ne, firstName_lte, authId_nin, firstName_ne, gender_nin, picture_lte, authId_exists, _id_gt, lastLogin_lt, gender_in, lastName_gte, authId_gt, birthday_lte, createdAt_lt, firstName, picture_gt, email_lt, lastLogin_gt, lastName, email_nin, email_gt, name_gt, name_lt, authId_lte, gender_ne, email_lte, name_in, param) {
  return {
          _id_nin: _id_nin,
          phone_gt: phone_gt,
          phone_lte: phone_lte,
          lastLogin_exists: lastLogin_exists,
          lastName_in: lastName_in,
          gender_exists: gender_exists,
          gender_lte: gender_lte,
          lastName_gt: lastName_gt,
          lastLogin_ne: lastLogin_ne,
          _id_lt: _id_lt,
          lastLogin_gte: lastLogin_gte,
          _id: _id,
          createdAt_nin: createdAt_nin,
          gender_gte: gender_gte,
          finishedOnboarding_exists: finishedOnboarding_exists,
          birthday: birthday,
          authId_ne: authId_ne,
          sentAbandonedOnboardingEmail_exists: sentAbandonedOnboardingEmail_exists,
          authId_in: authId_in,
          OR: OR,
          picture: picture,
          phone_exists: phone_exists,
          email_gte: email_gte,
          finishedOnboarding: finishedOnboarding,
          finishedOnboarding_ne: finishedOnboarding_ne,
          updatedAt_in: updatedAt_in,
          lastLogin_lte: lastLogin_lte,
          lastName_lt: lastName_lt,
          lastLogin: lastLogin,
          gender_lt: gender_lt,
          updatedAt_ne: updatedAt_ne,
          sentAbandonedOnboardingEmail: sentAbandonedOnboardingEmail,
          lastLogin_in: lastLogin_in,
          createdAt: createdAt,
          createdAt_gt: createdAt_gt,
          picture_ne: picture_ne,
          AND: AND,
          _id_exists: _id_exists,
          birthday_nin: birthday_nin,
          gender: gender,
          name_lte: name_lte,
          email_exists: email_exists,
          name_ne: name_ne,
          phone_ne: phone_ne,
          email_in: email_in,
          createdAt_in: createdAt_in,
          phone_gte: phone_gte,
          updatedAt_gt: updatedAt_gt,
          firstName_exists: firstName_exists,
          authId: authId,
          updatedAt_nin: updatedAt_nin,
          updatedAt_lt: updatedAt_lt,
          lastName_ne: lastName_ne,
          picture_lt: picture_lt,
          firstName_gte: firstName_gte,
          firstName_gt: firstName_gt,
          _id_in: _id_in,
          lastLogin_nin: lastLogin_nin,
          birthday_in: birthday_in,
          authId_gte: authId_gte,
          createdAt_gte: createdAt_gte,
          gender_gt: gender_gt,
          lastName_exists: lastName_exists,
          updatedAt_exists: updatedAt_exists,
          createdAt_exists: createdAt_exists,
          name_exists: name_exists,
          address: address,
          birthday_gte: birthday_gte,
          name: name,
          birthday_gt: birthday_gt,
          picture_nin: picture_nin,
          lastName_nin: lastName_nin,
          phone_in: phone_in,
          picture_gte: picture_gte,
          _id_lte: _id_lte,
          firstName_nin: firstName_nin,
          sentAbandonedOnboardingEmail_ne: sentAbandonedOnboardingEmail_ne,
          phone_lt: phone_lt,
          birthday_ne: birthday_ne,
          name_nin: name_nin,
          firstName_lt: firstName_lt,
          phone: phone,
          email_ne: email_ne,
          _id_gte: _id_gte,
          email: email,
          birthday_lt: birthday_lt,
          updatedAt_lte: updatedAt_lte,
          address_exists: address_exists,
          updatedAt_gte: updatedAt_gte,
          createdAt_ne: createdAt_ne,
          lastName_lte: lastName_lte,
          birthday_exists: birthday_exists,
          authId_lt: authId_lt,
          picture_exists: picture_exists,
          updatedAt: updatedAt,
          firstName_in: firstName_in,
          name_gte: name_gte,
          picture_in: picture_in,
          phone_nin: phone_nin,
          createdAt_lte: createdAt_lte,
          _id_ne: _id_ne,
          firstName_lte: firstName_lte,
          authId_nin: authId_nin,
          firstName_ne: firstName_ne,
          gender_nin: gender_nin,
          picture_lte: picture_lte,
          authId_exists: authId_exists,
          _id_gt: _id_gt,
          lastLogin_lt: lastLogin_lt,
          gender_in: gender_in,
          lastName_gte: lastName_gte,
          authId_gt: authId_gt,
          birthday_lte: birthday_lte,
          createdAt_lt: createdAt_lt,
          firstName: firstName,
          picture_gt: picture_gt,
          email_lt: email_lt,
          lastLogin_gt: lastLogin_gt,
          lastName: lastName,
          email_nin: email_nin,
          email_gt: email_gt,
          name_gt: name_gt,
          name_lt: name_lt,
          authId_lte: authId_lte,
          gender_ne: gender_ne,
          email_lte: email_lte,
          name_in: name_in
        };
}

function makeInputObjectUserAddressQueryInput$1(street1_lte, zip_nin, zip_exists, city_lt, zip_lt, AND, city, state_lte, street1_ne, street1_exists, state_ne, street2_exists, state_gt, street1_in, zip, city_exists, state, street2_lte, street2_in, zip_gte, state_nin, city_ne, city_lte, street1_gte, street2_ne, city_nin, zip_gt, city_in, street2_gte, street2_gt, zip_ne, state_gte, state_lt, zip_lte, state_in, OR, zip_in, street2_nin, street1_gt, city_gte, city_gt, street2, street2_lt, street1, street1_lt, state_exists, street1_nin, param) {
  return {
          street1_lte: street1_lte,
          zip_nin: zip_nin,
          zip_exists: zip_exists,
          city_lt: city_lt,
          zip_lt: zip_lt,
          AND: AND,
          city: city,
          state_lte: state_lte,
          street1_ne: street1_ne,
          street1_exists: street1_exists,
          state_ne: state_ne,
          street2_exists: street2_exists,
          state_gt: state_gt,
          street1_in: street1_in,
          zip: zip,
          city_exists: city_exists,
          state: state,
          street2_lte: street2_lte,
          street2_in: street2_in,
          zip_gte: zip_gte,
          state_nin: state_nin,
          city_ne: city_ne,
          city_lte: city_lte,
          street1_gte: street1_gte,
          street2_ne: street2_ne,
          city_nin: city_nin,
          zip_gt: zip_gt,
          city_in: city_in,
          street2_gte: street2_gte,
          street2_gt: street2_gt,
          zip_ne: zip_ne,
          state_gte: state_gte,
          state_lt: state_lt,
          zip_lte: zip_lte,
          state_in: state_in,
          OR: OR,
          zip_in: zip_in,
          street2_nin: street2_nin,
          street1_gt: street1_gt,
          city_gte: city_gte,
          city_gt: city_gt,
          street2: street2,
          street2_lt: street2_lt,
          street1: street1,
          street1_lt: street1_lt,
          state_exists: state_exists,
          street1_nin: street1_nin
        };
}

function makeDefaultVariables$1(param) {
  return {
          query: undefined
        };
}

var GetGifteeQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectGifteeQueryInput: serializeInputObjectGifteeQueryInput$1,
  serializeInputObjectUserQueryInput: serializeInputObjectUserQueryInput$1,
  serializeInputObjectUserAddressQueryInput: serializeInputObjectUserAddressQueryInput$1,
  makeVariables: makeVariables$1,
  makeInputObjectGifteeQueryInput: makeInputObjectGifteeQueryInput$1,
  makeInputObjectUserQueryInput: makeInputObjectUserQueryInput$1,
  makeInputObjectUserAddressQueryInput: makeInputObjectUserAddressQueryInput$1,
  makeDefaultVariables: makeDefaultVariables$1
};

var include$1 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var GetGifteeQuery_refetchQueryDescription = include$1.refetchQueryDescription;

var GetGifteeQuery_use = include$1.use;

var GetGifteeQuery_useLazy = include$1.useLazy;

var GetGifteeQuery_useLazyWithVariables = include$1.useLazyWithVariables;

var GetGifteeQuery = {
  GetGifteeQuery_inner: GetGifteeQuery_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectGifteeQueryInput: serializeInputObjectGifteeQueryInput$1,
  serializeInputObjectUserQueryInput: serializeInputObjectUserQueryInput$1,
  serializeInputObjectUserAddressQueryInput: serializeInputObjectUserAddressQueryInput$1,
  makeVariables: makeVariables$1,
  makeInputObjectGifteeQueryInput: makeInputObjectGifteeQueryInput$1,
  makeInputObjectUserQueryInput: makeInputObjectUserQueryInput$1,
  makeInputObjectUserAddressQueryInput: makeInputObjectUserAddressQueryInput$1,
  makeDefaultVariables: makeDefaultVariables$1,
  refetchQueryDescription: GetGifteeQuery_refetchQueryDescription,
  use: GetGifteeQuery_use,
  useLazy: GetGifteeQuery_useLazy,
  useLazyWithVariables: GetGifteeQuery_useLazyWithVariables
};

var Raw$2 = {};

var query$2 = (require("@apollo/client").gql`
  query GetGifteeIdAndSourceUserQuery($query: GifteeQueryInput)  {
    giftee(query: $query)  {
      __typename
      _id
      sourceUser  {
        __typename
        _id
      }
    }
  }
`);

function parse$2(value) {
  var value$1 = value.giftee;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1._id;
    var value$3 = value$1.sourceUser;
    var tmp$1;
    if (value$3 == null) {
      tmp$1 = undefined;
    } else {
      var value$4 = value$3._id;
      tmp$1 = {
        __typename: value$3.__typename,
        _id: !(value$4 == null) ? Caml_option.some(value$4) : undefined
      };
    }
    tmp = {
      __typename: value$1.__typename,
      _id: !(value$2 == null) ? Caml_option.some(value$2) : undefined,
      sourceUser: tmp$1
    };
  }
  return {
          giftee: tmp
        };
}

function serialize$2(value) {
  var value$1 = value.giftee;
  var giftee;
  if (value$1 !== undefined) {
    var value$2 = value$1.sourceUser;
    var sourceUser;
    if (value$2 !== undefined) {
      var value$3 = value$2._id;
      var _id = value$3 !== undefined ? Caml_option.valFromOption(value$3) : null;
      var value$4 = value$2.__typename;
      sourceUser = {
        __typename: value$4,
        _id: _id
      };
    } else {
      sourceUser = null;
    }
    var value$5 = value$1._id;
    var _id$1 = value$5 !== undefined ? Caml_option.valFromOption(value$5) : null;
    var value$6 = value$1.__typename;
    giftee = {
      __typename: value$6,
      _id: _id$1,
      sourceUser: sourceUser
    };
  } else {
    giftee = null;
  }
  return {
          giftee: giftee
        };
}

function serializeInputObjectUserAddressQueryInput$2(inp) {
  var a = inp.street1_lte;
  var a$1 = inp.zip_nin;
  var a$2 = inp.zip_exists;
  var a$3 = inp.city_lt;
  var a$4 = inp.zip_lt;
  var a$5 = inp.AND;
  var a$6 = inp.city;
  var a$7 = inp.state_lte;
  var a$8 = inp.street1_ne;
  var a$9 = inp.street1_exists;
  var a$10 = inp.state_ne;
  var a$11 = inp.street2_exists;
  var a$12 = inp.state_gt;
  var a$13 = inp.street1_in;
  var a$14 = inp.zip;
  var a$15 = inp.city_exists;
  var a$16 = inp.state;
  var a$17 = inp.street2_lte;
  var a$18 = inp.street2_in;
  var a$19 = inp.zip_gte;
  var a$20 = inp.state_nin;
  var a$21 = inp.city_ne;
  var a$22 = inp.city_lte;
  var a$23 = inp.street1_gte;
  var a$24 = inp.street2_ne;
  var a$25 = inp.city_nin;
  var a$26 = inp.zip_gt;
  var a$27 = inp.city_in;
  var a$28 = inp.street2_gte;
  var a$29 = inp.street2_gt;
  var a$30 = inp.zip_ne;
  var a$31 = inp.state_gte;
  var a$32 = inp.state_lt;
  var a$33 = inp.zip_lte;
  var a$34 = inp.state_in;
  var a$35 = inp.OR;
  var a$36 = inp.zip_in;
  var a$37 = inp.street2_nin;
  var a$38 = inp.street1_gt;
  var a$39 = inp.city_gte;
  var a$40 = inp.city_gt;
  var a$41 = inp.street2;
  var a$42 = inp.street2_lt;
  var a$43 = inp.street1;
  var a$44 = inp.street1_lt;
  var a$45 = inp.state_exists;
  var a$46 = inp.street1_nin;
  return {
          street1_lte: a !== undefined ? a : undefined,
          zip_nin: a$1 !== undefined ? a$1.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          zip_exists: a$2 !== undefined ? a$2 : undefined,
          city_lt: a$3 !== undefined ? a$3 : undefined,
          zip_lt: a$4 !== undefined ? a$4 : undefined,
          AND: a$5 !== undefined ? a$5.map(serializeInputObjectUserAddressQueryInput$2) : undefined,
          city: a$6 !== undefined ? a$6 : undefined,
          state_lte: a$7 !== undefined ? a$7 : undefined,
          street1_ne: a$8 !== undefined ? a$8 : undefined,
          street1_exists: a$9 !== undefined ? a$9 : undefined,
          state_ne: a$10 !== undefined ? a$10 : undefined,
          street2_exists: a$11 !== undefined ? a$11 : undefined,
          state_gt: a$12 !== undefined ? a$12 : undefined,
          street1_in: a$13 !== undefined ? a$13.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          zip: a$14 !== undefined ? a$14 : undefined,
          city_exists: a$15 !== undefined ? a$15 : undefined,
          state: a$16 !== undefined ? a$16 : undefined,
          street2_lte: a$17 !== undefined ? a$17 : undefined,
          street2_in: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          zip_gte: a$19 !== undefined ? a$19 : undefined,
          state_nin: a$20 !== undefined ? a$20.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          city_ne: a$21 !== undefined ? a$21 : undefined,
          city_lte: a$22 !== undefined ? a$22 : undefined,
          street1_gte: a$23 !== undefined ? a$23 : undefined,
          street2_ne: a$24 !== undefined ? a$24 : undefined,
          city_nin: a$25 !== undefined ? a$25.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          zip_gt: a$26 !== undefined ? a$26 : undefined,
          city_in: a$27 !== undefined ? a$27.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          street2_gte: a$28 !== undefined ? a$28 : undefined,
          street2_gt: a$29 !== undefined ? a$29 : undefined,
          zip_ne: a$30 !== undefined ? a$30 : undefined,
          state_gte: a$31 !== undefined ? a$31 : undefined,
          state_lt: a$32 !== undefined ? a$32 : undefined,
          zip_lte: a$33 !== undefined ? a$33 : undefined,
          state_in: a$34 !== undefined ? a$34.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          OR: a$35 !== undefined ? a$35.map(serializeInputObjectUserAddressQueryInput$2) : undefined,
          zip_in: a$36 !== undefined ? a$36.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          street2_nin: a$37 !== undefined ? a$37.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          street1_gt: a$38 !== undefined ? a$38 : undefined,
          city_gte: a$39 !== undefined ? a$39 : undefined,
          city_gt: a$40 !== undefined ? a$40 : undefined,
          street2: a$41 !== undefined ? a$41 : undefined,
          street2_lt: a$42 !== undefined ? a$42 : undefined,
          street1: a$43 !== undefined ? a$43 : undefined,
          street1_lt: a$44 !== undefined ? a$44 : undefined,
          state_exists: a$45 !== undefined ? a$45 : undefined,
          street1_nin: a$46 !== undefined ? a$46.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined
        };
}

function serializeInputObjectGifteeQueryInput$2(inp) {
  var a = inp.age_ne;
  var a$1 = inp.interests_exists;
  var a$2 = inp.createdAt_gte;
  var a$3 = inp.picture_exists;
  var a$4 = inp.age_lt;
  var a$5 = inp._id;
  var a$6 = inp.picture_in;
  var a$7 = inp.AND;
  var a$8 = inp.updatedAt_gt;
  var a$9 = inp.updatedAt_lte;
  var a$10 = inp.status_gte;
  var a$11 = inp.sourceUser_exists;
  var a$12 = inp._id_exists;
  var a$13 = inp.createdAt_ne;
  var a$14 = inp.isCustom_exists;
  var a$15 = inp.updatedAt_gte;
  var a$16 = inp.status_lte;
  var a$17 = inp.name_gte;
  var a$18 = inp.createdAt_exists;
  var a$19 = inp.age_nin;
  var a$20 = inp.picture;
  var a$21 = inp.interests_nin;
  var a$22 = inp.interests_in;
  var a$23 = inp.relationship_gte;
  var a$24 = inp.relationship_lte;
  var a$25 = inp.age_exists;
  var a$26 = inp.name;
  var a$27 = inp.name_lte;
  var a$28 = inp.userId_in;
  var a$29 = inp.isPendingAcceptance;
  var a$30 = inp.relationship_ne;
  var a$31 = inp.age_in;
  var a$32 = inp.name_nin;
  var a$33 = inp.picture_gte;
  var a$34 = inp.status_lt;
  var a$35 = inp.age;
  var a$36 = inp.updatedAt_exists;
  var a$37 = inp.userId_nin;
  var a$38 = inp._id_gt;
  var a$39 = inp.userId_ne;
  var a$40 = inp.status_exists;
  var a$41 = inp.sourceUser;
  var a$42 = inp.userId_gt;
  var a$43 = inp._id_gte;
  var a$44 = inp.createdAt_in;
  var a$45 = inp.updatedAt_ne;
  var a$46 = inp.isPendingAcceptance_ne;
  var a$47 = inp.relationship_exists;
  var a$48 = inp._id_lte;
  var a$49 = inp._id_ne;
  var a$50 = inp.isCustom;
  var a$51 = inp._id_nin;
  var a$52 = inp.status_ne;
  var a$53 = inp.createdAt_nin;
  var a$54 = inp.status_nin;
  var a$55 = inp.userId_lte;
  var a$56 = inp.name_ne;
  var a$57 = inp.picture_lte;
  var a$58 = inp.createdAt_lt;
  var a$59 = inp.status_in;
  var a$60 = inp.updatedAt_in;
  var a$61 = inp._id_in;
  var a$62 = inp._id_lt;
  var a$63 = inp.createdAt;
  var a$64 = inp.userId_exists;
  var a$65 = inp.age_gte;
  var a$66 = inp.picture_lt;
  var a$67 = inp.updatedAt;
  var a$68 = inp.name_lt;
  var a$69 = inp.status_gt;
  var a$70 = inp.relationship_nin;
  var a$71 = inp.age_lte;
  var a$72 = inp.relationship_lt;
  var a$73 = inp.userId_gte;
  var a$74 = inp.status;
  var a$75 = inp.createdAt_lte;
  var a$76 = inp.name_in;
  var a$77 = inp.relationship_in;
  var a$78 = inp.isCustom_ne;
  var a$79 = inp.isPendingAcceptance_exists;
  var a$80 = inp.OR;
  var a$81 = inp.updatedAt_lt;
  var a$82 = inp.createdAt_gt;
  var a$83 = inp.relationship_gt;
  var a$84 = inp.name_gt;
  var a$85 = inp.interests;
  var a$86 = inp.age_gt;
  var a$87 = inp.picture_nin;
  var a$88 = inp.picture_ne;
  var a$89 = inp.relationship;
  var a$90 = inp.picture_gt;
  var a$91 = inp.name_exists;
  var a$92 = inp.userId_lt;
  var a$93 = inp.userId;
  var a$94 = inp.updatedAt_nin;
  return {
          age_ne: a !== undefined ? a : undefined,
          interests_exists: a$1 !== undefined ? a$1 : undefined,
          createdAt_gte: a$2 !== undefined ? Caml_option.valFromOption(a$2) : undefined,
          picture_exists: a$3 !== undefined ? a$3 : undefined,
          age_lt: a$4 !== undefined ? a$4 : undefined,
          _id: a$5 !== undefined ? Caml_option.valFromOption(a$5) : undefined,
          picture_in: a$6 !== undefined ? a$6.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          AND: a$7 !== undefined ? a$7.map(serializeInputObjectGifteeQueryInput$2) : undefined,
          updatedAt_gt: a$8 !== undefined ? Caml_option.valFromOption(a$8) : undefined,
          updatedAt_lte: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          status_gte: a$10 !== undefined ? (
              a$10 === "ADDED" ? "ADDED" : (
                  a$10 === "PENDING" ? "PENDING" : "APPROVED"
                )
            ) : undefined,
          sourceUser_exists: a$11 !== undefined ? a$11 : undefined,
          _id_exists: a$12 !== undefined ? a$12 : undefined,
          createdAt_ne: a$13 !== undefined ? Caml_option.valFromOption(a$13) : undefined,
          isCustom_exists: a$14 !== undefined ? a$14 : undefined,
          updatedAt_gte: a$15 !== undefined ? Caml_option.valFromOption(a$15) : undefined,
          status_lte: a$16 !== undefined ? (
              a$16 === "ADDED" ? "ADDED" : (
                  a$16 === "PENDING" ? "PENDING" : "APPROVED"
                )
            ) : undefined,
          name_gte: a$17 !== undefined ? a$17 : undefined,
          createdAt_exists: a$18 !== undefined ? a$18 : undefined,
          age_nin: a$19 !== undefined ? a$19.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          picture: a$20 !== undefined ? a$20 : undefined,
          interests_nin: a$21 !== undefined ? a$21.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          interests_in: a$22 !== undefined ? a$22.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          relationship_gte: a$23 !== undefined ? (
              a$23 === "FRIEND" ? "FRIEND" : (
                  a$23 === "SISTER" ? "SISTER" : (
                      a$23 === "GRANDMA" ? "GRANDMA" : (
                          a$23 === "GRANDPA" ? "GRANDPA" : (
                              a$23 === "MOTHER" ? "MOTHER" : (
                                  a$23 === "COUSIN" ? "COUSIN" : (
                                      a$23 === "GIRLFRIEND" ? "GIRLFRIEND" : (
                                          a$23 === "FATHER_IN_LAW" ? "FATHER_IN_LAW" : (
                                              a$23 === "MOTHER_IN_LAW" ? "MOTHER_IN_LAW" : (
                                                  a$23 === "FATHER" ? "FATHER" : (
                                                      a$23 === "BROTHER_IN_LAW" ? "BROTHER_IN_LAW" : (
                                                          a$23 === "HUSBAND" ? "HUSBAND" : (
                                                              a$23 === "UNCLE" ? "UNCLE" : (
                                                                  a$23 === "AUNT" ? "AUNT" : (
                                                                      a$23 === "SISTER_IN_LAW" ? "SISTER_IN_LAW" : (
                                                                          a$23 === "OTHER" ? "OTHER" : (
                                                                              a$23 === "BROTHER" ? "BROTHER" : (
                                                                                  a$23 === "WIFE" ? "WIFE" : (
                                                                                      a$23 === "GENERAL_FAMILY" ? "GENERAL_FAMILY" : "BOYFRIEND"
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          relationship_lte: a$24 !== undefined ? (
              a$24 === "FRIEND" ? "FRIEND" : (
                  a$24 === "SISTER" ? "SISTER" : (
                      a$24 === "GRANDMA" ? "GRANDMA" : (
                          a$24 === "GRANDPA" ? "GRANDPA" : (
                              a$24 === "MOTHER" ? "MOTHER" : (
                                  a$24 === "COUSIN" ? "COUSIN" : (
                                      a$24 === "GIRLFRIEND" ? "GIRLFRIEND" : (
                                          a$24 === "FATHER_IN_LAW" ? "FATHER_IN_LAW" : (
                                              a$24 === "MOTHER_IN_LAW" ? "MOTHER_IN_LAW" : (
                                                  a$24 === "FATHER" ? "FATHER" : (
                                                      a$24 === "BROTHER_IN_LAW" ? "BROTHER_IN_LAW" : (
                                                          a$24 === "HUSBAND" ? "HUSBAND" : (
                                                              a$24 === "UNCLE" ? "UNCLE" : (
                                                                  a$24 === "AUNT" ? "AUNT" : (
                                                                      a$24 === "SISTER_IN_LAW" ? "SISTER_IN_LAW" : (
                                                                          a$24 === "OTHER" ? "OTHER" : (
                                                                              a$24 === "BROTHER" ? "BROTHER" : (
                                                                                  a$24 === "WIFE" ? "WIFE" : (
                                                                                      a$24 === "GENERAL_FAMILY" ? "GENERAL_FAMILY" : "BOYFRIEND"
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          age_exists: a$25 !== undefined ? a$25 : undefined,
          name: a$26 !== undefined ? a$26 : undefined,
          name_lte: a$27 !== undefined ? a$27 : undefined,
          userId_in: a$28 !== undefined ? a$28.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          isPendingAcceptance: a$29 !== undefined ? a$29 : undefined,
          relationship_ne: a$30 !== undefined ? (
              a$30 === "FRIEND" ? "FRIEND" : (
                  a$30 === "SISTER" ? "SISTER" : (
                      a$30 === "GRANDMA" ? "GRANDMA" : (
                          a$30 === "GRANDPA" ? "GRANDPA" : (
                              a$30 === "MOTHER" ? "MOTHER" : (
                                  a$30 === "COUSIN" ? "COUSIN" : (
                                      a$30 === "GIRLFRIEND" ? "GIRLFRIEND" : (
                                          a$30 === "FATHER_IN_LAW" ? "FATHER_IN_LAW" : (
                                              a$30 === "MOTHER_IN_LAW" ? "MOTHER_IN_LAW" : (
                                                  a$30 === "FATHER" ? "FATHER" : (
                                                      a$30 === "BROTHER_IN_LAW" ? "BROTHER_IN_LAW" : (
                                                          a$30 === "HUSBAND" ? "HUSBAND" : (
                                                              a$30 === "UNCLE" ? "UNCLE" : (
                                                                  a$30 === "AUNT" ? "AUNT" : (
                                                                      a$30 === "SISTER_IN_LAW" ? "SISTER_IN_LAW" : (
                                                                          a$30 === "OTHER" ? "OTHER" : (
                                                                              a$30 === "BROTHER" ? "BROTHER" : (
                                                                                  a$30 === "WIFE" ? "WIFE" : (
                                                                                      a$30 === "GENERAL_FAMILY" ? "GENERAL_FAMILY" : "BOYFRIEND"
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          age_in: a$31 !== undefined ? a$31.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          name_nin: a$32 !== undefined ? a$32.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          picture_gte: a$33 !== undefined ? a$33 : undefined,
          status_lt: a$34 !== undefined ? (
              a$34 === "ADDED" ? "ADDED" : (
                  a$34 === "PENDING" ? "PENDING" : "APPROVED"
                )
            ) : undefined,
          age: a$35 !== undefined ? a$35 : undefined,
          updatedAt_exists: a$36 !== undefined ? a$36 : undefined,
          userId_nin: a$37 !== undefined ? a$37.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          _id_gt: a$38 !== undefined ? Caml_option.valFromOption(a$38) : undefined,
          userId_ne: a$39 !== undefined ? Caml_option.valFromOption(a$39) : undefined,
          status_exists: a$40 !== undefined ? a$40 : undefined,
          sourceUser: a$41 !== undefined ? serializeInputObjectUserQueryInput$2(a$41) : undefined,
          userId_gt: a$42 !== undefined ? Caml_option.valFromOption(a$42) : undefined,
          _id_gte: a$43 !== undefined ? Caml_option.valFromOption(a$43) : undefined,
          createdAt_in: a$44 !== undefined ? a$44.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          updatedAt_ne: a$45 !== undefined ? Caml_option.valFromOption(a$45) : undefined,
          isPendingAcceptance_ne: a$46 !== undefined ? a$46 : undefined,
          relationship_exists: a$47 !== undefined ? a$47 : undefined,
          _id_lte: a$48 !== undefined ? Caml_option.valFromOption(a$48) : undefined,
          _id_ne: a$49 !== undefined ? Caml_option.valFromOption(a$49) : undefined,
          isCustom: a$50 !== undefined ? a$50 : undefined,
          _id_nin: a$51 !== undefined ? a$51.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          status_ne: a$52 !== undefined ? (
              a$52 === "ADDED" ? "ADDED" : (
                  a$52 === "PENDING" ? "PENDING" : "APPROVED"
                )
            ) : undefined,
          createdAt_nin: a$53 !== undefined ? a$53.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          status_nin: a$54 !== undefined ? a$54.map(function (b) {
                  if (b !== undefined) {
                    if (b === "ADDED") {
                      return "ADDED";
                    } else if (b === "PENDING") {
                      return "PENDING";
                    } else {
                      return "APPROVED";
                    }
                  }
                  
                }) : undefined,
          userId_lte: a$55 !== undefined ? Caml_option.valFromOption(a$55) : undefined,
          name_ne: a$56 !== undefined ? a$56 : undefined,
          picture_lte: a$57 !== undefined ? a$57 : undefined,
          createdAt_lt: a$58 !== undefined ? Caml_option.valFromOption(a$58) : undefined,
          status_in: a$59 !== undefined ? a$59.map(function (b) {
                  if (b !== undefined) {
                    if (b === "ADDED") {
                      return "ADDED";
                    } else if (b === "PENDING") {
                      return "PENDING";
                    } else {
                      return "APPROVED";
                    }
                  }
                  
                }) : undefined,
          updatedAt_in: a$60 !== undefined ? a$60.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          _id_in: a$61 !== undefined ? a$61.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          _id_lt: a$62 !== undefined ? Caml_option.valFromOption(a$62) : undefined,
          createdAt: a$63 !== undefined ? Caml_option.valFromOption(a$63) : undefined,
          userId_exists: a$64 !== undefined ? a$64 : undefined,
          age_gte: a$65 !== undefined ? a$65 : undefined,
          picture_lt: a$66 !== undefined ? a$66 : undefined,
          updatedAt: a$67 !== undefined ? Caml_option.valFromOption(a$67) : undefined,
          name_lt: a$68 !== undefined ? a$68 : undefined,
          status_gt: a$69 !== undefined ? (
              a$69 === "ADDED" ? "ADDED" : (
                  a$69 === "PENDING" ? "PENDING" : "APPROVED"
                )
            ) : undefined,
          relationship_nin: a$70 !== undefined ? a$70.map(function (b) {
                  if (b !== undefined) {
                    if (b === "FRIEND") {
                      return "FRIEND";
                    } else if (b === "SISTER") {
                      return "SISTER";
                    } else if (b === "GRANDMA") {
                      return "GRANDMA";
                    } else if (b === "GRANDPA") {
                      return "GRANDPA";
                    } else if (b === "MOTHER") {
                      return "MOTHER";
                    } else if (b === "COUSIN") {
                      return "COUSIN";
                    } else if (b === "GIRLFRIEND") {
                      return "GIRLFRIEND";
                    } else if (b === "FATHER_IN_LAW") {
                      return "FATHER_IN_LAW";
                    } else if (b === "MOTHER_IN_LAW") {
                      return "MOTHER_IN_LAW";
                    } else if (b === "FATHER") {
                      return "FATHER";
                    } else if (b === "BROTHER_IN_LAW") {
                      return "BROTHER_IN_LAW";
                    } else if (b === "HUSBAND") {
                      return "HUSBAND";
                    } else if (b === "UNCLE") {
                      return "UNCLE";
                    } else if (b === "AUNT") {
                      return "AUNT";
                    } else if (b === "SISTER_IN_LAW") {
                      return "SISTER_IN_LAW";
                    } else if (b === "OTHER") {
                      return "OTHER";
                    } else if (b === "BROTHER") {
                      return "BROTHER";
                    } else if (b === "WIFE") {
                      return "WIFE";
                    } else if (b === "GENERAL_FAMILY") {
                      return "GENERAL_FAMILY";
                    } else {
                      return "BOYFRIEND";
                    }
                  }
                  
                }) : undefined,
          age_lte: a$71 !== undefined ? a$71 : undefined,
          relationship_lt: a$72 !== undefined ? (
              a$72 === "FRIEND" ? "FRIEND" : (
                  a$72 === "SISTER" ? "SISTER" : (
                      a$72 === "GRANDMA" ? "GRANDMA" : (
                          a$72 === "GRANDPA" ? "GRANDPA" : (
                              a$72 === "MOTHER" ? "MOTHER" : (
                                  a$72 === "COUSIN" ? "COUSIN" : (
                                      a$72 === "GIRLFRIEND" ? "GIRLFRIEND" : (
                                          a$72 === "FATHER_IN_LAW" ? "FATHER_IN_LAW" : (
                                              a$72 === "MOTHER_IN_LAW" ? "MOTHER_IN_LAW" : (
                                                  a$72 === "FATHER" ? "FATHER" : (
                                                      a$72 === "BROTHER_IN_LAW" ? "BROTHER_IN_LAW" : (
                                                          a$72 === "HUSBAND" ? "HUSBAND" : (
                                                              a$72 === "UNCLE" ? "UNCLE" : (
                                                                  a$72 === "AUNT" ? "AUNT" : (
                                                                      a$72 === "SISTER_IN_LAW" ? "SISTER_IN_LAW" : (
                                                                          a$72 === "OTHER" ? "OTHER" : (
                                                                              a$72 === "BROTHER" ? "BROTHER" : (
                                                                                  a$72 === "WIFE" ? "WIFE" : (
                                                                                      a$72 === "GENERAL_FAMILY" ? "GENERAL_FAMILY" : "BOYFRIEND"
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          userId_gte: a$73 !== undefined ? Caml_option.valFromOption(a$73) : undefined,
          status: a$74 !== undefined ? (
              a$74 === "ADDED" ? "ADDED" : (
                  a$74 === "PENDING" ? "PENDING" : "APPROVED"
                )
            ) : undefined,
          createdAt_lte: a$75 !== undefined ? Caml_option.valFromOption(a$75) : undefined,
          name_in: a$76 !== undefined ? a$76.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          relationship_in: a$77 !== undefined ? a$77.map(function (b) {
                  if (b !== undefined) {
                    if (b === "FRIEND") {
                      return "FRIEND";
                    } else if (b === "SISTER") {
                      return "SISTER";
                    } else if (b === "GRANDMA") {
                      return "GRANDMA";
                    } else if (b === "GRANDPA") {
                      return "GRANDPA";
                    } else if (b === "MOTHER") {
                      return "MOTHER";
                    } else if (b === "COUSIN") {
                      return "COUSIN";
                    } else if (b === "GIRLFRIEND") {
                      return "GIRLFRIEND";
                    } else if (b === "FATHER_IN_LAW") {
                      return "FATHER_IN_LAW";
                    } else if (b === "MOTHER_IN_LAW") {
                      return "MOTHER_IN_LAW";
                    } else if (b === "FATHER") {
                      return "FATHER";
                    } else if (b === "BROTHER_IN_LAW") {
                      return "BROTHER_IN_LAW";
                    } else if (b === "HUSBAND") {
                      return "HUSBAND";
                    } else if (b === "UNCLE") {
                      return "UNCLE";
                    } else if (b === "AUNT") {
                      return "AUNT";
                    } else if (b === "SISTER_IN_LAW") {
                      return "SISTER_IN_LAW";
                    } else if (b === "OTHER") {
                      return "OTHER";
                    } else if (b === "BROTHER") {
                      return "BROTHER";
                    } else if (b === "WIFE") {
                      return "WIFE";
                    } else if (b === "GENERAL_FAMILY") {
                      return "GENERAL_FAMILY";
                    } else {
                      return "BOYFRIEND";
                    }
                  }
                  
                }) : undefined,
          isCustom_ne: a$78 !== undefined ? a$78 : undefined,
          isPendingAcceptance_exists: a$79 !== undefined ? a$79 : undefined,
          OR: a$80 !== undefined ? a$80.map(serializeInputObjectGifteeQueryInput$2) : undefined,
          updatedAt_lt: a$81 !== undefined ? Caml_option.valFromOption(a$81) : undefined,
          createdAt_gt: a$82 !== undefined ? Caml_option.valFromOption(a$82) : undefined,
          relationship_gt: a$83 !== undefined ? (
              a$83 === "FRIEND" ? "FRIEND" : (
                  a$83 === "SISTER" ? "SISTER" : (
                      a$83 === "GRANDMA" ? "GRANDMA" : (
                          a$83 === "GRANDPA" ? "GRANDPA" : (
                              a$83 === "MOTHER" ? "MOTHER" : (
                                  a$83 === "COUSIN" ? "COUSIN" : (
                                      a$83 === "GIRLFRIEND" ? "GIRLFRIEND" : (
                                          a$83 === "FATHER_IN_LAW" ? "FATHER_IN_LAW" : (
                                              a$83 === "MOTHER_IN_LAW" ? "MOTHER_IN_LAW" : (
                                                  a$83 === "FATHER" ? "FATHER" : (
                                                      a$83 === "BROTHER_IN_LAW" ? "BROTHER_IN_LAW" : (
                                                          a$83 === "HUSBAND" ? "HUSBAND" : (
                                                              a$83 === "UNCLE" ? "UNCLE" : (
                                                                  a$83 === "AUNT" ? "AUNT" : (
                                                                      a$83 === "SISTER_IN_LAW" ? "SISTER_IN_LAW" : (
                                                                          a$83 === "OTHER" ? "OTHER" : (
                                                                              a$83 === "BROTHER" ? "BROTHER" : (
                                                                                  a$83 === "WIFE" ? "WIFE" : (
                                                                                      a$83 === "GENERAL_FAMILY" ? "GENERAL_FAMILY" : "BOYFRIEND"
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          name_gt: a$84 !== undefined ? a$84 : undefined,
          interests: a$85 !== undefined ? a$85.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          age_gt: a$86 !== undefined ? a$86 : undefined,
          picture_nin: a$87 !== undefined ? a$87.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          picture_ne: a$88 !== undefined ? a$88 : undefined,
          relationship: a$89 !== undefined ? (
              a$89 === "FRIEND" ? "FRIEND" : (
                  a$89 === "SISTER" ? "SISTER" : (
                      a$89 === "GRANDMA" ? "GRANDMA" : (
                          a$89 === "GRANDPA" ? "GRANDPA" : (
                              a$89 === "MOTHER" ? "MOTHER" : (
                                  a$89 === "COUSIN" ? "COUSIN" : (
                                      a$89 === "GIRLFRIEND" ? "GIRLFRIEND" : (
                                          a$89 === "FATHER_IN_LAW" ? "FATHER_IN_LAW" : (
                                              a$89 === "MOTHER_IN_LAW" ? "MOTHER_IN_LAW" : (
                                                  a$89 === "FATHER" ? "FATHER" : (
                                                      a$89 === "BROTHER_IN_LAW" ? "BROTHER_IN_LAW" : (
                                                          a$89 === "HUSBAND" ? "HUSBAND" : (
                                                              a$89 === "UNCLE" ? "UNCLE" : (
                                                                  a$89 === "AUNT" ? "AUNT" : (
                                                                      a$89 === "SISTER_IN_LAW" ? "SISTER_IN_LAW" : (
                                                                          a$89 === "OTHER" ? "OTHER" : (
                                                                              a$89 === "BROTHER" ? "BROTHER" : (
                                                                                  a$89 === "WIFE" ? "WIFE" : (
                                                                                      a$89 === "GENERAL_FAMILY" ? "GENERAL_FAMILY" : "BOYFRIEND"
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          picture_gt: a$90 !== undefined ? a$90 : undefined,
          name_exists: a$91 !== undefined ? a$91 : undefined,
          userId_lt: a$92 !== undefined ? Caml_option.valFromOption(a$92) : undefined,
          userId: a$93 !== undefined ? Caml_option.valFromOption(a$93) : undefined,
          updatedAt_nin: a$94 !== undefined ? a$94.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined
        };
}

function serializeInputObjectUserQueryInput$2(inp) {
  var a = inp._id_nin;
  var a$1 = inp.phone_gt;
  var a$2 = inp.phone_lte;
  var a$3 = inp.lastLogin_exists;
  var a$4 = inp.lastName_in;
  var a$5 = inp.gender_exists;
  var a$6 = inp.gender_lte;
  var a$7 = inp.lastName_gt;
  var a$8 = inp.lastLogin_ne;
  var a$9 = inp._id_lt;
  var a$10 = inp.lastLogin_gte;
  var a$11 = inp._id;
  var a$12 = inp.createdAt_nin;
  var a$13 = inp.gender_gte;
  var a$14 = inp.finishedOnboarding_exists;
  var a$15 = inp.birthday;
  var a$16 = inp.authId_ne;
  var a$17 = inp.sentAbandonedOnboardingEmail_exists;
  var a$18 = inp.authId_in;
  var a$19 = inp.OR;
  var a$20 = inp.picture;
  var a$21 = inp.phone_exists;
  var a$22 = inp.email_gte;
  var a$23 = inp.finishedOnboarding;
  var a$24 = inp.finishedOnboarding_ne;
  var a$25 = inp.updatedAt_in;
  var a$26 = inp.lastLogin_lte;
  var a$27 = inp.lastName_lt;
  var a$28 = inp.lastLogin;
  var a$29 = inp.gender_lt;
  var a$30 = inp.updatedAt_ne;
  var a$31 = inp.sentAbandonedOnboardingEmail;
  var a$32 = inp.lastLogin_in;
  var a$33 = inp.createdAt;
  var a$34 = inp.createdAt_gt;
  var a$35 = inp.picture_ne;
  var a$36 = inp.AND;
  var a$37 = inp._id_exists;
  var a$38 = inp.birthday_nin;
  var a$39 = inp.gender;
  var a$40 = inp.name_lte;
  var a$41 = inp.email_exists;
  var a$42 = inp.name_ne;
  var a$43 = inp.phone_ne;
  var a$44 = inp.email_in;
  var a$45 = inp.createdAt_in;
  var a$46 = inp.phone_gte;
  var a$47 = inp.updatedAt_gt;
  var a$48 = inp.firstName_exists;
  var a$49 = inp.authId;
  var a$50 = inp.updatedAt_nin;
  var a$51 = inp.updatedAt_lt;
  var a$52 = inp.lastName_ne;
  var a$53 = inp.picture_lt;
  var a$54 = inp.firstName_gte;
  var a$55 = inp.firstName_gt;
  var a$56 = inp._id_in;
  var a$57 = inp.lastLogin_nin;
  var a$58 = inp.birthday_in;
  var a$59 = inp.authId_gte;
  var a$60 = inp.createdAt_gte;
  var a$61 = inp.gender_gt;
  var a$62 = inp.lastName_exists;
  var a$63 = inp.updatedAt_exists;
  var a$64 = inp.createdAt_exists;
  var a$65 = inp.name_exists;
  var a$66 = inp.address;
  var a$67 = inp.birthday_gte;
  var a$68 = inp.name;
  var a$69 = inp.birthday_gt;
  var a$70 = inp.picture_nin;
  var a$71 = inp.lastName_nin;
  var a$72 = inp.phone_in;
  var a$73 = inp.picture_gte;
  var a$74 = inp._id_lte;
  var a$75 = inp.firstName_nin;
  var a$76 = inp.sentAbandonedOnboardingEmail_ne;
  var a$77 = inp.phone_lt;
  var a$78 = inp.birthday_ne;
  var a$79 = inp.name_nin;
  var a$80 = inp.firstName_lt;
  var a$81 = inp.phone;
  var a$82 = inp.email_ne;
  var a$83 = inp._id_gte;
  var a$84 = inp.email;
  var a$85 = inp.birthday_lt;
  var a$86 = inp.updatedAt_lte;
  var a$87 = inp.address_exists;
  var a$88 = inp.updatedAt_gte;
  var a$89 = inp.createdAt_ne;
  var a$90 = inp.lastName_lte;
  var a$91 = inp.birthday_exists;
  var a$92 = inp.authId_lt;
  var a$93 = inp.picture_exists;
  var a$94 = inp.updatedAt;
  var a$95 = inp.firstName_in;
  var a$96 = inp.name_gte;
  var a$97 = inp.picture_in;
  var a$98 = inp.phone_nin;
  var a$99 = inp.createdAt_lte;
  var a$100 = inp._id_ne;
  var a$101 = inp.firstName_lte;
  var a$102 = inp.authId_nin;
  var a$103 = inp.firstName_ne;
  var a$104 = inp.gender_nin;
  var a$105 = inp.picture_lte;
  var a$106 = inp.authId_exists;
  var a$107 = inp._id_gt;
  var a$108 = inp.lastLogin_lt;
  var a$109 = inp.gender_in;
  var a$110 = inp.lastName_gte;
  var a$111 = inp.authId_gt;
  var a$112 = inp.birthday_lte;
  var a$113 = inp.createdAt_lt;
  var a$114 = inp.firstName;
  var a$115 = inp.picture_gt;
  var a$116 = inp.email_lt;
  var a$117 = inp.lastLogin_gt;
  var a$118 = inp.lastName;
  var a$119 = inp.email_nin;
  var a$120 = inp.email_gt;
  var a$121 = inp.name_gt;
  var a$122 = inp.name_lt;
  var a$123 = inp.authId_lte;
  var a$124 = inp.gender_ne;
  var a$125 = inp.email_lte;
  var a$126 = inp.name_in;
  return {
          _id_nin: a !== undefined ? a.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          phone_gt: a$1 !== undefined ? a$1 : undefined,
          phone_lte: a$2 !== undefined ? a$2 : undefined,
          lastLogin_exists: a$3 !== undefined ? a$3 : undefined,
          lastName_in: a$4 !== undefined ? a$4.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          gender_exists: a$5 !== undefined ? a$5 : undefined,
          gender_lte: a$6 !== undefined ? a$6 : undefined,
          lastName_gt: a$7 !== undefined ? a$7 : undefined,
          lastLogin_ne: a$8 !== undefined ? Caml_option.valFromOption(a$8) : undefined,
          _id_lt: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          lastLogin_gte: a$10 !== undefined ? Caml_option.valFromOption(a$10) : undefined,
          _id: a$11 !== undefined ? Caml_option.valFromOption(a$11) : undefined,
          createdAt_nin: a$12 !== undefined ? a$12.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          gender_gte: a$13 !== undefined ? a$13 : undefined,
          finishedOnboarding_exists: a$14 !== undefined ? a$14 : undefined,
          birthday: a$15 !== undefined ? Caml_option.valFromOption(a$15) : undefined,
          authId_ne: a$16 !== undefined ? a$16 : undefined,
          sentAbandonedOnboardingEmail_exists: a$17 !== undefined ? a$17 : undefined,
          authId_in: a$18 !== undefined ? a$18.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          OR: a$19 !== undefined ? a$19.map(serializeInputObjectUserQueryInput$2) : undefined,
          picture: a$20 !== undefined ? a$20 : undefined,
          phone_exists: a$21 !== undefined ? a$21 : undefined,
          email_gte: a$22 !== undefined ? a$22 : undefined,
          finishedOnboarding: a$23 !== undefined ? a$23 : undefined,
          finishedOnboarding_ne: a$24 !== undefined ? a$24 : undefined,
          updatedAt_in: a$25 !== undefined ? a$25.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          lastLogin_lte: a$26 !== undefined ? Caml_option.valFromOption(a$26) : undefined,
          lastName_lt: a$27 !== undefined ? a$27 : undefined,
          lastLogin: a$28 !== undefined ? Caml_option.valFromOption(a$28) : undefined,
          gender_lt: a$29 !== undefined ? a$29 : undefined,
          updatedAt_ne: a$30 !== undefined ? Caml_option.valFromOption(a$30) : undefined,
          sentAbandonedOnboardingEmail: a$31 !== undefined ? a$31 : undefined,
          lastLogin_in: a$32 !== undefined ? a$32.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          createdAt: a$33 !== undefined ? Caml_option.valFromOption(a$33) : undefined,
          createdAt_gt: a$34 !== undefined ? Caml_option.valFromOption(a$34) : undefined,
          picture_ne: a$35 !== undefined ? a$35 : undefined,
          AND: a$36 !== undefined ? a$36.map(serializeInputObjectUserQueryInput$2) : undefined,
          _id_exists: a$37 !== undefined ? a$37 : undefined,
          birthday_nin: a$38 !== undefined ? a$38.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          gender: a$39 !== undefined ? a$39 : undefined,
          name_lte: a$40 !== undefined ? a$40 : undefined,
          email_exists: a$41 !== undefined ? a$41 : undefined,
          name_ne: a$42 !== undefined ? a$42 : undefined,
          phone_ne: a$43 !== undefined ? a$43 : undefined,
          email_in: a$44 !== undefined ? a$44.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          createdAt_in: a$45 !== undefined ? a$45.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          phone_gte: a$46 !== undefined ? a$46 : undefined,
          updatedAt_gt: a$47 !== undefined ? Caml_option.valFromOption(a$47) : undefined,
          firstName_exists: a$48 !== undefined ? a$48 : undefined,
          authId: a$49 !== undefined ? a$49 : undefined,
          updatedAt_nin: a$50 !== undefined ? a$50.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          updatedAt_lt: a$51 !== undefined ? Caml_option.valFromOption(a$51) : undefined,
          lastName_ne: a$52 !== undefined ? a$52 : undefined,
          picture_lt: a$53 !== undefined ? a$53 : undefined,
          firstName_gte: a$54 !== undefined ? a$54 : undefined,
          firstName_gt: a$55 !== undefined ? a$55 : undefined,
          _id_in: a$56 !== undefined ? a$56.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          lastLogin_nin: a$57 !== undefined ? a$57.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          birthday_in: a$58 !== undefined ? a$58.map(function (b) {
                  if (b !== undefined) {
                    return Caml_option.valFromOption(b);
                  }
                  
                }) : undefined,
          authId_gte: a$59 !== undefined ? a$59 : undefined,
          createdAt_gte: a$60 !== undefined ? Caml_option.valFromOption(a$60) : undefined,
          gender_gt: a$61 !== undefined ? a$61 : undefined,
          lastName_exists: a$62 !== undefined ? a$62 : undefined,
          updatedAt_exists: a$63 !== undefined ? a$63 : undefined,
          createdAt_exists: a$64 !== undefined ? a$64 : undefined,
          name_exists: a$65 !== undefined ? a$65 : undefined,
          address: a$66 !== undefined ? serializeInputObjectUserAddressQueryInput$2(a$66) : undefined,
          birthday_gte: a$67 !== undefined ? Caml_option.valFromOption(a$67) : undefined,
          name: a$68 !== undefined ? a$68 : undefined,
          birthday_gt: a$69 !== undefined ? Caml_option.valFromOption(a$69) : undefined,
          picture_nin: a$70 !== undefined ? a$70.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          lastName_nin: a$71 !== undefined ? a$71.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          phone_in: a$72 !== undefined ? a$72.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          picture_gte: a$73 !== undefined ? a$73 : undefined,
          _id_lte: a$74 !== undefined ? Caml_option.valFromOption(a$74) : undefined,
          firstName_nin: a$75 !== undefined ? a$75.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          sentAbandonedOnboardingEmail_ne: a$76 !== undefined ? a$76 : undefined,
          phone_lt: a$77 !== undefined ? a$77 : undefined,
          birthday_ne: a$78 !== undefined ? Caml_option.valFromOption(a$78) : undefined,
          name_nin: a$79 !== undefined ? a$79.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          firstName_lt: a$80 !== undefined ? a$80 : undefined,
          phone: a$81 !== undefined ? a$81 : undefined,
          email_ne: a$82 !== undefined ? a$82 : undefined,
          _id_gte: a$83 !== undefined ? Caml_option.valFromOption(a$83) : undefined,
          email: a$84 !== undefined ? a$84 : undefined,
          birthday_lt: a$85 !== undefined ? Caml_option.valFromOption(a$85) : undefined,
          updatedAt_lte: a$86 !== undefined ? Caml_option.valFromOption(a$86) : undefined,
          address_exists: a$87 !== undefined ? a$87 : undefined,
          updatedAt_gte: a$88 !== undefined ? Caml_option.valFromOption(a$88) : undefined,
          createdAt_ne: a$89 !== undefined ? Caml_option.valFromOption(a$89) : undefined,
          lastName_lte: a$90 !== undefined ? a$90 : undefined,
          birthday_exists: a$91 !== undefined ? a$91 : undefined,
          authId_lt: a$92 !== undefined ? a$92 : undefined,
          picture_exists: a$93 !== undefined ? a$93 : undefined,
          updatedAt: a$94 !== undefined ? Caml_option.valFromOption(a$94) : undefined,
          firstName_in: a$95 !== undefined ? a$95.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          name_gte: a$96 !== undefined ? a$96 : undefined,
          picture_in: a$97 !== undefined ? a$97.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          phone_nin: a$98 !== undefined ? a$98.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          createdAt_lte: a$99 !== undefined ? Caml_option.valFromOption(a$99) : undefined,
          _id_ne: a$100 !== undefined ? Caml_option.valFromOption(a$100) : undefined,
          firstName_lte: a$101 !== undefined ? a$101 : undefined,
          authId_nin: a$102 !== undefined ? a$102.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          firstName_ne: a$103 !== undefined ? a$103 : undefined,
          gender_nin: a$104 !== undefined ? a$104.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          picture_lte: a$105 !== undefined ? a$105 : undefined,
          authId_exists: a$106 !== undefined ? a$106 : undefined,
          _id_gt: a$107 !== undefined ? Caml_option.valFromOption(a$107) : undefined,
          lastLogin_lt: a$108 !== undefined ? Caml_option.valFromOption(a$108) : undefined,
          gender_in: a$109 !== undefined ? a$109.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          lastName_gte: a$110 !== undefined ? a$110 : undefined,
          authId_gt: a$111 !== undefined ? a$111 : undefined,
          birthday_lte: a$112 !== undefined ? Caml_option.valFromOption(a$112) : undefined,
          createdAt_lt: a$113 !== undefined ? Caml_option.valFromOption(a$113) : undefined,
          firstName: a$114 !== undefined ? a$114 : undefined,
          picture_gt: a$115 !== undefined ? a$115 : undefined,
          email_lt: a$116 !== undefined ? a$116 : undefined,
          lastLogin_gt: a$117 !== undefined ? Caml_option.valFromOption(a$117) : undefined,
          lastName: a$118 !== undefined ? a$118 : undefined,
          email_nin: a$119 !== undefined ? a$119.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined,
          email_gt: a$120 !== undefined ? a$120 : undefined,
          name_gt: a$121 !== undefined ? a$121 : undefined,
          name_lt: a$122 !== undefined ? a$122 : undefined,
          authId_lte: a$123 !== undefined ? a$123 : undefined,
          gender_ne: a$124 !== undefined ? a$124 : undefined,
          email_lte: a$125 !== undefined ? a$125 : undefined,
          name_in: a$126 !== undefined ? a$126.map(function (b) {
                  if (b !== undefined) {
                    return b;
                  }
                  
                }) : undefined
        };
}

function serializeVariables$2(inp) {
  var a = inp.query;
  return {
          query: a !== undefined ? serializeInputObjectGifteeQueryInput$2(a) : undefined
        };
}

function makeVariables$2(query, param) {
  return {
          query: query
        };
}

function makeInputObjectGifteeQueryInput$2(age_ne, interests_exists, createdAt_gte, picture_exists, age_lt, _id, picture_in, AND, updatedAt_gt, updatedAt_lte, status_gte, sourceUser_exists, _id_exists, createdAt_ne, isCustom_exists, updatedAt_gte, status_lte, name_gte, createdAt_exists, age_nin, picture, interests_nin, interests_in, relationship_gte, relationship_lte, age_exists, name, name_lte, userId_in, isPendingAcceptance, relationship_ne, age_in, name_nin, picture_gte, status_lt, age, updatedAt_exists, userId_nin, _id_gt, userId_ne, status_exists, sourceUser, userId_gt, _id_gte, createdAt_in, updatedAt_ne, isPendingAcceptance_ne, relationship_exists, _id_lte, _id_ne, isCustom, _id_nin, status_ne, createdAt_nin, status_nin, userId_lte, name_ne, picture_lte, createdAt_lt, status_in, updatedAt_in, _id_in, _id_lt, createdAt, userId_exists, age_gte, picture_lt, updatedAt, name_lt, status_gt, relationship_nin, age_lte, relationship_lt, userId_gte, status, createdAt_lte, name_in, relationship_in, isCustom_ne, isPendingAcceptance_exists, OR, updatedAt_lt, createdAt_gt, relationship_gt, name_gt, interests, age_gt, picture_nin, picture_ne, relationship, picture_gt, name_exists, userId_lt, userId, updatedAt_nin, param) {
  return {
          age_ne: age_ne,
          interests_exists: interests_exists,
          createdAt_gte: createdAt_gte,
          picture_exists: picture_exists,
          age_lt: age_lt,
          _id: _id,
          picture_in: picture_in,
          AND: AND,
          updatedAt_gt: updatedAt_gt,
          updatedAt_lte: updatedAt_lte,
          status_gte: status_gte,
          sourceUser_exists: sourceUser_exists,
          _id_exists: _id_exists,
          createdAt_ne: createdAt_ne,
          isCustom_exists: isCustom_exists,
          updatedAt_gte: updatedAt_gte,
          status_lte: status_lte,
          name_gte: name_gte,
          createdAt_exists: createdAt_exists,
          age_nin: age_nin,
          picture: picture,
          interests_nin: interests_nin,
          interests_in: interests_in,
          relationship_gte: relationship_gte,
          relationship_lte: relationship_lte,
          age_exists: age_exists,
          name: name,
          name_lte: name_lte,
          userId_in: userId_in,
          isPendingAcceptance: isPendingAcceptance,
          relationship_ne: relationship_ne,
          age_in: age_in,
          name_nin: name_nin,
          picture_gte: picture_gte,
          status_lt: status_lt,
          age: age,
          updatedAt_exists: updatedAt_exists,
          userId_nin: userId_nin,
          _id_gt: _id_gt,
          userId_ne: userId_ne,
          status_exists: status_exists,
          sourceUser: sourceUser,
          userId_gt: userId_gt,
          _id_gte: _id_gte,
          createdAt_in: createdAt_in,
          updatedAt_ne: updatedAt_ne,
          isPendingAcceptance_ne: isPendingAcceptance_ne,
          relationship_exists: relationship_exists,
          _id_lte: _id_lte,
          _id_ne: _id_ne,
          isCustom: isCustom,
          _id_nin: _id_nin,
          status_ne: status_ne,
          createdAt_nin: createdAt_nin,
          status_nin: status_nin,
          userId_lte: userId_lte,
          name_ne: name_ne,
          picture_lte: picture_lte,
          createdAt_lt: createdAt_lt,
          status_in: status_in,
          updatedAt_in: updatedAt_in,
          _id_in: _id_in,
          _id_lt: _id_lt,
          createdAt: createdAt,
          userId_exists: userId_exists,
          age_gte: age_gte,
          picture_lt: picture_lt,
          updatedAt: updatedAt,
          name_lt: name_lt,
          status_gt: status_gt,
          relationship_nin: relationship_nin,
          age_lte: age_lte,
          relationship_lt: relationship_lt,
          userId_gte: userId_gte,
          status: status,
          createdAt_lte: createdAt_lte,
          name_in: name_in,
          relationship_in: relationship_in,
          isCustom_ne: isCustom_ne,
          isPendingAcceptance_exists: isPendingAcceptance_exists,
          OR: OR,
          updatedAt_lt: updatedAt_lt,
          createdAt_gt: createdAt_gt,
          relationship_gt: relationship_gt,
          name_gt: name_gt,
          interests: interests,
          age_gt: age_gt,
          picture_nin: picture_nin,
          picture_ne: picture_ne,
          relationship: relationship,
          picture_gt: picture_gt,
          name_exists: name_exists,
          userId_lt: userId_lt,
          userId: userId,
          updatedAt_nin: updatedAt_nin
        };
}

function makeInputObjectUserQueryInput$2(_id_nin, phone_gt, phone_lte, lastLogin_exists, lastName_in, gender_exists, gender_lte, lastName_gt, lastLogin_ne, _id_lt, lastLogin_gte, _id, createdAt_nin, gender_gte, finishedOnboarding_exists, birthday, authId_ne, sentAbandonedOnboardingEmail_exists, authId_in, OR, picture, phone_exists, email_gte, finishedOnboarding, finishedOnboarding_ne, updatedAt_in, lastLogin_lte, lastName_lt, lastLogin, gender_lt, updatedAt_ne, sentAbandonedOnboardingEmail, lastLogin_in, createdAt, createdAt_gt, picture_ne, AND, _id_exists, birthday_nin, gender, name_lte, email_exists, name_ne, phone_ne, email_in, createdAt_in, phone_gte, updatedAt_gt, firstName_exists, authId, updatedAt_nin, updatedAt_lt, lastName_ne, picture_lt, firstName_gte, firstName_gt, _id_in, lastLogin_nin, birthday_in, authId_gte, createdAt_gte, gender_gt, lastName_exists, updatedAt_exists, createdAt_exists, name_exists, address, birthday_gte, name, birthday_gt, picture_nin, lastName_nin, phone_in, picture_gte, _id_lte, firstName_nin, sentAbandonedOnboardingEmail_ne, phone_lt, birthday_ne, name_nin, firstName_lt, phone, email_ne, _id_gte, email, birthday_lt, updatedAt_lte, address_exists, updatedAt_gte, createdAt_ne, lastName_lte, birthday_exists, authId_lt, picture_exists, updatedAt, firstName_in, name_gte, picture_in, phone_nin, createdAt_lte, _id_ne, firstName_lte, authId_nin, firstName_ne, gender_nin, picture_lte, authId_exists, _id_gt, lastLogin_lt, gender_in, lastName_gte, authId_gt, birthday_lte, createdAt_lt, firstName, picture_gt, email_lt, lastLogin_gt, lastName, email_nin, email_gt, name_gt, name_lt, authId_lte, gender_ne, email_lte, name_in, param) {
  return {
          _id_nin: _id_nin,
          phone_gt: phone_gt,
          phone_lte: phone_lte,
          lastLogin_exists: lastLogin_exists,
          lastName_in: lastName_in,
          gender_exists: gender_exists,
          gender_lte: gender_lte,
          lastName_gt: lastName_gt,
          lastLogin_ne: lastLogin_ne,
          _id_lt: _id_lt,
          lastLogin_gte: lastLogin_gte,
          _id: _id,
          createdAt_nin: createdAt_nin,
          gender_gte: gender_gte,
          finishedOnboarding_exists: finishedOnboarding_exists,
          birthday: birthday,
          authId_ne: authId_ne,
          sentAbandonedOnboardingEmail_exists: sentAbandonedOnboardingEmail_exists,
          authId_in: authId_in,
          OR: OR,
          picture: picture,
          phone_exists: phone_exists,
          email_gte: email_gte,
          finishedOnboarding: finishedOnboarding,
          finishedOnboarding_ne: finishedOnboarding_ne,
          updatedAt_in: updatedAt_in,
          lastLogin_lte: lastLogin_lte,
          lastName_lt: lastName_lt,
          lastLogin: lastLogin,
          gender_lt: gender_lt,
          updatedAt_ne: updatedAt_ne,
          sentAbandonedOnboardingEmail: sentAbandonedOnboardingEmail,
          lastLogin_in: lastLogin_in,
          createdAt: createdAt,
          createdAt_gt: createdAt_gt,
          picture_ne: picture_ne,
          AND: AND,
          _id_exists: _id_exists,
          birthday_nin: birthday_nin,
          gender: gender,
          name_lte: name_lte,
          email_exists: email_exists,
          name_ne: name_ne,
          phone_ne: phone_ne,
          email_in: email_in,
          createdAt_in: createdAt_in,
          phone_gte: phone_gte,
          updatedAt_gt: updatedAt_gt,
          firstName_exists: firstName_exists,
          authId: authId,
          updatedAt_nin: updatedAt_nin,
          updatedAt_lt: updatedAt_lt,
          lastName_ne: lastName_ne,
          picture_lt: picture_lt,
          firstName_gte: firstName_gte,
          firstName_gt: firstName_gt,
          _id_in: _id_in,
          lastLogin_nin: lastLogin_nin,
          birthday_in: birthday_in,
          authId_gte: authId_gte,
          createdAt_gte: createdAt_gte,
          gender_gt: gender_gt,
          lastName_exists: lastName_exists,
          updatedAt_exists: updatedAt_exists,
          createdAt_exists: createdAt_exists,
          name_exists: name_exists,
          address: address,
          birthday_gte: birthday_gte,
          name: name,
          birthday_gt: birthday_gt,
          picture_nin: picture_nin,
          lastName_nin: lastName_nin,
          phone_in: phone_in,
          picture_gte: picture_gte,
          _id_lte: _id_lte,
          firstName_nin: firstName_nin,
          sentAbandonedOnboardingEmail_ne: sentAbandonedOnboardingEmail_ne,
          phone_lt: phone_lt,
          birthday_ne: birthday_ne,
          name_nin: name_nin,
          firstName_lt: firstName_lt,
          phone: phone,
          email_ne: email_ne,
          _id_gte: _id_gte,
          email: email,
          birthday_lt: birthday_lt,
          updatedAt_lte: updatedAt_lte,
          address_exists: address_exists,
          updatedAt_gte: updatedAt_gte,
          createdAt_ne: createdAt_ne,
          lastName_lte: lastName_lte,
          birthday_exists: birthday_exists,
          authId_lt: authId_lt,
          picture_exists: picture_exists,
          updatedAt: updatedAt,
          firstName_in: firstName_in,
          name_gte: name_gte,
          picture_in: picture_in,
          phone_nin: phone_nin,
          createdAt_lte: createdAt_lte,
          _id_ne: _id_ne,
          firstName_lte: firstName_lte,
          authId_nin: authId_nin,
          firstName_ne: firstName_ne,
          gender_nin: gender_nin,
          picture_lte: picture_lte,
          authId_exists: authId_exists,
          _id_gt: _id_gt,
          lastLogin_lt: lastLogin_lt,
          gender_in: gender_in,
          lastName_gte: lastName_gte,
          authId_gt: authId_gt,
          birthday_lte: birthday_lte,
          createdAt_lt: createdAt_lt,
          firstName: firstName,
          picture_gt: picture_gt,
          email_lt: email_lt,
          lastLogin_gt: lastLogin_gt,
          lastName: lastName,
          email_nin: email_nin,
          email_gt: email_gt,
          name_gt: name_gt,
          name_lt: name_lt,
          authId_lte: authId_lte,
          gender_ne: gender_ne,
          email_lte: email_lte,
          name_in: name_in
        };
}

function makeInputObjectUserAddressQueryInput$2(street1_lte, zip_nin, zip_exists, city_lt, zip_lt, AND, city, state_lte, street1_ne, street1_exists, state_ne, street2_exists, state_gt, street1_in, zip, city_exists, state, street2_lte, street2_in, zip_gte, state_nin, city_ne, city_lte, street1_gte, street2_ne, city_nin, zip_gt, city_in, street2_gte, street2_gt, zip_ne, state_gte, state_lt, zip_lte, state_in, OR, zip_in, street2_nin, street1_gt, city_gte, city_gt, street2, street2_lt, street1, street1_lt, state_exists, street1_nin, param) {
  return {
          street1_lte: street1_lte,
          zip_nin: zip_nin,
          zip_exists: zip_exists,
          city_lt: city_lt,
          zip_lt: zip_lt,
          AND: AND,
          city: city,
          state_lte: state_lte,
          street1_ne: street1_ne,
          street1_exists: street1_exists,
          state_ne: state_ne,
          street2_exists: street2_exists,
          state_gt: state_gt,
          street1_in: street1_in,
          zip: zip,
          city_exists: city_exists,
          state: state,
          street2_lte: street2_lte,
          street2_in: street2_in,
          zip_gte: zip_gte,
          state_nin: state_nin,
          city_ne: city_ne,
          city_lte: city_lte,
          street1_gte: street1_gte,
          street2_ne: street2_ne,
          city_nin: city_nin,
          zip_gt: zip_gt,
          city_in: city_in,
          street2_gte: street2_gte,
          street2_gt: street2_gt,
          zip_ne: zip_ne,
          state_gte: state_gte,
          state_lt: state_lt,
          zip_lte: zip_lte,
          state_in: state_in,
          OR: OR,
          zip_in: zip_in,
          street2_nin: street2_nin,
          street1_gt: street1_gt,
          city_gte: city_gte,
          city_gt: city_gt,
          street2: street2,
          street2_lt: street2_lt,
          street1: street1,
          street1_lt: street1_lt,
          state_exists: state_exists,
          street1_nin: street1_nin
        };
}

function makeDefaultVariables$2(param) {
  return {
          query: undefined
        };
}

var GetGifteeIdAndSourceUserQuery_inner = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  serializeInputObjectGifteeQueryInput: serializeInputObjectGifteeQueryInput$2,
  serializeInputObjectUserQueryInput: serializeInputObjectUserQueryInput$2,
  serializeInputObjectUserAddressQueryInput: serializeInputObjectUserAddressQueryInput$2,
  makeVariables: makeVariables$2,
  makeInputObjectGifteeQueryInput: makeInputObjectGifteeQueryInput$2,
  makeInputObjectUserQueryInput: makeInputObjectUserQueryInput$2,
  makeInputObjectUserAddressQueryInput: makeInputObjectUserAddressQueryInput$2,
  makeDefaultVariables: makeDefaultVariables$2
};

var include$2 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$2,
      Raw: Raw$2,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables$2
    });

var GetGifteeIdAndSourceUserQuery_refetchQueryDescription = include$2.refetchQueryDescription;

var GetGifteeIdAndSourceUserQuery_use = include$2.use;

var GetGifteeIdAndSourceUserQuery_useLazy = include$2.useLazy;

var GetGifteeIdAndSourceUserQuery_useLazyWithVariables = include$2.useLazyWithVariables;

var GetGifteeIdAndSourceUserQuery = {
  GetGifteeIdAndSourceUserQuery_inner: GetGifteeIdAndSourceUserQuery_inner,
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  serializeInputObjectGifteeQueryInput: serializeInputObjectGifteeQueryInput$2,
  serializeInputObjectUserQueryInput: serializeInputObjectUserQueryInput$2,
  serializeInputObjectUserAddressQueryInput: serializeInputObjectUserAddressQueryInput$2,
  makeVariables: makeVariables$2,
  makeInputObjectGifteeQueryInput: makeInputObjectGifteeQueryInput$2,
  makeInputObjectUserQueryInput: makeInputObjectUserQueryInput$2,
  makeInputObjectUserAddressQueryInput: makeInputObjectUserAddressQueryInput$2,
  makeDefaultVariables: makeDefaultVariables$2,
  refetchQueryDescription: GetGifteeIdAndSourceUserQuery_refetchQueryDescription,
  use: GetGifteeIdAndSourceUserQuery_use,
  useLazy: GetGifteeIdAndSourceUserQuery_useLazy,
  useLazyWithVariables: GetGifteeIdAndSourceUserQuery_useLazyWithVariables
};

export {
  GetGifteesQuery ,
  GetGifteeQuery ,
  GetGifteeIdAndSourceUserQuery ,
  
}
/* query Not a pure module */
