import React, { FC } from "react";
import { Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  outlinedInput: {
    marginBottom: 10,
    width: "100%",
    "& label": {
      color: theme.palette.text.hint,
    },
    "& input": {
      padding: "15px 13px",
      color: theme.palette.text.primary,
    },
    "&:hover label": {
      color: `${theme.palette.text.primary} !important`,
    },
    "& fieldset": {
      borderColor: `${theme.palette.text.hint} !important`,
    },
    "&:hover fieldset": {
      borderColor: `${theme.palette.text.primary} !important`,
    },
    "& input:-internal-autofill-selected": {
      borderRadius: 30,
      color: theme.palette.text.primary + " !important",
    },
  },
  //TODO: what the heck is this?
  darkMode: {
    width: "100%",
    marginBottom: 10,
  },
}));

type Props = {
  label?: string;
  onKeyPress?: (event: React.KeyboardEvent) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  value: string | number;
  variant?: string;
  type?: string;
  name?: string;
};

const OutlinedInput: FC<Props> = ({ label, onKeyPress, onChange, value, variant, type, name }) => {
  const classes = useStyles();
  return (
    <TextField
      label={label}
      variant="outlined"
      onKeyPress={onKeyPress}
      onChange={onChange}
      value={value}
      className={classes.outlinedInput}
      type={type}
      InputProps={{ name }}
    />
  );
};

export default OutlinedInput;
