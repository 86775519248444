import React, { FC, useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import PrimaryButton from "../general-ui/buttons/PrimaryButton";
import SecondaryButton from "../general-ui/buttons/SecondaryButton";
import SecondaryModal from "../general-ui/modals/SecondaryModal";
import { useMutation, useQuery } from "@apollo/client";
import { deleteOneGiftee, updateOneGiftee } from "../../graphql/mutations/giftees";
import { insertOneNotification } from "../../graphql/mutations/notifications";
import { getGifteesQuery } from "../../graphql/queries/giftees";

import { getNotifications_notifications as NotificationType } from "../../graphql/queries/notifications/__generated__/getNotifications";
import {
  DeleteOneGifteeMutation,
  DeleteOneGifteeMutationVariables,
} from "../../graphql/mutations/giftees/__generated__/DeleteOneGifteeMutation";
import {
  InsertOneNotificationMutation,
  InsertOneNotificationMutationVariables,
} from "../../graphql/mutations/notifications/__generated__/InsertOneNotificationMutation";
import { GifteeStatus, NotificationType as NotificationTypeEnum } from "../../__generated__/globalTypes";
import { selectUser, setShowAddGifteeSuggestionDialog } from "../../redux/add-giftee/actions";
import { useDispatch } from "react-redux";
import { getGiftees, getGifteesVariables } from "../../graphql/queries/giftees/__generated__/getGiftees";
import { useAuthContext } from "../../auth/authContext";
import useTheme from "@material-ui/core/styles/useTheme";
import {
  UpdateOneGifteeMutation,
  UpdateOneGifteeMutationVariables,
} from "../../graphql/mutations/giftees/__generated__/UpdateOneGifteeMutation";

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    height: 60,
    width: 60,
    borderRadius: 30,
    marginRight: 10,
  },
  notificationWrapper: {
    marginBottom: 10,
  },
  notification: {
    height: "100px",
    padding: "0px",
    paddingLeft: "10px",
    boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    "& p": {
      color: theme.palette.primary.main,
    },
  },
  notificationButtons: {
    height: "100%",
  },
  notificationButton: {
    "& button": {
      margin: 4,
    },
  },
  divider: {
    width: 10,
  },
  focusText: {
    color: theme.palette.text.primaryBranded,
  },
}));

type Props = {
  notification: NotificationType;
  timeAgo: string;
  onDelete: () => void;
};

const NotificationGifteeRequest: FC<Props> = ({ notification, timeAgo, onDelete }) => {
  const theme = useTheme();
  const requester = notification.requester;
  const [showModal, setShowModal] = useState(false);
  const [openExpansion, setOpenExpansion] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const { authState } = useAuthContext();
  const getGifteesResult = useQuery<getGiftees, getGifteesVariables>(getGifteesQuery, {
    variables: {
      query: {
        userId: authState.user.auth.id,
      },
    },
    fetchPolicy: "cache-only",
  });
  const dispatch = useDispatch();
  const [deleteGiftee] = useMutation<DeleteOneGifteeMutation, DeleteOneGifteeMutationVariables>(deleteOneGiftee);
  const [updateGiftee] = useMutation<UpdateOneGifteeMutation, UpdateOneGifteeMutationVariables>(updateOneGiftee);
  const [addNotification] = useMutation<InsertOneNotificationMutation, InsertOneNotificationMutationVariables>(
    insertOneNotification
  );

  const toggleExpansion = () => {
    setOpenExpansion(!openExpansion);
  };

  const acceptGifteeRequest = async () => {
    setShowModal(false);
    await updateGiftee({
      variables: {
        query: {
          userId: requester?._id,
          sourceUser: { _id: notification.userId },
        },
        set: {
          isPendingAcceptance: false,
          status: GifteeStatus.APPROVED,
        },
      },
    });
    await addNotification({
      variables: {
        data: {
          type: NotificationTypeEnum.GIFTEEREQUESTACCEPTED,
          new: true,
          createdAt: new Date(),
          userId: requester?._id,
          requestee: {
            link: notification.userId,
          },
          archived: false,
        },
      },
    });
    const currentGifteeUserIds = getGifteesResult?.data?.giftees?.map((giftee) => giftee?.sourceUser?._id) ?? [];

    if (requester && !currentGifteeUserIds.includes(requester._id)) {
      dispatch({ ...selectUser(requester) });
      dispatch({ ...setShowAddGifteeSuggestionDialog(true) });
    }
    onDelete();
  };

  const declineGifteeRequest = async () => {
    setShowDeclineModal(false);
    await deleteGiftee({
      variables: {
        query: {
          userId: requester?._id,
          sourceUser: {
            _id: notification.userId,
          },
        },
      },
    });
    await addNotification({
      variables: {
        data: {
          type: NotificationTypeEnum.GIFTEEREQUESTDENIED,
          new: true,
          createdAt: new Date(),
          userId: requester?._id,
          requestee: {
            link: notification.userId,
          },
          archived: false,
        },
      },
    });
    onDelete();
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openDeclineModal = () => {
    setShowDeclineModal(true);
  };

  const closeDeclineModal = () => {
    setShowDeclineModal(false);
  };

  const requesterName = requester?.firstName ?? requester?.email;

  const classes = useStyles();

  const userAvatar = requester?.picture ? (
    <ListItemAvatar>
      <Avatar src={requester?.picture} className={classes.avatar} />
    </ListItemAvatar>
  ) : (
    <ListItemAvatar>
      <Avatar className={classes.avatar}>{requesterName?.charAt(0)?.toUpperCase()}</Avatar>
    </ListItemAvatar>
  );

  return (
    <div className={classes.notificationWrapper}>
      <ListItem key={notification._id} className={classes.notification} button onClick={toggleExpansion}>
        {userAvatar}
        <ListItemText secondary={timeAgo}>
          <span style={{ color: theme.palette.text.primaryBranded }}>{requester?.firstName ?? requester?.email}</span>{" "}
          wants to add you as a <strong className={classes.focusText}>giftee</strong>!
        </ListItemText>
        {openExpansion ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openExpansion} timeout="auto" unmountOnExit>
        <ListItem className={classes.notificationButtons} disableGutters>
          <PrimaryButton onClick={openModal}>accept</PrimaryButton>
          <div className={classes.divider}></div>
          <SecondaryButton onClick={openDeclineModal}>decline</SecondaryButton>
        </ListItem>
      </Collapse>
      <SecondaryModal showModal={showDeclineModal} closeModal={closeDeclineModal} title="Declining Giftee Request">
        Are you sure you want to decline?
        <br />
        <div className="dialog-btn-set">
          <PrimaryButton onClick={declineGifteeRequest}>Yes</PrimaryButton>
          <SecondaryButton onClick={closeDeclineModal}>No</SecondaryButton>
        </div>
      </SecondaryModal>
      <SecondaryModal
        showModal={showModal}
        closeModal={closeModal}
        title={"Accepting Giftee Request from: " + requesterName}
      >
        When you accept this giftee request, this user will be able to import your birthday, all occasions, and gift
        recommendations. Are you sure you want to accept?<br></br>
        <br />
        <div className="dialog-btn-set">
          <PrimaryButton onClick={acceptGifteeRequest}>Yes</PrimaryButton>
          <SecondaryButton onClick={closeModal}>No</SecondaryButton>
        </div>
      </SecondaryModal>
    </div>
  );
};

export default NotificationGifteeRequest;
