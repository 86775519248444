import React, { FC } from 'react';

import Grid from '@material-ui/core/Grid/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import GeneralAvatar from '../general-ui/GeneralAvatar';

const useStyles = makeStyles((theme) => ({
  productMatch: {
    "&.MuiTypography-root": {
      fontSize: 11,
    },
  },
  gifteeName: {
    whiteSpace: "nowrap",
    "&.MuiTypography-root": {
      fontSize: 8,
    },
  },
  gifteeChip: {
    display: "flex",
    alignItems: "center",
    boxShadow: theme.shadows[1],
    borderRadius: 30,
    overflow: "hidden",
    height: theme.spacing(3.5),
    width: 100,
    padding: 3,
    background: theme.palette.common.white,
  },
  gifteeMatchAvatar: {
    marginRight: theme.spacing(0.5),
  },
}));

type Props = {
  gifteeName: string;
  gifteePicture?: string;
  similarity: number;
  matchGradient: string;
  similarityFormatted: string;
};

const GifteeMatchChip: FC<Props> = ({ gifteeName, gifteePicture, matchGradient, similarityFormatted }) => {
  const classes = useStyles();
  return (
    <div className={classes.gifteeChip}>
      <div className={classes.gifteeMatchAvatar}>
        <GeneralAvatar alt={gifteeName} src={gifteePicture} size="extremely-small">
          {gifteeName.charAt(0)}
        </GeneralAvatar>
      </div>
      <Grid container direction="column" wrap="nowrap">
        <Typography variant="subtitle2" className={classes.gifteeName} style={{ color: matchGradient }}>
          {gifteeName}
        </Typography>
        {similarityFormatted !== "0% Match" ? (
          <Typography variant="subtitle2" className={classes.productMatch} style={{ color: matchGradient }}>
            {similarityFormatted}
          </Typography>
        ) : null}
      </Grid>
    </div>
  );
};

export default GifteeMatchChip;
