import React, { useState, FC } from "react";
import AnimatedCheckmark from "../general-ui/AnimatedCheckmark";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { User } from "../../@types/user";
import app from "../../realm";
import UserActionCard from "../general-ui/cards/UserActionCard";
import InputAdornment from "@material-ui/core/InputAdornment";
import { getInviteLink } from "../../utils/helpers/userDataHelpers";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2),
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    color: theme.palette.text.primary,
    padding: 16,
    "& h4": {
      color: theme.palette.text.primary,
    },
  },
  sendInviteContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  text: {
    color: theme.palette.text.primary,
  },
  formInput: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "stretch",
    color: theme.palette.text.primary,
    marginBottom: 20,
    "& .MuiInputBase-root:before": {
      borderColor: theme.palette.text.hint,
    },
    "& .MuiInputBase-root": {
      background: theme.palette.primary.transparent,
    },
    "& input": {
      color: theme.palette.text.primary,
    },
    "& label": {
      color: theme.palette.text.hint,
    },
  },
  success: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    cursor: "pointer",
    zIndex: 10,
    boxShadow: "inset 0 0 2px #5FB9A6",
  },
  iconButton: {
    background: "none",
    padding: theme.spacing(0.5),
    border: "none",
    color: theme.palette.text.faint,
  },
}));

type Props = {
  user: User;
  onComplete?: () => void;
};

const InviteFriendEmail: FC<Props> = ({ user, onComplete }) => {
  const [email, setEmail] = useState("");
  const [messageSent, setMessageSent] = useState(false);

  const emailOnKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      submit();
    }
  };

  const emailHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmail(event.target.value);
  };

  const submit = async () => {
    const url = getInviteLink(user);
    await app.currentUser?.callFunction("InviteEmail", [email, user, url]);
    setMessageSent(true);
    onComplete?.();
    setTimeout(() => {
      setMessageSent(false);
    }, 5000);
  };

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <UserActionCard>
        <>
          {!messageSent ? (
            <div className={classes.sendInviteContainer}>
              <Typography variant="h6" className={classes.text}>
                Send Invite
              </Typography>
              <Typography variant="subtitle2" className={classes.text} gutterBottom>
                Your friend or family member will receive an email with a link to join Occasional.ly and become your
                Giftee.
              </Typography>
              <TextField
                className={classes.formInput}
                variant="outlined"
                type="text"
                label="Invitee's Email"
                value={email}
                placeholder="dad@dadjokes.com"
                onChange={emailHandler}
                onKeyPress={emailOnKeyPress}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <button disabled={email.trim() === ""} className={classes.iconButton} onClick={submit}>
                        <span className="lnr lnr-paper-plane"></span>
                      </button>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          ) : (
            <div className={classes.form}>
              <div className={classes.success}>
                <AnimatedCheckmark checked={messageSent} />
              </div>
              Invitation sent!!
            </div>
          )}
        </>
      </UserActionCard>
    </div>
  );
};

export default InviteFriendEmail;
