import React, { FC } from "react";
import StepWizard from "react-step-wizard";
import AddUserOccasions from "./import-giftee/AddUserOccasions";
import AddUserRelationship from "./import-giftee/AddUserRelationship";
import ConfirmGifteePage from "./import-giftee/ConfirmGifteePage";
import GifteeImportConfirmed from "./import-giftee/GifteeImportConfirmed";

type Props = { initialStep: number };

const ImportGifteeWizard: FC<Props> = ({ initialStep }) => {
  return (
    <StepWizard initialStep={initialStep}>
      <AddUserOccasions />
      <AddUserRelationship />
      <ConfirmGifteePage />
      <GifteeImportConfirmed />
    </StepWizard>
  );
};

export default ImportGifteeWizard;
