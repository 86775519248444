import clsx from 'clsx';
import React, { FC } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { AvatarGroup } from '@material-ui/lab';

type AvatarProps = {
  alt?: string;
  src?: string;
  children: React.ReactNode;
};

type Props = {
  avatars: AvatarProps[];
  size?: "small" | "extra-small" | "extremely-small";
};

const useStyles = makeStyles((theme: Theme) => ({
  bigAvatar: {
    margin: 10,
    width: 150,
    height: 150,
    fontSize: 60,
    backgroundColor: theme.palette.primary.main,
  },
  smallAvatar: {
    margin: 10,
    width: 75,
    height: 75,
    fontSize: 40,
    backgroundColor: theme.palette.primary.main,
  },
  extraSmallAvatar: {
    margin: 5,
    width: 30,
    height: 30,
    fontSize: 25,
    backgroundColor: theme.palette.primary.main,
  },
  extremelySmallAvatar: {
    width: 23,
    height: 23,
    fontSize: 18,
    backgroundColor: theme.palette.primary.main,
  },
  noWidth: {
    width: "initial",
  },
}));

const GeneralAvatarMulti: FC<Props> = ({ avatars, size }) => {
  const classes = useStyles();

  const avatarClass = clsx({
    [classes.bigAvatar]: !size,
    [classes.smallAvatar]: size === "small",
    [classes.extraSmallAvatar]: size === "extra-small",
    [classes.extremelySmallAvatar]: size === "extremely-small",
  });

  return (
    <Grid
      container
      className={size === "extremely-small" ? classes.noWidth : undefined}
      justifyContent="center"
      alignItems="center"
    >
      <AvatarGroup spacing="small" max={3} classes={{ avatar: avatarClass }}>
        {avatars.map(({ alt, src, children }) => (
          <Avatar alt={alt} src={src} className={avatarClass}>
            {children}
          </Avatar>
        ))}
      </AvatarGroup>
    </Grid>
  );
};

export default GeneralAvatarMulti;
