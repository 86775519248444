import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import StepWizard from 'react-step-wizard';

import { useMutation } from '@apollo/client/react/hooks/useMutation';
import { makeStyles, Theme } from '@material-ui/core';

import { NotificationType } from '../../__generated__/globalTypes';
import { useAuthContext } from '../../auth/authContext';
import { deleteManyNotifications } from '../../graphql/mutations/notifications';
import {
  DeleteManyNotificationsMutation, DeleteManyNotificationsMutationVariables
} from '../../graphql/mutations/notifications/__generated__/DeleteManyNotificationsMutation';
import { getNotificationsQuery } from '../../graphql/queries/notifications';
import useUpsertGiftProfile from '../../hooks/useUpsertGiftProfile';
import { GiftIdeaRootState } from '../../redux/giftideas/reducers';
import { setGiftProfile, setLoading } from '../../redux/onboarding/actions';
import { OnboardingRootState } from '../../redux/onboarding/reducers';
import { RootState } from '../../redux/store';
import { giftProfileCreatedParam } from '../../routes/params/searchParamsConstants';
import CreateGiftProfileNav from './onboarding/CreateGiftProfileNav';
import CreateProfileInstructions from './onboarding/CreateProfileInstructions';
import GiftProfileCreationIntro from './onboarding/GiftProfileCreationIntro';
import GiftProfileCreationOutro from './onboarding/GiftProfileCreationOutro';
import GiftSelection from './onboarding/GiftSelection';
import GiftTypesInput from './onboarding/GiftTypesInput';
import OnboardingNav from './onboarding/OnboardingNav';
import SetGender from './onboarding/SetGender';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    width: "100%",
  },
  onboardingArea: {
    flex: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.background.background,
    backgroundSize: "20vw",
  },
  logo: {
    position: "fixed",
    top: 30,
    left: 40,
    width: 45,
    objectFit: "contain",
    zIndex: 1000,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  wizardContainer: {
    height: "100vh",
    maxWidth: 925,
    top: "5vh",
    left: "calc(50% - 250px)",
    zIndex: 100,
    transition: "0.5s cubic-bezier(0.0, 0, 0.2, 1)",
    background: theme.palette.background.background,
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      width: "100vw",
      height: "100vh",
      borderRadius: 0,
    },
  },
  wizard: {
    display: "flex",
    flexDirection: "column-reverse",
  },
}));

export enum CreateGiftProfileWizardSteps {
  CreateProfileIntro = 1,
  GiftTypesInput,
  SetGender,
  GiftSelection,
  Finish,
}

const CreateGiftProfileWizard: FC = () => {
  const classes = useStyles();
  const [step, setStep] = useState(CreateGiftProfileWizardSteps.CreateProfileIntro);
  const history = useHistory();
  const [deleteNotification] = useMutation<DeleteManyNotificationsMutation, DeleteManyNotificationsMutationVariables>(
    deleteManyNotifications
  );
  const { authState } = useAuthContext();
  const dispatch = useDispatch();
  const onboardingState = useSelector<RootState, OnboardingRootState>((state) => {
    return state.onboarding;
  });
  const giftIdeasState = useSelector<RootState, GiftIdeaRootState>((state) => {
    return state.giftIdeas;
  });
  const [submitProfile] = useUpsertGiftProfile();
  const confirmProfile = async () => {
    dispatch({ ...setLoading(true) });
    const { gender, giftTypes } = onboardingState;
    const { selectedGifts } = giftIdeasState;

    await submitProfile({
      profile: selectedGifts.length > 0 ? selectedGifts : null,
      userId: authState.user.auth.id,
      interests: giftTypes.length > 0 ? giftTypes : null,
      gender,
    });
    dispatch({ ...setGiftProfile() });
    await deleteNotification({
      variables: {
        query: {
          type: NotificationType.GIFTPROFILEREQUEST,
          userId: authState.user.auth.id,
        },
      },
      refetchQueries: [
        {
          query: getNotificationsQuery,
          variables: {
            query: {
              userId: authState.user.auth.id,
            },
          },
        },
      ],
    });
    dispatch({ ...setLoading(false) });
    history.push("/home/giftees?" + giftProfileCreatedParam + "=true");
  };

  return (
    <div className={classes.main} data-testid="gift-profile-insert-wizard">
      <a href="/home">
        <img className={classes.logo} src="images/logos/bright-logo.png" />
      </a>
      <div className={classes.onboardingArea}>
        <div className={classes.wizardContainer}>
          <StepWizard
            onStepChange={({ activeStep }) => setStep(activeStep)}
            nav={<CreateGiftProfileNav onFinish={confirmProfile} step={step} />}
            className={classes.wizard}
          >
            <GiftProfileCreationIntro
              bodyText="Great choice! Once you create your gift profile, you'll be getting the most out of Occasional.ly. This will allow friends and
          family to get the best possible gift recommendations for you."
            />
            <GiftTypesInput />
            <SetGender />
            <GiftSelection />
            <GiftProfileCreationOutro />
          </StepWizard>
        </div>
      </div>
    </div>
  );
};

export default CreateGiftProfileWizard;
