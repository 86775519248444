import DOMPurify from 'dompurify';
import React, { FC, useMemo } from 'react';

import { CircularProgress, Icon, IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import GiftIdeaDetailCtas from './GiftIdeaDetailCtas';
import GiftIdeaDetailHeader from './GiftIdeaDetailHeader';
import { GiftIdea } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  headerActions: {
    zIndex: 10,
  },
  modal: {
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: "calc(100vh - 145px)",
    background: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 85px)",
    },
  },
  closeButton: {
    "&.MuiIconButton-root": {
      background: theme.palette.secondary.main,
      color: theme.palette.common.white,
      height: 36,
      width: 36,
    },
  },
  header: {
    position: "relative",
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    flex: "initial",
    display: "flex",
    justifyContent: "flex-start",
    "& h5": {
      zIndex: 10,
      whiteSpace: "initial",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 10,
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
  giftIdeaDescription: {
    position: "relative",
    color: theme.palette.text.primary,
    padding: 16,
    zIndex: 10,
    whiteSpace: "pre-wrap",
    // "& h2": {
    //   zIndex: 10,
    //   minHeight: 27,
    // },
  },
  productActions: {
    padding: theme.spacing(2),
  },
  viewProduct: {
    paddingTop: theme.spacing(0.5),
  },
  markGiftPurchased: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(3),
  },
}));

type Props = {
  onClose: () => void;
  isMobile: boolean;
  giftIdea: GiftIdea;
  loading?: boolean;
};

const GiftIdeaDetailContent: FC<Props> = ({ onClose, isMobile, giftIdea, loading }) => {
  const classes = useStyles();
  const description = useMemo(
    () => (giftIdea.giftSource === "zazzle" ? null : DOMPurify.sanitize(giftIdea.description ?? "")),
    [giftIdea.description]
  );
  return (
    <div className={classes.modal}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            className={classes.header}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              className={classes.headerActions}
            >
              <IconButton onClick={onClose} className={classes.closeButton} aria-label="close product">
                <Icon>
                  <span className="lnr lnr-cross"></span>
                </Icon>
              </IconButton>
            </Grid>
          </Grid>
          <GiftIdeaDetailHeader showPrice={!isMobile} giftIdea={giftIdea} />
          <Typography variant="body1" className={classes.giftIdeaDescription}>
            <div
              dangerouslySetInnerHTML={{
                __html: description ?? "",
              }}
            />
          </Typography>
          <GiftIdeaDetailCtas viewOnly={false} />
        </>
      )}
    </div>
  );
};

export default GiftIdeaDetailContent;
