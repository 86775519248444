import { useEffect, useMemo } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';

import { useAuthContext } from '../auth/authContext';
import { getGifteesQuery } from '../graphql/queries/giftees';
import {
  getGiftees, getGifteesVariables
} from '../graphql/queries/giftees/__generated__/getGiftees';
import { getUsersQuery } from '../graphql/queries/users';
import {
  getUsers, getUsers_users, getUsersVariables
} from '../graphql/queries/users/__generated__/getUsers';

export const useQueryUsersWhoAddedMe = (): [getUsers_users[], boolean] => {
  const { authState } = useAuthContext();
  const { data: gifteesOfUserData, called: gifteesCalled } = useQuery<getGiftees, getGifteesVariables>(
    getGifteesQuery,
    {
      variables: {
        query: {
          sourceUser: {
            _id: authState.user.customData._id,
          },
        },
      },
    }
  );
  const userIds = useMemo(
    () => gifteesOfUserData?.giftees.map((g) => g?.sourceUser?._id),
    [gifteesCalled, gifteesOfUserData?.giftees]
  );

  const [getUsers, { data, loading, called }] = useLazyQuery<getUsers, getUsersVariables>(getUsersQuery);

  useEffect(() => {
    if ((userIds?.length ?? 0) > 0 && gifteesCalled) {
      getUsers({
        variables: {
          query: {
            _id_in: userIds,
          },
        },
      });
    }
  }, [userIds]);

  const users = useMemo<getUsers_users[]>(
    () =>
      (data?.users?.filter((user) => Boolean(user))?.map((user) => ({ ...user, _id: user?.authId })) ??
        []) as getUsers_users[],
    [data?.users, called]
  );

  return [users, loading];
};
