import { User } from '../../../@types/user';
import { GifteeRelationship, GiftProfileGender } from '../../../__generated__/globalTypes';
import {
  getOccasions_occasions
} from '../../../graphql/queries/occasions/__generated__/getOccasions';
import {
  ADD_GIFTEE_OCCASION, DESELECT_OCCASION, DESELECT_USER, REMOVE_GIFTEE_OCCASION, RESET_ADD_GIFTEE,
  SELECT_OCCASION, SELECT_USER, SET_ADD_GIFTEE_LOADING, SET_GIFTEE_BIRTHDAY, SET_GIFTEE_GENDER,
  SET_GIFTEE_INTERESTS, SET_GIFTEE_NAME, SET_GIFTEE_OCCASIONS, SET_GIFTEE_PICTURE, SET_INVITE_SENT,
  SET_RELATIONSHIP, SET_SHOW_ADD_FIRST_GIFTEE_DIALOG, SET_SHOW_ADD_GIFTEE_SUGGESTION_DIALOG,
  SET_SHOW_ADD_GIFTEE_WIZARD, SET_SHOW_IMPORT_GIFTEE_WIZARD
} from '../constants/action-types';

export const addOccasion = (occasion: Partial<getOccasions_occasions>) => {
  return { type: ADD_GIFTEE_OCCASION, payload: occasion };
};
export const removeOccasion = (occasion: Partial<getOccasions_occasions>) => {
  return { type: REMOVE_GIFTEE_OCCASION, payload: occasion._id };
};
export const setOccasions = (occasions: Partial<getOccasions_occasions>[]) => {
  return { type: SET_GIFTEE_OCCASIONS, payload: occasions };
};
export const selectOccasion = (occasion: Partial<getOccasions_occasions>) => {
  return { type: SELECT_OCCASION, payload: occasion };
};
export const deselectOccasion = (occasion: Partial<getOccasions_occasions>) => {
  return { type: DESELECT_OCCASION, payload: occasion._id };
};
export const selectUser = (user: Partial<User["customData"]>) => {
  return { type: SELECT_USER, payload: { ...user, birthday: undefined } };
};
export const deSelectUser = () => {
  return { type: DESELECT_USER };
};
export const setInviteSent = (value: boolean) => {
  return { type: SET_INVITE_SENT, payload: value };
};
export const setRelationship = (value: GifteeRelationship) => {
  return { type: SET_RELATIONSHIP, payload: value };
};
export const setShowAddGifteeWizard = (value: boolean) => {
  return { type: SET_SHOW_ADD_GIFTEE_WIZARD, payload: value };
};
export const setShowImportGifteeWizard = (value: boolean) => {
  return { type: SET_SHOW_IMPORT_GIFTEE_WIZARD, payload: value };
};
export const setShowAddGifteeSuggestionDialog = (value: boolean) => {
  return { type: SET_SHOW_ADD_GIFTEE_SUGGESTION_DIALOG, payload: value };
};
export const setShowAddFirstGifteeDialog = (value: boolean) => {
  return { type: SET_SHOW_ADD_FIRST_GIFTEE_DIALOG, payload: value };
};
export const setGifteeBirthday = (value: Date) => {
  return { type: SET_GIFTEE_BIRTHDAY, payload: value };
};
export const setGifteeInterests = (value: string[]) => {
  return { type: SET_GIFTEE_INTERESTS, payload: value };
};
export const setGifteeName = (value: string) => {
  return { type: SET_GIFTEE_NAME, payload: value };
};
export const setGifteePictue = (value: string | undefined) => {
  return { type: SET_GIFTEE_PICTURE, payload: value };
};
export const setGifteeGender = (value: GiftProfileGender) => {
  return { type: SET_GIFTEE_GENDER, payload: value };
};
export const resetAddGifteeState = () => {
  return { type: RESET_ADD_GIFTEE };
};
export const setAddGifteeLoading = (value: boolean) => {
  return { type: SET_ADD_GIFTEE_LOADING, payload: value };
};
