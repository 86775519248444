/* TypeScript file generated from UserGiftProfileEdit.res by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as UserGiftProfileEditBS__Es6Import from './UserGiftProfileEdit.bs';
const UserGiftProfileEditBS: any = UserGiftProfileEditBS__Es6Import;

import type {Json_t as Js_Json_t} from '../../../src/shims/Js.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Props = { readonly userId: Js_Json_t };

export const make: React.ComponentType<{ readonly userId: Js_Json_t }> = UserGiftProfileEditBS.make;
