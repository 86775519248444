import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Occasion as OccasionType } from '../../../@types/occasions';
import { OccasionType as OccasionTypeEnum } from '../../../__generated__/globalTypes';
import { addOccasions, removeOccasion } from '../../../redux/onboarding/actions';
import { OnboardingRootState } from '../../../redux/onboarding/reducers';
import { RootState } from '../../../redux/store';
import getNextOccasionDate from '../../../utils/occasionDates';
import { wizardModalStyles } from '../../../utils/styleHelpers';
import OccasionSelectionList from '../../occasions/OccasionSelectionList';
import { OnboardingWizardSteps } from '../OnboardingWizard';
import OnboardingStep from './OnboardingStep';

const useStyles = makeStyles((theme) => ({
  wizardModal: wizardModalStyles(theme),
  occasionSelectWrapper: {
    height: "100%",
    width: "100%",
    maxWidth: 600,
    [theme.breakpoints.up("lg")]: {
      width: 925,
    },
  },
  occasionSelectHeader: {
    background: theme.palette.primary.transparent,
    borderRadius: theme.shape.borderRadius,
    height: 115,
    padding: theme.spacing(1),
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    color: theme.palette.text.primary,
  },
  headerImage: {
    height: 105,
    marginBottom: -35,
  },
  floatingTitle: {
    color: theme.palette.common.white,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.background.background,
  },
  occasionList: {
    marginTop: 50,
  },
  confirmSelectionsButton: {
    position: "fixed",
    bottom: 50,
  },
}));

type Props = {} & Partial<StepWizardChildProps>;

const SelectOccasions: FC<Props> = ({ nextStep, previousStep, currentStep }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const onboardingState = useSelector<RootState, OnboardingRootState>((state) => {
    return state.onboarding;
  });
  const selectedOccasionTypes = onboardingState.occasions.map((occasion) => occasion.type ?? "");
  const selectOccasion = (occasion: Partial<OccasionType>) => {
    if (occasion.type) {
      const nextOccasionDate = getNextOccasionDate(occasion.type);
      dispatch({ ...addOccasions([{ ...occasion, nextOccasionDate: nextOccasionDate?.toLocaleDateString() ?? "" }]) });
    }
  };
  const deselectOccasion = (occasion: Partial<OccasionType>) => {
    dispatch({ ...removeOccasion(occasion) });
  };
  const excludedOccasions = [OccasionTypeEnum.MOTHERSDAY, OccasionTypeEnum.FATHERSDAY];

  return (
    <OnboardingStep
      step={currentStep ?? OnboardingWizardSteps.Introduction}
      onNext={nextStep}
      onPrevious={previousStep}
    >
      <Grid
        container
        wrap="nowrap"
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        className={classes.occasionSelectWrapper}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          wrap="nowrap"
          className={classes.form}
        >
          <Grid
            container
            className={classes.occasionSelectHeader}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <div>
              <Typography variant="h6" align="left" gutterBottom>
                Pick Your Occasions
              </Typography>
              <Typography variant="subtitle1" align="left">
                On what occasions would you like gifts given to you?
              </Typography>
            </div>
            <img className={classes.headerImage} src="/images/calendar.png" />
          </Grid>
          <OccasionSelectionList
            onDeselectOccasion={deselectOccasion}
            onSelectOccasion={selectOccasion}
            selectedOccasionTypes={selectedOccasionTypes}
            excludedOccasions={excludedOccasions}
          />
        </Grid>
      </Grid>
    </OnboardingStep>
  );
};

export default SelectOccasions;
