import React, { FC, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';

import { OccasionType } from '../../__generated__/globalTypes';
import { useAuthContext } from '../../auth/authContext';
import { getOccasionsQuery } from '../../graphql/queries/occasions';
import {
  getOccasions as getOccasionsType, getOccasions_occasions as OccasionEntityType,
  getOccasionsVariables
} from '../../graphql/queries/occasions/__generated__/getOccasions';
import FloatingActionButton from '../general-ui/buttons/FloatingActionButton';
import SecondaryButton from '../general-ui/buttons/SecondaryButton';
import GiftBoxMessage from '../general-ui/GiftBoxMessage';
import Modal from '../general-ui/modals/Modal';
import Occasion from '../occasions/Occasion';
import NewOccasionWizard from '../wizards/NewOccasionWizard';

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: "80vw",
    maxWidth: 400,
  },
  settingsWrapper: {
    position: "relative",
  },
  addInterestContainer: {
    position: "absolute",
    left: "calc(50% - 20px)",
    bottom: -50,
    color: theme.palette.text.primary,
    "& button": {
      color: theme.palette.text.primary,
    },
  },
  occasionsList: {
    padding: 16,
    height: "calc(100vh - 150px)",
    overflowY: "scroll",
    position: "relative",
    "& h4, label": {
      color: theme.palette.text.primary,
    },
  },
  userOccasions: {
    padding: 0,
  },
}));

const OccasionSettings: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { authState } = useAuthContext();
  const [newOccasionShow, setNewOccasionShow] = useState(false);
  const [newOccasionType, setNewOccasionType] = useState<OccasionType | "">("");
  const { data } = useQuery<getOccasionsType, getOccasionsVariables>(getOccasionsQuery, {
    variables: {
      query: {
        userId: authState.user.auth.id,
      },
    },
  });

  const birthdayOccasion = useMemo(
    () => data?.occasions.find((occasion) => occasion?.type === OccasionType.BIRTHDAY),
    [data?.occasions]
  );

  const handleNewOccasionBtnClick = () => {
    setNewOccasionShow(true);
  };

  const handleBirthdayBtnClick = () => {
    setNewOccasionShow(true);
    setNewOccasionType(OccasionType.BIRTHDAY);
  };

  const hideNewOccasion = () => {
    setNewOccasionShow(false);
  };
  const renderBirthday = () => {
    if (!data?.occasions) {
      return <CircularProgress />;
    } else if (birthdayOccasion == null) {
      return <SecondaryButton onClick={handleBirthdayBtnClick}>Add Your Bithday</SecondaryButton>;
    } else {
      return <Occasion key={birthdayOccasion?._id} occasion={birthdayOccasion} user={true} />;
    }
  };

  const renderOccasionsAll = () => {
    if (!data?.occasions) {
      return <CircularProgress />;
    } else if (data?.occasions.length == 0) {
      return <GiftBoxMessage primary="No Occasions" secondary="Add your occasions here!" />;
    } else {
      return data?.occasions
        .filter((occasion): occasion is OccasionEntityType => occasion?.type !== OccasionType.BIRTHDAY)
        .map((occasion) => <Occasion key={occasion._id} occasion={occasion} user={true} />);
    }
  };

  return (
    <div className={classes.occasionsList}>
      <Typography variant="h6" gutterBottom>
        My Occasions
      </Typography>
      <div className="birthday flex-column-center">
        <label>Your Birthday:</label>
        <ul className={classes.userOccasions}>{renderBirthday()}</ul>
      </div>
      <label>Your Other Occasions:</label>
      <FloatingActionButton
        handler={handleNewOccasionBtnClick}
        message="Add Occasion"
        messagePosition="top"
        position="center"
        icon="lnr-plus"
        color="secondary"
        css={{ zIndex: 200 }}
      />
      <ul className={classes.userOccasions}>{renderOccasionsAll()}</ul>
      <Modal
        header="Add new Occasion to your profile"
        title="Add new Occasion to your profile"
        showModal={newOccasionShow}
        closeModal={hideNewOccasion}
      >
        <NewOccasionWizard
          initialOccasionType={newOccasionType}
          userId={authState.user.auth.id}
          hideModal={hideNewOccasion}
          forUser={true}
        />
      </Modal>
    </div>
  );
};

export default OccasionSettings;
