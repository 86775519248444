import "./App.css";

import React, { FC } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import GifteeDetail from "./components/giftees/GifteeDetail";
import LoginSignup from "./components/login-and-signup/LoginSignup";
import HandleAuthRedirect from "./routes/HandleAuthRedirect";
import Home from "./routes/Home";
import GlobalMessageFeed from "./components/general-ui/global-messages/GlobalMessageFeed";
import PrivateRoute from "./components/router/PrivateRoute";
import ForgotPassword from "./routes/ForgotPassword";
import ResetPassword from "./routes/ResetPassword";
import Welcome from "./routes/Welcome";
import CssBaseline from "@material-ui/core/CssBaseline";
import RoutesContainer from "./RoutesContainer";
import CreateGiftProfile from "./routes/CreateGiftProfile";
import { useServiceWorker } from "./serviceWorker";

const App: FC = () => {
  useServiceWorker();

  return (
    <Router>
      <RoutesContainer>
        <CssBaseline />
        <Switch>
          <Route path="/login" component={LoginSignup} />
          <Route exact path="/login/:id" component={LoginSignup} />
          <Route exact path="/handle-redirect" component={HandleAuthRedirect} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <PrivateRoute exact path="/">
            <Redirect to="/home/giftees" />
          </PrivateRoute>
          <PrivateRoute exact path="/home">
            <Redirect to="/home/giftees" />
          </PrivateRoute>
          <PrivateRoute exact path="/home/giftees">
            <Home />
          </PrivateRoute>
          <PrivateRoute exact path="/home/occasions">
            <Home />
          </PrivateRoute>
          <PrivateRoute exact path="/home/gift-ideas">
            <Home />
          </PrivateRoute>
          <PrivateRoute exact path="/welcome">
            <Welcome />
          </PrivateRoute>
          <PrivateRoute exact path="/gift-profile-create">
            <CreateGiftProfile />
          </PrivateRoute>
          <PrivateRoute path="/giftee/:gifteeId">
            <GifteeDetail />
          </PrivateRoute>

          {/* <Route exact path="/explore-gift-ideas" component={ExploreGiftIdeas}/> */}
          {/* <Route exact path="/dash-admin" component={AdminDash}/> */}
        </Switch>
        <GlobalMessageFeed />
      </RoutesContainer>
    </Router>
  );
};

export default App;
