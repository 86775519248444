import React, { FC } from "react";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  interest: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    animation: "$fadeIn 1s",
    margin: "4px",
    maxWidth: 250,
    "& .MuiChip-icon": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .lnr": {
      color: theme.palette.text.primary,
      fontSize: 18,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    '& [class*="deleteIcon-"]': {
      color: "rgba(255, 255, 255, 0.7)",
    },
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  interestSelect: {
    color: theme.palette.text.primary,
    display: "flex",
    justifyContent: "space-between",
    height: "100px",
    borderBottom: "1px solid white",
    padding: "15px",
    paddingBottom: "5px",
  },
  valueHidden: {
    transition: theme.transitions.duration.standard + " " + theme.transitions.easing.easeInOut,
    opacity: "0",
  },
  valueVisible: {
    transition: theme.transitions.duration.standard + " " + theme.transitions.easing.easeInOut,
    opacity: "1",
  },
  checkMark: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    cursor: "pointer",
    zIndex: 10,
    boxShadow: "inset 0 0 2px #d90386",
  },
}));

type Props = {
  interest: any;
  onDelete?: (interest?: any) => void;
};

const Interest: FC<Props> = ({ interest, onDelete }) => {
  const classes = useStyles();

  return (
    <Chip
      className={classes.interest}
      // icon={
      //   <Icon>
      //     <span className={"lnr " + icon}></span>
      //   </Icon>
      // }
      label={interest}
      color="primary"
      onDelete={onDelete}
    />
  );
};

export default Interest;
