import {
  ADD_OCCASIONS,
  REMOVE_OCCASION,
  UPDATE_OCCASION,
  SET_OCCASIONS,
  SET_ACTIVE_OCCASION,
  OPEN_GIFTS_PICKED_DIALOG,
  CLOSE_GIFTS_PICKED_DIALOG,
  OPEN_VIEW_OCCASION_DIALOG,
  CLOSE_VIEW_OCCASION_DIALOG,
} from "../constants/action-types";
import { GeneralAction } from "../types";
import { getOccasions_occasions as Occasion } from "../../../graphql/queries/occasions/__generated__/getOccasions";

export type OccasionRootState = {
  occasions: Occasion[];
  activeOccasion: Occasion | null;
  openGiftsPickedDialog: boolean;
  openViewOccasionDialog: boolean;
};

const initialState = {
  occasions: [],
  activeOccasion: null,
  openGiftsPickedDialog: false,
  openViewOccasionDialog: false,
};

const reducer = (state: OccasionRootState = initialState, action: GeneralAction) => {
  switch (action.type) {
    case ADD_OCCASIONS:
      return { ...state, occasions: [...state.occasions, ...action.payload.occasions] };
    case REMOVE_OCCASION: {
      const indexOfReminder = state.occasions.findIndex((occasion) => occasion._id === action.payload.occasion._id);
      const newReminders = [...state.occasions];
      newReminders.splice(indexOfReminder, 1);
      return { ...state, occasions: newReminders };
    }
    case UPDATE_OCCASION: {
      const indexOfReminder = state.occasions.findIndex((occasion) => occasion._id === action.payload.occasion._id);
      const updatedReminder = { ...state.occasions[indexOfReminder], ...action.payload };
      const newReminders = [...state.occasions];
      newReminders.splice(indexOfReminder, 1, updatedReminder);
      return { ...state, occasions: newReminders };
    }
    case SET_OCCASIONS:
      return { ...state, occasions: action.payload.occasions };
    case SET_ACTIVE_OCCASION:
      return { ...state, activeOccasion: action.payload.occasion };
    case OPEN_GIFTS_PICKED_DIALOG:
      return { ...state, openGiftsPickedDialog: true };
    case CLOSE_GIFTS_PICKED_DIALOG:
      return { ...state, openGiftsPickedDialog: false };
    case OPEN_VIEW_OCCASION_DIALOG:
      return { ...state, openViewOccasionDialog: true };
    case CLOSE_VIEW_OCCASION_DIALOG:
      return { ...state, openViewOccasionDialog: false };
    default:
      return state;
  }
};

export default reducer;
