import {
  ADD_OCCASIONS,
  REMOVE_OCCASION,
  UPDATE_OCCASION,
  SET_OCCASIONS,
  SET_ACTIVE_OCCASION,
  OPEN_GIFTS_PICKED_DIALOG,
  CLOSE_GIFTS_PICKED_DIALOG,
  OPEN_VIEW_OCCASION_DIALOG,
  CLOSE_VIEW_OCCASION_DIALOG,
} from "../constants/action-types";
import { getOccasion_occasion } from "../../../graphql/queries/occasions/__generated__/getOccasion";

export const addOccasions = (occasions: getOccasion_occasion[]) => {
  return { type: ADD_OCCASIONS, payload: { occasions } };
};

export const removeOccasion = (occasion: getOccasion_occasion) => {
  return { type: REMOVE_OCCASION, payload: { occasion } };
};

export const updateOccasion = (occasion: getOccasion_occasion) => {
  return { type: UPDATE_OCCASION, payload: { occasion } };
};

export const setOccasions = (occasions: getOccasion_occasion[]) => {
  return { type: SET_OCCASIONS, payload: { occasions } };
};
export const setActiveOccasion = (occasion: getOccasion_occasion) => {
  return { type: SET_ACTIVE_OCCASION, payload: { occasion } };
};
export const openGiftsPickedDialog = () => {
  return { type: OPEN_GIFTS_PICKED_DIALOG };
};
export const closeGiftsPickedDialog = () => {
  return { type: CLOSE_GIFTS_PICKED_DIALOG };
};
export const openViewOccasionDialog = () => {
  return { type: OPEN_VIEW_OCCASION_DIALOG };
};
export const closeViewOccasionDialog = () => {
  return { type: CLOSE_VIEW_OCCASION_DIALOG };
};
