import React, { FC } from "react";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

type TabPanelProps = {
  children: React.ReactNode;
  value: number;
  index: number;
  className?: string;
};

const TabPanel: FC<TabPanelProps> = ({ children, value, index, className, ...other }) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={className}
      {...other}
    >
      {value === index && (
        <Box p={3} className={className}>
          {children}
        </Box>
      )}
    </Typography>
  );
};

export default TabPanel;
