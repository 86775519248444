import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import React, { FC, useState } from 'react';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { OccasionType } from '../../__generated__/globalTypes';
import { getOccasions_occasions } from '../../graphql/queries/occasions/__generated__/getOccasions';
import AnimatedCheckmark from '../general-ui/AnimatedCheckmark';
import OccasionGiftee from './OccasionGiftee';
import occasionIcons from './occasionIcons';
import OccasionUser from './OccasionUser';

dayjs.extend(advancedFormat);
dayjs.extend(utc);

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: theme.palette.text.primary,
    background: theme.palette.primary.transparent,
    padding: 0,
    borderRadius: 12,
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  cardSelected: {
    background: theme.palette.primary.strongTransparent,
  },
  occasionSelect: {
    width: "40px",
    height: "40px",
    cursor: "pointer",
    zIndex: 10,
    background: "rgba(255,255,255,0.2)",
    borderRadius: "50%",
  },
  occasionIcon: {
    display: "flex",
    width: 60,
    fontSize: 30,
    color: theme.palette.text.primary,
    position: "relative",
    "& .lnr": {
      position: "absolute",
      left: 0,
      top: 0,
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "&:after": {
      content: '""',
      position: "absolute",
      right: 0,
      height: "100%",
      width: 2,
      background: theme.palette.text.primary,
    },
  },
  occasionContents: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing(2),
    height: 86,
  },
  occasionSummaryDetails: {
    color: theme.palette.text.primary,
    paddingLeft: 10,
  },
  occasionSummaryName: {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
}));

type Props = {
  occasionSelected?: boolean;
  occasion: getOccasions_occasions;
  onSelect?: (occasion: getOccasions_occasions) => void;
  onDeselect?: (occasion: getOccasions_occasions) => void;
  selectable?: boolean;
  user?: boolean;
  showGiftee?: boolean;
  wateredDown?: boolean;
};

const Occasion: FC<Props> = ({
  occasionSelected,
  occasion,
  onSelect,
  onDeselect,
  selectable,
  user,
  showGiftee,
  wateredDown,
}) => {
  const [occasionSelectedState, setOccasionSelectedState] = useState(occasionSelected);

  const selectOccasionHandler = () => {
    const selected = !occasionSelectedState;
    setOccasionSelectedState(selected);
    if (onSelect && selected) {
      onSelect(occasion);
    } else if (onDeselect && !selected) {
      onDeselect(occasion);
    }
  };

  const classes = useStyles();
  const occasionDate = occasion.nextOccasionDate ? occasion.nextOccasionDate : new Date().toDateString();
  const occasionIconClass = occasionIcons(occasion.type ?? OccasionType.BIRTHDAY);
  const occasionIcon = <span className={occasionIconClass}></span>;

  if (selectable) {
    return (
      <Card className={classes.card} data-testid={`selectable-occasion`}>
        <CardActionArea onClick={selectOccasionHandler}>
          <div className={classes.occasionContents}>
            <div className={classes.occasionContents}>
              <div className={classes.occasionIcon}>
                <div>{occasionIcon}</div>
              </div>
              <Grid
                container
                direction="column"
                justifyContent="space-evenly"
                alignItems="flex-start"
                className={classes.occasionSummaryDetails}
              >
                <div className={classes.occasionSummaryName}>{occasion.name}</div>
                <div>{occasion.nextOccasionDate && dayjs(occasion.nextOccasionDate).utc().format("MMM Do")}</div>
              </Grid>
            </div>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <div role="button" className={classes.occasionSelect}>
                <AnimatedCheckmark
                  otherStyles={{ zIndex: 12, height: 40, width: 40 }}
                  checked={occasionSelectedState}
                />
              </div>
            </Grid>
          </div>
        </CardActionArea>
      </Card>
    );
  } else if (user) {
    return <OccasionUser occasion={occasion} />;
  } else if (!wateredDown) {
    return <OccasionGiftee occasion={occasion} showGiftee={showGiftee} />;
  } else {
    return (
      <div className="the-occasion">
        <li className="single-occasion">
          <ul className="occasion-info">
            <li>
              {occasionIcon}&nbsp; {occasion.name}
            </li>
            <li> {occasionDate}</li>
          </ul>
        </li>
      </div>
    );
  }
};

export default Occasion;
