// This optional code is used to register a service worker.
// register() is not called by default.

import { initializeFirebase } from "./firebaseRegister";
import { Workbox } from "workbox-window";
import { useEffect } from "react";
import { useGlobalMessageContext } from "./components/general-ui/global-messages/globalMessageContext";
// Your other SW code goes here.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

type Config = {
  onUpdate?: () => void;
};

const swUrl = `${process.env.PUBLIC_URL}/sw.js`;
const wb = new Workbox(swUrl);

export function useServiceWorker() {
  const { globalMessageDispatch } = useGlobalMessageContext();

  useEffect(() => {
    const onUpdate = () => {
      // `event.wasWaitingBeforeRegister` will be false if this is
      // the first time the updated service worker is waiting.
      // When `event.wasWaitingBeforeRegister` is true, a previously
      // updated service worker is still waiting.
      // You may want to customize the UI prompt accordingly.
      globalMessageDispatch({
        type: "ADD_GLOBAL_MESSAGE",
        payload: {
          children: "New content detected. Click to reload: ",
          callback: () => {
            wb.addEventListener("controlling", (event) => {
              window.location.reload();
            });
            wb.messageSkipWaiting();
          },
          notCloseable: true,
        },
      });
    };
    window.addEventListener("load", () => {
      register({ onUpdate });
    });
  }, []);
}

async function register(config?: Config) {
  if ("serviceWorker" in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    //this should only be firing on load, only have this function fire in onload listener
    if (isLocalhost) {
      // This is running on localhost. Let's check if a service worker still exists or not.
      await checkValidServiceWorker(swUrl, config);
      // Add some additional logging to localhost, pointing developers to the
      // service worker/PWA documentation.
      await navigator.serviceWorker.ready;
      console.log(
        "This web app is being served cache-first by a service " + "worker. To learn more, visit https://bit.ly/CRA-PWA"
      );
    } else {
      // Is not localhost. Just register service worker
      await registerValidSW(swUrl, config);
    }
  }
}

// const listenForWaitingServiceWorker = (
//   reg: ServiceWorkerRegistration,
//   callback: (r: ServiceWorkerRegistration) => void
// ) => {
//   const awaitStateChange = () => {
//     reg.installing?.addEventListener("statechange", (self: any) => {
//       if (self.state === "installed") callback(reg);
//     });
//   };
//   if (!reg) return;
//   if (reg.waiting) return callback(reg);
//   if (reg.installing) awaitStateChange();
//   reg.addEventListener("updatefound", awaitStateChange);
// };

// function promptUserToRefresh(reg: ServiceWorkerRegistration) {
//   // this is just an example
//   // don't use window.confirm in real life; it's terrible
//   if (window.confirm("New version available! OK to refresh?")) {
//     reg.waiting?.postMessage("skipWaiting");
//   }
// }

async function registerValidSW(swUrl: string, config?: Config) {
  // console.log("registering");
  // console.log(swUrl);
  try {
    const r = await registerWorkbox(swUrl, config);
    console.log(r);
    if (r != null) {
      initializeFirebase(r);
      // registration = r;
      // registration.onupdatefound = () => {
      //   // console.log("sw update found");
      //   // console.log(registration);
      //   const installingWorker = registration.installing;
      //   if (installingWorker == null) {
      //     return;
      //   }
      //   installingWorker.onstatechange = () => {
      //     // console.log("service worker state change");
      //     // console.log(installingWorker.state);
      //     if (installingWorker.state === "installed") {
      //       if (navigator.serviceWorker.controller) {
      //         // At this point, the updated precached content has been fetched,
      //         // but the previous service worker will still serve the older
      //         // content until all client tabs are closed.
      //         console.log(
      //           "New content is available and will be used when all " +
      //             "tabs for this page are closed. See https://bit.ly/CRA-PWA."
      //         );
      //       } else {
      //         // At this point, everything has been precached.
      //         // It's the perfect time to display a
      //         // "Content is cached for offline use." message.
      //         // console.log("Content is cached for offline use.");

      //         // Execute callback
      //         if (config && config.onSuccess) {
      //           config.onSuccess(registration);
      //         }
      //       }
      //     }
      //   };
      // };
    }
  } catch (error) {
    console.error("Error during service worker registration: ", error);
  }
}

async function registerWorkbox(swUrl: string, config?: Config) {
  // Add an event listener to detect when the registered
  // service worker has installed but is waiting to activate.
  wb.addEventListener("waiting", () => config?.onUpdate?.());
  wb.addEventListener("activated", (event) => {
    // `event.isUpdate` will be true if another version of the service
    // worker was controlling the page when this version was registered.
    if (!event.isUpdate) {
      // console.log("Service worker activated for the first time!");
      // If your service worker is configured to precache assets, those
      // assets should all be available now.
    }
  });

  return await wb.register();
}

async function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  try {
    const response = await fetch(swUrl, {
      headers: { "Service-Worker": "script" },
    });
    // Ensure service worker exists, and that we really are getting a JS file.
    const contentType = response.headers.get("content-type");
    if (response.status === 404 || (contentType != null && contentType.indexOf("javascript") === -1)) {
      // No service worker found. Probably a different app. Reload the page.
      navigator.serviceWorker.ready.then((registration) => {
        registration.unregister().then(() => {
          window.location.reload();
        });
      });
    } else {
      // console.log("we found service worker!");
      // Service worker found. Proceed as normal.
      registerValidSW(swUrl, config);
    }
  } catch (e) {
    console.log("No internet connection found. App is running in offline mode.");
  }
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
}
