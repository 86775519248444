import React, { FC, useEffect, useRef, useState } from 'react';

import { Grid, IconButton, LinearProgress, makeStyles, Snackbar, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    "& .lnr-cancel": {
      color: theme.palette.text.primary,
    },
  },
  childrenContainer: {
    width: "100%",
  },
}));

type Props = {
  closeable?: boolean;
  countdown?: number;
  finalText?: string;
  persistent?: boolean;
  callback?: () => void;
  notCloseable?: boolean;
};

const GlobalSnackbar: FC<Props> = ({ closeable, children, countdown, persistent, callback, notCloseable }) => {
  const [openState, setOpenState] = useState(true);
  const [countdownState, setCountdownState] = useState<number>(countdown as number);
  const interval = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (countdown && !interval.current && countdownState) {
      interval.current = setInterval(() => {
        setCountdownState((prevCountdownState) => (prevCountdownState as number) - 1000);
      }, 1000);
    }
    if (countdownState === 0) {
      clearInterval(interval.current as NodeJS.Timeout);
    }
  }, [countdown, interval, countdownState]);

  const classes = useStyles();
  const handleClose = (event: React.SyntheticEvent<any, Event>, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenState(false);
  };

  return (
    <Snackbar
      open={openState}
      autoHideDuration={!persistent ? 6000 : null}
      onClose={handleClose}
      classes={{ root: classes.root }}
    >
      <Grid container>
        <Grid container alignItems="center" justifyContent="space-between" direction="row" wrap="nowrap">
          <Grid classes={{ root: classes.childrenContainer }}>{children}</Grid>
          {closeable && (
            <IconButton size="small" aria-label="close" color="inherit" onClick={(e) => handleClose(e, "click")}>
              <span className="lnr lnr-cross2" />
            </IconButton>
          )}
          {callback && (
            <IconButton size="small" aria-label="close" color="inherit" onClick={callback}>
              <span className="lnr lnr-refresh" />
            </IconButton>
          )}
          {!notCloseable && (
            <IconButton size="small" aria-label="close" color="inherit" onClick={(e) => handleClose(e, "close")}>
              <span className="lnr lnr-cross" />
            </IconButton>
          )}
        </Grid>
        <Grid>
          {countdown && (
            <LinearProgress variant="determinate" value={((countdown - countdownState) / countdown) * 100} />
          )}
        </Grid>
      </Grid>
    </Snackbar>
  );
};

export default GlobalSnackbar;
