import Grid from "@material-ui/core/Grid/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React, { useMemo } from "react";
import { FC } from "react";
import GeneralAvatarMulti from "../general-ui/GeneralAvatarMulti";
import { GifteeMatch } from "./types";
import { getMatchGradient } from "./utils";

const useStyles = makeStyles((theme) => ({
  gifteeName: {
    whiteSpace: "nowrap",
    "&.MuiTypography-root": {
      fontSize: 8,
    },
  },
  gifteeChip: {
    display: "flex",
    alignItems: "center",
    boxShadow: theme.shadows[1],
    borderRadius: 30,
    overflow: "hidden",
    height: theme.spacing(3.5),
    width: 100,
    padding: 3,
    background: theme.palette.common.white,
  },
  gifteeMatchAvatar: {
    marginRight: theme.spacing(0.5),
  },
}));

type Props = {
  giftees: GifteeMatch[];
};

const GifteeMatchMulti: FC<Props> = ({ giftees }) => {
  const classes = useStyles();
  const firstGiftee = giftees[0];
  const firstSimilarity = firstGiftee.similarity;
  const avatars = useMemo(
    () =>
      giftees.map((g) => ({
        alt: g.name ?? undefined,
        src: g.picture ?? undefined,
        children: <>{g.name?.charAt(0)}</>,
      })),
    [giftees.length]
  );
  return (
    <div className={classes.gifteeChip}>
      <div className={classes.gifteeMatchAvatar}>
        <GeneralAvatarMulti avatars={avatars} size="extremely-small" />
      </div>
      <Grid container direction="column" wrap="nowrap">
        <Typography
          variant="subtitle2"
          className={classes.gifteeName}
          style={{ color: getMatchGradient(firstSimilarity ?? 0) }}
        >
          {firstGiftee.name} <br /> and {giftees.length - 1} others
        </Typography>
      </Grid>
    </div>
  );
};

export default GifteeMatchMulti;
