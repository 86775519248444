import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import Grid from '@material-ui/core/Grid/Grid';
import IconButton from '@material-ui/core/IconButton/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';

import useIsMobile from '../../../hooks/useIsMobile';
import { setShowAddGifteeWizard } from '../../../redux/add-giftee/actions';
import { RESET_ADD_GIFTEE } from '../../../redux/add-giftee/constants/action-types';
import { AddGifteeRootState } from '../../../redux/add-giftee/reducers';
import { RootState } from '../../../redux/store';
import PrimaryButton from '../../general-ui/buttons/PrimaryButton';
import { AddGifteeWizardSteps } from '../AddGifteeWizard';
import {
  useWizardHandleBack, useWizardHandleNext, useWizardNavProps, WizardNavProps
} from './hooks';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "absolute",
    bottom: 10,
    width: "100%",
    zIndex: 10,
    padding: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      position: "fixed",
      bottom: 0,
      width: "100vw",
      display: "flex",
    },
  },
  backButton: {
    zIndex: 10,
    position: "absolute",
    top: 20,
    left: 20,
    color: theme.palette.text.primary,
  },
  closeButton: {
    zIndex: 10,
    position: "absolute",
    top: 20,
    right: 20,
    color: theme.palette.text.primary,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  ctaButton: {
    paddingTop: theme.spacing(2),
    "& .MuiButtonBase-root": {
      height: 56,
      fontSize: 20,
      [theme.breakpoints.up("sm")]: {
        maxWidth: 275,
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingBottom: theme.spacing(1),
      alignSelf: "flex-end",
    },
  },
}));

type Props = {
  navPropsCallback: (step: number, addGifteeState: AddGifteeRootState) => WizardNavProps;
  handleNextResolver: (step: number, defaultNext: () => void) => (callback?: () => void) => void;
} & Partial<StepWizardChildProps>;

const WizardNav: FC<Props> = ({
  nextStep,
  currentStep,
  navPropsCallback,
  previousStep,
  goToStep,
  handleNextResolver,
}) => {
  const addGifteeState = useSelector<RootState, AddGifteeRootState>((state) => {
    return state.addGiftee;
  });
  const dispatch = useDispatch();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const { hideNav, buttonText, disabled, hideBack } = useWizardNavProps(
    currentStep ?? 0,
    addGifteeState,
    navPropsCallback
  );

  const hideNewGifteeForm = () => {
    dispatch({ type: RESET_ADD_GIFTEE });
    dispatch({ ...setShowAddGifteeWizard(false) });
  };

  const handleBackResolver = useCallback((step: AddGifteeWizardSteps) => {
    switch (step) {
      case AddGifteeWizardSteps.AddCustomGiftee:
        return () => goToStep?.(AddGifteeWizardSteps.InviteUserOrAddCustomGiftee);
      case AddGifteeWizardSteps.AddUserPageOne:
        return () => goToStep?.(AddGifteeWizardSteps.AddGifteePageOne);
      default:
        return () => previousStep?.();
    }
  }, []);

  const handleNextStep = () => {
    nextStep?.();
  };

  const handleBack = useWizardHandleBack(currentStep ?? 0, handleBackResolver);
  const handleNext = useWizardHandleNext(currentStep ?? 0, handleNextResolver, handleNextStep);

  const onBack = () => {
    handleBack?.();
  };
  const onNext = () => {
    handleNext?.();
  };

  return (
    <>
      {!hideBack && (
        <IconButton className={classes.backButton} onClick={onBack}>
          <span className="lnr lnr-arrow-left"></span>
        </IconButton>
      )}
      <IconButton className={classes.closeButton} onClick={hideNewGifteeForm}>
        <span className="lnr lnr-cross"></span>
      </IconButton>
      {!hideNav && (
        <div className={classes.wrapper}>
          <Grid
            container
            direction={isMobile ? "column" : "column-reverse"}
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            className={classes.ctaButton}
          >
            <div className={classes.buttonContainer}>
              <PrimaryButton disabled={disabled} onClick={onNext}>
                {buttonText}
              </PrimaryButton>
            </div>
          </Grid>
        </div>
      )}
    </>
  );
};

export default WizardNav;
