import { MuiThemeProvider } from "@material-ui/core/styles";
import { DefaultTheme } from "@material-ui/styles";
import React, { FC, useEffect, useState } from "react";
import { darkTheme, lightTheme } from "./theme";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/store";
import { GeneralRootState } from "./redux/general/reducers";
import { setDarkMode, toggleDarkMode } from "./redux/general/actions";

type Props = {
  children: React.ReactChild;
};

const ThemeWrapper: FC<Props> = ({ children }) => {
  const [theme, setTheme] = useState<DefaultTheme>(darkTheme);
  const dispatch = useDispatch();
  const generalState = useSelector<RootState, GeneralRootState>((state) => {
    return state.general;
  });

  const { darkMode } = generalState;

  useEffect(() => {
    if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
      setTheme(darkTheme);
      dispatch({ ...setDarkMode(true) });
    } else {
      setTheme(lightTheme);
      dispatch({ ...setDarkMode(false) });
    }
    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (e) => {
      const theme = e.matches ? darkTheme : lightTheme;
      setTheme(theme);
      dispatch({ ...setDarkMode(e.matches) });
    });
  }, []);

  useEffect(() => {
    if (darkMode) {
      setTheme(darkTheme);
    } else {
      setTheme(lightTheme);
    }
  }, [darkMode]);

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeWrapper;
