export const ADD_GLOBAL_MESSAGE = "ADD_GLOBAL_MESSAGE";
export const OPEN_NOTIFICATIONS_MENU = "OPEN_NOTIFICATIONS_MENU";
export const CLOSE_NOTIFICATIONS_MENU = "CLOSE_NOTIFICATIONS_MENU";
export const TOGGLE_USER_MENU = "TOGGLE_USER_MENU";
export const OPEN_USER_MENU = "OPEN_USER_MENU";
export const CLOSE_USER_MENU = "CLOSE_USER_MENU";
export const TOGGLE_DARK_MODE = "TOGGLE_DARK_MODE";
export const SET_DARK_MODE = "SET_DARK_MODE";
export const SET_SHOW_GIFT_PROFILE_CREATED = "SET_SHOW_GIFT_PROFILE_CREATED";
export const RESET_GENERAL_STATE = "RESET_GENERAL_STATE";
export const GO_TO_MENU_STEP = "GO_TO_MENU_STEP";
