import React, { FC } from "react";
import { makeStyles, useTheme, Theme } from "@material-ui/core";
import classnames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  checkmarkWrapper: {
    position: "relative",
  },

  circle: {
    strokeDasharray: 166,
    strokeDashoffset: 166,
    strokeWidth: 2,
    strokeMiterlimit: 10,
    fill: "none",
  },
  checkedCircle: {
    animation: "$stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards",
  },
  checkmark: {
    width: "100%",
    height: "auto",
    position: "relative",
    borderRadius: "50%",
    display: "block",
    strokeWidth: 2,
    stroke: "#fff",
    strokeMiterlimit: 10,
    boxShadow: "inset 0px 0px 0px var(--box-shadow-color)",
  },
  checkedCheckmark: {
    animation: "$fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both",
  },
  iconWrapper: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: -1,
  },
  check: {
    transformOrigin: "50% 50%",
    strokeDasharray: 48,
    strokeDashoffset: 48,
  },
  checkedCheck: {
    animation: "$stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards",
  },
  "@keyframes stroke": {
    "100%": {
      strokeDashoffset: 0,
    },
  },
  "@keyframes scale": {
    "0%, 100%": {
      transform: "none",
    },
    "50%": {
      transform: "scale3d(1.1, 1.1, 1)",
    },
  },
  "@keyframes fill": {
    "100%": {
      boxShadow: "inset 0px 0px 0px 100px var(--box-shadow-color)",
    },
  },
}));

type Props = {
  color?: string;
  otherStyles?: React.CSSProperties;
  checked?: boolean;
  icon?: React.ReactNode;
};

const AnimatedDelete: FC<Props> = ({ color, otherStyles, checked, icon }) => {
  const theme = useTheme();

  const classes = useStyles();

  if (!color) {
    color = theme.palette.secondary.main;
  }

  const stylesAdded = {
    "--box-shadow-color": color,
    ...otherStyles,
  };

  const checkmarkClasses = classnames({
    [classes.checkmark]: true,
    [classes.checkedCheckmark]: checked,
  });

  const circleClasses = classnames({
    [classes.circle]: true,
    [classes.checkedCircle]: checked,
  });

  const checkClasses = classnames({
    [classes.check]: true,
    [classes.checkedCheck]: checked,
  });

  return (
    <div className={classes.checkmarkWrapper} style={stylesAdded as React.CSSProperties}>
      {icon && <div className={classes.iconWrapper}>{icon}</div>}
      <svg
        className={checkmarkClasses}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
        preserveAspectRatio="xMidYMid meet"
      >
        <circle className={circleClasses} cx="26" cy="26" r="25" fill="none" stroke={color} />

        <path className={checkClasses} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </svg>
    </div>
  );
};

export default AnimatedDelete;
