import React, { Component, FC } from 'react';

import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme: Theme) => ({
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    background: theme.palette.background.background,
    color: theme.palette.text.primary,
    justifyContent: "space-between",
  },
  drawerOptions: {
    display: "flex",
    alignItems: "center",
    padding: "4px 8px",
    height: 40,
    background: theme.palette.background.background,
    color: theme.palette.text.primary,
    justifyContent: "space-between",
  },
  settingsButton: {
    color: theme.palette.text.primary,
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  headerTitle: {
    display: "flex",
    alignItems: "center",
    "& h3, h5": {
      margin: 0,
    },
  },
  headerIcon: {
    fontSize: 40,
    marginRight: 4,
  },
  drawer: {
    flexShrink: 0,
    [theme.breakpoints.up("sm")]: {
      width: 240,
      flexShrink: 0,
    },
  },
  closeButton: {
    color: theme.palette.text.primary,
    fontSize: 40,
  },
}));

type Props = {
  closeNotifications: () => void;
  notificationsCount?: number;
  currentStep?: number;
  goToStep?: (step: number) => void;
};

const NotificationsNav: FC<Props> = ({ closeNotifications, goToStep, notificationsCount, currentStep }) => {
  const handleDrawerClose = () => {
    closeNotifications();
  };

  const goToStepHandler = (step: number) => {
    if (goToStep) {
      goToStep(step);
    }
  };

  const classes = useStyles();

  return (
    <div>
      <div className={classes.drawerHeader}>
        <div className={classes.headerTitle}>
          <Icon className={classes.headerIcon}>
            <span className="lnr lnr-alarm"></span>
          </Icon>
          <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" wrap="nowrap">
            <Typography variant="h6" component="h3">
              Notifications
            </Typography>
            <Typography variant="subtitle1" component="h5">
              {notificationsCount} new
            </Typography>
          </Grid>
        </div>
        <IconButton onClick={handleDrawerClose}>
          <ChevronRightIcon color="action" className={classes.closeButton} />
        </IconButton>
      </div>
      <div className={classes.drawerOptions}>
        {currentStep === 2 ? (
          <button className={classes.settingsButton} onClick={() => goToStepHandler(1)}>
            <span className="lnr lnr-arrow-left"></span>
            &nbsp; Go Back
          </button>
        ) : (
          <button className={classes.settingsButton} onClick={() => goToStepHandler(2)}>
            <span className="lnr lnr-cog"></span>
            &nbsp; Settings
          </button>
        )}
      </div>
    </div>
  );
};

export default NotificationsNav;
