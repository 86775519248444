import { ApolloClient } from "@apollo/client";
import { useLazyQuery } from "@apollo/client/react/hooks/useLazyQuery";
import { LazyQueryResult } from "@apollo/client/react/types/types";
import { useCallback, useRef } from "react";
import { userSearch } from "../graphql/queries/users";
import { UserSearch, UserSearchVariables } from "../graphql/queries/users/__generated__/UserSearch";
import { debounceString } from "../utils/helpers/functionHelpers";

const useUserSearch = (
  client?: ApolloClient<object>
): [(arg: string) => void, LazyQueryResult<UserSearch, UserSearchVariables>] => {
  const [searchUsers, searchUsersContext] = useLazyQuery<UserSearch, UserSearchVariables>(userSearch, {
    fetchPolicy: "network-only",
    client,
  });
  const initialSearchUsers = useRef(searchUsers);
  const callUserSearch = useCallback(
    debounceString(async (search: string) => {
      await searchUsers({
        variables: {
          input: search,
        },
      });
    }, 1000),
    [initialSearchUsers.current]
  );

  return [callUserSearch, searchUsersContext];
};

export default useUserSearch;
