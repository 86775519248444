import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Giftee as GifteeType } from '../../../@types/giftee';
import { RESET_NEW_OCCASION_STATE } from '../../../redux/new-occasion/constants/action-types';
import { NewOccasionRootState } from '../../../redux/new-occasion/reducers';
import { RootState } from '../../../redux/store';
import AnimatedCheckmark from '../../general-ui/AnimatedCheckmark';
import PrimaryButton from '../../general-ui/buttons/PrimaryButton';
import WizardStep from '../general-wizard-ui/WizardStep';

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    cursor: "pointer",
    zIndex: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    height: "330px",
  },
}));

type Props = {
  hideModal: () => void;
  giftee: GifteeType;
} & Partial<StepWizardChildProps>;

const OccasionConfirmed: FC<Props> = ({ hideModal, goToStep, giftee }) => {
  const dispatch = useDispatch();
  const { occasionName, confirmed } = useSelector<RootState, NewOccasionRootState>((state) => {
    return state.newOccasion;
  });

  const handleGoToStep = (step: number) => {
    if (goToStep) {
      goToStep(step);
    }
  };
  const hideModalHandler = () => {
    hideModal();
    handleGoToStep(1);
    dispatch({ type: RESET_NEW_OCCASION_STATE });
  };

  const classes = useStyles();

  return (
    <WizardStep>
      <Grid container direction="column" justifyContent="space-between" alignItems="center">
        <div className={classes.success}>
          {confirmed ? <AnimatedCheckmark checked={confirmed} /> : <CircularProgress size={100} thickness={2} />}
        </div>
        <div>
          <h3>Occasion has been added</h3>
          <h5>
            Hooray! You are staying on top of <strong>{occasionName ? occasionName : "this occasion"}</strong> for{" "}
            <strong>{giftee ? giftee.name : "this cool giftee"}</strong>! Way to go!
          </h5>
        </div>
        <PrimaryButton disabled={!confirmed} onClick={hideModalHandler}>
          Cool!
        </PrimaryButton>
      </Grid>
    </WizardStep>
  );
};

export default OccasionConfirmed;
