import gql from "graphql-tag";

export const getNotificationsQuery = gql`
  query getNotifications($query: NotificationQueryInput) {
    notifications(query: $query) {
      _id
      archived
      createdAt
      new
      occasion {
        name
        nextOccasionDate
        type
      }
      giftee {
        name
        _id
        createdAt
        userId
        picture
        interests
      }
      type
      userId
      requester {
        _id
        name
        firstName
        picture
        email
      }
      requestee {
        _id
        name
        firstName
        picture
        email
      }
      invitee {
        _id
        name
        firstName
        picture
        email
      }
    }
  }
`;
