import 'cropperjs/dist/cropper.css';

import React, { FC, RefObject, useRef, useState } from 'react';
import Cropper from 'react-cropper';

import Grid from '@material-ui/core/Grid';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';

import PrimaryButton from './buttons/PrimaryButton';
import SecondaryButton from './buttons/SecondaryButton';

const styles = (theme: Theme) =>
  createStyles({
    photoAlter: {
      "& .cropper-view-box": {
        borderRadius: "50%",
      },
    },
    dialogButtons: {
      marginTop: 20,
      "& button:nth-child(1)": {
        marginRight: 5,
      },
    },
  });

type Props = {
  classes: {
    photoAlter: string;
    dialogButtons: string;
  };
  src?: string;
  onClose: () => void;
  onSave: (base64: string) => void;
};

const PhotoAlterDialog: FC<Props> = ({ classes, src, onClose, onSave }) => {
  const [image, setImage] = useState(src);
  const cropper = useRef<Cropper>() as RefObject<Cropper>;

  const handleClose = () => onClose();

  const _crop = () => {
    //get the cropper base64
    const base64 = cropper?.current?.getCroppedCanvas?.().toDataURL?.();
    // console.log(base64);
    //set it as internal state
    if (base64) {
      setImage(base64);
      onSave(base64);
    }
  };
  const _rotate = () => {
    cropper?.current?.rotate(45);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      wrap="nowrap"
      className={classes.photoAlter}
    >
      <Cropper ref={cropper} src={image} style={{ height: 400, width: "100%" }} aspectRatio={12 / 12} guides={false} />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        wrap="nowrap"
        className={classes.dialogButtons}
      >
        <SecondaryButton onClick={_rotate}>
          <span className="lnr lnr-redo2"></span> &nbsp; Rotate
        </SecondaryButton>
        <PrimaryButton onClick={_crop}>
          <span className="lnr lnr-checkmark-circle"></span> &nbsp; Crop
        </PrimaryButton>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(PhotoAlterDialog);
