export const getMatchGradient = (similarityNumber: number) => {
  const color1 = [95, 185, 166];
  const color2 = [244, 67, 54];
  const w = similarityNumber * 2 - 1;
  const w1 = (w / 1 + 1) / 2;
  const w2 = 1 - w1;
  const rgb = [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2),
  ];
  return "rgb(" + rgb[0] + ", " + rgb[1] + ", " + rgb[2] + ")";
};

export const parseSimilarity = (similarity: string) => {
  return parseFloat(similarity);
};

export const formatSimilarity = (similarity: number) => {
  return `${(similarity * 100).toFixed(0)}% Match`;
};
