// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";

var ObserverEntry = {};

function make(rootOpt, rootMarginOpt, thresholdOpt, param) {
  var root = rootOpt !== undefined ? Caml_option.valFromOption(rootOpt) : null;
  var rootMargin = rootMarginOpt !== undefined ? rootMarginOpt : "0px";
  var threshold = thresholdOpt !== undefined ? thresholdOpt : 0;
  return {
          root: root,
          rootMargin: rootMargin,
          threshold: threshold
        };
}

var ObserverOptions = {
  make: make
};

var Observer = {};

export {
  ObserverEntry ,
  ObserverOptions ,
  Observer ,
  
}
/* No side effect */
