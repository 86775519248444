import { Theme } from '@material-ui/core/styles/createTheme';

import createMyTheme from './customTheme';

const theme = createMyTheme({});

export const darkTheme = createMyTheme({
  palette: {
    type: "dark" as const,
    primary: {
      light: "#B1BEBB",
      transparent: "rgba(95, 185, 166, 0.09)",
      strongTransparent: "rgba(95, 185, 166, 0.2)",
      main: "#5FB9A6",
      dark: "#335850",
      gradient: "linear-gradient(-68deg, #151E27 , #335850)",
    },
    secondary: {
      main: "#C68A77",
      transparent: "rgba(198, 138, 119, 0.7)",
    },
    info: {
      main: "#e675a8",
      light: "#2196f3",
      gradient: "linear-gradient(90deg, #E2207B, #C68A77)",
    },
    error: {
      light: "#e5a0a0",
      main: "#CD5C5C",
      dark: "#ff3b93",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "RGBA(255, 255, 255, 0.87)",
      hint: "RGBA(255, 255, 255, 0.7)",
      faint: "RGBA(255, 255, 255, 0.6)",
      overlay: "RGBA(255, 255, 255, 0.05)",
      primaryBranded: "#AFDCD3",
      primaryBrandedLight: "RGBA175, 220, 211, 0.87)",
      primaryBrandedHint: "RGBA(175, 220, 211, 0.7)",
      primaryBrandedFaint: "RGBA(175, 220, 211, 0.6)",
      secondaryBranded: "#E3C5BB",
      secondaryBrandedHint: "RGBA(227, 197, 187,  0.7)",
      secondaryBrandedLight: "RGBA(227, 197, 187,  0.7)",
      secondaryBrandedFaint: "RGBA(227, 197, 187,  0.7)",
    },
    background: {
      background: "#151E27",
      paper: "#1B2C32",
      paper2DP: "#20383C",
      activatedPaper: "radial-gradient(circle 300px at top right, #424626, #1b2c32)",
      activatedPaper2: "radial-gradient(circle 300px at top right, #4a1e34, #1b2c32)",
      activatedPaperTransparent: "radial-gradient(circle 300px at top right, #424626, rgba(0,0,0,0))",
      gradientBackground: "linear-gradient(#151e27 0%, #1b2c32 100%)",
      gradientBackgroundActivated: "linear-gradient(#151e27 0%, #1b2c32 80%)",
    },
    common: {
      white: "#FFF",
      offWhite: "#FAFAFA",
    },
    grey: {
      200: "#EBEBEB",
    },
  },
  typography: {
    fontFamily: '"Poppins"',
    h1: {
      fontFamily: '"Sacramento"',
    },
    h2: {
      fontFamily: '"Poppins"',
    },
    h3: {
      fontFamily: '"Poppins"',
    },
    h4: {
      fontFamily: '"Poppins"',
    },
    h5: {
      fontFamily: '"Poppins"',
    },
    h6: {
      fontFamily: '"Poppins"',
    },
  },
  shape: {
    borderRadius: 30,
  },
  overrides: {
    MuiDialog: {
      root: {
        zIndex: 1500,
      },
    },
    MuiCircularProgress: {
      indeterminate: {
        strokeLinecap: "round",
      },
      colorSecondary: {
        stroke: "white",
      },
    },
    MuiPaper: {
      elevation1: {},
    },
    MuiButtonBase: {
      root: {
        [theme.breakpoints.down("sm")]: {
          "&:hover.MuiButton-contained": {
            background: "#345850",
          },
        },
      },
    },
    MuiCssBaseline: {
      "@global": {
        html: {
          height: "100%",
          width: "100%",
          WebkitFontSmoothing: "auto",
          background: "#151E27",
        },
      },
    },
  },
});

export const lightTheme = createMyTheme({
  palette: {
    type: "light" as const,
    primary: {
      light: "#B1BEBB",
      transparent: "rgba(95, 185, 166, 0.09)",
      strongTransparent: "rgba(95, 185, 166, 0.2)",
      main: "#2f8d75",
      dark: "#1b503e",
      gradient: "linear-gradient(-68deg, #1b503e , #44a993)",
    },
    secondary: {
      main: "#904e3d",
      transparent: "rgba(144, 78, 61, 0.7)",
    },
    info: {
      main: "#e675a8",
      light: "#2196f3",
      gradient: "linear-gradient(90deg, #E2207B, #C68A77)",
    },
    error: {
      light: "#e5a0a0",
      main: "#CD5C5C",
      dark: "#ff3b93",
    },
    text: {
      primary: "#000",
      secondary: "RGBA(0, 0, 0, 0.87)",
      hint: "RGBA(0, 0, 0, 0.7)",
      faint: "RGBA(0, 0, 0, 0.6)",
      overlay: "RGBA(0, 0, 0, 0.05)",
      primaryBranded: "#44a993",
      primaryBrandedLight: "RGBA(175, 220, 211, 0.87)",
      primaryBrandedHint: "#2f8d75",
      primaryBrandedFaint: "#1b503e",
      secondaryBranded: "#b2705d",
      secondaryBrandedHint: "#904e3d",
      secondaryBrandedLight: "#904e3d",
      secondaryBrandedFaint: "RGBA(227, 197, 187,  0.7)",
    },
    background: {
      background: "#FFF",
      paper: "#ECECEC",
      paper2DP: "#DFDFDF",
      activatedPaper: "radial-gradient(circle 300px at top right, #ecd1e3, #ECECEC)",
      activatedPaper2: "radial-gradient(circle 300px at top right, #DBA1C8, #ECECEC)",
      activatedPaperTransparent: "radial-gradient(circle 300px at top right, #F2FF9A, rgba(0,0,0,0))",
      gradientBackground: "linear-gradient(#FFF 0%, #eaeaea  100%)",
      gradientBackgroundActivated: "linear-gradient(#FFF 0%, #eaeaea  80%)",
    },
    common: {
      white: "#FFF",
      offWhite: "#FAFAFA",
    },
    grey: {
      200: "#EBEBEB",
    },
  },
  typography: {
    fontFamily: '"Poppins"',
    h1: {
      fontFamily: '"Sacramento"',
    },
    h2: {
      fontFamily: '"Poppins"',
    },
    h3: {
      fontFamily: '"Poppins"',
    },
    h4: {
      fontFamily: '"Poppins"',
    },
    h5: {
      fontFamily: '"Poppins"',
    },
    h6: {
      fontFamily: '"Poppins"',
    },
  },
  shape: {
    borderRadius: 30,
  },
  overrides: {
    MuiDialog: {
      root: {
        zIndex: 1500,
      },
    },
    MuiCircularProgress: {
      colorSecondary: {
        stroke: "white",
      },
    },
    MuiPaper: {
      elevation1: {},
    },
    MuiIconButton: {
      root: {
        color: "#000",
      },
    },
    MuiOutlinedInput: {
      notchedOutline: { borderColor: "rgba(0, 0, 0, 0.23) " },
    },
    MuiButtonBase: {
      root: {
        [theme.breakpoints.down("sm")]: {
          "&:hover.MuiButton-contained": {
            background: "#345850",
          },
        },
      },
    },
    MuiCssBaseline: {
      "@global": {
        html: {
          height: "100%",
          width: "100%",
          WebkitFontSmoothing: "auto",
          background: "#151E27",
        },
      },
    },
  },
});

export const getSideBarNavColor = (theme: Theme) => {
  return theme.palette.type === "light" ? theme.palette.text.primaryBranded : theme.palette.background.paper2DP;
};

export const getSideBarTextColor = (theme: Theme) => {
  return theme.palette.type === "light" ? theme.palette.common.white : theme.palette.common.white;
};
