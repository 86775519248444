import {
  CANCEL_DELETE_GIFTEE, CLOSE_ADD_INTEREST_DIALOG, CLOSE_ADD_OCCASION_DIALOG,
  CLOSE_ADD_REMINDER_DIALOG, CLOSE_EDIT_GIFTEE_GIFT_PROFILE_INTERESTS_DIALOG,
  OPEN_ADD_INTEREST_DIALOG, OPEN_ADD_OCCASION_DIALOG, OPEN_ADD_REMINDER_DIALOG,
  OPEN_EDIT_GIFTEE_GIFT_PROFILE_INTERESTS_DIALOG
} from '../constants/action-types';

export const openAddOccasionDialog = () => {
  return { type: OPEN_ADD_OCCASION_DIALOG };
};
export const closeAddOccasionDialog = () => {
  return { type: CLOSE_ADD_OCCASION_DIALOG };
};
export const cancelDeleteGiftee = () => {
  return { type: CANCEL_DELETE_GIFTEE };
};
export const openAddReminderDialog = () => {
  return { type: OPEN_ADD_REMINDER_DIALOG };
};
export const closeAddReminderDialog = () => {
  return { type: CLOSE_ADD_REMINDER_DIALOG };
};
export const openAddInterestDialog = () => {
  return { type: OPEN_ADD_INTEREST_DIALOG };
};
export const closeAddInterestDialog = () => {
  return { type: CLOSE_ADD_INTEREST_DIALOG };
};
export const openEditGifteeGiftProfileInterestsDialog = () => {
  return { type: OPEN_EDIT_GIFTEE_GIFT_PROFILE_INTERESTS_DIALOG };
};
export const closeEditGifteeGiftProfileInterestsDialog = () => {
  return { type: CLOSE_EDIT_GIFTEE_GIFT_PROFILE_INTERESTS_DIALOG };
};
