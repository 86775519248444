/* TypeScript file generated from GiftIdeaFeedForGiftee.res by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as GiftIdeaFeedForGifteeBS__Es6Import from './GiftIdeaFeedForGiftee.bs';
const GiftIdeaFeedForGifteeBS: any = GiftIdeaFeedForGifteeBS__Es6Import;

import type {Json_t as Js_Json_t} from '../../../src/shims/Js.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Props = { readonly gifteeId?: Js_Json_t; readonly userId?: Js_Json_t };

export const make: React.ComponentType<{ readonly gifteeId?: Js_Json_t; readonly userId?: Js_Json_t }> = GiftIdeaFeedForGifteeBS.make;
