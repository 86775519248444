import React, { useState, FC } from "react";
// import { Meteor } from 'meteor/meteor';
import AnimatedCheckmark from "../general-ui/AnimatedCheckmark";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import PrimaryButton from "../general-ui/buttons/PrimaryButton";
// import { Invite } from '../../api/inviteFriend'
import { makeStyles, Theme } from "@material-ui/core/styles";
import { User } from "../../@types/user";
import client from "../../stitch";
import UserActionCard from "../general-ui/cards/UserActionCard";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button/Button";
import GlobalMessageDispatch from "../general-ui/global-messages/GlobalMessageDispatch";
import Grid from "@material-ui/core/Grid/Grid";
import Icon from "@material-ui/core/Icon";
import ShareButton from "../general-ui/buttons/ShareButton";
import { getInviteLink } from "../../utils/helpers/userDataHelpers";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    color: theme.palette.text.primary,
    width: "100%",
  },
  linkActionContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  text: {
    color: theme.palette.text.primary,
  },
  formInput: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "stretch",
    color: theme.palette.text.primary,
    marginBottom: 20,
    "& .MuiInputBase-root:before": {
      borderColor: "rgba(255,255,255,0.6)",
    },
    "& .MuiInputBase-root": {
      background: theme.palette.primary.transparent,
    },
    "& input": {
      color: theme.palette.text.primary,
    },
    "& label": {
      color: "rgba(255,255,255,0.6)",
    },
  },
  iconButton: {
    background: "none",
    padding: theme.spacing(0.5),
    border: "none",
    color: "rgba(255,255,255,0.8)",
  },
}));

type Props = {
  user: User;
  onComplete?: () => void;
};

const InviteFriendLinkShare: FC<Props> = ({ user, onComplete }) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const inviteLink = getInviteLink(user);
  const copyLink = () => {
    const el = document.createElement("textarea");
    el.value = inviteLink;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setLinkCopied(true);
    setTimeout(() => {
      onComplete?.();
    }, 2000);
  };

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <UserActionCard>
        <>
          <Typography variant="h6" className={classes.text}>
            Get Invite Link
          </Typography>
          <div className={classes.linkActionContainer}>
            <Typography variant="subtitle2" className={classes.text} gutterBottom>
              Copy your link below
            </Typography>
            <TextField
              className={classes.formInput}
              variant="outlined"
              type="text"
              label=""
              value={inviteLink}
              placeholder="dad@dadjokes.com"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <button className={classes.iconButton} onClick={copyLink}>
                      <span className="lnr lnr-copy"></span>
                    </button>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={classes.linkActionContainer}>
            <Typography variant="subtitle2" className={classes.text} gutterBottom>
              Share your link
            </Typography>
            <ShareButton url={inviteLink} text="Just for fun" title="Occasional.ly Invite" onShare={onComplete} />
          </div>
        </>
      </UserActionCard>
      {linkCopied && (
        <GlobalMessageDispatch>
          <Grid container direction="row" alignItems="center">
            <AnimatedCheckmark
              otherStyles={{ width: 40, height: 40 }}
              checked={true}
              icon={
                <Icon>
                  <span className="lnr lnr-copy"></span>
                </Icon>
              }
            />

            <Typography variant="body1" color="inherit">
              &nbsp;Link copied!
            </Typography>
          </Grid>
        </GlobalMessageDispatch>
      )}
    </div>
  );
};

export default InviteFriendLinkShare;
