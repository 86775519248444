import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import { useLazyQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { getOccasionsQuery } from '../../../graphql/queries/occasions';
import {
  getOccasions as getOccasionsQueryType, getOccasions_occasions as OccasionType,
  getOccasionsVariables
} from '../../../graphql/queries/occasions/__generated__/getOccasions';
import { selectOccasion, setOccasions } from '../../../redux/add-giftee/actions';
import { AddGifteeRootState } from '../../../redux/add-giftee/reducers';
import { RootState } from '../../../redux/store';
import SecondaryButton from '../../general-ui/buttons/SecondaryButton';
import GeneralAvatar from '../../general-ui/GeneralAvatar';
import Occasion from '../../occasions/Occasion';

const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.text.primary,
    height: "90vh",
    padding: 0,
    overflow: "hidden",
    overflowY: "scroll",
    background: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: {
      height: "100vh",
    },
  },
  gifteeInfo: {
    color: theme.palette.text.primary,
    zIndex: 100,
  },
  userName: {
    zIndex: 100,
    color: theme.palette.text.primary,
  },
  formHeader: {
    color: theme.palette.text.primary,
    position: "relative",
    zIndex: 10,
    padding: 16,
    "& h5": {
      color: theme.palette.text.primary,
      margin: "0px",
      fontSize: 18,
    },
  },
  list: {
    color: theme.palette.text.primary,
    position: "relative",
    overflowX: "hidden",
    overflowY: "scroll",
    width: "100%",
    padding: theme.spacing(2),
    "& ul": {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      listStyle: "none",
      paddingLeft: 0,
      paddingTop: 40,
      zIndex: 15,
    },
  },
  buttons: {
    padding: 16,
  },
}));

type Props = {} & Partial<StepWizardChildProps>;

const AddUserOccasions: FC<Props> = ({ goToStep }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const addGifteeState = useSelector<RootState, AddGifteeRootState>((state) => {
    return state.addGiftee;
  });
  const { occasions, userSelected, showImportGifteeWizard, occasionsSelected } = addGifteeState;

  const [getOccasions, { data, loading, called }] = useLazyQuery<getOccasionsQueryType, getOccasionsVariables>(
    getOccasionsQuery,
    {
      variables: {
        query: {
          userId: userSelected?._id,
        },
      },
    }
  );

  const occasionNamesSelected = useMemo(() => occasionsSelected.map((o) => o.name), [occasionsSelected]);

  useEffect(() => {
    if (showImportGifteeWizard) {
      getOccasions();
    }
  }, [showImportGifteeWizard]);

  useEffect(() => {
    if (!loading && data?.occasions && called) {
      const filteredOccasions = data.occasions.filter(Boolean) as OccasionType[];
      dispatch({
        ...setOccasions(filteredOccasions),
      });
    }
  }, [loading, called]);

  const selectOccasionHandler = (occasion: OccasionType) => {
    dispatch({
      ...selectOccasion(occasion),
    });
  };

  const confirmOccasions = () => {
    goToStep?.(2);
  };

  const renderOccasionsAll = () => {
    if (!occasions || loading) {
      return <CircularProgress />;
    } else if (occasions.length > 0) {
      return occasions.map((occasion) => (
        <Occasion
          occasionSelected={occasionNamesSelected.includes(occasion.name)}
          onSelect={selectOccasionHandler}
          key={occasion?._id}
          occasion={occasion as OccasionType}
          selectable={true}
        />
      ));
    } else {
      return (
        <Grid container direction="column" justifyContent="center" alignItems="center">
          This user has no occasions to add... odd.
        </Grid>
      );
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      wrap="nowrap"
      classes={{ container: "flex-1 " + classes.container }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        wrap="nowrap"
        classes={{ container: classes.gifteeInfo }}
      >
        <div className="cloudinary-photo-upload-container">
          {userSelected ? (
            <GeneralAvatar src={userSelected?.picture ?? ""}>{userSelected?.email?.charAt(0)}</GeneralAvatar>
          ) : (
            <GeneralAvatar>
              <i className="lnr lnr-gift"></i>
            </GeneralAvatar>
          )}
        </div>
      </Grid>
      <Grid container direction="column" justifyContent="flex-start" alignItems="center">
        <h2 className={classes.userName}>{userSelected?.name ? userSelected?.name : userSelected?.email}</h2>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        alignItems="center"
        classes={{ container: classes.formHeader }}
      >
        <h5>Select the occasions you want to add for this giftee</h5>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        classes={{ container: classes.list }}
      >
        <ul className="occasions">{renderOccasionsAll()}</ul>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        alignItems="center"
        classes={{ container: "flex-1 " + classes.buttons }}
      >
        <SecondaryButton onClick={confirmOccasions}>Confirm Selections</SecondaryButton>
      </Grid>
    </Grid>
  );
};

export default AddUserOccasions;
