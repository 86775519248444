import { makeStyles } from "@material-ui/core";
import Tab from "@material-ui/core/Tab/Tab";
import Tabs from "@material-ui/core/Tabs/Tabs";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { getSideBarNavColor, getSideBarTextColor } from "../../theme";

const useStyles = makeStyles((theme) => ({
  navTabs: {
    color: getSideBarTextColor(theme),
    width: "100%",
    alignSelf: "flex-end",
    overflow: "visible",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    "& .MuiTabs-indicator": {
      background: "none",
      width: "100%",
    },
    "& .MuiTabs-scroller": {
      overflow: "visible",
    },
  },
  navTab: {
    width: "100%",
    zIndex: 10,
    transition: theme.transitions.create("color"),
    "&.Mui-selected": {
      color: theme.palette.text.primaryBrandedFaint,
    },
  },
  tabBackground: {
    background: theme.palette.background.background,
    borderTopLeftRadius: 28,
    borderBottomLeftRadius: 28,
    width: "100%",
    height: "100%",
    position: "relative",
  },
  tabCornersWhite: {
    position: "absolute",
    right: 0,
    width: 30,
    height: 30,
    background: theme.palette.background.background,
    "&:nth-child(1)": {
      top: -30,
    },
    "&:nth-child(2)": {
      bottom: -30,
    },
  },
  tabCornersGreen: {
    position: "absolute",
    borderRadius: "50%",
    right: 0,
    width: 60,
    height: 60,
    background: getSideBarNavColor(theme),
    "&:nth-child(3)": {
      top: -60,
    },
    "&:nth-child(4)": {
      bottom: -60,
    },
  },
  gifteePhoto: {
    zIndex: 10,
    alignSelf: "center",
  },
  deleteButtonWrapper: {
    position: "absolute",
    bottom: 10,
  },
}));

type TabProps = {
  to?: string;
  label: string;
};

type Props = {
  tabs: ReadonlyArray<TabProps>;
  activeTab?: number;
};

const SidebarTabs: FC<Props> = ({ tabs, activeTab }) => {
  const classes = useStyles();

  const tabIndicatorChildren = (
    <div className={classes.tabBackground}>
      <span className={classes.tabCornersWhite}></span>
      <span className={classes.tabCornersWhite}></span>
      <span className={classes.tabCornersGreen}></span>
      <span className={classes.tabCornersGreen}></span>
    </div>
  );

  return (
    <Tabs
      orientation="vertical"
      value={activeTab ?? 0}
      aria-label="Tabs for navigation"
      variant="scrollable"
      className={classes.navTabs}
      TabIndicatorProps={{ children: tabIndicatorChildren }}
      scrollButtons="off"
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.to ?? tab.label}
          disableRipple
          className={classes.navTab}
          component={Link}
          to={tab.to ?? ""}
          label={tab.label}
        />
      ))}
    </Tabs>
  );
};

export default SidebarTabs;
