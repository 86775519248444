import { Occasion } from '../../../@types/occasions';
import { GiftIdea } from '../../../components/gifts/types';
import {
  ADD_GIFT_TYPE, ADD_INVITE, ADD_OCCASIONS_ONBOARDING, Gender, REMOVE_GIFT_TYPE, REMOVE_INVITE,
  REMOVE_ONBOARDING_OCCASION, SELECT_GIFT_IDEA, SET_BIRTHDAY, SET_GENDER, SET_GIFT_PROFILE,
  SET_LOADING, SET_NAME, SET_ONBOARDING_LOADING, SET_ONBOARDING_OCCASIONS, SET_PHONE
} from '../constants/action-types';
import { GeneralAction } from '../types';

export type OnboardingInvite = {
  value: string;
  type: "email" | "sms";
};

export type OnboardingRootState = {
  userName: string;
  phone: string;
  giftTypes: string[];
  occasions: Partial<Occasion>[];
  birthday: string | null;
  gender: Gender | null;
  selectedGiftIdeas: Partial<GiftIdea>[];
  giftProfileSet: boolean;
  invites: OnboardingInvite[];
  loading: boolean;
};

const initialState = {
  userName: "",
  phone: "",
  giftTypes: [],
  occasions: [],
  birthday: null,
  gender: null,
  selectedGiftIdeas: [],
  giftProfileSet: false,
  invites: [],
  loading: false,
};

const reducer = (state: OnboardingRootState = initialState, action: GeneralAction) => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case ADD_GIFT_TYPE:
      return { ...state, giftTypes: [...new Set([...state.giftTypes, action.payload.giftType])] };
    case REMOVE_GIFT_TYPE:
      const indexOfGiftType = state.giftTypes.findIndex((giftType) => giftType === action.payload.giftType);
      const newGiftTypes = [...state.giftTypes];
      newGiftTypes.splice(indexOfGiftType, 1);
      return { ...state, giftTypes: newGiftTypes };
    case ADD_OCCASIONS_ONBOARDING:
      return { ...state, occasions: [...state.occasions, ...action.payload.occasions] };
    case REMOVE_ONBOARDING_OCCASION: {
      const indexOfOccasion = state.occasions.findIndex((occasion) => occasion._id === action.payload.occasion._id);
      const newOccasions = [...state.occasions];
      newOccasions.splice(indexOfOccasion, 1);
      return { ...state, occasions: newOccasions };
    }
    case SET_ONBOARDING_OCCASIONS:
      return { ...state, occasions: action.payload.occasions };
    case SET_NAME:
      return { ...state, userName: action.payload };
    case SET_PHONE:
      return { ...state, phone: action.payload };
    case SET_BIRTHDAY:
      return { ...state, birthday: action.payload.birthday };
    case SET_GENDER:
      return { ...state, gender: action.payload };
    case SELECT_GIFT_IDEA:
      return { ...state, selectedGiftIdeas: [...state.selectedGiftIdeas, action.payload] };
    case SET_GIFT_PROFILE:
      return { ...state, giftProfileSet: true };
    case ADD_INVITE:
      return { ...state, invites: [...state.invites, action.payload] };
    case REMOVE_INVITE:
      const indexOfInvite = state.invites.findIndex((invite) => invite.value === action.payload);
      const newInvites = [...state.invites];
      newInvites.splice(indexOfInvite, 1);
      return { ...state, invites: newInvites };
    case SET_ONBOARDING_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default reducer;
