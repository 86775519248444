import { ApolloCache } from "@apollo/client";
import { getRemindersQuery } from "../../queries/reminders";
import { getReminders, getReminders_reminders } from "../../queries/reminders/__generated__/getReminders";
import { UpdateOneReminderMutation } from "../../mutations/reminders/__generated__/UpdateOneReminderMutation";
import { FetchResult } from "@apollo/client/link/core";
import { Dayjs } from "dayjs";

export const updateRemindersInCache = <T, V>(
  cache: ApolloCache<T>,
  variables: V,
  result: FetchResult<UpdateOneReminderMutation>,
  date: Dayjs | null
) => {
  try {
    const data = cache.readQuery<getReminders>({ query: getRemindersQuery, variables });
    const reminders = [...(data?.reminders ?? [])];
    const updatedReminderIndex = reminders.findIndex((r) => r?._id === result?.data?.updateOneReminder?._id);
    if (updatedReminderIndex !== -1 && date) {
      const newReminder = { ...reminders[updatedReminderIndex], date: date.calendar() } as getReminders_reminders;
      reminders.splice(updatedReminderIndex, 1, newReminder);
    }
    cache.writeQuery({
      query: getRemindersQuery,
      variables,
      data: reminders,
    });
  } catch (e) {
    console.error(e);
    //no-op
  }
};

export const insertRemindersInCache = <T, V>(cache: ApolloCache<T>, variables: V, reminder: getReminders_reminders) => {
  try {
    const data = cache.readQuery<getReminders>({ query: getRemindersQuery, variables });
    const reminders = [...(data?.reminders ?? []), reminder];
    cache.writeQuery({
      query: getRemindersQuery,
      variables,
      data: reminders,
    });
  } catch (e) {
    console.error(e);
    //no-op
  }
};
