import { Checkbox, Link, Typography } from "@material-ui/core";
import makeStyles from "@material-ui/styles/makeStyles";
import React, { FC, useState } from "react";
import CookiePolicyDialog from "./CookiePolicyDialog";
import PrivacyPolicyDialog from "./PrivacyPolicyDialog";
import TermsAndConditionsDialog from "./TermsAndConditionsDialog";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: 11,
    padding: 2,
    "& .MuiLink-root": {
      fontSize: 11,
    },
  },
}));

type Props = {
  handlePolicyAcceptChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

const AcceptTermsCheckbox: FC<Props> = ({ handlePolicyAcceptChange }) => {
  const classes = useStyles();
  const [acceptPolicies, setAcceptPolicies] = useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [openCookiesPolicy, setOpenCookiesPolicy] = useState(false);
  const [openTermsAndConditions, setOpenTermsAndConditions] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setAcceptPolicies(checked);
    handlePolicyAcceptChange(event, checked);
  };

  const handlePrivacyPolicyClick = () => {
    setOpenPrivacyPolicy(true);
  };
  const handleCookiesPolicyClick = () => {
    setOpenCookiesPolicy(true);
  };
  const handleTermsAndConditionsClick = () => {
    setOpenTermsAndConditions(true);
  };

  return (
    <div className={classes.container}>
      <Checkbox checked={acceptPolicies} onChange={handleChange} />
      <Typography variant="caption" className={classes.text}>
        Accept{" "}
        <Link color="secondary" component="button" onClick={handlePrivacyPolicyClick}>
          Privacy Policy
        </Link>{" "}
        ,{" "}
        <Link color="secondary" component="button" onClick={handleCookiesPolicyClick}>
          Cookies Policy
        </Link>{" "}
        and{" "}
        <Link color="secondary" component="button" onClick={handleTermsAndConditionsClick}>
          Terms and Conditions
        </Link>
      </Typography>
      <PrivacyPolicyDialog open={openPrivacyPolicy} onClose={() => setOpenPrivacyPolicy(false)} />
      <CookiePolicyDialog open={openCookiesPolicy} onClose={() => setOpenCookiesPolicy(false)} />
      <TermsAndConditionsDialog open={openTermsAndConditions} onClose={() => setOpenTermsAndConditions(false)} />
    </div>
  );
};

export default AcceptTermsCheckbox;
