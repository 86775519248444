import React, { FC } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuthContext } from "../../auth/authContext";

type Props = {} & RouteProps;

const PrivateRoute: FC<Props> = ({ children, ...rest }) => {
  const { authState } = useAuthContext();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authState.isAuthenticated ? (
          authState.user.customData.finishedOnboarding ? (
            children
          ) : location.pathname.includes("welcome") ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/welcome",
                state: { from: location },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
