import { OccasionDayMonthDateInsertInput, OccasionType } from "../__generated__/globalTypes";
import { HDate } from "@hebcal/core";

const getNextOccasionDate = (occasionType: OccasionType, dayMonthDate?: OccasionDayMonthDateInsertInput) => {
  switch (occasionType) {
    case OccasionType.BIRTHDAY:
    case OccasionType.WEDDING:
    case OccasionType.ANNIVERSARY:
    case OccasionType.CUSTOM:
      if (dayMonthDate) {
        return getRegularOccasionDate(dayMonthDate);
      } else {
        return undefined;
      }

    case OccasionType.CHRISTMAS:
      return getNextChristmas();

    case OccasionType.VALENTINES:
      return getNextValentines();

    case OccasionType.KWANZAA:
      return getNextKwanzaa();

    case OccasionType.MOTHERSDAY:
      return getNextMothersDay();

    case OccasionType.FATHERSDAY:
      return getNextFathersDay();

    case OccasionType.HANUKKAH:
      return getNextHannukah();

    case OccasionType.EASTER:
      return getNextEaster();

    default:
      if (dayMonthDate) {
        return getRegularOccasionDate(dayMonthDate);
      } else {
        return undefined;
      }
  }
};

const getRegularOccasionDate = (dayMonthDate: OccasionDayMonthDateInsertInput) => {
  const today = new Date();
  let date;
  //take the date of the occasion in this current year
  const currentYearOccasion = new Date(today.getFullYear(), dayMonthDate?.month ?? 0, dayMonthDate?.day ?? 0);
  //find out if it is before or after today

  //if it is after today, just use that date
  if (today < currentYearOccasion) {
    date = currentYearOccasion;
  }
  //if it is after today, increment year by one
  else {
    currentYearOccasion.setFullYear(currentYearOccasion.getFullYear() + 1);
    date = currentYearOccasion;
  }

  return date;
};

/**
 *Get the next coming Christams
 */
const getNextChristmas = () => {
  const today = new Date();
  let date;
  //take the date of the occasion in this current year
  const christmas = new Date(today.getFullYear(), 11, 25);

  //find out if it is before or after today

  //if it is after today, just use that dates
  if (today < christmas) {
    date = christmas;
  }
  //if it is after today, increment year by one
  else {
    christmas.setFullYear(christmas.getFullYear() + 1);
    date = christmas;
  }
  return date;
};

/**
 *Get the next coming Valentine's
 */
const getNextValentines = () => {
  const today = new Date();
  let date;
  //take the date of the occasion in this current year
  const valentines = new Date(today.getFullYear(), 1, 14);

  //find out if it is before or after today

  //if it is after today, just use that dates
  if (today < valentines) {
    date = valentines;
  }
  //if it is after today, increment year by one
  else {
    valentines.setFullYear(valentines.getFullYear() + 1);
    date = valentines;
  }
  return date;
};

/**
 * Get the next coming Kwanzaa
 */

const getNextKwanzaa = () => {
  const today = new Date();
  let date;
  //take the date of the occasion in this current year
  const kwanzaa = new Date(today.getFullYear(), 11, 26);
  //find out if it is before or after today

  //if it is after today, just use that dates
  if (today < kwanzaa) {
    date = kwanzaa;
  }
  //if it is after today, increment year by one
  else {
    kwanzaa.setFullYear(kwanzaa.getFullYear() + 1);
    date = kwanzaa;
  }
  return date;
};

const getNextMothersDay = () => {
  const today = new Date();
  let date;
  //take the date of the mothers day
  const mayFirst = new Date(new Date().getFullYear(), 4, 1);
  const dayOfWeek = mayFirst.getUTCDay();

  let firstSunday;

  if (dayOfWeek === 0) {
    firstSunday = mayFirst;
  } else {
    firstSunday = mayFirst;
    firstSunday.setDate(1 + (7 - dayOfWeek));
  }

  let mothersDay = new Date(firstSunday);
  mothersDay.setDate(firstSunday.getUTCDate() + 7);
  mothersDay = new Date(mothersDay);
  //find out if it is before or after today

  //if it is after today, just use that dates
  if (today < mothersDay) {
    date = mothersDay;
  }
  //if it is after today, calculate for next year
  else {
    const mayFirst = new Date(new Date().getFullYear() + 1, 4, 1);
    const dayOfWeek = mayFirst.getUTCDay();

    let firstSunday;

    if (dayOfWeek === 0) {
      firstSunday = mayFirst;
    } else {
      firstSunday = mayFirst;
      firstSunday.setDate(1 + (7 - dayOfWeek));
    }

    let mothersDay = new Date(firstSunday);
    mothersDay.setDate(firstSunday.getUTCDate() + 7);
    mothersDay = new Date(mothersDay);
    date = mothersDay;
  }

  return date;
};

const getNextFathersDay = () => {
  const today = new Date();
  let date;
  //take the date of the mothers day
  const juneFirst = new Date(new Date().getFullYear(), 5, 1);
  const dayOfWeek = juneFirst.getUTCDay();

  let firstSunday;

  if (dayOfWeek === 0) {
    //if day of week is 0, then we already know that this day is the first sunday, so we assign it to June first
    firstSunday = juneFirst;
  } else {
    firstSunday = juneFirst;
    firstSunday.setDate(1 + (7 - dayOfWeek));
  }

  //we set it as two weeks from the first sunday so it is the 3rd sunday
  let fathersDay = new Date(firstSunday);
  fathersDay.setDate(firstSunday.getUTCDate() + 14);
  fathersDay = new Date(fathersDay);
  //find out if it is before or after today

  //if it is after today, just use that dates
  if (today < fathersDay) {
    date = fathersDay;
  }
  //if it is after today, increment year by one
  else {
    const juneFirst = new Date(new Date().getFullYear() + 1, 5, 1);
    const dayOfWeek = juneFirst.getUTCDay();

    let firstSunday;

    if (dayOfWeek === 0) {
      //if day of week is 0, then we already know that this day is the first sunday, so we assign it to June first
      firstSunday = juneFirst;
    } else {
      firstSunday = juneFirst;
      firstSunday.setDate(1 + (7 - dayOfWeek));
    }

    //we set it as two weeks from the first sunday so it is the 3rd sunday
    let fathersDay = new Date(firstSunday);
    fathersDay.setDate(firstSunday.getUTCDate() + 14);
    fathersDay = new Date(fathersDay);
    date = fathersDay;
  }
  return date;
};

const getNextHannukah = () => {
  const today = new HDate();
  const hannukah = new HDate(25, 9, today.getFullYear());
  if (hannukah.greg() < new Date()) {
    return new HDate(25, 9, today.getFullYear() + 1).greg();
  }
  return hannukah.greg();
};

const getNextEaster = () => {
  const year = new Date().getFullYear() + 1;
  const c = Math.floor(year / 100);
  const n = year - 19 * Math.floor(year / 19);
  const K = Math.floor((c - 17) / 25);
  let i = c - Math.floor(c / 4) - Math.floor((c - K) / 3) + 19 * n + 15;
  i = i - 30 * Math.floor(i / 30);
  i = i - Math.floor(i / 28) * (1 - Math.floor(i / 28) * Math.floor(29 / (i + 1)) * Math.floor((21 - n) / 11));
  let J = year + Math.floor(year / 4) + i + 2 - c + Math.floor(c / 4);
  J = J - 7 * Math.floor(J / 7);
  const l = i - J;
  const month = 3 + Math.floor((l + 40) / 44);
  const date = l + 28 - 31 * Math.floor(month / 4);
  return new Date(year, month - 1, date);
};

export default getNextOccasionDate;
