import React from "react";

import IconButton from "@material-ui/core/IconButton";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme: Theme) => ({
  deleteMain: {
    color: theme.palette.error.main,
  },
  deleteLight: {
    color: theme.palette.error.light,
  },
}));

type Props = {
  variant?: string;
  onClick: () => void;
};

const DeleteButton: React.FC<Props> = ({ onClick, variant }) => {
  const classes = useStyles();
  const deleteClass = variant === "light" ? classes.deleteLight : classes.deleteMain;

  return (
    <Tooltip title="Delete">
      <IconButton onClick={onClick}>
        <span className={deleteClass + " lnr lnr-trash2"}></span>
      </IconButton>
    </Tooltip>
  );
};

export default DeleteButton;
