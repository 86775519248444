import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Icon, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SpeedDial, SpeedDialAction } from '@material-ui/lab';

import useAnalyticsEvent from '../../hooks/analytics/useAnalyticsEvent';
import useIsMobile from '../../hooks/useIsMobile';
import {
  closeGiftIdeaDialog, openMarkGiftPurchasedDialog, openPurchaseQuestionPrompt
} from '../../redux/giftideas/actions';
import { GiftIdeaRootState } from '../../redux/giftideas/reducers';
import { RootState } from '../../redux/store';
import PrimaryLink from '../general-ui/buttons/PrimaryLink';
import TertiaryButton from '../general-ui/buttons/TertiaryButton';

const useStyles = makeStyles((theme) => ({
  productActions: {
    padding: theme.spacing(2),
    background: theme.palette.background.paper2DP,
    height: 85,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    position: "absolute",
    zIndex: 100,
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      width: "100vw",
      alignItems: "flex-end",
    },
  },
  price: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  cta: {
    flex: 4,
    width: "100%",
  },
  fab: {
    color: theme.palette.background.background,
    background: theme.palette.text.primary,
    "&:hover": {
      background: theme.palette.text.faint,
    },
  },
  speedActions: {
    background: theme.palette.text.primary,
    color: theme.palette.background.background,
  },
  speedDial: {
    flex: 1,
    marginRight: theme.spacing(2),
  },
  openStoreLink: {
    [theme.breakpoints.down("sm")]: {
      height: 56,
    },
  },
  tooltip: {
    borderRadius: 8,
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
}));

type Props = { viewOnly: boolean };

const GiftIdeaDetailCtas: FC<Props> = ({ viewOnly = false }) => {
  const classes = useStyles();
  const { gifteeId } = useParams<{ gifteeId: string }>();
  const fireGoogleAnalytics = useAnalyticsEvent("gift-ideas", "click", "View Product on 3rd Party Site");
  const dispatch = useDispatch();
  const giftIdeasState = useSelector<RootState, GiftIdeaRootState>((state) => {
    return state.giftIdeas;
  });
  const [openOptions, setOpenOptions] = useState(false);
  const handleOpenOptions = () => {
    setOpenOptions(true);
  };
  const handleCloseOptions = () => {
    setOpenOptions(false);
  };

  const handleMarkPurchasedClick = () => {
    dispatch({ ...openMarkGiftPurchasedDialog() });
    dispatch({ ...closeGiftIdeaDialog() });
  };

  const handleViewProduct = () => {
    fireGoogleAnalytics();
    dispatch({ ...openPurchaseQuestionPrompt() });
  };

  const { activeGiftIdea } = giftIdeasState;
  const isMobile = useIsMobile();
  //FIXME: using gifteeId param is hacky for hiding this button, need permanent solution
  const showMarkPurchaseButton = !viewOnly && Boolean(gifteeId);
  return (
    <div className={classes.productActions}>
      {isMobile && (
        <div className={classes.price}>
          <Typography variant="caption">price:</Typography>
          <Typography variant="h6">${activeGiftIdea?.price}</Typography>
        </div>
      )}
      {isMobile ? (
        <>
          {showMarkPurchaseButton && (
            <SpeedDial
              ariaLabel="Gift Idea Options"
              classes={{ root: classes.speedDial, fab: classes.fab }}
              icon={
                <Icon>
                  <span className="lnr lnr-ellipsis"></span>
                </Icon>
              }
              onClose={handleCloseOptions}
              onOpen={handleOpenOptions}
              open={openOptions}
              FabProps={{ color: "inherit" }}
            >
              <SpeedDialAction
                classes={{ fab: classes.speedActions, staticTooltipLabel: classes.tooltip }}
                key="Mark Gift as Purchased"
                icon={
                  <Icon>
                    <span className="lnr lnr-receipt"></span>
                  </Icon>
                }
                tooltipOpen={true}
                tooltipPlacement="right"
                tooltipTitle="Mark Purchased"
                onClick={handleMarkPurchasedClick}
              />
            </SpeedDial>
          )}
        </>
      ) : (
        <div className={classes.cta}>
          <TertiaryButton onClick={handleMarkPurchasedClick}>
            Mark Purchased{"  "}
            <Icon className={classes.icon}>
              <span className="lnr lnr-receipt"></span>
            </Icon>
          </TertiaryButton>
        </div>
      )}
      <div className={classes.cta}>
        <PrimaryLink
          onClick={handleViewProduct}
          href={activeGiftIdea?.url ?? ""}
          target="_blank"
          rel="noopener"
          component="a"
          className={classes.openStoreLink}
        >
          View Product
          <Icon className={classes.icon}>
            <span className="lnr lnr-cart-full"></span>
          </Icon>
        </PrimaryLink>
      </div>
    </div>
  );
};

export default GiftIdeaDetailCtas;
