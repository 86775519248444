import React, { FC, useEffect, useMemo, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';

import { GiftIdeaRootState } from '../../redux/giftideas/reducers';
import { RootState } from '../../redux/store';
import GifteeMatchChip from './GifteeMatchChip';
import RetailerLogo from './RetailerLogo';
import { GiftIdea } from './types';
import { formatSimilarity, getMatchGradient } from './utils';

const useStyles = makeStyles((theme: Theme) => ({
  giftIdeaDetailHeader: {
    display: "flex",
    width: "100%",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
      flexDirection: "column",
    },
  },
  giftImageContainer: {
    marginTop: theme.spacing(1),
    borderRadius: 20,
    position: "relative",
    overflow: "hidden",
    zIndex: 10,
    flex: 2,
    width: "60%",
    maxHeight: 300,
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "40vh",
      flex: "inherit",
      width: "100%",
      height: "calc(50vh - 52px)",
    },
  },
  giftImage: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    background: "white",
  },
  associatedGifts: {
    position: "absolute",
    top: 10,
    right: 0,
    width: 70,
    borderRadius: 8,
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: "calc(100% - 20px)",
    overflowY: "scroll",
  },
  associatedGiftButton: {
    marginBottom: theme.spacing(0.5),
    width: 50,
    height: 50,
    border: "none",
    background: "none",
    borderRadius: 8,
    boxShadow: theme.shadows[2],
    cursor: "pointer",
    padding: 0,
  },
  associatedGiftImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 8,
  },
  giftMatchAndInfo: {
    marginTop: theme.spacing(1),
    display: "flex",
    width: "40%",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      flex: "inherit",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  matches: {
    maxHeight: 100,
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flex: "inherit",
      width: "100%",
      alignItems: "center",
      justifyContent: "flex-start",
      overflowY: "hidden",
      overflowX: "scroll",
    },
  },
  giftInfo: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      flex: "inherit",
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: theme.spacing(1),
    },
  },
  giftTitle: {
    fontSize: 18,
    textAlign: "right",
    zIndex: 10,
    whiteSpace: "initial",
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      textAlign: "left",
    },
  },
  giftLogoWrapper: {
    marginLeft: theme.spacing(1),
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: 55,
    },
    padding: theme.spacing(0.5),
    borderRadius: 8,
    overflow: "hidden",
    background: theme.palette.common.white,
  },
  giftPriceAndLogo: {
    fontSize: 18,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "30%",
      flexDirection: "row",
    },
  },
  gifteeMatchChip: {
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(0.5),
    },
  },
}));

type Props = {
  giftIdea: GiftIdea | undefined;
  showPrice?: boolean;
};

const GiftIdeaDetailHeader: FC<Props> = ({ giftIdea, showPrice = true }) => {
  const classes = useStyles();

  const [activeGiftImage, setActiveGiftImage] = useState("");
  const associatedProducts = giftIdea?.associatedProducts ?? [];
  const renderedAssociatedProducts = [
    ...associatedProducts,
    { image: giftIdea?.image ?? "", title: giftIdea?.title, url: giftIdea?.url },
  ];
  //for when giftidea changes, need to set new state image
  useEffect(() => {
    if (giftIdea?.image) {
      setActiveGiftImage(giftIdea?.image);
    }
  }, [giftIdea?.image]);

  const isMainImageVideo = useMemo(
    () => activeGiftImage.includes(".mp4") || activeGiftImage.includes(".mov"),
    [activeGiftImage]
  );

  return (
    <div className={classes.giftIdeaDetailHeader}>
      <div className={classes.giftImageContainer}>
        {renderedAssociatedProducts.length > 1 ? (
          <div className={classes.associatedGifts}>
            {renderedAssociatedProducts.map((p) => {
              const productImage = p?.image ?? "";
              const isVideo = p?.image.includes(".mp4") || p?.image.includes(".mov");
              return (
                <button onClick={() => setActiveGiftImage(productImage)} className={classes.associatedGiftButton}>
                  {isVideo ? (
                    <video src={productImage} className={classes.associatedGiftImage} />
                  ) : (
                    <img className={classes.associatedGiftImage} src={productImage} />
                  )}
                </button>
              );
            })}
          </div>
        ) : null}
        {isMainImageVideo ? (
          <video src={activeGiftImage} autoPlay className={classes.giftImage} />
        ) : (
          <img src={activeGiftImage} className={classes.giftImage} />
        )}
      </div>
      <div className={classes.giftMatchAndInfo}>
        <div className={classes.matches}>
          {/* {activeGiftIdeaGifteeMatches?.map((g) => (
            <div className={classes.gifteeMatchChip}>
              <GifteeMatchChip
                gifteeName={g.name ?? ""}
                gifteePicture={g.picture ?? undefined}
                similarity={g.similarity}
                matchGradient={getMatchGradient(g.similarity)}
                similarityFormatted={formatSimilarity(g.similarity)}
              />
            </div>
          ))} */}
        </div>
        <div className={classes.giftInfo}>
          <Typography variant="h6" noWrap className={classes.giftTitle}>
            {giftIdea?.title}
          </Typography>
          <Typography variant="body1" noWrap className={classes.giftPriceAndLogo}>
            {showPrice && `$${giftIdea?.price}`}
            <div className={classes.giftLogoWrapper}>
              <RetailerLogo provider={giftIdea?.giftProvider} />
            </div>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default GiftIdeaDetailHeader;
