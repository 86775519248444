import { makeStyles } from "@material-ui/core";
import React, { FC, useEffect } from "react";
import { useLocation } from "react-router";

const getPageTitleFromPathname = (search: string): string => {
  const map: Record<string, string> = {
    "/login": "Login & Signup",
    "/login/": "Login & Signup",
    "/handle-redirect": "3rd Party Authentication",
    "/forgot-password": "Forgot Password",
    "/reset-password": "Forgot Password",
    "/home/giftees": "Giftees",
    "/home/occasions": "Occasions",
    "/home/gift-ideas": "Gift Ideas",
    "/welcome": "Welcome",
    "/giftee/": "Giftee",
  };

  const keys = Object.keys(map);
  const key = keys.find((k, i) => search.includes(k)) ?? "";

  return map[key] ?? map[search];
};

const useStyles = makeStyles((theme) => ({
  "@global": {
    "&html": {
      background: theme.palette.background.background,
    },
  },
  app: {
    background: theme.palette.background.background,
  },
}));

const RoutesContainer: FC = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  useEffect(() => {
    const pageTitle = getPageTitleFromPathname(location.pathname) ?? "";
    document.title = `Occasional.ly | ${pageTitle}`;
  }, [location.pathname]);
  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
    });
  }, []);

  return <div className={classes.app}>{children}</div>;
};

export default RoutesContainer;
