import {
  ADD_GIFT_TYPE,
  REMOVE_GIFT_TYPE,
  ADD_OCCASIONS_ONBOARDING,
  REMOVE_ONBOARDING_OCCASION,
  SET_ONBOARDING_OCCASIONS,
  DESELECT_GIFT_IDEA,
  SET_BIRTHDAY,
  SET_GIFT_PROFILE,
  ADD_INVITE,
  REMOVE_INVITE,
  SET_GENDER,
  Gender,
  SET_NAME,
  SET_PHONE,
  SET_LOADING,
} from "../constants/action-types";
import { Occasion } from "../../../@types/occasions";
import { SELECT_GIFT_IDEA } from "../../giftideas/constants/action-types";
import { GiftIdea } from "../../../components/gifts/types";
import { OnboardingInvite } from "../reducers";

export const addGiftType = (giftType: string) => {
  return { type: ADD_GIFT_TYPE, payload: { giftType } };
};

export const setLoading = (payload: boolean) => {
  return { type: SET_LOADING, payload };
};

export const removeGiftType = (giftType: string) => {
  return { type: REMOVE_GIFT_TYPE, payload: { giftType } };
};

export const addOccasions = (occasions: Partial<Occasion>[]) => {
  return { type: ADD_OCCASIONS_ONBOARDING, payload: { occasions } };
};

export const removeOccasion = (occasion: Partial<Occasion>) => {
  return { type: REMOVE_ONBOARDING_OCCASION, payload: { occasion } };
};

export const setOccasions = (occasions: Partial<Occasion>[]) => {
  return { type: SET_ONBOARDING_OCCASIONS, payload: { occasions } };
};

export const setName = (name: string) => {
  return { type: SET_NAME, payload: name };
};
export const setPhone = (phone: string) => {
  return { type: SET_PHONE, payload: phone };
};
export const setBirthday = (birthday: Date | null) => {
  return { type: SET_BIRTHDAY, payload: { birthday: birthday?.toLocaleDateString() ?? null } };
};
export const setGender = (gender: Gender) => {
  return { type: SET_GENDER, payload: gender };
};

export const selectGiftIdea = (giftIdea: Partial<GiftIdea>) => {
  return { type: SELECT_GIFT_IDEA, payload: giftIdea };
};
export const deselectGiftIdea = (giftIdea: Partial<GiftIdea>) => {
  return { type: DESELECT_GIFT_IDEA, payload: giftIdea };
};
export const setGiftProfile = () => {
  return { type: SET_GIFT_PROFILE };
};
export const addInvite = (invite: OnboardingInvite) => {
  return { type: ADD_INVITE, payload: invite };
};
export const removeInvite = (invite: OnboardingInvite) => {
  return { type: REMOVE_INVITE, payload: invite };
};
