import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import Badge from '@material-ui/core/Badge/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { AuthActionTypes } from '../../@types/auth';
import { useAuthContext } from '../../auth/authContext';
import { getGiftProfileQuery } from '../../graphql/queries/gift-profiles';
import {
  getGiftProfile, getGiftProfileVariables
} from '../../graphql/queries/gift-profiles/__generated__/getGiftProfile';
import { logout } from '../../realm/auth';
import { openUserMenu, resetGeneralState } from '../../redux/general/actions';
import DarkModeToggle from './DarkModeToggle';
import InstallButton from './InstallButton';

const useStyles = makeStyles((theme: Theme) => ({
  listWrapper: {
    paddingTop: 30,
  },
  listContainer: {
    color: theme.palette.text.primary,
    minHeight: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiListItemIcon-root": {
      color: theme.palette.text.primary,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  settingsBadge: {
    right: 15,
    minWidth: 10,
    maxWidth: 10,
    height: 10,
    background: theme.palette.error.dark,
  },
  inviteIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 48,
    "& span": {
      color: theme.palette.secondary.main,
    },
    color: theme.palette.secondary.main,
    "& .MuiListItemIcon-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  specialItem: {
    color: theme.palette.info.main,
    "& .MuiListItemIcon-root": {
      color: theme.palette.info.main,
    },
  },
}));

type Props = RouteComponentProps & {
  nextStep?: () => void;
  goToStep?: (step: number) => void;
  showMenu?: boolean;
};

const SettingsAndProfile: FC<Props> = ({ goToStep, history, showMenu }) => {
  const { authDispatch, authState } = useAuthContext();
  const { data, loading } = useQuery<getGiftProfile, getGiftProfileVariables>(getGiftProfileQuery, {
    variables: {
      query: {
        userId: authState.user.customData._id,
      },
    },
    fetchPolicy: "network-only",
  });
  const missingGiftProfile = (data?.giftProfile?.recommendationsByCategory?.length ?? 0) === 0 && !loading;
  const dispatch = useDispatch();

  const moveToStep = (step: number) => {
    if (goToStep) goToStep(step);
  };

  const openMenu = () => {
    dispatch({ ...openUserMenu() });
  };

  const logoutHandler = async () => {
    await logout();
    authDispatch({
      type: AuthActionTypes.LOGOUT_USER,
    });
    dispatch({ ...resetGeneralState() });
    history.push("/login");
  };

  const classes = useStyles();

  const missingInfo = (authState.user.customData.phone?.trim() ?? "") === "" || !authState.user.customData.address;

  return (
    <List className={classes.listWrapper}>
      <ListItem className={classes.listContainer} button onClick={() => (showMenu ? moveToStep(3) : openMenu())}>
        <Badge classes={{ badge: classes.settingsBadge }} badgeContent={missingInfo ? " " : null} color="primary">
          <ListItemIcon>
            <span className="lnr lnr-user"></span>
          </ListItemIcon>
        </Badge>
        {showMenu && <ListItemText>Account Info</ListItemText>}
      </ListItem>
      <ListItem className={classes.listContainer} button onClick={() => (showMenu ? moveToStep(2) : openMenu())}>
        <Badge
          classes={{ badge: classes.settingsBadge }}
          badgeContent={missingGiftProfile ? " " : null}
          color="primary"
        >
          <ListItemIcon>
            <span className="lnr lnr-gift"></span>
          </ListItemIcon>
        </Badge>
        {showMenu && <ListItemText>Gift Profile</ListItemText>}
      </ListItem>
      <InstallButton showMenu={showMenu} />
      <ListItem className={classes.listContainer} button onClick={() => (showMenu ? moveToStep(4) : openMenu())}>
        <ListItemIcon>
          <span className="lnr lnr-calendar-full"></span>
        </ListItemIcon>
        {showMenu && <ListItemText>My Occasions</ListItemText>}
      </ListItem>
      <ListItem className={classes.inviteIcon} button onClick={() => (showMenu ? moveToStep(5) : openMenu())}>
        <ListItemIcon>
          <span className="lnr lnr-paper-plane"></span>
        </ListItemIcon>
        {showMenu && <ListItemText>Invite Friends & Family</ListItemText>}
      </ListItem>
      <ListItem button onClick={() => (showMenu ? null : openMenu())} className={classes.listContainer}>
        <ListItemIcon>
          <span className="lnr lnr-moon"></span>
        </ListItemIcon>
        {showMenu && (
          <>
            <ListItemText>Dark mode</ListItemText> <DarkModeToggle />
          </>
        )}
      </ListItem>
      <ListItem className={classes.listContainer} button onClick={logoutHandler}>
        <ListItemIcon>
          <span className="lnr lnr-exit"></span>
        </ListItemIcon>
        {showMenu && <ListItemText>Log out</ListItemText>}
      </ListItem>
    </List>
  );
};

export default withRouter(SettingsAndProfile);
