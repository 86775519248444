import { ApolloCache } from "@apollo/client";
import { FetchResult } from "@apollo/client/link/core";
import { DeleteOneNotificationMutation } from "../../mutations/notifications/__generated__/DeleteOneNotificationMutation";
import { getNotificationsQuery } from "../../queries/notifications";
import {
  getNotifications,
  getNotifications_notifications,
} from "../../queries/notifications/__generated__/getNotifications";
import { UpdateOneNotificationMutation } from "../../mutations/notifications/__generated__/UpdateOneNotificationMutation";

export const deleteNotificationFromCache = <T, V>(
  cache: ApolloCache<T>,
  variables: V,
  result: FetchResult<DeleteOneNotificationMutation>
) => {
  try {
    const data = cache.readQuery<getNotifications>({ query: getNotificationsQuery, variables });
    const notifications = [...(data?.notifications ?? [])];
    const deletedNotificationIndex = notifications.findIndex(
      (r) => r?._id === result?.data?.deleteOneNotification?._id
    );
    const newNotifications = [...notifications];
    newNotifications.splice(deletedNotificationIndex, 1);
    cache.writeQuery({
      query: getNotificationsQuery,
      variables,
      data: {
        notifications: newNotifications,
      },
    });
  } catch (e) {
    console.error(e);
    //no-op
  }
};

export const archiveNotificationInCache = <T, V>(
  cache: ApolloCache<T>,
  variables: V,
  result: FetchResult<UpdateOneNotificationMutation>
) => {
  try {
    const data = cache.readQuery<getNotifications>({ query: getNotificationsQuery, variables });
    const notifications = [...(data?.notifications ?? [])];
    let index = -1;
    const archivedNotification = notifications.find((r, i) => {
      if (r?._id === result?.data?.updateOneNotification?._id) {
        index = i;
        return true;
      }
    });
    const updatedNotification = { ...archivedNotification, archived: true } as getNotifications_notifications;
    const newNotifications = [...notifications];
    newNotifications.splice(index, 1);
    newNotifications.push(updatedNotification);
    cache.writeQuery({
      query: getNotificationsQuery,
      variables,
      data: {
        notifications: newNotifications,
      },
    });
  } catch (e) {
    console.error(e);
    //no-op
  }
};
