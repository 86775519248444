import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card/Card";
import CardActionArea from "@material-ui/core/CardActionArea";

const useStyles = makeStyles((theme) => ({
  actionContainer: {
    background: theme.palette.primary.transparent,
    width: "100%",
    minHeight: 200,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  actionButtonArea: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
  },
}));

type Props = { onClick?: () => void; children: React.ReactElement };

const UserActionCard: FC<Props> = ({ onClick, children }) => {
  const classes = useStyles();

  return (
    <Card className={classes.actionContainer}>
      {onClick ? (
        <CardActionArea className={classes.actionButtonArea} onClick={onClick}>
          {children}
        </CardActionArea>
      ) : (
        children
      )}
    </Card>
  );
};

export default UserActionCard;
