import clsx from 'clsx';
import React, { FC } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { wizardModalStyles } from '../../../utils/styleHelpers';

const useStyles = makeStyles((theme) => ({
  wizardModalStyles: wizardModalStyles(theme),
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    background: theme.palette.background.paper,
    overflowY: "scroll",
  },
}));

type Props = {
  children?: React.ReactElement | React.ReactElement[];
};

const WizardStep: FC<Props> = ({ children }) => {
  const classes = useStyles();
  const containerClasses = clsx({
    [classes.mainContainer]: true,
    [classes.wizardModalStyles]: true,
  });

  return <div className={containerClasses}>{children}</div>;
};

export default WizardStep;
