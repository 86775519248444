import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { Card, CardActionArea, Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography/Typography';

import { getGiftProfileQuery } from '../../graphql/queries/gift-profiles';
import {
  getGiftProfile, getGiftProfileVariables
} from '../../graphql/queries/gift-profiles/__generated__/getGiftProfile';
import { getGiftee_giftee } from '../../graphql/queries/giftees/__generated__/getGiftee';
import {
  getOccasions as getOccasionsQueryType, getOccasions_occasions,
  getOccasionsVariables as getOccasionsQueryVariables
} from '../../graphql/queries/occasions/__generated__/getOccasions';
import { getOccasionsQuery } from '../../graphql/queries/occasions/index';
import {
  closeAddInterestDialog, closeAddOccasionDialog, closeAddReminderDialog,
  closeEditGifteeGiftProfileInterestsDialog, openEditGifteeGiftProfileInterestsDialog
} from '../../redux/active-giftee/actions/index';
import { ActiveGifteeRootState } from '../../redux/active-giftee/reducers/index';
import { RootState } from '../../redux/store';
import { make as GifteeGiftProfileEdit } from '../../rescript/components/GifteeGiftProfileEdit.gen';
import { make as GiftIdeaFeedForGiftee } from '../../rescript/components/GiftIdeaFeedForGiftee.gen';
import { make as GiftIdeaDetail } from '../../rescript/components/GiftIdeaModal.gen';
import AddInterestForm from '../forms/AddInterestForm';
import AddReminderForm from '../forms/AddReminderForm';
import Modal from '../general-ui/modals/Modal';
import SecondaryModal from '../general-ui/modals/SecondaryModal';
import TabPanelRoute from '../general-ui/TabPanelRoute';
import OccasionFeed from '../occasions/OccasionFeed';
import NewOccasionWizard from '../wizards/NewOccasionWizard';
import GifteeDetailInfo from './giftee-detail-mobile/GifteeDetailInfo';
import GifteeDesktopSidebarNav from './GifteeDesktopSidebarNav';
import GifteeDetailButton from './GifteeDetailButton';
import InterestsList from './InterestsList';

const useStyles = makeStyles((theme: Theme) => ({
  gifteeDetailDesktop: {
    position: "relative",
    height: "100vh",
    display: "flex",
    overflow: "hidden",
  },
  gifteeDetailHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: "100%",
    height: 104,
    color: theme.palette.common.white,
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    backgroundImage: "url('/images/white-corner-splatter.png')",
    backgroundSize: 100,
    backgroundPosition: "top right",
    backgroundRepeat: "no-repeat",
  },
  gifteeDetailWidget: {
    flex: 1,
    margin: 8,
    padding: 16,
    "& .MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight": {
      opacity: 0,
    },
  },
  gifteeContent: {
    width: "calc(100vw - 362px)",
    padding: 60,
    paddingTop: 100,
  },
  gifteeColumn: {
    zIndex: 10,
    padding: "0px 16px",
    maxWidth: 380,
    flexWrap: "nowrap",
  },
  gifteeColumnHeader: {
    height: 300,
  },
  columnHeaderTitle: {
    background: theme.palette.background.background,
    border: "2px solid white",
    borderRadius: 30,
    height: 160,
    width: "100%",
    color: theme.palette.text.primary,
    padding: 16,
    marginBottom: 20,
    zIndex: 10,
  },
  giftIdeaColumnHeaderTitle: {
    marginTop: 120,
  },
  giftFeedContainer: {
    height: "calc(100vh - 120px)",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      opacity: 0,
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.text.secondary,
      outline: "1px solid slategrey",
      borderRadius: 100,
    },
  },
  gifteeHeaderActions: {
    color: theme.palette.text.primary,
    zIndex: 20,
    padding: 10,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },
  gifteeTitle: {
    alignSelf: "center",
    color: theme.palette.text.primary,
  },
  detailButtonWrapper: {
    position: "fixed",
    bottom: "100px",
    right: "100px",
    zIndex: 15,
  },
  gifteeSettingWrapper: {
    marginTop: 30,
  },
  interestsTab: {
    "& img": {
      width: "100%",
    },
  },
  profileTitle: {
    marginTop: -70,
  },
  priceFilterTitle: {
    marginBottom: 0,
  },
  addInterestContainer: {
    position: "absolute",
    left: "calc(50% - 20px)",
    bottom: -50,
    color: theme.palette.text.primary,
    "& button": {
      color: theme.palette.text.primary,
    },
  },
  viewGiftIdeasContainer: {
    bottom: 50,
    left: "calc(50% - 75px)",
    position: "fixed",
    "& button": {
      color: theme.palette.text.primary,
      width: 150,
    },
  },
  giftIdeaFilter: {
    padding: 16,
  },
  giftIdeaFeed: {
    padding: 16,
    alignItems: "flex-start",
    height: "calc(100vh - 260px)",
    marginTop: -40,
    overflowY: "scroll",
  },
  loadingGifts: {
    marginTop: 40,
  },
  occasions: {
    width: "100%",
    height: "calc(100vh - 290px)",
    listStyle: "none",
    paddingLeft: 0,
    paddingTop: 30,
    zIndex: 15,
    marginTop: -40,
    overflowY: "scroll",
    overflow: "visible",
    "& > .MuiPaper-root": {
      width: "95%",
      margin: "auto",
    },
  },
}));

type Props = { giftee: getGiftee_giftee };
const GifteeDetailDesktop: FC<Props> = ({ giftee }) => {
  const dispatch = useDispatch();
  const activeGifteeState = useSelector<RootState, ActiveGifteeRootState>((state) => {
    return state.activeGiftee;
  });
  const isUser = !!giftee.sourceUser;

  const getOccasionsQueryContext = useQuery<getOccasionsQueryType, getOccasionsQueryVariables>(getOccasionsQuery, {
    variables: {
      query: {
        giftee: {
          _id: giftee._id,
        },
      },
    },
    fetchPolicy: "network-only",
  });

  const giftProfileVariables = isUser
    ? {
        userId: giftee.sourceUser?._id,
      }
    : {
        gifteeId: giftee._id,
      };

  const { data: giftProfileData } = useQuery<getGiftProfile, getGiftProfileVariables>(getGiftProfileQuery, {
    variables: {
      query: giftProfileVariables,
    },
  });

  const showInterestsEdit = Boolean(!isUser && giftProfileData?.giftProfile?.interests);

  const occasions = (getOccasionsQueryContext.data?.occasions ?? []).filter(
    (occasion): occasion is getOccasions_occasions => occasion !== null
  );

  const openEditInterestsForm = () => {
    dispatch({ ...openEditGifteeGiftProfileInterestsDialog() });
  };

  const closeEditInterestsForm = () => {
    dispatch({ ...closeEditGifteeGiftProfileInterestsDialog() });
  };

  const closeNewInterestForm = () => {
    dispatch({ ...closeAddInterestDialog() });
  };

  const closeAddReminderForm = () => {
    dispatch({ ...closeAddReminderDialog() });
  };

  const hideNewOccasion = () => {
    dispatch({ ...closeAddOccasionDialog() });
  };

  const classes = useStyles();
  const history = useHistory();

  if (!giftee) {
    return <Redirect to="/app"></Redirect>;
  }

  return (
    <div className={classes.gifteeDetailDesktop}>
      <GiftIdeaDetail />

      <div className={classes.detailButtonWrapper}>
        <GifteeDetailButton giftee={giftee}></GifteeDetailButton>
      </div>
      {showInterestsEdit && (
        <SecondaryModal
          title={"Edit interests for " + giftee.name}
          showModal={activeGifteeState.editGifteeGiftProfileInterestsDialogOpen}
          closeModal={closeEditInterestsForm}
        >
          <GifteeGiftProfileEdit gifteeId={giftee._id} sourceUserId={giftee.sourceUser?._id} />
        </SecondaryModal>
      )}
      <SecondaryModal
        title={"Add New Interest to " + giftee.name}
        showModal={activeGifteeState.newInterestDialogOpen}
        closeModal={closeNewInterestForm}
      >
        <AddInterestForm giftee={giftee} />
      </SecondaryModal>
      <Modal
        showModal={activeGifteeState.newOccasionDialogOpen}
        header={"Add new Occasion to " + giftee.name}
        title={"Add new Occasion to " + giftee.name}
        closeModal={hideNewOccasion}
      >
        <NewOccasionWizard giftee={giftee} hideModal={hideNewOccasion} />
      </Modal>
      <SecondaryModal
        title="Add Reminder"
        showModal={activeGifteeState.newReminderDialogOpen}
        closeModal={closeAddReminderForm}
      >
        <AddReminderForm occasions={occasions} closeModal={closeAddReminderForm} />
      </SecondaryModal>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.gifteeHeaderActions}
      >
        <Tooltip title="Back to Home">
          <IconButton onClick={() => history.push("/home")}>
            <span className="closer lnr lnr-arrow-left"></span>
          </IconButton>
        </Tooltip>
      </Grid>
      <GifteeDesktopSidebarNav giftee={giftee} />
      <Grid className={classes.gifteeContent}>
        <TabPanelRoute route={`/giftee/${giftee._id}/info`} index={0}>
          <Grid container>
            <Paper className={classes.gifteeDetailHeader}>
              <Typography variant="h5" component="h4" align="left" gutterBottom>
                Giftee Info
              </Typography>
              <Typography variant="subtitle2" component="h4" align="left" gutterBottom>
                {giftee.isCustom ? "This is your giftee!" : "This giftee is tied to an Occasional.ly user"}
              </Typography>
            </Paper>
          </Grid>

          <Grid container direction="row" justifyContent="space-between" wrap="nowrap">
            <Paper className={classes.gifteeDetailWidget}>
              <GifteeDetailInfo giftee={giftee} />
            </Paper>
            <Card className={classes.gifteeDetailWidget}>
              <CardActionArea onClick={showInterestsEdit ? openEditInterestsForm : undefined}>
                <Typography variant="subtitle1" component="h4" align="left" gutterBottom>
                  Gift Profile
                </Typography>
                <InterestsList giftProfile={giftProfileData?.giftProfile ?? undefined} />
                {showInterestsEdit && (
                  <IconButton onClick={openEditInterestsForm}>
                    <span className="lnr lnr-pencil"></span>
                  </IconButton>
                )}
              </CardActionArea>
            </Card>
          </Grid>
        </TabPanelRoute>
        <TabPanelRoute route={`/giftee/${giftee._id}/occasions`} index={0}>
          <Grid container direction="column" justifyContent="space-evenly" alignItems="flex-start">
            <Grid container>
              <Paper className={classes.gifteeDetailHeader}>
                <Typography variant="h5" component="h4" align="left" gutterBottom>
                  Occasions
                </Typography>
                <Typography variant="subtitle2" component="h4" align="left" gutterBottom>
                  {`${getOccasionsQueryContext.data?.occasions?.length ?? 0} occasions coming up this year.`}
                </Typography>
              </Paper>
            </Grid>
            <OccasionFeed queryContext={getOccasionsQueryContext} />
          </Grid>
        </TabPanelRoute>
        <TabPanelRoute route={`/giftee/${giftee._id}/gift-ideas`} index={0}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.giftFeedContainer}
          >
            <GiftIdeaFeedForGiftee gifteeId={giftee._id} userId={giftee.sourceUser?._id} />
          </Grid>
        </TabPanelRoute>
      </Grid>
    </div>
  );
};

export default GifteeDetailDesktop;
