import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import Avatar from '@material-ui/core/Avatar';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
// import { Meteor } from 'meteor/meteor';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { useAuthContext } from '../../auth/authContext';
import { deleteOneNotification } from '../../graphql/mutations/notifications';
import {
  DeleteOneNotificationMutation, DeleteOneNotificationMutationVariables
} from '../../graphql/mutations/notifications/__generated__/DeleteOneNotificationMutation';
import { getNotificationsQuery } from '../../graphql/queries/notifications';
import {
  getNotifications_notifications
} from '../../graphql/queries/notifications/__generated__/getNotifications';
import { closeNotificationsMenu, openUserMenu, setGoToMenuStep } from '../../redux/general/actions';
import PrimaryButton from '../general-ui/buttons/PrimaryButton';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    height: 60,
    width: 60,
    marginRight: 10,
  },
  notificationWrapper: {
    marginBottom: 10,
  },
  notification: {
    height: "100px",
    padding: "0px",
    paddingLeft: "10px",
    boxShadow: theme.shadows[2],
    "& .MuiListItemText-primary": {
      fontSize: 14,
    },
  },
  notificationButtons: {
    height: "100%",
  },
  notificationButtonAdd: {
    height: "100%",
    width: "100px",
    borderRadius: "0px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    "&:hover": {
      background: theme.palette.error.dark,
    },
  },
}));

type Props = {
  notification: getNotifications_notifications;
  timeAgo: string;
};

const NotificationGiftProfileRequest: FC<Props> = ({ notification, timeAgo }) => {
  const { authState } = useAuthContext();
  const { requester } = notification;
  const theme = useTheme();
  const [openExpansion, setOpenExpansion] = useState(false);
  const dispatch = useDispatch();
  const [deleteNotification] = useMutation<DeleteOneNotificationMutation, DeleteOneNotificationMutationVariables>(
    deleteOneNotification
  );

  const toggleExpansion = () => {
    setOpenExpansion(!openExpansion);
  };

  const handleCreateGiftProfile = async () => {
    dispatch({ ...setGoToMenuStep(2) });
    dispatch({ ...closeNotificationsMenu() });
    dispatch({ ...openUserMenu() });
    await deleteNotification({
      variables: {
        query: {
          _id: notification._id,
        },
      },
      refetchQueries: [
        {
          query: getNotificationsQuery,
          variables: {
            query: {
              userId: authState.user.auth.id,
            },
          },
        },
      ],
    });
  };

  const classes = useStyles();

  const requesterName = requester?.firstName ?? requester?.email;

  const userAvatar = requester?.picture ? (
    <ListItemAvatar>
      <Avatar src={requester?.picture} className={classes.avatar} />
    </ListItemAvatar>
  ) : (
    <ListItemAvatar>
      <Avatar className={classes.avatar}>{requesterName?.charAt(0)}</Avatar>
    </ListItemAvatar>
  );

  return (
    <div className={classes.notificationWrapper}>
      <ListItem button key={notification._id} className={classes.notification} onClick={toggleExpansion}>
        {userAvatar}
        <ListItemText secondary={timeAgo}>
          <span style={{ color: theme.palette.text.primaryBranded }}>{requesterName}</span> has requested that you setup
          your gift profile so that they can get gift ideas for you! Click the button below to get started.
        </ListItemText>
        {openExpansion ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openExpansion} timeout="auto" unmountOnExit>
        <ListItem className={classes.notificationButtons} disableGutters>
          <PrimaryButton onClick={handleCreateGiftProfile}>Setup Gift Profile</PrimaryButton>
        </ListItem>
      </Collapse>
    </div>
  );
};

export default NotificationGiftProfileRequest;
