import classNames from 'classnames';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import { FormControl } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { setGender } from '../../../redux/onboarding/actions';
import { Gender } from '../../../redux/onboarding/constants/action-types';
import { OnboardingRootState } from '../../../redux/onboarding/reducers';
import { RootState } from '../../../redux/store';
import { OnboardingWizardSteps } from '../OnboardingWizard';
import OnboardingStep from './OnboardingStep';

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    width: "100%",
    height: "37.5vh",
    maxHeight: 250,
    [theme.breakpoints.down("sm")]: {
      height: "30.5vh",
    },
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  mainImage: {
    height: "100%",
    objectFit: "contain",
  },
  headline: {
    marginBottom: theme.spacing(2),
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "100%",
    maxWidth: 600,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    background: theme.palette.background.background,
    color: theme.palette.text.primary,
  },
  formControl: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& label": {
      color: theme.palette.text.primary,
    },
    "& .MuiSelect-select option": {
      background: theme.palette.background.background,
    },
    "& .Mui-focused select option": {
      background: theme.palette.background.background,
    },
  },
  header: {
    color: theme.palette.primary.main,
  },
  input: {
    color: theme.palette.text.primary,
    "& fieldset": {
      borderColor: theme.palette.text.primary,
    },
  },
  inputWrap: {
    marginTop: 20,
    position: "relative",
    "& button": {
      position: "absolute",
      right: 4,
      top: "calc(50% - 20px)",
      color: theme.palette.text.primary,
    },
  },
  confirmButtonContainer: {
    marginTop: theme.spacing(3),
  },
  radioIconChecked: {
    height: 24,
    width: 24,
    "&.lnr-woman2": {
      color: theme.palette.error.dark,
      border: "2px solid " + theme.palette.error.dark,
    },
    "&.lnr-man2": {
      color: theme.palette.info.light,
      border: "2px solid " + theme.palette.info.light,
    },
    "&.lnr-rocket": {
      color: theme.palette.primary.main,
      border: "2px solid " + theme.palette.primary.main,
    },
    fontSize: 16,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

type Props = {} & Partial<StepWizardChildProps>;

const SetBirthday: FC<Props> = ({ currentStep, nextStep, previousStep }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { gender } = useSelector<RootState, OnboardingRootState>((state) => {
    return state.onboarding;
  });

  return (
    <OnboardingStep
      step={currentStep ?? OnboardingWizardSteps.Introduction}
      onNext={nextStep}
      onPrevious={previousStep}
    >
      <div className={classes.form}>
        <Grid container direction="column" justifyContent="space-evenly" alignItems="center">
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <div className={classes.imageContainer}>
              <img src="/images/gender.png" className={classes.mainImage} />
            </div>
            <Typography variant="h6" align="center" className={classes.headline}>
              What gender do you identify as?
            </Typography>
            <Typography variant="body1" align="center" gutterBottom>
              This will help us recommend gifts on your behalf. Your answer will allow us to filter out gift
              recommendations that don't make sense for you.
            </Typography>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup aria-label="gender" name="gender" value={gender}>
                <FormControlLabel
                  value={"female"}
                  control={
                    <Radio
                      checkedIcon={<span className={classNames(["lnr lnr-woman2", classes.radioIconChecked])}></span>}
                      checked={gender === Gender.Female}
                      onClick={() => dispatch({ ...setGender(Gender.Female) })}
                    />
                  }
                  label="Female"
                />
                <FormControlLabel
                  value={"male"}
                  control={
                    <Radio
                      checkedIcon={<span className={classNames(["lnr lnr-man2", classes.radioIconChecked])}></span>}
                      checked={gender === Gender.Male}
                      onClick={() => dispatch({ ...setGender(Gender.Male) })}
                    />
                  }
                  label="Male"
                />
                <FormControlLabel
                  value="unisex"
                  control={
                    <Radio
                      checkedIcon={<span className={classNames(["lnr lnr-rocket", classes.radioIconChecked])}></span>}
                      checked={gender === Gender.Unisex}
                      onClick={() => dispatch({ ...setGender(Gender.Unisex) })}
                    />
                  }
                  label="Non-binary"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </OnboardingStep>
  );
};

export default SetBirthday;
