import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import { useLazyQuery, useQuery } from '@apollo/client';
import { CircularProgress, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { OccasionType } from '../../../__generated__/globalTypes';
import { useAuthContext } from '../../../auth/authContext';
import { getGifteesQuery } from '../../../graphql/queries/giftees';
import {
  getGiftees, getGifteesVariables
} from '../../../graphql/queries/giftees/__generated__/getGiftees';
import { getUsersQuery } from '../../../graphql/queries/users';
import {
  getUsers, getUsers_users, getUsersVariables
} from '../../../graphql/queries/users/__generated__/getUsers';
import { UserSearch_UserSearch } from '../../../graphql/queries/users/__generated__/UserSearch';
import { useQueryUsersWhoAddedMe } from '../../../hooks/useQueryUsersWhoAddedMe';
import { setUsersToNotify } from '../../../redux/new-occasion/actions';
import { NewOccasionRootState } from '../../../redux/new-occasion/reducers';
import { RootState } from '../../../redux/store';
import PrimaryButton from '../../general-ui/buttons/PrimaryButton';
import SecondaryButton from '../../general-ui/buttons/SecondaryButton';
import UserSearchResult from '../../general-ui/UserSearchResult';
import WizardStep from '../general-wizard-ui/WizardStep';
import { NewOccasionWizardSteps } from '../NewOccasionWizard';

const useStyles = makeStyles((theme: Theme) => ({
  formControlHeader: {
    marginBottom: theme.spacing(1),
    marginTop: "20px",
  },
  container: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  formControl: {
    width: "100%",
  },
  gifteeSearchResults: {
    zIndex: 10,
    overflow: "hidden",
    overflowY: "scroll",
    flex: 2,
  },
  selectOccasionContent: {
    height: "400px",
  },
  actionButtons: {
    width: "100%",
    height: 100,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    justifySelf: "flex-end",
  },
}));

type Props = {} & Partial<StepWizardChildProps>;

const SelectUsersToNotify: FC<Props> = ({ nextStep, previousStep, goToStep }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { usersToNotify, occasionType } = useSelector<RootState, NewOccasionRootState>((state) => {
    return state.newOccasion;
  });
  const [users, loading] = useQueryUsersWhoAddedMe();

  const customDateOccasion =
    occasionType === OccasionType.BIRTHDAY ||
    occasionType === OccasionType.WEDDING ||
    occasionType === OccasionType.ANNIVERSARY ||
    occasionType === OccasionType.CUSTOM;

  const handleGoBack = () => {
    if (customDateOccasion) {
      previousStep?.();
    } else {
      goToStep?.(NewOccasionWizardSteps.SelectOccasionType);
    }
  };

  const handleUserClick = (user: UserSearch_UserSearch) => {
    if (usersToNotify.includes(user._id)) {
      handleRemoveUser({ ...user, authId: user._id });
    } else {
      handleAddUser({ ...user, authId: user._id });
    }
  };

  const handleAddUser = (user: getUsers_users) => {
    const previousUsersToNotify = usersToNotify;
    dispatch({ ...setUsersToNotify([...previousUsersToNotify, user.authId ?? ""]) });
  };

  const handleRemoveUser = (user: getUsers_users) => {
    const userIndex = usersToNotify.findIndex((u) => u === user.authId);
    const newUsers = [...usersToNotify];
    newUsers.splice(userIndex, 1);
    dispatch({ ...setUsersToNotify(newUsers) });
  };

  const renderUserSearch = useCallback(() => {
    if (loading) {
      return (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      );
    } else if ((users.length ?? 0) > 0) {
      return users.map((user) => {
        const listUser = { ...user, _id: user.authId };
        return (
          <UserSearchResult
            key={listUser._id}
            user={listUser}
            selected={usersToNotify.includes(listUser._id ?? "")}
            userResultClick={handleUserClick}
          />
        );
      });
    } else {
      return (
        <Typography align="center" variant="subtitle1">
          Looks like there aren't any users to notify. You can skip this.
        </Typography>
      );
    }
  }, [users, usersToNotify]);

  const handleConfirmSelections = () => {
    nextStep?.();
  };

  return (
    <WizardStep>
      <div className={classes.container}>
        <Typography align="center" variant="h6" gutterBottom>
          Which users do you want to notify about your new occasion?
        </Typography>
        <div className={classes.gifteeSearchResults}>{renderUserSearch()}</div>

        <div className={classes.actionButtons}>
          <PrimaryButton onClick={handleConfirmSelections}>Confirm Selections</PrimaryButton>
          <SecondaryButton onClick={handleGoBack}>
            <span className="lnr lnr-arrow-left"></span>
            &nbsp;Go Back
          </SecondaryButton>
        </div>
      </div>
    </WizardStep>
  );
};

export default SelectUsersToNotify;
