import React, { createContext, useContext } from "react";
import { GlobalMessageState, GlobalMessageAction } from "../../../@types/global-messages";

export const initialState = {
  globalMessages: [],
};

const initialContext: {
  globalMessageState: GlobalMessageState;
  globalMessageDispatch: React.Dispatch<GlobalMessageAction>;
} = {
  globalMessageState: initialState,
  globalMessageDispatch: () => {},
};

export const GlobalMessageContext = createContext(initialContext);

export const useGlobalMessageContext = () => useContext(GlobalMessageContext);
