import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { useAuthContext } from '../../../auth/authContext';
import { deleteOneGiftee, updateOneGiftee } from '../../../graphql/mutations/giftees';
import {
  DeleteOneGifteeMutation, DeleteOneGifteeMutationVariables
} from '../../../graphql/mutations/giftees/__generated__/DeleteOneGifteeMutation';
import {
  UpdateOneGifteeMutation, UpdateOneGifteeMutationVariables
} from '../../../graphql/mutations/giftees/__generated__/UpdateOneGifteeMutation';
import { getGiftee_giftee } from '../../../graphql/queries/giftees/__generated__/getGiftee';
import { getOccasionsQuery } from '../../../graphql/queries/occasions';
import {
  getOccasions as getOccasionsQueryType, getOccasions_occasions,
  getOccasionsVariables as getOccasionsQueryVariables
} from '../../../graphql/queries/occasions/__generated__/getOccasions';
import { deleteGifteeFromCache } from '../../../graphql/update-cache/giftees';
import {
  cancelDeleteGiftee, closeAddInterestDialog, closeAddOccasionDialog, closeAddReminderDialog
} from '../../../redux/active-giftee/actions';
import { ActiveGifteeRootState } from '../../../redux/active-giftee/reducers';
import { RootState } from '../../../redux/store';
import {
  make as GifteeGiftProfileEdit
} from '../../../rescript/components/GifteeGiftProfileEdit.gen';
import { make as GiftIdeaFeed } from '../../../rescript/components/GiftIdeaFeedForGiftee.gen';
import { make as GiftIdeaDetail } from '../../../rescript/components/GiftIdeaModal.gen';
import AddInterestForm from '../../forms/AddInterestForm';
import AddReminderForm from '../../forms/AddReminderForm';
import AnimatedDelete from '../../general-ui/AnimatedDelete';
import DeleteButton from '../../general-ui/buttons/DeleteButton';
import SecondaryButton from '../../general-ui/buttons/SecondaryButton';
import TertiaryButton from '../../general-ui/buttons/TertiaryButton';
import CloudinaryPhotoUpload from '../../general-ui/CloudinaryPhotoUpload';
import GlobalMessageDispatch from '../../general-ui/global-messages/GlobalMessageDispatch';
import MobileMenuTabs from '../../general-ui/MobileMenuTabs';
import Modal from '../../general-ui/modals/Modal';
import SecondaryModal from '../../general-ui/modals/SecondaryModal';
import TabPanelRoute from '../../general-ui/TabPanelRoute';
import OccasionFeed from '../../occasions/OccasionFeed';
import NewOccasionWizard from '../../wizards/NewOccasionWizard';
import GifteeDetailButton from '../GifteeDetailButton';
import { useGifteeRoutes } from '../gifteeRoutes';
import InterestsList from '../InterestsList';
import GifteeDetailInfo from './GifteeDetailInfo';

export const useStyles = makeStyles((theme) => ({
  gifteeDetailMobile: {
    width: "100vw",
    height: "calc(100vh - 76px)",
    overflowY: "scroll",
  },
  gifteeDetailHeader: {
    position: "relative",
    color: theme.palette.text.primary,
    "& h2": {
      zIndex: 10,
      minHeight: 27,
    },
  },
  gifteeHeaderActions: {
    color: theme.palette.text.primary,
    zIndex: 10,
    padding: 10,
  },
  gifteePhoto: {
    zIndex: 10,
  },
  detailButtonWrapper: {
    position: "fixed",
    bottom: 36,
    width: "100vw",
    zIndex: theme.zIndex.speedDial,
  },
  gifteeSettingWrapper: {
    marginTop: 30,
  },
  occasionsTab: {},
  interestsTab: {
    padding: 16,
    "& img": {
      width: "100%",
    },
  },
  profileTitle: {
    marginTop: -70,
  },
  priceFilterTitle: {
    marginBottom: 40,
  },
  addInterestContainer: {
    position: "absolute",
    left: "calc(50% - 20px)",
    bottom: -50,
    color: theme.palette.text.primary,
    "& button": {
      color: theme.palette.text.primary,
    },
  },
  tabContainer: {
    zIndex: 90,
    position: "fixed",
    bottom: -1,
    width: "calc(100vw - 16px)",
    borderRadius: 50,
    background: theme.palette.primary.dark,
    color: theme.palette.common.white,
    "& .Mui-selected": {
      color: theme.palette.primary.dark,
    },
    "& .MuiTab-root": {
      flex: 1,
      zIndex: 10,
    },
    "& .MuiTabs-indicator": {
      height: "100%",
      border: `3px solid ${theme.palette.primary.dark}`,
      borderRadius: 50,
      background: theme.palette.text.primary,
      boxSizing: "border-box",
    },
  },
  tabPanel: {
    padding: 4,
  },
  viewGiftIdeasContainer: {
    bottom: 50,
    left: "calc(50% - 75px)",
    position: "fixed",
    "& button": {
      color: theme.palette.text.primary,
      width: 150,
    },
  },
}));

type Props = {
  giftee: getGiftee_giftee;
};

const GifteeDetailMobile: FC<Props> = ({ giftee }) => {
  const history = useHistory();
  const { authState } = useAuthContext();

  const dispatch = useDispatch();
  const activeGifteeState = useSelector<RootState, ActiveGifteeRootState>((state) => {
    return state.activeGiftee;
  });

  const [cloudinaryResult, setCloudinaryResult] = useState<null | string>(null);
  const [deleteGifteeShow, setDeleteGifteeShow] = useState(false);
  const [deleteGifteeSnackbarShow, setDeleteGifteeSnackbarShow] = useState(false);
  const [updateGiftee] = useMutation<UpdateOneGifteeMutation, UpdateOneGifteeMutationVariables>(updateOneGiftee);
  const [deleteGiftee] = useMutation<DeleteOneGifteeMutation, DeleteOneGifteeMutationVariables>(deleteOneGiftee);
  const getOccasionsQueryContext = useQuery<getOccasionsQueryType, getOccasionsQueryVariables>(getOccasionsQuery, {
    variables: {
      query: {
        giftee: {
          _id: giftee._id,
        },
      },
    },
    fetchPolicy: "network-only",
  });

  const cloudinaryResultHandler = async (file: string) => {
    setCloudinaryResult(file);
    await updateGiftee({
      variables: {
        query: {
          _id: giftee._id,
        },
        set: {
          picture: file,
        },
      },
    });
  };

  const hideNewOccasion = () => {
    dispatch({ ...closeAddOccasionDialog() });
  };

  const deleteGifteeForm = () => {
    setDeleteGifteeShow(true);
  };

  const deleteGifteeFormHide = () => {
    setDeleteGifteeShow(false);
  };

  const deleteGifteeHandler = async () => {
    const gifteeId = giftee._id;
    setDeleteGifteeShow(false);
    setDeleteGifteeSnackbarShow(true);

    const variables = {
      query: {
        _id: gifteeId,
      },
    };

    await deleteGiftee({
      variables,
      update: (cache, result) =>
        deleteGifteeFromCache(
          cache,
          {
            query: {
              userId: authState.user.auth.id,
            },
          },
          result
        ),
    });

    history.push("/home");

    //TODO: Add cancellation timeout
    setTimeout(() => {
      if (!activeGifteeState.cancelDeleteGiftee) {
        //if no cancel, then delete
        // Meteor.call("giftees.remove", gifteeId);
        //TODO ADD API CALL
      } else {
        //otherwise, do not delete and reset everything
        //TODO: Handle not deleting giftee
        // this.setState({
        //   gifteeDeletingSnack: false,
        //   cancelDeleteGiftee: false,
        //   gifteeDeletingSnackMessage: "Deleting Giftee...",
        //   deleted: true,
        // });
      }
    }, 5000);
  };

  // const handleCancelDeleteGiftee = () => {
  //   //set state to cancel giftee deletion
  //   dispatch({ ...cancelDeleteGiftee() });
  // };

  // const closeDeleteGifteeSnackbar = () => {
  //   //TODO: add snackbar system globally
  //   // this.setState({
  //   //   gifteeDeletingSnack: false,
  //   // });
  // };

  const closeNewInterestForm = () => {
    dispatch({ ...closeAddInterestDialog() });
  };

  const closeAddReminderForm = () => {
    dispatch({ ...closeAddReminderDialog() });
  };

  const backToApp = () => {
    history.push("/home");
  };

  const classes = useStyles();
  const parentData = {
    image: cloudinaryResult ?? giftee.picture,
    text: giftee.name,
  };
  const occasions = (getOccasionsQueryContext.data?.occasions ?? []).filter(
    (occasion): occasion is getOccasions_occasions => occasion !== null
  );

  const [routes] = useGifteeRoutes(giftee._id);

  return (
    <div className={classes.gifteeDetailMobile}>
      <GiftIdeaDetail />
      <SecondaryModal
        title={"Add New Interest to " + giftee.name}
        showModal={activeGifteeState.newInterestDialogOpen}
        closeModal={closeNewInterestForm}
      >
        <AddInterestForm giftee={giftee} />
      </SecondaryModal>
      <Modal
        showModal={activeGifteeState.newOccasionDialogOpen}
        header={"Add new Occasion to " + giftee.name}
        title={"Add new Occasion to " + giftee.name}
        closeModal={hideNewOccasion}
      >
        <NewOccasionWizard giftee={giftee} hideModal={hideNewOccasion} />
      </Modal>
      <SecondaryModal
        title="Add Reminder"
        showModal={activeGifteeState.newReminderDialogOpen}
        closeModal={closeAddReminderForm}
      >
        <AddReminderForm occasions={occasions} closeModal={closeAddReminderForm} />
      </SecondaryModal>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.gifteeDetailHeader}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.gifteeHeaderActions}
        >
          <div className="closer">
            <IconButton onClick={backToApp}>
              <span className="lnr lnr-arrow-left"></span>
            </IconButton>
          </div>
          <DeleteButton onClick={deleteGifteeForm} variant="light"></DeleteButton>
        </Grid>
        <div className={"cloudinary-photo-upload-container " + classes.gifteePhoto}>
          <CloudinaryPhotoUpload
            key={giftee._id}
            onUpload={cloudinaryResultHandler}
            parentData={parentData}
            allowUpload={!giftee.sourceUser}
          />
        </div>
        <h2>{giftee.name}</h2>
        <div className={classes.detailButtonWrapper}>
          <GifteeDetailButton giftee={giftee}></GifteeDetailButton>
        </div>
      </Grid>
      <div className={classes.tabContainer}>
        <MobileMenuTabs activeTab={routes.findIndex((r) => r.to === window.location.pathname) ?? 0} tabs={routes} />
      </div>
      <TabPanelRoute route={`/giftee/${giftee._id}/info`} className={classes.tabPanel} index={0}>
        <GifteeDetailInfo giftee={giftee} />
      </TabPanelRoute>
      <TabPanelRoute route={`/giftee/${giftee._id}/occasions`} className={classes.tabPanel} index={1}>
        <div id="edit-occasions">
          <Grid
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="flex-start"
            className={classes.occasionsTab}
          >
            <OccasionFeed queryContext={getOccasionsQueryContext} />
          </Grid>
        </div>
      </TabPanelRoute>
      <TabPanelRoute route={`/giftee/${giftee._id}/interests`} className={classes.tabPanel} index={2}>
        <div id="edit-interest">
          <div className={classes.interestsTab}>
            <div>
              <GifteeGiftProfileEdit gifteeId={giftee._id} sourceUserId={giftee.sourceUser?._id} />
              {/* <InterestsList interests={giftee.interests ?? []} giftee={giftee} /> */}
              {/* <Fab
                className={classes.addInterestContainer}
                size="small"
                color="primary"
                aria-label="Add"
                onClick={handleNewInterestClick}
              >
                <Icon fontSize="small">add</Icon>
              </Fab> */}
            </div>
          </div>
        </div>
      </TabPanelRoute>
      <TabPanelRoute route={`/giftee/${giftee._id}/gift-ideas`} className={classes.tabPanel} index={3}>
        <GiftIdeaFeed gifteeId={giftee._id} userId={giftee.sourceUser?._id} />
      </TabPanelRoute>

      <SecondaryModal
        title={"Delete Giftee: " + giftee.name}
        showModal={deleteGifteeShow}
        closeModal={deleteGifteeFormHide}
      >
        <h3>Do you want to delete {giftee.name} as a giftee?</h3>
        <div className="dialog-btn-set">
          <TertiaryButton onClick={deleteGifteeFormHide}>Cancel</TertiaryButton>
          <SecondaryButton onClick={deleteGifteeHandler}>Delete</SecondaryButton>
        </div>
      </SecondaryModal>
      {deleteGifteeSnackbarShow && (
        <GlobalMessageDispatch finalText="Occasion Deleted">
          <Grid container direction="row" alignItems="center">
            <AnimatedDelete
              otherStyles={{ width: 40, height: 40 }}
              checked={true}
              icon={
                <Icon>
                  <span className="lnr lnd-calendar-cross"></span>
                </Icon>
              }
            />

            <Typography variant="body1" color="inherit">
              Giftee deleted
            </Typography>
            {/* <SecondaryButton onClick={handleCancelDeleteGiftee}>Cancel</SecondaryButton> */}
          </Grid>
        </GlobalMessageDispatch>
      )}
    </div>
  );
};

export default GifteeDetailMobile;
