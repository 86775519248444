// Generated by ReScript, PLEASE EDIT WITH CARE

import * as TS from "../bindings/TS.bs.js";
import * as React from "react";
import * as Core from "@material-ui/core";

function GiftProfileInfoModal(Props) {
  var _open = Props.open;
  var onClose = Props.onClose;
  return React.createElement(TS.Modal.make, {
              variant: "Dark",
              closeModal: onClose,
              showModal: _open,
              children: React.createElement(Core.Box, {
                    children: null,
                    padding: 2
                  }, React.createElement("p", undefined, "Your gift profile makes it possible for Occasional.ly to find gift ideas that suit you."), React.createElement("p", undefined, "Other users that you connect with, friends and family, will be needing gift ideas as your occasions come up! Make it easier for them by selecting a few categories that fit you well."), React.createElement("p", undefined, "If you don't setup your gift profile by not making any selections, no one will get gift ideas for you. :(")),
              header: "What is your Gift Profile?",
              title: "What is your Gift Profile?"
            });
}

var make = GiftProfileInfoModal;

export {
  make ,
  
}
/* TS Not a pure module */
