import { Occasion } from '../../../@types/occasions';
import { User } from '../../../@types/user';
import { GifteeRelationship, GiftProfileGender } from '../../../__generated__/globalTypes';
import {
  gifteeRelationshipStartPhrase, GifteeRelationshipStartPhraseType
} from '../../../components/giftees/GifteeRelationshipSelect';
import {
  ADD_GIFTEE_OCCASION, DESELECT_OCCASION, DESELECT_USER, REMOVE_GIFTEE_OCCASION, RESET_ADD_GIFTEE,
  SELECT_OCCASION, SELECT_USER, SET_ADD_GIFTEE_LOADING, SET_GIFTEE_BIRTHDAY, SET_GIFTEE_GENDER,
  SET_GIFTEE_INTERESTS, SET_GIFTEE_NAME, SET_GIFTEE_OCCASIONS, SET_GIFTEE_PICTURE, SET_INVITE_SENT,
  SET_RELATIONSHIP, SET_SHOW_ADD_FIRST_GIFTEE_DIALOG, SET_SHOW_ADD_GIFTEE_SUGGESTION_DIALOG,
  SET_SHOW_ADD_GIFTEE_WIZARD, SET_SHOW_IMPORT_GIFTEE_WIZARD
} from '../constants/action-types';
import { AddGifteeAction } from '../types';

export type AddGifteeRootState = {
  userSelected?: User["customData"];
  occasions: Occasion[];
  occasionsSelected: Occasion[];
  inviteSent: boolean;
  birthday: string | null;
  gifteePicture: string;
  gifteeName: string;
  gifteeGender: GiftProfileGender | null;
  gifteeInterests: string[];
  relationship: GifteeRelationship | GifteeRelationshipStartPhraseType;
  showAddGifteeWizard: boolean;
  showImportGifteeWizard: boolean;
  showAddGifteeSuggestionDialog: boolean;
  showAddFirstGifteeDialog: boolean;
  loading: boolean;
};

const initialState = {
  userSelected: undefined,
  occasions: [],
  occasionsSelected: [],
  inviteSent: false,
  birthday: new Date().toDateString(),
  gifteePicture: "",
  gifteeName: "",
  gifteeGender: null,
  gifteeInterests: [],
  relationship: gifteeRelationshipStartPhrase,
  showAddGifteeWizard: false,
  showImportGifteeWizard: false,
  showAddGifteeSuggestionDialog: false,
  showAddFirstGifteeDialog: false,
  loading: false,
};

const addGifteeReducer = (state: AddGifteeRootState = initialState, action: AddGifteeAction): AddGifteeRootState => {
  switch (action.type) {
    case RESET_ADD_GIFTEE:
      return initialState;
    case ADD_GIFTEE_OCCASION:
      return { ...state, occasions: [...state.occasions, action.payload] };
    case REMOVE_GIFTEE_OCCASION:
      const occasions = [...state.occasions];
      const occasionIndex = occasions.findIndex((occasion) => occasion._id === action.payload);
      occasions.splice(occasionIndex, 1);
      return { ...state, occasions };
    case SET_GIFTEE_OCCASIONS:
      return { ...state, occasions: action.payload };
    case SELECT_OCCASION:
      return { ...state, occasionsSelected: [...state.occasionsSelected, action.payload] };
    case DESELECT_OCCASION:
      const occasionsSelected = [...state.occasionsSelected];
      const occasionsIndex = occasionsSelected.findIndex((occasion) => occasion._id === action.payload);
      occasionsSelected.splice(occasionsIndex, 1);
      return { ...state, occasionsSelected };
    case SELECT_USER:
      return { ...state, userSelected: action.payload };
    case DESELECT_USER:
      return { ...state, userSelected: undefined };
    case SET_RELATIONSHIP:
      return { ...state, relationship: action.payload };
    case SET_INVITE_SENT:
      return { ...state, inviteSent: action.payload };
    case SET_SHOW_ADD_GIFTEE_WIZARD:
      return { ...state, showAddGifteeWizard: action.payload };
    case SET_SHOW_IMPORT_GIFTEE_WIZARD:
      return { ...state, showImportGifteeWizard: action.payload };
    case SET_SHOW_ADD_GIFTEE_SUGGESTION_DIALOG:
      return { ...state, showAddGifteeSuggestionDialog: action.payload };
    case SET_SHOW_ADD_FIRST_GIFTEE_DIALOG:
      return { ...state, showAddFirstGifteeDialog: action.payload };
    case SET_GIFTEE_INTERESTS:
      return { ...state, gifteeInterests: action.payload };
    case SET_GIFTEE_NAME:
      return { ...state, gifteeName: action.payload };
    case SET_GIFTEE_PICTURE:
      return { ...state, gifteePicture: action.payload };
    case SET_GIFTEE_BIRTHDAY:
      return { ...state, birthday: action.payload };
    case SET_GIFTEE_GENDER:
      return { ...state, gifteeGender: action.payload };
    case SET_ADD_GIFTEE_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default addGifteeReducer;
