import { AuthActions, AuthState, AuthActionTypes } from "../@types/auth";
import { UserProfile, UserCustomData } from "../@types/user";
import { blankUserCustomData } from "./authContext";

export const reducer = (state: AuthState, action: AuthActions): AuthState => {
  switch (action.type) {
    case AuthActionTypes.LOGIN_USER:
      const realmUser = action.payload.realmUser;
      const accessToken = action.payload.accessToken;
      const email = action.payload.email;
      const customData = Object.keys(realmUser?.customData).length > 0 ? realmUser?.customData : realmUser?.profile;
      const loginObject: AuthState = {
        isAuthenticated: true,
        accessToken,
        user: {
          email: email as string,
          auth: {
            type: realmUser?.providerType,
            id: realmUser?.id,
          },
          customData: { ...customData, _id: realmUser?.id } as UserCustomData,
          profile: { ...realmUser?.profile } as UserProfile,
        },
      };

      localStorage.setItem("occ-user", JSON.stringify(loginObject));

      return {
        ...loginObject,
      };

    case AuthActionTypes.LOGOUT_USER:
      const logoutObject: AuthState = {
        isAuthenticated: false,
        accessToken: "",
        user: {
          email: "",
          auth: {
            type: "",
            id: "",
          },
          customData: blankUserCustomData,
          profile: {
            data: {},
          },
        },
      };

      localStorage.setItem("occ-user", JSON.stringify(logoutObject));

      return {
        ...logoutObject,
      };
    case AuthActionTypes.SET_USER_CUSTOM_DATA:
      const userObject: AuthState = {
        ...state,
        user: {
          ...state.user,
          customData: {
            ...state.user.customData,
            ...action.payload.customData,
          },
        },
      };

      localStorage.setItem("occ-user", JSON.stringify(userObject));

      return {
        ...userObject,
      };
    case AuthActionTypes.SET_TOKEN:
      const token = action.payload.accessToken;
      const newState = {
        ...state,
        accessToken: token,
      };

      localStorage.setItem("occ-user", JSON.stringify(newState));

      return newState;
    default:
      return state;
  }
};
