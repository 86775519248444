import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { openUserMenu } from "../../redux/general/actions";

const useStyles = makeStyles((theme) => ({
  listWrapper: {
    paddingTop: 30,
  },
  listContainer: {
    color: theme.palette.text.primary,
    minHeight: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiListItemIcon-root": {
      color: theme.palette.text.primary,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));

type Props = {
  showMenu?: boolean;
};

let promptEvent: BeforeInstallPromptEvent | undefined;

const InstallButton: FC<Props> = ({ showMenu }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isStandalone = useMemo(() => window.matchMedia("(display-mode: standalone)").matches, []);
  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      const event = e as BeforeInstallPromptEvent;
      event.preventDefault();
      promptEvent = event;
    });
  }, []);

  const handleClick = async () => {
    if (promptEvent != null) {
      promptEvent.prompt();
      await promptEvent?.userChoice;
      promptEvent = undefined;
    }
  };

  const openMenu = () => {
    dispatch({ ...openUserMenu() });
  };

  if (isStandalone || !promptEvent) {
    return null;
  }

  return (
    <ListItem className={classes.listContainer} button onClick={showMenu ? handleClick : openMenu}>
      <ListItemIcon>
        <span className="lnr lnr-download"></span>
      </ListItemIcon>
      {showMenu && <ListItemText>Install Occasional.ly</ListItemText>}
    </ListItem>
  );
};

export default InstallButton;
