import React, { FC, useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
// import { Meteor } from 'meteor/meteor';
import { makeStyles, Theme } from "@material-ui/core/styles";

import { getNotifications_notifications as NotificationType } from "../../graphql/queries/notifications/__generated__/getNotifications";
import PrimaryButton from "../general-ui/buttons/PrimaryButton";
import SecondaryButton from "../general-ui/buttons/SecondaryButton";
import useTheme from "@material-ui/core/styles/useTheme";

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    height: 60,
    width: 60,
    borderRadius: 30,
    marginRight: 10,
  },
  notificationWrapper: {
    marginBottom: 10,
  },
  notification: {
    height: "100px",
    padding: "0px",
    paddingLeft: "10px",
    boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    "& p": {
      color: theme.palette.primary.main,
    },
  },
  notificationButtons: {
    height: "100%",
  },
  notificationButton: {
    "& button": {
      margin: 4,
    },
  },
  divider: {
    width: 10,
  },
  focusText: {
    color: theme.palette.primary.dark,
  },
}));

type Props = {
  notification: NotificationType;
  timeAgo: string;
  onDelete: () => void;
  onArchive: () => void;
};

const NotificationOccasion: FC<Props> = ({ notification, timeAgo, onDelete, onArchive }) => {
  const theme = useTheme();
  const { giftee, occasion } = notification;

  const [expansion, setExpansion] = useState(true);

  const toggleExpansion = () => setExpansion(!expansion);

  const gifteeInitials = giftee?.name ? giftee?.name.charAt(0) + giftee?.name.charAt(1) : "";

  const classes = useStyles();

  const userAvatar = giftee?.picture ? (
    <ListItemAvatar>
      <Avatar src={giftee?.picture} className={classes.avatar} />
    </ListItemAvatar>
  ) : (
    <ListItemAvatar>
      <Avatar className={classes.avatar}>{gifteeInitials}</Avatar>
    </ListItemAvatar>
  );

  return (
    <div className={classes.notificationWrapper}>
      <ListItem key={notification._id} className={classes.notification} button onClick={toggleExpansion}>
        {userAvatar}
        <ListItemText secondary={timeAgo}>
          It's {giftee?.name + "'"}s <span style={{ color: theme.palette.text.primaryBranded }}>{occasion?.name}</span>{" "}
          today!
        </ListItemText>
      </ListItem>
      <Collapse in={expansion} timeout="auto" unmountOnExit>
        <ListItem className={classes.notificationButtons} disableGutters>
          <PrimaryButton onClick={onArchive}>archive</PrimaryButton>
          <div className={classes.divider}></div>
          <SecondaryButton onClick={onDelete}>delete</SecondaryButton>
        </ListItem>
      </Collapse>
    </div>
  );
};

export default NotificationOccasion;
