import React, { FC } from 'react';

import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { User } from '../../@types/user';
import { UserSearch_UserSearch } from '../../graphql/queries/users/__generated__/UserSearch';
import AnimatedCheckmark from './AnimatedCheckmark';
import UserAvatar from './UserAvatar';

const useStyles = makeStyles((theme: Theme) => ({
  giftee: {
    color: theme.palette.text.primary,
    display: "flex",
    height: "100px",
    padding: "15px",
    paddingBottom: "5px",
  },
  pendingAcceptance: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    "& .lnr-clock": {
      fontSize: 20,
      marginLeft: "10px",
    },
  },
  gifteeAvatar: {
    width: "65px",
    height: "65px",
  },
  gifteeInfo: {
    maxWidth: "60%",
    flex: "3",
    marginLeft: "10px",
    wordWrap: "break-word",
    "& .MuiTypography-h6": {
      fontSize: "1.05rem",
    },
  },
  gifteeName: {
    margin: "0px",
    color: theme.palette.text.primary,
  },
  gifteeButtonContainer: {
    flex: "1",
  },
  gifteeButton: {
    background: "rgba(255,255,255,0.2)",
    borderRadius: "50%",
  },
  upcomingOccasion: {
    fontSize: 12,
    color: "#b1bebb",
  },
  giftIdeas: {
    color: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
  },
}));

type Props = {
  userResultClick: (user: UserSearch_UserSearch) => void;
  user: UserSearch_UserSearch;
  selected?: boolean;
};

const UserSearchResult: FC<Props> = ({ userResultClick, user, selected }) => {
  const classes = useStyles();
  const userSearchResultClick = () => {
    userResultClick(user);
  };

  return (
    <ListItem key={user._id} dense button onClick={userSearchResultClick} classes={{ root: classes.giftee }}>
      <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.gifteeAvatar}>
        <UserAvatar name={user.name ?? user.email ?? ""} picture={user.picture} />
      </Grid>
      <div className={classes.gifteeInfo}>
        <Typography variant="h6">{user.name ?? user.email}</Typography>
      </div>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.gifteeButtonContainer}
      >
        <div className={classes.gifteeButton}>
          <AnimatedCheckmark otherStyles={{ height: 40, width: 40 }} checked={selected}></AnimatedCheckmark>
        </div>
      </Grid>
    </ListItem>
  );
};

export default UserSearchResult;
