import makeStyles from "@material-ui/core/styles/makeStyles";
import Tabs from "@material-ui/core/Tabs/Tabs";
import React from "react";
import { FC } from "react";
import MobileTab from "./MobileTab";

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    display: "flex",
    alignItems: "center",
    width: "100vw",
    height: 80,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    background: theme.palette.background.paper2DP,
    color: theme.palette.text.primary,
    "& .MuiTab-root": {
      flex: 1,
      zIndex: 10,
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  tabSectionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tabSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "42%",
  },
}));

type TabProps = {
  to?: string;
  onClick?: () => void;
  label: string;
  icon: string;
  badge?: React.ReactNode;
};

type Props = {
  tabs: ReadonlyArray<TabProps>;
  activeTab?: number;
  onChange?: (e: React.ChangeEvent<{}>, t: number) => void;
};

const MobileMenuTabs: FC<Props> = ({ tabs, activeTab, onChange }) => {
  const classes = useStyles();
  const currentActiveTab = activeTab ?? 0;
  return (
    <Tabs
      classes={{ root: classes.tabContainer, flexContainer: classes.tabSectionContainer }}
      value={activeTab ?? 0}
      onChange={onChange}
    >
      <div className={classes.tabSection}>
        {tabs.slice(0, 2).map((tab, i) => {
          const isActive = i === currentActiveTab;
          const icon = <span className={tab.icon}></span>;
          return (
            <MobileTab
              badge={tab.badge}
              label={<>{tab.label}</>}
              key={tab.to}
              to={tab.to}
              icon={icon}
              isActive={isActive}
              onClick={tab.onClick}
            />
          );
        })}
      </div>
      <div className={classes.tabSection}>
        {tabs.slice(2, 4).map((tab, i) => {
          const isActive = i + 2 === currentActiveTab;
          const icon = <span className={tab.icon}></span>;
          return (
            <MobileTab
              badge={tab.badge}
              label={<>{tab.label}</>}
              key={tab.to}
              to={tab.to}
              icon={icon}
              isActive={isActive}
              onClick={tab.onClick}
            />
          );
        })}
      </div>
    </Tabs>
  );
};

export default MobileMenuTabs;
