import React, { FC, useEffect, useMemo, useState } from "react";
import StepWizard from "react-step-wizard";
import Introduction from "./onboarding/Introduction";
import GiftTypesInput from "./onboarding/GiftTypesInput";
import CreateProfileInstructions from "./onboarding/CreateProfileInstructions";
import GiftSelection from "./onboarding/GiftSelection";
import ProfileConfirmation from "./onboarding/ProfileConfirmation";
import { Theme, makeStyles } from "@material-ui/core";
import SelectOccasions from "./onboarding/SelectOccasions";
import SelectOccasionInstructions from "./onboarding/SelectOccasionInstructions";
import SetBirthday from "./onboarding/SetBirthday";
import OnboardingProgress from "./onboarding/OnboardingProgress";
import OnboardingNav from "./onboarding/OnboardingNav";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../auth/authContext";
import app from "../../realm";
import { AuthActionTypes } from "../../@types/auth";
import { setGiftProfile, setLoading } from "../../redux/onboarding/actions";
import useInviteMutations from "../../hooks/useInviteMutations";
import useCompleteOnboarding from "../../hooks/useCompleteOnboarding";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { OnboardingRootState } from "../../redux/onboarding/reducers";
import { GiftIdeaRootState } from "../../redux/giftideas/reducers";
import OnboardingInviteFriends from "./onboarding/OnboardingInviteFriends";
import { getInviteLink } from "../../utils/helpers/userDataHelpers";
import SetGender from "./onboarding/SetGender";
import GiftProfileCreationIntro from "./onboarding/GiftProfileCreationIntro";
import SetNameAndPhone from "./onboarding/SetNameAndPhone";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    width: "100%",
  },
  onboardingArea: {
    flex: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.background.background,
    backgroundSize: "20vw",
  },
  logo: {
    position: "fixed",
    top: 30,
    left: 40,
    width: 45,
    objectFit: "contain",
    zIndex: 1000,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  wizardContainer: {
    height: "100vh",
    maxWidth: 925,
    top: "5vh",
    left: "calc(50% - 250px)",
    zIndex: 100,
    transition: "0.5s cubic-bezier(0.0, 0, 0.2, 1)",
    background: theme.palette.background.background,
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      width: "100vw",
      height: "100vh",
      borderRadius: 0,
    },
  },
  wizard: {
    display: "flex",
    flexDirection: "column-reverse",
  },
}));

export enum OnboardingWizardSteps {
  Introduction = 1,
  SelectOccasionInstructions,
  SelectOccasions,
  SetNameAndPhone,
  SetBirthday,
  InviteFriends,
  ProfileConfirmation,
}

const OnboardingWizard: FC = () => {
  const classes = useStyles();
  const [step, setStep] = useState(OnboardingWizardSteps.Introduction);
  const history = useHistory();
  const { authState, authDispatch } = useAuthContext();
  const dispatch = useDispatch();
  const onboardingState = useSelector<RootState, OnboardingRootState>((state) => {
    return state.onboarding;
  });
  const giftIdeasState = useSelector<RootState, GiftIdeaRootState>((state) => {
    return state.giftIdeas;
  });
  const [submitOnboarding] = useCompleteOnboarding();
  const [processInvite] = useInviteMutations();
  const confirmProfile = async () => {
    const { occasions, birthday, invites, gender, giftTypes, userName, phone } = onboardingState;
    const { selectedGifts } = giftIdeasState;

    dispatch({ ...setLoading(true) });

    await submitOnboarding({
      occasions,
      birthday,
      name: userName,
      phone,
      profile: selectedGifts.length > 0 ? selectedGifts : null,
      userId: authState.user.auth.id,
      interests: giftTypes.length > 0 ? giftTypes : null,
      gender,
    });
    const invite = localStorage.getItem("occ-invite");
    if (invite) {
      await processInvite(authState.user.customData);
    }
    dispatch({ ...setGiftProfile() });
    try {
      for (let i = 0; i < invites.length; i++) {
        const url = getInviteLink(authState.user);
        const invite = invites[i];
        await app.currentUser?.callFunction("InviteEmail", [invite, authState.user, url]);
      }
    } catch (e) {
      console.error("Error with sending invites");
      console.error(e);
    }
    await finishOnboarding();
  };

  const finishOnboarding = async () => {
    const { userName, phone } = onboardingState;
    authDispatch({
      type: AuthActionTypes.SET_USER_CUSTOM_DATA,
      payload: {
        customData: {
          ...authState.user.customData,
          finishedOnboarding: true,
          name: userName,
          phone,
        },
      },
    });
    dispatch({ ...setLoading(false) });
    await app.currentUser?.refreshCustomData();
    history.push("/home/giftees?addGifteeFirst=true");
  };
  return (
    <div className={classes.main} data-testid="onboarding-wizard">
      <img className={classes.logo} src="images/logos/bright-logo.png" />
      <div className={classes.onboardingArea}>
        <div className={classes.wizardContainer}>
          <StepWizard
            onStepChange={({ activeStep }) => setStep(activeStep)}
            nav={<OnboardingNav onFinish={confirmProfile} step={step} />}
            className={classes.wizard}
          >
            <Introduction />
            <SelectOccasionInstructions />
            <SelectOccasions />
            <SetNameAndPhone />
            <SetBirthday />
            <OnboardingInviteFriends />
            <ProfileConfirmation />
          </StepWizard>
        </div>
      </div>
    </div>
  );
};

export default OnboardingWizard;
