import gql from "graphql-tag";

export const insertOneGiftee = gql`
  mutation InsertOneGifteeMutation($data: GifteeInsertInput!) {
    insertOneGiftee(data: $data) {
      _id
      age
      createdAt
      isCustom
      isPendingAcceptance
      interests
      name
      picture
      relationship
      userId
    }
  }
`;

export const updateOneGiftee = gql`
  mutation UpdateOneGifteeMutation($query: GifteeQueryInput!, $set: GifteeUpdateInput!) {
    updateOneGiftee(query: $query, set: $set) {
      _id
      name
    }
  }
`;

export const upsertOneGiftee = gql`
  mutation UpsertOneGifteeMutation($query: GifteeQueryInput!, $data: GifteeInsertInput!) {
    upsertOneGiftee(query: $query, data: $data) {
      _id
      name
    }
  }
`;

export const deleteOneGiftee = gql`
  mutation DeleteOneGifteeMutation($query: GifteeQueryInput!) {
    deleteOneGiftee(query: $query) {
      _id
    }
  }
`;
