import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { Link } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { RootState } from '../../redux/store';
import PrimaryButton from '../general-ui/buttons/PrimaryButton';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';

const useStyles = makeStyles((theme) => ({
  loginSignup: {
    padding: theme.spacing(2),
    position: "relative",
    height: "100vh",
    background: theme.palette.background.background,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row-reverse",
    },
  },
  submitButton: {
    marginTop: 10,
    "& button": {
      padding: "15px 13px",
      height: 63,
      fontSize: 20,
    },
  },
  logo: {
    width: 60,
    marginBottom: theme.spacing(1),
    alignSelf: "flex-start",
  },
  loginLink: {
    fontSize: 20,
  },
  formWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 5,
    transition: ".25s " + theme.transitions.easing.easeInOut,
    flex: 1,
  },
  switchModeLink: {
    color: theme.palette.text.secondaryBranded,
    background: "none",
    border: "none",
    cursor: "pointer",
    padding: 0,
    fontSize: "1rem",
  },
  switchModeText: {
    marginTop: theme.spacing(1),
  },
  welcomeImage: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
}));

enum ViewState {
  Welcome,
  Signup,
  Login,
}

const MobileSignup: FC = () => {
  const darkMode = useSelector<RootState, boolean>((state) => {
    return state.general.darkMode;
  });
  const [viewState, setViewState] = useState(ViewState.Welcome);
  const classes = useStyles();
  const handleCreateAccountClick = () => {
    setViewState(ViewState.Signup);
  };
  const handleLoginClick = () => {
    setViewState(ViewState.Login);
  };
  let content;

  const welcomeImageUrl = darkMode ? "/images/gift-hug.png" : "/images/outline-gift-hug.png";
  const formImageUrl = darkMode ? "/images/you-and-dog.png" : "/images/outline-girl-and-dog.png";

  switch (viewState) {
    case ViewState.Welcome:
      content = (
        <>
          <Grid container alignItems="flex-end" justifyContent="flex-end">
            <Link onClick={handleLoginClick} className={classes.loginLink}>
              Log In
            </Link>
          </Grid>
          <div>
            <img src="/images/logos/contrast-logo.png" className={classes.logo} />
            <Typography variant="h5">Welcome to Occasional.ly</Typography>
            <Typography variant="h6">Your Occasion Ally</Typography>
          </div>
          <Grid container justifyContent="center" alignItems="center" className={classes.submitButton}>
            <PrimaryButton onClick={handleCreateAccountClick}>Create Account</PrimaryButton>
          </Grid>
          <img src={welcomeImageUrl} className={classes.welcomeImage} />
        </>
      );
      break;
    case ViewState.Signup:
      content = (
        <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.formWrapper}>
          <img src="/images/logos/contrast-logo.png" className={classes.logo} />
          <SignupForm />
          <Typography variant="subtitle1" className={classes.switchModeText}>
            Are you an existing user?{" "}
            <button onClick={() => setViewState(ViewState.Login)} className={classes.switchModeLink}>
              Log in
            </button>
          </Typography>
          <img src={formImageUrl} className={classes.welcomeImage} />
        </Grid>
      );
      break;
    case ViewState.Login:
      content = (
        <div className={classes.formWrapper}>
          <img src="/images/logos/contrast-logo.png" className={classes.logo} />
          <LoginForm />
          <Typography variant="subtitle1" className={classes.switchModeText}>
            Don't have an account?{" "}
            <button onClick={() => setViewState(ViewState.Signup)} className={classes.switchModeLink}>
              Create Account
            </button>
          </Typography>
          <img src={formImageUrl} className={classes.welcomeImage} />
        </div>
      );
  }

  return <div className={classes.loginSignup}>{content}</div>;
};

export default MobileSignup;
