import React, { FC } from 'react';

import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography/Typography';

import { getSideBarNavColor, getSideBarTextColor } from '../../theme';
import SidebarTabs from './SidebarTabs';

const useStyles = makeStyles((theme) => ({
  desktopSidebar: {
    height: "100vh",
    background: getSideBarNavColor(theme),
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    minWidth: 320,
    marginLeft: 90,
    zIndex: 10,
    position: "relative",
    overflow: "hidden",
  },
  sidebarTitle: {
    marginTop: 60,
    color: getSideBarTextColor(theme),
    fontFamily: "Sacramento",
    fontSize: 48,
  },
}));

type Props = {
  homeRoutes: ReadonlyArray<{ to?: string; label: string }>;
};

const HomeDesktopSidebar: FC<Props> = ({ homeRoutes }) => {
  const classes = useStyles();

  return (
    <div className={classes.desktopSidebar}>
      <Grid container justifyContent="center" alignItems="center">
        <Typography variant="h6" className={classes.sidebarTitle}>
          Occasional.ly
        </Typography>
      </Grid>
      <SidebarTabs tabs={homeRoutes} activeTab={homeRoutes.findIndex((r) => r.to === window.location.pathname) ?? 0} />
    </div>
  );
};

export default HomeDesktopSidebar;
