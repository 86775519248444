import React, { FC, useEffect } from "react";

import { makeStyles, Theme } from "@material-ui/core/styles";

import { handleRedirect } from "../realm/auth";
import Loader from "../components/general-ui/Loader";

const useStyles = makeStyles((theme: Theme) => ({
  redirectPage: {
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.primary.gradient,
  },
}));

type Props = {
  classes: {
    forgotPassPage: string;
    forgotPassForm: string;
    submitButton: string;
  };
};

const HandleAuthRedirect: FC<Props> = () => {
  const classes = useStyles();

  useEffect(() => {
    handleRedirect();
    window.close();
  }, []);

  return (
    <div className={classes.redirectPage}>
      <Loader />
    </div>
  );
};

export default HandleAuthRedirect;
