import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { RootState } from '../../redux/store';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';

const useStyles = makeStyles((theme) => ({
  heroArea: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    transition: "all .25s " + theme.transitions.easing.easeInOut,
    flex: 1,
  },
  heroContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    maxWidth: 600,
  },
  mainImage: {
    width: "100%",
    marginTop: theme.spacing(5),
    objectFit: "contain",
  },
  logo: {
    width: 60,
    marginBottom: theme.spacing(4),
  },
  loginSignup: {
    position: "relative",
    height: "100vh",
    background: theme.palette.background.background,
    display: "flex",
    overflow: "hidden",
    flexDirection: "row-reverse",
  },
  formWrapper: {
    flex: 1,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  signup: {
    zIndex: 5,
    transition: ".25s " + theme.transitions.easing.easeInOut,
    width: "100%",
    padding: "5px 0px",
    flex: 2,
    [theme.breakpoints.up("sm")]: {
      maxWidth: 350,
      maxHeight: "initial",
      height: "100%",
    },
    "& h1": {
      color: theme.palette.text.primary,
      marginBottom: 0,
    },
    "& h2": {
      color: theme.palette.text.primary,
      marginBottom: 10,
      transition: "all .25s " + theme.transitions.easing.easeInOut,
      fontSize: "1em",
      "& span": {
        marginRight: 5,
        opacity: 1,
        visibility: "visible",
        transition: "all .25s " + theme.transitions.easing.easeInOut,
        textDecoration: "underline",
      },
    },
    "&.slide-up": {
      transform: "translateY(1%)",
      "& .ocl-form-secondary": {
        height: 30,
        visibility: "hidden",
        opacity: 0,
      },
      "& h2": {
        opacity: 0,
        visibility: "hidden",
      },
    },
    "&:before": {
      content: "",
      zIndex: -2,
      opacity: 0.8,
      position: "absolute",
      top: "0",
      right: "0",
      bottom: "0",
      left: "0",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center bottom",
      backgroundSize: "cover",
      backgroundImage: "url('new-giftbox-flat.png')",
    },
  },
  login: {
    zIndex: 5,
    transition: ".25s " + theme.transitions.easing.easeInOut,
    width: "100%",
    position: "absolute",
    height: "60vh",
    top: 0,
    opacity: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    color: theme.palette.text.primary,
    [theme.breakpoints.up("sm")]: {
      height: "100vh",
      flex: 2,
      left: 0,
      justifyContent: "center",
      "&:before": {
        display: "none",
      },
    },
    "& h1": {
      marginBottom: 0,
    },
    "& h2": {
      transition: "all .25s " + theme.transitions.easing.easeInOut,
      fontSize: "1em",
      "& span": {
        transition: "all .25s " + theme.transitions.easing.easeInOut,
        textDecoration: "underline",
      },
    },
    "&:before": {
      content: "''",
      top: -160,
      position: "absolute",
      left: "50%",
      transform: "translate(-50%, 25%)",
      width: "200%",
      height: 550,
      borderRadius: "50%",
      zIndex: -1,
      transition: "all .25s " + theme.transitions.easing.easeInOut,
      backgroundColor: theme.palette.background.background,
    },
    "&:after": {
      content: "''",
      position: "absolute",
      backgroundColor: theme.palette.background.background,
      height: "100vh",
      width: "100%",
      zIndex: -1,
      top: 20,
      [theme.breakpoints.up("sm")]: {
        backgroundColor: theme.palette.background.background,
        top: 0,
      },
    },
    "&.slide-up": {
      transform: "translateY(calc(100vh - (100vh - 450px)))",
      opacity: 0,
      zIndex: -1,
      "& h2": {
        fontSize: "1em",
        "& span": {
          transition: "all .25s " + theme.transitions.easing.easeInOut,
        },
      },
      "&:before": {
        top: -125,
      },
    },
  },
  switchModeLink: {
    color: theme.palette.text.secondaryBranded,
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  loginWrapper: {
    position: "relative",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
      width: 350,
    },
  },
}));

enum Mode {
  Login,
  Signup,
}

const DesktopLoginSignup: FC = () => {
  const darkMode = useSelector<RootState, boolean>((state) => {
    return state.general.darkMode;
  });
  const [mode, setMode] = useState(Mode.Signup);
  const classes = useStyles();
  const signupWrapperClass = clsx({
    [classes.signup]: true,
    "slide-up": mode === Mode.Login,
  });
  const loginWrapper = clsx({
    [classes.login]: true,
    "slide-up": mode === Mode.Signup,
  });

  return (
    <div className={classes.loginSignup}>
      <div className={classes.heroArea}>
        <div className={classes.heroContent}>
          <Typography variant="h4">Welcome to Occasional.ly</Typography>
          <Typography variant="h6">Your Occasion Ally</Typography>
          <img
            src={darkMode ? "/images/gift-happiness.png" : "/images/outline-gift-happiness.png"}
            className={classes.mainImage}
          />
        </div>
      </div>
      <div className={classes.formWrapper}>
        <Grid container direction="column" justifyContent="center" alignItems="center" className={signupWrapperClass}>
          <Grid alignItems="center" justifyContent="center" direction="column" container>
            <img className={classes.logo} src="/images/logos/contrast-logo.png" />
          </Grid>

          <SignupForm />
          <Typography variant="subtitle1">
            Are you an existing user?{" "}
            <button onClick={() => setMode(Mode.Login)} className={classes.switchModeLink}>
              Log in
            </button>
          </Typography>
        </Grid>
        <div className={loginWrapper}>
          <div className={classes.loginWrapper}>
            <Grid alignItems="center" justifyContent="center" direction="column" container>
              <img className={classes.logo} src="/images/logos/contrast-logo.png" />
            </Grid>

            <LoginForm />
            <Typography variant="subtitle1">
              Don't have an account?{" "}
              <button onClick={() => setMode(Mode.Signup)} className={classes.switchModeLink}>
                Create Account
              </button>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopLoginSignup;
