import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import StepWizard from 'react-step-wizard';

import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { closeMarkGiftPurchasedDialog, setActiveGiftIdea } from '../../redux/giftideas/actions';
import AnimatedCheckmark from '../general-ui/AnimatedCheckmark';
import GlobalMessageDispatch from '../general-ui/global-messages/GlobalMessageDispatch';
import MarkGiftIdeaPurchased from './create-gift-purchase/MarkGiftIdeaPurchased';
import SearchGiftIdea from './create-gift-purchase/SearchGiftIdea';

const useStyles = makeStyles((theme) => ({
  headerActions: {
    zIndex: 10,
    color: theme.palette.text.secondary,
  },
  closeButton: {
    background: theme.palette.secondary.main,
    color: theme.palette.text.primary,
  },
}));

type Props = {
  gifteeId: string | null;
  userId: string | null;
};

const CreateGiftPurchaseWizard: FC<Props> = ({ gifteeId, userId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const closeDialog = () => {
    dispatch({ ...setActiveGiftIdea({}) });
    dispatch({ ...closeMarkGiftPurchasedDialog() });
  };

  return (
    <>
      <StepWizard
        isLazyMount={true}
        nav={
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            className={classes.headerActions}
          >
            <IconButton onClick={closeDialog} className={classes.closeButton} aria-label="close product">
              <Icon>
                <span className="lnr lnr-cross"></span>
              </Icon>
            </IconButton>
          </Grid>
        }
      >
        <SearchGiftIdea />
        <MarkGiftIdeaPurchased gifteeId={gifteeId} userId={userId} />
      </StepWizard>
      {false && (
        <GlobalMessageDispatch>
          <Grid container direction="row" alignItems="center">
            <AnimatedCheckmark
              otherStyles={{ width: 40, height: 40 }}
              checked={true}
              icon={
                <Icon>
                  <span className="lnr lnr-calendar-full"></span>
                </Icon>
              }
            />

            <Typography variant="body1" color="inherit">
              &nbsp;Occasion successfully added!
            </Typography>
          </Grid>
        </GlobalMessageDispatch>
      )}
    </>
  );
};

export default CreateGiftPurchaseWizard;
