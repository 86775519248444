// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ProductFeedRow from "./ProductFeedRow.bs.js";
import * as Styles from "@material-ui/styles";

var useStyles = Styles.makeStyles({
      giftIdeasByCategory: {
        width: "100%"
      }
    });

function GiftIdeasByCategoryFeed(Props) {
  var productFeeds = Props.productFeeds;
  var classes = useStyles();
  return React.createElement("div", {
              className: classes.giftIdeasByCategory
            }, productFeeds.map(function (productFeed, i) {
                  return React.createElement(ProductFeedRow.make, {
                              productFeed: productFeed,
                              key: String(i)
                            });
                }));
}

var make = GiftIdeasByCategoryFeed;

export {
  useStyles ,
  make ,
  
}
/* useStyles Not a pure module */
