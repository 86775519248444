import React, { FC, useState } from 'react';

import { useMutation } from '@apollo/client/react/hooks/useMutation';
import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { Card, makeStyles, Typography } from '@material-ui/core';

import { NotificationType } from '../../__generated__/globalTypes';
import { insertOneNotification } from '../../graphql/mutations/notifications';
import {
  InsertOneNotificationMutation, InsertOneNotificationMutationVariables
} from '../../graphql/mutations/notifications/__generated__/InsertOneNotificationMutation';
import { getGifteeQuery } from '../../graphql/queries/giftees';
import {
  getGiftee, getGifteeVariables
} from '../../graphql/queries/giftees/__generated__/getGiftee';
import AnimatedCheckmark from '../general-ui/AnimatedCheckmark';
import PrimaryButton from '../general-ui/buttons/PrimaryButton';

const useStyles = makeStyles((theme) => ({
  actionContainer: {
    padding: theme.spacing(2),
    background: theme.palette.background.paper,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  checkmarkContainer: {
    height: 100,
    width: 100,
    display: "flex",
    alignItems: "center",
  },
}));

type Props = {
  sourceUserId: string;
  userId: string;
};

const GiftProfileRequestCard: FC<Props> = ({ sourceUserId, userId }) => {
  const classes = useStyles();
  const [requestSent, setRequestSent] = useState(false);
  const [error, setError] = useState(false);
  const [addNotification] = useMutation<InsertOneNotificationMutation, InsertOneNotificationMutationVariables>(
    insertOneNotification
  );
  const { data: gifteeData } = useQuery<getGiftee, getGifteeVariables>(getGifteeQuery, {
    variables: {
      query: {
        sourceUser: {
          _id: sourceUserId,
        },
        userId: userId,
      },
    },
  });
  const handleGiftProfileRequest = async () => {
    try {
      await addNotification({
        variables: {
          data: {
            requester: {
              link: sourceUserId,
            },
            userId: userId,
            createdAt: new Date(),
            type: NotificationType.GIFTPROFILEREQUEST,
            new: true,
          },
        },
      });
    } catch (e) {
      console.error(e);
      setError(true);
    }
    setRequestSent(true);
  };

  return (
    <Card className={classes.actionContainer}>
      {requestSent ? (
        <>
          {!error ? (
            <>
              <div className={classes.checkmarkContainer}>
                <AnimatedCheckmark checked={requestSent} />
              </div>
              <Typography align="center" variant="h6" gutterBottom>
                Request sent!
              </Typography>{" "}
            </>
          ) : (
            "An error occurred. Please try again"
          )}
        </>
      ) : (
        <>
          <Typography align="center" variant="h6" gutterBottom>
            {gifteeData?.giftee?.name} hasn't setup their gift profile :(
          </Typography>
          <Typography align="center" variant="body1" gutterBottom>
            You can't get gift ideas for {gifteeData?.giftee?.name} until they tell us what kind of gifts they like.
          </Typography>
          <Typography align="center" variant="body1" gutterBottom>
            However, good news! You can just click this button and request that {gifteeData?.giftee?.name} setup their
            gift profile.
          </Typography>
          <PrimaryButton onClick={handleGiftProfileRequest}>REQUEST GIFT PROFILE</PrimaryButton>{" "}
        </>
      )}
    </Card>
  );
};

export default GiftProfileRequestCard;
