import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useAuthContext } from '../auth/authContext';
import AppBar from '../components/general-ui/AppBar';
import FloatingActionButton from '../components/general-ui/buttons/FloatingActionButton';
import HomeDesktopSidebar from '../components/general-ui/HomeDesktopSidebar';
import HomeMobileMenu from '../components/general-ui/HomeMobileMenu';
import Loader from '../components/general-ui/Loader';
import Modal from '../components/general-ui/modals/Modal';
import TabPanelRoute from '../components/general-ui/TabPanelRoute';
import AddFirstGifteeDialog from '../components/giftees/AddFirstGifteeDialog';
import AddGifteeSuggestionDialog from '../components/giftees/AddGifteeSuggestionDialog';
import GifteeList from '../components/giftees/GifteeList';
import GiftProfileCreatedDialog from '../components/gifts/GiftProfileCreatedDialog';
import OccasionFeed from '../components/occasions/OccasionFeed';
import AddGifteeWizard from '../components/wizards/AddGifteeWizard';
import ImportGifteeWizard from '../components/wizards/ImportGifteeWizard';
import { getGifteesQuery } from '../graphql/queries/giftees';
import { getGiftee_giftee } from '../graphql/queries/giftees/__generated__/getGiftee';
import {
  getGiftees, getGifteesVariables
} from '../graphql/queries/giftees/__generated__/getGiftees';
import { getNotificationsQuery } from '../graphql/queries/notifications';
import {
  getNotifications, getNotificationsVariables
} from '../graphql/queries/notifications/__generated__/getNotifications';
import { getOccasionsQuery } from '../graphql/queries/occasions';
import {
  getOccasions, getOccasionsVariables
} from '../graphql/queries/occasions/__generated__/getOccasions';
import useSearchParams from '../hooks/useSearchParams';
import {
  setShowAddFirstGifteeDialog, setShowAddGifteeWizard, setShowImportGifteeWizard
} from '../redux/add-giftee/actions';
import { AddGifteeRootState } from '../redux/add-giftee/reducers';
import { openNotificationsMenu, setShowGiftProfileCreatedDialog } from '../redux/general/actions';
import { RootState } from '../redux/store';
import { make as GiftIdeaFeed } from '../rescript/components/GiftIdeaFeedForMultiGiftee.gen';
import { make as GiftIdeaDetail } from '../rescript/components/GiftIdeaModal.gen';
import {
  giftProfileCreatedParam, showAddFirstGifteeDialogParam
} from './params/searchParamsConstants';

const useStyles = makeStyles((theme: Theme) => ({
  app: {
    height: "100vh",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  appContent: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      "& ::-webkit-scrollbar": {
        width: "0px",
      },
      "& ::-webkit-scrollbar-track": {
        opacity: 0,
      },

      "& ::-webkit-scrollbar-thumb": {
        display: "none",
      },
    },
  },
  contentWrapper: {
    padding: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(1),
    },
  },
  myGiftees: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "60px",
    flex: 1,
    [theme.breakpoints.up("sm")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  myGifteesTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: "24px",
    color: theme.palette.text.primary,
    [theme.breakpoints.up("sm")]: {
      color: theme.palette.text.secondary,
    },
  },
  gifteeList: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "@media(min-width: 960px)": {
      flexDirection: "row",
    },
  },
  promptFirstGiftee: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    "& button": {
      maxWidth: 400,
    },
  },
  giftIdeaFeedWrapper: {
    overflowY: "scroll",
    maxHeight: "80vh",
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      maxWidth: "calc(100vw - 410px)",
    },
  },
}));

type Props = {};

const Home: FC<Props> = () => {
  const params = useSearchParams();
  const { authState } = useAuthContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const notificationsQueryContext = useQuery<getNotifications, getNotificationsVariables>(getNotificationsQuery, {
    variables: {
      query: {
        userId: authState.user.customData._id,
      },
    },
    skip: !authState.user.customData._id,
  });
  const classes = useStyles();

  const dispatch = useDispatch();

  const addGifeeState = useSelector<RootState, AddGifteeRootState>((state) => {
    return state.addGiftee;
  });

  const gifteeQuery = useQuery<getGiftees, getGifteesVariables>(getGifteesQuery, {
    variables: {
      query: {
        userId: authState.user.customData._id,
      },
    },
    fetchPolicy: "cache-first",
    skip: !authState.user.customData._id,
  });
  const showAddFirstGifteeDialog = params.get(showAddFirstGifteeDialogParam);
  const homeRoutes: ReadonlyArray<{
    to?: string;
    onClick?: () => void;
    label: string;
    icon: string;
    badge?: React.ReactNode;
  }> = useMemo(
    () => [
      { to: "/home/giftees", label: "Giftees", icon: "lnr lnr-users" },
      { to: "/home/occasions", label: "Occasions", icon: "lnr lnr-calendar-full" },
      { to: "/home/gift-ideas", label: "Gift Ideas", icon: "lnr lnr-gift" },
    ],
    []
  );

  const newNotificationsCount = notificationsQueryContext.data?.notifications?.filter((n) => n?.new)?.length ?? 0;
  const mobileNotificationRoute: {
    to?: string;
    onClick?: () => void;
    label: string;
    icon: string;
    badge?: React.ReactNode;
  } = {
    onClick: () => dispatch({ ...openNotificationsMenu() }),
    label: "Notifications",
    icon: "lnr lnr-alarm",
    badge: newNotificationsCount > 0 ? <>{newNotificationsCount}</> : undefined,
  };
  const mobileHomeRoutes = [...homeRoutes, mobileNotificationRoute];
  const createdGiftProfile = params.get(giftProfileCreatedParam);
  const handleNewGifteeButton = () => {
    dispatch({ ...setShowAddGifteeWizard(true) });
  };
  const hideImportGifteeForm = () => {
    dispatch({ ...setShowImportGifteeWizard(false) });
  };

  const giftees = useMemo(
    () => (gifteeQuery.data?.giftees ?? []).filter(Boolean),
    [gifteeQuery.loading]
  ) as getGiftee_giftee[];
  // const gifteeMatches = giftees.map((g) => ({
  //   ...g,
  //   similarity: 0,
  // }));
  const gifteeIds = useMemo(() => giftees.map((g) => g?._id)?.filter(Boolean) ?? [], [giftees]);
  const sourceUserIds = useMemo(() => giftees.map((g) => g?.sourceUser?._id).filter(Boolean) ?? [], [giftees]);

  const occasionQuery = useQuery<getOccasions, getOccasionsVariables>(getOccasionsQuery, {
    variables: {
      query: {
        giftee: {
          _id_in: gifteeIds,
        },
      },
    },
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (createdGiftProfile === "true") {
      dispatch({ ...setShowGiftProfileCreatedDialog(true) });
    }
  }, [createdGiftProfile]);
  useEffect(() => {
    if (showAddFirstGifteeDialog === "true") {
      dispatch({ ...setShowAddFirstGifteeDialog(true) });
    }
  }, [createdGiftProfile]);

  return (
    <div className={classes.app}>
      <div className={classes.appContent}>
        <AppBar />
        {!isMobile ? <HomeDesktopSidebar homeRoutes={homeRoutes} /> : <HomeMobileMenu homeRoutes={mobileHomeRoutes} />}
        <TabPanelRoute route={homeRoutes[0].to ?? ""} index={0} className={classes.contentWrapper}>
          <div className={classes.myGiftees}>
            <Typography variant="h5" component="h2" className={classes.myGifteesTitle}>
              Giftee Hub
            </Typography>
            <GifteeList queryContext={gifteeQuery} />
          </div>
        </TabPanelRoute>
        <TabPanelRoute route={homeRoutes[1].to ?? ""} index={0} className={classes.contentWrapper}>
          <div className={classes.myGiftees}>
            <Typography variant="h5" component="h2" className={classes.myGifteesTitle}>
              Occasions
            </Typography>
            <OccasionFeed queryContext={occasionQuery} showGiftee={true} />
          </div>
        </TabPanelRoute>
        <TabPanelRoute route={homeRoutes[2].to ?? ""} index={0} className={classes.contentWrapper}>
          <GiftIdeaDetail />
          <div className={classes.myGiftees}>
            <Typography variant="h5" component="h2" className={classes.myGifteesTitle}>
              Gift Ideas
            </Typography>
          </div>
          {gifteeQuery.loading ? (
            <>
              <Loader />
            </>
          ) : (
            <div className={classes.giftIdeaFeedWrapper}>
              <GiftIdeaFeed gifteeIds={gifteeIds} userIds={sourceUserIds} />
            </div>
          )}
        </TabPanelRoute>
        <AddGifteeWizard initialStep={0} />
        <Modal
          showModal={addGifeeState.showImportGifteeWizard}
          closeModal={hideImportGifteeForm}
          title="Add a Giftee"
          variant="dark"
        >
          <ImportGifteeWizard initialStep={0} />
        </Modal>
        <AddGifteeSuggestionDialog />
        <GiftProfileCreatedDialog />
        <AddFirstGifteeDialog />
        <FloatingActionButton
          handler={handleNewGifteeButton}
          message="Add Giftee"
          messagePosition="top"
          position={isMobile ? "center" : "right"}
          icon="lnr-user-plus"
          color="secondary"
          size="large"
          data-testid="add-giftee-button"
          css={{ zIndex: isMobile ? undefined : 1 }}
        />
      </div>
    </div>
  );
};

export default Home;
