import React, { FC } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import BigIcon from '../../general-ui/BigIcon';
import UserActionCard from '../../general-ui/cards/UserActionCard';
import { AddGifteeWizardSteps } from '../AddGifteeWizard';
import WizardStep from '../general-wizard-ui/WizardStep';

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
  },
  closeButton: {
    color: theme.palette.text.primary,
    position: "absolute",
    top: 5,
    right: 5,
  },
  formHeader: {
    color: theme.palette.text.primary,
  },
}));

type Props = { hideModal: () => void } & Partial<StepWizardChildProps>;

const InviteUserOrAddCustomGiftee: FC<Props> = ({ goToStep, hideModal }) => {
  const classes = useStyles();

  return (
    <WizardStep>
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        className={classes.container}
      >
        <Typography variant="h4" align="center">
          How do you want to build this giftee?
        </Typography>
        <UserActionCard onClick={() => goToStep?.(AddGifteeWizardSteps.InviteFriendGiftee)}>
          <>
            <BigIcon icon="lnr-paper-plane" />
            <Typography align="center" variant="h6">
              Invite someone with a special link
            </Typography>
          </>
        </UserActionCard>
        <UserActionCard onClick={() => goToStep?.(AddGifteeWizardSteps.AddCustomGiftee)}>
          <>
            <BigIcon icon="lnr-beaker" />
            <Typography align="center" variant="h6">
              Create Giftee profile yourself
            </Typography>
          </>
        </UserActionCard>
      </Grid>
    </WizardStep>
  );
};

export default InviteUserOrAddCustomGiftee;
