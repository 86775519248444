import { ThemeOptions, createMuiTheme } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createPalette" {
  interface SimplePaletteColorOptions {
    gradient?: string;
    transparent?: string;
    strongTransparent?: string;
  }
  interface PaletteColor {
    gradient: string;
    transparent: string;
    strongTransparent: string;
  }
  interface TypeBackground {
    background: string;
    paper: string;
    paper2DP: string;
    activatedPaper: string;
    activatedPaper2: string;
    activatedPaperTransparent: string;
    gradientBackground: string;
    gradientBackgroundActivated: string;
  }
  interface TypeText {
    faint: string;
    overlay: string;
    primaryBranded: string;
    primaryBrandedLight: string;
    primaryBrandedHint: string;
    primaryBrandedFaint: string;
    secondaryBranded: string;
    secondaryBrandedLight: string;
    secondaryBrandedHint: string;
    secondaryBrandedFaint: string;
  }
  interface Palette {
    lightGreen: PaletteColor;
  }
  interface PaletteOptions {
    lightGreen?: PaletteColor;
  }
  interface CommonColors {
    offWhite?: string;
  }
}

export default function createMyTheme(options: ThemeOptions) {
  return createMuiTheme({
    ...options,
  });
}
