import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { useQuery } from "@apollo/client";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { getGifteeQuery } from "../../graphql/queries/giftees";
import GifteeDetailMobile from "./giftee-detail-mobile/GifteeDetailMobile";
import GifteeDetailDesktop from "./GifteeDetailDesktop";
import { getGiftee, getGifteeVariables } from "../../graphql/queries/giftees/__generated__/getGiftee";
import Loader from "../general-ui/Loader";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CreateGiftPurchaseDialog from "../gift-purchases/CreateGiftPurchaseDialog";

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.background.background,
    height: "100vh",
  },
}));

const GifteeDetail: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { gifteeId } = useParams<{ gifteeId: string }>();

  const { data, loading, error } = useQuery<getGiftee, getGifteeVariables>(getGifteeQuery, {
    variables: {
      query: {
        _id: gifteeId,
      },
    },
    fetchPolicy: "network-only",
  });
  if (error) {
    throw error;
  }

  return (
    <div className={classes.container}>
      {error && "An error occured"}
      {loading && <Loader />}
      {data?.giftee && (
        <>
          <CreateGiftPurchaseDialog gifteeId={data?.giftee?._id ?? null} />
          <Grid>
            {mobile ? <GifteeDetailMobile giftee={data.giftee} /> : <GifteeDetailDesktop giftee={data.giftee} />}
          </Grid>
        </>
      )}
    </div>
  );
};

export default GifteeDetail;
