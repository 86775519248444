import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';

import { AddGifteeRootState } from '../../../redux/add-giftee/reducers';
import { RootState } from '../../../redux/store';
import AnimatedCheckmark from '../../general-ui/AnimatedCheckmark';
import SecondaryButton from '../../general-ui/buttons/SecondaryButton';
import { AddGifteeWizardSteps } from '../AddGifteeWizard';
import WizardStep from '../general-wizard-ui/WizardStep';

const useStyles = makeStyles((theme) => ({
  success: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    cursor: "pointer",
    zIndex: 10,
    boxShadow: "inset 0 0 2px #5FB9A6",
  },
  container: {
    color: theme.palette.text.primary,
    height: "100%",
    padding: 0,
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formHeader: {
    color: theme.palette.text.primary,
    position: "relative",
    zIndex: 10,
    padding: 10,
    "& h2": {
      color: theme.palette.text.primary,
    },
  },
}));

type Props = { hideModal: () => void } & Partial<StepWizardChildProps>;

const GifteeInvitationSent: FC<Props> = ({ hideModal, goToStep }) => {
  const classes = useStyles();
  const addGifteeState = useSelector<RootState, AddGifteeRootState>((state) => {
    return state.addGiftee;
  });
  const { userSelected } = addGifteeState;

  return (
    <WizardStep>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        wrap="nowrap"
        className={classes.container}
      >
        <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.formHeader}>
          <div className={classes.success}>
            <AnimatedCheckmark checked={addGifteeState.inviteSent} color="#5FB9A6" />
          </div>

          <h3>Invitation Has Been Sent!</h3>
          <Typography variant="subtitle1">
            Hooray! You just invited <strong>{userSelected ? userSelected.name : "this cool user"}</strong> to be your
            giftee! Once they accept, you'll be able to get gift recommendations and reminders for them.
          </Typography>
        </Grid>
      </Grid>
    </WizardStep>
  );
};

export default GifteeInvitationSent;
