import {
  ADD_GLOBAL_MESSAGE, CLOSE_NOTIFICATIONS_MENU, CLOSE_USER_MENU, GO_TO_MENU_STEP,
  OPEN_NOTIFICATIONS_MENU, OPEN_USER_MENU, RESET_GENERAL_STATE, SET_DARK_MODE,
  SET_SHOW_GIFT_PROFILE_CREATED, TOGGLE_DARK_MODE, TOGGLE_USER_MENU
} from '../constants/action-types';
import { GlobalMessageAction } from '../types';

export const addGlobalMessage = ({ children }: GlobalMessageAction["payload"]) => {
  return { type: ADD_GLOBAL_MESSAGE, payload: { children } };
};
export const openNotificationsMenu = () => {
  return { type: OPEN_NOTIFICATIONS_MENU };
};

export const closeNotificationsMenu = () => {
  return { type: CLOSE_NOTIFICATIONS_MENU };
};
export const openUserMenu = () => {
  return { type: OPEN_USER_MENU };
};
export const toggleUserMenu = () => {
  return { type: TOGGLE_USER_MENU };
};
export const closeUserMenu = () => {
  return { type: CLOSE_USER_MENU };
};
export const toggleDarkMode = () => {
  return { type: TOGGLE_DARK_MODE };
};
export const setDarkMode = (mode: boolean) => {
  return { type: SET_DARK_MODE, payload: mode };
};
export const setShowGiftProfileCreatedDialog = (payload: boolean) => {
  return { type: SET_SHOW_GIFT_PROFILE_CREATED, payload };
};
export const setGoToMenuStep = (payload: number | null) => {
  return { type: GO_TO_MENU_STEP, payload };
};
export const resetGeneralState = () => {
  return { type: RESET_GENERAL_STATE };
};
