import { Theme } from "@material-ui/core";

export const wizardModalStyles = (theme: Theme) => ({
  height: "calc(90vh - 30px)",
  padding: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    height: "calc(100vh - 60px)",
    paddingTop: 60,
  },
});
