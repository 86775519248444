import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import { useMutation } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { GifteeStatus, NotificationType } from '../../../__generated__/globalTypes';
import { useAuthContext } from '../../../auth/authContext';
import { insertOneGiftee } from '../../../graphql/mutations/giftees';
import {
  InsertOneGifteeMutation, InsertOneGifteeMutationVariables
} from '../../../graphql/mutations/giftees/__generated__/InsertOneGifteeMutation';
import { insertOneNotification } from '../../../graphql/mutations/notifications';
import {
  InsertOneNotificationMutation, InsertOneNotificationMutationVariables
} from '../../../graphql/mutations/notifications/__generated__/InsertOneNotificationMutation';
import { getGifteesQuery } from '../../../graphql/queries/giftees';
import { setInviteSent } from '../../../redux/add-giftee/actions';
import { AddGifteeRootState } from '../../../redux/add-giftee/reducers';
import { RootState } from '../../../redux/store';
import GeneralAvatar from '../../general-ui/GeneralAvatar';
import ModalPage from '../../general-ui/modals/ModalPage';
import { AddGifteeWizardSteps } from '../AddGifteeWizard';

const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.text.primary,
    height: "100%",
    padding: 0,
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formHeader: {
    color: theme.palette.text.primary,
    position: "relative",
    zIndex: 10,
    paddingTop: 60,
    "& h2": {
      color: theme.palette.text.primary,
    },
  },

  confirm: {
    width: "100%",
    zIndex: 100,
  },
  confirmButton: {
    padding: "0px 10px",
    marginTop: -10,
  },
  confirmContainer: {
    paddingBottom: 20,
  },
}));

type Props = Partial<StepWizardChildProps>;

const InviteUser: FC<Props> = ({ goToStep, previousStep }) => {
  const { authState } = useAuthContext();
  const classes = useStyles();
  const [addGiftee] = useMutation<InsertOneGifteeMutation, InsertOneGifteeMutationVariables>(insertOneGiftee);
  const [addNotification] = useMutation<InsertOneNotificationMutation, InsertOneNotificationMutationVariables>(
    insertOneNotification
  );

  const addGifteeState = useSelector<RootState, AddGifteeRootState>((state) => {
    return state.addGiftee;
  });
  const dispatch = useDispatch();
  const user = addGifteeState.userSelected;

  const inviteUser = async () => {
    const birthdayDate = user?.birthday;
    let age: number | undefined;
    if (birthdayDate) {
      age = new Date().getFullYear() - new Date(birthdayDate).getFullYear() || 0;
    }

    const userId = authState.user.auth.id;
    await addGiftee({
      variables: {
        data: {
          name: user?.name ?? user?.email,
          age,
          userId,
          createdAt: new Date(),
          status: GifteeStatus.PENDING,
          picture: user?.picture,
          sourceUser: {
            link: user?._id,
          },
        },
      },
      refetchQueries: [
        {
          query: getGifteesQuery,
          variables: {
            query: {
              userId,
            },
          },
        },
      ],
    });
    await addNotification({
      variables: {
        data: {
          createdAt: new Date(),
          type: NotificationType.ADDGIFTEEREQUEST,
          userId: user?._id,
          requester: {
            link: userId,
          },
          new: true,
          archived: false,
        },
      },
    });
    goToStep?.(AddGifteeWizardSteps.GifteeInvitationSent);
    dispatch({ ...setInviteSent(true) });
  };

  return (
    <ModalPage>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        wrap="nowrap"
        className={classes.container}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          wrap="nowrap"
          classes={{ container: "flex-1 " + classes.formHeader }}
        >
          <div className="cloudinary-photo-upload-container">
            {user?.picture ? (
              <GeneralAvatar src={user.picture}>{user?.email?.charAt(0)}</GeneralAvatar>
            ) : (
              <GeneralAvatar>
                <i className="lnr lnr-gift"></i>
              </GeneralAvatar>
            )}
          </div>
          <h2>{user?.name ?? user?.email}</h2>
        </Grid>
      </Grid>
    </ModalPage>
  );
};

export default InviteUser;
