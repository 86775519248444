import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import {
  getOccasions_occasions as OccasionType
} from '../../graphql/queries/occasions/__generated__/getOccasions';
import { openMarkGiftPurchasedDialog } from '../../redux/giftideas/actions';
import { openGiftsPickedDialog, setActiveOccasion } from '../../redux/occasions/actions';

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.text.primary,
  },
  radios: {
    marginLeft: theme.spacing(1),
    "& .MuiRadio-root": {
      color: theme.palette.grey[200],
    },
    "& .MuiIconButton-colorSecondary.Mui-checked": {
      color: theme.palette.secondary.main,
    },
  },
}));

type Props = { occasion: OccasionType };

const OccasionGiftPick: FC<Props> = ({ occasion }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentOccasionYear = new Date(occasion.nextOccasionDate).getFullYear();
  const giftsPicked = occasion.purchases?.filter((p) => p?.targetYear === currentOccasionYear) ?? [];
  const handleChange = () => {
    if (giftsPicked.length === 0) {
      dispatch({ ...openMarkGiftPurchasedDialog() });
      dispatch({ ...setActiveOccasion(occasion) });
    }
  };

  const openGiftsPicked = () => {
    dispatch({ ...setActiveOccasion(occasion) });
    dispatch({ ...openGiftsPickedDialog() });
  };

  return (
    <div>
      <Grid container justifyContent="flex-end" alignItems="center">
        <Typography className={classes.text}>Found the right gift(s)?</Typography>
        <FormGroup className={classes.radios} row>
          <FormControlLabel
            color="secondary"
            classes={{ label: classes.text }}
            control={
              <Checkbox checked={giftsPicked.length > 0} onChange={handleChange} inputProps={{ "aria-label": "Yes" }} />
            }
            label="Yes"
          />
        </FormGroup>
      </Grid>
      <Grid container justifyContent="flex-end" alignItems="center">
        {giftsPicked.length > 0 && (
          <>
            {giftsPicked.length === 1 ? (
              <Link onClick={openGiftsPicked}>{giftsPicked.length} gift picked</Link>
            ) : (
              <Link onClick={openGiftsPicked}>{giftsPicked.length} gifts picked</Link>
            )}
          </>
        )}
      </Grid>
    </div>
  );
};

export default OccasionGiftPick;
