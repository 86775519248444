// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Index from "../../redux/giftideas/actions/index";

function setActiveGiftIdea(prim) {
  return Index.setActiveGiftIdea(prim);
}

function setActiveGiftIdeaId(prim) {
  return Index.setActiveGiftIdeaId(prim);
}

function setActiveGiftIdeaGifteeMatches(prim) {
  return Index.setActiveGiftIdeaGifteeMatches(prim);
}

function openGiftIdeaDialog(prim) {
  return Index.openGiftIdeaDialog();
}

function closeGiftIdeaDialog(prim) {
  return Index.closeGiftIdeaDialog();
}

var Action = {
  setActiveGiftIdea: setActiveGiftIdea,
  setActiveGiftIdeaId: setActiveGiftIdeaId,
  setActiveGiftIdeaGifteeMatches: setActiveGiftIdeaGifteeMatches,
  openGiftIdeaDialog: openGiftIdeaDialog,
  closeGiftIdeaDialog: closeGiftIdeaDialog
};

var RootState = {};

export {
  Action ,
  RootState ,
  
}
/* ../../redux/giftideas/actions/index Not a pure module */
