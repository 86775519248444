import React, { FC, useReducer } from "react";

import { GlobalMessageContext, initialState } from "./globalMessageContext";
import { reducer } from "./globalMessageReducer";

type Props = {
  children: (string | JSX.Element)[] | (string | JSX.Element) | React.ReactNode;
};

const GlobalMessageWrapper: FC<Props> = ({ children }) => {
  const [globalMessageState, globalMessageDispatch] = useReducer(reducer, initialState);

  return (
    <GlobalMessageContext.Provider
      value={{
        globalMessageState,
        globalMessageDispatch,
      }}
    >
      {children}
    </GlobalMessageContext.Provider>
  );
};

export default GlobalMessageWrapper;
