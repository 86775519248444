import React, { useEffect } from "react";
import { useGlobalMessageContext } from "./globalMessageContext";

type Props = {
  children: React.ReactNode;
  countdown?: number;
  notCloseable?: boolean;
  finalText?: string;
  id: string;
};

const GlobalMessageReplace = ({ children, countdown, finalText, notCloseable, id }: Props) => {
  const { globalMessageDispatch } = useGlobalMessageContext();

  useEffect(() => {
    globalMessageDispatch({
      type: "REPLACE_GLOBAL_MESSAGE",
      payload: {
        id,
        children,
        countdown,
        finalText,
        notCloseable,
      },
    });
  }, []);

  return <></>;
};

export default GlobalMessageReplace;
