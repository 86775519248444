import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  lightMode: {
    marginBottom: 10,
    width: "100%",
    "& label": {
      color: theme.palette.text.hint,
    },
    "& input": {
      padding: "15px 13px",
      color: theme.palette.text.primary,
    },
    "&:hover label": {
      color: `${theme.palette.text.primary} !important`,
    },
    "& fieldset": {
      borderColor: `${theme.palette.text.hint} !important`,
    },
    "&:hover fieldset": {
      borderColor: `${theme.palette.text.primary} !important`,
    },
    "& input:-internal-autofill-selected": {
      borderRadius: 30,
      color: theme.palette.text.primary + " !important",
    },
  },
  darkMode: {
    marginBottom: 10,
    width: "100%",
  },
}));

type Props = {
  label?: string;
  onKeyPress?: (event: React.KeyboardEvent<Element>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  value: string | number;
  variant?: string;
  name?: string;
};

const PasswordInput: FC<Props> = ({ label, onKeyPress, onChange, value, variant, name }) => {
  // handle password viewing changes

  const classes = useStyles();
  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  return (
    <TextField
      className={variant === "light" ? classes.lightMode : classes.darkMode}
      variant="outlined"
      type={values.showPassword ? "text" : "password"}
      label={label}
      value={value}
      onChange={onChange}
      onKeyPress={onKeyPress}
      InputProps={{
        name,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              style={{ color: variant === "light" ? "white" : undefined }}
            >
              {values.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordInput;
