import { useSubscription } from "@apollo/client";
import gql from "graphql-tag";
import { useAuthContext } from "../auth/authContext";
import {
  getNotifications_notifications,
  getNotifications,
} from "../graphql/queries/notifications/__generated__/getNotifications";

export const notificationsSubscription = gql`
  subscription getNotificationsSub($userIdVariable: String!) {
    notifications(userIdVariable: $userIdVariable) {
      _id
      type
      createdAt
      occasion {
        type
        name
        nextOccasionDate
      }
      invitee {
        _id
        email
        name
        authId
        picture
      }
      giftee {
        name
        picture
      }
      requester {
        _id
        email
        name
        authId
        picture
      }
      requestee {
        _id
        email
        name
        authId
        picture
      }
    }
  }
`;

const useNotificationSub = async (onNext: (e: getNotifications_notifications[]) => void) => {
  const { authState } = useAuthContext();
  useSubscription<getNotifications>(notificationsSubscription, {
    variables: { userIdVariable: authState.user.customData._id ?? "" },
    onSubscriptionData: ({ subscriptionData }) => {
      const notificationsFiltered = subscriptionData?.data?.notifications?.filter(Boolean) as
        | getNotifications_notifications[]
        | null;
      onNext(notificationsFiltered ?? []);
    },
  });
};

export default useNotificationSub;
