import { ApolloCache } from "@apollo/client";
import { getGiftProfileQuery } from "../../queries/gift-profiles";
import {
  getGiftProfile,
} from "../../queries/gift-profiles/__generated__/getGiftProfile";

export const updateGiftProfileInCache = <T, V, R>(cache: ApolloCache<T>, variables: V, newData: R) => {
  try {
    const data = cache.readQuery<getGiftProfile>({ query: getGiftProfileQuery, variables });
    const giftProfile = data?.giftProfile ?? [];
    cache.writeQuery({
      query: getGiftProfileQuery,
      variables,
      data: { ...giftProfile, newData },
    });
  } catch (e) {
    //no-op
  }
};
export const updateGiftProfilePurchasesInCache = <T, V>(
  cache: ApolloCache<T>,
  variables: V,
  purchases: {
    transactionId: any;
    giftIdeaId: any;
    date: any;
  }[]
) => {
  try {
    const data = cache.readQuery<getGiftProfile>({ query: getGiftProfileQuery, variables });
    const giftProfile = data?.giftProfile;
    cache.writeQuery({
      query: getGiftProfileQuery,
      variables,
      data: { giftProfile: { ...giftProfile, purchases } },
    });
  } catch (e) {
    console.error(e);
    //no-op
  }
};
