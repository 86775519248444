import gql from "graphql-tag";

export const insertOneNotificationSetting = gql`
  mutation InsertOneNotificationSetting($data: NotificationSettingInsertInput!) {
    insertOneNotificationSetting(data: $data) {
      _id
    }
  }
`;

export const updateOneNotificationSetting = gql`
  mutation UpdateOneNotificationSettingMutation(
    $query: NotificationSettingQueryInput!
    $set: NotificationSettingUpdateInput!
  ) {
    updateOneNotificationSetting(query: $query, set: $set) {
      _id
    }
  }
`;

export const deleteOneNotificationSetting = gql`
  mutation DeleteOneNotificationSettingMutation($query: NotificationSettingQueryInput!) {
    deleteOneNotificationSetting(query: $query) {
      _id
    }
  }
`;
