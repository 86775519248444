import React, { FC } from 'react';

import { Grid, MuiThemeProvider, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { darkTheme } from '../../../theme';
import PrimaryButton from '../buttons/PrimaryButton';

const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.text.primary,
    background: theme.palette.background.background,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    textAlign: "center",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: 600,
    zIndex: 2,
    padding: theme.spacing(0.5),
  },
  image: {
    width: "100%",
    marginBottom: theme.spacing(3),
  },
  textContainer: {
    maxWidth: 400,
  },
  title: {},
}));

const ErrorPage: FC = () => {
  const classes = useStyles();
  return (
    <MuiThemeProvider theme={darkTheme}>
      <div className={classes.container}>
        <div className={classes.content}>
          <img className={classes.image} alt="An error occurred with Occasional.ly" src="/images/kitty-problem.png" />
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            wrap="nowrap"
            className={classes.textContainer}
          >
            <Typography color="secondary" className={classes.title} variant="h3" gutterBottom>
              Oops!
            </Typography>
            <Typography variant="h6" gutterBottom>
              We ran into a bump in the road. That's our bad and we do apologize. Click the button below to reload the
              app
            </Typography>
            <PrimaryButton onClick={() => window.location.reload()}>Get a Fresh Start</PrimaryButton>
          </Grid>
        </div>
      </div>
    </MuiThemeProvider>
  );
};

export default ErrorPage;
