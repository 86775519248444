import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepWizardChildProps } from 'react-step-wizard';

import { useMutation } from '@apollo/client/react/hooks';
import { CircularProgress, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { GifteeStatus, GiftProfileGender, OccasionType } from '../../../__generated__/globalTypes';
import { useAuthContext } from '../../../auth/authContext';
import { insertOneGiftProfile } from '../../../graphql/mutations/gift-profiles';
import {
  InsertOneGiftProfileMutation, InsertOneGiftProfileMutationVariables
} from '../../../graphql/mutations/gift-profiles/__generated__/InsertOneGiftProfileMutation';
import { insertOneGiftee } from '../../../graphql/mutations/giftees';
import {
  InsertOneGifteeMutation, InsertOneGifteeMutationVariables
} from '../../../graphql/mutations/giftees/__generated__/InsertOneGifteeMutation';
import { getGifteesQuery } from '../../../graphql/queries/giftees';
import useAnalyticsEvent from '../../../hooks/analytics/useAnalyticsEvent';
import useAddOccasion from '../../../hooks/useAddOccasion';
import { setAddGifteeLoading } from '../../../redux/add-giftee/actions';
import { AddGifteeRootState } from '../../../redux/add-giftee/reducers';
import { RootState } from '../../../redux/store';
import getNextOccasionDate from '../../../utils/occasionDates';
import AnimatedCheckmark from '../../general-ui/AnimatedCheckmark';
import { gifteeRelationshipStartPhrase } from '../../giftees/GifteeRelationshipSelect';
import { AddGifteeWizardSteps } from '../AddGifteeWizard';
import WizardStep from '../general-wizard-ui/WizardStep';

const useStyles = makeStyles((theme: Theme) => ({
  successContainer: {
    marginTop: theme.spacing(8),
    width: 100,
    height: "70%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
}));

type Props = {} & Partial<StepWizardChildProps>;

const CreatingCustomGiftee: FC<Props> = ({ currentStep }) => {
  const classes = useStyles();
  const { authState } = useAuthContext();
  const [success, setSuccess] = useState(false);
  const [addCustomGiftee, { called: customGifteeAddCalled }] = useMutation<
    InsertOneGifteeMutation,
    InsertOneGifteeMutationVariables
  >(insertOneGiftee);
  const [addGiftProfile, { called: giftProfileAddCalled }] = useMutation<
    InsertOneGiftProfileMutation,
    InsertOneGiftProfileMutationVariables
  >(insertOneGiftProfile);
  const [addOccasion] = useAddOccasion();
  const addGifteeState = useSelector<RootState, AddGifteeRootState>((state) => {
    return state.addGiftee;
  });
  const { gifteeName, gifteePicture, gifteeGender, gifteeInterests, birthday, occasionsSelected, relationship } =
    addGifteeState;

  const fireGoogleAnalytics = useAnalyticsEvent("giftees", "create", "Custom Giftee");
  const dispatch = useDispatch();
  const handleNewGifteeForm = async () => {
    dispatch({ ...setAddGifteeLoading(true) });

    const birthdayDate = new Date(birthday ?? new Date().toDateString());
    try {
      const userId = authState.user.auth.id;
      if (relationship !== gifteeRelationshipStartPhrase) {
        const customGifteeResult = await addCustomGiftee({
          variables: {
            data: {
              name: gifteeName,
              userId,
              isCustom: true,
              picture: gifteePicture,
              createdAt: new Date(),
              interests: gifteeInterests,
              status: GifteeStatus.ADDED,
              relationship: relationship,
            },
          },
          refetchQueries: [
            {
              query: getGifteesQuery,
              variables: {
                query: {
                  userId,
                },
              },
            },
          ],
        });

        const profile = gifteeInterests.map((interest) => ({ title: interest.trim() }));

        await addGiftProfile({
          variables: {
            data: {
              gifteeId: customGifteeResult?.data?.insertOneGiftee?._id,
              profile,
              interests: gifteeInterests,
              purchases: [],
              gender: gifteeGender ?? GiftProfileGender.UNISEX,
            },
          },
        });
        if (birthdayDate) {
          const dayMonthDate = {
            month: birthdayDate.getMonth(),
            day: birthdayDate.getDate(),
          };
          await addOccasion({
            annual: true,
            name: "Birthday",
            type: OccasionType.BIRTHDAY,
            createdAt: new Date(),
            nextOccasionDate: getNextOccasionDate(OccasionType.BIRTHDAY, dayMonthDate),
            dayMonthDate,
            giftee: {
              link: customGifteeResult?.data?.insertOneGiftee?._id,
            },
          });
        }

        for (let i = 0; i < occasionsSelected.length; i++) {
          const occasion = occasionsSelected[i];
          const nextOccasionDate = getNextOccasionDate(occasion.type);
          const occasionDateObj = {
            month: nextOccasionDate?.getMonth(),
            day: nextOccasionDate?.getDate(),
          };
          await addOccasion({
            annual: true,
            name: occasion.name,
            type: occasion.type,
            createdAt: new Date(),
            nextOccasionDate,
            dayMonthDate: occasionDateObj,
            giftee: {
              link: customGifteeResult?.data?.insertOneGiftee?._id,
            },
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
    fireGoogleAnalytics();
    setSuccess(true);
    dispatch({ ...setAddGifteeLoading(false) });
  };

  useEffect(() => {
    if (
      currentStep === AddGifteeWizardSteps.CreatingCustomGiftee &&
      gifteeName.trim() !== "" &&
      gifteeInterests.length > 0 &&
      !customGifteeAddCalled &&
      !giftProfileAddCalled &&
      !success
    ) {
      handleNewGifteeForm();
    }
  }, [currentStep, gifteeName, gifteeInterests]);

  return (
    <WizardStep>
      <div className={classes.successContainer}>
        {success ? (
          <div>
            <AnimatedCheckmark checked={true} />
            <Typography align="center">Giftee Created Successfully!</Typography>
          </div>
        ) : (
          <CircularProgress size={100} thickness={2} />
        )}
      </div>
    </WizardStep>
  );
};

export default CreatingCustomGiftee;
