import Switch from "@material-ui/core/Switch/Switch";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleDarkMode } from "../../redux/general/actions";
import { GeneralRootState } from "../../redux/general/reducers";
import { RootState } from "../../redux/store";

const DarkModeToggle: FC = () => {
  const dispatch = useDispatch();

  const { darkMode } = useSelector<RootState, GeneralRootState>((state) => {
    return state.general;
  });

  const handleChange = () => {
    dispatch({ ...toggleDarkMode() });
  };
  return (
    <Switch
      edge="end"
      onChange={handleChange}
      checked={darkMode}
      inputProps={{
        "aria-labelledby": "switch-list-label-wifi",
      }}
    />
  );
};

export default DarkModeToggle;
