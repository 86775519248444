import { ApolloCache, FetchResult } from "@apollo/client";
import { DeleteOneGifteeMutation } from "../../mutations/giftees/__generated__/DeleteOneGifteeMutation";
import { InsertOneGifteeMutation } from "../../mutations/giftees/__generated__/InsertOneGifteeMutation";
import { UpdateOneGifteeMutation } from "../../mutations/giftees/__generated__/UpdateOneGifteeMutation";
import { getGifteeQuery, getGifteesQuery } from "../../queries/giftees";
import { getGiftee } from "../../queries/giftees/__generated__/getGiftee";
import { getGiftees } from "../../queries/giftees/__generated__/getGiftees";

export const updateGifteesInCache = <T, V extends Record<string, any>, R>(
  cache: ApolloCache<T>,
  variables: V,
  result: FetchResult<InsertOneGifteeMutation>
) => {
  try {
    const data = cache.readQuery<getGiftees>({ query: getGifteesQuery, variables });
    const giftees = data?.giftees ?? [];
    cache.writeQuery({
      query: getGifteesQuery,
      variables,
      data: [...giftees, result.data?.insertOneGiftee],
    });
  } catch (e) {
    //no-op
  }
};

export const deleteGifteeFromCache = <T, V, R>(
  cache: ApolloCache<T>,
  variables: V,
  result: FetchResult<DeleteOneGifteeMutation>
) => {
  try {
    const data = cache.readQuery<getGiftees>({ query: getGifteesQuery, variables });
    const giftees = data?.giftees ?? [];
    const deletedGifteeIndex = giftees.findIndex((r) => r?._id === result?.data?.deleteOneGiftee?._id);
    const newGiftees = [...giftees];
    newGiftees.splice(deletedGifteeIndex, 1);
    cache.writeQuery({
      query: getGifteesQuery,
      variables,
      data: {
        giftees: newGiftees,
      },
    });
  } catch (e) {
    //no-op
  }
};

export const updateGifteeInCache = <T, V, R>(cache: ApolloCache<T>, variables: V, result: R) => {
  try {
    const data = cache.readQuery<getGiftee>({ query: getGifteeQuery, variables });
    const giftee = data?.giftee ?? [];
    cache.writeQuery({
      query: getGifteeQuery,
      variables,
      data: {
        giftee: { ...giftee, result },
      },
    });
  } catch (e) {
    //no-op
  }
};
