// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/styles";

var useStyles = Styles.makeStyles(function (theme) {
      return {
              formControl: {
                width: "100%"
              },
              noBorder: {
                border: "none"
              },
              border: {
                borderColor: theme.palette.text.primary
              }
            };
    });

function GenderSelect(Props) {
  var onChange = Props.onChange;
  var gender = Props.gender;
  var noBorder = Props.noBorder;
  var noLabel = Props.noLabel;
  var match = React.useState(function () {
        return "UNISEX";
      });
  var setGenderState = match[1];
  var genderState = match[0];
  React.useEffect((function () {
          Curry._1(setGenderState, (function (param) {
                  return gender;
                }));
          
        }), [gender]);
  var handleChange = function ($$event) {
    var match = $$event.currentTarget.value;
    var tmp;
    switch (match) {
      case "female" :
          tmp = "FEMALE";
          break;
      case "male" :
          tmp = "MALE";
          break;
      default:
        tmp = "UNISEX";
    }
    return Curry._1(onChange, tmp);
  };
  var value = genderState !== undefined ? (
      genderState === "UNISEX" ? "unisex" : (
          genderState === "MALE" ? "male" : "female"
        )
    ) : "";
  var classes = useStyles();
  var inputClasses;
  var exit = 0;
  if (noBorder !== undefined && noBorder) {
    inputClasses = {
      notchedOutline: classes.noBorder
    };
  } else {
    exit = 1;
  }
  if (exit === 1) {
    inputClasses = {
      notchedOutline: classes.border
    };
  }
  var label;
  var exit$1 = 0;
  if (noLabel !== undefined && noLabel) {
    label = null;
  } else {
    exit$1 = 1;
  }
  if (exit$1 === 1) {
    label = React.createElement(Core.InputLabel, {
          children: "Gender",
          htmlFor: "gender-select"
        });
  }
  return React.createElement(Core.FormControl, {
              children: null,
              classes: {
                root: classes.formControl
              },
              variant: "outlined"
            }, label, React.createElement(Core.Select, {
                  children: null,
                  input: React.createElement(Core.OutlinedInput, {
                        classes: inputClasses,
                        id: "gender-select",
                        labelWidth: 110,
                        name: "Gender"
                      }),
                  native: true,
                  onChange: handleChange,
                  value: value
                }, React.createElement("option", {
                      value: ""
                    }), React.createElement("option", {
                      value: "unisex"
                    }, "Non-binary"), React.createElement("option", {
                      value: "male"
                    }, "Male"), React.createElement("option", {
                      value: "female"
                    }, "Female")));
}

var make = GenderSelect;

export {
  useStyles ,
  make ,
  
}
/* useStyles Not a pure module */
